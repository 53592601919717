import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

function getMinMaxValues(filter, revert) {
	if (filter !== null && filter !== undefined) {
		const values = filter.split(':').at(-1);
		const [min, max] = values.split('-');
		if (min && max && !Number.isNaN(min) && !Number.isNaN(max)) {
			return [
				parseFloat(revert ? revert(min) : min),
				parseFloat(revert ? revert(max) : max),
			];
		}
		return null;
	}
	return null;
}

function MuiDataTableRangeFilterDisplay({
	filterList,
	onChange,
	index,
	column,
	minValue = 0,
	maxValue = 100,
	format,
	revert,
}) {
	const initialValue =
		filterList[index].length !== 0
			? getMinMaxValues(filterList[index][0], revert)
			: null;

	const [value, setValue] = React.useState(
		initialValue || [minValue, maxValue],
	);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const onChangeCommitted = (event, newValue) => {
		const [min, max] = newValue;

		const minLabel = format ? format(min) : min;
		const maxLabel = format ? format(max) : max;
		const filterValue = `${column.label}: ${minLabel} - ${maxLabel}`;
		onChange([filterValue], index, column);
	};

	return (
		<Box>
			<Typography variant="subtitle2">{column.label}</Typography>
			<Slider
				disabled={minValue === maxValue}
				min={minValue}
				max={maxValue}
				getAriaLabel={() => column.label}
				value={value}
				onChange={handleChange}
				onChangeCommitted={onChangeCommitted}
				valueLabelDisplay="auto"
				valueLabelFormat={format}
				marks={[
					{
						value: minValue,
						label: format(minValue),
					},
					{
						value: maxValue,
						label: format(maxValue),
					},
				]}
			/>
		</Box>
	);
}

export const getRangeOptions = ({
	format,
	revert,
	minValue,
	maxValue,
}) => ({
	display: (filterList, onChange, index, column) => (
		<MuiDataTableRangeFilterDisplay
			filterList={filterList}
			onChange={onChange}
			column={column}
			index={index}
			minValue={minValue}
			maxValue={maxValue}
			format={format}
			revert={revert}
		/>
	),
	logic: (value, filters) => {
		// For some reason false, means that it will be shown in the table
		if (!filters || filters.length === 0) {
			return false;
		}
		const [min, max] = getMinMaxValues(filters[0], revert);
		return value < min || value > max;
	},
});

MuiDataTableRangeFilterDisplay.propTypes = {
	filterList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
		.isRequired,
	onChange: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
	column: PropTypes.shape({
		label: PropTypes.string.isRequired,
	}).isRequired,
	minValue: PropTypes.number,
	maxValue: PropTypes.number,
	format: PropTypes.func,
	revert: PropTypes.func,
};

MuiDataTableRangeFilterDisplay.defaultProps = {
	format: null,
	revert: null,
	minValue: 0,
	maxValue: 100,
};
