import React from 'react';
import { DRAWER_OPEN_STORAGE_KEY } from '@app/lib/localStorage';
import useAuth from '@app/hooks/useAuth';

export function useDrawerOpen() {
	const { user, merchant } = useAuth();
	const drawerOpenStorageValue = React.useMemo(
		() => JSON.parse(localStorage.getItem(DRAWER_OPEN_STORAGE_KEY)),
		[],
	);
	const isDrawerOpenValue = React.useMemo(
		() =>
			drawerOpenStorageValue === null ||
			drawerOpenStorageValue === true,
		[drawerOpenStorageValue],
	);
	const [isDrawerOpen, setIsDrawerOpen] =
		React.useState(isDrawerOpenValue);
	const [isMobileLayout, setIsMobileLayout] = React.useState(
		window.innerWidth <= 767,
	);

	const toggleDrawer = React.useCallback(() => {
		setIsDrawerOpen((prevValue) => {
			const newValue = !prevValue;
			localStorage.setItem(
				DRAWER_OPEN_STORAGE_KEY,
				JSON.stringify(newValue),
			);
			return newValue;
		});
	}, []);

	React.useEffect(() => {
		if (drawerOpenStorageValue === null) {
			localStorage.setItem(
				DRAWER_OPEN_STORAGE_KEY,
				JSON.stringify(true),
			);
		}
	}, [drawerOpenStorageValue]);

	React.useEffect(() => {
		const handleResize = () => {
			setIsMobileLayout(window.innerWidth <= 767);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const open = isDrawerOpen && !isMobileLayout && user && merchant;
	const isOpenDrawerButtonHidden = open || isMobileLayout;

	return { open, toggleDrawer, isOpenDrawerButtonHidden };
}
