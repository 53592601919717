import PropTypes from 'prop-types';
import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

import useAuth from '@app/hooks/useAuth';
import { useSelectBillingTier } from '@app/hooks/useMerchant';
import SubscriptionTileContent from './SubscriptionTileContent';
import Button from '@app/components/shared/button/Button';

function SubscriptionTile({ subscription }) {
	const theme = useTheme();
	const { merchant } = useAuth();
	const { mutate } = useSelectBillingTier();

	const isSelected =
		merchant.active_subscription?.billing_tier_id === subscription.id;

	const handleChangeSubscription = React.useCallback(() => {
		mutate(
			{
				tierId: subscription.id,
				dealId: subscription.deals[0]?.id,
				shouldCancelPreviousSubscription: false,
			},
			{
				onSuccess: ({ confirmationUrl }) => {
					if (confirmationUrl) {
						window.open(confirmationUrl, '_blank');
					}
				},
			},
		);
	}, [mutate, subscription.id, subscription.deals]);

	const billingInterval = React.useMemo(
		() =>
			subscription.billing_interval === 'annually'
				? 'yearly'
				: subscription.billing_interval,
		[subscription.billing_interval],
	);

	return (
		<Grid item xs={12} md={6} lg={4}>
			<Card
				sx={{
					minWidth: 275,
					border: isSelected
						? `1px solid ${theme.palette.primary.main}`
						: 'primary.main',
					borderRadius: '10px',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<CardContent
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						minHeight: '100%',
					}}
				>
					<SubscriptionTileContent
						isSelected={isSelected}
						subscription={subscription}
						billingInterval={billingInterval}
					/>
					<Button
						disabled={isSelected}
						onClick={handleChangeSubscription}
					>
						Get {subscription.title} {billingInterval}
					</Button>
				</CardContent>
			</Card>
		</Grid>
	);
}

SubscriptionTile.propTypes = {
	subscription: PropTypes.shape({
		id: PropTypes.string,
		title: PropTypes.string,
		billing_interval: PropTypes.string,
		deals: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
			}),
		),
	}).isRequired,
};

export default SubscriptionTile;
