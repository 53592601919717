import * as React from 'react';
import CookieConsent from 'react-cookie-consent';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

import useCookieConsent from '../../hooks/useCookieConsent';
import constants from '../../constants';

function CookieConsentBanner() {
	const {
		cookieConsent,
		setCookieConsent,
		cookieConsentClicked,
		setCookieConsentClicked,
	} = useCookieConsent();

	return (
		<CookieConsent
			id="bazo-cookie-consent-banner"
			visible={
				// Show the banner on every reload if user declines
				cookieConsent || cookieConsentClicked ? 'hidden' : 'show'
			}
			expires={365}
			buttonText="Accept"
			onAccept={() => {
				setCookieConsentClicked(true);
				setCookieConsent(true);
			}}
			enableDeclineButton
			declineButtonText="decline"
			onDecline={() => {
				setCookieConsentClicked(true);
				setCookieConsent(false);
			}}
			hideOnAccept
			// z-index of drawer is 1200 + 1
			ButtonComponent={Button}
			style={{ zIndex: constants.zIndex.bannerAlert }}
			disableButtonStyles
			location="bottom"
			customButtonProps={{
				variant: 'contained',
				sx: {
					marginRight: 3,
				},
			}}
		>
			<Typography sx={{ maxWidth: '1500px' }}>
				We use essential cookies to make our site work. With your
				consent, we may also use non-essential cookies to improve user
				experience and analyze website traffic. By clicking “Accept,“
				you agree to our website&apos;s cookie use as described in our{' '}
				<Link
					href={constants.cookiePolicyUrl}
					rel="noopener noreferrer"
					target="_blank"
				>
					Cookie Policy.
				</Link>
				{/* . You can change your cookie settings at any time by
					clicking “Preferences.” */}
			</Typography>
		</CookieConsent>
	);
}

export default CookieConsentBanner;
