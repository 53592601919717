export const pages = {
	LANDING_PAGE: 'Landing',
	MERCHANT_LOGIN: 'Merchant Login',
};
export const eventCategories = {
	USER: 'User',
	NAVIGATION: 'Navigation',
	FAQ: 'Faq',
	LOGIN: 'Login',
	LOGOUT: 'Logout',
	CREATE_CAMPAIGN: 'Create Campaign',
	UPDATE_CAMPAIGN: 'Update Campaign',
	FULFILL_CAMPAIGN: 'Fulfill Campaign',
};
export const events = {
	CLICK: 'click',
	SUBMIT: 'submit',
	DOWNLOAD: 'download',
	VISIT: 'visit',
};
export const transport = {
	BEACON: 'beacon',
	XHR: 'xhr',
};

export const GA_LABEL = {
	CAMPAIGN_FORM_DESCRIPTION: 'campaign-form-description',
	PRODUCT_TITLE: 'product-title',
	SUBSCRIPTION_CARD: 'subscription-card',
	LOGIN_EMAIL_INPUT: 'login-email-input',
	NOT_FOUND_CODE: 'not-found-code',
	PREORDERS_TABLE: 'preorders-table',
	REGISTER_TITLE: 'register-title',
	RESET_PASSWORD_EMAIL: 'reset-password-email',
	RESET_PASSWORD_INPUT: 'reset-password-input',
};

export default {
	pages,
	eventCategories,
	events,
	transport,
};
