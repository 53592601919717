export default {
	nyopTiming: {
		defaultCampaignLength: 3, // Default when creating Campaign in days
		postEndedDuration: 2, // How many days the delivery date is after campaign is ended
		maxCampaignDuration: 10, // Maximum number of days a Campaign can last
	},
	merchantTermsUrl: 'https://bazo.co/merchant-terms',
	purchaserTermsUrl: 'https://bazo.co/purchaser-terms',
	privacyUrl: 'https://bazo.co/privacy',
	cookiePolicyUrl: 'https://bazo.co/cookie-policy',
	faqUrl:
		'https://octagonal-mice-923.notion.site/26cec2684c0645159a346f8191041fde?v=4eae5a12948e4aa59fb23d970695fd0c',
	onboardingFaqUrl:
		'https://octagonal-mice-923.notion.site/26cec2684c0645159a346f8191041fde?v=4eae5a12948e4aa59fb23d970695fd0c&p=6935a3add4cb4f8d81ae5afc0a2b256f&pm=s',
	deferredPurchaseOptions: {
		depositValue: {
			NoDeposit: 0.0,
			Deposit25: 25.0,
			Deposit50: 50.0,
			Deposit75: 75.0,
			Deposit100: 100.0,
		},
		sellingPlanFulfillmentTrigger: {
			Asap: 'ASAP',
			Unknown: 'UNKNOWN',
			ExactTime: 'EXACT_TIME',
		},
		sellingPlanReserve: {
			OnSale: 'ON_SALE',
			OnFulfillment: 'ON_FULFILLMENT',
		},
		sellingPlanRemainingBalanceChargeTrigger: {
			NoRemainingBalance: 'NO_REMAINING_BALANCE',
			ExactTime: 'EXACT_TIME',
		},
	},
	campaignTypes: {
		NYOP: 'nyop',
		MARKETPLACE: 'marketplace',
		PREORDER: 'preorder',
	},
	shopifyObjectPrefix: {
		SELLING_PLAN_GROUP: 'gid://shopify/SellingPlanGroup/',
		PRODUCT_VARIANT: 'gid://shopify/ProductVariant/',
		PRODUCT: 'gid://shopify/Product/',
	},
	colors: {
		primary: '#00CD9F',
		primaryFade: '#00CD9F14',
		primaryBorder: '#00CDA180',
		secondary: '#216CAC',
		tertiary: '#FFB428',
		info: '#216CAC',
		warning: '#EF6C00',
		failure: '#D32F2F',
		disabled: '#CCCCCC',
		disabledActiveMask: '#00000020', // Mask for disabled element that previously was selected as active for contrast among not active elements
		pending: '#918c8c',
		textMain: '#000000',
		darkGrey: '#949494',
		defaultBorder: '#7676764D',
	},
	auxiliaryCategories: {
		ORDER_PROTECTION: 'ORDER_PROTECTION',
		FREE_GIFT: 'FREE_GIFT',
	},
	auxiliaryCategoriesFields: {
		ORDER_PROTECTION: 'auxiliary_protection_step_complete',
		FREE_GIFT: 'auxiliary_free_gift_step_complete',
	},
	auxiliaryCategoriesWords: {
		ORDER_PROTECTION: ['order', 'ship'],
		FREE_GIFT: ['free', 'gift'],
	},
	counterOfferPercent: 0.75,
	zIndex: {
		popover: 1500,
		modalBackground: 1400,
		bannerAlert: 1300,
		navStickyContainer: 1003,
		lockedComponentWrapper: 1002,
		lockedComponent: 1001,
		drawer: 100,
	},
};
