import React from 'react';
import Typography from '@mui/material/Typography';

import CurrentAuxiliaryProductsTable from './CurrentAuxiliaryProductsTable';
import constants from '@app/constants';

const titleStyles = {
	fontSize: '1.25rem',
	fontWeight: 500,
	marginBottom: 2,
};

const disclaimerStyles = {
	mt: 1,
	mb: 3,
	color: constants.colors.pending,
	fontSize: 14,
};

function AuxiliaryProducts() {
	return (
		<>
			<CurrentAuxiliaryProductsTable
				auxiliaryCategory={
					constants.auxiliaryCategories.ORDER_PROTECTION
				}
				checkBoxText="I do not use Order or Shipping Protection"
				warningText="Action Needed: Confirm 'Order Protection' setting to ensure precise order handling. Please select the appropriate product or check 'I do not use Order or Shipping Protection.'"
			>
				<Typography sx={titleStyles}>Order protection</Typography>
				<Typography>
					Do you have order or shipping protection? If so, please
					select it below.
				</Typography>
				<Typography sx={disclaimerStyles}>
					*For accurate offer handling, Bazo requires correct
					identification of order protection. Failure to do so may
					lead to offer discrepancies.
				</Typography>
			</CurrentAuxiliaryProductsTable>
			<CurrentAuxiliaryProductsTable
				auxiliaryCategory={constants.auxiliaryCategories.FREE_GIFT}
				checkBoxText="I do not have products that are automatically added to the cart"
				warningText="Action Needed: Confirm 'Free Gifts' setting to ensure precise order handling. Please select the applicable products or check 'I do not have products that are automatically added to the cart.'"
			>
				<Typography sx={titleStyles}>
					Products automatically added to the cart
				</Typography>
				<Typography>
					Do you have free gifts or any other products that get
					automatically attached to orders? If so, please select them
					below.
				</Typography>
				<Typography sx={disclaimerStyles}>
					*For accurate offer handling, Bazo requires correct
					identification of gifts and other products that are added
					automatically. Failure to do so may lead to offer
					discrepancies.
				</Typography>
			</CurrentAuxiliaryProductsTable>
		</>
	);
}

export default AuxiliaryProducts;
