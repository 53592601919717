export function toTitleCase(str) {
	return str.replace(
		/\b\w+/g,
		(s) => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase(),
	);
}

export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}
