import React from 'react';
import constants from '@app/constants';
import { useAuxiliaryCacheKey } from '@app/hooks/useAuxiliaryCacheKey';
import { useAuxiliaryProducts } from '@app/hooks/useProducts';

export const useFetchOnboardingAuxiliaryProducts = () => {
	const [selectedProtectionIds, setSelectedProtectionIds] =
		React.useState([]);
	const [selectedGiftsIds, setSelectedGiftsIds] = React.useState([]);

	const { ORDER_PROTECTION, FREE_GIFT } =
		constants.auxiliaryCategories;

	const { data: allAuxiliaryProducts } = useAuxiliaryProducts({
		cacheKey: useAuxiliaryCacheKey(
			`${ORDER_PROTECTION}|${FREE_GIFT}`,
		),
	});

	React.useEffect(() => {
		if (
			!allAuxiliaryProducts?.products ||
			!allAuxiliaryProducts.products.length
		) {
			return;
		}

		const orderProtectionProducts =
			allAuxiliaryProducts.products.filter(
				(product) => product.auxiliary_category === ORDER_PROTECTION,
			);
		const freeGiftProducts = allAuxiliaryProducts.products.filter(
			(product) => product.auxiliary_category === FREE_GIFT,
		);

		setSelectedProtectionIds(
			orderProtectionProducts.map((product) => product.id),
		);
		setSelectedGiftsIds(
			freeGiftProducts.map((product) => product.id),
		);
	}, [
		allAuxiliaryProducts,
		setSelectedProtectionIds,
		setSelectedGiftsIds,
		ORDER_PROTECTION,
		FREE_GIFT,
	]);

	return {
		selectedProtectionIds,
		setSelectedProtectionIds,
		selectedGiftsIds,
		setSelectedGiftsIds,
	};
};
