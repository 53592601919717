import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import { useMarketplaceProductsContext } from '@app/pages/marketplace/supplier/context/useMarketProductsContext';

function ProductCheckboxInput({ productId, variantsIds, disabled }) {
	const { updateProductEnabled, getVariantConfig } =
		useMarketplaceProductsContext();

	const enabled = variantsIds.every(
		(id) => getVariantConfig({ variantId: id, productId })?.enabled,
	);

	const indeterminate =
		!enabled &&
		variantsIds.some(
			(id) => getVariantConfig({ variantId: id, productId })?.enabled,
		);

	return (
		<Checkbox
			indeterminate={indeterminate}
			onClick={(event) => event.stopPropagation()}
			checked={enabled}
			onChange={(event) => {
				updateProductEnabled({
					productId,
					variantsIds,
					enabled: event.target.checked,
				});
			}}
			disabled={disabled}
		/>
	);
}

ProductCheckboxInput.propTypes = {
	productId: PropTypes.string.isRequired,
	variantsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
	disabled: PropTypes.bool,
};

ProductCheckboxInput.defaultProps = {
	disabled: false,
};

export default ProductCheckboxInput;
