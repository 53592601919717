import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';

// We want to have a prompt, label, helper text on most of our text inputs for clarity for users

function ConversationalInputWrapper({
	prompt,
	children,
	secondaryPrompt = '',
	helperText = '', // Prefer to use the TextField helperText instead for accessibility.  Use this only if the input doesn't feature helperText
	errorText = '',
	sx = {},
}) {
	return (
		<Box
			sx={{
				mb: 2,
				...sx,
			}}
		>
			<Typography fontWeight="bold" sx={{ mb: 1 }}>
				{prompt}
			</Typography>
			{secondaryPrompt && (
				<FormHelperText sx={{ mb: 1 }}>
					{secondaryPrompt}
				</FormHelperText>
			)}
			{children}
			{helperText && (
				<FormHelperText
					sx={{
						pl: 2, // Matches TextField helper text
						color: errorText ? 'error' : 'text.secondary',
					}}
				>
					{errorText || helperText}
				</FormHelperText>
			)}
		</Box>
	);
}

ConversationalInputWrapper.propTypes = {
	prompt: PropTypes.string.isRequired,
	secondaryPrompt: PropTypes.string,
	children: PropTypes.node.isRequired,
	helperText: PropTypes.string,
	errorText: PropTypes.string,
	sx: PropTypes.shape({}),
};

export default ConversationalInputWrapper;
