import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Table from '@app/components/shared/Table/Table';
import ProductThumbnail from '@app/components/shared/ui/ProductThumbnail';
import ProductCell from '@app/components/shared/Table/components/ProductCell';
import Loader from '@app/components/shared/loaders/CircularLoader';
import { useSortedAuxiliaryProducts } from '@app/hooks/useSortedAuxiliaryProducts';
import VariantsSearchInput from '@app/components/shared/variantsSearch/VariantsSearchInput';
import { getQueryKeysByWords } from '@app/helpers/shopifyQueryHelpers';

const formatProductData = (data, addProductColumn) => {
	if (data.length < 1) return data;

	return data.map((product) => {
		const { id, handle, title, sku } = product.node;

		const productImage = {
			src: product.node.featuredImage?.url,
		};
		const formattedProduct = {
			...product,
			handle,
		};

		return {
			id,
			checkbox: () => false,
			productImage: () => (
				<ProductThumbnail variantImage={productImage} />
			),
			productName: () => (
				<ProductCell
					product={formattedProduct}
					title={title}
					sku={sku}
				/>
			),
			addProduct: addProductColumn(product),
		};
	});
};

function AuxiliaryProductsTable({
	selectedAuxiliaryIds,
	setSelectedAuxiliaryIds,
	withBorder,
	showSelect,
	addProductColumn,
	queryKeysArray,
}) {
	const [page, setPage] = React.useState(0);

	const {
		resultsPerPage,
		onPrevious,
		onNext,
		hasPrevious,
		hasNext,
		isFetching,
		isLoading,
		products,
		searchTermDisplay,
		handleSearch,
		handleClear,
	} = useSortedAuxiliaryProducts({
		page,
		queryKeys: getQueryKeysByWords(queryKeysArray),
	});

	const formattedData = formatProductData(products, addProductColumn);

	if (isLoading) {
		return (
			<Container
				component="main"
				maxWidth="xl"
				sx={{ textAlign: 'center' }}
			>
				<Loader />
			</Container>
		);
	}

	return (
		<Table
			page={page}
			setPage={setPage}
			loading={isLoading || isFetching}
			data={formattedData}
			columns={[]}
			showSelect={showSelect}
			setSelected={setSelectedAuxiliaryIds}
			selected={selectedAuxiliaryIds}
			defaultRowsPerPage={resultsPerPage}
			staticHeight={false}
			hasPrevious={hasPrevious}
			hasNext={hasNext}
			onPrevious={onPrevious}
			withBorder={withBorder}
			onNext={onNext}
			containerStyle={{
				borderTopLeftRadius: 0,
				borderTopRightRadius: 0,
			}}
			toolbar={
				<Box>
					<Box
						sx={{
							marginTop: 1,
							marginBottom: 2,
						}}
					>
						<VariantsSearchInput
							searchTermDisplay={searchTermDisplay}
							handleSearch={handleSearch}
							handleClear={handleClear}
							placeholder="Filter list"
						/>
					</Box>
					{!products.length && (
						<Typography>No products found</Typography>
					)}
				</Box>
			}
		/>
	);
}

AuxiliaryProductsTable.propTypes = {
	selectedAuxiliaryIds: PropTypes.arrayOf(PropTypes.string)
		.isRequired,
	setSelectedAuxiliaryIds: PropTypes.func.isRequired,
	withBorder: PropTypes.bool,
	showSelect: PropTypes.bool,
	addProductColumn: PropTypes.func,
	queryKeysArray: PropTypes.arrayOf(PropTypes.string),
};

AuxiliaryProductsTable.defaultProps = {
	withBorder: true,
	showSelect: true,
	addProductColumn: () => null,
	queryKeysArray: ['free', 'order', 'gift', 'ship'],
};

export default AuxiliaryProductsTable;
