import React from 'react';
import PropTypes from 'prop-types';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { successHandler } from '@app/lib/SnackbarUtils';

function Code({ children }) {
	const theme = useTheme();

	const handleCopy = React.useCallback(() => {
		navigator.clipboard.writeText(children);
		successHandler();
	}, [children]);

	return (
		<pre
			style={{
				border: `1px solid ${theme.palette.text.disabled}`,
				padding: '20px',
				position: 'relative',
				borderRadius: '10px',
				backgroundColor: theme.palette.action.disabledBackground,
				overflow: 'auto',
			}}
		>
			<IconButton
				onClick={handleCopy}
				sx={{
					position: 'absolute',
					top: '20px',
					right: '20px',
					background: 'none',
				}}
			>
				<ContentCopyIcon />
			</IconButton>
			<code style={{ fontSize: 14 }}>{children}</code>
		</pre>
	);
}

Code.propTypes = {
	children: PropTypes.string.isRequired,
};

export default Code;
