import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

import useAuth from '@app/hooks/useAuth';
import constants from '@app/constants';

const listItemStyles = {
	padding: 0,
	marginLeft: '20px',
	listStyleType: 'disc',
	display: 'list-item',
	fontSize: 14,
};

const listTypographyStyles = {
	fontSize: 14,
};

const barSubTextStyles = {
	fontSize: 11,
};

function CounterPopoverText({ thresholdDiscount }) {
	const { formatMerchantCurrency } = useAuth();

	const formatValue = useCallback(
		(value) => formatMerchantCurrency(value).split('.')[0],
		[formatMerchantCurrency],
	);

	const progressBarValue =
		Math.floor((100 - thresholdDiscount) / 10) * 10;
	const formattedCurrentValue = formatValue(progressBarValue);

	const barMaxValue = formatValue(100);
	const barMinValue = formatValue(0);

	const leftMargin = progressBarValue - progressBarValue / 10;
	const rightMargin = progressBarValue + (progressBarValue / 10) * -1;

	return (
		<>
			<Typography
				variant="subtitle1"
				sx={{
					fontSize: 16,
					fontWeight: 600,
				}}
			>
				Example
			</Typography>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					marginTop: 1,
					marginBottom: 1,
				}}
			>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						position: 'relative',
					}}
				>
					<Typography sx={barSubTextStyles}>100%</Typography>
					{progressBarValue !== 0 && (
						<Typography
							variant="body2"
							sx={{
								...barSubTextStyles,
								position: 'absolute',
								left: `${
									progressBarValue > 50
										? leftMargin + 2
										: rightMargin + 4
								}%`,
							}}
						>
							{100 - progressBarValue}%
						</Typography>
					)}
					<Typography sx={{ ...barSubTextStyles, marginRight: 1.5 }}>
						0%
					</Typography>
				</Box>
				<LinearProgress
					variant="determinate"
					color="tertiary"
					value={progressBarValue}
					sx={{
						height: 15,
						borderRadius: 5,
						width: '87%',
						marginTop: 0.5,
						marginBottom: 0.5,
						alignSelf: 'center',
						backgroundColor: constants.colors.primary,
					}}
				/>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<Typography sx={{ ...barSubTextStyles, marginLeft: 1.5 }}>
						{barMinValue}
					</Typography>
					{progressBarValue !== 0 && (
						<Typography
							variant="body2"
							sx={{
								...barSubTextStyles,
								position: 'absolute',
								left: `${
									progressBarValue > 50
										? leftMargin + 1
										: rightMargin + 4.5
								}%`,
							}}
						>
							{formattedCurrentValue}
						</Typography>
					)}
					<Typography sx={barSubTextStyles}>{barMaxValue}</Typography>
				</Box>
				<List>
					<ListItemText
						primary={`If your item is priced at ${barMaxValue}`}
						primaryTypographyProps={listTypographyStyles}
					/>
					<ListItemText
						primary={`Any offers below ${formattedCurrentValue} will be automatically countered to ${formattedCurrentValue}`}
						primaryTypographyProps={listTypographyStyles}
						sx={listItemStyles}
					/>
					<ListItemText
						primary={`Any offers at or above ${formattedCurrentValue} will be accepted`}
						primaryTypographyProps={listTypographyStyles}
						sx={{
							...listItemStyles,
							marginBottom: -1.5,
						}}
					/>
				</List>
			</Box>
		</>
	);
}

CounterPopoverText.propTypes = {
	thresholdDiscount: PropTypes.number.isRequired,
};

export default CounterPopoverText;
