import * as React from 'react';
import PropTypes from 'prop-types';

import SelectInput from '@app/components/shared/inputs/SelectInput';

const propTypes = {
	activeShop: PropTypes.string,
	handleChange: PropTypes.func.isRequired,
	selectOptions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		}),
	).isRequired,
};

const defaultProps = {
	activeShop: '',
};

function SelectShopForm({ activeShop, handleChange, selectOptions }) {
	return (
		<SelectInput
			name="activeShop"
			value={activeShop}
			label="Select a shop to login"
			handleChange={handleChange}
			options={selectOptions}
			inputLabelText="Select a shop to login"
			fullWidth
		/>
	);
}

SelectShopForm.propTypes = propTypes;
SelectShopForm.defaultProps = defaultProps;

export default SelectShopForm;
