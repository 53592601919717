import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import EllipsesTypography from '../../ui/EllipsesTypography';
import { GA_LABEL } from '../../../../constants/googleAnalytics';

function ProductCell({ product, title, sku, link }) {
	const { handle } = product;
	const titleText = title !== 'Default Title' ? title : '';

	const EllipsesContent = (
		<EllipsesTypography
			data-ga-label={GA_LABEL.PRODUCT_TITLE}
			lines={2}
			variant="subtitle2"
			sx={{ fontSize: '16px' }}
		>
			{handle}
		</EllipsesTypography>
	);

	return (
		<Stack spacing={0.25}>
			{link ? (
				<Link
					href={link}
					target="_blank"
					rel="noopener noreferrer"
					title="View variant in Shopify Admin"
				>
					{EllipsesContent}
				</Link>
			) : (
				EllipsesContent
			)}
			{titleText && (
				<Typography variant="subtitle1" sx={{ fontSize: '14px' }}>
					{titleText}
				</Typography>
			)}
			{sku && (
				<Typography
					color="#666666"
					sx={{ fontSize: '14px' }}
				>{`#${sku}`}</Typography>
			)}
		</Stack>
	);
}

ProductCell.propTypes = {
	product: PropTypes.shape({
		handle: PropTypes.string,
	}).isRequired,
	title: PropTypes.string.isRequired,
	sku: PropTypes.string,
	link: PropTypes.string,
};

ProductCell.defaultProps = {
	sku: '',
	link: '',
};

export default ProductCell;
