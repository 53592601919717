import React from 'react';

import { useNavigate } from 'react-router-dom';
import LoginSignUpContainer from '../../components/shared/ui/LoginSignupContainer';
import LoginForm from './LoginForm';

import useAuth from '../../hooks/useAuth';
import useForm from '../../hooks/useForm';
import { GAevent } from '../../lib/googleAnalytics';
import ProfilerProvider from '../../providers/ProfilerProvider';
import {
	eventCategories,
	events,
	transport,
} from '../../constants/googleAnalytics';

export default function SignIn() {
	const navigate = useNavigate();
	const {
		login,
		loading,
		error,
		refreshUser,
		user: loggedUser,
	} = useAuth();

	// Here we refresh user with using session token to make sure it is either authorized or not. In case user is unauthorized
	// auth context changes appropriately to hide some of the menu elements. If authorized, user gets redirected back to main page
	React.useEffect(() => {
		refreshUser();
	}, [refreshUser]);

	const {
		handleSubmit,
		handleChange,
		data: user,
		errors,
	} = useForm({
		initialValues: {
			email: '',
			password: '',
		},
		validations: {
			email: {
				required: {
					value: true,
					message: 'Please provide your username.',
				},
				pattern: {
					value: /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
					message: 'You must provide a valid email address',
				},
			},
			password: {
				required: {
					value: true,
					message: 'Please provide a password.',
				},
				custom: {
					isValid: (value) => value.length >= 8 && value.length < 64,
					message:
						'Password must be between 8 and 64 characters long',
				},
			},
		},
		onSubmit: async () => {
			GAevent({
				categoryName: eventCategories.LOGIN,
				eventName: events.SUBMIT,
				transport: transport.BEACON,
			});

			await login({
				username: user?.email,
				password: user?.password,
			});
		},
	});

	React.useEffect(() => {
		if (loggedUser) {
			navigate('/');
		}
	}, [loggedUser, navigate]);

	return (
		<ProfilerProvider id="Login">
			<LoginSignUpContainer headerText="Login">
				<LoginForm
					onSubmit={handleSubmit}
					onChange={handleChange}
					values={user}
					errors={errors}
					isLoading={loading}
					fetchError={
						error?.message
							? { message: 'Invalid username or password' }
							: null
					}
					disabled={
						!user.email ||
						user?.password.length < 8 ||
						user?.password.length > 64
					}
				/>
			</LoginSignUpContainer>
		</ProfilerProvider>
	);
}
