import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../modal/Modal';
import RecreateAction from '@app/pages/nyopCampaign/actions/RecreateAction';
import Button from '../../button/Button';

function RecreateNyopButton({
	campaignId,
	buttonText,
	size,
	disabled,
	onSuccess,
}) {
	const [modalVisible, setModalVisible] = React.useState(false);

	return (
		<>
			<ConfirmationModal
				open={modalVisible}
				handleModal={setModalVisible}
			>
				<RecreateAction
					campaignId={campaignId}
					onSuccess={() => {
						if (onSuccess) {
							onSuccess();
						}
						setModalVisible(false);
					}}
				/>
			</ConfirmationModal>
			<Button
				disabled={disabled}
				variant="outlined"
				color="info"
				size={size}
				sx={{ width: '100%' }}
				onClick={() => setModalVisible(true)}
			>
				{buttonText}
			</Button>
		</>
	);
}

RecreateNyopButton.propTypes = {
	campaignId: PropTypes.string.isRequired,
	buttonText: PropTypes.string,
	size: PropTypes.string,
	disabled: PropTypes.bool,
	onSuccess: PropTypes.func,
};

RecreateNyopButton.defaultProps = {
	buttonText: 'Restart',
	size: 'medium',
	disabled: false,
	onSuccess: null,
};

export default RecreateNyopButton;
