export function createAppEmbedDeepLink({
	uuid,
	template,
	myshopifyDomain,
	handle,
}) {
	return `https://${myshopifyDomain}/admin/themes/current/editor?context=apps&template=${template}&activateAppId=${uuid}/${handle}`;
}

export function createAppBlockDeepLink({
	uuid,
	myshopifyDomain,
	handle,
	template,
	target,
}) {
	return `https://${myshopifyDomain}/admin/themes/current/editor?template=${template}&addAppBlockId=${uuid}/${handle}&target=${target}`;
}

export function addAdditionalScriptsDeepLink({ myshopifyDomain }) {
	return `https://${myshopifyDomain}/admin/settings/checkout#additionalscripts`;
}

export function previewAppBlockDeepLink({
	uuid,
	myshopifyDomain,
	template,
	target,
}) {
	// TODO: This block parameter doesn't work, and there doesn't seem to be a way to make it work at the moment. Change when it's possible
	return `https://${myshopifyDomain}/admin/themes/current/editor?template=${template}&target=${target}&block=${uuid}`;
}
