import React from 'react';
import PropTypes from 'prop-types';
import { useMarketplaceProductsContext } from '@app/pages/marketplace/supplier/context/useMarketProductsContext';
import InputField from '@app/pages/marketplace/supplier/table/components/InputField';

function VariantThresholdInput({ productId, variantId }) {
	const { updateVariantThreshold, getVariantConfig } =
		useMarketplaceProductsContext();

	const config = getVariantConfig({ productId, variantId });

	return (
		<InputField
			value={config.threshold}
			endAdornment="%"
			inherited={!config.variantId}
			handleChange={(newValue) => {
				updateVariantThreshold({
					variantId,
					productId,
					threshold: newValue,
				});
			}}
		/>
	);
}

VariantThresholdInput.propTypes = {
	productId: PropTypes.string.isRequired,
	variantId: PropTypes.string.isRequired,
};

export default VariantThresholdInput;
