import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
	useProductSync,
	useStartProductSync,
} from '@app/hooks/useMerchant';
import { formatReadableDateTime } from '@app/helpers/dateHelpers';
import InfoPopoverIcon from '@app/components/shared/ui/InfoPopoverIcon';
import Button from '@app/components/shared/button/Button';

function ProductSyncSetting() {
	const [isPolling, setIsPolling] = useState(false);

	const { data, refetch, isLoading } = useProductSync({
		configOpts: {
			enabled: isPolling,
			refetchInterval: isPolling ? 5000 : false, // Only poll if polling is enabled
		},
	});

	const {
		mutate: startSync,
		data: startSyncResponse,
		isLoading: isStartSyncRequestLoading,
		error,
	} = useStartProductSync({
		configOpts: {},
	});

	useEffect(() => {
		// Check the status of the current sync job
		const productSync = data?.data?.productSync;
		if (
			productSync &&
			!productSync.completed_at &&
			!productSync.failed_at
		) {
			setIsPolling(true); // Start polling if a sync is in progress
		} else {
			setIsPolling(false); // Stop polling if sync is complete or failed
		}
	}, [data]);

	useEffect(
		() =>
			// Stop polling when the component unmounts
			() =>
				setIsPolling(false),
		[],
	);

	let productSync = data?.data?.productSync;
	if (
		!productSync ||
		(startSyncResponse?.data?.productSync &&
			productSync.id !== startSyncResponse.data.productSync.id)
	) {
		productSync = startSyncResponse?.data?.productSync;
	}

	const productSyncInProgress =
		isStartSyncRequestLoading ||
		(productSync &&
			!productSync.completed_at &&
			!productSync.failed_at);

	let lastSyncMessage = 'Unknown';
	if (productSync?.completed_at) {
		lastSyncMessage = `completed at ${formatReadableDateTime(
			productSync.completed_at,
		)}`;
	} else if (productSync?.failed_at) {
		lastSyncMessage = `failed at ${formatReadableDateTime(
			productSync.failed_at,
		)}`;
	} else if (productSync) {
		lastSyncMessage = `still in progress, sync started at ${formatReadableDateTime(
			productSync.started_at,
		)}`;
	}

	const handleSync = () => {
		startSync();
		refetch(); // Fetch immediately after starting sync to get the latest status
		setIsPolling(true); // Start polling after kicking off the sync
	};

	return (
		<Box>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
				<Typography
					sx={{
						fontSize: '1.25rem',
						fontWeight: 500,
					}}
				>
					Inventory Sync
				</Typography>
				<InfoPopoverIcon text="Products and Collections sync process might take about 10 minutes. During that time you are not recommended to create or edit campaigns" />
			</Box>

			<Grid
				container
				columnSpacing={5}
				sx={{
					alignItems: 'flex-start',
					mt: 2,
				}}
			>
				<Grid item>
					<Typography
						sx={{
							mt: 1,
							mb: 3,
							color: 'text.secondary',
							fontSize: 14,
						}}
					>
						Last sync: {lastSyncMessage}
					</Typography>
				</Grid>
				<Grid item>
					<Button
						onClick={handleSync}
						loadingIndicator="Syncing..."
						loading={isLoading || productSyncInProgress}
						disabled={!!error}
					>
						Sync
					</Button>
				</Grid>
			</Grid>
			{error ? (
				<Typography
					sx={{
						mt: 1,
						mb: 3,
						color: 'error.main',
						fontSize: 14,
					}}
				>
					{error}
				</Typography>
			) : null}
		</Box>
	);
}

export default ProductSyncSetting;
