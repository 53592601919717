import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Table from '@app/components/shared/Table/Table';
import ProductThumbnail from '@app/components/shared/ui/ProductThumbnail';
import ProductCell from '@app/components/shared/Table/components/ProductCell';
import Loader from '@app/components/shared/loaders/CircularLoader';
import VariantsSearchInput from '@app/components/shared/variantsSearch/VariantsSearchInput';
import Modal from '@app/components/shared/modal/Modal';
import campaignModalStyles from '@app/styles/campaignModalStyles';
import {
	useAuxiliaryProducts,
	useRemoveAuxiliary,
} from '@app/hooks/useProducts';
import AddAuxiliaryProductsModal from './AddAuxiliaryProductsModal';
import { usePagination } from '@app/hooks/usePagination';
import AuxiliaryCheckBox from './AuxiliaryCheckBox';
import { useAuxiliaryCacheKey } from '@app/hooks/useAuxiliaryCacheKey';
import AuxiliaryWarning from './AuxiliaryWarning';

const formatProductData = (
	data,
	handleRemoveAuxiliary,
	isRemoveLoading,
) => {
	if (data.length < 1) return data;

	return data.map((product) => {
		const { id, title, sku, image_url: imageUrl } = product;

		const productImage = {
			src: imageUrl,
		};

		return {
			id,
			checkbox: () => false,
			productImage: () => (
				<ProductThumbnail variantImage={productImage} />
			),
			productName: () => (
				<ProductCell product={product} title={title} sku={sku} />
			),
			deleteProduct: () => (
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						justifyContent: 'flex-end',
						paddingRight: 2,
					}}
				>
					<IconButton
						disabled={isRemoveLoading}
						onClick={() => handleRemoveAuxiliary(id)}
					>
						<ClearIcon />
					</IconButton>
				</Box>
			),
		};
	});
};

function CurrentAuxiliaryProductsTable({
	auxiliaryCategory,
	checkBoxText,
	warningText,
	children,
}) {
	const [addMoreProductsModal, setAddMoreProductsModal] =
		React.useState(false);
	const [initialProducts, setInitialProducts] = React.useState([]);
	const [displayProducts, setDisplayProducts] = React.useState([]);
	const [searchTermDisplay, setSearchTermDisplay] =
		React.useState('');
	const [checkBoxValue, setCheckBoxValue] = React.useState(false);

	const { data, isLoading, isFetching } = useAuxiliaryProducts({
		auxiliaryCategory,
		cacheKey: useAuxiliaryCacheKey(auxiliaryCategory),
	});

	const resultsPerPage = 20;
	const {
		page,
		setPage,
		dataWithPagination,
		hasNext,
		hasPrevious,
		onNext,
		onPrevious,
	} = usePagination({ data: displayProducts, resultsPerPage });

	React.useEffect(() => {
		if (data?.products) {
			setInitialProducts(data?.products);
		}
	}, [data?.products]);

	React.useEffect(() => {
		if (searchTermDisplay.length === 0) {
			setDisplayProducts(initialProducts);
			return;
		}

		setDisplayProducts(
			initialProducts.filter((product) =>
				product.title
					.toLowerCase()
					.includes(searchTermDisplay.toLocaleLowerCase()),
			),
		);
	}, [searchTermDisplay, initialProducts]);

	const handleSearch = React.useCallback((event) => {
		const { value } = event.target;
		setSearchTermDisplay(value);
	}, []);

	const handleClear = React.useCallback(() => {
		setSearchTermDisplay('');
	}, []);

	const { mutate, isLoading: isRemoveLoading } = useRemoveAuxiliary({
		cacheKey: useAuxiliaryCacheKey(auxiliaryCategory),
		configOpts: {
			meta: {
				toastDisabled: true,
			},
		},
	});

	const handleRemoveAuxiliary = React.useCallback(
		async (productId) => {
			mutate({
				productIds: [productId],
				auxiliaryCategory,
			});
		},
		[mutate, auxiliaryCategory],
	);

	const formattedData = formatProductData(
		dataWithPagination,
		handleRemoveAuxiliary,
		isRemoveLoading,
	);

	if (isLoading) {
		return (
			<Container
				component="main"
				maxWidth="xl"
				sx={{ textAlign: 'center' }}
			>
				<Loader />
			</Container>
		);
	}

	return (
		<>
			<Table
				page={page}
				setPage={setPage}
				loading={isLoading || isFetching}
				data={formattedData}
				columns={[]}
				selected={[]}
				setSelected={() => null}
				defaultRowsPerPage={resultsPerPage}
				staticHeight={false}
				withBorder={false}
				toolbar={
					<Box>
						{!initialProducts.length && !checkBoxValue && (
							<AuxiliaryWarning text={warningText} sx={{ mb: 2 }} />
						)}
						{children}
						<Box
							sx={{
								marginBottom: 4,
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<VariantsSearchInput
								searchTermDisplay={searchTermDisplay}
								handleSearch={handleSearch}
								handleClear={handleClear}
								placeholder="Filter list"
							/>
							<Modal
								title="Add products"
								buttonText="Add products"
								contentStyle={campaignModalStyles}
								handleModal={setAddMoreProductsModal}
								open={addMoreProductsModal}
							>
								<AddAuxiliaryProductsModal
									displayProducts={displayProducts}
									setInitialProducts={setInitialProducts}
									onClose={() => setAddMoreProductsModal(false)}
									auxiliaryCategory={auxiliaryCategory}
								/>
							</Modal>
						</Box>
						{!displayProducts.length && (
							<Typography>No products found</Typography>
						)}
					</Box>
				}
				hasPrevious={hasPrevious}
				hasNext={hasNext}
				onNext={onNext}
				onPrevious={onPrevious}
				containerStyle={{
					marginBottom: 2,
					width: '100%',
				}}
			/>
			<AuxiliaryCheckBox
				text={checkBoxText}
				areAnyProductsSelected={!!displayProducts.length}
				auxiliaryCategory={auxiliaryCategory}
				checkBoxValue={checkBoxValue}
				setCheckBoxValue={setCheckBoxValue}
			/>
		</>
	);
}

CurrentAuxiliaryProductsTable.propTypes = {
	auxiliaryCategory: PropTypes.string,
	checkBoxText: PropTypes.string.isRequired,
	warningText: PropTypes.string.isRequired,
	children: PropTypes.node,
};

CurrentAuxiliaryProductsTable.defaultProps = {
	auxiliaryCategory: null,
	children: null,
};

export default CurrentAuxiliaryProductsTable;
