import * as React from 'react';
import { Outlet } from 'react-router-dom';

import MuiDrawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';

import NavListItems from './SidebarNav';
import BazoLogo from '../shared/ui/BazoLogo';
import AccountButton from './AccountButton';
import BreadcrumbNav from './BreadcrumbNav';
import Copyright from '../shared/ui/Copyright';
import CookieConsentBanner from './CookieConsent';
import SelectInput from '../shared/inputs/SelectInput';

import useAuth from '../../hooks/useAuth';

import {
	campaignRoutes,
	mainRoutes,
	marketplaceRoutes,
	settingsRoutes,
} from './navConfig';
import { useDrawerOpen } from './hooks/useDrawerOpen';
import Warnings from './Warnings';
import SubscriptionNavItem from './components/subscriptions/SubscriptionNavItem';
import constants from '@app/constants';
import RequiresFlag from '../shared/flags/RequiresFlag';
import { FLAGS } from '@app/hooks/useFlags';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + constants.zIndex.drawer,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

export function DashboardContent() {
	const { user, merchant, onActiveShopChange } = useAuth();

	const activeMerchants = user?.merchants.filter((m) => m.active);

	const [activeShop, setActiveShop] = React.useState(
		merchant?.id || '',
	);

	const { open, toggleDrawer, isOpenDrawerButtonHidden } =
		useDrawerOpen();

	React.useEffect(() => {
		setActiveShop(merchant?.id || '');
	}, [merchant]);

	const handleActiveShopChange = async (event) => {
		const { value: merchantId } = event.target;
		await onActiveShopChange({ merchantId });
	};

	const sideBarAvailable = !!(merchant && user);

	return (
		<Box sx={{ display: 'flex' }}>
			<AppBar position="fixed" open={open}>
				<Toolbar
					sx={{
						pr: '24px', // keep right padding when drawer closed
					}}
				>
					{sideBarAvailable && (
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={toggleDrawer}
							sx={{
								marginRight: '36px',
								...(isOpenDrawerButtonHidden && { display: 'none' }),
							}}
						>
							<MenuIcon />
						</IconButton>
					)}
					<Box sx={{ flexGrow: 1, display: 'inline-flex' }}>
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							mr={2}
						>
							<BazoLogo
								width="100px"
								sx={{
									display: { xs: 'none', md: 'block' },
								}}
							/>
						</Box>
						{activeMerchants && activeMerchants.length > 1 && (
							<SelectInput
								name="activeShop"
								value={activeShop}
								handleChange={handleActiveShopChange}
								sx={{ backgroundColor: 'white' }}
								fullWidth={false}
								StartAdornmentIcon={StorefrontIcon}
								options={activeMerchants.map((m) => ({
									label: m.name,
									value: m.id,
								}))}
							/>
						)}
					</Box>
					{user && merchant?.name && merchant?.myshopify_domain && (
						<Box display="flex" gap={1.3} sx={{ mt: 0.8 }}>
							<Tooltip title="Shopify Admin">
								<Link
									color="inherit"
									href={`https://${merchant?.myshopify_domain}/admin`}
									target="_blank"
								>
									<AdminPanelSettingsIcon />
								</Link>
							</Tooltip>
							<Tooltip title={merchant?.myshopify_domain}>
								<Link
									color="inherit"
									href={`https://${merchant?.myshopify_domain}`}
									target="_blank"
								>
									<ShoppingBagIcon />
								</Link>
							</Tooltip>
						</Box>
					)}
					<AccountButton />
				</Toolbar>
			</AppBar>
			{sideBarAvailable && (
				<Drawer variant="permanent" open={open}>
					<Toolbar
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
							px: [1],
						}}
					>
						<IconButton onClick={toggleDrawer}>
							<ChevronLeftIcon />
						</IconButton>
					</Toolbar>
					<Divider />
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="space-between"
						height="100%"
						paddingBottom="5px"
					>
						<List component="nav">
							<NavListItems
								routes={mainRoutes}
								subheader="Configure"
							/>
							<RequiresFlag flag={FLAGS.MARKETPLACE_AVAILABLE}>
								<Divider sx={{ my: 1 }} />
								<NavListItems
									routes={marketplaceRoutes}
									subheader="Marketplace"
								/>
							</RequiresFlag>
							<Divider sx={{ my: 1 }} />
							<NavListItems
								subheader="Campaigns"
								routes={campaignRoutes}
							/>
							<Divider sx={{ my: 1 }} />
							<NavListItems routes={settingsRoutes} subheader="" />
						</List>
						<SubscriptionNavItem />
					</Box>
				</Drawer>
			)}
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					minHeight: '100vh',
				}}
			>
				<Toolbar />
				<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
					<Box mb={2}>
						<BreadcrumbNav />
					</Box>
					<Outlet />
					<Copyright />
				</Container>
			</Box>
			<CookieConsentBanner />
			<Warnings />
		</Box>
	);
}

export default DashboardContent;
