import axios from './index';

import * as urlHelpers from '../helpers/urlHelpers';

const apiUrl = '/api/products';

export async function getProducts({
	page,
	perPage,
	sortKey,
	status,
	inventoryKind,
	searchTerm,
	collectionId,
	ageRate,
	valueRate,
	marketplaceFilterEnabled,
}) {
	const queryString = urlHelpers.cleanAndBuildUrlQueryParams({
		page,
		perPage,
		sortKey,
		status,
		inventoryKind,
		searchTerm,
		collectionId,
		ageRate,
		valueRate,
		marketplaceFilterEnabled,
	});

	const response = await axios.get(`${apiUrl}?${queryString}`);
	return response.data;
}

export async function getProductsByIds(productIds) {
	const response = await axios.post(`${apiUrl}/where`, {
		ids: productIds,
	});
	return response.data;
}

export async function getProductsForPriorityChart({
	status,
	inventoryKind,
	searchTerm,
	collectionId,
	valueRate,
	ageRate,
}) {
	const queryString = urlHelpers.cleanAndBuildUrlQueryParams({
		status,
		inventoryKind,
		searchTerm,
		collectionId,
		valueRate,
		ageRate,
	});

	const response = await axios.get(
		`${apiUrl}/priorities?${queryString}`,
	);
	return response.data;
}

export async function getShopifyProducts({
	first,
	after,
	last,
	before,
	sortKey,
	searchTerm,
}) {
	const queryString = urlHelpers.cleanAndBuildUrlQueryParams({
		first,
		after,
		last,
		before,
		sortKey,
		searchTerm,
	});

	const response = await axios.get(
		`${apiUrl}/shopify?${queryString}`,
	);
	return response.data;
}

export async function getAuxiliaryProducts({ auxiliaryCategory }) {
	const queryString = urlHelpers.cleanAndBuildUrlQueryParams({
		category: auxiliaryCategory,
	});

	const response = await axios.get(
		`${apiUrl}/auxiliary?${queryString}`,
	);
	return response.data;
}

export async function makeProductsAuxiliary({
	productIds,
	auxiliaryCategory,
	removeAuxiliaryFromOtherProducts,
}) {
	const response = await axios.patch(`${apiUrl}/make-auxiliary`, {
		productIds,
		auxiliaryCategory,
		removeAuxiliaryFromOtherProducts,
	});
	return response.data;
}

export async function removeAuxiliary({
	productIds,
	auxiliaryCategory,
}) {
	const response = await axios.patch(`${apiUrl}/remove-auxiliary`, {
		productIds,
		auxiliaryCategory,
	});
	return response.data;
}

export async function getProductsTags() {
	const response = await axios.get(`${apiUrl}/tags`);
	return response.data;
}
