import {
	differenceInDays,
	differenceInHours,
	getUnixTime,
	parseISO,
} from 'date-fns';
import {
	addDaysToDate,
	formatReadableDate,
	isDate,
} from '../../../../helpers/dateHelpers';
import constants from '../../../../constants';

export const validationConfig = {
	description: {
		required: {
			value: true,
			message:
				'You must provide a description for your Campaign. It will help you identify and manage this Campaign later.',
		},
	},
	deposit: {
		// required but cannot easily evaluate with 0 as option
		custom: {
			isValid: (value) =>
				typeof value === 'number' &&
				Object.values(
					constants.deferredPurchaseOptions.depositValue,
				).indexOf(value) > -1,
			message: 'Deposit must be one of the available options',
		},
	},
	remainingBalanceChargeDate: {
		required: {
			value: true,
			message:
				'You must set a fulfillment date.  Customers will be charged and items will be fulfilled on this date',
		},
		custom: {
			isValid: (value) => isDate(value),
			message: 'Please select a valid date (MM-DD-YYYY)',
		},
	},
	fulfillment: {
		required: {
			value: true,
			message:
				'You must select an Inventory Policy. It tells Shopify when to decrement inventory.',
		},
		custom: {
			isValid: (value) =>
				value &&
				Object.values(
					constants.deferredPurchaseOptions
						.sellingPlanFulfillmentTrigger,
				).indexOf(value) > -1,
			message: 'Fulfillment must be one of the available options.',
		},
	},
	variants: {
		required: {
			value: true,
			message: 'You must add variants to the Campaign.',
		},
		pattern: {
			value: /^[\d|,]+$/,
			message:
				'Your variant Ids should be comma separated, numeric values with no spaces.',
		},
		custom: {
			isValid: (value) => {
				const arrFrom = value.split(',');
				const hasDuplicates =
					new Set(arrFrom).size !== arrFrom.length;

				return value.slice(-1) !== ',' && !hasDuplicates;
			},
			message: 'Please remove duplicates or trailing comma.',
		},
	},
};

export const validateNyopDates = (startDate, endDate) => {
	const stDate =
		typeof startDate === 'string' ? parseISO(startDate) : startDate;
	const enDate =
		typeof endDate === 'string' ? parseISO(endDate) : endDate;
	const dateValidation = {
		startDate: {
			isValid: true,
			errorMessage: '',
		},
		endDate: {
			isValid: true,
			errorMessage: '',
		},
	};

	if (!isDate(stDate)) {
		dateValidation.startDate = {
			isValid: false,
			errorMessage: 'Invalid date',
		};
	}

	if (!isDate(enDate)) {
		dateValidation.endDate = {
			isValid: false,
			errorMessage: 'Invalid date',
		};
	}

	if (getUnixTime(enDate) < getUnixTime(stDate)) {
		dateValidation.endDate = {
			isValid: false,
			errorMessage: 'End date cannot be before start date',
		};
	}

	return dateValidation;
};

export function getNyopEndMaxDate(action, startDate, endDate) {
	const stDate =
		typeof startDate === 'string' ? parseISO(startDate) : startDate;
	const enDate =
		typeof endDate === 'string' ? parseISO(endDate) : endDate;

	if (action === 'update' && stDate < new Date()) {
		return enDate;
	}
	return addDaysToDate(
		stDate,
		constants.nyopTiming.maxCampaignDuration,
	);
}

export function getNyopDuration(startDate, endDate) {
	const stDate =
		typeof startDate === 'string' ? parseISO(startDate) : startDate;
	const enDate =
		typeof endDate === 'string' ? parseISO(endDate) : endDate;
	const days = differenceInDays(enDate, stDate);
	const hours = differenceInHours(enDate, stDate) - days * 24;

	const daysDuration =
		days !== 0
			? `${Math.abs(days)} ${Math.abs(days) !== 1 ? 'days' : 'day'}`
			: '';
	const hourDuration =
		hours !== 0 && Math.abs(days) <= 3
			? `${Math.abs(hours)} ${
					Math.abs(hours) !== 1 ? 'hours' : 'hour'
			  }`
			: '';

	const duration = `${daysDuration} ${hourDuration}`;

	return {
		days,
		hours,
		duration,
		ended: stDate > enDate,
	};
}

export function formatDescription({ date, deposit }) {
	const formattedDate = formatReadableDate(date);

	const messages = {
		[constants.deferredPurchaseOptions.depositValue.NoDeposit.toString()]: `Pay later when the order is shipped by ${formattedDate}.`,
		[constants.deferredPurchaseOptions.depositValue.Deposit25.toString()]: `Pay only 25% today and the rest when order ships by ${formattedDate}.`,
		[constants.deferredPurchaseOptions.depositValue.Deposit50.toString()]: `Pay only 50% today and the rest when order ships by ${formattedDate}.`,
		[constants.deferredPurchaseOptions.depositValue.Deposit75.toString()]: `Pay only 75% today and the rest when order ships by ${formattedDate}.`,
		[constants.deferredPurchaseOptions.depositValue.Deposit100.toString()]: `Pay in full today and the order will ship by ${formattedDate}.`,
	};

	return messages[deposit.toString()];
}

export function formatNyopDescription({
	chargeAfter,
	currencySymbol,
	automationGreatestInterval,
}) {
	if (automationGreatestInterval) {
		if (automationGreatestInterval === 1) {
			return `We will respond to your offer within an hour. Pay ${currencySymbol}0.00 today!`;
		}
		return `We will respond to your offer within ${automationGreatestInterval} hours. Pay ${currencySymbol}0.00 today!`;
	}
	if (chargeAfter === 1) {
		return `We will respond to your offer within 24 hours. Pay ${currencySymbol}0.00 today!`;
	}
	return `We will respond to your offer within ${chargeAfter} days. Pay ${currencySymbol}0.00 today!`;
}

export function formatValues({
	id,
	sellingPlanId,
	description,
	deposit,
	remainingBalanceChargeDate,
	fulfillment,
	inventory,
	variants,
}) {
	const floatDeposit = parseFloat(deposit);
	const remainingBalanceChargeTrigger =
		floatDeposit === 100
			? constants.deferredPurchaseOptions
					.sellingPlanRemainingBalanceChargeTrigger.NoRemainingBalance
			: constants.deferredPurchaseOptions
					.sellingPlanRemainingBalanceChargeTrigger.ExactTime;
	const remainingBalanceChargeTime = new Date(
		remainingBalanceChargeDate,
	).setHours(0, 0, 0, 0); // Set all to midnight in their time zone
	const productVariantIds = variants
		.split(',')
		.map((vId) => `gid://shopify/ProductVariant/${vId.trim()}`);

	return {
		id: id || null,
		sellingPlanId: sellingPlanId || null,
		productVariantIds,
		sellingPlanGroupName: formatDescription({
			date: remainingBalanceChargeDate,
			deposit,
		}),
		remainingBalanceChargeTrigger,
		remainingBalanceChargeTime: new Date(remainingBalanceChargeTime),
		deposit: floatDeposit,
		fulfillmentTrigger: fulfillment,
		inventoryReservation: inventory,
		sellingPlanDescription: description,
	};
}

export function formatNyopValues({
	id,
	sellingPlanId,
	description,
	extendChargeDateWithCounterDelay,
	variants,
	startDate,
	endDate,
	sellingPlanGroupName,
	customerSegmentationVisibility,
	startingOfferInfo,
}) {
	const productVariantIds = variants
		.split(',')
		.map((vId) => `gid://shopify/ProductVariant/${vId.trim()}`);

	const correctedStartingOfferInfo = { ...startingOfferInfo };
	Object.entries(correctedStartingOfferInfo).forEach(
		([key, value]) => {
			if (!value || !Number(value)) {
				correctedStartingOfferInfo[key] = null;
				return;
			}
			const newValue = +value / 100;
			correctedStartingOfferInfo[key] = +newValue.toFixed(2);
		},
	);

	return {
		id: id || null,
		sellingPlanId: sellingPlanId || null,
		productVariantIds,
		sellingPlanGroupName,
		extendChargeDateWithCounterDelay,
		deposit: 0,
		sellingPlanDescription: description,
		startDate,
		endDate,
		customerSegmentationVisibility,
		startingOfferInfo: correctedStartingOfferInfo,
	};
}

export const variantGqlToNumericIdString = (variantsArr) => {
	const gqlPrefix = 'gid://shopify/ProductVariant/';
	if (!variantsArr) return '';
	return variantsArr.map((id) => id.replace(gqlPrefix, '')).join(',');
};
