import * as React from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import SellingPlanGroupForm from '../../components/shared/form/SellingPlanGroupForm/SellingGroupUpdate';
import VariantsTable from '../../components/shared/Table/VariantTable';
import Loader from '../../components/shared/loaders/CircularLoader';

import { useSellingPlanGroup } from '../../hooks/useSellingPlanGroups';

function PreorderCampaignDetailsPage() {
	const { id: sellingPlanGroupId } = useParams();
	const {
		data: preorderCampaign,
		isLoading: preorderLoading,
		isFetching: preorderFetching,
	} = useSellingPlanGroup({
		id: sellingPlanGroupId,
	});

	if (preorderLoading) {
		return <Loader fullPage />;
	}

	return (
		<Grid container spacing={2}>
			<Grid md={4} item>
				<Paper sx={{ p: 1 }}>
					<SellingPlanGroupForm
						sellingPlanGroup={preorderCampaign?.data}
						sellingPlanId={preorderCampaign?.data?.selling_plan_ids?.find(
							() => true,
						)}
						description={preorderCampaign?.data?.description}
						deposit={preorderCampaign?.data?.deposit}
						remainingBalanceChargeDate={
							preorderCampaign?.data?.remaining_balance_charge_date
						}
						fulfillment={preorderCampaign?.data?.fulfillment_trigger}
						inventory={preorderCampaign?.data?.inventory_reservation}
						variants={preorderCampaign?.data?.variants}
						name={preorderCampaign?.data?.name}
					/>
				</Paper>
			</Grid>
			<Grid item md={8} xs={12}>
				<VariantsTable
					variants={preorderCampaign?.variants}
					isFetching={preorderFetching}
				/>
			</Grid>
		</Grid>
	);
}

export default PreorderCampaignDetailsPage;
