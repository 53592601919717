import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Link } from '@mui/material';
import { formatISOTimestamp } from '@app/helpers/dateHelpers';

import { OrderType } from '@app/propTypes/types';
import { orderCancellationInProgress } from '@app/pages/customerPortalNyop/helpers';

function CancellationNotice({ order }) {
	const theme = useTheme();
	const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

	if (!order.cancelled_at && !order.cancellation_started_at) {
		return null;
	}

	if (order.cancelled_at) {
		return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					border: `1px solid ${theme.palette.subdued.main}`,
					p: 2,
					mb: 1,
				}}
			>
				<CancelOutlinedIcon color="text.secondary" sx={{ mr: 1 }} />
				{isSmDown ? (
					<Typography variant="h5">Order Cancelled</Typography>
				) : (
					<Box>
						<Typography variant="h6">
							Your order has been cancelled.
						</Typography>
						<Typography variant="subtitle1">
							Your order was cancelled on{' '}
							{formatISOTimestamp(
								order?.cancelled_at,
								order.customer_locale,
								{
									month: 'long',
									day: 'numeric',
								},
							)}
						</Typography>
					</Box>
				)}
			</Box>
		);
	}
	if (order.cancellation_failed_at) {
		return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					border: `1px solid ${theme.palette.subdued.main}`,
					p: 2,
					mb: 1,
				}}
			>
				<CancelOutlinedIcon color="text.secondary" sx={{ mr: 1 }} />
				{isSmDown ? (
					<Typography variant="h5">Cancellation failed</Typography>
				) : (
					<Box>
						<Typography variant="h6">Cancellation failed</Typography>
						<Typography variant="subtitle1">
							We were unable to cancel your order. Please try again or{' '}
							<Link
								href="mailto:support@bazo.co"
								target="_top"
								color="secondary"
							>
								reach out to us
							</Link>
						</Typography>
					</Box>
				)}
			</Box>
		);
	}

	if (orderCancellationInProgress(order)) {
		return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					border: `1px solid ${theme.palette.subdued.main}`,
					p: 2,
					mb: 1,
				}}
			>
				<CancelOutlinedIcon color="text.secondary" sx={{ mr: 1 }} />
				{isSmDown ? (
					<Typography variant="h5">Cancelling Order...</Typography>
				) : (
					<Box>
						<Typography variant="h6">
							Cancellation in progress
						</Typography>
						<Typography variant="subtitle1">
							Your cancellation request has been received and is
							currently being processed. We&apos;ll notify you as soon
							as it&apos;s confirmed. Thank you for your patience.
						</Typography>
					</Box>
				)}
			</Box>
		);
	}
}

CancellationNotice.propTypes = {
	order: OrderType.isRequired,
};

export default CancellationNotice;
