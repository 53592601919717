import * as React from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import SubscriptionsTilesContainer from '@app/components/subscriptions/SubscriptionsTilesContainer';
import constants from '@app/constants';
import CancelSubscription from '@app/pages/settings/subscriptions/cancelSubscription';
import useAuth from '@app/hooks/useAuth';

function SubscriptionsPage() {
	const { activeSubscription } = useAuth();

	return (
		<Container maxWidth="lg">
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: 2,
					mb: 5,
					mt: 5,
					textAlign: 'center',
				}}
			>
				<Typography variant="h4">
					Unlock Dynamic Pricing Power
				</Typography>
				<Typography
					sx={{
						color: constants.colors.darkGrey,
					}}
				>
					Introduce Dynamic Pricing with Our &apos;Name Your
					Price&apos; Subscription Plans
				</Typography>
			</Box>
			<SubscriptionsTilesContainer />
			{activeSubscription ? <CancelSubscription /> : null}
		</Container>
	);
}

export default SubscriptionsPage;
