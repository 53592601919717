import * as React from 'react';
import PropTypes from 'prop-types';

import { useEventBus } from './useEventBus';
import * as events from '@app/constants/eventConstants';

const defaultCreateCampaignInfo = {
	isMarketplace: false,
	marketplaceCampaignId: null,
	fieldsData: {},
	automationRules: {
		enabled: true,
	},
	step: 0,
};

const CreateCampaignContext = React.createContext(undefined);

export function CreateCampaignProvider({ children }) {
	const { subscribe, unsubscribe } = useEventBus();

	const [createCampaignInfo, setCreateCampaignInfo] = React.useState(
		defaultCreateCampaignInfo,
	);

	const resetCreateCampaignInfo = React.useCallback(() => {
		setCreateCampaignInfo(defaultCreateCampaignInfo);
	}, []);

	// Clear campaign state when logging out or changing shops
	React.useEffect(() => {
		// Clear state when changing shops or logging out
		subscribe(events.LOGOUT_EVENT, resetCreateCampaignInfo);
		subscribe(
			events.ACTIVE_SHOP_CHANGED_EVENT,
			resetCreateCampaignInfo,
		);

		return () => {
			unsubscribe(events.LOGOUT_EVENT, resetCreateCampaignInfo);
			unsubscribe(
				events.ACTIVE_SHOP_CHANGED_EVENT,
				resetCreateCampaignInfo,
			);
		};
	}, [subscribe, unsubscribe, resetCreateCampaignInfo]);

	const setIsMarketplace = React.useCallback(({ isMarketplace }) => {
		setCreateCampaignInfo((prev) => ({
			...prev,
			isMarketplace,
		}));
	}, []);

	const memoedValue = React.useMemo(
		() => ({
			createCampaignInfo,
			setCreateCampaignInfo,
			resetCreateCampaignInfo,
			setIsMarketplace,
		}),
		[setIsMarketplace, createCampaignInfo, resetCreateCampaignInfo],
	);

	return (
		<CreateCampaignContext.Provider value={memoedValue}>
			{children}
		</CreateCampaignContext.Provider>
	);
}

CreateCampaignProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export function useCreateCampaignContext() {
	const context = React.useContext(CreateCampaignContext);
	if (context === undefined) {
		throw new Error(
			'useCreateCampaignContext must be used within a provider',
		);
	}
	return context;
}
