import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function ControlledAccordions({ panels, sx, summarySx, detailsSx }) {
	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<Box
			sx={{
				width: '100%',
				mb: 3,
				...sx,
			}}
		>
			{panels.map((accordion) => {
				const { id, summary, details } = accordion;
				return (
					<Accordion
						expanded={expanded === id}
						key={id}
						onChange={handleChange(id)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1bh-content"
							id="panel1bh-header"
							sx={{
								backgroundColor: '#F9F9F9',
								borderTop: 1,
								borderTopColor: 'divider',
								borderBottom: 1,
								borderBottomColor: 'divider',
								...summarySx,
							}}
						>
							{/* <Typography className={classes.heading}> */}
							{typeof summary === 'string' ? (
								<Typography fontWeight="bold" color="secondary.main">
									{summary}
								</Typography>
							) : (
								summary
							)}
						</AccordionSummary>
						<AccordionDetails sx={{ mt: 2, ...detailsSx }}>
							{typeof details === 'string' ? (
								<Typography fontWeight="bold" color="secondary.main">
									{details}
								</Typography>
							) : (
								details
							)}
						</AccordionDetails>
					</Accordion>
				);
			})}
		</Box>
	);
}

ControlledAccordions.propTypes = {
	panels: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			summary: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.node,
			]),
			details: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.node,
			]),
		}),
	).isRequired,
	sx: PropTypes.shape({}),
	summarySx: PropTypes.shape({}),
	detailsSx: PropTypes.shape({}),
};

ControlledAccordions.defaultProps = {
	sx: {},
	summarySx: {},
	detailsSx: {},
};

export default ControlledAccordions;
