import React from 'react';

import StatusBadge from '@app/components/shared/ui/StatusBadge';
import { OfferType, OrderType } from '@app/propTypes/types';
import { orderCancellationInProgress } from '@app/pages/customerPortalNyop/helpers';

function OfferStatusBadge({ order, offer }) {
	if (!offer) return null;

	// Default: offer has not been accepted, countered or declined
	let config = {
		buttonText: 'Pending Shop Review',
		color: 'text.disabled',
		textColor: '#000',
	};

	if (orderCancellationInProgress(order)) {
		config = {
			buttonText: 'Cancelling...',
			color: 'error.main',
			textColor: '#fff',
		};
	}
	// Counter created and declined (should take precedence over cancelled
	// because it can be both cancelled and declined and declined is more specific to the offer)
	else if (
		offer?.counter_offer_created_at &&
		offer?.counter_offer_amount &&
		offer?.counter_offer_declined_at
	) {
		config = {
			buttonText: 'Declined',
			color: 'error.main',
			textColor: '#fff',
		};
		// Counter created and not yet accepted
	} else if (offer?.canceled_at) {
		config = {
			buttonText: 'Cancelled',
			color: 'error.main',
			textColor: '#fff',
		};
	} else if (offer?.accepted_at || offer?.counter_offer_accepted_at) {
		config = {
			buttonText: 'Accepted',
			color: 'success.main',
			textColor: 'success.contrastText',
		};
		// Counter created and not yet accepted
	} else if (
		offer?.counter_offer_created_at &&
		offer?.counter_offer_amount &&
		!offer?.counter_offer_accepted_at
	) {
		config = {
			buttonText: 'Pending Your Review',
			color: 'tertiary.main',
			textColor: '#000',
		};
		// Offer accepted or counter accepted
	}

	// If out of stock override any other cases
	if (offer.handle_out_of_stock) {
		config = {
			buttonText: 'Sold Out',
			color: 'error.main',
			textColor: '#FFF',
		};
	}

	return <StatusBadge {...config} sx={{ textAlign: 'center' }} />;
}

OfferStatusBadge.propTypes = {
	offer: OfferType.isRequired,
	order: OrderType.isRequired,
};

export default OfferStatusBadge;
