import React from 'react';
import useForm from '@app/hooks/useForm';
import {
	useGetMaxOffersPerOrder,
	useSetMaxOffersPerOrder,
} from '@app/hooks/useMerchant';
import SettingContainer from '../components/settingContainer/SettingContainer';

const MAX_OFFERS_PER_ORDER = 20;
const DEFAULT_OFFERS_PER_ORDER = 10;

function MaxOffersPerOrder() {
	const [error, setError] = React.useState('');

	const { data: maxOffersPerOrder, isLoading } =
		useGetMaxOffersPerOrder();
	const { mutate } = useSetMaxOffersPerOrder();

	const { handleSubmit, handleChange, data, errors } = useForm({
		initialValues: {
			maxOffersPerOrder: maxOffersPerOrder?.data?.maxOffersPerOrder,
		},
		validations: {
			maxOffersPerOrder: {
				pattern: {
					value: /^(1[0-9]|20|[1-9])$/,
					message: `The maximum number of offers per order must be a valid number between 1 and ${MAX_OFFERS_PER_ORDER} (inclusive).`,
				},
				custom: {
					isValid: (value) => value > 0,
					message:
						'Please ensure that maximum number of offers per order is set to at least 1. This indicates that at least one offer must be included in each order.',
				},
			},
		},
		onSubmit: async () => {
			try {
				mutate({ maxOffersPerOrder: data.maxOffersPerOrder });
			} catch (err) {
				console.error(err);
				setError(err.message);
			}
		},
	});

	React.useEffect(() => {
		let value = maxOffersPerOrder?.data?.maxOffersPerOrder;
		if (value === undefined && !isLoading) {
			value = DEFAULT_OFFERS_PER_ORDER;
		}

		handleChange('maxOffersPerOrder')({
			target: {
				value,
			},
		});
		// eslint-disable-next-line
	}, [maxOffersPerOrder?.data?.maxOffersPerOrder, isLoading]);

	const handleMaxOffersPerOrderChange = (e) => {
		let value = Number.parseInt(e.target.value, 10);
		if (Number.isNaN(value) || value <= 0) {
			value = 0;
		}

		if (value > MAX_OFFERS_PER_ORDER) {
			value = MAX_OFFERS_PER_ORDER;
		}

		handleChange('maxOffersPerOrder')({
			target: {
				value,
			},
		});
	};

	return (
		<SettingContainer
			title="Max Offers per Order"
			subtitle="Customize Cart Limits for Shoppers"
			helperText={`Set the maximum number of offers that can be included in a single order. Please note that the maximum value allowed is ${MAX_OFFERS_PER_ORDER}.`}
			value={data.maxOffersPerOrder}
			inputType="number"
			onChangeValue={handleMaxOffersPerOrderChange}
			inputName="maxOffersPerOrder"
			onClick={handleSubmit}
			errors={[...Object.values(errors ?? {}), error]}
			isLoading={isLoading}
			placeholder="Loading..."
		/>
	);
}

export default MaxOffersPerOrder;
