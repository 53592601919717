import React from 'react';
import { getCounterValues } from '@app/pages/createCampaign/helpers';

export function useAutomationRules({
	selectedVariants,
	defaultAutomationRules,
}) {
	const [automationRules, setAutomationRules] = React.useState(
		defaultAutomationRules,
	);

	React.useEffect(() => {
		const perProductCounterDiscounts =
			getCounterValues(selectedVariants);

		const formattedCounterDiscounts = {};
		Object.keys(perProductCounterDiscounts).forEach((key) => {
			// eslint-disable-next-line no-prototype-builtins
			if (perProductCounterDiscounts.hasOwnProperty(key)) {
				formattedCounterDiscounts[key] = {
					maxEffectiveDiscount: perProductCounterDiscounts[key] / 100,
				};
			}
		});

		setAutomationRules((prevRules) => ({
			...prevRules,
			variants: {
				// Marketplace Campaigns get wiped out here when transforming the ids
				...(defaultAutomationRules?.variants
					? defaultAutomationRules.variants
					: {}),
				...formattedCounterDiscounts,
			},
		}));
		// Only use defaultAutomationRules.variants in marketplace campaign
		// and adding in dependency array causes infinite loop
		// and it's available on first render anyways
	}, [selectedVariants]); // eslint-disable-line

	return { automationRules, setAutomationRules };
}
