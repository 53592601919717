import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function WidgetComponent({ title, value }) {
	return (
		<Box
			sx={{
				minHeight: '92px',
				width: '100%',
				backgroundColor: 'white',
				padding: 2,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				borderRadius: '4px',
				boxShadow: 1,
			}}
		>
			<Typography sx={{ fontSize: 12 }}>{title}</Typography>
			<Typography
				sx={{
					fontSize: 20,
					fontWeight: 500,
					color: 'primary.main',
				}}
			>
				{value ?? '-'}
			</Typography>
		</Box>
	);
}

WidgetComponent.propTypes = {
	title: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		.isRequired,
};

export default WidgetComponent;
