import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import { useState } from 'react';

function SelectInput({
	name,
	value,
	label,
	disabled,
	handleChange,
	options,
	inputLabelText,
	fullWidth,
	size,
	StartAdornmentIcon,
	endAdornment,
	onOpen,
	onClose,
	sx,
}) {
	const [open, setOpen] = useState(false);

	return (
		<FormControl fullWidth={fullWidth} size={size} sx={sx}>
			<InputLabel id={`select-label-${name}`}>
				{inputLabelText}
			</InputLabel>
			<Select
				labelId={`select-label-${name}`}
				id={`select-input-${name}`}
				value={value}
				name={name}
				label={label}
				disabled={disabled}
				open={open}
				onOpen={(event) => {
					onOpen(event);
					setOpen(true);
				}}
				onClose={(event) => {
					onClose(event);
					setOpen(false);
				}}
				onClick={() => !disabled && setOpen(!open)}
				sx={{ ':hover': { cursor: 'pointer' } }}
				onChange={handleChange}
				{...(StartAdornmentIcon && {
					startAdornment: (
						<InputAdornment position="start">
							<StartAdornmentIcon />
						</InputAdornment>
					),
				})}
				endAdornment={endAdornment}
			>
				{options.map((option) => (
					<MenuItem
						key={`select-option-${name}-${option.label}`}
						value={option.value}
					>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

SelectInput.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.string,
	handleChange: PropTypes.func.isRequired,
	onOpen: PropTypes.func,
	onClose: PropTypes.func,
	inputLabelText: PropTypes.string,
	fullWidth: PropTypes.bool,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
			]),
		}),
	).isRequired,
	size: PropTypes.string,
	StartAdornmentIcon: PropTypes.elementType,
	endAdornment: PropTypes.shape({}),
	sx: PropTypes.shape({}),
	disabled: PropTypes.bool,
};

SelectInput.defaultProps = {
	value: '',
	fullWidth: false,
	disabled: false,
	label: null,
	inputLabelText: '',
	size: 'small',
	StartAdornmentIcon: null,
	endAdornment: null,
	sx: {},
	onOpen: () => {},
	onClose: () => {},
};

export default SelectInput;
