import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import constants from '@app/constants';

export default function RadioButtonGroup({
	name,
	value,
	variant,
	handleChange,
	radios,
	helperText,
	formLabelText,
	sx,
}) {
	return (
		<FormControl sx={{ ml: 0, display: 'block' }} variant={variant}>
			<FormLabel
				id={`radio-group-${name}`}
				sx={{
					color: constants.colors.textMain,
					fontWeight: 'bold',
				}}
			>
				{formLabelText}
			</FormLabel>
			<RadioGroup
				aria-labelledby={`radio-group-${name}`}
				name={name}
				value={value}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					...sx,
				}}
				onChange={handleChange}
			>
				{radios.map((radio) => (
					<React.Fragment key={`radio-${name}-${radio.label}`}>
						<FormControlLabel
							value={radio.value}
							control={<Radio disabled={radio.disabled} />}
							label={radio.label}
						/>
						{radio?.helperText && (
							<FormHelperText>{radio.helperText}</FormHelperText>
						)}
					</React.Fragment>
				))}
			</RadioGroup>
			{helperText && (
				<FormHelperText sx={{ pl: 2 }}>{helperText}</FormHelperText>
			)}
		</FormControl>
	);
}

RadioButtonGroup.propTypes = {
	name: PropTypes.string.isRequired,
	variant: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	handleChange: PropTypes.func.isRequired,
	radios: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
			]),
			label: PropTypes.string,
			helperText: PropTypes.string,
			disabled: PropTypes.bool,
		}),
	).isRequired,
	helperText: PropTypes.string,
	formLabelText: PropTypes.string.isRequired,
	sx: PropTypes.shape({}),
};

RadioButtonGroup.defaultProps = {
	variant: 'standard',
	value: '',
	helperText: '',
	sx: {},
};
