import axios from './index';
import constants from '../constants';

const apiUrl = '/api/campaigns';

export async function get({ active, type } = {}) {
	const params = new URLSearchParams();
	if (type !== null && type !== undefined) {
		params.append('type', type);
	}

	if (active) {
		params.append('active', active);
	}

	const response = await axios.get(`${apiUrl}?${params.toString()}`);
	return response.data;
}

export async function getById({ id, type }) {
	const encodedUrlParam = encodeURIComponent(id);

	let response;
	if (type === constants.campaignTypes.NYOP) {
		response = await axios.get(`${apiUrl}/nyop/${encodedUrlParam}`);
	} else {
		response = await axios.get(`${apiUrl}/${encodedUrlParam}`);
	}
	return response.data;
}

export async function getSummary() {
	const response = await axios.get(`${apiUrl}/summary`);
	return response.data;
}

export async function create({
	productVariantIds,
	sellingPlanGroupName,
	remainingBalanceChargeTrigger,
	remainingBalanceChargeTime,
	deposit,
	fulfillmentTrigger,
	inventoryReservation,
	sellingPlanDescription,
}) {
	const response = await axios.post(apiUrl, {
		productVariantIds,
		sellingPlanGroupName,
		remainingBalanceChargeTrigger,
		remainingBalanceChargeTime,
		deposit,
		fulfillmentTrigger,
		inventoryReservation,
		sellingPlanDescription,
	});

	return response.data;
}

export async function update({
	id,
	sellingPlanId,
	productVariantIds,
	sellingPlanGroupName,
	remainingBalanceChargeTrigger,
	remainingBalanceChargeTime,
	deposit,
	fulfillmentTrigger,
	inventoryReservation,
	sellingPlanDescription,
}) {
	const encodedUrlParam = encodeURIComponent(id);
	const response = await axios.patch(`${apiUrl}/${encodedUrlParam}`, {
		id,
		sellingPlanId,
		productVariantIds,
		sellingPlanGroupName,
		remainingBalanceChargeTrigger,
		remainingBalanceChargeTime,
		deposit,
		fulfillmentTrigger,
		inventoryReservation,
		sellingPlanDescription,
	});

	return response.data;
}

export async function del({ id }) {
	const encodedUrlParam = encodeURIComponent(id);
	const response = await axios.delete(`${apiUrl}/${encodedUrlParam}`);

	return response.data;
}
