import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import constants from '../../../constants';

function Copyright(props) {
	return (
		<Box>
			<Typography
				variant="body2"
				color="text.secondary"
				align="center"
				sx={{ mt: 3 }}
				{...props}
			>
				Need to get in touch? Send us an email at{' '}
				<Link
					color="inherit"
					rel="noopener noreferrer"
					target="_top"
					href="mailto:support@bazo.co"
				>
					support@bazo.co
				</Link>
			</Typography>
			<Typography
				variant="body2"
				color="text.secondary"
				align="center"
				{...props}
			>
				<Link
					color="inherit"
					href={constants.termsUrl}
					target="_blank"
					rel="noopener noreferrer"
				>
					Terms of Service
				</Link>
				{' | '}
				<Link
					color="inherit"
					href={constants.privacyUrl}
					target="_blank"
					rel="noopener noreferrer"
				>
					Privacy Policy
				</Link>
			</Typography>
			<Typography
				variant="body2"
				color="text.secondary"
				align="center"
				sx={{ mt: 1 }}
				{...props}
			>
				{'Copyright © '}
				<Link
					color="inherit"
					href="https://bazo.co"
					target="_blank"
					rel="noopener noreferrer"
				>
					Bazo, Inc.
				</Link>{' '}
				{new Date().getFullYear()}.
			</Typography>
		</Box>
	);
}

export default Copyright;
