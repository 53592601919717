/**
 * This is meant to clear out the local storage keys for our MUI Datatables.
 * Since we reuse components, we had to suffix the keys with an id.
 * We don't want the keys to get bloated over time and we clear them out with each Campaign Finalize
 */

export const VARIANT_SUMMARY_STORAGE_KEY_PREFIX =
	'nyop-variants-summary';
export function getVariantSummaryStorageKey(campaignId) {
	return `${VARIANT_SUMMARY_STORAGE_KEY_PREFIX}-${campaignId}`;
}

export const VARIANT_DETAILS_STORAGE_KEY_PREFIX =
	'nyop-variant-details';
export function getVariantDetailsStorageKey(id) {
	return `${VARIANT_DETAILS_STORAGE_KEY_PREFIX}-${id}`;
}

export const DRAWER_OPEN_STORAGE_KEY = 'is-drawer-open';

export function deleteKeysWithPrefix(prefix) {
	Object.keys(localStorage)
		.filter((key) => key.startsWith(prefix))
		.forEach((key) => localStorage.removeItem(key));
}

// This function is ran when a campaign is successful and that is probably
// an arbitrary point since these can also apply to inactive campaigns but if a user
// comes back to an old campaign and the filters are reset it's not the end of the world and it's ,mostly for active campaigns
// which is where people will likely spend the most amount of time
// TODO: Find a better place for this call that completing a campaign bc inactive campaigns might
// have a better cadence than this
export function clearLocalStorageTableCache() {
	deleteKeysWithPrefix(VARIANT_SUMMARY_STORAGE_KEY_PREFIX);
	deleteKeysWithPrefix(VARIANT_DETAILS_STORAGE_KEY_PREFIX);
}
