import * as React from 'react';
import PropTypes from 'prop-types';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import PaymentStatus from './components/PaymentStatus';
import ThankYou from './components/ThankYou';
import CounterOffers from './components/CounterOffers';
import CustomerUpdatePayment from './components/CustomerUpdatePayment';
import ShippingStatus from './components/ShippingStatus';
import CustomerInformation from './components/CustomerInformation';
import OrderDetails from './components/OrderDetails';
import CancellationModal from './components/CancellationModal';

import Accordion from '../../components/shared/ui/Accordion';
import Modal from '../../components/shared/modal/Modal';
import OutlineBox from '../../components/shared/ui/OutlineBox';
import Mailto from '../../components/shared/ui/MailtoLink';

import {
	MerchantType,
	OfferType,
	OrderType,
	PreorderType,
	VariantType,
} from '../../propTypes/types';
import constants from '../../constants';
import Button from '@app/components/shared/button/Button';

function CustomerPortalPreorderLayout({
	order,
	merchant,
	variants,
	preorders,
	offers,
	token,
}) {
	const [modalOpen, setModalOpen] = React.useState(false);

	let dueDate;
	let initialDueDate;
	let consentedDueDate;
	let orderType;

	if (preorders.length !== 0) {
		dueDate = preorders?.find(
			(po) => po.payment_due_at,
		)?.payment_due_at;
		initialDueDate =
			preorders?.find((po) => po.initial_fulfillment_date)
				?.initial_fulfillment_date || dueDate;
		consentedDueDate =
			preorders?.find((po) => po.consented_fulfillment_date)
				?.consented_fulfillment_date || initialDueDate;
		orderType = constants.campaignTypes.PREORDER;
	} else if (offers.length !== 0) {
		// Assuming that there can be a single campaign at the same time, all offers will have the same end dates
		const offer = offers[0];
		dueDate = offer.end_date;
		initialDueDate = offer.original_end_date;
		orderType = constants.campaignTypes.NYOP;
	}

	return (
		<Container maxWidth="lg">
			<Grid container>
				<Grid item p={3} md={6} sm={12} sx={{ p: { xs: 1, sm: 2 } }}>
					<ThankYou
						order={order}
						merchant={merchant}
						customer={order?.customer}
					>
						<Accordion
							sx={{ display: { sm: 'unset', md: 'none' } }}
							panels={[
								{
									id: 'customer-order-summary-panel',
									summary: (
										<Box display="flex" width="100%">
											<ShoppingCartIcon
												color="secondary"
												sx={{ mr: 2 }}
											/>
											<Typography
												fontWeight="bold"
												color="secondary.main"
											>
												Show order summary
											</Typography>
										</Box>
									),
									details: (
										<OrderDetails
											order={order}
											preorders={preorders}
											variants={variants}
											xs={{ mt: 4 }}
										/>
									),
								},
							]}
						/>
					</ThankYou>
					<CounterOffers
						order={order}
						offers={offers.filter((o) => o.counter_offer_created_at)}
						token={token}
						merchant={merchant}
						variants={variants}
					/>
					<PaymentStatus
						paymentStatus={order.financial_status}
						dueDate={dueDate}
						orderId={order.id}
						consentedDueDate={consentedDueDate}
						initialDueDate={initialDueDate}
						cancelReason={order?.cancel_reason}
						cancelledAt={order?.cancelled_at}
						token={token}
						orderType={orderType}
					/>
					{/* Shipping address can be null on non-deliverable items */}
					{order?.address && (
						<ShippingStatus
							shippingAddress={order?.address}
							dueDate={dueDate}
							fulfillmentStatus={order?.fulfillment_status}
							cancelledAt={order?.cancelled_at}
							orderType={orderType}
						/>
					)}
					<OutlineBox>
						<CustomerInformation
							billingAddress={order.billing_address}
							shippingAddress={order.address}
							customer={order.customer}
						/>
					</OutlineBox>
					{!order?.cancelled_at && (
						<Accordion
							panels={[
								// {
								// 	id: 'customer-edit-shipping',
								// 	summary: 'Edit shipping address',
								// 	details: (
								// 		<EditShippingAddress
								// 			shippingAddress={order?.address}
								// 			token={token}
								// 			provinces={countries.find(
								// 				(country) =>
								// 					country?.name ===
								// 					order?.address?.country,
								// 			)}
								// 		/>
								// 	),
								// },
								{
									id: 'customer-edit-billing-address',
									summary: 'Edit billing address',
									details: <CustomerUpdatePayment token={token} />,
								},
							]}
						/>
					)}
					{!order?.cancelled_at && (
						<OutlineBox>
							<Box />
							<Modal
								buttonText="Cancel Order"
								buttonVariant="text"
								buttonColor="secondary"
								handleModal={setModalOpen}
								open={modalOpen}
							>
								<CancellationModal
									order={order}
									token={token}
									handleModal={setModalOpen}
								/>
							</Modal>
						</OutlineBox>
					)}
					<OutlineBox>
						<Typography>
							Need help?{' '}
							<Mailto
								email="support@bazo.co"
								subject={`Customer Support Request;${
									order?.domain ? ` Shop: ${order?.domain};` : ''
								}Order: ${order?.id}`}
								color="secondary"
							>
								<Typography component="span">Contact us</Typography>
							</Mailto>
						</Typography>
						<Link
							href={`https://${merchant.domain}`}
							target="_blank"
							rel="noopener noreferrer"
							color="primary.contrastText"
							underline="none"
						>
							<Button color="secondary">Continue Shopping</Button>
						</Link>
					</OutlineBox>
				</Grid>
				<Grid
					item
					p={3}
					md={6}
					sm={12}
					sx={{
						borderLeft: { sm: null, md: 1 },
						borderLeftColor: { sm: null, md: 'divider' },
						backgroundColor: { sm: 'inherit', md: '#F9F9F9' },
					}}
					order={{ xs: 1, sm: 1, md: 2 }}
				>
					<OrderDetails
						order={order}
						preorders={preorders}
						variants={variants}
						sx={{ display: { xs: 'none', md: 'unset' } }}
					/>
				</Grid>
			</Grid>
		</Container>
	);
}

CustomerPortalPreorderLayout.propTypes = {
	order: OrderType.isRequired,
	offers: PropTypes.arrayOf(OfferType).isRequired,
	preorders: PropTypes.arrayOf(PreorderType).isRequired,
	variants: PropTypes.arrayOf(VariantType).isRequired,
	merchant: MerchantType.isRequired,
	token: PropTypes.string.isRequired,
};

export default CustomerPortalPreorderLayout;
