import React from 'react';
import PropTypes from 'prop-types';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@app/components/shared/button/Button';

import CancellationModal from '@app/pages/customerPortalPreorder/components/CancellationModal';
import Modal from '@app/components/shared/modal/Modal';

import { OfferType, OrderType } from '@app/propTypes/types';
import {
	getPendingOffers,
	orderCancellationInProgress,
} from '../helpers';

// TODO: Copy pasted some components from old customer portal
function CustomerActions({ token, order, offers }) {
	const queryClient = useQueryClient();
	const [modalOpen, setModalOpen] = React.useState(false);

	const { mutate, data, isLoading, isError } = useMutation({
		mutationFn: async () =>
			axios.get('/api/customers/update-payment-method', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}),
		onSuccess: () => {
			// TODO: Do a lazy update here instead of invalidating cache which makes unnecessary call
			queryClient.invalidateQueries(['customer', token]);
		},
	});

	const pendingOffers = getPendingOffers(offers);

	const cancellationInProgress = orderCancellationInProgress(order);

	const orderCanBeCanceled =
		pendingOffers.length !== 0 && !cancellationInProgress;

	const paymentMethodCanBeUpdated = !cancellationInProgress;

	return (
		<Box sx={{ textAlign: 'center' }} p={4}>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-around',
				}}
			>
				{paymentMethodCanBeUpdated ? (
					<Button loading={isLoading} onClick={() => mutate()}>
						Update Payment Method
					</Button>
				) : null}
				{orderCanBeCanceled ? (
					<Modal
						buttonText="Cancel Order"
						buttonVariant="text"
						buttonColor="error"
						handleModal={setModalOpen}
						open={modalOpen}
					>
						<CancellationModal
							order={order}
							token={token}
							handleModal={setModalOpen}
						/>
					</Modal>
				) : null}
			</Box>
			{isError && (
				<Typography color="error.main" mt={2}>
					An error occurred. Could not send update email.
				</Typography>
			)}
			{data?.data?.data?.success && (
				<Typography mt={2}>
					An email was just sent to {data?.data?.data?.email}. Check
					your inbox to update your payment information.
				</Typography>
			)}
		</Box>
	);
}

CustomerActions.propTypes = {
	token: PropTypes.string.isRequired,
	order: OrderType.isRequired,
	offers: PropTypes.arrayOf(OfferType).isRequired,
};

export default CustomerActions;
