import { differenceInMinutes } from 'date-fns';

const MINUTES_BEFORE_STUCK_CANCELLATION_EXPIRES = 2;

export function getVariantMap(variants) {
	return variants.reduce((acc, variant) => {
		acc[variant.id] = variant;
		return acc;
	}, {});
}

export function mergeLineItems({ order, offers }) {
	// Merge order line items and offers
	const mergedList = order.line_items.map((lineItem) => {
		// Find the offer for this line item
		const offerForLineItem = offers.find(
			(offer) =>
				offer.line_item_id ===
				`gid://shopify/LineItem/${lineItem.id}`,
		);

		// If there's an offer for this line item, use the offer details
		if (offerForLineItem) {
			return {
				...lineItem,
				offer: offerForLineItem,
				isOffer: true,
			};
		}

		// Otherwise, just return the line item
		return lineItem;
	});

	// This will break when the custom line item is added
	return mergedList.filter(
		(lineItem) => !lineItem.name.includes('Bazo Counteroffer'),
	);
}

export function sortOrderDetails(mergedList) {
	return mergedList.sort((a, b) => {
		if (a?.offer?.counter_offer_created_at) return -1;
		if (b?.offer?.counter_offer_created_at) return 1;
		if (a?.accepted_at) return -1;
		if (b?.accepted_at) return 1;
		if (a?.offer?.id) return -1;
		if (b?.offer?.id) return 1;
		return 0;
	});
}

// Offers that require merchant input
export function getUnderMerchantReviewOffers(offers) {
	return (
		offers?.filter(
			(offer) =>
				!offer?.counter_offer_created_at &&
				!offer?.accepted_at &&
				!offer?.canceled_at,
		) ?? []
	);
}

// Offers that were declined by customer, merchant or system
export function getDeclinedOffers(offers) {
	return offers.filter(
		(offer) => offer?.counter_offer_declined_at || offer?.canceled_at,
	);
}

// Offers that were countered by merchant but not reviewed by customer
export function getUnderCustomerReviewOffers(offers) {
	return offers.filter(
		(offer) =>
			offer?.counter_offer_created_at &&
			!offer?.counter_offer_accepted_at &&
			!offer?.counter_offer_declined_at &&
			!offer?.canceled_at,
	);
}

// Offers that require either customer's or merchant's review
export function getPendingOffers(offers) {
	return offers.filter(
		(offer) =>
			!offer?.accepted_at &&
			!offer?.counter_offer_accepted_at &&
			!offer?.counter_offer_declined_at &&
			!offer?.canceled_at,
	);
}

export function getFirstValidNumber(...values) {
	return values.find((val) => {
		const num = typeof val === 'string' ? parseFloat(val) : val;
		return num !== null && num !== undefined && !Number.isNaN(num);
	});
}

// Difference between the offer countered value and the bulk offer countered value
export function getBulkOffersSavedPrice(offers = []) {
	return offers.reduce((result, offer) => {
		if (!offer.bulk_counter_offer_amount) {
			return result;
		}

		const offerAmount = offer.counter_offer_amount
			? offer.counter_offer_amount
			: offer.offer_amount;
		const priceDifference =
			offerAmount - offer.bulk_counter_offer_amount;

		return result + priceDifference * offer.quantity;
	}, 0);
}

export function orderCancellationInProgress(order) {
	if (!order.cancellation_started_at) {
		return false;
	}

	if (
		order.cancellation_failed_at ||
		order.cancellation_completed_at
	) {
		return false;
	}

	const minutesPassed = Math.abs(
		differenceInMinutes(
			new Date(),
			new Date(order.cancellation_started_at),
		),
	);

	return minutesPassed < MINUTES_BEFORE_STUCK_CANCELLATION_EXPIRES;
}
