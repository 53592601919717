import * as React from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import { useQueryClient } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import ConfirmationModal from '@app/components/shared/modal/Modal';
import Loader from '@app/components/shared/loaders/CircularLoader';
import {
	CACHE_KEY,
	useNyopCampaign,
} from '@app/hooks/useNyopCampaign';
import ProfilerProvider from '@app/providers/ProfilerProvider';
import { clearLocalStorageTableCache } from '@app/lib/localStorage';

import Table from './table/index';
import Summary from './Summary';
import EndAction from './actions/EndAction';
import CloseAction from './actions/CloseAction';
import CancelAction from './actions/CancelAction';
import FinalizeAction from './actions/FinalizeAction';

import {
	CANCEL_ACTION,
	CLOSE_ACTION,
	END_ACTION,
	FINALIZE_ACTION,
} from './actions/constants';
import {
	campaignBeingFinalized,
	campaignEnded,
	campaignStarted,
} from './utils';
import NotFound from '@app/pages/notFound/index';
import Button from '@app/components/shared/button/Button';

function NYOPCampaignSummary() {
	const { id } = useParams();
	const [modalOpen, setModal] = React.useState(false);
	const [action, setAction] = React.useState(null);
	const queryClient = useQueryClient();
	const { data, isLoading } = useNyopCampaign({
		id,
	});

	if (isLoading) return <Loader fullPage />;

	const { campaign, aggregations } = data.data;

	if (!campaign) {
		return <NotFound />;
	}

	const isFinalizing = campaignBeingFinalized(campaign);

	return (
		<ProfilerProvider id="NyopCampaignPage">
			<Box
				mb={2}
				sx={{
					display: 'flex',
					float: 'right',
				}}
			>
				{campaign && !campaign.deleted && (
					<Box sx={{ display: 'flex', gap: '0.5rem' }}>
						<Button
							variant="outlined"
							color="secondary"
							component={Link}
							to={`/campaigns/nyop/${id}/edit`}
						>
							Edit
						</Button>
						{!isFinalizing && (
							<Button
								variant="outlined"
								color="error"
								onClick={() => {
									setModal(true);
									setAction(CANCEL_ACTION);
								}}
							>
								Cancel
							</Button>
						)}
						{campaignEnded(campaign) ? (
							<>
								{!isFinalizing && (
									<Tooltip
										placement="top"
										title={
											aggregations.pendingOffersCount
												? `There are still ${aggregations.pendingOffersCount} pending offers left`
												: ''
										}
									>
										<span>
											<Button
												variant="outlined"
												disabled={
													aggregations.pendingOffersCount !== 0
												}
												onClick={() => {
													setModal(true);
													setAction(CLOSE_ACTION);
												}}
											>
												Close
											</Button>
										</span>
									</Tooltip>
								)}
								<Button
									variant="contained"
									onClick={() => {
										setModal(true);
										setAction(FINALIZE_ACTION);
									}}
								>
									{isFinalizing ? 'Finalizing...' : 'Finalize'}
								</Button>
							</>
						) : (
							<Button
								disabled={!campaignStarted(campaign)}
								variant="contained"
								onClick={() => {
									setModal(true);
									setAction(END_ACTION);
								}}
							>
								End early
							</Button>
						)}
					</Box>
				)}
			</Box>

			<ConfirmationModal open={modalOpen} handleModal={setModal}>
				{action === END_ACTION && (
					<EndAction
						campaignId={campaign.id}
						onSuccess={() => {
							setModal(false);
						}}
					/>
				)}
				{action === CLOSE_ACTION && (
					<CloseAction
						campaignId={campaign.id}
						onSuccess={() => {
							setModal(false);
						}}
					/>
				)}
				{action === CANCEL_ACTION && (
					<CancelAction
						campaignId={campaign.id}
						onSuccess={() => {
							setModal(false);
						}}
					/>
				)}
				{action === FINALIZE_ACTION && (
					<FinalizeAction
						campaign={campaign}
						aggregations={aggregations}
						onSuccess={() => {
							queryClient.invalidateQueries([CACHE_KEY]);
							clearLocalStorageTableCache();
						}}
					/>
				)}
			</ConfirmationModal>
			<Grid container rowSpacing={2}>
				<Grid item md={12}>
					<Summary campaign={campaign} aggregations={aggregations} />
				</Grid>
				<Grid item md={12}>
					<Table campaign={campaign} />
				</Grid>
			</Grid>
			<Outlet />
		</ProfilerProvider>
	);
}

export default NYOPCampaignSummary;
