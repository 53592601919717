import {
	useQuery,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import * as preorderApi from '../api/preorders';

export const CACHE_KEY = 'preorders';

export function usePreorders({
	sellingPlanGroupId,
	configOpts = null,
}) {
	return useQuery(
		[CACHE_KEY, sellingPlanGroupId],
		async () => preorderApi.getById({ sellingPlanGroupId }),
		configOpts,
	);
}

export function useFulfillPreorders({
	sellingPlanGroupId,
	configOpts = {},
}) {
	const queryClient = useQueryClient();

	return useMutation(preorderApi.fulfillPreorders, {
		onSettled: () =>
			queryClient.invalidateQueries([CACHE_KEY, sellingPlanGroupId]),
		...configOpts,
	});
}
