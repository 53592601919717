import React from 'react';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import InfoPopoverIcon from '@app/components/shared/ui/InfoPopoverIcon';

function MarketplaceEnabledHeadRenderer() {
	return (
		<TableCell
			sx={{
				display: 'table-cell',
				alignItems: 'center',
				position: 'relative',
				borderBottom: '1px solid rgba(224, 224, 224, 1)',
			}}
		>
			<Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
				<Typography
					sx={{
						fontSize: '0.875rem',
						fontWeight: 500,
						maxWidth: '90px',
						textAlign: 'center',
					}}
				>
					Marketplace Available
				</Typography>
				<InfoPopoverIcon
					text="The item is available in the marketplace. To manage marketplace products, please visit Marketplace Products"
					iconStyles={{ fontSize: 16 }}
					textStyles={{
						fontSize: 14,
						width: '300px',
						maxWidth: '300px',
					}}
				/>
			</Box>
		</TableCell>
	);
}

export default MarketplaceEnabledHeadRenderer;
