export const additionalBazoCode = `<!-- BEGIN BAZO HIDE PAYMENT BUTTON IN ORDER STATUS PAGE -->
<script>
 (function() {
   document.addEventListener('DOMContentLoaded', function() {
     try {
       const payNowSection = document.querySelector('.notice--info');
       if (payNowSection) {
         payNowSection.style.display = 'none';
       }
     } catch (error) {
       console.error('Bazo: Error hiding the payment button:', error);
     }
   });
  })();
</script>
<!-- END BAZO HIDE PAYMENT BUTTON IN ORDER STATUS PAGE -->`;
