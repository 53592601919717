import * as React from 'react';
import MuiDataTable from 'mui-datatables';

import PropTypes from 'prop-types';
import Loader from '../../components/shared/loaders/CircularLoader';

import { useSellingPlanGroups } from '../../hooks/useSellingPlanGroups';

import constants from '../../constants';

import {
	formatDate,
	formatReadableDate,
} from '../../helpers/dateHelpers';

import columns from './campaignColumns';
import getNoTableMatch from '@app/components/shared/Table/components/NoTableMatch';

function CampaignTable({ type } = {}) {
	const { data, isLoading, isFetching } = useSellingPlanGroups({
		type,
	});

	if (isLoading) {
		return <Loader fullPage />;
	}

	const getFulfillmentDateByType = (campaign) => {
		if (type === constants.campaignTypes.NYOP) {
			return formatReadableDate(
				campaign.remaining_balance_charge_date,
			);
		}
		if (type === constants.campaignTypes.PREORDER) {
			return campaign.deleted
				? 'Fulfilled'
				: campaign.remaining_balance_charge_date;
		}

		return 'N/A';
	};

	const getTableData = () => {
		let tableData =
			data?.data.map((campaign) => ({
				...campaign,
				preorders: campaign?.preorders.length || 0,
				// unpaidOrders:
				// 	campaign?.preorders.filter((p) => !p.complete)?.length ||
				// 	0,
				remaining_balance_charge_date:
					getFulfillmentDateByType(campaign),
				start_date: campaign.start_date
					? formatReadableDate(campaign.start_date)
					: '',
				end_date: campaign.end_date
					? formatReadableDate(campaign.end_date)
					: '',
				items: campaign.variants.length || 0,
			})) || [];
		// TODO: remove if after refactoring sellingPlanGroup api logic
		if (type === constants.campaignTypes.NYOP) {
			tableData = tableData.filter((campaign) => !campaign.deleted);
		}
		return tableData;
	};

	return (
		<MuiDataTable
			title="Campaign Management"
			data={isFetching ? [] : getTableData()}
			columns={columns[type] || []}
			options={{
				elevation: 0,
				filter: true,
				filterType: 'dropdown',
				responsive: 'vertical',
				enableNestedDataAccess: '.',
				tableId: 'preorders-campaigns',
				storageKey: 'preorders-campaigns',
				selectableRowsHideCheckboxes: true,
				viewColumns: true,
				textLabels: {
					body: {
						noMatch: getNoTableMatch({
							isLoading: isFetching,
							text: 'Sorry, no Campaigns found',
						}),
					},
				},
				downloadOptions: {
					filename: `campaigns_${formatDate(new Date())}.csv`,
				},
			}}
		/>
	);
}

CampaignTable.propTypes = {
	type: PropTypes.string,
};

CampaignTable.defaultProps = {
	type: constants.campaignTypes.NYOP,
};

export default CampaignTable;
