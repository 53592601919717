import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { formatDate } from '@app/helpers/dateHelpers';
import { parsePercents } from '@app/lib/parse';
import useAuth from '@app/hooks/useAuth';
import { getVariantDetailsStorageKey } from '@app/lib/localStorage';
import { getRangeOptions } from '@app/components/shared/Table/components/MuiDatatableRangeFilter';
import IdRenderer from './IdRenderer';
import StatusRenderer from './StatusRenderer';
import MoneyRenderer from './MoneyRenderer';
import InfoPopover from '@app/components/shared/ui/InfoPopoverIcon';
import offerService from '@app/services/offer.service';
import getNoTableMatch from '@app/components/shared/Table/components/NoTableMatch';

function getIdRenderer(value, data) {
	const orderId = data.rowData.at(-1);
	const offerId = data.rowData.at(-4);
	return <IdRenderer id={value || offerId} orderId={orderId} />;
}

function getStatusRenderer(value, data) {
	const fulfilledQuantity = data.rowData.at(-2);
	const quantity = data.rowData.at(-3);
	const cancellationReason = data.rowData.at(-6);
	return (
		<StatusRenderer
			quantity={quantity}
			fulfilledQuantity={fulfilledQuantity}
			status={value}
			cancellationReason={cancellationReason}
		/>
	);
}

function getMoneyRenderer(value, data) {
	const currency = data.rowData.at(-9);
	const shopCurrency = data.rowData.at(-8);
	const customerCurrencyOfferAmount = data.rowData.at(-7);
	const bulkCounterOfferAmount = data.rowData.at(-12);
	const customerCurrencyBulkCounterOfferAmount = data.rowData.at(-14);

	if (!bulkCounterOfferAmount) {
		return (
			<MoneyRenderer
				shopCurrencyAmount={value}
				customerCurrencyAmount={customerCurrencyOfferAmount}
				customerCurrency={currency}
				shopCurrency={shopCurrency}
			/>
		);
	}

	return (
		<Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
			<MoneyRenderer
				shopCurrencyAmount={value}
				customerCurrencyAmount={customerCurrencyOfferAmount}
				customerCurrency={currency}
				shopCurrency={shopCurrency}
			/>
			<InfoPopover
				popoverVerticalPlacement="top"
				iconStyles={{ fontSize: 16 }}
				type="deal"
			>
				<Typography>
					The customer is eligible for an additional bulk discount and
					has been counter offered
					<MoneyRenderer
						shopCurrencyAmount={bulkCounterOfferAmount}
						customerCurrencyAmount={
							customerCurrencyBulkCounterOfferAmount
						}
						customerCurrency={currency}
						shopCurrency={shopCurrency}
					/>
				</Typography>
			</InfoPopover>
		</Box>
	);
}

function getRevenueRenderer(value, data) {
	const currency = data.rowData.at(-9);
	const shopCurrency = data.rowData.at(-8);
	const customerCurrencyRevenue = data.rowData.at(-10);
	const bulkCounterOfferRevenue = data.rowData.at(-11);
	const customerCurrencyBulkCounterOfferRevenue =
		data.rowData.at(-13);

	if (!bulkCounterOfferRevenue) {
		return (
			<MoneyRenderer
				shopCurrencyAmount={value}
				customerCurrencyAmount={customerCurrencyRevenue}
				customerCurrency={currency}
				shopCurrency={shopCurrency}
			/>
		);
	}

	return (
		<Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
			<MoneyRenderer
				shopCurrencyAmount={value}
				customerCurrencyAmount={customerCurrencyRevenue}
				customerCurrency={currency}
				shopCurrency={shopCurrency}
			/>
			<InfoPopover
				popoverVerticalPlacement="top"
				iconStyles={{ fontSize: 16 }}
				type="deal"
			>
				<Typography>
					The customer is eligible for an additional bulk discount and
					has been counter offered
					<MoneyRenderer
						shopCurrencyAmount={bulkCounterOfferRevenue}
						customerCurrencyAmount={
							customerCurrencyBulkCounterOfferRevenue
						}
						customerCurrency={currency}
						shopCurrency={shopCurrency}
					/>
				</Typography>
			</InfoPopover>
		</Box>
	);
}

const getDiscountRenderer = (value, data) => {
	const bulkDiscount = data.rowData.at(-15);

	if (!bulkDiscount) {
		return <Typography>{parsePercents(value)}%</Typography>;
	}

	return (
		<Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
			<Typography>{parsePercents(value)}%</Typography>
			<InfoPopover
				popoverVerticalPlacement="top"
				iconStyles={{ fontSize: 16 }}
				type="deal"
			>
				<Typography>
					Customer is eligible for an additional bulk discount. If
					they accept the bulk counter offer, the discount will be{' '}
					{parsePercents(bulkDiscount)}%
				</Typography>
			</InfoPopover>
		</Box>
	);
};

function getColumns({
	offersWithVariantData,
	formatMerchantCurrency,
}) {
	const revenueList = offersWithVariantData.map((v) => v.revenue);
	const maxRevenue = Math.max(...revenueList);
	const minRevenue = Math.min(...revenueList);

	const offerAmountsList = offersWithVariantData.map((v) => v.amount);
	const maxAmount = Math.max(...offerAmountsList);
	const minAmount = Math.min(...offerAmountsList);
	return [
		{
			name: 'number',
			label: 'Offer Id',
			options: {
				customBodyRender: getIdRenderer,
			},
		},
		{
			name: 'amount',
			label: 'Offer Price',
			options: {
				filterType: 'custom',
				filterOptions: getRangeOptions({
					format: formatMerchantCurrency,
					revert: (value) => Number(value.replace(/[^0-9.-]+/g, '')),
					maxValue: maxAmount,
					minValue: minAmount,
				}),
				customBodyRender: getMoneyRenderer,
			},
		},
		{
			name: 'quantity',
			label: 'Units',
		},
		{
			name: 'revenue',
			label: 'Revenue from Offers',
			options: {
				filterType: 'custom',
				filterOptions: getRangeOptions({
					format: formatMerchantCurrency,
					revert: (value) => Number(value.replace(/[^0-9.-]+/g, '')),
					maxValue: maxRevenue,
					minValue: minRevenue,
				}),
				customBodyRender: getRevenueRenderer,
			},
		},
		{
			name: 'discount',
			label: 'Effective Discount',
			options: {
				customBodyRender: getDiscountRenderer,
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				customBodyRender: getStatusRenderer,
			},
		},
		// Extra data hidden columns (Add new ones to the start)
		{
			// data.rowData.at(-16)
			name: 'number',
			options: {
				viewColumns: false,
				filter: false,
				display: 'excluded',
				download: false,
			},
		},
		{
			// data.rowData.at(-15)
			name: 'bulkDiscount',
			options: {
				viewColumns: false,
				filter: false,
				display: 'excluded',
				download: false,
			},
		},
		{
			// data.rowData.at(-14)
			name: 'customerCurrencyBulkCounterOfferAmount',
			options: {
				viewColumns: false,
				filter: false,
				display: 'excluded',
				download: false,
			},
		},
		{
			// data.rowData.at(-13)
			name: 'customerCurrencyBulkCounterOfferRevenue',
			options: {
				viewColumns: false,
				filter: false,
				display: 'excluded',
				download: false,
			},
		},
		{
			// data.rowData.at(-12)
			name: 'bulkCounterOfferAmount',
			options: {
				viewColumns: false,
				filter: false,
				display: 'excluded',
				download: false,
			},
		},
		{
			// data.rowData.at(-11)
			name: 'bulkCounterOfferRevenue',
			options: {
				viewColumns: false,
				filter: false,
				display: 'excluded',
				download: false,
			},
		},
		{
			// data.rowData.at(-10)
			name: 'customerCurrencyRevenue',
			options: {
				viewColumns: false,
				filter: false,
				display: 'excluded',
				download: false,
			},
		},
		{
			// data.rowData.at(-9)
			name: 'currency',
			options: {
				viewColumns: false,
				filter: false,
				display: 'excluded',
				download: false,
			},
		},
		{
			// data.rowData.at(-8)
			name: 'shop_currency',
			options: {
				viewColumns: false,
				filter: false,
				display: 'excluded',
				download: false,
			},
		},
		{
			// data.rowData.at(-7)
			name: 'customerCurrencyAmount',
			options: {
				viewColumns: false,
				filter: false,
				display: 'excluded',
				download: false,
			},
		},
		{
			// data.rowData.at(-6)
			name: 'cancellation_reason',
			options: {
				viewColumns: false,
				filter: false,
				display: 'excluded',
				download: false,
			},
		},
		{
			// 	data.rowData.at(-5)
			name: 'amount',
			options: {
				viewColumns: false,
				filter: false,
				display: 'excluded',
				download: false,
			},
		},
		{
			// 	data.rowData.at(-4)
			name: 'id',
			options: {
				viewColumns: false,
				filter: false,
				display: 'excluded',
				download: false,
			},
		},
		{
			// 	data.rowData.at(-3)
			name: 'quantity',
			options: {
				viewColumns: false,
				filter: false,
				display: 'excluded',
				download: false,
			},
		},
		{
			// 	data.rowData.at(-2)
			name: 'fulfilled_quantity',
			options: {
				viewColumns: false,
				filter: false,
				display: 'excluded',
				download: false,
			},
		},
		{
			// data.rowData.at(-1)
			name: 'order_id',
			options: {
				viewColumns: false,
				filter: false,
				display: 'excluded',
				download: false,
			},
		},
	];
}

function OffersTable({ campaign, parentRef, offers, isFetching }) {
	const { formatMerchantCurrency } = useAuth();

	const offersWithVariantData = offers.map((offer) => {
		const amount = parseFloat(
			offer.shop_currency_counter_offer_amount ||
				offer.shop_currency_offer_amount,
		);
		const customerCurrencyAmount = parseFloat(
			offer.counter_offer_amount || offer.offer_amount,
		);
		const customerCurrencyRevenue =
			customerCurrencyAmount * offer.quantity;

		const discount =
			offer.counter_offer_discount || offer.offer_discount;

		const bulkCounterOfferAmount =
			offer.shop_currency_bulk_counter_offer_amount;
		const bulkCounterOfferRevenue = bulkCounterOfferAmount
			? bulkCounterOfferAmount * offer.quantity
			: null;

		const customerCurrencyBulkCounterOfferAmount =
			offer.bulk_counter_offer_amount;
		const customerCurrencyBulkCounterOfferRevenue =
			customerCurrencyBulkCounterOfferAmount
				? customerCurrencyBulkCounterOfferAmount * offer.quantity
				: null;

		const bulkDiscount = customerCurrencyBulkCounterOfferAmount
			? 1 - customerCurrencyBulkCounterOfferAmount / offer.msrp
			: null;

		return {
			...offer,
			status: offerService.getOfferStatus(offer),
			amount,
			customerCurrencyAmount,
			revenue: amount * offer.quantity,
			customerCurrencyRevenue,
			bulkCounterOfferAmount,
			bulkCounterOfferRevenue,
			customerCurrencyBulkCounterOfferAmount,
			customerCurrencyBulkCounterOfferRevenue,
			discount,
			bulkDiscount,
		};
	});

	const columns = React.useMemo(
		() =>
			getColumns({
				offersWithVariantData,
				formatMerchantCurrency,
			}),
		[offersWithVariantData, formatMerchantCurrency],
	);

	return (
		<MUIDataTable
			title="Offers"
			data={isFetching ? [] : offersWithVariantData}
			columns={columns}
			options={{
				filter: true,
				filterType: 'dropdown',
				responsive: 'vertical',
				enableNestedDataAccess: '.',
				storageKey: getVariantDetailsStorageKey(
					`${campaign?.id}-${parentRef}-offers`,
				),
				viewColumns: true,
				selectableRows: 'none',
				downloadOptions: {
					filename: `offers_${parentRef}_${formatDate(
						new Date(),
					)}.csv`,
				},
				textLabels: {
					body: {
						noMatch: getNoTableMatch({
							isLoading: isFetching,
							text: 'Sorry, no Offers found',
						}),
					},
				},
				jumpToPage: true,
			}}
		/>
	);
}

OffersTable.propTypes = {
	campaign: PropTypes.shape({
		id: PropTypes.string.isRequired,
	}),
	parentRef: PropTypes.string.isRequired,
	offers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
		}),
	).isRequired,
	isFetching: PropTypes.bool,
};

OffersTable.defaultProps = {
	campaign: null,
	isFetching: false,
};

export default OffersTable;
