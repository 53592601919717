import * as _ from 'lodash-es';

import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import {
	sortVariantsByTitle,
	splitVariantsByCounterValue,
} from './utils';
import TableHeader from './header';
import TableBody from './body';

function EditByProductTable({
	variants,
	thresholds,
	setThresholds,
	defaultThreshold,
	fixedCostBuffer,
}) {
	const threshold = parseFloat(defaultThreshold);

	const sortedVariants = React.useMemo(() => {
		const {
			variantsWithValidCounterValues,
			variantsWithInvalidCounterValues,
		} = splitVariantsByCounterValue(variants, thresholds, threshold);

		return [
			...sortVariantsByTitle(variantsWithInvalidCounterValues),
			...sortVariantsByTitle(variantsWithValidCounterValues),
		];
		// eslint-disable-next-line
	}, [variants]);

	const products = React.useMemo(
		() => _.groupBy(sortedVariants, 'product_id'),
		[sortedVariants],
	);

	const Header = React.useMemo(
		() => (
			<TableHeader
				defaultThreshold={threshold}
				fixedCostBuffer={fixedCostBuffer}
			/>
		),
		[fixedCostBuffer, threshold],
	);

	const Body = React.useMemo(
		() => (
			<TableBody
				products={products}
				setThresholds={setThresholds}
				thresholds={thresholds}
				threshold={threshold}
				fixedCostBuffer={fixedCostBuffer}
			/>
		),
		// eslint-disable-next-line
		[products],
	);

	return (
		<Table>
			{Header}
			{Body}
		</Table>
	);
}

EditByProductTable.propTypes = {
	variants: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	thresholds: PropTypes.objectOf(PropTypes.string).isRequired,
	setThresholds: PropTypes.func.isRequired,
	defaultThreshold: PropTypes.string.isRequired,
	fixedCostBuffer: PropTypes.number,
};

EditByProductTable.defaultProps = {
	fixedCostBuffer: 0,
};

export default EditByProductTable;
