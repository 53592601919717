import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

/**
 * @desc Needed for MaterialUI Date Picker and Date-fns
 * https://mui.com/x/react-date-pickers/localization/
 */

function MaterialUiLocaliztionProvider({ children }) {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			{children}
		</LocalizationProvider>
	);
}

MaterialUiLocaliztionProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export default MaterialUiLocaliztionProvider;
