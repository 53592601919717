import React from 'react';
import { Box } from '@mui/material';

import CreatePreorderCampaignForm from '@app/pages/createCampaign/Preorder';
import CreateNyopCampaignForm from '@app/pages/createCampaign/Nyop';
import constants from '@app/constants';
import { useCreateCampaignContext } from '@app/hooks/useCreateCampaignContext';

function CreateCampaignForm() {
	const [campaignType, setCampaignType] = React.useState(
		constants.campaignTypes.NYOP,
	);

	const { createCampaignInfo } = useCreateCampaignContext();

	const completeNavigateLink = createCampaignInfo?.isMarketplace
		? '/marketplace/campaigns'
		: '/campaigns/nyop';

	return (
		<Box sx={{ minWidth: '50vw' }}>
			{campaignType === constants.campaignTypes.NYOP && (
				<CreateNyopCampaignForm
					onCampaignTypeChange={setCampaignType}
					onCompleteNavigate={completeNavigateLink}
				/>
			)}
			{campaignType &&
				campaignType === constants.campaignTypes.PREORDER && (
					<CreatePreorderCampaignForm
						onCampaignTypeChange={setCampaignType}
					/>
				)}
		</Box>
	);
}

export default CreateCampaignForm;
