import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

function AuxiliaryWarning({ sx, text }) {
	return (
		<Alert
			severity="warning"
			sx={{
				display: 'flex',
				alignItems: 'center',
				...sx,
			}}
		>
			<Typography>{text}</Typography>
		</Alert>
	);
}

AuxiliaryWarning.propTypes = {
	sx: PropTypes.instanceOf(Object),
	text: PropTypes.string.isRequired,
};

AuxiliaryWarning.defaultProps = {
	sx: {},
};

export default AuxiliaryWarning;
