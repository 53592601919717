import * as React from 'react';

import { Box, Link } from '@mui/material';

import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import EmailInput from '../../components/shared/inputs/EmailInput';
import ErrorMessage from '../../components/shared/ui/ErrorMessage';
import useForm from '../../hooks/useForm';
import useAuth from '../../hooks/useAuth';
import { GA_LABEL } from '../../constants/googleAnalytics';
import Button from '@app/components/shared/button/Button';

const propTypes = {};

const defaultProps = {};

function ResetPasswordBeginForm() {
	const { resetPasswordBegin, loading, error } = useAuth();
	const [success, setSuccess] = React.useState(false);

	const { handleSubmit, handleChange, data, errors } = useForm({
		initialValues: {
			email: '',
		},
		validations: {
			email: {
				required: {
					value: true,
					message: 'Please provide your email.',
				},
				pattern: {
					value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
					message: 'You must provide a valid email address',
				},
			},
		},
		onSubmit: async () => {
			const isSuccessful = await resetPasswordBegin({
				email: data?.email,
			});
			if (isSuccessful) {
				setSuccess(true);
			}
		},
	});

	if (success) {
		return (
			<Box justifyContent="center" alignItems="center">
				<Typography variant="h6" align="center">
					We sent the reset link. Please check your email!
				</Typography>
			</Box>
		);
	}

	return (
		<Box
			component="form"
			onSubmit={handleSubmit}
			noValidate
			sx={{ mt: 1 }}
		>
			<EmailInput
				data-ga-label={GA_LABEL.RESET_PASSWORD_EMAIL}
				id="email-input-login"
				name="email"
				label="Email"
				onChange={handleChange('email')}
				value={data.email}
				autoComplete="username"
				autoFocus
				required
			/>
			{errors?.email && <ErrorMessage message={errors?.email} />}
			<Box display="flex" justifyContent="flex-end">
				<Link to="/login" component={RouterLink}>
					Back to login
				</Link>
			</Box>
			<Button
				fullWidth
				sx={{ mt: 3, mb: 2 }}
				disabled={!data.email}
				onClick={handleSubmit}
				loading={loading}
			>
				Reset
			</Button>
			{error && <Typography color="error">{error}</Typography>}
		</Box>
	);
}

ResetPasswordBeginForm.propTypes = propTypes;
ResetPasswordBeginForm.defaultProps = defaultProps;

export default ResetPasswordBeginForm;
