import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useOffers } from '@app/pages/customerPortalNyop/components/OffersProvider';

import { mergeLineItems } from '../helpers';

import { OfferType, OrderType } from '@app/propTypes/types';
import { localizeCurrency } from '@app/helpers/localization.helpers';

const getColorTransitions = (textColor) => ({
	color: textColor,
	transition: 'color 0.5s ease',
	animation:
		textColor === 'text.primary' ? 'none' : `${textColor} 1s ease`,
	'@keyframes success.main': {
		'0%': { color: 'success.main' },
		'100%': { color: 'text.primary' },
	},
	'@keyframes error.main': {
		'0%': { color: 'error.main' },
		'100%': { color: 'text.primary' },
	},
});

function OrderTotal({
	initialTotal,
	order,
	offers,
	sx,
	acceptAllChecked,
}) {
	const [subTotal, setSubTotal] = React.useState(initialTotal);
	const [taxes, setTaxes] = React.useState(0);
	const [total, setTotal] = React.useState(initialTotal);
	const [textColor, setTextColor] = React.useState('text.primary');

	const { offerStatus } = useOffers();
	const prevTotalRef = React.useRef(initialTotal);
	const isMounted = React.useRef(true);

	const calculateSubTotal = React.useCallback(
		() =>
			mergeLineItems({ order, offers }).reduce((acc, item) => {
				if (item.isOffer) {
					const { offer } = item;
					const quantity = offer.fulfilled_quantity ?? offer.quantity;

					if (offer.canceled_at) {
						return acc;
					}
					if (offer.accepted_at || offer.counter_offer_accepted_at) {
						return (
							acc +
							Number(
								offer.counter_offer_accepted_at
									? offer.counter_offer_amount
									: offer.offer_amount,
							) *
								quantity
						);
					}
					if (acceptAllChecked && offer.bulk_counter_offer_amount) {
						return (
							acc + Number(offer.bulk_counter_offer_amount) * quantity
						);
					}
					if (
						offer.counter_offer_created_at &&
						(offerStatus[offer.id] === true || acceptAllChecked)
					) {
						return (
							acc + Number(offer.counter_offer_amount) * quantity
						);
					}
					if (offerStatus[offer.id] === false) {
						return acc;
					}
					return acc + Number(offer.offer_amount) * quantity;
				}
				return acc;
				// TODO: Decide if we want to show the full order total somewhere
				// return acc + Number(item.price_set.presentment_money.amount); // Uncomment this to show total order cost
			}, 0),
		[offers, order, offerStatus, acceptAllChecked],
	);

	const calculateTaxes = React.useCallback(
		() =>
			offers.reduce((acc, offer) => {
				if (offer.canceled_at || offerStatus[offer.id] === false) {
					return acc;
				}

				const originalTaxes = parseFloat(
					offer.original_line_item_taxes ?? 0,
				);
				const counterOfferTaxes = parseFloat(
					offer.counter_offer_taxes ?? 0,
				);

				if (
					offer.counter_offer_accepted_at ||
					(offer.counter_offer_created_at &&
						(offerStatus[offer.id] === true || acceptAllChecked))
				) {
					return acc + originalTaxes + counterOfferTaxes;
				}
				return acc + originalTaxes;
			}, 0),
		[offers, offerStatus, acceptAllChecked],
	);

	// Tracking ref keeps from throwing react warning for
	// setting state on an unmounted component
	React.useEffect(
		() => () => {
			isMounted.current = false;
		},
		[],
	);

	React.useEffect(() => {
		const newSubtotal = calculateSubTotal();
		const newTaxes = calculateTaxes();
		const newTotal = newSubtotal + newTaxes;

		setSubTotal(newSubtotal);
		setTotal(newTotal);
		setTaxes(newTaxes);

		if (newTotal < prevTotalRef.current && !acceptAllChecked) {
			setTextColor('error.main');
		} else if (newTotal > prevTotalRef.current) {
			setTextColor('success.main');
		}

		prevTotalRef.current = newTotal;

		// Reset color after a delay
		setTimeout(() => {
			if (isMounted.current) {
				setTextColor('text.primary');
			}
		}, 1000);
	}, [
		offerStatus,
		calculateSubTotal,
		calculateTaxes,
		acceptAllChecked,
	]);

	return (
		<Box sx={{ flexDirection: 'column', gap: 0.5, ...sx }}>
			{subTotal !== total ? (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<Typography
						variant="h5"
						component="span"
						fontWeight="bold"
						sx={getColorTransitions(textColor)}
					>
						Subtotal:
					</Typography>
					<Typography
						variant="h5"
						component="span"
						fontWeight="bold"
						sx={getColorTransitions(textColor)}
					>
						{localizeCurrency({
							customerLocale: order?.customer_locale,
							currencyCode:
								order?.subtotal_price_set?.presentment_money
									?.currency_code,
							amount: subTotal,
						})}
					</Typography>
				</Box>
			) : null}
			{taxes ? (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<Typography
						variant="h5"
						component="span"
						fontWeight="bold"
						sx={getColorTransitions(textColor)}
					>
						Taxes:
					</Typography>
					<Typography
						variant="h5"
						component="span"
						fontWeight="bold"
						sx={getColorTransitions(textColor)}
					>
						{localizeCurrency({
							customerLocale: order?.customer_locale,
							currencyCode:
								order?.subtotal_price_set?.presentment_money
									?.currency_code,
							amount: taxes,
						})}
					</Typography>
				</Box>
			) : null}

			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<Typography
					variant="h4"
					component="span"
					fontWeight="bold"
					sx={getColorTransitions(textColor)}
				>
					Total Due:
				</Typography>
				<Typography
					variant="h4"
					component="span"
					fontWeight="bold"
					sx={getColorTransitions(textColor)}
				>
					{localizeCurrency({
						customerLocale: order?.customer_locale,
						currencyCode:
							order?.subtotal_price_set?.presentment_money
								?.currency_code,
						amount: total,
					})}
				</Typography>
			</Box>
		</Box>
	);
}

OrderTotal.propTypes = {
	initialTotal: PropTypes.number,
	order: OrderType.isRequired,
	offers: PropTypes.arrayOf(OfferType).isRequired,
	sx: PropTypes.shape({}),
	acceptAllChecked: PropTypes.bool,
};

OrderTotal.defaultProps = {
	initialTotal: 0,
	sx: {},
	acceptAllChecked: false,
};

export default OrderTotal;
