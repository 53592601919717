import * as React from 'react';
import PropTypes from 'prop-types';

import { useEventBus } from './useEventBus';
import * as events from '@app/constants/eventConstants';

const InventoryReviewContext = React.createContext(undefined);

export function InventoryReviewProvider({ children }) {
	const { subscribe, unsubscribe } = useEventBus();

	const [selectedVariants, setSelectedVariants] = React.useState([]);

	React.useEffect(() => {
		const clearSelectedVariants = () => {
			setSelectedVariants([]);
		};

		// Clear state when changing shops or logging out
		subscribe(events.LOGOUT_EVENT, clearSelectedVariants);
		subscribe(
			events.ACTIVE_SHOP_CHANGED_EVENT,
			clearSelectedVariants,
		);

		return () => {
			unsubscribe(events.LOGOUT_EVENT, clearSelectedVariants);
			unsubscribe(
				events.ACTIVE_SHOP_CHANGED_EVENT,
				clearSelectedVariants,
			);
		};
	}, [subscribe, unsubscribe]);

	const memoedValue = React.useMemo(
		() => [selectedVariants, setSelectedVariants],
		[selectedVariants],
	);

	return (
		<InventoryReviewContext.Provider value={memoedValue}>
			{children}
		</InventoryReviewContext.Provider>
	);
}

InventoryReviewProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export function useInventoryReviewContext() {
	const context = React.useContext(InventoryReviewContext);
	if (context === undefined) {
		throw new Error(
			'useInventoryReviewContext must be used within a provider',
		);
	}
	return context;
}
