import axios from './index';

import * as urlHelpers from '../helpers/urlHelpers';

const apiUrl = '/api/products/collections';

export async function getCollections({
	collectionQuery,
	includeDeleted = false,
}) {
	const queryString = urlHelpers.cleanAndBuildUrlQueryParams({
		collectionQuery,
		includeDeleted,
	});

	const response = await axios.get(`${apiUrl}?${queryString}`);
	return response.data;
}

export async function syncCollection({ collectionId }) {
	const response = await axios.post(`${apiUrl}/sync`, {
		collectionId,
	});
	return response.data;
}
