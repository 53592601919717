import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Close from '@mui/icons-material/Close';
import Check from '@mui/icons-material/Check';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import constants from '@app/constants/index';

const smallWidth = '80px';
const largeWidth = '100px';

function AcceptDeclineButton({
	children,
	color,
	onClick,
	ariaChecked,
	disabled,
}) {
	const theme = useTheme();

	return (
		<Button
			role="radio"
			aria-checked={ariaChecked}
			disableRipple
			variant="text"
			disabled={disabled}
			sx={{
				borderRadius: '20px',
				width: { xs: smallWidth, sm: largeWidth },
				height: '40px',
				fontWeight: 'bold',
				transition: 'all 0.2s 0.1s ease',
				'&:hover': {
					opacity: 0.8,
				},
				'&:focus': {
					boxShadow: theme.shadows[5],
					backgroundColor: theme.palette.action.hover,
				},
				color,
			}}
			onClick={onClick}
		>
			{children}
		</Button>
	);
}

AcceptDeclineButton.propTypes = {
	children: PropTypes.node.isRequired,
	color: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
		.isRequired,
	onClick: PropTypes.func.isRequired,
	ariaChecked: PropTypes.bool.isRequired,
	disabled: PropTypes.bool.isRequired,
};

function AcceptDeclineToggle({ accepted, onChange, disabled }) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const getButtonColor = (isAccepted) => {
		if (isAccepted === accepted) {
			return theme.palette.common.white;
		}
		return theme.palette.action.active;
	};

	const getWidth = () => {
		if (isSmallScreen) {
			return smallWidth;
		}
		return largeWidth;
	};

	const getMaskBackgroundColor = () => {
		if (disabled) {
			return constants.colors.disabledActiveMask; // When disabled mask has smaller opacity, so it is not too dark
		}
		if (accepted) {
			return theme.palette.primary.main;
		}
		return theme.palette.error.main;
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<Box
				role="radiogroup"
				aria-labelledby="toggle-label"
				sx={{
					borderRadius: '20px',
					position: 'relative',
					background: theme.palette.text.disabled,
					boxShadow: '1px 1px 2px rgba(165, 165, 165) inset',
				}}
			>
				<Box
					sx={{
						width: { xs: smallWidth, sm: largeWidth },
						height: '40px',
						borderRadius: '20px',
						background: getMaskBackgroundColor(),
						position: 'absolute',
						boxShadow: `1px 0 2px ${
							disabled
								? theme.palette.text.disabled
								: theme.palette.primary.dark
						}`,
						transition: 'all 0.5s ease',
						transform: `translateX(${!accepted ? 0 : getWidth()})`,
					}}
				/>
				<AcceptDeclineButton
					ariaChecked={!disabled && !accepted}
					color={getButtonColor(false)}
					onClick={() => onChange(false)}
					disabled={disabled}
				>
					<Close
						sx={{
							display: { xs: 'none', sm: 'flex' },
							marginRight: 0.5,
						}}
					/>
					Decline
				</AcceptDeclineButton>
				<AcceptDeclineButton
					ariaChecked={!disabled && accepted}
					color={getButtonColor(true)}
					onClick={() => onChange(true)}
					disabled={disabled}
				>
					<Check
						sx={{
							display: { xs: 'none', sm: 'flex' },
							marginRight: 0.5,
						}}
					/>
					Accept
				</AcceptDeclineButton>
			</Box>
		</Box>
	);
}

AcceptDeclineToggle.propTypes = {
	accepted: PropTypes.bool, // is not set when react query refreshes the page on focus out
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};
AcceptDeclineToggle.defaultProps = {
	accepted: true,
	disabled: false,
};

export default AcceptDeclineToggle;
