import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import constants from '../../../constants/index';
import CancelledOfferItem from './CancelledOfferItem';

function StatusRenderer({
	status,
	fulfilledQuantity,
	quantity,
	cancellationReason,
}) {
	let color = 'grey';
	if (
		status === 'Accepted' ||
		status === 'Auto Accepted' ||
		status === 'Countered | Converted'
	) {
		color = constants.colors.primary;
	} else if (
		status === 'Countered | Lost' ||
		status === 'Countered | Cancelled'
	) {
		color = constants.colors.failure;
	} else if (status === 'Countered | Offer Out') {
		color = constants.colors.tertiary;
	}
	if (
		color === constants.colors.primary &&
		fulfilledQuantity !== null &&
		fulfilledQuantity !== quantity
	) {
		return (
			<Typography sx={{ color }}>
				{status} ({fulfilledQuantity} units)
			</Typography>
		);
	}
	if (status === 'Canceled' && cancellationReason) {
		return (
			<CancelledOfferItem reason={cancellationReason} color={color} />
		);
	}
	return <Typography sx={{ color }}>{status}</Typography>;
}

StatusRenderer.propTypes = {
	status: PropTypes.string.isRequired,
	quantity: PropTypes.number.isRequired,
	fulfilledQuantity: PropTypes.number,
	cancellationReason: PropTypes.string,
};

StatusRenderer.defaultProps = {
	fulfilledQuantity: null,
	cancellationReason: null,
};

export default StatusRenderer;
