import React from 'react';
import MuiDataTable from 'mui-datatables';
import Typography from '@mui/material/Typography';
import {
	formatDate,
	formatReadableDate,
} from '@app/helpers/dateHelpers';
import Loader from '@app/components/shared/loaders/CircularLoader';
import getNoTableMatch from '@app/components/shared/Table/components/NoTableMatch';
import CampaignNameRenderer from '@app/pages/campaignManagement/supplierMarketplaceTable/table/CampaignNameRenderer';
import { useMarketplaceCampaignContext } from '@app/hooks/useMarketplaceCampaignContext';

const DataColumnOptions = {
	viewColumns: false,
	filter: false,
	sort: false,
	download: false,
	display: 'excluded',
};

const DataIndex = {
	campaign_created_at: -11,
	rejected_at: -10,
	supplierVariants: -9,
	id: -8,
	minimum_offer: -7,
	starting_offer: -6,
	description: -5,
	automation_rules: -4,
	end_date: -3,
	start_date: -2,
	variants: -1,
};

function getDataColumns() {
	return Object.keys(DataIndex).map((name) => ({
		name,
		options: DataColumnOptions,
	}));
}

function getStatusRender(value, data) {
	const rejectedAt = data.rowData.at(DataIndex.rejected_at);
	const completedAt = data.rowData.at(DataIndex.campaign_created_at);
	if (rejectedAt) {
		return <Typography>Rejected</Typography>;
	}
	if (completedAt) {
		return <Typography>Campaign Created</Typography>;
	}
	return <Typography>In Review</Typography>;
}

function getVariantsCountRenderer(value) {
	return <Typography>{value.length}</Typography>;
}

function getNameRenderer(value, data) {
	const created = data.rowData.at(DataIndex.campaign_created_at);
	return (
		<CampaignNameRenderer
			campaignId={data.rowData.at(DataIndex.id)}
			description={value}
			disabled={!created}
		/>
	);
}

const columns = [
	{
		name: 'description',
		label: 'Name',
		options: {
			customBodyRender: getNameRenderer,
			filter: false,
		},
	},
	{
		name: 'shop',
		label: 'Shop',
		options: {
			filter: false,
		},
	},
	{
		name: 'variants',
		label: 'Variants Count',
		options: {
			customBodyRender: getVariantsCountRenderer,
			filter: false,
		},
	},
	{
		name: 'start_date',
		label: 'Start Date',
		options: {
			filter: false,
			customBodyRender: (value) => formatReadableDate(value),
		},
	},
	{
		name: 'end_date',
		label: 'End Date',
		options: {
			filter: false,
			customBodyRender: (value) => formatReadableDate(value),
		},
	},
	{
		name: 'id',
		label: 'Status',
		options: {
			customBodyRender: getStatusRender,
			filter: false,
			sort: false,
		},
	},
	...getDataColumns(),
];

function SupplierMarketplaceCampaignsTable() {
	const { campaigns, campaignsLoading, campaignsRefetching } =
		useMarketplaceCampaignContext();

	if (campaignsLoading) {
		return <Loader fullPage />;
	}

	return (
		<MuiDataTable
			title="Marketplace Campaigns"
			data={campaigns || []}
			columns={columns}
			options={{
				elevation: 0,
				count: campaigns?.length || 0,
				rowsPerPage: 20,
				rowsPerPageOptions: [10, 20, 50],
				filter: true,
				filterType: 'dropdown',
				responsive: 'vertical',
				enableNestedDataAccess: '.',
				selectableRowsHideCheckboxes: true,
				viewColumns: true,
				textLabels: {
					body: {
						noMatch: getNoTableMatch({
							isLoading: campaignsRefetching,
							text: 'Sorry, no Marketplace Campaigns found',
						}),
					},
				},
				downloadOptions: {
					filename: `marketplace_campaigns_${formatDate(
						new Date(),
					)}.csv`,
				},
				tableId: 'marketplace-campaigns',
				storageKey: 'marketplace-campaigns',
				serverSide: false,
			}}
		/>
	);
}

export default SupplierMarketplaceCampaignsTable;
