import PropTypes from 'prop-types';
import ExecutionProgress from '@app/components/muiTable/elements/ExecutionProgress';

function OffersExecutionProgress({ offers }) {
	let counteredUnits = 0;
	let convertedUnits = 0;
	let lostUnits = 0;
	let total = 0;

	offers.forEach((offer) => {
		if (offer.canceled_at) {
			lostUnits += offer.quantity;
			total += offer.quantity;
		} else if (
			offer.counter_offer_created_at &&
			!offer.accepted_at &&
			!offer.counter_offer_accepted_at
		) {
			counteredUnits += offer.quantity;
			total += offer.quantity;
		} else if (offer.accepted_at || offer.counter_offer_accepted_at) {
			convertedUnits += offer.fulfilled_quantity || offer.quantity;
			total += offer.fulfilled_quantity || offer.quantity;
		} else {
			total += offer.quantity;
		}
	});

	return (
		<ExecutionProgress
			counteredUnits={counteredUnits}
			convertedUnits={convertedUnits}
			lostUnits={lostUnits}
			total={total}
		/>
	);
}

OffersExecutionProgress.propTypes = {
	offers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			offer_amount: PropTypes.string.isRequired,
			counter_offer_amount: PropTypes.string,
			accepted_at: PropTypes.string,
			counter_offer_created_at: PropTypes.string,
			counter_offer_accepted_at: PropTypes.string,
			canceled_at: PropTypes.string,
		}),
	).isRequired,
};

OffersExecutionProgress.defaultProps = {};

export default OffersExecutionProgress;
