import {
	add,
	differenceInDays,
	differenceInHours,
	format,
	getHours,
	getMinutes,
	intervalToDuration,
	isBefore,
	isValid,
	parseISO,
} from 'date-fns';

export const formatDate = (date, formatStr = 'MM/dd/yy') =>
	format(new Date(date), formatStr);

export const getNumberOfDays = (start, end) => {
	const date1 = new Date(start);
	const date2 = new Date(end);

	// One day in milliseconds
	const oneDay = 1000 * 60 * 60 * 24;

	// Calculating the time difference between two dates
	const diffInTime = date2.getTime() - date1.getTime();

	// Calculating the no. of days between two dates
	const diffInDays = Math.round(diffInTime / oneDay);

	return diffInDays;
};

export const getDaysBetween = (start, end) => {
	const startDate = new Date(start);
	const endDate = new Date(end);
	const numberOfDays = differenceInDays(endDate, startDate);
	return numberOfDays;
};

export const getHoursBetween = (start, end) => {
	const startDate = new Date(start);
	const endDate = new Date(end);
	return differenceInHours(endDate, startDate);
};

export const addDays = (date, days) => {
	const dateObj = new Date(date);
	dateObj.setDate(dateObj.getDate() + days);
	return dateObj.toLocaleDateString();
};

export const formatReadableDate = (
	date,
	locale = 'en-us',
	opts = {},
) => {
	if (!date) {
		return '';
	}
	const dateObj = new Date(date);
	const options = {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		...opts,
	};
	return dateObj.toLocaleDateString(locale, options);
};

export const formatReadableDateTime = (
	date,
	locale = 'en-us',
	opts = {},
) => {
	if (!date) {
		return '';
	}
	const dateObj = new Date(date);
	const options = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		...opts,
	};
	return dateObj.toLocaleDateString(locale, options);
};

export function formatISOTimestamp(
	isoTimestamp,
	locale = 'en-US', // eslint-disable-line
	opts,
) {
	const date = new Date(isoTimestamp);
	return date.toLocaleDateString(locale, opts);
}

export const isDate = (date) => isValid(new Date(date));

export const getTimeBetween = ({ start, end }) =>
	intervalToDuration({
		start: new Date(start),
		end: new Date(end),
	});

export const dateIsBefore = ({ start, end }) =>
	isBefore(new Date(start), new Date(end));

export const addDaysToDate = (date, days) => {
	const parsedDate = typeof date === 'string' ? parseISO(date) : date;
	const resetDate = new Date(date).setHours(0, 0, 0, 0);
	const hours = getHours(parsedDate);
	const minutes = getMinutes(parsedDate);
	return add(resetDate, { days, hours, minutes });
};

export const getNyopPresetDate = () => {
	const date = new Date();
	return new Date(
		date.setHours(date.getHours(), date.getMinutes(), 0, 0),
	);
};
