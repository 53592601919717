import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { getNumericId } from '@app/lib/shopify';
import Button from '../shared/button/Button';

function CampaignsList({ variantId, campaigns }) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			{campaigns?.map((campaign, index) => {
				const campaignId = getNumericId(campaign.id);

				let campaignLink = `campaigns/nyop/${campaignId}`;
				if (variantId) {
					const numericId = getNumericId(variantId);
					campaignLink = `${campaignLink}/${numericId}`;
				}

				return (
					<React.Fragment
						key={`variant-table-campaign-link-${campaign?.id}-${index}`} // eslint-disable-line
					>
						<Button variant="text" component={Link} to={campaignLink}>
							<Typography
								sx={{
									maxWidth: 200,
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									display: '-webkit-box',
									WebkitLineClamp: '2',
									WebkitBoxOrient: 'vertical',
								}}
							>
								{campaign.title}
							</Typography>
						</Button>
						{index < campaigns.length - 1 && (
							<Divider orientation="vertical" flexItem />
						)}
					</React.Fragment>
				);
			})}
		</Box>
	);
}

CampaignsList.propTypes = {
	variantId: PropTypes.string,
	campaigns: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			title: PropTypes.string,
		}),
	),
};

CampaignsList.defaultProps = {
	variantId: null,
	campaigns: null,
};

export default CampaignsList;
