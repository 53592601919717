import React from 'react';
import { default as MaterialSnackbar } from '@mui/material/Snackbar'; // eslint-disable-line
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import constants from '@app/constants';

const Alert = React.forwardRef((props, ref) => (
	<MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

function Snackbar({
	open,
	setOpen,
	autoHideDuration,
	severity,
	message,
	anchorOrigin,
}) {
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') return;
		setOpen(false);
	};

	return (
		<MaterialSnackbar
			open={open}
			autoHideDuration={autoHideDuration}
			onClose={handleClose}
			anchorOrigin={anchorOrigin}
			style={{ zIndex: constants.zIndex.bannerAlert }}
			action={
				<IconButton
					size="small"
					aria-label="close"
					color="inherit"
					onClick={handleClose}
				>
					<CloseIcon fontSize="small" />
				</IconButton>
			}
		>
			<Alert
				onClose={handleClose}
				severity={severity}
				sx={{
					width: '100%',
					mt: 0,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				{message}
			</Alert>
		</MaterialSnackbar>
	);
}

Snackbar.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	autoHideDuration: PropTypes.number,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
		.isRequired,
	severity: PropTypes.oneOf(['success', 'error', 'warning', 'info'])
		.isRequired,
	anchorOrigin: PropTypes.shape({
		horizontal: PropTypes.string,
		veritcal: PropTypes.string,
	}),
};

Snackbar.defaultProps = {
	autoHideDuration: 5000,
	anchorOrigin: {
		vertical: 'top',
		horizontal: 'center',
	},
};

export default Snackbar;
