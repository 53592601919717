import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import PropTypes from 'prop-types';

function ProductSelector({
	enabled,
	options,
	currentProduct,
	setCurrentProduct,
}) {
	const onSelect = React.useCallback(
		(event, newValue) => {
			setCurrentProduct(newValue);
		},
		[setCurrentProduct],
	);

	return (
		<FormControl sx={{ width: '100%', mb: 1 }}>
			<Autocomplete
				disablePortal
				disableClearable
				id="product-example-autocomplete"
				options={options}
				onChange={onSelect}
				value={currentProduct.full_title}
				disabled={!enabled}
				renderInput={(params) => (
					<TextField
						{...params}
						value={currentProduct.full_title}
						placeholder="Select your product"
					/>
				)}
			/>
		</FormControl>
	);
}

ProductSelector.propTypes = {
	enabled: PropTypes.bool.isRequired,
	options: PropTypes.arrayOf(PropTypes.string).isRequired,
	currentProduct: PropTypes.shape({
		full_title: PropTypes.string,
	}).isRequired,
	setCurrentProduct: PropTypes.func.isRequired,
};

export default ProductSelector;
