import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@app/components/shared/button/Button';

export default function HorizontalLinearStepper({
	steps,
	reset,
	onComplete,
	disabled,
}) {
	const [activeStep, setActiveStep] = React.useState(0);
	const [skipped, setSkipped] = React.useState(new Set());

	const isStepOptional = (step) => step.optional;

	const isStepSkipped = (step) => skipped.has(step);

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);

		if (
			typeof onComplete === 'function' &&
			activeStep === steps.length - 1
		) {
			onComplete();
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleSkip = () => {
		if (!isStepOptional(activeStep)) {
			// Should only happen if someone is trying to break things
			throw new Error("You can't skip a step that isn't optional.");
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped((prevSkipped) => {
			const newSkipped = new Set(prevSkipped.values());
			newSkipped.add(activeStep);
			return newSkipped;
		});
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	return (
		<Box sx={{ width: '100%', margin: 'auto' }}>
			<Stepper activeStep={activeStep} orientation="vertical">
				{steps.map((step, index) => {
					const stepProps = {};
					const labelProps = {};

					if (step.optional) {
						labelProps.optional = (
							<Typography variant="caption">Optional</Typography>
						);
					}

					if (isStepSkipped(index)) {
						stepProps.completed = false;
					}

					return (
						<Step key={step.labelText} {...stepProps}>
							<StepLabel {...labelProps}>
								<Box sx={{ fontWeight: 'bold' }} component="span">
									{step.labelText}
								</Box>
							</StepLabel>
							<StepContent>
								{step.description && (
									<Box component="div">{step.description}</Box>
								)}
								<Button
									onClick={handleNext}
									sx={{ mt: 1, mr: 1 }}
									disabled={index === steps.length - 1 && disabled}
								>
									{index === steps.length - 1 ? 'Finish' : 'Continue'}
								</Button>
								<Button
									variant="text"
									disabled={index === 0}
									onClick={handleBack}
									sx={{ mt: 1, mr: 1 }}
								>
									Back
								</Button>
								{isStepOptional(activeStep) && (
									<Button
										variant="text"
										onClick={handleSkip}
										sx={{ mt: 1, mr: 1, color: 'text.secondary' }}
									>
										Skip
									</Button>
								)}
							</StepContent>
						</Step>
					);
				})}
			</Stepper>
			{activeStep === steps.length && (
				<Paper square elevation={0} sx={{ p: 3 }}>
					<Typography>
						All steps completed - you&apos;re finished
					</Typography>
					{reset && (
						<Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
							Reset
						</Button>
					)}
				</Paper>
			)}
		</Box>
	);
}

HorizontalLinearStepper.propTypes = {
	steps: PropTypes.arrayOf(
		PropTypes.shape({
			labelText: PropTypes.string,
			description: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.node,
			]),
			optional: PropTypes.bool,
		}),
	).isRequired,
	onComplete: PropTypes.func,
	reset: PropTypes.bool,
	disabled: PropTypes.bool,
};

HorizontalLinearStepper.defaultProps = {
	reset: true,
	onComplete: null,
	disabled: false,
};
