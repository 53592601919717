import React from 'react';

import SettingCheckbox from '../components/settingCheckbox/SettingCheckbox';
import {
	useGetCheckoutValidation,
	useUpdateCheckoutValidation,
} from '@app/hooks/useCheckoutValidation';

function CheckoutValidation() {
	const [error, setError] = React.useState('');
	const {
		data: checkoutValidation,
		isLoading: isCheckoutValidationLoading,
	} = useGetCheckoutValidation();
	const { mutate: update, isLoading: isUpdateLoading } =
		useUpdateCheckoutValidation();

	const handleRecalculateTaxesChange = React.useCallback(
		async (e) => {
			try {
				const { checked: enabled } = e.target;
				update({ enabled });
				setError('');
			} catch (err) {
				console.error(err);
				setError(err.message);
			}
		},
		[update],
	);

	return (
		<SettingCheckbox
			title="Checkout Validation Settings"
			subtitle="Optimize checkout flow by activating validation checks. This feature ensures that carts align with 'Name Your Price' campaign terms and discount policies."
			helperText="Enable to block checkouts when campaigns have ended, to disallow combining discount codes, and to prevent orders with incompatible tax settings due to import duties."
			checkboxText="Enable checkout validation"
			checked={checkoutValidation?.node?.enabled}
			onChange={handleRecalculateTaxesChange}
			error={error}
			isLoading={isCheckoutValidationLoading || isUpdateLoading}
		/>
	);
}

export default CheckoutValidation;
