import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function MetricWidget({ name, value, height, sx }) {
	return (
		<Paper
			sx={{
				height,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column',
				p: 2,
				...sx,
			}}
		>
			<Box
				sx={{
					width: '80%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
				}}
			>
				<Typography
					paragraph
					variant="h5"
					sx={{ textAlign: 'center' }}
				>
					{name}
				</Typography>
				<Box sx={{ width: '100%', textAlign: 'center' }}>
					<Typography color="primary" variant="h4">
						{value}
					</Typography>
				</Box>
			</Box>
		</Paper>
	);
}

MetricWidget.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.element,
	]).isRequired,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	sx: PropTypes.shape({}),
};

MetricWidget.defaultProps = {
	height: '150px',
	sx: {},
};

export default MetricWidget;
