import React from 'react';
import PropTypes from 'prop-types';
import MUITableBody from '@mui/material/TableBody';
import ProductRow from './components/productRow';
import { VariantType } from '@app/propTypes/types';

function TableBody({
	products,
	threshold,
	thresholds,
	setThresholds,
	fixedCostBuffer,
}) {
	return (
		<MUITableBody>
			{Object.entries(products).map(
				([productId, productVariants]) => (
					<ProductRow
						variants={productVariants}
						thresholds={thresholds}
						setThresholds={setThresholds}
						defaultThreshold={threshold}
						fixedCostBuffer={fixedCostBuffer}
						key={`edit-by-product-row-${productId}`}
					/>
				),
			)}
		</MUITableBody>
	);
}

TableBody.propTypes = {
	products: PropTypes.objectOf(PropTypes.arrayOf(VariantType))
		.isRequired,
	thresholds: PropTypes.objectOf(PropTypes.string).isRequired,
	setThresholds: PropTypes.func.isRequired,
	threshold: PropTypes.number.isRequired,
	fixedCostBuffer: PropTypes.number,
};

TableBody.defaultProps = {
	fixedCostBuffer: 0,
};

export default TableBody;
