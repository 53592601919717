import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InfoPopover from '@app/components/shared/ui/InfoPopoverIcon';
import { capitalizeFirstLetter } from '@app/helpers/stringHelpers';

function CancelledOfferItem({ reason, color }) {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '5px',
			}}
		>
			<Typography sx={{ color }}>Canceled</Typography>
			<InfoPopover
				popoverVerticalPlacement="top"
				iconStyles={{ fontSize: 16, color }}
				textStyles={{
					fontSize: 14,
				}}
			>
				{capitalizeFirstLetter(reason)}
			</InfoPopover>
		</Box>
	);
}

CancelledOfferItem.propTypes = {
	reason: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
};

export default CancelledOfferItem;
