import * as React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import CampaignTable from './CampaignTable';
import NyopCampaignTable from './nyopTable';
import RetailerMarketplaceCampaigns from './marketplaceTable';
import { ModalProvider } from '../../hooks/useModal';
import ProfilerProvider from '../../providers/ProfilerProvider';
import constants from '../../constants';
import SupplierMarketplaceCampaigns from './supplierMarketplaceTable';
import useAuth from '@app/hooks/useAuth';

function CampaignManagement({ type }) {
	const { merchant } = useAuth();
	return (
		<ModalProvider>
			<ProfilerProvider id="CampaignManagement">
				{type === constants.campaignTypes.PREORDER ? (
					<CampaignTable type={constants.campaignTypes.PREORDER} />
				) : null}
				{type === constants.campaignTypes.NYOP ? (
					<NyopCampaignTable />
				) : null}
				{type === constants.campaignTypes.MARKETPLACE &&
				merchant.is_marketplace ? (
					<RetailerMarketplaceCampaigns />
				) : null}
				{type === constants.campaignTypes.MARKETPLACE &&
				!merchant.is_marketplace ? (
					<SupplierMarketplaceCampaigns />
				) : null}
				<Outlet />
			</ProfilerProvider>
		</ModalProvider>
	);
}

CampaignManagement.propTypes = {
	type: PropTypes.string.isRequired,
};

export default CampaignManagement;
