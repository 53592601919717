import PropTypes from 'prop-types';
import React from 'react';

import LockIcon from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

import constants from '@app/constants';
import campaignModalStyles from '@app/styles/campaignModalStyles';
import Button from '../button/Button';

const containerStyle = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	position: 'absolute',
	gap: 2,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxHeight: '90%',
	overflowY: 'scroll',
	overflowX: 'hidden',
	bgcolor: 'background.paper',
	borderRadius: 0.7,
	boxShadow: 24,
	pt: 10,
	pb: 10,
};

function disableChildren(element) {
	return React.Children.map(element, (child) => {
		if (!React.isValidElement(child)) {
			return child;
		}

		const childProps = {
			...child.props,
			disabled: true,
			buttonDisabled: true,
			enabled: false,
		};

		if (childProps.children) {
			childProps.children = (
				<>{disableChildren(childProps.children)}</>
			);
		}

		return React.cloneElement(child, childProps);
	});
}

function LockedComponent({
	children,
	sx,
	open,
	description,
	buttonText,
	href,
	modalInfoSx,
}) {
	const theme = useTheme();

	const disabledChildren = disableChildren(children);

	if (!open) {
		return children;
	}

	return (
		<Box position="relative">
			<Box
				position="absolute"
				top={0}
				zIndex={constants.zIndex.lockedComponentWrapper}
				width="100%"
				height="100%"
			>
				<Box sx={[campaignModalStyles, containerStyle, modalInfoSx]}>
					<LockIcon
						sx={{
							fontSize: '60px',
							color: theme.palette.primary.main,
						}}
					/>
					<Box textAlign="center">{description}</Box>
					{buttonText && (
						<Button
							component={Link}
							target="_blank"
							rel="noopener noreferrer"
							href={href}
						>
							{buttonText}
						</Button>
					)}
				</Box>
			</Box>
			<Box
				position="absolute"
				top={0}
				left={0}
				width="100%"
				height="100%"
				bgcolor="rgba(0, 0, 0, 0.65)"
				zIndex={constants.zIndex.lockedComponent}
				sx={sx}
			/>
			{disabledChildren}
		</Box>
	);
}

LockedComponent.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	sx: PropTypes.instanceOf(Object),
	open: PropTypes.bool,
	description: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	buttonText: PropTypes.string,
	href: PropTypes.string,
	modalInfoSx: PropTypes.instanceOf(Object),
};

LockedComponent.defaultProps = {
	sx: {},
	open: true,
	description: null,
	buttonText: '',
	href: '',
	modalInfoSx: {},
};

export default LockedComponent;
