import * as React from 'react';
import PropTypes from 'prop-types';
import {
	MutationCache,
	QueryCache,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { errorHandler, successHandler } from '../lib/SnackbarUtils';

const QUERY_STALE_TIME = 60_000; // cache expires after a minute

const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError: errorHandler,
	}),
	mutationCache: new MutationCache({
		onSuccess: (_, __, ___, mutation) => {
			if (!mutation.meta?.toastDisabled) {
				successHandler(mutation.options?.msg);
			}
		},
		onError: errorHandler,
	}),
	defaultOptions: {
		queries: {
			staleTime: QUERY_STALE_TIME,
		},
	},
});

function ReactQueryProvider({ children }) {
	return (
		<QueryClientProvider client={queryClient}>
			{children}
			<ReactQueryDevtools
				initialIsOpen={false}
				position="bottom-right"
			/>
		</QueryClientProvider>
	);
}

ReactQueryProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export default ReactQueryProvider;
