import * as React from 'react';

import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MetricWidget from '@app/components/shared/ui/MetricWidget';
import ProgressBar from '@app/components/shared/loaders/ProgressBar';
import constants from '@app/constants/index';
import { OffersAggregations } from '../types';
import OffersChart from '@app/components/charts/OffersChart';
import { getThresholdPrice } from '@app/helpers/campaignHelpers';
import { CampaignType } from '@app/propTypes/campaign';

function Summary({
	variant,
	offers,
	formatMerchantCurrency,
	campaign = { automation_rules: null },
}) {
	const variantPrice = variant?.msrp || variant?.price;
	const thresholdPrice = getThresholdPrice({
		automationRules: campaign.automation_rules,
		id: variant.id,
		price: variantPrice,
	});

	return (
		<Box sx={{ width: '100%' }}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={8}>
					<Box sx={{ height: '100%' }}>
						<Card
							sx={{
								height: '100%',
								p: 1,
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<OffersChart
								offers={offers}
								formatMerchantCurrency={formatMerchantCurrency}
								msrp={variantPrice}
								thresholdPrice={thresholdPrice}
							/>
						</Card>
					</Box>
				</Grid>

				<Grid item md={4} xs={12}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 1,
							height: '100%',
						}}
					>
						<MetricWidget
							name="MSRP"
							value={formatMerchantCurrency(
								variant.msrp || variant.price,
							)}
						/>
						<MetricWidget
							name="Threshold Price"
							value={formatMerchantCurrency(thresholdPrice)}
						/>

						<MetricWidget
							name="Execution Progress"
							value={
								<Box sx={{ width: '100%' }}>
									<ProgressBar
										data={[
											{
												label: 'Accepted offers units',
												value:
													variant.aggregations
														.acceptedOffersInventory +
													variant.aggregations
														.counteredAndAcceptedOffersInventory,
												color: constants.colors.primary,
											},
											{
												label: 'Countered offers units',
												value:
													variant.aggregations
														.counteredOffersInventory,
												color: constants.colors.tertiary,
											},
											{
												label: 'Canceled offers units',
												value:
													variant.aggregations
														.canceledOffersInventory,
												color: constants.colors.failure,
											},
										]}
										total={variant.aggregations.offersInventory}
										showLegend
										legendInPercentage
									/>
								</Box>
							}
						/>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

Summary.propTypes = {
	variant: PropTypes.shape({
		price: PropTypes.string,
		msrp: PropTypes.string,
		id: PropTypes.string,
		aggregations: OffersAggregations.isRequired,
	}).isRequired,
	offers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			offer_amount: PropTypes.string.isRequired,
			counter_offer_amount: PropTypes.string,
			accepted_at: PropTypes.string,
			counter_offer_created_at: PropTypes.string,
			counter_offer_accepted_at: PropTypes.string,
			canceled_at: PropTypes.string,
		}),
	).isRequired,
	formatMerchantCurrency: PropTypes.func.isRequired,
	campaign: CampaignType,
};

export default Summary;
