import React from 'react';
import MUIDataTable from 'mui-datatables';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import {
	createTheme,
	ThemeProvider,
	useTheme,
} from '@mui/material/styles';
import TitleWithImage from '@app/components/muiTable/elements/TitleWithImage';
import {
	getProductLink,
	getProductVariantLink,
} from '@app/lib/shopify';
import { usePagination } from '@app/hooks/usePagination';
import { useProductsByIds } from '@app/hooks/useProducts';
import useAuth from '@app/hooks/useAuth';

const dataColumnOptions = {
	viewColumns: false,
	filter: false,
	sort: false,
	download: false,
	display: 'excluded',
};

export const dataIndex = {
	shop: -4,
	id: -3,
	image_url: -2,
	handle: -1,
};

function getDataColumns() {
	return Object.keys(dataIndex).map((name) => ({
		name,
		options: dataColumnOptions,
	}));
}

export function getProductTitle(value, data) {
	const imageUrl = data.rowData.at(dataIndex.image_url);
	const handle = data.rowData.at(dataIndex.handle);
	const id = data.rowData.at(dataIndex.id);
	const shop = data.rowData.at(dataIndex.shop);

	return (
		<TitleWithImage
			title={value}
			imageUrl={imageUrl}
			subtitle={handle}
			url={getProductLink(shop, id)}
		/>
	);
}

function SelectedProductsTable({
	defaultSelectedVariants,
	style,
	customToolbar,
	additionalProductColumns,
	additionalVariantColumns,
	withProductTitle,
	withVariantTitle,
}) {
	const { merchant } = useAuth();
	const theme = useTheme();

	// business logic state
	const [selectedVariants, setSelectedVariants] = React.useState(
		defaultSelectedVariants,
	);

	const productIds = Array.from(
		new Set(selectedVariants.map((variant) => variant.product_id)),
	);

	const { data: productsWithoutPagination = [] } = useProductsByIds({
		productIds,
		cacheKey: `${merchant.id}|${productIds}`,
	});

	// filtering/pagination state values
	const [perPage, setPerPage] = React.useState(20);

	// table state
	const [expandedProductsIds, setExpandedProductsIds] =
		React.useState([]);

	React.useEffect(() => {
		setSelectedVariants(defaultSelectedVariants);
	}, [defaultSelectedVariants]);

	const {
		page,
		setPage,
		dataWithPagination: products,
	} = usePagination({
		data: productsWithoutPagination,
		resultsPerPage: perPage,
	});

	const defaultColumns = [
		withProductTitle
			? {
					name: 'title',
					label: 'Product',
					options: {
						customBodyRender: getProductTitle,
					},
			  }
			: null,
		...additionalProductColumns,
		// EXTRA DATA
		...getDataColumns(),
	].filter(Boolean);

	const rowsExpanded = products
		.map((product, index) => [product.id, index])
		.filter(([productId]) => expandedProductsIds.includes(productId))
		.map(([, index]) => index);

	const rowsSelected = products
		.map((product, index) => [product, index])
		.map(([, index]) => index);

	const options = {
		selectableRows: 'none',
		storageKey: 'inventory',
		serverSide: true,
		filter: false,
		search: false,
		download: false,
		print: false,
		viewColumns: false,
		selectToolbarPlacement: 'none',
		responsive: 'standard',
		expandableRows: true,
		expandableRowsHeader: false,
		expandableRowsOnClick: true,
		customToolbar: () => customToolbar,
		rowsExpanded,
		rowsSelected,
		isRowExpandable: () => true,
		renderExpandableRow: (rowData) => {
			const id = rowData.at(dataIndex.id);
			const shop = rowData.at(dataIndex.shop);
			const variants = selectedVariants.filter(
				(variant) => variant.product_id === id,
			);
			const imageUrl = rowData.at(dataIndex.image_url);

			return variants.map((variant) => (
				<TableRow key={`selectable-product-row-${variant.id}`}>
					<TableCell />
					{withVariantTitle && (
						<TableCell sx={{ pb: '4px', pt: '4px', pl: '2rem' }}>
							<TitleWithImage
								title={variant.title}
								imageUrl={variant.image_url || imageUrl}
								subtitle={variant.sku}
								url={getProductVariantLink(shop, id, variant.id)}
							/>
						</TableCell>
					)}
					{additionalVariantColumns(variant.id, rowData)}
				</TableRow>
			));
		},
		onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) => {
			let newExpandedProductsIds = expandedProductsIds;
			currentRowsExpanded.forEach((currentRow) => {
				const isExpanded = allRowsExpanded.find(
					(row) => row.dataIndex === currentRow.dataIndex,
				);

				const productId = products[currentRow.dataIndex].id;
				if (isExpanded && !expandedProductsIds.includes(productId)) {
					newExpandedProductsIds.push(productId);
				} else {
					newExpandedProductsIds = newExpandedProductsIds.filter(
						(p) => p !== productId,
					);
				}
			});

			setExpandedProductsIds(newExpandedProductsIds);
		},
		// Pagination configurations
		count: productsWithoutPagination.length ?? 200,
		page,
		rowsPerPage: perPage,
		rowsPerPageOptions: [10, 20, 50, 200],
		onChangeRowsPerPage: (value) => {
			setPerPage(value);
			setPage(0);
		},
		onChangePage: (newPage) => {
			setPage(newPage);
		},
	};

	const getMuiTheme = () => {
		const overrides = {
			components: {
				MUIDataTable: {
					styleOverrides: {
						root: {},
					},
				},
				MuiToolbar: {
					styleOverrides: {
						root: {
							'&.MuiToolbar-root > :first-of-type': {
								// We need to remove the title container that moves toolbar to the right on wide layouts
								display: 'none',
							},
							display: customToolbar ? '' : 'none',
							paddingLeft: '0px !important',
							paddingRight: '0px !important',
						},
					},
				},
			},
		};
		if (style?.removeFrame) {
			overrides.components.MUIDataTable.styleOverrides.root.boxShadow =
				'none';
		}

		return createTheme(theme, overrides);
	};

	return (
		<ThemeProvider theme={getMuiTheme()}>
			<MUIDataTable
				title=""
				data={products}
				columns={defaultColumns}
				options={options}
			/>
		</ThemeProvider>
	);
}

SelectedProductsTable.propTypes = {
	defaultSelectedVariants: PropTypes.arrayOf(
		PropTypes.shape({ id: PropTypes.string }),
	),
	style: PropTypes.shape({
		removeFrame: PropTypes.bool,
	}),
	customToolbar: PropTypes.node,
	additionalProductColumns: PropTypes.arrayOf(PropTypes.shape({})),
	additionalVariantColumns: PropTypes.func,
	withProductTitle: PropTypes.bool,
	withVariantTitle: PropTypes.bool,
};

SelectedProductsTable.defaultProps = {
	defaultSelectedVariants: [],
	style: null,
	customToolbar: null,
	additionalProductColumns: [],
	additionalVariantColumns: () => null,
	withProductTitle: true,
	withVariantTitle: true,
};

export default SelectedProductsTable;
