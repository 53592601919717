import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

import constants from '../constants';

// Create a theme instance.
const theme = createTheme({
	// TODO: Figure out what to do about these fonts
	typography: {
		fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(
			',',
		),
	},
	palette: {
		// mode: 'dark', //TODO: implement dark mode switch
		primary: {
			main: constants.colors.primary,
			contrastText: '#FFF',
		},
		info: {
			main: constants.colors.info,
			contrastText: '#FFF',
		},
		secondary: {
			main: constants.colors.secondary,
			contrastText: '#FFF',
		},
		tertiary: {
			main: constants.colors.tertiary,
			contrastText: '#FFF',
		},
		subdued: {
			main: grey[200],
			contrastText: grey[800],
		},
		accept: {
			main: '#47D5B5',
			accented: '#05CFA2',
		},
		counter: {
			main: '#FFB428',
			accented: '#FFA600',
		},
		variability: {
			main: '#06C096',
			accented: '#03AA85',
		},
		pending: {
			main: '#918c8c',
		},
		unused: {
			main: '#CCCCCC',
			accented: '#BABABA',
		},
		warning: {
			main: '#DE8B56',
			contrastText: '#FFF',
		},
		error: {
			main: constants.colors.failure,
		},
		success: {
			main: '#00CD9F',
		},
		text: {
			main: '#FFFFFF',
			secondary: '#949494',
		},
		button: {
			primary: {
				main: '#00CD9F',
			},
			secondary: {
				main: '#282828',
			},
		},
	},
	components: {
		MUIDataTable: {
			styleOverrides: {
				root: {
					borderTopRightRadius: 0,
					borderTopLeftRadius: 0,
				},
			},
		},
	},
});

export default theme;
