import * as React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import PersonIcon from '@mui/icons-material/Person';

const propTypes = {
	id: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	helperText: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	margin: PropTypes.string,
	fullWidth: PropTypes.bool,
	autoFocus: PropTypes.bool,
	// https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
	autoComplete: PropTypes.string,
	sx: PropTypes.shape({}),
	required: PropTypes.bool,
	disabled: PropTypes.bool,
};

const defaultProps = {
	id: 'username-email-input',
	label: 'Email',
	helperText: '',
	placeholder: 'jane.smith@exmaple.com',
	margin: 'normal',
	fullWidth: true,
	autoFocus: false,
	autoComplete: 'username',
	sx: {},
	required: true,
	disabled: false,
};

function EmailInput({
	id,
	name,
	label,
	helperText,
	placeholder,
	onChange,
	value,
	sx,
	margin,
	autoFocus,
	autoComplete,
	fullWidth,
	required,
	disabled,
}) {
	return (
		<TextField
			id={id}
			name={name}
			type="email"
			label={label}
			helperText={helperText}
			placeholder={placeholder}
			onChange={onChange}
			value={value}
			margin={margin}
			sx={sx}
			autoFocus={autoFocus}
			autoComplete={autoComplete}
			fullWidth={fullWidth}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<PersonIcon />
					</InputAdornment>
				),
			}}
			required={required}
			disabled={disabled}
		/>
	);
}

EmailInput.propTypes = propTypes;
EmailInput.defaultProps = defaultProps;

export default EmailInput;
