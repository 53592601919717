import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import * as React from 'react';
import Button from '../button/Button';
import ModalScreen from './ModalScreen';

// TODO: This is very similar to other modal.
// Refactor to one component that work in all cases

// TODO: Pass in styling and other props as needed
export default function BasicModal({
	children,
	buttonText,
	buttonVariant,
	buttonColor,
	buttonSx,
	aria,
	buttonDisabled,
	open,
	handleModal,
	title,
	closeIcon,
	contentStyle,
	BackdropProps,
}) {
	return (
		<Box>
			{buttonText && (
				<Button
					onClick={() => handleModal(true)}
					color={buttonColor}
					variant={buttonVariant}
					disabled={buttonDisabled}
					sx={{ width: '100%', ...buttonSx }}
				>
					{buttonText}
				</Button>
			)}
			<ModalScreen
				contentStyle={contentStyle}
				BackdropProps={BackdropProps}
				aria={aria}
				open={open}
				title={title}
				closeIcon={closeIcon}
				handleModal={handleModal}
			>
				{children}
			</ModalScreen>
		</Box>
	);
}

BasicModal.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	buttonText: PropTypes.string,
	buttonVariant: PropTypes.string,
	buttonColor: PropTypes.string,
	buttonSx: PropTypes.shape({}),
	aria: PropTypes.shape({
		labelledby: PropTypes.string,
		describedby: PropTypes.string,
	}),
	buttonDisabled: PropTypes.bool,
	open: PropTypes.bool.isRequired,
	handleModal: PropTypes.func.isRequired,
	title: PropTypes.string,
	closeIcon: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	contentStyle: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	BackdropProps: PropTypes.object,
};

BasicModal.defaultProps = {
	children: null,
	buttonVariant: 'contained',
	buttonColor: 'primary',
	buttonSx: {},
	aria: {
		labelledby: '',
		describedby: '',
	},
	buttonDisabled: false,
	buttonText: '',
	title: '',
	closeIcon: true,
	contentStyle: {},
	BackdropProps: {},
};
