import React from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';

import TableHeader from '@app/pages/customerPortalNyop/components/TableHeader';
import ItemRowContainer from '@app/components/shared/layout/ItemRowContainer';
import ListItem from '@app/pages/customerPortalNyop/components/ListItem';
import AcceptDeclineToggle from '@app/pages/customerPortalNyop/components/AcceptDeclineToggle';
import InfoBox from '@app/components/shared/ui/InfoBox';
import ErrorMessage from '@app/components/shared/ui/ErrorMessage';
import Button from '@app/components/shared/button/Button';

import { useOffers } from '@app/pages/customerPortalNyop/components/OffersProvider';

import {
	getBulkOffersSavedPrice,
	getDeclinedOffers,
	getFirstValidNumber,
	getUnderCustomerReviewOffers,
	getUnderMerchantReviewOffers,
	getVariantMap,
} from '../helpers';

import {
	MerchantType,
	OfferType,
	OrderType,
	VariantType,
} from '@app/propTypes/types';
import BulkAcceptCheckbox from './BulkAcceptCheckbox';

const offersPendingText =
	'Your offers are currently under review. We appreciate your patience and will get back to you soon.';
const offersAcceptedText =
	'Your offers have been submitted. Please check your inbox shortly for our final confirmation.🚀';
const counterOffersPendingText =
	'Inventory is limited. Accept or decline your offers below to process your order';
const offersDeclinedText =
	"It looks like we couldn't strike a deal this time, but we're thankful for the chance to try. Don't hesitate to toss another offer our way in the future — we'd love another opportunity to make it work. Thank you!";
const paymentFailureText =
	'We’re sorry, but we ran into an issue processing your payment for your offer(s). Please update your payment information. Otherwise, your offers may be cancelled.';

const toastMessage =
	'✅ Accepted offers are in process. 🚫 Declined offers will be refunded. 📧 Check your inbox for final confirmation.';

function getDefaultInfoBoxText({ order, offers }) {
	const anyPendingCounterOffers =
		getUnderCustomerReviewOffers(offers);
	if (anyPendingCounterOffers.length !== 0) {
		return [counterOffersPendingText, 'info'];
	}

	const declinedOffers = getDeclinedOffers(offers);
	if (offers.length === declinedOffers.length) {
		return [offersDeclinedText, 'info'];
	}

	if (order.extra?.paymentAttemptFailed) {
		return [paymentFailureText, 'warning'];
	}

	const pendingOffers = getUnderMerchantReviewOffers(offers);
	if (pendingOffers.length !== 0) {
		return [offersPendingText, 'info'];
	}

	return [offersAcceptedText, 'success'];
}

function CounterOffers({
	order,
	offers,
	variants,
	merchant,
	token,
	acceptAllChecked,
	setAcceptAllChecked,
	disabled,
}) {
	const queryClient = useQueryClient();
	const { setOfferStatus: setOfferContext } = useOffers();

	const [defaultInfoBoxText, defaultInfoBoxType] =
		getDefaultInfoBoxText({ order, offers });

	const [infoBoxText, setInfoBoxText] = React.useState(
		defaultInfoBoxText,
	);
	const [infoBoxType, setInfoBoxType] = React.useState(
		defaultInfoBoxType,
	);

	const counterOffers = getUnderCustomerReviewOffers(offers);
	const bulkOffersPriceDiscount = getBulkOffersSavedPrice(offers);

	const [offerStatus, setOfferStatus] = React.useState(
		counterOffers.reduce((acc, offer) => {
			acc[offer.id] = true;
			return acc;
		}, {}),
	);

	const mutateEndpoint = acceptAllChecked
		? '/respond-to-bulk-counter-offers'
		: '/respond-to-counter-offers';

	const { mutate, data, isLoading, error } = useMutation({
		mutationFn: async () =>
			axios.post(
				`/api/customers/offers/${order.id}${mutateEndpoint}`,
				{ offerStatus },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			),
		onSuccess: () => {
			// TODO: Do a lazy update here instead of invalidating cache which makes unnecessary call
			queryClient.invalidateQueries(['customer', token]);

			const anyAccepted = Object.values(offerStatus).some(
				(os) => !!os,
			);
			if (anyAccepted) {
				setInfoBoxText(offersAcceptedText);
				setInfoBoxType('success');
			} else {
				setInfoBoxText(offersDeclinedText);
				setInfoBoxType('info');
			}
		},
		msg: toastMessage,
	});

	const variantMap = getVariantMap(variants);

	// Set context to use in other Customer Portal component
	React.useEffect(() => {
		setOfferContext(offerStatus);
	}, [offerStatus, setOfferContext]);

	const rowHeight = {
		xs: '100px',
		sm: '150px',
	};

	const handleToggleChange = (offerId, accepted) => {
		setOfferStatus((prev) => ({
			...prev,
			[offerId]: accepted,
		}));
	};

	const handleCheckBoxChange = (e, newValue) => {
		setAcceptAllChecked(newValue);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		await mutate();
	};

	return (
		<FormControl
			component="form"
			onSubmit={handleSubmit}
			sx={{ width: '100%' }}
		>
			<FormGroup>
				<FormLabel
					variant="h3"
					sx={{ fontWeight: 'bold', fontSize: '1.5em' }}
				>
					New Offers:
				</FormLabel>
				{!disabled ? (
					<InfoBox type={infoBoxType} sx={{ mt: 1, mb: 1 }}>
						{infoBoxText}
					</InfoBox>
				) : null}
				<ItemRowContainer
					items={counterOffers}
					headerComponent={
						<>
							<TableHeader leftText="ITEM" rightText="NEW OFFER" />
							{Boolean(bulkOffersPriceDiscount) && (
								<BulkAcceptCheckbox
									bulkOffersPriceDiscount={bulkOffersPriceDiscount}
									order={order}
									acceptAllChecked={acceptAllChecked}
									handleCheckBoxChange={handleCheckBoxChange}
									disabled={disabled}
								/>
							)}
						</>
					}
					renderChild={(offer) => {
						const matchedVariant = variantMap[offer.variant_id];

						const bulkCounterOfferAmount =
							offer.bulk_counter_offer_amount &&
							parseFloat(offer.bulk_counter_offer_amount);
						const counterOfferAmount =
							offer.counter_offer_amount &&
							parseFloat(offer.counter_offer_amount);
						const offerAmount =
							acceptAllChecked && bulkCounterOfferAmount
								? bulkCounterOfferAmount
								: counterOfferAmount;
						const msrp = parseFloat(offer.msrp);

						return (
							<ListItem
								key={`counter-offer-list-${offer.id}`}
								name="counterOffersCustomerPortalNyop"
								order={order}
								offer={offer}
								quantity={getFirstValidNumber(
									offer.fulfilled_quantity,
									offer.quantity,
									1,
								)}
								variant={matchedVariant}
								offerPrice={offerAmount}
								originalPrice={msrp}
								declined={!offerStatus[offer.id]}
								rowHeight={rowHeight}
								secondaryContent={
									<AcceptDeclineToggle
										accepted={
											offerStatus[offer.id] || acceptAllChecked
										}
										onChange={(accepted) =>
											handleToggleChange(offer.id, accepted)
										}
										disabled={disabled || acceptAllChecked}
									/>
								}
								acceptAllChecked={acceptAllChecked}
								disabled={disabled}
							/>
						);
					}}
					emptyStateComponent={
						<Box pt={3} pb={3}>
							<Typography
								variant="h6"
								sx={{ fontWeight: 'bold', mb: 1 }}
							>
								You have no New Offers at this time
							</Typography>
							<Typography
								variant="subtitle1"
								sx={{ color: 'text.secondary' }}
							>
								We appreciate your offers! While we review your
								offers, please note that{' '}
								{merchant?.name ? merchant.name : 'the merchant'}{' '}
								might suggest an alternative. You&apos;ll be able to
								view and respond to any New Offers here.
							</Typography>
						</Box>
					}
				/>
				<Button
					type="submit"
					color="primary"
					disabled={disabled || counterOffers.length < 1 || !!data}
					loading={isLoading}
					sx={{ width: '100%', mt: 2, mb: 0.5 }}
				>
					Submit New Offers
				</Button>
				{error ? (
					<ErrorMessage
						message={
							error?.response?.data?.message ||
							'Oops! Something went wrong when submitting you New Offers. Please try again.'
						}
						sx={{ width: '100%', textAlign: 'center' }}
					/>
				) : (
					<Typography
						sx={{ textAlign: 'center', color: 'text.secondary' }}
					>
						Your order will be processed with the options above
					</Typography>
				)}
			</FormGroup>
		</FormControl>
	);
}

CounterOffers.propTypes = {
	order: OrderType.isRequired,
	offers: PropTypes.arrayOf(OfferType).isRequired,
	variants: PropTypes.arrayOf(VariantType).isRequired,
	merchant: MerchantType.isRequired,
	token: PropTypes.string.isRequired,
	acceptAllChecked: PropTypes.bool,
	setAcceptAllChecked: PropTypes.func,
	disabled: PropTypes.bool,
};

CounterOffers.defaultProps = {
	acceptAllChecked: false,
	setAcceptAllChecked: () => null,
	disabled: false,
};

export default CounterOffers;
