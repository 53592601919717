import * as React from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import Box from '@mui/material/Box';
import PreorderTable from './PreorderTable';
import MetricWidget from '../../components/shared/ui/MetricWidget';
import Loader from '../../components/shared/loaders/CircularLoader';
import ConfirmFulfillment from './ConfirmFulfillment';
import ConfirmationModal from '../../components/shared/modal/Modal';
import { useSellingPlanGroup } from '../../hooks/useSellingPlanGroups';
import { formatDate, isDate } from '../../helpers/dateHelpers';
import FulfillButton from './FulfillButton';

function PreorderExecution() {
	const { id } = useParams();

	const [modalOpen, setModal] = React.useState(false);
	const [shouldPoll, setShouldPoll] = React.useState(false);
	const { data, isLoading, isFetching } = useSellingPlanGroup({
		id,
		configOpts: {
			// FIXME: We need to build a progress bar based on FLow Queue progress
			// And not poll like this.  Could be inaccurate...
			refetchInterval: (tData) => {
				const pollingInterval = 15 * 1000;
				const preorders = tData?.data?.preorders || [];

				const isFinished =
					preorders.filter(
						(preorder) =>
							preorder?.financial_status === 'paid' ||
							preorder?.payment_error !== null,
					).length !== preorders.length;

				return shouldPoll && isFinished ? pollingInterval : undefined;
			},
		},
	});

	if (isLoading) return <Loader fullPage />;

	return (
		<>
			<Box
				mb={2}
				sx={{
					display: 'flex',
					float: 'right',
				}}
			>
				<FulfillButton
					sellingPlanGroup={data?.data}
					setModal={setModal}
				/>
			</Box>

			<Grid container spacing={2}>
				<Grid item md={4}>
					<MetricWidget
						name="Payments Pending"
						value={
							data?.data?.preorders?.filter(
								(preorder) =>
									!preorder.canceled &&
									(preorder.financial_status === 'pending' ||
										preorder.financial_status === 'partially_paid'),
							).length || 0
						}
					/>
				</Grid>
				<Grid item md={4}>
					<MetricWidget
						name="Preorder Revenue"
						value={
							data?.data?.preorders &&
							data?.data?.preorders.length > 0
								? data.data.preorders
										.reduce((totalRevenue, preorder) => {
											if (preorder.canceled) return totalRevenue;
											return (
												parseFloat(totalRevenue) +
												parseFloat(preorder.item_price)
											);
										}, 0)
										.toFixed(2)
								: '0'
						}
					/>
				</Grid>
				<Grid item md={4}>
					<MetricWidget
						name="Ship Date"
						value={
							data?.data?.remaining_balance_charge_date &&
							isDate(data.data.remaining_balance_charge_date)
								? formatDate(
										data.data.remaining_balance_charge_date,
										'MM/dd/yy',
								  )
								: 'Not Set'
						}
					/>
				</Grid>
				<Grid item md={12}>
					{data?.data?.preorders && (
						<PreorderTable
							preorders={data?.data?.preorders}
							isFetching={isFetching}
						/>
					)}
				</Grid>
			</Grid>
			<ConfirmationModal open={modalOpen} handleModal={setModal}>
				<ConfirmFulfillment
					sellingPlanGroupId={data?.data?.id}
					count={data?.data?.preorders.length}
					handleModal={setModal}
					setShouldPoll={setShouldPoll}
				/>
			</ConfirmationModal>
		</>
	);
}

export default PreorderExecution;
