import React from 'react';

import Loader from '../../loaders/CircularLoader';

function getNoTableMatch({ isLoading, text }) {
	if (!isLoading) {
		return text;
	}

	return <Loader />;
}

export default getNoTableMatch;
