import React from 'react';
import { useLocation } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {
	createTheme,
	ThemeProvider,
	useTheme,
} from '@mui/material/styles';
import { useProducts } from '@app/hooks/useProducts';
import TitleWithImage from '@app/components/muiTable/elements/TitleWithImage';
import useAuth from '@app/hooks/useAuth';
import InfoPopoverIcon from '@app/components/shared/ui/InfoPopoverIcon';
import CampaignsList from '@app/components/inputs/CampaignsList';
import {
	getProductLink,
	getProductVariantLink,
} from '@app/lib/shopify';
import getNoTableMatch from '@app/components/shared/Table/components/NoTableMatch';
import { useCollections } from '@app/hooks/useCollections';
import { capitalizeFirstLetter } from '@app/helpers/stringHelpers';
import TableCustomFilterDialogFooter from '../shared/Table/components/TableCustomFilterDialogFooter';
import { INVENTORY_KIND_OPTIONS, STATUS_OPTIONS } from './constants';
import RequiresFlag from '../shared/flags/RequiresFlag';
import { FLAGS, useFlagEnabled } from '@app/hooks/useFlags';
import MarketplaceEnabledHeadRenderer from './elements/MarketplaceEnabledHeadRenderer';
import ProductCheckboxInput from '@app/pages/marketplace/supplier/table/components/ProductCheckboxInput';
import {
	MarketplaceProductsProvider,
	useMarketplaceProductsContext,
} from '@app/pages/marketplace/supplier/context/useMarketProductsContext';
import VariantCheckboxInput from '@app/pages/marketplace/supplier/table/components/VariantCheckboxInput';

const DataColumnOptions = {
	viewColumns: false,
	filter: false,
	sort: false,
	download: false,
	display: 'excluded',
};

const DataIndex = {
	formatMerchantNumber: -8,
	inventory_tracked: -7,
	shop: -6,
	id: -5,
	variants: -4,
	formatMerchantCurrency: -3,
	image_url: -2,
	handle: -1,
};

function getDataColumns() {
	return Object.keys(DataIndex).map((name) => ({
		name,
		options: DataColumnOptions,
	}));
}

function getProductTitle(value, data) {
	const imageUrl = data.rowData.at(DataIndex.image_url);
	const handle = data.rowData.at(DataIndex.handle);
	const id = data.rowData.at(DataIndex.id);
	const shop = data.rowData.at(DataIndex.shop);
	return (
		<TitleWithImage
			title={value}
			imageUrl={imageUrl}
			subtitle={handle}
			url={getProductLink(shop, id)}
		/>
	);
}

function getInventoryValue(value, data) {
	const formatMerchantCurrency = data.rowData.at(
		DataIndex.formatMerchantCurrency,
	);

	const amount = formatMerchantCurrency(value);

	if (value < 0) {
		return (
			<Stack direction="row" spacing={3}>
				<Typography>{amount}</Typography>
				<InfoPopoverIcon text="A negative quantity means you likely have Bazo orders pending and a negative inventory quantity.  You should make up the negative balance in revenue when those orders are fulfilled." />
			</Stack>
		);
	}
	return amount;
}

function getInventoryQuantity(value, data) {
	const formatMerchantNumber = data.rowData.at(
		DataIndex.formatMerchantNumber,
	);

	const variants = data.rowData.at(DataIndex.variants);
	const notTracked = variants.every((v) => !v.inventory_tracked);
	const partiallyNotTracked =
		!notTracked && variants.some((v) => !v.inventory_tracked);

	return (
		<Stack direction="row" spacing={3}>
			<Typography>{formatMerchantNumber({ value })}</Typography>
			{notTracked ? (
				<InfoPopoverIcon text="Displayed quantity may not be accurate as all product variants are untracked." />
			) : null}
			{partiallyNotTracked ? (
				<InfoPopoverIcon text="Displayed quantity may not be accurate as some product variants are untracked." />
			) : null}
		</Stack>
	);
}

function getCampaignsList(value) {
	return <CampaignsList campaigns={value} />;
}

function getCommonCampaigns(product) {
	const campaigns = {};
	let init = false;
	product.variants.forEach((variant) => {
		const foundCampaigns = [];
		variant.campaigns?.forEach((campaign) => {
			if (!init) {
				campaigns[campaign.id] = campaign;
			}
			foundCampaigns.push(campaign.id);
		});
		if (!init) {
			init = true;
		} else {
			Object.values(campaigns).forEach((campaign) => {
				if (!foundCampaigns.includes(campaign?.id)) {
					delete campaigns[campaign.id];
				}
			});
		}
	});

	return Object.values(campaigns).filter((v) => !!v);
}

function getMarketplaceEnabledRenderer(value, data) {
	const variantsIds = data.rowData
		.at(DataIndex.variants)
		.map((v) => v.id);

	return (
		<ProductCheckboxInput
			productId={value}
			variantsIds={variantsIds}
			disabled
		/>
	);
}

function ProductsTable({
	defaultSelectedVariants,
	defaultInventoryKind,
	onSelectedVariantsChanged,
	onSearchChanged,
	onCollectionFilterChanged,
	onStatusFilterChanged,
	onInventoryKindChanged,
	style,
	ageRate,
	valueRate,
}) {
	const location = useLocation();
	const theme = useTheme();
	const {
		merchant,
		formatMerchantCurrency,
		formatMerchantNumber,
		getCurrencySymbol,
	} = useAuth();

	// business logic state
	const [selectedVariants, setSelectedVariants] = React.useState(
		defaultSelectedVariants,
	);
	const selectedVariantsIds = selectedVariants.map(
		(variant) => variant.id,
	);

	// filtering/pagination state values
	const [searchTerm, setSearchTerm] = React.useState('');
	const [collection, setCollection] = React.useState();
	const [status, setStatus] = React.useState('All');
	const [inventoryKind, setInventoryKind] = React.useState(
		defaultInventoryKind,
	);
	const [marketplaceFilterEnabled, setMarketplaceFilterEnabled] =
		React.useState(false);
	const [page, setPage] = React.useState(0);
	const [sortKey, setSortKey] = React.useState(
		'inventory_value,desc',
	);
	const [perPage, setPerPage] = React.useState(20);

	const marketplaceAvailableEnabled = useFlagEnabled(
		FLAGS.MARKETPLACE_AVAILABLE,
	);

	// table state
	const [expandedProductsIds, setExpandedProductsIds] =
		React.useState([]);
	const { deriveFromMarketplaceProducts } =
		useMarketplaceProductsContext();

	const { data, isLoading, isFetching } = useProducts({
		configOpts: {
			enabled: !!merchant,
		},
		cacheKey: `${merchant.id}|${searchTerm}|${
			collection?.id
		}|${status}|${inventoryKind}|${page}|${perPage}|${sortKey}|${
			inventoryKind && ageRate
		}|${inventoryKind && valueRate}|${marketplaceFilterEnabled}`,
		queryVariables: {
			searchTerm,
			status,
			inventoryKind,
			page: page + 1,
			perPage,
			sortKey,
			collectionId: collection?.id,
			ageRate,
			valueRate,
			marketplaceFilterEnabled,
		},
	});

	const { data: collectionData } = useCollections({
		cacheKey: '',
		queryVariables: { query: '' },
	});
	const allCollections = React.useMemo(
		() =>
			(collectionData?.collections || []).map((collectionInfo) => ({
				...collectionInfo,
				title: capitalizeFirstLetter(collectionInfo.title),
			})),
		[collectionData],
	);

	React.useEffect(() => {
		if (!data) {
			return;
		}
		const marketplaceProducts = [];
		data.products?.forEach((product) => {
			if (product.marketplace_product) {
				marketplaceProducts.push(product.marketplace_product);
			}
			product.variants?.forEach((variant) => {
				if (variant.marketplace_product) {
					marketplaceProducts.push(variant.marketplace_product);
				}
			});
		});

		deriveFromMarketplaceProducts(marketplaceProducts);
	}, [data, deriveFromMarketplaceProducts]);

	React.useEffect(() => {
		setInventoryKind(defaultInventoryKind);
	}, [setInventoryKind, defaultInventoryKind]);

	React.useEffect(() => {
		if (onSearchChanged) onSearchChanged(searchTerm);
	}, [searchTerm, onSearchChanged]);

	React.useEffect(() => {
		if (onCollectionFilterChanged)
			onCollectionFilterChanged(collection);
	}, [collection, onCollectionFilterChanged]);

	React.useEffect(() => {
		if (onStatusFilterChanged) onStatusFilterChanged(status);
	}, [status, onStatusFilterChanged]);

	React.useEffect(() => {
		if (onInventoryKindChanged) onInventoryKindChanged(inventoryKind);
	}, [inventoryKind, onInventoryKindChanged]);

	React.useEffect(() => {
		onSelectedVariantsChanged(selectedVariants);
	}, [onSelectedVariantsChanged, selectedVariants]);

	const onVariantSelected = (variant, selected) => {
		const selectedVariantsWithoutChanged = selectedVariants.filter(
			(selectedVariant) => selectedVariant.id !== variant.id,
		);
		if (
			selected &&
			selectedVariantsWithoutChanged.length ===
				selectedVariants.length
		) {
			setSelectedVariants([
				...selectedVariantsWithoutChanged,
				variant,
			]);
		} else {
			setSelectedVariants(selectedVariantsWithoutChanged);
		}
	};

	const products = [];
	if (data && !isLoading && !isFetching) {
		data?.products?.forEach((product) => {
			products.push({
				...product,
				...product.product_values,
				campaigns: getCommonCampaigns(product),
				formatMerchantCurrency,
				formatMerchantNumber,
			});
		});
	}

	const columns = [
		{
			name: 'title',
			label: 'Product',
			options: {
				customBodyRender: getProductTitle,
				filter: false,
			},
		},
		{
			name: 'inventory_value',
			label: `Inventory Value (${getCurrencySymbol()})`,
			options: {
				customBodyRender: getInventoryValue,
				filter: false,
			},
		},
		{
			name: 'inventory_quantity',
			label: 'Inventory Units',
			options: {
				customBodyRender: getInventoryQuantity,
				filter: false,
			},
		},
		{
			name: 'days_in_store',
			label: 'Days In Store',
			options: {
				filter: false,
			},
		},
		{
			name: 'campaigns',
			label: 'Campaign',
			options: {
				customBodyRender: getCampaignsList,
				filter: false,
				sort: false,
			},
		},
		{
			name: 'collections',
			label: 'Collections',
			options: {
				viewColumns: false,
				filter: true,
				sort: false,
				download: false,
				display: 'excluded',
				filterOptions: {
					names: allCollections.map(
						(collectionInfo) => collectionInfo.title,
					),
				},
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				viewColumns: false,
				filter: true,
				sort: false,
				download: false,
				display: 'excluded',
				filterOptions: {
					names: STATUS_OPTIONS,
				},
			},
		},
		{
			name: 'inventoryKind',
			label: 'Inventory Kind',
			options: {
				viewColumns: false,
				filter: true,
				sort: false,
				download: false,
				display: 'excluded',
				filterOptions: {
					names: INVENTORY_KIND_OPTIONS.map((option) => option.label),
				},
			},
		},
		marketplaceAvailableEnabled
			? {
					name: 'id',
					label: 'Marketplace Available',
					options: {
						customBodyRender: getMarketplaceEnabledRenderer,
						customHeadRender: MarketplaceEnabledHeadRenderer,
						filter: true,
						filterType: 'checkbox',
						filterOptions: {
							names: ['Available'],
						},
						sort: false,
					},
			  }
			: null,
		// EXTRA DATA
		...getDataColumns(),
	].filter(Boolean);

	const rowsExpanded = products
		.map((product, index) => [product.id, index])
		.filter(([productId]) => expandedProductsIds.includes(productId))
		.map(([, index]) => index);

	const rowsSelected = products
		.map((product, index) => [product, index])
		.filter(([product]) =>
			product.variants.every((variant) =>
				selectedVariantsIds.includes(variant.id),
			),
		)
		.map(([, index]) => index);

	const options = {
		elevation: 0,
		storageKey: 'inventory',
		serverSide: true,
		filter: true,
		filterType: 'dropdown',
		search: true,
		onSearchChange: (searchText) => setSearchTerm(searchText),
		searchPlaceholder: 'Search',
		download: false,
		print: false,
		viewColumns: false,
		selectToolbarPlacement: 'none',
		responsive: 'standard',
		expandableRows: true,
		expandableRowsHeader: false,
		expandableRowsOnClick: true,
		textLabels: {
			body: {
				noMatch: getNoTableMatch({
					isLoading: isLoading || isFetching,
					text: 'No products found',
				}),
			},
		},
		rowsExpanded,
		rowsSelected,
		isRowExpandable: (dataIndex) => {
			const variants = products[dataIndex]?.variants;
			return variants && variants.length !== 0;
		},
		renderExpandableRow: (rowData) => {
			const id = rowData.at(DataIndex.id);
			const shop = rowData.at(DataIndex.shop);
			const variants = rowData.at(DataIndex.variants);
			const imageUrl = rowData.at(DataIndex.image_url);

			return variants
				.sort((v1, v2) => (v1.title > v2.title ? 1 : -1))
				.map((variant) => (
					<TableRow key={variant.id}>
						<TableCell sx={{ pb: '4px', pt: '4px', pl: '2rem' }}>
							<Checkbox
								checked={selectedVariantsIds.includes(variant.id)}
								onChange={(event, checked) =>
									onVariantSelected(variant, checked)
								}
							/>
						</TableCell>
						<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
							<TitleWithImage
								title={variant.title}
								imageUrl={variant.image_url || imageUrl}
								subtitle={variant.sku}
								url={getProductVariantLink(shop, id, variant.id)}
							/>
						</TableCell>
						<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
							{formatMerchantCurrency(
								variant.inventory_quantity *
									(variant.msrp || variant.price),
							)}
						</TableCell>
						<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
							<Stack direction="row" spacing={3}>
								<Typography>
									{formatMerchantNumber({
										value: variant.inventory_quantity,
									})}
								</Typography>
								{!variant.inventory_tracked ? (
									<InfoPopoverIcon
										iconStyles={{ width: '1.2rem' }}
										text="Inventory quantities displayed are not tracked in real-time and may be inaccurate"
									/>
								) : null}
							</Stack>
						</TableCell>
						<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
							-
						</TableCell>
						<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
							<CampaignsList
								variantId={variant.id}
								campaigns={variant.campaigns}
							/>
						</TableCell>
						<RequiresFlag flag={FLAGS.MARKETPLACE_AVAILABLE}>
							<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
								<VariantCheckboxInput
									productId={id}
									variantId={variant.id}
									disabled
								/>
							</TableCell>
						</RequiresFlag>
					</TableRow>
				));
		},
		onRowSelectionChange: (updatedRows, allRowsSelected) => {
			if (allRowsSelected.length === 0) {
				setSelectedVariants([]);
				return;
			}

			const addedVariants = [];
			const removedVariantsIds = [];

			updatedRows.forEach((updatedRow) => {
				const productVariants =
					products[updatedRow.dataIndex].variants;

				const isSelected = allRowsSelected.find(
					(row) => row.dataIndex === updatedRow.dataIndex,
				);

				if (isSelected) {
					addedVariants.push(
						...productVariants.filter(
							(variant) => !selectedVariantsIds.includes(variant.id),
						),
					);
				} else {
					removedVariantsIds.push(
						...productVariants.map((variant) => variant.id),
					);
				}
			});

			const allVariants = [...selectedVariants, ...addedVariants];
			const updatedVariants = allVariants.filter(
				(variant) => !removedVariantsIds.includes(variant.id),
			);
			setSelectedVariants(updatedVariants);
		},
		onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) => {
			let newExpandedProductsIds = expandedProductsIds;
			currentRowsExpanded.forEach((currentRow) => {
				const isExpanded = allRowsExpanded.find(
					(row) => row.dataIndex === currentRow.dataIndex,
				);

				const productId = products[currentRow.dataIndex].id;
				if (isExpanded && !expandedProductsIds.includes(productId)) {
					newExpandedProductsIds.push(productId);
				} else {
					newExpandedProductsIds = newExpandedProductsIds.filter(
						(p) => p !== productId,
					);
				}
			});

			setExpandedProductsIds(newExpandedProductsIds);
		},
		// Sorting configuration
		onColumnSortChange: (column, direction) => {
			setSortKey(`${column},${direction}`);
		},
		// Pagination configurations
		count: data?.count ?? 200,
		page,
		rowsPerPage: perPage,
		rowsPerPageOptions: [10, 20, 50, 200],
		onChangeRowsPerPage: (value) => {
			setPerPage(value);
			setPage(0);
		},
		onChangePage: (newPage) => {
			setPage(newPage);
		},
		onFilterChange: (changedColumn, filterList) => {
			if (changedColumn === 'collections') {
				const collectionTitle = filterList[5]?.[0];
				const selectedCollection = allCollections.find(
					(collectionInfo) =>
						collectionInfo.title === collectionTitle,
				);

				setCollection(selectedCollection);
			} else if (changedColumn === 'status') {
				const selectedStatus = filterList[6]?.[0] || 'All';

				setStatus(selectedStatus);
			} else if (changedColumn === 'inventoryKind') {
				const inventoryKindLabel = filterList[7]?.[0];
				const selectedInventoryKind = INVENTORY_KIND_OPTIONS.find(
					(option) => option.label === inventoryKindLabel,
				);

				setInventoryKind(selectedInventoryKind?.value);
			} else if (changedColumn === 'marketplace_product') {
				const marketplaceAvailableValue =
					filterList[8]?.[0] === 'Available';
				setMarketplaceFilterEnabled(marketplaceAvailableValue);
			} else if (!changedColumn) {
				// changedColumn is null when the reset button is clicked
				setCollection();
				setStatus('All');
				setInventoryKind();
				setMarketplaceFilterEnabled(false);
			}
		},
		customFilterDialogFooter: TableCustomFilterDialogFooter,
	};

	const getMuiTheme = () => {
		const overrides = {
			components: {
				MUIDataTable: {
					styleOverrides: {
						root: {},
					},
				},
			},
		};
		if (style?.removeFrame) {
			overrides.components.MUIDataTable.styleOverrides.root.boxShadow =
				'none';
		}

		return createTheme(theme, overrides);
	};

	/**
	 * This is here because of an issue where we have stacked modals
	 * and the z index of the resulting modal / paper component for the
	 * filter popup gets a lower z index than the modal behind it.
	 * Trying to provide style overrides to the theme for the Datatables
	 * doesn't do work because there are more than one of the same elements.
	 * TODO: Figure out a way to do this without DOM manipulation
	 *
	 * THis also only runs on the campaign create/edit menu
	 */
	React.useEffect(() => {
		let observer = null;

		// Only run on routes where this is in a stacked modal
		if (location.pathname.startsWith('/campaigns/nyop')) {
			const setZIndexForElements = () => {
				const filterZIndex = '15000';
				const filterListZIndex = '15100';

				const adjustZIndex = (element, zIndex) => {
					if (element?.style) {
						element.style.zIndex = zIndex; // eslint-disable-line
					}
				};

				const popovers = document.querySelectorAll(
					'.MuiPopover-root.MuiModal-root',
				);
				if (popovers.length > 1) {
					adjustZIndex(popovers[1], filterZIndex);
				}

				// These are the ids MUI Datatables gives to the filter lists
				// and they must sit on top of the popover above
				const menuIds = [
					'menu-collections',
					'menu-status',
					'menu-inventoryKind',
				];
				menuIds.forEach((id) => {
					adjustZIndex(document.getElementById(id), filterListZIndex);
				});

				// Search was have a conflict with another style for some reason
				/**
				 * .css-17ijv2x-MuiToolbar-root.MuiToolbar-root>:first-of-type {
				 *	   display: none;
				 * }
				 */
				const productTable = document.querySelector('#product-table');
				const toolbar = productTable?.querySelector(
					'.MuiToolbar-root',
				);
				if (toolbar?.firstChild?.style) {
					toolbar.firstChild.style.display = 'block'; // eslint-disable-line
				}
			};

			observer = new MutationObserver(() => {
				setZIndexForElements();
			});

			const config = { childList: true, subtree: true };

			observer.observe(document.body, config);
		}

		return () => {
			if (observer) {
				observer.disconnect();
			}
		};
	}, [location.pathname]);

	return (
		<ThemeProvider theme={getMuiTheme()}>
			<Box id="product-table">
				<MUIDataTable
					title={style?.hideTitle ? '' : 'Inventory Review'}
					data={products}
					columns={columns}
					options={options}
				/>
			</Box>
		</ThemeProvider>
	);
}

ProductsTable.propTypes = {
	onSelectedVariantsChanged: PropTypes.func.isRequired,
	onSearchChanged: PropTypes.func,
	onCollectionFilterChanged: PropTypes.func,
	onStatusFilterChanged: PropTypes.func,
	onInventoryKindChanged: PropTypes.func,
	defaultInventoryKind: PropTypes.string,
	defaultSelectedVariants: PropTypes.arrayOf(
		PropTypes.shape({ id: PropTypes.string }),
	),
	style: PropTypes.shape({
		removeFrame: PropTypes.bool,
		hideTitle: PropTypes.bool,
	}),
	ageRate: PropTypes.number,
	valueRate: PropTypes.number,
};

ProductsTable.defaultProps = {
	defaultSelectedVariants: [],
	onSearchChanged: null,
	onCollectionFilterChanged: null,
	onStatusFilterChanged: null,
	defaultInventoryKind: null,
	onInventoryKindChanged: null,
	style: null,
	ageRate: null,
	valueRate: null,
};

function MarketplaceProductsTable(props) {
	return (
		<MarketplaceProductsProvider>
			<ProductsTable {...props} />
		</MarketplaceProductsProvider>
	);
}

export default MarketplaceProductsTable;
