import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { useInventoryReviewContext } from '@app/hooks/useInventoryReviewContext';
import { useCreateCampaignContext } from '@app/hooks/useCreateCampaignContext';
import { mergeAutomationRulesWithVariants } from '@app/pages/createCampaign/helpers';

// Amount to add to fixed cost buffer when creating marketplace campaign
// to account for additional costs like tree planting
const marketplaceFixedCostAutoIncrementAmount = 1;

// Need this to translate the automation rules from supplier ids to marketplace ids
/* eslint-disable no-param-reassign */
const mapVariantAndProductIds = (variants, automationRules) => {
	// Create a mapping from created_from_variant_id to your store's variant id
	const variantMapping = variants.reduce((map, variant) => {
		map[variant.created_from_variant_id] = variant.id;
		return map;
	}, {});

	// Create a mapping from created_from_product_id to your store's product id
	const productMapping = variants.reduce((map, variant) => {
		if (variant.product && variant.product.created_from_product_id) {
			map[variant.product.created_from_product_id] =
				variant.product_id;
		}
		return map;
	}, {});

	// Transform the automation rules using the created mappings
	const newAutomationRules = {
		...automationRules,
		// Auto increment fixed cost buffer for tree planting
		variants: Object.entries(automationRules.variants).reduce(
			(newVariants, [key, value]) => {
				const newKey =
					variantMapping[key] || productMapping[key] || key;
				newVariants[newKey] = value;
				return newVariants;
			},
			{},
		),
	};

	return newAutomationRules;
};
/* eslint-enable no-param-reassign */

function formatAutomationRulesForMarketplace({
	variants,
	automationRules,
}) {
	const mappedAutomationRules = mapVariantAndProductIds(
		variants,
		automationRules,
	);

	const originalFixedCostBuffer =
		mappedAutomationRules?.fixedCostBuffer || 0;

	const marketplaceFormattedAutomationRules = {
		...mappedAutomationRules,
		// Always process at the end of Campaign
		processAtTheEndOfCampaign: true,
		autoAcceptInterval: null,
		autoCounterInterval: null,
		// Increment fixed cost buffer for tree planting
		autoIncrementedFixedCostBuffer: true,
		fixedCostBuffer:
			originalFixedCostBuffer +
			marketplaceFixedCostAutoIncrementAmount,
		originalFixedCostBuffer,
	};

	return marketplaceFormattedAutomationRules;
}

function CreateCampaignButton({
	id,
	startingOfferInfo,
	description,
	automationRules,
	startDate,
	endDate,
	variants,
}) {
	const navigate = useNavigate();
	const [, setSelectedVariants] = useInventoryReviewContext();

	const { setCreateCampaignInfo } = useCreateCampaignContext();

	const handleCreateCampaignNavigate = React.useCallback(() => {
		const marketplaceAutomationRules =
			formatAutomationRulesForMarketplace({
				variants,
				automationRules,
			});

		const variantsWithAutomationRules =
			mergeAutomationRulesWithVariants({
				variants,
				variantAutomationRules: marketplaceAutomationRules.variants,
			});

		setSelectedVariants(variantsWithAutomationRules);
		setCreateCampaignInfo({
			fieldsData: {
				description,
				startDate,
				endDate,
				startingOfferInfo,
			},
			automationRules: marketplaceAutomationRules,
			step: 0,
			isMarketplace: false,
			marketplaceCampaignId: id,
		});
		navigate('/campaigns/create');
	}, [
		id,
		setSelectedVariants,
		setCreateCampaignInfo,
		description,
		startDate,
		endDate,
		startingOfferInfo,
		automationRules,
		navigate,
		variants,
	]);

	// TODO: Use a more declarative way to establish this from state
	const isDisabled = variants.length === 0;

	return (
		<Tooltip
			title={
				isDisabled
					? 'You must sync products before creating Campaign'
					: ''
			}
		>
			{/* Wrap in span bc button doesn't forward the ref to Tooltip */}
			<Box component="span">
				<Button
					onClick={handleCreateCampaignNavigate}
					disabled={isDisabled}
				>
					Create Campaign
				</Button>
			</Box>
		</Tooltip>
	);
}

CreateCampaignButton.propTypes = {
	id: PropTypes.string.isRequired,
	startingOfferInfo: PropTypes.shape({
		minimumOffer: PropTypes.string,
		startingOffer: PropTypes.string,
	}).isRequired,
	description: PropTypes.string.isRequired,
	automationRules: PropTypes.shape({}).isRequired,
	startDate: PropTypes.string.isRequired,
	endDate: PropTypes.string.isRequired,
	variants: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
		}),
	).isRequired,
};

export default CreateCampaignButton;
