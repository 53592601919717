import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import constants from '@app/constants';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxHeight: '90%',
	overflowY: 'scroll',
	overflowX: 'hidden',
	bgcolor: 'background.paper',
	borderRadius: 0.7,
	boxShadow: 24,
	pr: 3,
	pl: 3,
	pb: 3,
};

function ModalScreen({
	contentStyle,
	open,
	BackdropProps,
	aria,
	title,
	closeIcon,
	handleModal,
	children,
}) {
	return (
		<Modal
			open={open}
			onClose={() => handleModal(false)}
			aria-labelledby={aria.labelledby || ''}
			aria-describedby={aria.describedby || ''}
			sx={{
				overflowY: 'auto',
				zIndex: constants.zIndex.modalBackground,
			}}
			BackdropProps={BackdropProps}
		>
			<Box sx={[style, contentStyle]}>
				<Box
					sx={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						position: 'relative',
					}}
				>
					<Box
						sx={{
							pt: 1,
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Typography
							variant="subtitle1"
							sx={{ fontWeight: 'bold' }}
						>
							{title}
						</Typography>
						{closeIcon && (
							<IconButton
								aria-label="Close"
								onClick={() => handleModal(false)}
							>
								<CloseIcon />
							</IconButton>
						)}
					</Box>
					{children}
				</Box>
			</Box>
		</Modal>
	);
}

ModalScreen.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	open: PropTypes.bool.isRequired,
	aria: PropTypes.shape({
		labelledby: PropTypes.string,
		describedby: PropTypes.string,
	}),
	handleModal: PropTypes.func.isRequired,
	title: PropTypes.string,
	closeIcon: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	contentStyle: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	BackdropProps: PropTypes.object,
};

ModalScreen.defaultProps = {
	children: null,
	aria: {
		labelledby: '',
		describedby: '',
	},
	title: '',
	closeIcon: true,
	contentStyle: {},
	BackdropProps: {},
};

export default ModalScreen;
