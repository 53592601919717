import React from 'react';
import PropTypes from 'prop-types';
import { isNaN } from 'lodash-es';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ConversationalInputWrapper from '@app/components/shared/inputs/ConversationalTextInput';

function StartingOfferAmount({
	disabled,
	startingOfferInfo,
	onChange,
}) {
	const handleStartingValuesChange = React.useCallback(
		(e) => {
			let value = Number.parseInt(e.target.value, 10);

			if (isNaN(value) || value <= 0) {
				value = 0;
			} else if (value > 100) {
				value = 100;
			}

			onChange({
				...startingOfferInfo,
				[e.target.name]: value.toString(),
			});
		},
		[onChange, startingOfferInfo],
	);

	const handleBlur = React.useCallback(() => {
		const { startingOffer, minimumOffer } = startingOfferInfo;

		if (
			startingOffer &&
			minimumOffer &&
			+startingOffer < +minimumOffer
		) {
			onChange({
				...startingOfferInfo,
				startingOffer: minimumOffer,
			});
		}
	}, [onChange, startingOfferInfo]);

	return (
		<>
			<ConversationalInputWrapper prompt="What's the lowest offer percentage you'll consider?">
				<TextField
					name="minimumOffer"
					type="number"
					disabled={disabled}
					value={startingOfferInfo.minimumOffer || ''}
					onChange={handleStartingValuesChange}
					onBlur={handleBlur}
					sx={{ width: '100%' }}
					helperText="Defines the minimum offer percentage, marking the lowest offer shoppers can submit. Keeping this at 0 (the default) introduces ambiguity around your minimum thresholds, potentially leading to more frequent offers at amounts you're not willing to accept."
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">%</InputAdornment>
						),
					}}
					placeholder="0"
				/>
			</ConversationalInputWrapper>
			<ConversationalInputWrapper prompt="Where would you like the offer starting point?">
				<TextField
					name="startingOffer"
					type="number"
					disabled={disabled}
					value={startingOfferInfo.startingOffer || ''}
					onChange={handleStartingValuesChange}
					onBlur={handleBlur}
					sx={{ width: '100%' }}
					helperText={`Upon accessing the offer window, shoppers will see a default offer set at ${
						startingOfferInfo.startingOffer ?? 75
					}% of the MSRP. A starting point around 75% (default) is recommended to encourage more favorable offers.`}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">%</InputAdornment>
						),
					}}
					placeholder="75"
				/>
			</ConversationalInputWrapper>
		</>
	);
}

StartingOfferAmount.propTypes = {
	startingOfferInfo: PropTypes.shape({
		minimumOffer: PropTypes.string,
		startingOffer: PropTypes.string,
	}).isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

StartingOfferAmount.defaultProps = {
	disabled: false,
};

export default StartingOfferAmount;
