import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import InfoPopover from '@app/components/shared/ui/InfoPopoverIcon';

function EnableAlgoSellLabel() {
	return (
		<Box sx={{ display: 'flex', gap: 0.7 }}>
			<Typography variant="body1">Enable AlgoSell</Typography>
			<InfoPopover>
				<Typography component="span">
					<Box component="span" fontWeight="fontWeightMedium">
						Set it & forget it:
					</Box>{' '}
					Use our machine learning algorithm to automatically sell
					inventory based on the parameters below
				</Typography>
			</InfoPopover>
		</Box>
	);
}

export default EnableAlgoSellLabel;
