import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

function CustomerPortalHelmet({ children }) {
	return (
		<>
			{/* https://shopify.dev/apps/store/security/secure-tokens */}
			<Helmet>
				<meta name="robots" content="noindex" />
			</Helmet>
			{children}
		</>
	);
}

CustomerPortalHelmet.propTypes = {
	children: PropTypes.node.isRequired,
};

export default CustomerPortalHelmet;
