import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash-es';

import MUIDataTable from 'mui-datatables';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Box } from '@mui/material';
import ActionsRenderer from '../../../components/shared/TableCells/AnalyzeButton';
import RevenueRenderer from '@app/components/muiTable/elements/RevenueRenderer';
import ExecutionProgress from '@app/components/muiTable/elements/ExecutionProgress';
import CollectionsRenderer from '@app/components/muiTable/elements/CollectionsRenderer';
import SellthroughRenderer from '@app/components/muiTable/elements/SellthroughRenderer';
import UnitsRenderer from '@app/components/muiTable/elements/UnitsRenderer';
import { campaignFinalized } from '../utils';
import { getVariantSummaryStorageKey } from '@app/lib/localStorage';
import TitleWithImage from '@app/components/muiTable/elements/TitleWithImage';
import {
	getProductLink,
	getProductVariantLink,
} from '@app/lib/shopify';
import { useCampaignProducts } from '@app/hooks/useNyopCampaign';
import useAuth from '@app/hooks/useAuth';
import { getRangeOptions } from '@app/components/shared/Table/components/MuiDatatableRangeFilter';
import { useCollections } from '@app/hooks/useCollections';
import { capitalizeFirstLetter } from '@app/helpers/stringHelpers';
import Loader from '@app/components/shared/loaders/CircularLoader';

const DataColumnOptions = {
	viewColumns: false,
	filter: false,
	sort: false,
	download: false,
	display: 'excluded',
};

const DataIndex = {
	campaignId: -8,
	summary: -7,
	isFinalized: -6,
	shop: -5,
	id: -4,
	variants: -3,
	image_url: -2,
	handle: -1,
};

function getDataColumns() {
	return Object.keys(DataIndex).map((name) => ({
		name,
		options: DataColumnOptions,
	}));
}

function getProductTitle(value, data) {
	const imageUrl = data.rowData.at(DataIndex.image_url);
	const id = data.rowData.at(DataIndex.id);
	const shop = data.rowData.at(DataIndex.shop);
	const handle = data.rowData.at(DataIndex.handle);

	return (
		<TitleWithImage
			title={value}
			imageUrl={imageUrl}
			subtitle={handle}
			url={getProductLink(shop, id)}
		/>
	);
}

function getExecutionProgress(value, data) {
	const summary = data.rowData.at(DataIndex.summary);
	return (
		<ExecutionProgress
			total={value}
			convertedUnits={summary.convertedUnits}
			counteredUnits={summary.counteredUnits}
			lostUnits={summary.lostUnits}
		/>
	);
}

function getCollectionsRenderer(value) {
	const collections = value.map((node) => node.title);
	return <CollectionsRenderer collections={collections} />;
}

function getActionsRenderer(value, data) {
	const campaignId = data.rowData.at(DataIndex.campaignId);
	return (
		<ActionsRenderer campaignId={campaignId} productId={value} />
	);
}

function getRevenueRenderer(value, data) {
	const isFinalized = data.rowData.at(DataIndex.isFinalized);
	const summary = data.rowData.at(DataIndex.summary);
	return (
		<RevenueRenderer
			factualRevenue={summary.factualRevenue}
			possibleRevenue={summary.possibleRevenue}
			potentialRevenue={summary.potentialRevenue}
			isFinalized={isFinalized}
		/>
	);
}

function getSellthroughRenderer(value, data) {
	const summary = data.rowData.at(DataIndex.summary);
	const availableUnits = data.rowData
		.at(DataIndex.variants)
		.reduce((res, v) => res + v.inventory_quantity, 0);
	const isFinalized = data.rowData.at(DataIndex.isFinalized);
	return (
		<SellthroughRenderer
			factualOffersUnits={summary.factualOffersUnits}
			possibleOffersUnits={summary.possibleOffersUnits}
			potentialOffersUnits={summary.potentialOffersUnits}
			isFinalized={isFinalized}
			availableUnits={availableUnits}
		/>
	);
}

function getUnitsRenderer(value, data) {
	const summary = data.rowData.at(DataIndex.summary);
	const isFinalized = data.rowData.at(DataIndex.isFinalized);
	return (
		<UnitsRenderer
			factualOffersUnits={summary.factualOffersUnits}
			possibleOffersUnits={summary.possibleOffersUnits}
			potentialOffersUnits={summary.potentialOffersUnits}
			isFinalized={isFinalized}
		/>
	);
}

function getColumns({
	formatMerchantCurrency,
	formatMerchantNumber,
	products,
	allCollections,
}) {
	const revenueList = products.map((v) => v.revenue);
	const maxRevenue = Math.max(...revenueList);
	const minRevenue = Math.min(...revenueList);

	return [
		{
			name: 'title',
			label: 'Product',
			options: {
				filter: false,
				customBodyRender: getProductTitle,
			},
		},
		{
			name: 'collections',
			label: 'Collection',
			options: {
				sort: false,
				customBodyRender: getCollectionsRenderer,
				filterType: 'multiselect',
				filterList: [],
				filterOptions: {
					names: allCollections,
				},
			},
		},
		{
			name: 'revenue',
			label: 'Revenue',
			options: {
				filterType: 'custom',
				filterOptions: getRangeOptions({
					format: formatMerchantCurrency,
					revert: (value) => Number(value.replace(/[^0-9.-]+/g, '')),
					maxValue: maxRevenue,
					minValue: minRevenue,
				}),
				customBodyRender: getRevenueRenderer,
			},
		},
		{
			name: 'sellThrough',
			label: 'Sell-through',
			options: {
				filter: false,
				customBodyRender: getSellthroughRenderer,
			},
		},
		{
			name: 'offersUnits',
			label: 'Units from offers',
			options: {
				filter: false,
				customBodyRender: getUnitsRenderer,
			},
		},
		{
			name: 'inStockUnits',
			label: 'Units available',
			options: {
				sort: false,
				filter: false,
				customBodyRender: (value) => (
					<Box sx={{ textAlign: 'center' }}>
						{formatMerchantNumber({ value })}
					</Box>
				),
			},
		},
		{
			name: 'summary.totalUnits',
			label: 'Execution Progress',
			options: {
				filter: false,
				customBodyRender: getExecutionProgress,
			},
		},
		{
			name: 'id',
			label: 'Actions',
			options: {
				filter: false,
				sort: false,
				download: false,
				customBodyRender: getActionsRenderer,
			},
		},
		// // These columns are here just for reference, all new columns should be added on the top
		...getDataColumns(),
	];
}

function Table({ campaign }) {
	const { formatMerchantCurrency, formatMerchantNumber } = useAuth();
	const isFinalized = campaignFinalized(campaign);

	// table state
	const [expandedProductsIds, setExpandedProductsIds] =
		React.useState([]);

	// filtering/pagination state values
	const [count, setCount] = React.useState(null);
	const [searchTerm, setSearchTerm] = React.useState('');
	const [page, setPage] = React.useState(0);
	const [sortKey, setSortKey] = React.useState(
		'summary.factualRevenue,desc',
	);
	const [perPage, setPerPage] = React.useState(20);
	const [collections, setCollections] = React.useState([]);

	const { data, isLoading, error, isFetching } = useCampaignProducts({
		campaignId: campaign.id,
		queryVariables: {
			searchTerm,
			page: page + 1,
			perPage,
			sortKey,
			getCount: count === null,
			collections,
		},
	});

	const {
		data: collectionData,
		isLoading: isCollectionsDataLoading,
		isFetching: isCollectionsDataFetching,
	} = useCollections({
		cacheKey: '',
		queryVariables: { query: '' },
	});
	const allCollections = React.useMemo(
		() =>
			(collectionData?.collections || []).map((collection) =>
				capitalizeFirstLetter(collection.title),
			),
		[collectionData],
	);

	React.useEffect(() => {
		if (data && count === null) {
			setCount(data.count);
		}
	}, [data, count]);

	const products = React.useMemo(
		() =>
			(data?.products || []).map((product) => ({
				...product,
				revenue: isFinalized
					? product.summary.factualRevenue
					: product.summary.possibleRevenue,
				offersUnits: product.summary.potentialOffersUnits,
				inStockUnits: product.variants.reduce(
					(res, v) => res + v.inventory_quantity,
					0,
				),
				campaignId: campaign.id,
				isFinalized,
			})),
		[campaign, isFinalized, data],
	);

	const columns = React.useMemo(
		() =>
			getColumns({
				formatMerchantCurrency,
				formatMerchantNumber,
				products,
				allCollections,
			}),
		[
			formatMerchantCurrency,
			formatMerchantNumber,
			products,
			allCollections,
		],
	);

	let message = 'Sorry, no Products found';
	if (error) {
		message =
			'Sorry, failed to load Products. Try to refresh the page';
	} else if (
		isLoading ||
		isCollectionsDataLoading ||
		isFetching ||
		isCollectionsDataFetching
	) {
		message = <Loader />;
	}

	const rowsExpanded = products
		.map((product, index) => [product.id, index])
		.filter(([productId]) => expandedProductsIds.includes(productId))
		.map(([, index]) => index);

	return (
		<MUIDataTable
			title="Campaign Summary"
			data={isFetching || isCollectionsDataFetching ? [] : products}
			columns={columns}
			options={{
				elevation: 0,
				filter: true,
				search: true,
				print: false,
				download: false,
				serverSide: true,
				filterType: 'dropdown',
				enableNestedDataAccess: '.',
				storageKey: getVariantSummaryStorageKey(campaign.id),
				selectableRowsHideCheckboxes: true,
				viewColumns: false,
				textLabels: {
					body: {
						noMatch: message,
					},
				},
				responsive: 'simple',
				expandableRows: true,
				expandableRowsHeader: false,
				expandableRowsOnClick: true,
				rowsExpanded,
				isRowExpandable: (dataIndex) => {
					const variants = products[dataIndex]?.variants;
					return variants && variants.length !== 0;
				},
				renderExpandableRow: (rowData) => {
					const id = rowData.at(DataIndex.id);
					const shop = rowData.at(DataIndex.shop);
					const variants = rowData.at(DataIndex.variants);
					const imageUrl = rowData.at(DataIndex.image_url);

					return variants.map((variant) => (
						<TableRow key={variant.id}>
							<TableCell sx={{ pb: '4px', pt: '4px', pl: '2rem' }} />
							<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
								<TitleWithImage
									title={variant.title}
									imageUrl={variant.image_url || imageUrl}
									subtitle={variant.sku}
									url={getProductVariantLink(shop, id, variant.id)}
								/>
							</TableCell>
							<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
								-
							</TableCell>
							<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
								<RevenueRenderer
									potentialRevenue={variant.summary.potentialRevenue}
									possibleRevenue={variant.summary.possibleRevenue}
									factualRevenue={variant.summary.factualRevenue}
									isFinalized={isFinalized}
								/>
							</TableCell>
							<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
								<SellthroughRenderer
									factualOffersUnits={
										variant.summary.factualOffersUnits
									}
									possibleOffersUnits={
										variant.summary.possibleOffersUnits
									}
									potentialOffersUnits={
										variant.summary.potentialOffersUnits
									}
									isFinalized={isFinalized}
									availableUnits={variant.inventory_quantity}
								/>
							</TableCell>
							<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
								<UnitsRenderer
									factualOffersUnits={
										variant.summary.factualOffersUnits
									}
									possibleOffersUnits={
										variant.summary.possibleOffersUnits
									}
									potentialOffersUnits={
										variant.summary.potentialOffersUnits
									}
									isFinalized={isFinalized}
								/>
							</TableCell>
							<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
								<Box sx={{ textAlign: 'center' }}>
									{formatMerchantNumber({
										value: variant.inventory_quantity,
									})}
								</Box>
							</TableCell>
							<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
								<ExecutionProgress
									total={variant.summary.totalUnits}
									lostUnits={variant.summary.lostUnits}
									convertedUnits={variant.summary.convertedUnits}
									counteredUnits={variant.summary.counteredUnits}
								/>
							</TableCell>
							<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
								<ActionsRenderer
									campaignId={campaign.id}
									productId={id}
									variantId={variant.id}
								/>
							</TableCell>
						</TableRow>
					));
				},
				onRowExpansionChange: (
					currentRowsExpanded,
					allRowsExpanded,
				) => {
					let newExpandedProductsIds = expandedProductsIds;
					currentRowsExpanded.forEach((currentRow) => {
						const isExpanded = allRowsExpanded.find(
							(row) => row.dataIndex === currentRow.dataIndex,
						);

						const productId = products[currentRow.dataIndex].id;
						if (
							isExpanded &&
							!expandedProductsIds.includes(productId)
						) {
							newExpandedProductsIds.push(productId);
						} else {
							newExpandedProductsIds = newExpandedProductsIds.filter(
								(p) => p !== productId,
							);
						}
					});

					setExpandedProductsIds(newExpandedProductsIds);
				},

				onColumnSortChange: (column, direction) => {
					if (column === 'revenue') {
						setSortKey(`summary.factualRevenue,${direction}`);
					} else if (
						column === 'offersUnits' ||
						column === 'sellThrough'
					) {
						setSortKey(`summary.factualOffersUnits,${direction}`);
					} else {
						setSortKey(`${column},${direction}`);
					}
				},
				onFilterChange: (changedColumn, filterList) => {
					if (filterList[1]) {
						setCollections(filterList[1]);
					}
				},
				// Pagination configurations
				count: data?.count ?? 20,
				page,
				rowsPerPage: perPage,
				rowsPerPageOptions: [10, 20, 50, 100],
				onChangeRowsPerPage: (value) => {
					setPerPage(value);
					setPage(0);
				},
				onChangePage: (newPage) => {
					setPage(newPage);
				},
				searchText: searchTerm,
				onSearchChange: _.debounce((newSearchTerm) => {
					setSearchTerm(newSearchTerm);
				}, 400),
			}}
		/>
	);
}

Table.propTypes = {
	campaign: PropTypes.shape({
		id: PropTypes.string.isRequired,
		end_date: PropTypes.string.isRequired,
	}).isRequired,
};

export default Table;
