import axios from './index';

const apiUrl = '/api/preorders';

export async function getById({ sellingPlanGroupId }) {
	const encodedSellingPlanId = encodeURIComponent(sellingPlanGroupId);
	const response = await axios.get(
		`${apiUrl}/${encodedSellingPlanId}`,
	);
	return response.data;
}

export async function fulfillPreorders({ sellingPlanGroupId }) {
	const response = await axios.post(`${apiUrl}/fulfillment`, {
		sellingPlanGroupId,
	});
	return response.data;
}
