import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

function OutlineBox({ children, sx, ...rest }) {
	return (
		<Box
			p={2}
			mb={2}
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				border: 1,
				borderColor: 'divider',
				...sx,
			}}
			{...rest}
		>
			{children}
		</Box>
	);
}

OutlineBox.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	sx: PropTypes.shape({}),
};

OutlineBox.defaultProps = {
	sx: {},
};

export default OutlineBox;
