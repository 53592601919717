import * as React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import ButtonBase from '@mui/material/ButtonBase';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import useAuth from '../../hooks/useAuth';

import constants from '../../constants';
import { GAevent } from '../../lib/googleAnalytics';
import {
	eventCategories,
	events,
	transport,
} from '../../constants/googleAnalytics';
import Button from '../shared/button/Button';

function AccountButton() {
	const { user, logout, merchant } = useAuth();
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const location = useLocation();

	const storeInitials = React.useMemo(() => {
		if (!merchant?.name) {
			return '';
		}
		const nameParts = merchant.name.split(' ').slice(0, 2);
		return nameParts.map((part) => part[0]).join('');
	}, [merchant?.name]);

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onFaqClick = () => {
		GAevent({
			categoryName: eventCategories.FAQ,
			eventName: events.VISIT,
			transport: transport.BEACON,
		});
		handleClose();
	};

	const handleLogout = () => {
		GAevent({
			categoryName: eventCategories.LOGOUT,
			eventName: events.SUBMIT,
			transport: transport.BEACON,
		});
		logout();
		handleClose();
	};

	// If we are on registration page, do not confuse the user with login button
	if (location?.pathname === '/register') {
		return null;
	}

	if (!user) {
		return (
			<Box>
				<Button
					component={NavLink}
					to="/login"
					aria-label="Link to Login page"
					sx={{
						bgcolor: 'background.default',
						color: 'primary.main',
					}}
				>
					Login
				</Button>
			</Box>
		);
	}

	return (
		<Box>
			<ButtonBase
				sx={{ ml: 1.3 }}
				aria-label="account of current user"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				onClick={handleMenu}
			>
				<Avatar
					sx={{
						width: '28px',
						height: '28px',
					}}
				>
					<Typography>{storeInitials}</Typography>
				</Avatar>
			</ButtonBase>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem
					onClick={onFaqClick}
					component={Link}
					target="_blank"
					rel="noopener noreferrer"
					href={constants.faqUrl}
				>
					Bazo FAQ
				</MenuItem>
				<MenuItem onClick={() => navigate('/onboarding')}>
					Onboarding
				</MenuItem>
				<MenuItem onClick={handleLogout}>Logout</MenuItem>
			</Menu>
		</Box>
	);
}

export default AccountButton;
