import React from 'react';

export function usePagination({ data, resultsPerPage }) {
	const [page, setPage] = React.useState(0);

	const start = page * resultsPerPage;
	const end = (page + 1) * resultsPerPage;
	const dataWithPagination = data.slice(start, end);

	const hasNext = end < data.length;
	const hasPrevious = page !== 0;

	const onNext = React.useCallback(() => {
		setPage((prevPage) => prevPage + 1);
	}, []);

	const onPrevious = React.useCallback(() => {
		setPage((prevPage) => prevPage - 1);
	}, []);

	return {
		page,
		setPage,
		dataWithPagination,
		hasNext,
		hasPrevious,
		onNext,
		onPrevious,
	};
}
