import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {
	targetPriceFromThreshold,
	thresholdFromTargetPrice,
	validateCounterValueMsrp,
} from '../utils';
import constants from '@app/constants';
import ProductValueInput from './productValueInput';
import TitleWithImage from '@app/components/muiTable/elements/TitleWithImage';
import { getProductVariantLink } from '@app/lib/shopify';
import WrongCounterValuePopover from './wrongCounterValuePopover';
import useAuth from '@app/hooks/useAuth';
import { VariantType } from '@app/propTypes/types';
import {
	THRESHOLD_MAX_VALUE,
	THRESHOLD_MIN_VALUE,
} from '../constants';

function VariantRow({
	variant,
	defaultThreshold,
	parentThreshold,
	setThresholds,
	fixedCostBuffer,
}) {
	const { merchant, formatMerchantCurrency, getCurrencySymbol } =
		useAuth();

	const currencySymbol = getCurrencySymbol();
	const [threshold, setThreshold] = React.useState(defaultThreshold);

	const inputThreshold =
		threshold !== null ? threshold : parentThreshold;

	const [changed, setChanged] = React.useState(!!defaultThreshold);

	const variantMsrp = variant.msrp;
	const variantPrice = variant.price;
	const price = +(variantMsrp || variantPrice);

	const [targetPrice, setTargetPrice] = React.useState(null);
	const inputTargetPrice =
		targetPrice !== null
			? targetPrice
			: targetPriceFromThreshold({
					price,
					threshold: inputThreshold,
			  });

	const [priceValid, setPriceValid] = React.useState(
		validateCounterValueMsrp(
			inputThreshold,
			variantMsrp,
			variantPrice,
		),
	);

	React.useEffect(() => {
		const newPriceValid = validateCounterValueMsrp(
			inputThreshold,
			variantMsrp,
			variantPrice,
		);
		if (newPriceValid !== priceValid) {
			setPriceValid(newPriceValid);
		}
		// eslint-disable-next-line
	}, [threshold, priceValid]);

	const handleClearThreshold = () => {
		setThreshold(null);
		setTargetPrice(null);
		setChanged(false);
		setThresholds((prevValues) => {
			const { [variant.id]: _, ...newValues } = { ...prevValues };
			return newValues;
		});
	};

	const handleChangeThreshold = (event) => {
		const { value } = event.target;
		let newThreshold = +value;
		if (+value <= THRESHOLD_MIN_VALUE) {
			newThreshold = THRESHOLD_MIN_VALUE;
		} else if (+value >= THRESHOLD_MAX_VALUE) {
			newThreshold = THRESHOLD_MAX_VALUE;
		}

		setThresholds((prevValues) => ({
			...prevValues,
			[variant.id]: newThreshold,
		}));

		if (!changed) {
			setChanged(true);
		}

		const newTargetPrice = targetPriceFromThreshold({
			price,
			threshold: newThreshold,
		});
		setTargetPrice(newTargetPrice);

		setThreshold(newThreshold);
	};

	const handleChangeTargetPrice = (event) => {
		const { value } = event.target;
		let correctedTargetPrice = +value;
		if (+value <= 0) {
			correctedTargetPrice = 0;
		} else if (+value >= price) {
			correctedTargetPrice = price;
		}

		const newThreshold = thresholdFromTargetPrice({
			price,
			targetPrice: correctedTargetPrice,
		});

		setThresholds((prevValues) => ({
			...prevValues,
			[variant.id]: newThreshold,
		}));
		setChanged(true);
		setThreshold(newThreshold);
		setTargetPrice(correctedTargetPrice);
	};

	let textColor = constants.colors.pending;
	if (!priceValid) {
		textColor = constants.colors.failure;
	} else if (changed) {
		textColor = constants.colors.textMain;
	}

	const imageUrl = variant.image_url;

	return (
		<TableRow>
			<TableCell />
			<TableCell sx={{ pb: '4px', pt: '4px', pl: '2rem' }}>
				<TitleWithImage
					title={variant.title}
					imageUrl={variant.image_url || imageUrl}
					url={getProductVariantLink(
						merchant.myshopify_domain,
						variant.product_id,
						variant.id,
					)}
					titleIcon={
						!priceValid ? <WrongCounterValuePopover /> : null
					}
				/>
			</TableCell>
			<TableCell
				sx={{
					pb: '21px',
					pt: '21px',
					mr: 3,
					fontWeight: variantMsrp !== null ? 'bold' : 500,
					fontSize: 14,
				}}
			>
				{variantMsrp !== null
					? formatMerchantCurrency((+variantMsrp).toFixed(2))
					: 'N/A'}
			</TableCell>
			<TableCell
				sx={{
					pb: '21px',
					pt: '21px',
					mr: 3,
					fontWeight: variantMsrp === null ? 'bold' : 500,
					fontSize: 14,
				}}
			>
				{formatMerchantCurrency((+variantPrice).toFixed(2))}
			</TableCell>
			<TableCell
				sx={{
					pb: '21px',
					mr: 3,
				}}
			>
				<ProductValueInput
					id={variant.product_id}
					textColor={textColor}
					value={inputThreshold}
					handleClearThreshold={handleClearThreshold}
					handleChange={handleChangeThreshold}
					isValueDifferent={changed}
					endAdornment="%"
				/>
			</TableCell>

			<TableCell
				sx={{
					pb: '21px',
					mr: 3,
				}}
			>
				<ProductValueInput
					id={variant.product_id}
					textColor={textColor}
					value={inputTargetPrice}
					handleClearThreshold={handleClearThreshold}
					handleChange={handleChangeTargetPrice}
					isValueDifferent={changed}
					startAdornment={currencySymbol}
					maxValue={parseFloat(variantPrice)}
				/>
			</TableCell>

			{fixedCostBuffer ? (
				<TableCell>
					<Typography>
						{formatMerchantCurrency(
							(targetPrice || inputTargetPrice) + fixedCostBuffer,
						)}
					</Typography>
				</TableCell>
			) : null}
		</TableRow>
	);
}

VariantRow.propTypes = {
	variant: VariantType.isRequired,
	defaultThreshold: PropTypes.number,
	parentThreshold: PropTypes.number.isRequired,
	setThresholds: PropTypes.func.isRequired,
	fixedCostBuffer: PropTypes.number,
};

VariantRow.defaultProps = {
	defaultThreshold: null,
	fixedCostBuffer: 0,
};

export default VariantRow;
