import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import Typography from '@mui/material/Typography';
import { useSyncMarketplaceCampaignProducts } from '@app/hooks/useMarketplace';
import { useMarketplaceCampaignContext } from '@app/hooks/useMarketplaceCampaignContext';

function SyncVariantsButton({ id, variants, supplierVariantsCount }) {
	const { mutate } = useSyncMarketplaceCampaignProducts({});
	const { runningSyncs } = useMarketplaceCampaignContext();

	const onClick = () => {
		mutate({ id });
	};

	const disabled = runningSyncs && runningSyncs.length !== 0;

	const buttonTooltip = disabled
		? 'Marketplace products sync is in progress'
		: 'Click to make sure all variants are up-to-date in the marketplace with variants in the campaign';

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			{supplierVariantsCount !== variants.length ? (
				<Tooltip title="Some of the variants are missing, please run products synchronization by clicking button on the right">
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<WarningOutlinedIcon fontSize="16" />
						<Typography sx={{ textWrap: 'nowrap' }}>
							{variants.length}/{supplierVariantsCount}
						</Typography>
					</Box>
				</Tooltip>
			) : (
				<Typography>{variants.length}</Typography>
			)}
			<Tooltip title={buttonTooltip} arrow placement="top">
				<span>
					<IconButton onClick={onClick} disabled={disabled}>
						<RefreshIcon sx={{ width: 20, height: 20 }} />
					</IconButton>
				</span>
			</Tooltip>
		</Box>
	);
}

SyncVariantsButton.propTypes = {
	id: PropTypes.string.isRequired,
	supplierVariantsCount: PropTypes.number.isRequired,
	variants: PropTypes.arrayOf(
		PropTypes.shape({ id: PropTypes.string.isRequired }),
	).isRequired,
};

export default SyncVariantsButton;
