import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import { localizeCurrency } from '@app/helpers/localization.helpers';

function SaveBulkPriceText({
	bulkOffersPriceDiscount,
	customerLocale,
	currencyCode,
	sx,
}) {
	if (!bulkOffersPriceDiscount) {
		return null;
	}

	return (
		<Typography
			fontWeight="bold"
			color="text.secondary"
			variant="h6"
			sx={sx}
		>
			Accept All and Save{' '}
			{localizeCurrency({
				customerLocale,
				currencyCode,
				amount: bulkOffersPriceDiscount,
			})}
		</Typography>
	);
}

SaveBulkPriceText.propTypes = {
	bulkOffersPriceDiscount: PropTypes.number.isRequired,
	customerLocale: PropTypes.string.isRequired,
	currencyCode: PropTypes.string.isRequired,
	sx: PropTypes.instanceOf(Object),
};

SaveBulkPriceText.defaultProps = {
	sx: {},
};

export default SaveBulkPriceText;
