import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import constants from '@app/constants';

function AcceptTerms({ handleChange, acceptTerms }) {
	return (
		<Box>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							required
							onChange={handleChange}
							checked={acceptTerms}
						/>
					}
					label={
						<Box component="span">
							I have read and agree to the{' '}
							<Link
								component={Link}
								target="_blank"
								rel="noopener noreferrer"
								href={constants.merchantTermsUrl}
							>
								Merchant Terms and Conditions
							</Link>
							,{' '}
							<Link
								target="_blank"
								rel="noopener noreferrer"
								href={constants.purchaserTermsUrl}
							>
								Purchaser Terms and Conditions
							</Link>
							, and{' '}
							<Link
								target="_blank"
								rel="noopener noreferrer"
								href={constants.privacyUrl}
							>
								Privacy Policy
							</Link>{' '}
						</Box>
					}
				/>
			</FormGroup>
		</Box>
	);
}

AcceptTerms.propTypes = {
	handleChange: PropTypes.func.isRequired,
	acceptTerms: PropTypes.bool,
};

export default AcceptTerms;
