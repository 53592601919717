import ReactGA from 'react-ga4';
import { transport as gaTransport } from '../constants/googleAnalytics';

export const initGA = (trackingId, opts = {}) => {
	ReactGA.initialize(trackingId, opts);
};

export const GAsetPage = (page, options = {}) => {
	ReactGA.set({ page, ...options });
};

export const GApageView = (path) => {
	ReactGA.send({ hitType: 'pageview', page: path });
};

export function sendPageview(path) {
	ReactGA.set({ page: path });
	ReactGA.send({ hitType: 'pageview', page: path });
}

export const GAmodalView = (modal) => {
	ReactGA.modalview(modal);
};

export const GAevent = ({
	categoryName,
	eventName,
	label = null,
	// value = 0,
	nonInteraction = false,
	transport = gaTransport.XHR,
}) => {
	ReactGA.event({
		category: categoryName, // Required
		action: eventName, // Required
		label,
		// value,
		nonInteraction,
		transport,
	});
};

export const GAtiming = ({
	categoryName,
	variableName,
	valueNum,
}) => {
	ReactGA.event({
		category: 'timing',
		action: categoryName,
		label: variableName,
		value: valueNum,
		transport: 'xhr',
	});
};

export const GAexception = (detail) => {
	ReactGA.exception({ description: detail });
};
