import * as React from 'react';
import StorefrontIcon from '@mui/icons-material/Storefront';

import LoginSignUpContainer from '../../components/shared/ui/LoginSignupContainer';
import SelectShopForm from '../login/SelectShop';

import useAuth from '../../hooks/useAuth';
import Button from '@app/components/shared/button/Button';

function SelectShopPage() {
	const {
		user,
		// merchant,
		loading,
		// error,
		onActiveShopChange,
	} = useAuth();
	const [activeShop, setActiveShop] = React.useState('');

	const activeMerchants = user?.merchants.filter((m) => m.active);
	const handleActiveShopChange = (event) => {
		const { value } = event.target;
		setActiveShop(value);
	};

	const onSubmit = (event) => {
		event.preventDefault();
		onActiveShopChange({ merchantId: activeShop });
	};

	if (activeMerchants && activeMerchants.length !== 0) {
		return (
			<LoginSignUpContainer
				headerText="Select your shop"
				IconComponent={StorefrontIcon}
			>
				<SelectShopForm
					activeShop={activeShop}
					handleChange={handleActiveShopChange}
					selectOptions={user?.merchants
						?.filter((m) => m.active)
						.map((m) => ({
							label: m.name,
							value: m.id,
						}))}
				/>
				<Button
					type="submit"
					fullWidth
					sx={{ mt: 3, mb: 2 }}
					disabled={activeShop === ''}
					loading={loading}
					onSubmit={onSubmit}
					onClick={onSubmit}
				>
					Shop Login
				</Button>
			</LoginSignUpContainer>
		);
	}

	return (
		<LoginSignUpContainer headerText="You don't have any active shops to log into" />
	);
}

export default SelectShopPage;
