import * as React from 'react';
import {
	Navigate,
	Route,
	Routes,
	useNavigate,
} from 'react-router-dom';

import { initGA } from './lib/googleAnalytics';
import CustomerPortalHelmet from './components/customerPortal/CustomerPortalHelmet';

import DashboardFrame from './components/DashboardFrame';

import Login from './pages/login';
import Register from './pages/register';
import ResetPassword from './pages/resetPassword';
import SelectActiveShop from './pages/selectShop';
import InventoryReview from './pages/inventoryReview';
import CampaignManagement from './pages/campaignManagement';
import Onboarding from './pages/onboarding';
import EditCampaign from './pages/editCampaign';
import PreorderCampaignDetailsPage from './pages/campaignDetails/preorderCampaignDetails';
import PreorderExecution from './pages/preorders';
import NYOPCampaignSummary from './pages/nyopCampaign/index';
import NYOPVariantOffers from './pages/nyopCampaign/variantOffers';
import NYOPProductOffers from './pages/nyopCampaign/productOffers';
import CustomerPortalNyop from './pages/customerPortalNyop';
import CustomerPortalPreorder from './pages/customerPortalPreorder';
import SubscriptionsPage from './pages/settings/subscriptions';
import NotFound from './pages/notFound';

import RequireAuth from './hooks/RequireAuth';
import useCookieContext from './hooks/useCookieConsent';
import useGoogleAnalytics from './hooks/useGoogleAnalytics';
import constants from './constants';
import NotWhitelisted from './pages/notWhitelisted';
import BillingPage from './pages/settings/billing/index';
import useTrackLoad from './hooks/useTrackLoad';
import usePaintPerformance from './hooks/usePaintPerformance';
import CampaignWorkflowStepper from './components/campaignWorkflowStepper/CampaignWorkflowStepper';
import NYOPProductsSummary from '@app/pages/nyopCampaign/products/index';
import NYOPProductDetails from '@app/pages/nyopCampaign/products/productOffers/index';
import NYOPVariantDetails from '@app/pages/nyopCampaign/products/variantOffers/index';
import AppConfigurations from '@app/pages/settings/appConfigs/index';
import Email from '@app/pages/settings/email';
import SettingsNYOP from '@app/pages/settings/nyop';
import TabsNavigation from '@app/components/shared/tabsNavigation/TabsNavigation';
import {
	campaignMenuItems,
	getSettingsMenuItems,
} from '@app/constants/navigationItems';
import NyopInbox from '@app/pages/nyop/inbox/index';
import CreateCampaignForm from './pages/createCampaign';
import OnboardingSubscriptions from '@app/pages/onboarding/subscriptions/OnboardingSubscriptions';
import globalRouter from './lib/globalRouter';
import { FLAGS, useFlagEnabled } from './hooks/useFlags';
import IntegrationsPage from './pages/settings/integrations';
import MarketplaceSupplier from '@app/pages/marketplace/supplier/index';
import MarketplaceRetailer from '@app/pages/marketplace/retailer/index';
import useAuth from '@app/hooks/useAuth';
import MarketplaceCampaignSummary from '@app/pages/marketplace/campaigns/index';

function App() {
	// Initialize global router to use react navigation in axios interceptors
	const navigate = useNavigate();
	const { merchant } = useAuth();

	globalRouter.navigate = navigate;

	const klaviyoIntegrationFeatureEnabled = useFlagEnabled(
		FLAGS.KLAVIYO_INTEGRATION,
	);

	const { cookieConsent } = useCookieContext();

	// Initialize any trackers that require cookie consent
	React.useEffect(() => {
		if (cookieConsent) {
			initGA(import.meta.env.VITE_GA_TRACKING_ID);
		}
	}, [cookieConsent]);
	useGoogleAnalytics();
	useTrackLoad();
	usePaintPerformance();

	return (
		<Routes>
			<Route element={<DashboardFrame />}>
				<Route path="login" element={<Login />} />
				<Route path="register" element={<Register />} />
				<Route path="reset-password" element={<ResetPassword />} />
				<Route
					path="/select-active-merchant"
					element={
						<RequireAuth>
							<SelectActiveShop />
						</RequireAuth>
					}
				/>
				<Route
					path="/"
					element={
						<RequireAuth>
							<InventoryReview />
						</RequireAuth>
					}
				/>
				<Route
					path="marketplace/supplier"
					element={<MarketplaceSupplier />}
				/>
				<Route
					path="marketplace/retailer"
					element={<MarketplaceRetailer />}
				/>
				<Route
					path="campaigns/preorders/:id"
					element={<PreorderExecution />}
				/>
				<Route
					path="campaigns/preorders/:id/edit"
					element={
						<RequireAuth>
							<PreorderCampaignDetailsPage />
						</RequireAuth>
					}
				/>
				<Route
					path="nyop/products"
					element={
						<RequireAuth>
							<NYOPProductsSummary />
						</RequireAuth>
					}
				/>
				<Route
					path="nyop/products/:productId"
					element={
						<RequireAuth>
							<NYOPProductDetails />
						</RequireAuth>
					}
				/>
				<Route
					path="nyop/products/:productId/variants"
					element={<Navigate to=".." relative="path" />}
				/>
				<Route
					path="nyop/products/:productId/variants/:variantId"
					element={
						<RequireAuth>
							<NYOPVariantDetails />
						</RequireAuth>
					}
				/>
				<Route
					path="nyop/inbox"
					element={
						<RequireAuth>
							<NyopInbox />
						</RequireAuth>
					}
				/>
				<Route
					path="campaigns/create"
					element={<CreateCampaignForm />}
				/>
				<Route
					path="campaigns/nyop/:id/edit"
					element={
						<RequireAuth>
							<EditCampaign />
						</RequireAuth>
					}
				/>
				<Route
					element={
						<TabsNavigation
							items={campaignMenuItems.filter(
								(route) =>
									!route.condition || route.condition(merchant),
							)}
						/>
					}
				>
					<Route
						path="marketplace/campaigns"
						element={
							<RequireAuth>
								<CampaignManagement
									type={constants.campaignTypes.MARKETPLACE}
								/>
							</RequireAuth>
						}
					/>
					<Route element={<CampaignWorkflowStepper activeStep={0} />}>
						<Route
							path="nyop"
							element={<Navigate to="/campaigns/nyop" />}
						/>
						<Route
							path="campaigns"
							element={<Navigate to="/campaigns/nyop" />}
						/>
						<Route
							path="/nyop/campaigns"
							element={<Navigate to="/campaigns/nyop" />}
						/>

						<Route
							path="campaigns/nyop"
							element={
								<RequireAuth>
									<CampaignManagement
										type={constants.campaignTypes.NYOP}
									/>
								</RequireAuth>
							}
						/>
					</Route>
					<Route element={<CampaignWorkflowStepper activeStep={0} />}>
						<Route
							path="preorders"
							element={<Navigate to="/campaigns/preorders" />}
						/>

						<Route
							path="campaigns/preorders"
							element={
								<RequireAuth>
									<CampaignManagement
										type={constants.campaignTypes.PREORDER}
									/>
								</RequireAuth>
							}
						/>
					</Route>
				</Route>
				<Route
					path="marketplace/campaigns/:id"
					element={
						<RequireAuth>
							<MarketplaceCampaignSummary />
						</RequireAuth>
					}
				/>
				<Route element={<CampaignWorkflowStepper activeStep={1} />}>
					<Route
						path="campaigns/nyop/:id"
						element={
							<RequireAuth>
								<NYOPCampaignSummary />
							</RequireAuth>
						}
					>
						<Route
							path="products"
							element={<Navigate to=".." relative="path" />}
						/>
					</Route>
				</Route>
				<Route element={<CampaignWorkflowStepper activeStep={2} />}>
					<Route
						path="campaigns/nyop/:id/products/:productId/variants?"
						element={
							<RequireAuth>
								<NYOPProductOffers />
							</RequireAuth>
						}
					/>
				</Route>
				<Route element={<CampaignWorkflowStepper activeStep={2} />}>
					<Route
						path="campaigns/nyop/:id/products/:productId/variants/:variantId"
						element={
							<RequireAuth>
								<NYOPVariantOffers />
							</RequireAuth>
						}
					/>
				</Route>
				<Route
					element={
						<TabsNavigation
							items={getSettingsMenuItems(
								klaviyoIntegrationFeatureEnabled,
							)}
						/>
					}
				>
					<Route
						path="/settings"
						element={<Navigate to="/settings/inventory" />}
					/>
					<Route
						path="/settings/nyop"
						element={
							<RequireAuth>
								<SettingsNYOP />
							</RequireAuth>
						}
					/>
					<Route
						path="/settings/subscriptions"
						element={
							<RequireAuth>
								<SubscriptionsPage />
							</RequireAuth>
						}
					/>
					<Route
						path="/settings/email"
						element={
							<RequireAuth>
								<Email />
							</RequireAuth>
						}
					/>
					{klaviyoIntegrationFeatureEnabled && (
						<Route
							path="/settings/integrations"
							element={
								<RequireAuth>
									<IntegrationsPage />
								</RequireAuth>
							}
						/>
					)}
					<Route
						path="/settings/inventory"
						element={
							<RequireAuth>
								<AppConfigurations />
							</RequireAuth>
						}
					/>
				</Route>
				<Route
					path="/billing"
					element={
						<RequireAuth>
							<BillingPage />
						</RequireAuth>
					}
				/>
				{/* TODO: Add this back when needed */}
				{/* <Route
					path="preferences"
					element={
						<RequireAuth>
							<div>
								<p>Preferences Page!!!</p>
							</div>
						</RequireAuth>
					}
				/> */}
				<Route
					path="onboarding"
					element={
						<RequireAuth>
							<Onboarding />
						</RequireAuth>
					}
				/>
				<Route
					path="onboarding/subscriptions"
					element={
						<RequireAuth>
							<OnboardingSubscriptions />
						</RequireAuth>
					}
				/>
				<Route
					path="/auth/get-access-code"
					element={<NotWhitelisted />}
				/>
				<Route path="*" element={<NotFound />} />
			</Route>

			<Route
				path="/customers/manage-order/nyop"
				element={
					<CustomerPortalHelmet>
						<CustomerPortalNyop />
					</CustomerPortalHelmet>
				}
			/>
			<Route
				path="/customers/manage-order/preorder"
				element={
					<CustomerPortalHelmet>
						<CustomerPortalPreorder />
					</CustomerPortalHelmet>
				}
			/>
			{/* TODO: Remove this legacy portal from first wave when we think no one will go back and lok at it */}
			<Route
				path="/customers/manage-order"
				element={
					<CustomerPortalHelmet>
						<CustomerPortalPreorder />
					</CustomerPortalHelmet>
				}
			/>
		</Routes>
	);
}

export default App;
