import * as React from 'react';
import PropTypes from 'prop-types';

import { HelmetProvider as HelmetProviderAsync } from 'react-helmet-async';

function HelmetProvider({ children }) {
	return <HelmetProviderAsync>{children}</HelmetProviderAsync>;
}

HelmetProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]).isRequired,
};

export default HelmetProvider;
