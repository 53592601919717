import React from 'react';
import MarketplaceProductsTable from './table';
import { MarketplaceProductsProvider } from './context/useMarketProductsContext';

export default function MarketplaceRetailer() {
	return (
		<MarketplaceProductsProvider>
			<MarketplaceProductsTable />
		</MarketplaceProductsProvider>
	);
}
