import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function VariantOptions({ variant, sx }) {
	const options = variant.product.options.map((option, index) => {
		let value = null;
		if (index === 0) {
			value = variant.option1;
		} else if (index === 1) {
			value = variant.option2;
		} else if (index === 2) {
			value = variant.option3;
		}
		return {
			name: option.name,
			value,
		};
	});

	return (
		<Box>
			{options.map((option) => (
				<Box
					key={`variant-option-${option.name}-${option.value}`}
					sx={{ display: 'flex', alignItems: 'baseline', ...sx }}
				>
					<Typography
						variant="subtitle2"
						component="span"
						sx={{ marginRight: '0.5em' }}
					>
						{option.name}:
					</Typography>
					<Typography
						component="span"
						variant="subtitle2"
						fontWeight="bold"
					>
						{option.value}
					</Typography>
				</Box>
			))}
		</Box>
	);
}

VariantOptions.propTypes = {
	variant: PropTypes.shape({
		option1: PropTypes.string.isRequired,
		option2: PropTypes.string.isRequired,
		option3: PropTypes.string.isRequired,
		product: PropTypes.shape({
			options: PropTypes.arrayOf(
				PropTypes.shape({ name: PropTypes.string.isRequired }),
			),
		}).isRequired,
	}),
	sx: PropTypes.shape({}),
};

export default VariantOptions;
