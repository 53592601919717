import PropTypes from 'prop-types';

import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { CheckCircle } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import {
	useNyopCampaignFinalize,
	useNyopProgress,
} from '@app/hooks/useNyopCampaign';
import useAuth from '@app/hooks/useAuth';
import { parsePercents } from '@app/lib/parse';
import { OffersAggregations } from '../types';
import { campaignBeingFinalized } from '../utils';
import constants from '@app/constants/index';
import Button from '@app/components/shared/button/Button';

function FinalizeAction({ campaign, aggregations, onSuccess }) {
	const { formatMerchantCurrency } = useAuth();
	const [agreed, setAgreed] = React.useState(false);
	const [recreate, setRecreate] = React.useState(false);

	const isBeingFinalized = campaignBeingFinalized(campaign);
	const [processingError, setProcessingError] = React.useState(null);
	const [processingProgress, setProcessingProgress] = React.useState(
		isBeingFinalized ? 0 : null,
	);

	const [failedOffersProgress, setFailedOffersProgress] =
		React.useState(isBeingFinalized ? 0 : null);

	const {
		mutate,
		error,
		isLoading: loading,
	} = useNyopCampaignFinalize({
		id: campaign.id,
		configOpts: {
			onSuccess: () => {
				setProcessingProgress(0);
				setFailedOffersProgress(0);
			},
			meta: {
				toastDisabled: true,
			},
		},
	});

	useNyopProgress({
		id: campaign.id,
		configOpts: {
			enabled:
				processingProgress !== null &&
				processingProgress !== 1 &&
				!processingError,
			// FIXME: Put refetchInterval back to 1500 when we get our memory issues under control
			refetchInterval: 3000,
			onSuccess: (r) => {
				if (!isBeingFinalized) {
					return;
				}
				if (!r.data) {
					return;
				}
				if (r.data.processingError) {
					setProcessingError(r.data.processingError);
					onSuccess();
				}
				if (r.data.offersCount) {
					setProcessingProgress(
						(r.data.offersCount - r.data.uncompletedOffersCount) /
							r.data.offersCount,
					);
					setFailedOffersProgress(
						r.data.failedOffersCount / r.data.offersCount,
					);

					if (r.data.uncompletedOffersCount === 0) {
						onSuccess();
					}
				} else {
					setProcessingProgress(1);
					onSuccess();
				}
			},
		},
	});

	const handleSubmit = () =>
		mutate({ id: campaign.id, shouldRecreate: recreate });

	const acceptedOffersUnits = aggregations.acceptedOffersInventory;
	const acceptedOffersRevenue =
		aggregations.acceptedOffersPossibleRevenue;

	const counteredAndAcceptedOffersUnits =
		aggregations.counteredAndAcceptedOffersInventory;
	const counteredAndAcceptedOffersRevenue =
		aggregations.counteredAndAcceptedOffersPossibleRevenue;

	const totalUnits =
		acceptedOffersUnits + counteredAndAcceptedOffersUnits;
	const totalRevenue =
		acceptedOffersRevenue + counteredAndAcceptedOffersRevenue;

	const counteredOffersUnits = aggregations.counteredOffersInventory;
	const counteredOffersRevenue =
		aggregations.counteredOffersPotentialRevenue;

	const canceledOffersUnits = aggregations.canceledOffersInventory;
	const canceledOffersRevenue =
		aggregations.canceledOffersPotentialRevenue;

	const pendingOffersUnits = aggregations.pendingOffersInventory;
	const pendingOffersRevenue =
		aggregations.pendingOffersPotentialRevenue;

	const lostOffersUnits =
		counteredOffersUnits + canceledOffersUnits + pendingOffersUnits;
	const lostOffersRevenue =
		counteredOffersRevenue +
		canceledOffersRevenue +
		pendingOffersRevenue;

	return (
		<Box sx={{ maxWidth: '38rem', width: '38rem' }}>
			<Typography
				variant="h5"
				sx={{
					fontWeight: 'bold',
					textAlign: 'center',
					marginBottom: '20px',
				}}
			>
				FINALIZE
			</Typography>

			<Grid container>
				<Grid item xs={4}>
					<Typography sx={{ fontWeight: 'bold' }}>
						Category
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography sx={{ fontWeight: 'bold', textAlign: 'right' }}>
						Units
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography sx={{ fontWeight: 'bold', textAlign: 'right' }}>
						Revenue
					</Typography>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={4}>
					Accepted
				</Grid>
				<Grid item xs={4}>
					<Typography sx={{ textAlign: 'right' }}>
						{acceptedOffersUnits}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography sx={{ textAlign: 'right' }}>
						{formatMerchantCurrency(acceptedOffersRevenue)}
					</Typography>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={4}>
					Countered Converted
				</Grid>
				<Grid item xs={4}>
					<Typography sx={{ textAlign: 'right' }}>
						{counteredAndAcceptedOffersUnits}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography sx={{ textAlign: 'right' }}>
						{formatMerchantCurrency(
							counteredAndAcceptedOffersRevenue,
						)}
					</Typography>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={4}>
					Total
				</Grid>
				<Grid item xs={4}>
					<Typography sx={{ textAlign: 'right' }}>
						{totalUnits}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography sx={{ textAlign: 'right' }}>
						{formatMerchantCurrency(totalRevenue)}
					</Typography>
				</Grid>
			</Grid>

			<Divider color={constants.colors.primary} />

			<Grid container>
				<Grid item xs={4}>
					Countered Not Converted
				</Grid>
				<Grid item xs={4}>
					<Typography sx={{ textAlign: 'right' }}>
						{counteredOffersUnits}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography sx={{ textAlign: 'right' }}>
						{formatMerchantCurrency(counteredOffersRevenue)}
					</Typography>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={4}>
					No action
				</Grid>
				<Grid item xs={4}>
					<Typography sx={{ textAlign: 'right' }}>
						{pendingOffersUnits}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography sx={{ textAlign: 'right' }}>
						{formatMerchantCurrency(pendingOffersRevenue)}
					</Typography>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={4}>
					Canceled
				</Grid>
				<Grid item xs={4}>
					<Typography sx={{ textAlign: 'right' }}>
						{canceledOffersUnits}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography sx={{ textAlign: 'right' }}>
						{formatMerchantCurrency(canceledOffersRevenue)}
					</Typography>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={4}>
					Total Leakage
				</Grid>
				<Grid item xs={4}>
					<Typography sx={{ textAlign: 'right' }}>
						{lostOffersUnits}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography sx={{ textAlign: 'right' }}>
						{formatMerchantCurrency(lostOffersRevenue)}
					</Typography>
				</Grid>
			</Grid>

			<Divider color={constants.colors.failure} />

			<Box
				sx={{
					marginBottom: '20px',
				}}
			>
				<Typography
					variant="h6"
					sx={{ textAlign: 'center', textDecoration: 'underline' }}
				>
					Total Potential Revenue
				</Typography>
				<Typography
					variant="h6"
					sx={{ fontWeight: 'bold', textAlign: 'center' }}
				>
					{formatMerchantCurrency(aggregations.possibleRevenue)}
				</Typography>
			</Box>

			<Box
				sx={{
					position: 'relative',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
				}}
			>
				{processingProgress != null ? (
					<Box
						sx={{
							display: 'flex',
						}}
					>
						<Box sx={{ position: 'relative' }}>
							<Box
								sx={{
									top: 0,
									left: 0,
									bottom: 0,
									right: 0,
									position: 'absolute',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
								>
									{processingProgress + failedOffersProgress < 1 ? (
										`${parsePercents(
											processingProgress + failedOffersProgress,
											0,
										)}%`
									) : (
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												marginTop: -1,
											}}
										>
											<CheckCircle color="primary" />
											<Typography
												variant="caption"
												component="div"
												color="text.secondary"
											>
												Complete
											</Typography>
										</Box>
									)}
								</Typography>
							</Box>
							<CircularProgress
								sx={{ position: 'absolute', zIndex: 1 }}
								size={88}
								variant="determinate"
								value={processingProgress * 100}
							/>
							<CircularProgress
								sx={{ position: 'relative', zIndex: -1 }}
								size={88}
								variant="determinate"
								color="error"
								value={
									(processingProgress + failedOffersProgress) * 100
								}
							/>
						</Box>
					</Box>
				) : (
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						flexDirection="column"
					>
						<FormControlLabel
							label={
								<Typography sx={{ fontSize: 14 }}>
									I certify that I reviewed all selected offers and
									agree to the customer&#39;s offered price.
								</Typography>
							}
							control={
								<Checkbox
									required
									checked={agreed}
									onChange={(e) => setAgreed(e.target.checked)}
								/>
							}
						/>
						<FormControlLabel
							label={
								<Typography sx={{ fontSize: 14 }}>
									Recreate campaign on complete
								</Typography>
							}
							control={
								<Checkbox
									checked={recreate}
									onChange={(e) => setRecreate(e.target.checked)}
								/>
							}
						/>
						<Button
							type="submit"
							loading={loading}
							disabled={!agreed}
							onClick={handleSubmit}
						>
							EXECUTE CAMPAIGN
						</Button>
					</Box>
				)}

				{processingProgress === 1 && (
					<Typography>
						Campaign processing has been completed
					</Typography>
				)}

				{(processingError || error) && (
					<Typography color="error">
						{processingError || error.message}
					</Typography>
				)}
			</Box>
		</Box>
	);
}

FinalizeAction.propTypes = {
	campaign: PropTypes.shape({
		id: PropTypes.string.isRequired,
	}).isRequired,
	aggregations: OffersAggregations.isRequired,
	onSuccess: PropTypes.func.isRequired,
};

FinalizeAction.defaultProps = {};

export default FinalizeAction;
