import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import constants from '@app/constants';
import useAuth from '@app/hooks/useAuth';

const bulletStyles = {
	fontSize: 12,
	color: constants.colors.darkGrey,
};

const startupBullets = [
	'Select and Schedule products for Name Your Price',
	'Manually accept and counter offers',
	'View analytics and insights from your offers',
	'Email support',
	'Sell products on the Bazo Marketplace',
];

const otherBullets = ['Everything in previous tier and…'];

function SubscriptionTileContent({
	subscription,
	isSelected,
	billingInterval,
}) {
	const { formatMerchantCurrency } = useAuth();

	const renderBullets = React.useCallback(
		(bullets) =>
			bullets.map((bullet) => (
				<Typography sx={bulletStyles} key={bullet}>
					{bullet}
				</Typography>
			)),
		[],
	);

	const priceText = React.useMemo(() => {
		if (subscription.billing_interval_amount) {
			const inverval = billingInterval.replace('ly', '');
			return `${formatMerchantCurrency(
				subscription.billing_interval_amount,
				0,
				'USD',
			)}/${inverval}`;
		}

		return 'Free to install';
	}, [
		subscription.billing_interval_amount,
		formatMerchantCurrency,
		billingInterval,
	]);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 1.2,
				mb: 3,
			}}
		>
			<Typography sx={{ color: constants.colors.darkGrey }}>
				{subscription.title.toUpperCase()}
				{billingInterval
					? ` (${billingInterval.toUpperCase()})`
					: null}
			</Typography>
			<Typography
				sx={{
					fontSize: 26,
					mt: -0.5,
					mb: 0,
				}}
				color={isSelected && 'primary.main'}
				gutterBottom
			>
				{priceText}
			</Typography>
			<Typography sx={bulletStyles}>
				{`${parseFloat(
					subscription.percent_fee || 0,
				)}% commission on accepted offers`}
			</Typography>
			<Divider />
			{renderBullets(
				subscription.billing_interval_amount
					? otherBullets
					: startupBullets,
			)}
			{subscription.dedicated_account_manager && (
				<Typography sx={bulletStyles}>
					Dedicated account manager
				</Typography>
			)}
			{subscription.nyop_automation && (
				<>
					<Typography sx={bulletStyles}>
						Automated accept and counters
					</Typography>
					<Typography sx={bulletStyles}>
						Algorithmic selling
					</Typography>
				</>
			)}
		</Box>
	);
}

SubscriptionTileContent.propTypes = {
	subscription: PropTypes.shape({
		title: PropTypes.string,
		billing_interval_amount: PropTypes.string,
		percent_fee: PropTypes.string,
		marketplace_percent_fee: PropTypes.string,
		nyop_automation: PropTypes.bool,
		dedicated_account_manager: PropTypes.bool,
	}).isRequired,
	isSelected: PropTypes.bool.isRequired,
	billingInterval: PropTypes.string.isRequired,
};

export default SubscriptionTileContent;
