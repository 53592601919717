import * as React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import OutlineBox from '@app/components/shared/ui/OutlineBox';

import ProductThumbnail from '@app/components/shared/ui/ProductThumbnail';
import { parsePercents } from '@app/lib/parse';
import { localizeCurrency } from '@app/helpers/localization.helpers';
import { formatReadableDate } from '@app/helpers/dateHelpers';

import {
	MerchantType,
	OfferType,
	OrderType,
	VariantType,
} from '../../../propTypes/types';
import Button from '@app/components/shared/button/Button';

function Offer({ order, offer, variants, merchant, token }) {
	const { mutate, data, isLoading, error } = useMutation({
		mutationFn: async () =>
			axios.post(
				`/api/customers/offers/${offer.id}/accept-counter`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			),
	});

	const canceled = offer.canceled_at;
	const accepted =
		!canceled && (data || offer.counter_offer_accepted_at);
	const pending = !canceled && !accepted;

	let canceledMessage = 'Oops! Your offer has been canceled.';
	if (canceled) {
		if (offer.cancellation_source === 'merchant') {
			canceledMessage = 'Oops! The merchant canceled your offer';
		} else if (offer.cancellation_source === 'system') {
			canceledMessage =
				'Uh-oh! Your offer was canceled by the system.';
		} else if (offer.cancellation_source === 'customer') {
			canceledMessage = 'Oh no! You canceled your offer';
		}
	}

	const variant = variants
		.filter((v) => v.id === offer.variant_id)
		.pop();

	const msrp = parseFloat(offer.msrp);
	const offerAmount = parseFloat(offer.offer_amount);
	const counterOfferAmount =
		offer.counter_offer_amount &&
		parseFloat(offer.counter_offer_amount);

	const effectiveDiscount = parsePercents(
		(msrp - counterOfferAmount) / msrp,
	);

	return (
		<Box>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Box sx={{ display: 'block' }}>
					<Box sx={{ display: 'flex', gap: '4px' }}>
						<ProductThumbnail
							variant={variant}
							size="56px"
							sx={{
								objectFit: 'contain',
								border: 0,
								height: 'fit-content',
							}}
						/>
						<Box>
							<Typography fontSize={14} fontWeight="bold">
								{variant.displayName}
							</Typography>
							<Typography fontSize={14}>
								Price:{' '}
								{localizeCurrency({
									customerLocale: order?.customer_locale,
									currencyCode:
										order?.subtotal_price_set?.presentment_money
											?.currency_code,
									amount: msrp,
								})}
							</Typography>
							<Typography fontSize={14}>
								Your offer:{' '}
								{localizeCurrency({
									customerLocale: order?.customer_locale,
									currencyCode:
										order?.subtotal_price_set?.presentment_money
											?.currency_code,
									amount: offerAmount,
								})}
							</Typography>
						</Box>
					</Box>
				</Box>

				<OutlineBox
					sx={{
						backgroundColor: '#f5fad3',
						display: 'block',
						width: '50%',
						padding: 1,
						height: 'fit-content',
					}}
				>
					{canceled && (
						<Typography fontSize={14}>{canceledMessage}</Typography>
					)}
					{accepted && (
						<Typography fontSize={14}>
							Awesome! We’ll send you final details by{' '}
							{formatReadableDate(offer.end_date)} via email
						</Typography>
					)}
					{pending && (
						<>
							<Typography fontSize={14}>
								{merchant.name} is offering you{' '}
								{localizeCurrency({
									customerLocale: order?.customer_locale,
									currencyCode:
										order?.subtotal_price_set?.presentment_money
											?.currency_code,
									amount: counterOfferAmount,
								})}{' '}
								for this item
							</Typography>
							<Box
								sx={{
									display: 'flex',
									gap: '4px',
									width: '100%',
									justifyContent: 'space-between',
								}}
							>
								<Typography fontSize={16}>
									<Box component="span" fontWeight="bold">
										%{effectiveDiscount}
									</Box>{' '}
									off!
								</Typography>

								<Button
									color="secondary"
									onClick={mutate}
									loading={isLoading}
									sx={{
										display: 'block',
										float: 'right',
									}}
								>
									Accept
								</Button>
							</Box>
						</>
					)}
					{error && (
						<Typography color="error" fontSize={12}>
							{error.message}
						</Typography>
					)}
				</OutlineBox>
			</Box>
		</Box>
	);
}

function CounterOffers({ order, offers, variants, merchant, token }) {
	if (offers.length === 0) {
		return null;
	}

	return (
		<OutlineBox sx={{ display: 'block' }}>
			<Box>
				<Typography
					variant="subtitle1"
					sx={{
						fontWeight: 'bold',
					}}
				>
					{merchant.name} has responded to your offer
					{offers.length > 1 && 's'}
				</Typography>
			</Box>
			<Box>
				{offers.map((offer) => (
					<React.Fragment key={offer.id}>
						<Divider variant="fullWidth" sx={{ mb: 1 }} />
						<Offer
							order={order}
							offer={offer}
							variants={variants}
							token={token}
							merchant={merchant}
							key={offer.id}
						/>
					</React.Fragment>
				))}
			</Box>
		</OutlineBox>
	);
}

CounterOffers.propTypes = {
	order: OrderType.isRequired,
	offers: PropTypes.arrayOf(OfferType).isRequired,
	variants: PropTypes.arrayOf(VariantType).isRequired,
	merchant: MerchantType.isRequired,
	token: PropTypes.string.isRequired,
};

Offer.propTypes = {
	order: OrderType.isRequired,
	offer: OfferType.isRequired,
	variants: PropTypes.arrayOf(VariantType).isRequired,
	merchant: MerchantType.isRequired,
	token: PropTypes.string.isRequired,
};
export default CounterOffers;
