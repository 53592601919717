import React from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { useIntegrations, useSegments } from '@app/hooks/useMerchant';
import {
	CampaignAdvancedSettingsType,
	CustomerSegmentationType,
} from '@app/propTypes/campaign';
import constants from '@app/constants';

const KLAVIYO_INTEGRATION = 'klaviyo';

function SelectSegmentsBlock({
	integration,
	onChange,
	defaultSetting,
}) {
	const [selectedSegments, setSelectedSegments] = React.useState(
		defaultSetting?.segments || [],
	);

	const { data: segments, isLoading: segmentsLoading } = useSegments({
		integration,
	});

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;

		setSelectedSegments(value);

		if (value.length !== 0) {
			onChange({
				segments: value,
				integration: KLAVIYO_INTEGRATION,
			});
		} else {
			onChange(null);
		}
	};

	return (
		<Select
			sx={{ maxWidth: '400px' }}
			multiple
			displayEmpty
			value={selectedSegments}
			onChange={handleChange}
			input={<OutlinedInput />}
			renderValue={(selected) => {
				if (segmentsLoading) {
					return <em>Loading...</em>;
				}
				if (segments?.data.length === 0) {
					return <em>No segments</em>;
				}
				if (selectedSegments.length === 0) {
					return <em>Select segments</em>;
				}
				return segments.data
					.filter((s) => selected.includes(s.id))
					.map((s) => s.name)
					.join(', ');
			}}
			inputProps={{ 'aria-label': 'Without label' }}
		>
			{!segmentsLoading &&
				segments?.data.map((segment) => (
					<MenuItem key={segment.id} value={segment.id}>
						{segment.name}
					</MenuItem>
				))}
		</Select>
	);
}

SelectSegmentsBlock.propTypes = {
	integration: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	defaultSetting: CustomerSegmentationType,
};

SelectSegmentsBlock.defaultProps = {
	defaultSetting: null,
};

function AdvancedSettingsBlock({ onChange, defaultSettings }) {
	const [open, setOpen] = React.useState(false);
	const [settings, setSettings] = React.useState(defaultSettings);

	const { data: integrations, isLoading: integrationsLoading } =
		useIntegrations();

	const klaviyoIntegration = !integrationsLoading
		? integrations.data.find(
				({ integration }) => integration === KLAVIYO_INTEGRATION,
		  )
		: null;

	const handleSegmentationVisibilityChange =
		(integration) => (segments) => {
			let newSettings;
			if (!settings?.customerSegmentationVisibility) {
				newSettings = { ...settings };

				if (segments) {
					newSettings.customerSegmentationVisibility = [segments];
				} else {
					newSettings.customerSegmentationVisibility = null;
				}
			} else {
				const customerSegmentationSetting =
					settings?.customerSegmentationVisibility.filter(
						(s) => s.integration !== integration,
					);
				newSettings = {
					...settings,
					customerSegmentationVisibility: customerSegmentationSetting,
				};
				if (segments) {
					newSettings.customerSegmentationVisibility.push(segments);
				}
			}

			setSettings(newSettings);
			onChange(newSettings);
		};

	return (
		<Box sx={{ mt: 1, mb: 1 }}>
			<Box
				sx={{
					':hover': { cursor: 'pointer' },
					fontSize: '16px',
					width: '100%',
					color: 'button.primary.main',
					borderBottomWidth: '1px',
					borderBottomColor: constants.colors.defaultBorder,
					borderBottomStyle: 'solid',
				}}
				onClick={() => setOpen(!open)}
			>
				<Typography>Advanced</Typography>
			</Box>
			<Box sx={{ display: open ? 'block' : 'none', mt: 1 }}>
				<Box>
					<Typography
						sx={{
							width: 'fit-content',
							borderBottomWidth: '1px',
							borderBottomColor: constants.colors.defaultBorder,
							borderBottomStyle: 'solid',
						}}
					>
						Segmentations
					</Typography>

					{!klaviyoIntegration && (
						<Typography variant="caption">
							You do not have Klaviyo integration configured to use
							segmentation
						</Typography>
					)}

					{klaviyoIntegration && (
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Typography variant="caption">
								NYOP button will only be visible for customers in the
								selected Klaviyo segments
							</Typography>
							<SelectSegmentsBlock
								integration={KLAVIYO_INTEGRATION}
								onChange={handleSegmentationVisibilityChange(
									KLAVIYO_INTEGRATION,
								)}
								defaultSetting={settings?.customerSegmentationVisibility?.find(
									(s) => s.integration === KLAVIYO_INTEGRATION,
								)}
							/>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
}

AdvancedSettingsBlock.propTypes = {
	onChange: PropTypes.func.isRequired,
	defaultSettings: CampaignAdvancedSettingsType,
};

AdvancedSettingsBlock.defaultProps = {
	defaultSettings: null,
};

export default AdvancedSettingsBlock;
