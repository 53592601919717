import * as React from 'react';
import PropTypes from 'prop-types';
import InboxIcon from '@mui/icons-material/Inbox';
import Badge from '@mui/material/Badge';
import { useNyopInboxCount } from '@app/hooks/useNyopOffers';
import useAuth from '@app/hooks/useAuth';

function NyopOffersInboxIcon({ color }) {
	const { merchant } = useAuth();
	const { data } = useNyopInboxCount({
		configOpts: { enabled: !!merchant, refetchInterval: 60000 },
	});
	return (
		<Badge
			badgeContent={data?.count}
			color="primary"
			sx={{
				'& .MuiBadge-badge': {
					fontSize: 10,
					height: 15,
					minWidth: 10,
				},
			}}
		>
			<InboxIcon color={color} />
		</Badge>
	);
}

NyopOffersInboxIcon.propTypes = {
	color: PropTypes.string,
};

NyopOffersInboxIcon.defaultProps = {
	color: 'default',
};

export default NyopOffersInboxIcon;
