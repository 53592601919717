import axios from 'axios';
import Cookies from 'js-cookie';
import { COOKIE, RESPONSE_CODES } from '@app/api/constants';
import globalRouter from '@app/lib/globalRouter';

const instance = axios.create({
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
	},
});

instance.interceptors.response.use(
	(response) => {
		const redirectUrl = response?.data?.redirectUrl;
		if (redirectUrl) {
			globalRouter.navigate(redirectUrl);
		}
		return response;
	},
	(error) => {
		if (error?.response?.data?.message) {
			// eslint-disable-next-line no-param-reassign
			error.message = error.response.data.message;
		}

		if (
			error.response.status === 401 &&
			window.location.pathname !== '/login'
		) {
			// If we don't remove Cookie, there will be another request to /sessions which doesn't make sense as we already know
			// that user is unauthorized
			Cookies.remove(COOKIE);
			globalRouter.navigate('/login');
		} else if (
			error.response.status === 400 &&
			error?.response?.data?.code ===
				RESPONSE_CODES.SUBSCRIPTION_REQUIRED
		) {
			globalRouter.navigate('/settings/subscriptions');
		}
		return Promise.reject(error);
	},
);

export default instance;
