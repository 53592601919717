import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import WidgetComponent from './widgetComponent';
import useAuth from '@app/hooks/useAuth';
import { parsePercents } from '@app/lib/parse';

function WidgetsList({ widgetsData }) {
	const { formatMerchantCurrency, formatMerchantNumber } = useAuth();

	return (
		<Box
			sx={{
				width: '100%',
				mb: 3,
				mt: 3,
				display: 'flex',
				flexDirection: {
					xs: 'column',
					md: 'row',
				},
				justifyContent: 'space-between',
				gap: 2,
			}}
		>
			<WidgetComponent
				title="Total campaigns"
				value={formatMerchantNumber({ value: widgetsData?.allCount })}
			/>
			<WidgetComponent
				title="Active Campaigns"
				value={formatMerchantNumber({
					value: widgetsData?.activeCount,
				})}
			/>
			<WidgetComponent
				title="Achieved Revenue"
				value={formatMerchantCurrency(widgetsData?.achievedRevenue)}
			/>
			<WidgetComponent
				title="Potential Revenue from Active Campaigns"
				value={formatMerchantCurrency(widgetsData?.potentialRevenue)}
			/>
			<WidgetComponent
				title="Average Discount"
				value={`${parsePercents(widgetsData?.averageDiscount)}%`}
			/>
			<WidgetComponent
				title="Initial Average Discount"
				value={`${parsePercents(
					widgetsData?.initialAverageDiscount,
				)}%`}
			/>
		</Box>
	);
}

WidgetsList.propTypes = {
	widgetsData: PropTypes.shape({
		allCount: PropTypes.number,
		activeCount: PropTypes.number,
		achievedRevenue: PropTypes.string,
		potentialRevenue: PropTypes.string,
		averageDiscount: PropTypes.string,
		initialAverageDiscount: PropTypes.string,
	}).isRequired,
};

export default WidgetsList;
