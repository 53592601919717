import React from 'react';
import PropTypes from 'prop-types';
import { useMarketplaceProductsContext } from '@app/pages/marketplace/supplier/context/useMarketProductsContext';
import InputField from '@app/pages/marketplace/supplier/table/components/InputField';

function ProductThresholdInput({ productId }) {
	const { updateProductThreshold, getProductConfig } =
		useMarketplaceProductsContext();

	const config = getProductConfig({ productId });

	return (
		<InputField
			value={config.threshold}
			endAdornment="%"
			handleChange={(newValue) => {
				updateProductThreshold({ productId, threshold: newValue });
			}}
		/>
	);
}

ProductThresholdInput.propTypes = {
	productId: PropTypes.string.isRequired,
};

export default ProductThresholdInput;
