import * as React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

function TablePaginationActions({
	count,
	page,
	rowsPerPage,
	onPageChange,
	showFirstAndLast,
	onNext,
	onPrevious,
	hasNext,
	hasPrevious,
	loading,
}) {
	const theme = useTheme();

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPrevious();
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onNext();
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(
			event,
			Math.max(0, Math.ceil(count / rowsPerPage) - 1),
		);
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			{showFirstAndLast && (
				<IconButton
					onClick={handleFirstPageButtonClick}
					disabled={loading || page === 0}
					aria-label="first page"
				>
					{theme.direction === 'rtl' ? (
						<LastPageIcon />
					) : (
						<FirstPageIcon />
					)}
				</IconButton>
			)}
			<IconButton
				onClick={handleBackButtonClick}
				disabled={loading || page === 0 || !hasPrevious}
				aria-label="previous page"
			>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={loading || !hasNext}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			{showFirstAndLast && (
				<IconButton
					onClick={handleLastPageButtonClick}
					disabled={
						loading || page >= Math.ceil(count / rowsPerPage) - 1
					}
					aria-label="last page"
				>
					{theme.direction === 'rtl' ? (
						<FirstPageIcon />
					) : (
						<LastPageIcon />
					)}
				</IconButton>
			)}
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	onNext: PropTypes.func.isRequired,
	onPrevious: PropTypes.func.isRequired,
	hasNext: PropTypes.bool.isRequired,
	hasPrevious: PropTypes.bool.isRequired,
	showFirstAndLast: PropTypes.bool,
	loading: PropTypes.bool,
};

TablePaginationActions.defaultProps = {
	showFirstAndLast: false,
	loading: false,
};

export default TablePaginationActions;
