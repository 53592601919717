import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import VariantsSearchInput from '@app/components/shared/variantsSearch/VariantsSearchInput';
import BackSaveButtonContainer from '../backSaveButtonContainer';
import EditByProductTable from './table';
import { getCounterValues } from '@app/pages/createCampaign/helpers';
import CounterValueWarning from './table/components/counterValueWarning';
import { useInventoryReviewContext } from '@app/hooks/useInventoryReviewContext';

function EditByProductModal({
	onClose,
	defaultThresholdDiscount,
	fixedCostBuffer,
	areCounterValuesValid,
}) {
	const [selectedVariants, setSelectedVariants] =
		useInventoryReviewContext();

	const [searchTermDisplay, setSearchTermDisplay] =
		React.useState('');

	const [counterValues, setCounterValues] = React.useState(
		getCounterValues(selectedVariants),
	);

	const [displayVariants, setDisplayVariants] =
		React.useState(selectedVariants);

	const handleSearch = React.useCallback((event) => {
		const { value } = event.target;
		setSearchTermDisplay(value);
	}, []);

	const handleClear = React.useCallback(() => {
		setSearchTermDisplay('');
	}, []);

	const handleSaveChanges = useCallback(() => {
		setSelectedVariants((prevVariants) =>
			prevVariants.map((variant) => {
				const counterValue = counterValues[variant.id];
				const productCounterValue = counterValues[variant.product_id];
				return {
					...variant,
					counterValue: counterValue ?? productCounterValue,
					productCounterValue,
				};
			}),
		);
		onClose();
	}, [setSelectedVariants, counterValues, onClose]);

	React.useEffect(() => {
		if (searchTermDisplay.length === 0) {
			setDisplayVariants(selectedVariants);
			return;
		}

		const newVariants = selectedVariants.filter((variant) =>
			variant.full_title
				.toLocaleLowerCase()
				.includes(searchTermDisplay.toLocaleLowerCase()),
		);
		setDisplayVariants(newVariants);
		// eslint-disable-next-line
	}, [searchTermDisplay]);

	const Table = React.useMemo(
		() => (
			<EditByProductTable
				defaultThreshold={defaultThresholdDiscount}
				variants={displayVariants}
				setThresholds={setCounterValues}
				thresholds={counterValues}
				fixedCostBuffer={fixedCostBuffer}
			/>
		),
		// eslint-disable-next-line
		[displayVariants],
	);

	return (
		<Box
			sx={{
				display: 'flex',
				height: '100%',
				width: '100%',
				flexDirection: 'column',
				overflow: 'scroll',
				justifyContent: 'space-between',
			}}
		>
			<Box>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						marginTop: 1,
					}}
				>
					<VariantsSearchInput
						searchTermDisplay={searchTermDisplay}
						handleSearch={handleSearch}
						handleClear={handleClear}
						placeholder="Filter list"
					/>
				</Box>
				{!areCounterValuesValid && (
					<CounterValueWarning sx={{ mt: 2, mb: 1 }} />
				)}
				{Table}
				{!displayVariants?.length && (
					<Typography
						sx={{
							width: '100%',
							textAlign: 'center',
							padding: '16px',
						}}
					>
						Sorry, no matching records found
					</Typography>
				)}
			</Box>
			<BackSaveButtonContainer
				onClose={onClose}
				handleSaveChanges={handleSaveChanges}
			/>
		</Box>
	);
}

EditByProductModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	defaultThresholdDiscount: PropTypes.string.isRequired,
	areCounterValuesValid: PropTypes.bool.isRequired,
	fixedCostBuffer: PropTypes.number,
};

EditByProductModal.defaultProps = {
	fixedCostBuffer: 0,
};

export default EditByProductModal;
