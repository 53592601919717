import * as React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const propTypes = {
	id: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	helperText: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	margin: PropTypes.string,
	fullWidth: PropTypes.bool,
	autoFocus: PropTypes.bool,
	// https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
	autoComplete: PropTypes.string.isRequired,
	inputProps: PropTypes.shape({}),
	sx: PropTypes.shape({}),
	required: PropTypes.bool,
	disabled: PropTypes.bool,
};

const defaultProps = {
	id: 'password-input',
	label: 'Password',
	helperText: null,
	placeholder: '••••••••••',
	margin: 'normal',
	fullWidth: true,
	autoFocus: false,
	inputProps: {},
	sx: {},
	required: true,
	disabled: false,
};

function PasswordInput({
	id,
	name,
	label,
	helperText,
	placeholder,
	onChange,
	value,
	margin,
	fullWidth,
	autoFocus,
	autoComplete,
	inputProps,
	sx,
	required,
	disabled,
}) {
	const [showPassword, setShowPassword] = React.useState(false);
	const handleClickShowPassword = () =>
		setShowPassword(!showPassword);
	const handleMouseDownPassword = () =>
		setShowPassword(!showPassword);

	return (
		<TextField
			id={id}
			name={name}
			type={showPassword ? 'text' : 'password'}
			label={label}
			helperText={helperText}
			placeholder={placeholder}
			onChange={onChange}
			value={value}
			margin={margin}
			sx={sx}
			fullWidth={fullWidth}
			autoFocus={autoFocus}
			autoComplete={autoComplete}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<LockIcon />
					</InputAdornment>
				),
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							aria-label="Toggle password visibility"
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
						>
							{showPassword ? <Visibility /> : <VisibilityOff />}
						</IconButton>
					</InputAdornment>
				),
				...inputProps,
				/**
				 * Pass inputProps={{ autocomplete: off }} if you don't want autocomplete
				 * https://github.com/mui/material-ui/issues/2653#issuecomment-766579431
				 */
			}}
			required={required}
			disabled={disabled}
		/>
	);
}

PasswordInput.propTypes = propTypes;
PasswordInput.defaultProps = defaultProps;

export default PasswordInput;
