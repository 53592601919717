import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { checkNyopDates } from '@app/api/nyopCampaign';
import {
	addDaysToDate,
	getNyopPresetDate,
} from '@app/helpers/dateHelpers';
import InfoBox from '@app/components/shared/ui/InfoBox';
import { validateNyopDates } from '../../../components/shared/form/SellingPlanGroupForm/helpers';
import ConversationalInputWrapper from '@app/components/shared/inputs/ConversationalTextInput';

const inputWidthStyle = {
	maxWidth: '438.27px',
	width: '100%',
};

function StartEndDatesPicker({
	id,
	onChange,
	defaultStartDate,
	defaultEndDate,
}) {
	const [startDate, setStartDate] = React.useState(
		defaultStartDate || getNyopPresetDate(),
	);
	const [endDate, setEndDate] = React.useState(
		defaultEndDate || null,
	);

	const [noEndDateChecked, setNoEndDateChecked] = React.useState(
		!defaultEndDate,
	);

	const [datesValid, setDatesValid] = React.useState({
		startDate: {
			isValid: true,
			errorMessage: '',
		},
		endDate: {
			isValid: true,
			errorMessage: '',
		},
	});
	const [datesOverlapped, setDatesOverlapped] = React.useState(false);

	React.useEffect(() => {
		const actualEndDate = noEndDateChecked ? null : endDate;

		if (datesValid.startDate.isValid && datesValid.endDate.isValid) {
			checkNyopDates({
				id,
				startDate: new Date(startDate).toISOString(),
				endDate: new Date(endDate).toISOString(),
			}).then(({ count }) => setDatesOverlapped(count > 0));

			onChange(startDate, actualEndDate);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [datesValid]);

	React.useEffect(() => {
		const actualEndDate = noEndDateChecked ? null : endDate;

		if (!noEndDateChecked && !endDate) {
			setEndDate(addDaysToDate(startDate, 5));
			return;
		}

		const dateValidation = validateNyopDates(
			startDate,
			actualEndDate,
		);
		setDatesValid(dateValidation);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [noEndDateChecked, startDate, endDate]);

	return (
		<>
			<Divider sx={{ mt: 2, mb: 2 }} />
			<DateTimePicker
				required
				label="Start date"
				name="startDate"
				value={startDate}
				onChange={setStartDate}
				renderInput={(params) => (
					<ConversationalInputWrapper prompt="When would you like this Campaign to start?">
						<TextField
							{...params}
							margin="normal"
							helperText={
								datesValid.startDate.errorMessage ||
								"Products will feature the 'Name Your Price' option from this date."
							}
							display="block"
							error={!datesValid.startDate.isValid}
							sx={inputWidthStyle}
						/>
					</ConversationalInputWrapper>
				)}
			/>
			<Divider sx={{ mb: 2 }} />

			<DateTimePicker
				label="End date"
				name="endDate"
				value={noEndDateChecked ? defaultEndDate : endDate}
				minDateTime={startDate}
				disabled={noEndDateChecked}
				renderInput={(params) => (
					<ConversationalInputWrapper
						prompt="When would you like this Campaign to end?"
						sx={{ mb: 0 }}
					>
						<TextField
							{...params}
							margin="normal"
							helperText={
								datesValid.endDate.errorMessage ||
								"'Name Your Price' will be disabled, and no further offers can be made after this date."
							}
							display="block"
							error={!datesValid.endDate.isValid}
							sx={inputWidthStyle}
						/>
					</ConversationalInputWrapper>
				)}
				onChange={setEndDate}
			/>

			<FormControlLabel
				label={
					<Typography sx={{ fontSize: 14 }}>No end date</Typography>
				}
				control={
					<Checkbox
						checked={noEndDateChecked}
						onChange={(e) => setNoEndDateChecked(e.target.checked)}
					/>
				}
			/>

			{datesOverlapped && (
				<InfoBox type="warning" sx={{ mt: 1 }}>
					<Typography>
						These dates overlap with another campaign.
					</Typography>
				</InfoBox>
			)}
			<Divider sx={{ mt: 2, mb: 2 }} />
		</>
	);
}

StartEndDatesPicker.propTypes = {
	id: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	defaultStartDate: PropTypes.instanceOf(Date),
	defaultEndDate: PropTypes.instanceOf(Date),
};

StartEndDatesPicker.defaultProps = {
	id: null,
	defaultStartDate: null,
	defaultEndDate: null,
};

export default StartEndDatesPicker;
