import * as React from 'react';

import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import TitleWithImage from '@app/components/muiTable/elements/TitleWithImage';
import { getProductVariantLink } from '@app/lib/shopify';
import MoneyRenderer from '@app/components/muiTable/offers/MoneyRenderer';
import StatusRenderer from '@app/components/muiTable/offers/StatusRenderer';
import offerService from '@app/services/offer.service';
import useAuth from '@app/hooks/useAuth';
import { OfferType } from '@app/propTypes/types';
import {
	useNyopInboxOrder,
	useNyopOffersRespond,
} from '@app/hooks/useNyopOffers';
import constants from '@app/constants/index';
import Button from '../shared/button/Button';
import { getUnderMerchantReviewOffers } from '@app/pages/customerPortalNyop/helpers';
import InfoPopover from '@app/components/shared/ui/InfoPopoverIcon';

function OrderOffersProcessingRow({ offer, onOfferResponseChange }) {
	const { formatMerchantNumber, getCurrencySymbol } = useAuth();

	const [counterOfferAmount, setCounterOfferAmount] = React.useState(
		offer.shop_currency_counter_offer_amount ||
			offer.counter_offer_amount ||
			'',
	);

	const [counterOfferAmountError, setCounterOfferAmountError] =
		React.useState(null);

	const [offerAccepted, setOfferAccepted] = React.useState(
		offerService.isAccepted(offer),
	);

	const onAcceptedChange = (event, accepted) => {
		setOfferAccepted(accepted);
		if (accepted) {
			onOfferResponseChange(offer, {
				action: 'accept',
				correct: true,
			});
		} else {
			onOfferResponseChange(offer, {});
		}
	};

	const onCounterOfferAmountChange = (event) => {
		const strValue = event.target.value;
		let correct = true;
		if (!strValue) {
			setCounterOfferAmount('');
			return;
		}

		let numericValue = parseInt(strValue, 10);
		if (Number.isNaN(numericValue)) {
			return;
		}

		if (numericValue > offer.variant.price) {
			numericValue = Math.floor(offer.variant.price);
		} else if (numericValue <= 0) {
			numericValue = 1;
		}

		if (numericValue <= offer.shop_currency_offer_amount) {
			setCounterOfferAmountError(
				'The counter offer amount cannot be smaller than or equal to the original offer amount',
			);
			correct = false;
		} else {
			setCounterOfferAmountError(null);
		}

		setCounterOfferAmount(numericValue.toString());
		onOfferResponseChange(offer, {
			action: 'counter',
			counterOfferAmount: numericValue,
			correct,
		});
	};

	const orderIsNotPending = !offerService.isPending(offer);

	let acceptCheckboxTooltip = '';
	if (orderIsNotPending) {
		acceptCheckboxTooltip = `Offer cannot be marked as accepted in status ${offerService.getOfferStatus(
			offer,
		)}`;
	} else if (counterOfferAmount) {
		acceptCheckboxTooltip = 'Counter offer amount is already entered';
	}

	let counterOfferFieldTooltip = '';
	if (orderIsNotPending) {
		counterOfferFieldTooltip = `Offer cannot be countered in status ${offerService.getOfferStatus(
			offer,
		)}`;
	} else if (offerAccepted) {
		counterOfferFieldTooltip = 'Offer is already marked as accepted';
	} else if (counterOfferAmountError) {
		counterOfferFieldTooltip = counterOfferAmountError;
	}

	return (
		<TableRow key={offer.id}>
			<TableCell sx={{ pb: '4px', pt: '4px', pl: '24px' }}>
				<TitleWithImage
					title={offer.variant.title}
					imageUrl={offer.variant.image_url}
					subtitle={offer.variant.sku}
					url={getProductVariantLink(
						offer.shop,
						offer.variant.product_id,
						offer.variant.id,
					)}
				/>
			</TableCell>
			<TableCell sx={{ pb: '4px', pt: '4px' }}>
				<MoneyRenderer
					shopCurrencyAmount={+offer.shop_currency_offer_amount}
					customerCurrencyAmount={+offer.offer_amount}
					customerCurrency={offer.currency}
					shopCurrency={offer.shop_currency}
				/>
			</TableCell>
			<TableCell sx={{ pb: '4px', pt: '4px' }}>
				<MoneyRenderer
					shopCurrencyAmount={
						+(
							parseFloat(offer.shop_currency_counter_offer_amount) ||
							parseFloat(offer.shop_currency_offer_amount)
						)
					}
					customerCurrencyAmount={
						parseFloat(offer.counter_offer_amount) ||
						parseFloat(offer.offer_amount)
					}
					customerCurrency={offer.currency}
					shopCurrency={offer.shop_currency}
				/>
			</TableCell>
			<TableCell sx={{ pb: '4px', pt: '4px' }}>
				<MoneyRenderer
					shopCurrencyAmount={
						+(offer.variant.msrp || offer.variant.price)
					}
					customerCurrencyAmount={+offer.msrp}
					customerCurrency={offer.currency}
					shopCurrency={offer.shop_currency}
				/>
			</TableCell>
			<TableCell sx={{ pb: '4px', pt: '4px' }}>
				<Typography color={constants.colors.pending}>
					{formatMerchantNumber({
						value: offer.fulfilled_quantity || offer.quantity,
					})}
				</Typography>
			</TableCell>
			<TableCell sx={{ pb: '4px', pt: '4px' }}>
				<Tooltip title={acceptCheckboxTooltip} arrow>
					<span>
						<Checkbox
							disabled={
								!offerService.isPending(offer) || !!counterOfferAmount
							}
							checked={offerAccepted}
							onChange={onAcceptedChange}
						/>
					</span>
				</Tooltip>
			</TableCell>
			<TableCell>
				<Tooltip title={counterOfferFieldTooltip} arrow>
					<TextField
						id={`counter-offer-amount-${offer.id}`}
						type="number"
						value={counterOfferAmount}
						autoComplete="off"
						disabled={!offerService.isPending(offer) || offerAccepted}
						onChange={onCounterOfferAmountChange}
						error={!!counterOfferAmountError}
						sx={{ width: '200px' }}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Typography>{getCurrencySymbol()}</Typography>
								</InputAdornment>
							),
						}}
					/>
				</Tooltip>
			</TableCell>
			<TableCell>
				<StatusRenderer
					quantity={offer.quantity}
					fulfilledQuantity={offer.fulfilled_quantity}
					status={offerService.getOfferStatus(offer)}
					cancellationReason={offer.cancellation_reason}
				/>
			</TableCell>
		</TableRow>
	);
}

OrderOffersProcessingRow.propTypes = {
	onOfferResponseChange: PropTypes.func.isRequired,
	offer: OfferType.isRequired,
};

function OrderOffersProcessing({
	initialOrderId,
	initialOffers,
	queryKey,
}) {
	const [actions, setActions] = React.useState({});

	const [orderId] = React.useState(initialOrderId);

	const singleOrderQueryKey = [...queryKey, orderId];
	const { data } = useNyopInboxOrder({
		queryKey: singleOrderQueryKey,
		orderId,
	});

	const offers = data?.order?.offers || initialOffers;

	const { mutate, isLoading } = useNyopOffersRespond({ queryKey });

	const handleSubmit = async () => {
		await mutate({ actions });
		setActions({});
	};

	const onOfferResponseChange = (offer, response) => {
		setActions((prevState) => ({
			...prevState,
			[offer.id]: response,
		}));
	};

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
			}}
		>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Product</TableCell>
						<TableCell>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									gap: 1,
								}}
							>
								Original Offer{' '}
								<InfoPopover text="The price first proposed by the customer, displayed in the store's default currency. If the offer was made in another currency, the customer presentment currency is shown in parentheses." />
							</Box>
						</TableCell>
						<TableCell>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									gap: 1,
								}}
							>
								Current Offer{' '}
								<InfoPopover text="This reflects the latest offer amount after any counteroffers. It is shown in the store's default currency. If the offer was made in another currency, the customer presentment currency is shown in parentheses." />
							</Box>
						</TableCell>
						<TableCell>MSRP</TableCell>
						<TableCell>Quantity</TableCell>
						<TableCell>Accept</TableCell>
						<TableCell>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: 0.5,
								}}
							>
								Counter
								<InfoPopover
									popoverVerticalPlacement="bottom"
									popoverHorizontalPlacement="right"
									iconStyles={{ fontSize: 16 }}
									textStyles={{
										fontSize: 14,
										width: '450px',
										maxWidth: '450px',
									}}
								>
									Counter offers are created in your shop&apos;s
									default currency. Bazo automatically converts these
									into the currency your shoppers used, ensuring a
									seamless and local shopping experience.
								</InfoPopover>
							</Box>
						</TableCell>
						<TableCell>Status</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{offers?.map((offer) => (
						<OrderOffersProcessingRow
							offer={offer}
							onOfferResponseChange={onOfferResponseChange}
							key={offer.id}
						/>
					))}
				</TableBody>
			</Table>

			{getUnderMerchantReviewOffers(offers).length !== 0 ? (
				<Button
					sx={{ mt: 2, width: '200px', alignSelf: 'end' }}
					disabled={
						!offers ||
						Object.values(actions).length <
							offers.filter((offer) => offerService.isPending(offer))
								.length ||
						!!Object.values(actions).find((action) => !action.correct)
					}
					onClick={handleSubmit}
					loading={isLoading}
				>
					Submit
				</Button>
			) : null}
		</Box>
	);
}

OrderOffersProcessing.propTypes = {
	initialOrderId: PropTypes.string,
	initialOffers: PropTypes.arrayOf(OfferType),
	queryKey: PropTypes.arrayOf(PropTypes.string).isRequired,
};
OrderOffersProcessing.defaultProps = {
	initialOrderId: '',
	initialOffers: [],
};

export default OrderOffersProcessing;
