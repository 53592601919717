import React from 'react';
import * as _ from 'lodash-es';

import useAuth from './useAuth';
import { useShopifyProducts } from './useProducts';

const SORT_KEY = 'RELEVANCE';

export function useSortedAuxiliaryProducts({ page, queryKeys }) {
	const { merchant } = useAuth();

	const resultsPerPage = 10;

	const [queryVariables, setQueryVariables] = React.useState({
		first: resultsPerPage,
		after: null,
		last: null,
		before: null,
		searchTerm: queryKeys,
		sortKey: SORT_KEY,
	});
	const [searchTermDisplay, setSearchTermDisplay] =
		React.useState('');
	const [displayData, setDisplayData] = React.useState({
		products: { edges: [] },
	});

	const { isLoading, isError, data, isFetching } = useShopifyProducts(
		{
			cacheKey: `${merchant.id}|${queryVariables?.searchTerm}|${page}`,
			queryVariables,
		},
	);

	const handleClear = React.useCallback(() => {
		const resetQueryVars = {
			first: resultsPerPage,
			after: null,
			last: null,
			before: null,
			searchTerm: queryKeys,
			sortKey: SORT_KEY,
		};

		setQueryVariables(resetQueryVars);
		setSearchTermDisplay('');
	}, [resultsPerPage, setQueryVariables, queryKeys]);

	const debouncedChangeHandler = React.useMemo(
		() =>
			_.debounce((userInput) => {
				if (userInput === '') {
					handleClear();
				} else {
					const searchQueryVars = {
						first: resultsPerPage,
						after: null,
						last: null,
						before: null,
						searchTerm: `${queryKeys} AND ${userInput}`,
						sortKey: SORT_KEY,
					};

					setQueryVariables(searchQueryVars);
				}
			}, 400),
		[handleClear, queryKeys],
	);

	const handleSearch = (event) => {
		const { value } = event.target;
		setSearchTermDisplay(value);
		debouncedChangeHandler(value);
	};

	const onPrevious = () => {
		// Simply need to set props for queryVariable for react-query to refetch
		setQueryVariables((prev) => {
			const obj = { ...prev };
			obj.first = null;
			obj.after = null;
			obj.last = resultsPerPage;
			obj.before = displayData?.products.edges[0].cursor;
			return obj;
		});
	};

	const onNext = () => {
		// Simply need to set props for queryVariable for react-query to refetch
		setQueryVariables((prev) => {
			const obj = { ...prev };
			obj.first = resultsPerPage;
			obj.after = displayData?.products.edges.at(-1).cursor;
			obj.last = null;
			obj.before = null;
			return obj;
		});
	};

	const hasPrevious =
		displayData?.products &&
		displayData?.products?.pageInfo?.hasPreviousPage;

	const hasNext =
		displayData?.products &&
		displayData?.products?.pageInfo?.hasNextPage;

	React.useEffect(() => {
		// * Only set query data as display data,
		// * if the initial query is loading or
		// * the query is done.
		if (data?.products?.edges !== undefined) {
			setDisplayData(data);
		}
	}, [data]);

	const { edges: products = [] } = displayData.products;

	return {
		resultsPerPage,
		onPrevious,
		onNext,
		hasPrevious,
		hasNext,
		searchTermDisplay,
		isLoading,
		isFetching,
		isError,
		handleClear,
		handleSearch,
		products,
	};
}
