import * as googleAnalytics from '../lib/googleAnalytics';

/**
 *
 * @param options {{mount: boolean; update: boolean}}
 * @returns {{onRender: onRender}}
 */
function useRenderPerformance(
	options = { mount: true, update: true },
) {
	const onRender = (id, phase, actualDuration) => {
		if (options[phase]) {
			googleAnalytics.GAtiming({
				categoryName: id,
				variableName: phase,
				valueNum: actualDuration,
			});
		}
	};

	return { onRender };
}

export default useRenderPerformance;
