import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import ProductDetails from './ProductDetails';

import { localizeCurrency } from '../../../helpers/localization.helpers';
import { formatReadableDate } from '../../../helpers/dateHelpers';
import { OrderType } from '../../../propTypes/types';

const balanceDueMessage = (order, preorders) => {
	if (order?.refunds.length > 0) {
		return 'Refunded';
	}

	if (order?.financial_status === 'paid') {
		// Should read 0. We can do something else here if needed
		return 'Total Due';
	}

	// Refunded orders are also marked as cancelled
	// Need to do this check second
	if (order?.cancelled_at && order?.cancel_reason) {
		return 'Cancelled';
	}

	const preorderDueDate = preorders.find(
		(pre) => pre.order_id === order.admin_graphql_api_id,
	)?.payment_due_at;

	if (preorderDueDate) {
		return `Total due ${formatReadableDate(preorderDueDate)}`;
	}

	return 'Total Due';
};

function OrderDetails({ order, variants, preorders, sx }) {
	const items = order.line_items.filter((li) => li.variant_id);
	return (
		<Box sx={{ ...sx }}>
			{items &&
				items.map((lineItem, i) => {
					// TODO: this is a little messy.
					// Maybe combine on api side to simplify
					const variant = variants.find((v) =>
						v.id.includes(lineItem.variant_id),
					);
					return (
						<ProductDetails
							key={`customer-portal-img-thumb-${variant.id}-${i}`} // eslint-disable-line
							variant={variant || {}}
							lineItem={lineItem}
						/>
					);
				})}
			<Divider variant="fullWidth" sx={{ mb: 3 }} />
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					mb: 1,
				}}
			>
				<Typography
					color="text.secondary"
					sx={{ fontWeight: 'bold' }}
				>
					Subtotal
				</Typography>
				<Typography sx={{ fontWeight: 'bold' }}>
					{localizeCurrency({
						customerLocale: order?.customer_locale,
						currencyCode:
							order?.subtotal_price_set?.presentment_money
								?.currency_code,
						amount:
							order?.subtotal_price_set?.presentment_money?.amount,
					})}
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					mb: 1,
				}}
			>
				<Typography
					color="text.secondary"
					sx={{ fontWeight: 'bold' }}
				>
					Taxes
				</Typography>
				<Typography sx={{ fontWeight: 'bold' }}>
					{localizeCurrency({
						customerLocale: order?.customer_locale,
						currencyCode:
							order?.total_tax_set?.presentment_money?.currency_code,
						amount: order?.total_tax_set?.presentment_money?.amount,
					})}
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					mb: 1,
				}}
			>
				<Typography
					color="text.secondary"
					sx={{ fontWeight: 'bold' }}
				>
					Shipping
				</Typography>
				<Typography sx={{ fontWeight: 'bold' }}>
					{order?.total_shipping_price_set?.presentment_money
						?.amount === '0.00'
						? 'Free'
						: localizeCurrency({
								customerLocale: order?.customer_locale,
								currencyCode:
									order?.total_shipping_price_set?.presentment_money
										?.currency_code,
								amount:
									order?.total_shipping_price_set?.presentment_money
										?.amount,
						  })}
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Typography sx={{ fontWeight: 'bold' }}>
					Order Total
				</Typography>
				<Typography color="text.secondary">
					{
						order?.current_total_price_set?.presentment_money
							?.currency_code
					}
					{'  '}
					<Typography
						component="span"
						variant="h5"
						color="text.primary"
						sx={{ fontWeight: 'bold' }}
					>
						{localizeCurrency({
							customerLocale: order?.customer_locale,
							currencyCode:
								order?.current_total_price_set?.presentment_money
									?.currency_code,
							amount:
								order?.current_total_price_set?.presentment_money
									?.amount,
						})}
					</Typography>
				</Typography>
			</Box>
			<Divider variant="fullWidth" sx={{ mt: 3, mb: 3 }} />
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Typography sx={{ fontWeight: 'bold' }}>
					{balanceDueMessage(order, preorders)}
				</Typography>
				<Typography color="text.secondary">
					{order?.currency}
					{'  '}
					<Typography
						component="span"
						variant="h5"
						color="text.primary"
						sx={{ fontWeight: 'bold' }}
					>
						{localizeCurrency({
							customerLocale: order?.customer_locale,
							currencyCode:
								order?.payment_terms?.payment_schedules[0]?.currency,
							amount:
								order?.payment_terms?.payment_schedules[0]?.amount,
						})}
					</Typography>
				</Typography>
			</Box>
		</Box>
	);
}

OrderDetails.propTypes = {
	order: OrderType.isRequired,
	preorders: PropTypes.arrayOf(
		PropTypes.shape({
			order_id: PropTypes.string,
		}),
	),
	variants: PropTypes.arrayOf(PropTypes.shape({})),
	sx: PropTypes.shape({}),
};

OrderDetails.defaultProps = {
	variants: [],
	preorders: [],
	sx: {},
};

export default OrderDetails;
