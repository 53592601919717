import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import logo from '../../../assets/logos/bazo_green_white.png';

function BazoLogo({ width, sx }) {
	return (
		<Box
			component="img"
			alt="Bazo logo"
			src={logo}
			sx={{
				width,
				...sx,
			}}
		/>
	);
}

BazoLogo.propTypes = {
	sx: PropTypes.shape({}),
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

BazoLogo.defaultProps = {
	sx: {},
	width: '100px',
};

export default BazoLogo;
