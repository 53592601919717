import * as React from 'react';
import PropTypes from 'prop-types';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const propTypes = {
	headerText: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	IconComponent: PropTypes.elementType,
};

const defaultProps = {
	IconComponent: LockOutlinedIcon,
	children: null,
};

function LoginSignUpContainer({
	headerText,
	IconComponent,
	children,
}) {
	return (
		<Container component="main" maxWidth="sm">
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
					<IconComponent />
				</Avatar>
				<Typography component="h1" variant="h5" mb={2}>
					{headerText}
				</Typography>
				<Box width="100%">{children}</Box>
			</Box>
		</Container>
	);
}

LoginSignUpContainer.propTypes = propTypes;
LoginSignUpContainer.defaultProps = defaultProps;

export default LoginSignUpContainer;
