import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import constants from '@app/constants';

function BazoFaq() {
	return (
		<Box>
			<Typography>
				Check out the{' '}
				<Link
					target="_blank"
					rel="noopener noreferrer"
					href={constants.onboardingFaqUrl}
				>
					FAQs
				</Link>{' '}
				for more onboarding details. You may access this page at any
				time from the Account dropdown in the top right corner.
			</Typography>
		</Box>
	);
}

export default BazoFaq;
