import React, { useState } from 'react';

import Box from '@mui/material/Box';
import { updateMerchant } from '@app/api/merchant';
import useAuth from '@app/hooks/useAuth';
import useForm from '@app/hooks/useForm';
import { successHandler } from '@app/lib/SnackbarUtils';
import SettingContainer from '../components/settingContainer/SettingContainer';

function Email() {
	const { merchant, setMerchant } = useAuth();
	const [error, setError] = useState('');

	const { handleSubmit, handleChange, data, errors } = useForm({
		initialValues: {
			emailDisplayName: merchant.email_display_name || '',
		},
		validations: {
			emailDisplayName: {
				pattern: {
					value: /^[a-zA-Z0-9 \-']*?$/, // Allows only letters (both cases), numbers, spaces, hyphens, and apostrophes
					message:
						'Your display name contains invalid characters. Please use only letters, numbers, spaces, hyphens, and apostrophes.',
				},
				custom: {
					isValid: (value) => value.length <= 50,
					message:
						'Email display name must be less than 50 characters long',
				},
			},
		},
		onSubmit: async () => {
			try {
				await updateMerchant({
					fields: {
						email_display_name: data.emailDisplayName || null,
					},
				});
				setMerchant((prevMerchant) => ({
					...prevMerchant,
					email_display_name: data.emailDisplayName,
				}));
				successHandler();
			} catch (err) {
				console.error(err);
				setError(err.message);
			}
		},
	});

	return (
		<Box
			sx={{
				pl: 2,
				pr: 2,
				display: 'flex',
				flexDirection: 'column',
				gap: 3,
			}}
		>
			<SettingContainer
				title="Email Display Name"
				subtitle="Customize the sender name for all outgoing emails."
				value={data.emailDisplayName}
				onChangeValue={handleChange('emailDisplayName')}
				inputName="emailDisplayName"
				placeholder={`Defaults to ${merchant?.name} if empty`}
				onClick={handleSubmit}
				errors={[...Object.values(errors ?? {}), error]}
			/>
		</Box>
	);
}

export default Email;
