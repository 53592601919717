import React from 'react';
import PropTypes from 'prop-types';
import useRenderPerformance from '../hooks/useRenderPerformance';

export default function ProfilerProvider({ id, children, options }) {
	const { onRender } = useRenderPerformance(options);

	return (
		<React.Profiler id={id} onRender={onRender}>
			{children}
		</React.Profiler>
	);
}

ProfilerProvider.propTypes = {
	id: PropTypes.string.isRequired,
	options: PropTypes.shape({
		mount: PropTypes.bool,
		update: PropTypes.bool,
	}),
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]).isRequired,
};

ProfilerProvider.defaultProps = {
	options: {
		mount: true,
		update: true,
	},
};
