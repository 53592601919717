import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import StatusBadge from '../../ui/StatusBadge';

import { getDaysBetween } from '../../../../helpers/dateHelpers';

// FIXME: WIP make this interface with inventory

function createBadge({ date }) {
	const now = new Date();
	const timeBetween = getDaysBetween(now, date); // Will be negative if overdue

	const isWithin7Days = timeBetween <= 7;

	const warningColor = 'warning.main';
	const errorColor = 'error.main';

	// return overdue badge irrespective of order numbers
	if (timeBetween < 0) {
		return {
			message: `Overdue ${Math.abs(timeBetween)} day${
				timeBetween === 1 ? '' : 's'
			}`,
			statusColor: errorColor,
		};
	}

	if (timeBetween > 0 && isWithin7Days) {
		return {
			message: `Due in ${timeBetween} day${
				timeBetween.days === 1 ? '' : 's'
			}`,
			statusColor: warningColor,
		};
	}

	return null;
}

function FulfillmentCell({
	date,
	// totalOrderCount,
	// unpaidOrderCount,
}) {
	const badgeContent = createBadge({
		date,
		// totalOrderCount,
		// unpaidOrderCount,
	});

	return (
		<Stack spacing={0.25}>
			{date && (
				<Typography variant="subtitle1" sx={{ fontSize: '14px' }}>
					{date}
				</Typography>
			)}
			{badgeContent && (
				<StatusBadge
					buttonText={badgeContent.message}
					color={badgeContent.statusColor}
				/>
			)}
		</Stack>
	);
}

FulfillmentCell.propTypes = {
	date: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
	]).isRequired,
};

FulfillmentCell.defaultProps = {};

export default FulfillmentCell;
