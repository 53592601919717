import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

function Loader({ fullPage, size, color }) {
	if (fullPage) {
		return (
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
				style={{ minHeight: '100vh' }}
			>
				<Grid item xs={3}>
					<CircularProgress color={color} />
				</Grid>
			</Grid>
		);
	}
	return <CircularProgress size={size} />;
}

Loader.propTypes = {
	fullPage: PropTypes.bool,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string,
};

Loader.defaultProps = {
	fullPage: false,
	size: 40,
	color: 'primary',
};

export default Loader;
