import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AuxiliaryProductsTable from '../table/AuxiliaryProductsTable';
import constants from '@app/constants';

function ShippingAndGiftsStep({
	selectedAuxiliaryIds,
	setSelectedAuxiliaryIds,
	checkBoxSelected,
	setCheckBoxSelected,
	title,
	subTitle,
	checkBoxText,
	queryKeys,
}) {
	const isCheckBoxDisabled = !!selectedAuxiliaryIds.length;

	return (
		<Box>
			<Typography>{title}</Typography>
			<Typography
				sx={{
					mt: 1,
					mb: 2,
					color: constants.colors.pending,
					fontSize: 14,
				}}
			>
				{subTitle}
			</Typography>
			<AuxiliaryProductsTable
				selectedAuxiliaryIds={selectedAuxiliaryIds}
				setSelectedAuxiliaryIds={setSelectedAuxiliaryIds}
				queryKeysArray={queryKeys}
			/>
			<FormControlLabel
				label={
					<Typography
						sx={{
							fontSize: 14,
							color: isCheckBoxDisabled
								? constants.colors.pending
								: constants.colors.textMain,
						}}
					>
						{checkBoxText}
					</Typography>
				}
				control={
					<Checkbox
						checked={checkBoxSelected && !isCheckBoxDisabled}
						disabled={isCheckBoxDisabled}
						onChange={(e) => setCheckBoxSelected(e.target.checked)}
					/>
				}
			/>
		</Box>
	);
}

ShippingAndGiftsStep.propTypes = {
	selectedAuxiliaryIds: PropTypes.arrayOf(PropTypes.string)
		.isRequired,
	setSelectedAuxiliaryIds: PropTypes.func.isRequired,
	checkBoxSelected: PropTypes.bool.isRequired,
	setCheckBoxSelected: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string.isRequired,
	checkBoxText: PropTypes.string.isRequired,
	queryKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ShippingAndGiftsStep;
