import * as React from 'react';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import DeleteButton from '../../components/shared/Table/components/DeleteSellingPlanGroup';
import StatusBadge from '../../components/shared/ui/StatusBadge';
import {
	formatReadableDate,
	isDate,
} from '../../helpers/dateHelpers';
import FulfillmentCell from '../../components/shared/Table/components/FulfillmentCell';
import { getNumericId } from '../../lib/shopify';
import Button from '@app/components/shared/button/Button';

export const preorderColumns = [
	{
		name: 'description',
		label: 'Campaign',
	},
	{
		name: 'name',
		label: 'Description',
	},
	{
		name: 'remaining_balance_charge_date',
		label: 'Due Date',
		options: {
			customBodyRender: (value, tableMeta) => {
				// TODO: This is a messy way to access the original data when you need
				// to access more than one property.  There are a couple other ways but
				// but they are all pretty cumbersome.
				// This seems to be the nicest MUI table library though...
				// https://github.com/gregnb/mui-datatables/issues/1038
				const { rowData } = tableMeta;
				const totalOrderCount = rowData[3];
				// const unpaidOrderCount = rowData[3];

				// const hasUnpaidOrders = totalOrderCount > unpaidOrderCount;

				if (value === 'Fulfilled') {
					return (
						<StatusBadge
							buttonText="Fulfilled"
							color="primary.main"
						/>
					);
				}

				const date =
					Date.parse(value) !== 'Nan'
						? formatReadableDate(value)
						: 'N/A';
				return (
					<FulfillmentCell
						// hasUnpaidOrders={hasUnpaidOrders}
						totalOrderCount={totalOrderCount}
						// unpaidOrderCount={unpaidOrderCount}
						date={date}
					/>
				);
			},
			customFilterListOptions: {
				render: (value) =>
					isDate(value) ? formatReadableDate(value) : 'N/A',
			},
			filterOptions: {
				renderValue: (value) =>
					isDate(value) ? formatReadableDate(value) : 'N/A',
			},
		},
	},
	// {
	// 	// TODO: Make this use the built in methods rather than the map on data below
	// 	name: 'unpaidOrders',
	// 	label: 'Unpaid Orders',
	// },
	{
		// TODO: Make this use the built in methods rather than the map on data below
		name: 'preorders',
		label: 'Total Items',
	},
	{
		// TODO: This is very confusing make DB col easier to read with simple
		// status = active/pending payment/archived/deleted enum
		// Instead of shouldShow = !deleted
		name: 'deleted',
		label: 'Active Campaigns',
		options: {
			filter: true,
			filterType: 'checkbox',
			filterList: ['Active'],
			filterOptions: {
				names: ['Active', 'Inactive'],
				logic: (val, filterVal) => {
					const isActive = !val; // field is deleted: true/false

					const show =
						(filterVal.indexOf('Active') > -1 && isActive) ||
						(filterVal.indexOf('Inactive') > -1 && !isActive);

					return !show;
				},
			},
			customBodyRender: (value) => (
				<Switch checked={!value} disabled />
			),
		},
	},
	{
		name: 'deleted_at',
		label: 'End Date',
		options: {
			display: false,
			sort: true,
			filter: false,
			customBodyRender: (value) =>
				value ? formatReadableDate(value) : '',
		},
	},
	{
		name: 'inventory_reservation',
		label: 'Inventory Reservation',
		options: {
			display: false,
			filterType: 'checkbox',
		},
	},
	{
		name: 'summary',
		label: 'Summary',
		options: {
			display: false,
		},
	},
	{
		name: 'id',
		label: 'Manage',
		options: {
			sort: false,
			filter: false,
			setCellProps: () => ({
				style: {
					textAlign: 'center',
				},
			}),
			setCellHeaderProps: () => ({
				style: {
					textAlign: 'center',
				},
			}),
			customBodyRender: (value, tableMeta) => {
				const id = getNumericId(value);

				// TODO: Simplify this rowData references
				// This references deleted_at which is timestamp
				const isDeleted = !!tableMeta?.rowData[5];

				return (
					<Stack>
						<Button
							component={Link}
							to={`/campaigns/preorders/${id}/edit`}
							disabled={isDeleted}
						>
							Manage
						</Button>
						<Button
							component={Link}
							to={`/campaigns/preorders/${id}`}
							// disabled={isDeleted}
						>
							{!isDeleted ? 'Execute' : 'Review'}
						</Button>
					</Stack>
				);
			},
		},
	},
	{
		name: 'id',
		label: 'Cancel',
		options: {
			sort: false,
			filter: false,
			setCellProps: () => ({
				style: {
					textAlign: 'center',
				},
			}),
			setCellHeaderProps: () => ({
				style: {
					textAlign: 'center',
				},
			}),
			customBodyRender: (value, tableMeta) => {
				const id = value;
				// Is timestamp for is deleted property
				const isDeleted = !!tableMeta?.rowData[5];

				return (
					<DeleteButton
						disabled={isDeleted}
						sellingPlanGroupId={id}
					/>
				);
			},
		},
	},
];

const columns = {
	preorder: preorderColumns,
};

export default columns;
