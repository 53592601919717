import React from 'react';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

import PropTypes from 'prop-types';

function VariantsSearchInput({
	handleSearch,
	handleClear,
	searchTermDisplay,
	placeholder,
}) {
	const [isSearchInputHovered, setIsSearchInputHovered] =
		React.useState(false);
	const [isSearchInputFocused, setIsSearchInputFocused] =
		React.useState(false);

	const isClearVisible =
		(isSearchInputFocused || isSearchInputHovered) &&
		searchTermDisplay;

	return (
		<TextField
			id="variant-search-bar"
			label="Search"
			variant="outlined"
			onChange={handleSearch}
			value={searchTermDisplay}
			placeholder={placeholder}
			onMouseEnter={() => setIsSearchInputHovered(true)}
			onMouseLeave={() => setIsSearchInputHovered(false)}
			onFocus={() => setIsSearchInputFocused(true)}
			onBlur={() => setIsSearchInputFocused(false)}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<Search />
					</InputAdornment>
				),
				endAdornment: (
					<IconButton
						sx={{ visibility: isClearVisible ? 'visible' : 'hidden' }}
						onClick={handleClear}
					>
						<ClearIcon sx={{ width: 20, height: 20 }} />
					</IconButton>
				),
			}}
		/>
	);
}

VariantsSearchInput.propTypes = {
	handleSearch: PropTypes.func.isRequired,
	handleClear: PropTypes.func.isRequired,
	searchTermDisplay: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
};

VariantsSearchInput.defaultProps = {
	placeholder: 'Search product variants',
};

export default VariantsSearchInput;
