import React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import { ModalProvider } from '../../hooks/useModal';
import ProductsTable from '../../components/muiTable/ProductsTable';
import { useProductsForPriorityChart } from '@app/hooks/useProducts';
import useAuth from '@app/hooks/useAuth';
import Loader from '@app/components/shared/loaders/CircularLoader';
import MetricWidget from '@app/components/shared/ui/MetricWidget';
import PrioritiesChart from '@app/pages/inventoryReview/PrioritiesChart/PrioritiesChart';
import { useInventoryReviewContext } from '@app/hooks/useInventoryReviewContext';
import Button from '@app/components/shared/button/Button';
import RequiresFlag from '@app/components/shared/flags/RequiresFlag';
import { FLAGS } from '@app/hooks/useFlags';
import { useCreateCampaignContext } from '@app/hooks/useCreateCampaignContext';

function InventoryReview() {
	const navigate = useNavigate();
	const {
		merchant,
		formatMerchantCurrency,
		formatMerchantNumber,
		getCurrencySymbol,
	} = useAuth();

	const { setIsMarketplace } = useCreateCampaignContext();

	const [selectedVariant, setSelectedVariant] =
		useInventoryReviewContext();

	// Slim version of variants list for memory saving purpose. There may be thousands of variants and we don't need all of that data
	const selectedVariantsSlim = React.useMemo(
		() =>
			selectedVariant.map((variant) => ({
				id: variant.id,
				price: variant.price,
				msrp: variant.msrp,
				full_title: variant.full_title,
				sku: variant.sku,
				title: variant.title,
				product_id: variant.product_id,
				image_url: variant.image_url,
			})),
		[selectedVariant],
	);

	const [searchTerm, setSearchTerm] = React.useState('');
	const [collection, setCollection] = React.useState();
	const [status, setStatus] = React.useState('All');
	const [inventoryKind, setInventoryKind] = React.useState(null);
	const [ageRate, setAgeRate] = React.useState(0.5);
	const [valueRate, setValueRate] = React.useState(0.5);

	const [minMaxValues, setMinMaxValues] = React.useState(null);

	const { data } = useProductsForPriorityChart({
		queryVariables: {
			searchTerm,
			collectionId: collection?.id,
			status,
			inventoryKind: 'All',
			ageRate,
			valueRate,
		},
		cacheKey: `${merchant.id}|${searchTerm}|${
			collection?.id
		}|${status}|All|${inventoryKind && ageRate}|${
			inventoryKind && valueRate
		}`,
	});

	React.useEffect(() => {
		if (
			inventoryKind ||
			status !== 'All' ||
			collection ||
			searchTerm ||
			!data
		) {
			return;
		}
		let minAge = null;
		let maxAge = null;
		let minValue = null;
		let maxValue = null;

		const inventoryValues = [];
		data.products.forEach(({ product_values: values }) => {
			const daysInStore = parseInt(values.days_in_store, 10);
			const inventoryValue = parseFloat(values.inventory_value);
			inventoryValues.push(inventoryValue);
			if (minAge === null) {
				minAge = daysInStore;
				maxAge = daysInStore;
				minValue = inventoryValue;
				maxValue = inventoryValue;
			} else {
				minAge = Math.min(minAge, daysInStore);
				maxAge = Math.max(maxAge, daysInStore);
				minValue = Math.max(0, Math.min(minValue, inventoryValue));
				maxValue = Math.max(maxValue, inventoryValue);
			}
		});
		const valuesWithoutZeroes = inventoryValues.filter((v) => !!v);
		const medianValue = valuesWithoutZeroes.sort((a, b) =>
			a > b ? 1 : -1,
		)[Math.floor(valuesWithoutZeroes.length / 2)];

		setMinMaxValues({
			medianValue,
			minAge,
			maxAge,
			minValue,
			maxValue,
		});
	}, [data, searchTerm, collection, status, inventoryKind]);

	const totalInventoryValue = React.useMemo(() => {
		if (!data) {
			return null;
		}

		return data.products.reduce(
			(r, v) => r + parseFloat(v.product_values.inventory_value),
			0,
		);
	}, [data]);

	const totalInventoryUnits = React.useMemo(() => {
		if (!data) {
			return null;
		}

		return data.products.reduce(
			(r, v) =>
				r +
				parseInt(
					Math.max(v.product_values.inventory_quantity, 0),
					10,
				),
			0,
		);
	}, [data]);

	const handleCreateCampaignNavigate = React.useCallback(() => {
		setIsMarketplace({
			isMarketplace: false,
			marketplaceCampaignId: null,
		});
		navigate('campaigns/create');
	}, [setIsMarketplace, navigate]);

	const handleCreateMarketplaceCampaignNavigate =
		React.useCallback(() => {
			setIsMarketplace({
				isMarketplace: true,
				marketplaceCampaignId: null,
			});
			navigate('campaigns/create');
		}, [setIsMarketplace, navigate]);

	return (
		<>
			<ModalProvider>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '10px',
						height: '3px',
						position: 'sticky',
						width: { xs: '100%', md: '25%', lg: '20%' },
						top: '5rem',
						mb: 1,
						ml: 'auto',
						zIndex: 1000,
						textAlign: {
							xs: 'right',
							lg: 'right',
						},
					}}
				>
					<Button
						onClick={handleCreateCampaignNavigate}
						color="primary"
						variant="contained"
						disabled={selectedVariantsSlim.length === 0}
						sx={{ width: '100%' }}
					>
						Create Campaign
					</Button>
					{!merchant.is_marketplace && (
						<RequiresFlag flag={FLAGS.MARKETPLACE_AVAILABLE}>
							<Button
								onClick={handleCreateMarketplaceCampaignNavigate}
								color="primary"
								variant="contained"
								disabled={selectedVariantsSlim.length === 0}
								sx={{ width: '100%' }}
							>
								Create Marketplace Campaign
							</Button>
						</RequiresFlag>
					)}
				</Box>
			</ModalProvider>
			<Box sx={{ width: '100%', mb: 2 }}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={7}>
						<Box sx={{ height: '100%', minHeight: '400px' }}>
							<Card sx={{ height: '100%', pl: 2, pr: 2, m: 'auto' }}>
								{data?.products && minMaxValues ? (
									<PrioritiesChart
										products={data.products}
										minMaxValues={minMaxValues}
										formatMerchantCurrency={formatMerchantCurrency}
										onInventoryKindChanged={setInventoryKind}
										setAgeRate={setAgeRate}
										setValueRate={setValueRate}
										ageRate={ageRate}
										valueRate={valueRate}
										inventoryKind={inventoryKind}
									/>
								) : (
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '100%',
										}}
									>
										<Loader />
									</Box>
								)}
							</Card>
						</Box>
					</Grid>

					<Grid item md={5} xs={12}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: 1,
								height: '100%',
							}}
						>
							<MetricWidget
								name={`Total Inventory Value (${getCurrencySymbol()})`}
								value={formatMerchantCurrency(totalInventoryValue, 0)}
								sx={{ flexGrow: 1 }}
							/>
							<MetricWidget
								name="Total Units"
								value={formatMerchantNumber({
									value: totalInventoryUnits,
								})}
								sx={{ flexGrow: 1 }}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>

			<ProductsTable
				defaultSelectedVariants={selectedVariant}
				onSelectedVariantsChanged={setSelectedVariant}
				onSearchChanged={setSearchTerm}
				onCollectionFilterChanged={setCollection}
				onStatusFilterChanged={setStatus}
				onInventoryKindChanged={setInventoryKind}
				defaultInventoryKind={inventoryKind}
				ageRate={ageRate}
				valueRate={valueRate}
			/>
		</>
	);
}

export default InventoryReview;
