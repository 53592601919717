import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

function ItemRowContainer({
	items,
	renderChild,
	headerComponent,
	emptyStateComponent,
	sx,
}) {
	const theme = useTheme();
	const border = `1px solid ${theme.palette.subdued.main}`;

	return (
		<>
			{headerComponent}
			<Box
				sx={{
					'& > :first-of-type': {
						borderTop: border,
					},
					'& > *': {
						borderBottom: border,
						paddingTop: 1,
						paddingBottom: 1,
					},
					...sx,
				}}
			>
				{items.length > 0
					? items.map((item, index) => renderChild(item, index))
					: emptyStateComponent}
			</Box>
		</>
	);
}

ItemRowContainer.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	headerComponent: PropTypes.node,
	emptyStateComponent: PropTypes.node,
	renderChild: PropTypes.func.isRequired,
	sx: PropTypes.shape({}),
};

ItemRowContainer.defaultProps = {
	emptyStateComponent: null,
	headerComponent: null,
	sx: {},
};

export default ItemRowContainer;
