import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import { getNumericId } from '../../../lib/shopify';
import DeleteButton from '../../../components/shared/Table/components/DeleteSellingPlanGroup';
import EndNyopButton from '../../../components/shared/Table/components/EndNyopButton';
import CloseNyopButton from '../../../components/shared/Table/components/CloseNyopButton';
import RecreateNyopButton from '../../../components/shared/Table/components/RecreateNyopButton';
import Button from '@app/components/shared/button/Button';

function ActionsRenderer({ campaignId, campaignState }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const id = getNumericId(campaignId);
	const { isStarted, isEnded, isFinalizing, isFinalized, isDeleted } =
		campaignState;
	return (
		<>
			<Button onClick={handleClick} variant="text">
				<MoreHorizIcon />
			</Button>
			<Menu
				id="fade-menu"
				open={open}
				onClose={handleClose}
				TransitionComponent={Fade}
				anchorEl={anchorEl}
			>
				<MenuItem>
					<DeleteButton
						disabled={isDeleted || isFinalizing || isFinalized}
						size="small"
						type="nyop"
						sellingPlanGroupId={campaignId}
						onSuccess={handleClose}
					/>
				</MenuItem>
				<MenuItem>
					<Button
						size="small"
						disabled={isDeleted}
						variant="outlined"
						color="secondary"
						component={Link}
						to={`/campaigns/nyop/${id}/edit`}
						sx={{ width: '100%' }}
						onClick={handleClose}
					>
						Edit
					</Button>
				</MenuItem>
				{isDeleted && (
					<MenuItem>
						<RecreateNyopButton
							size="small"
							campaignId={campaignId}
							onSuccess={handleClose}
							buttonText="RESTART"
						/>
					</MenuItem>
				)}
				<MenuItem>
					<EndNyopButton
						disabled={isDeleted || isEnded || !isStarted}
						campaignId={campaignId}
						size="small"
						onSuccess={handleClose}
					/>
				</MenuItem>
				<MenuItem>
					<CloseNyopButton
						disabled={isDeleted || !isEnded}
						campaignId={campaignId}
						size="small"
						onSuccess={handleClose}
					/>
				</MenuItem>
				<MenuItem>
					<Button
						component={Link}
						size="small"
						variant="outlined"
						to={`/campaigns/nyop/${id}`}
						sx={{ width: '100%' }}
					>
						Review
					</Button>
				</MenuItem>
			</Menu>
		</>
	);
}

ActionsRenderer.propTypes = {
	campaignId: PropTypes.string.isRequired,
	campaignState: PropTypes.shape({
		isStarted: PropTypes.bool.isRequired,
		isEnded: PropTypes.bool.isRequired,
		isFinalizing: PropTypes.bool.isRequired,
		isFinalized: PropTypes.bool.isRequired,
		isDeleted: PropTypes.bool.isRequired,
	}).isRequired,
};

export default ActionsRenderer;
