import React from 'react';

import Box from '@mui/material/Box';

import CounterOfferItemSku from './counterOfferItemSku';
import MaxOffersPerOrder from './maxOffersPerOrder';
import RecalculateTaxes from './recalculateTaxes';
import CounterOfferExpiry from '@app/pages/settings/nyop/counterOfferExpiry';
import CheckoutValidation from './checkoutValidation';
import { FLAGS, useFlagEnabled } from '@app/hooks/useFlags';

function SettingsNYOP() {
	const customCounterOfferExpiryEnabled = useFlagEnabled(
		FLAGS.CUSTOM_COUNTER_OFFER_EXPIRY,
	);

	return (
		<Box
			sx={{
				pl: 2,
				pr: 2,
				display: 'flex',
				flexDirection: 'column',
				gap: 3,
			}}
		>
			<CounterOfferItemSku />
			<MaxOffersPerOrder />
			{customCounterOfferExpiryEnabled ? (
				<CounterOfferExpiry />
			) : null}
			<RecalculateTaxes />
			<CheckoutValidation />
		</Box>
	);
}

export default SettingsNYOP;
