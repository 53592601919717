import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@app/components/shared/button/Button';

const getButtonConfig = (sellingPlanGroup) => {
	if (sellingPlanGroup?.preorders.length < 1 || !sellingPlanGroup) {
		return {
			isDisabled: true,
			text: 'Fulfill',
		};
	}

	if (sellingPlanGroup?.deleted) {
		return {
			isDisabled: true,
			text: 'Ended',
		};
	}

	return {
		isDisabled: false,
		text: 'Fulfill',
	};
};

function FulfillButton({ sellingPlanGroup, setModal }) {
	const config = getButtonConfig(sellingPlanGroup);

	return (
		<Button
			disabled={config.isDisabled}
			onClick={() => setModal(true)}
		>
			{config.text}
		</Button>
	);
}

FulfillButton.propTypes = {
	sellingPlanGroup: PropTypes.shape({
		preorders: PropTypes.arrayOf(PropTypes.shape({})),
	}).isRequired,
	setModal: PropTypes.func.isRequired,
};

export default FulfillButton;
