import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material/styles';
import SaveBulkPriceText from './SaveBulkPriceText';
import { OrderType } from '@app/propTypes/types';

function BulkAcceptCheckbox({
	bulkOffersPriceDiscount,
	order,
	acceptAllChecked,
	handleCheckBoxChange,
	disabled,
}) {
	const theme = useTheme();

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'stretch',
				position: 'relative',
				backgroundColor: 'background.default',
				color: 'text.primary',
				transition: 'color 0.3s ease, background-color 0.3s ease',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					borderTop: `1px solid ${theme.palette.subdued.main}`,
					width: '100%',
					pt: 2,
					mb: 2,
					gap: 1,
				}}
			>
				<Checkbox
					checked={acceptAllChecked}
					size="small"
					sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
					onChange={handleCheckBoxChange}
					disabled={disabled}
				/>
				<Box
					sx={{
						flexGrow: 1,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}
				>
					<SaveBulkPriceText
						bulkOffersPriceDiscount={bulkOffersPriceDiscount}
						customerLocale={order?.customer_locale}
						currencyCode={
							order?.subtotal_price_set?.presentment_money
								?.currency_code
						}
					/>
					<Typography
						fontWeight="bold"
						color="text.secondary"
						variant="h6"
					>
						Secure exclusive savings when you accept all offers
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}

BulkAcceptCheckbox.propTypes = {
	bulkOffersPriceDiscount: PropTypes.number.isRequired,
	order: OrderType.isRequired,
	acceptAllChecked: PropTypes.bool.isRequired,
	handleCheckBoxChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

BulkAcceptCheckbox.defaultProps = {
	disabled: false,
};

export default BulkAcceptCheckbox;
