import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import productExampleImage from '@app/assets/images/product-example.png';
import ProductThumbnail from '@app/components/shared/ui/ProductThumbnail';

function ProductImage({ currentProduct, enabled }) {
	if (currentProduct.isDefault) {
		return (
			<Box
				component="img"
				src={productExampleImage}
				sx={{
					width: '100%',
					height: '100%',
				}}
			/>
		);
	}

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			<ProductThumbnail
				variantImage={{ src: currentProduct.image_url }}
				size="146px"
				sx={{
					filter: !enabled ? 'grayscale(100%)' : '',
				}}
			/>
		</Box>
	);
}

ProductImage.propTypes = {
	currentProduct: PropTypes.shape({
		isDefault: PropTypes.bool,
		image_url: PropTypes.string,
	}).isRequired,
	enabled: PropTypes.bool.isRequired,
};

export default ProductImage;
