import * as React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { useNavigate } from 'react-router-dom';
import SubscriptionsTilesContainer from '@app/components/subscriptions/SubscriptionsTilesContainer';
import constants from '@app/constants';
import Button from '@app/components/shared/button/Button';

function OnboardingSubscriptions() {
	const navigate = useNavigate();

	const handleSkip = React.useCallback(() => {
		navigate('/');
	}, [navigate]);

	return (
		<Container maxWidth="lg">
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: 2,
					mb: 5,
					mt: 5,
					textAlign: 'center',
				}}
			>
				<Typography variant="h4">
					Unlock Dynamic Pricing Power
				</Typography>
				<Typography
					sx={{
						color: constants.colors.darkGrey,
					}}
				>
					Introduce Dynamic Pricing with Our &apos;Name Your
					Price&apos; Subscription Plans
				</Typography>
			</Box>
			<SubscriptionsTilesContainer />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 3,
					mt: 3,
					width: '100%',
				}}
			>
				<Typography variant="p">
					You can check out the app first, but you will need to select
					a subscription before you can create a campaign.
				</Typography>
				<Box
					sx={{
						display: 'flex',
						gap: 2,
						ml: 'auto',
					}}
				>
					<Button onClick={handleSkip} variant="text">
						Skip
					</Button>
				</Box>
			</Box>
		</Container>
	);
}

export default OnboardingSubscriptions;
