import * as React from 'react';

import { Chart } from 'react-chartjs-2';

import PropTypes from 'prop-types';

import {
	BarController,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineController,
	LineElement,
	PointElement,
	Tooltip,
} from 'chart.js';
import Box from '@mui/material/Box';
import { ProductWithPrioritiesType } from '@app/propTypes/types';

ChartJS.register(
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
);

const AGE_STEP_SIZE = 30;
const MAX_SLIDER_VALUE = 365;
// We need it to be divisible by 30
const AGE_MAX_VALUE = 1020;

const Colors = {
	Font: '#666666',
	Stroke: '#00000033',
	FastSelling: '#00CD9F50',
	New: '#4798DC80',
	NewAccented: '#2EA1FF88',
	SlowMoving: '#F6BE0080',
	SlowMovingAccented: '#FFC800AF',
	FastSellingAccented: '#04EFB980',
	Stale: '#FF696150',
	StaleAccented: '#FF5B5489',
};

function findMedianValue(products) {
	const values = products.map((p) =>
		parseFloat(p.product_values.inventory_value),
	);
	const sortedArr = values.sort((a, b) => a.y - b.y);

	const middleIndex = Math.floor(sortedArr.length / 2);

	if (sortedArr.length < middleIndex + 1) {
		return sortedArr[0]?.y || 0;
	}
	if (sortedArr.length % 2 === 0) {
		return (
			(sortedArr[middleIndex - 1].y + sortedArr[middleIndex].y) / 2
		);
	}
	return sortedArr[middleIndex].y;
}

function getChartData(products, maxValue, ageRate, valueRate) {
	const chartData = products.map((product) => ({
		ageRate: product.product_values.age_rate,
		valueRate: product.product_values.value_rate,
		age: product.product_values.days_in_store,
		value: product.product_values.inventory_value,
		x: product.product_values.days_in_store || 0,
		y: Math.min(
			Math.max(product.product_values.inventory_value || 0, 0),
			maxValue,
		),
		title: product.title,
		id: product.id,
	}));

	const fastSellingDataset = [];
	const newDataset = [];
	const slowMovingDataset = [];
	const staleDataset = [];
	const zeroInventoryDataset = [];
	chartData.forEach((r) => {
		if (+r.valueRate === 0) {
			zeroInventoryDataset.push(r);
		} else if (r.ageRate < ageRate && r.valueRate < valueRate) {
			fastSellingDataset.push(r);
		} else if (r.ageRate < ageRate && r.valueRate >= valueRate) {
			newDataset.push(r);
		} else if (r.ageRate >= ageRate && r.valueRate < valueRate) {
			slowMovingDataset.push(r);
		} else if (r.ageRate >= ageRate && r.valueRate >= valueRate) {
			staleDataset.push(r);
		}
	});

	return [
		fastSellingDataset,
		newDataset,
		slowMovingDataset,
		staleDataset,
		zeroInventoryDataset,
	];
}

function getSuggestedValuesForAge(products) {
	const product = products.find(
		(p) => (parseFloat(p.product_values.age_rate) || 0) !== 0,
	);

	if (!product) {
		return [0, AGE_STEP_SIZE * 10];
	}
	const maxAge =
		Math.ceil(
			parseInt(product.product_values.days_in_store, 10) /
				parseFloat(product.product_values.age_rate),
		) + AGE_STEP_SIZE;

	return [0, Math.min(maxAge, AGE_MAX_VALUE)];
}

function getSuggestedMaxValue(products) {
	const product = products.find(
		(p) => (parseFloat(p.product_values.value_rate) || 0) !== 0,
	);

	if (!product) {
		return [0, 5000];
	}
	const maxValue = Math.ceil(
		parseInt(product.product_values.inventory_value, 10) /
			parseFloat(product.product_values.value_rate),
	);

	return [0, maxValue];
}

function PrioritiesChart({
	products,
	minMaxValues,
	onInventoryKindChanged,
	formatMerchantCurrency,
	setAgeRate,
	setValueRate,
	ageRate,
	valueRate,
	inventoryKind,
}) {
	const productsWithoutZeroInventory = React.useMemo(
		() =>
			products.filter((p) => +p.product_values.inventory_value > 0),
		[products],
	);

	const [suggestedMinAge, suggestedMaxAge] = React.useMemo(
		() =>
			minMaxValues
				? [minMaxValues.minAge, minMaxValues.maxAge]
				: getSuggestedValuesForAge(products),
		[minMaxValues, products],
	);

	const [suggestedMinValue, suggestedMaxValue, actualMaxValue] =
		React.useMemo(() => {
			const [minValue, maxValue] = minMaxValues
				? [minMaxValues.minValue, minMaxValues.maxValue]
				: getSuggestedMaxValue(productsWithoutZeroInventory);

			// Get record that's 5% from the top
			const productsSortedByValue = productsWithoutZeroInventory.sort(
				(p1, p2) =>
					+p1.product_values.inventory_value >
					+p2.product_values.inventory_value
						? 1
						: -1,
			);

			const index = Math.round(productsSortedByValue.length * 0.95);
			let marginProduct = productsSortedByValue[index];
			if (!marginProduct) {
				marginProduct =
					productsSortedByValue[productsSortedByValue.length - 1];
			}
			if (!marginProduct) {
				return [minValue, maxValue, maxValue];
			}

			const marginDiff =
				+marginProduct.product_values.inventory_value - minValue;
			const chartMaxValue = Math.min(
				+marginProduct.product_values.inventory_value + marginDiff,
				maxValue,
			);

			// Assuming that usually we want to have about 10 ticks
			const stepSize = chartMaxValue / 10;

			let powerOfTen = 1;
			while (stepSize / 10 ** (powerOfTen + 1) > 1) {
				powerOfTen += 1;
			}

			const adjustedChartMaxValue =
				Math.ceil(chartMaxValue / 10 ** powerOfTen) *
				10 ** powerOfTen;

			return [minValue, adjustedChartMaxValue, maxValue];
		}, [minMaxValues, productsWithoutZeroInventory]);

	// There are some weird bugs with this median if we don't use useMemo here
	const medianValue = React.useMemo(
		() =>
			minMaxValues
				? minMaxValues.medianValue
				: findMedianValue(productsWithoutZeroInventory),
		[minMaxValues, productsWithoutZeroInventory],
	);

	// We are adding up from 10 days to the beginning of the chart so new items don't look too cluttered
	const buffer = React.useMemo(
		() =>
			Math.min(
				Math.max(
					Math.floor((suggestedMaxAge - suggestedMinAge) * 0.05),
					10,
				),
				suggestedMinAge,
			),
		[suggestedMinAge, suggestedMaxAge],
	);

	const [
		fastSellingDataset,
		newDataset,
		slowMovingDataset,
		staleDataset,
		zeroInventoryDataset,
	] = React.useMemo(
		() =>
			getChartData(products, suggestedMaxValue, ageRate, valueRate),
		[products, suggestedMaxValue, ageRate, valueRate],
	);

	const sliderDefaultValue =
		suggestedMaxAge <= MAX_SLIDER_VALUE
			? 50
			: (MAX_SLIDER_VALUE / suggestedMaxAge) * 100;

	React.useEffect(() => {
		setValueRate(medianValue / actualMaxValue);
		// eslint-disable-next-line
	}, []);

	const onSliderValueSelected = React.useCallback(
		(selectedSliderValue) => {
			// 1. Calculate the value of the buffer on the slider e.g. 7 (%)
			const bufferValue =
				(buffer / (suggestedMaxAge + buffer - suggestedMinAge)) * 100;

			// It's possible when all items have the same published_at
			if (bufferValue === 100) {
				setAgeRate(0);
				return;
			}

			// 2. Subtract buffer part from the slider 54 - 7 = 47
			const v1 = selectedSliderValue - bufferValue;

			// 3. Reevaluate value of the slider (47 * 100) / (100 - 7) = 50.5
			const v2 = (v1 * 100) / (100 - bufferValue);

			setAgeRate(v2 / 100);
		},
		[buffer, suggestedMaxAge, suggestedMinAge, setAgeRate],
	);

	React.useEffect(() => {
		onSliderValueSelected(50);
	}, [onSliderValueSelected]);

	const data = {
		datasets: [
			{
				// We still need those to properly align rest of the data
				data: zeroInventoryDataset,
				backgroundColor: '#222222AA',
				hidden: true,
			},
			{
				data: fastSellingDataset,
				backgroundColor: '#222222AA',
				hidden:
					inventoryKind &&
					inventoryKind !== 'All' &&
					inventoryKind !== 'FAST_SELLING',
			},
			{
				data: newDataset,
				backgroundColor: '#222222AA',
				hidden:
					inventoryKind &&
					inventoryKind !== 'All' &&
					inventoryKind !== 'NEW',
			},
			{
				data: slowMovingDataset,
				backgroundColor: '#222222AA',
				hidden:
					inventoryKind &&
					inventoryKind !== 'ALL' &&
					inventoryKind !== 'SLOW_MOVING',
			},
			{
				data: staleDataset,
				backgroundColor: '#222222AA',
				hidden:
					inventoryKind &&
					inventoryKind !== 'All' &&
					inventoryKind !== 'STALE',
			},
		],
	};

	function isMouseWithinRect(ctx, rect) {
		return (
			ctx.mouseCoordinates &&
			ctx.mouseCoordinates.x >= rect[0] &&
			ctx.mouseCoordinates.x <= rect[0] + rect[2] &&
			ctx.mouseCoordinates.y >= rect[1] &&
			ctx.mouseCoordinates.y <= rect[1] + rect[3]
		);
	}

	function isMouseWithinCircle(ctx, x, y, radius) {
		if (!ctx.mouseCoordinates) {
			return false;
		}

		const distance = Math.sqrt(
			(ctx.mouseCoordinates.x - x) * (ctx.mouseCoordinates.x - x) +
				(ctx.mouseCoordinates.y - y) * (ctx.mouseCoordinates.y - y),
		);
		return distance < radius;
	}

	function drawRect(ctx, rect, color, accentColor) {
		ctx.beginPath();
		ctx.rect(...rect);

		ctx.fillStyle = isMouseWithinRect(ctx, rect)
			? accentColor
			: color;
		ctx.fill();
	}

	function getQuadrantsRects(ctx, chartArea, scales) {
		const sectionLeftWidth =
			((chartArea.right - chartArea.left) *
				(ctx.sliderValue === null || ctx.sliderValue === undefined
					? sliderDefaultValue
					: ctx.sliderValue)) /
			100;
		const sectionRightWidth =
			chartArea.right - chartArea.left - sectionLeftWidth;

		const sectionBottomHeight =
			chartArea.bottom - scales.y.getPixelForValue(medianValue);

		// const sectionBottomHeight =
		// 	((chartArea.bottom - chartArea.top) * medianValue) / 100;
		const sectionTopHeight =
			chartArea.bottom - chartArea.top - sectionBottomHeight;

		return {
			FAST_SELLING: [
				chartArea.left,
				chartArea.top + sectionTopHeight,
				sectionLeftWidth,
				sectionBottomHeight,
			],
			NEW: [
				chartArea.left,
				chartArea.top,
				sectionLeftWidth,
				sectionTopHeight,
			],
			SLOW_MOVING: [
				chartArea.left + sectionLeftWidth,
				chartArea.top + sectionTopHeight,
				sectionRightWidth,
				sectionBottomHeight,
			],
			STALE: [
				chartArea.left + sectionLeftWidth,
				chartArea.top,
				sectionRightWidth,
				sectionTopHeight,
			],
		};
	}

	function drawQuadrants(ctx, chartArea, scales) {
		const quadrantRects = getQuadrantsRects(ctx, chartArea, scales);

		drawRect(
			ctx,
			quadrantRects.FAST_SELLING,
			Colors.FastSelling,
			Colors.FastSellingAccented,
		);

		drawRect(ctx, quadrantRects.NEW, Colors.New, Colors.NewAccented);

		drawRect(
			ctx,
			quadrantRects.SLOW_MOVING,
			Colors.SlowMoving,
			Colors.SlowMovingAccented,
		);

		drawRect(
			ctx,
			quadrantRects.STALE,
			Colors.Stale,
			Colors.StaleAccented,
		);
	}

	function drawMedian(ctx, chartArea, scales) {
		const medianY = scales.y.getPixelForValue(medianValue);

		ctx.beginPath();
		ctx.moveTo(chartArea.left, medianY);
		ctx.lineTo(chartArea.right, medianY);

		ctx.lineWidth = 2;
		ctx.strokeStyle = '#222222AA';
		ctx.stroke();
	}

	function drawSelector(ctx, chartArea) {
		if (ctx.mouseDownSlider && ctx.mouseCoordinates) {
			ctx.sliderValue = Math.min(
				Math.max(
					((ctx.mouseCoordinates.x - chartArea.left) /
						chartArea.width) *
						100,
					0,
				),
				100,
			);
		}

		if (ctx.mouseDownSlider && ctx.mouseUp) {
			const now = Date.now();
			if (!ctx.sliderValueChanged) {
				ctx.sliderValueChanged = now;
			}
			if (now - ctx.sliderValueChanged > 200) {
				ctx.sliderValueChanged = now;
				onSliderValueSelected(ctx.sliderValue);
				setTimeout(() => {
					ctx.mouseDownSlider = false;
				}, 20);
			}
		}

		const sliderX =
			chartArea.left +
			((chartArea.right - chartArea.left) *
				(ctx.sliderValue === null || ctx.sliderValue === undefined
					? sliderDefaultValue
					: ctx.sliderValue)) /
				100;
		// Drawing the vertical divider
		ctx.beginPath();
		ctx.moveTo(sliderX, chartArea.top);
		ctx.lineTo(sliderX, chartArea.bottom);
		ctx.lineWidth = 3;
		ctx.strokeStyle = '#00CD9FFF';
		ctx.stroke();

		// Drawing the circle
		ctx.beginPath();
		const onCircle = isMouseWithinCircle(
			ctx,
			sliderX,
			chartArea.bottom,
			10,
		);
		if (onCircle) {
			ctx.canvas.style.cursor = 'pointer';
			ctx.fillStyle = '#00CD9F55';
			ctx.mouseHover = 'slider';
			ctx.arc(sliderX, chartArea.bottom, 16, 0, 2 * Math.PI, false);
			ctx.fill();

			if (ctx.mouseDown) {
				ctx.mouseDownSlider = true;
			}
		} else if (ctx.mouseHover !== 'quadrant') {
			ctx.canvas.style.cursor = '';
		}

		if (!onCircle) {
			ctx.mouseDown = false;
		}

		ctx.beginPath();
		ctx.fillStyle = '#00CD9FFF';
		ctx.arc(sliderX, chartArea.bottom, 10, 0, 2 * Math.PI, false);
		ctx.fill();
	}

	function getSelectedQuadrant(chart) {
		const { ctx, chartArea, scales } = chart;
		const quadrantRects = getQuadrantsRects(ctx, chartArea, scales);

		const quadrant = Object.entries(quadrantRects).find(([, rect]) =>
			isMouseWithinRect(ctx, rect),
		);

		if (quadrant) {
			return quadrant[0];
		}
		return null;
	}

	const CanvasPlugin = {
		beforeDraw({ ctx, chartArea, scales }) {
			drawQuadrants(ctx, chartArea, scales);
		},
		afterDraw({ ctx, chartArea, scales }) {
			drawMedian(ctx, chartArea, scales);
			drawSelector(ctx, chartArea, scales);
		},
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		animation: {
			duration: 0,
		},
		events: [
			'mousemove',
			'mouseout',
			'click',
			'touchstart',
			'touchmove',
			'mousedown',
			'mouseup',
		],
		onClick: ({ chart, x, y }) => {
			const { ctx } = chart;

			if (ctx.mouseDownSlider || ctx.clickBlocked) {
				return;
			}

			ctx.mouseCoordinates = { x, y };

			const quadrant = getSelectedQuadrant(chart);

			if (!quadrant) {
				return;
			}

			onInventoryKindChanged(quadrant);
		},
		onHover: ({ chart, x, y }) => {
			const { ctx } = chart;

			ctx.mouseCoordinates = { x, y };

			const quadrant = getSelectedQuadrant(chart);

			if (quadrant) {
				ctx.mouseHover = 'quadrant';
				ctx.canvas.style.cursor = 'pointer';
			} else {
				ctx.canvas.style.cursor = '';
			}

			// Minimize hover updates to prevent stack overflow
			if (ctx.currentQuadrant !== quadrant) {
				ctx.currentQuadrant = quadrant;
				chart.update();
			}
		},
		plugins: {
			events: [
				'mousemove',
				'mouseout',
				'click',
				'touchstart',
				'touchmove',
			],
			legend: {
				display: true,
				labels: {
					generateLabels: () => [
						{
							fillStyle: Colors.FastSelling,
							fontColor: Colors.Font,
							strokeStyle: Colors.Stroke,
							text: 'Fast Selling',
						},
						{
							fillStyle: Colors.New,
							fontColor: Colors.Font,
							strokeStyle: Colors.Stroke,
							text: 'New',
						},
						{
							fillStyle: Colors.SlowMoving,
							fontColor: Colors.Font,
							strokeStyle: Colors.Stroke,
							text: 'Slow Moving',
						},
						{
							fillStyle: Colors.Stale,
							fontColor: Colors.Font,
							strokeStyle: Colors.Stroke,
							text: 'Stale',
						},
					],
				},
			},
			tooltip: {
				callbacks: {
					label: (ctx) =>
						`${ctx.raw.title} [Age: ${
							ctx.raw.age
						} days, Value: ${formatMerchantCurrency(ctx.raw.value)}]`,
				},
			},
		},
		clip: false,
		scales: {
			x: {
				ticks: {
					display: true,
					stepSize: AGE_STEP_SIZE,
					callback: (value, index, values) => {
						if (index === values.length - 1) {
							return '1000+';
						}
						return value;
					},
				},
				grid: {
					display: false,
				},
				title: {
					display: true,
					text: 'Days In Store',
				},
				max: suggestedMaxAge,
				min: Math.max(suggestedMinAge - buffer, 0),
			},
			y: {
				title: {
					display: true,
					text: 'Inventory Value',
				},
				grid: {
					display: false,
				},
				ticks: {
					autoSkip: false,
					callback: (value, index, values) => {
						if (
							index === values.length - 1 &&
							actualMaxValue > suggestedMaxValue
						) {
							return `${formatMerchantCurrency(value, 0)}+`;
						}
						return formatMerchantCurrency(value, 0);
					},
				},
				suggestedMin: suggestedMinValue,
				max: suggestedMaxValue,
			},
		},
	};

	return (
		<Box
			sx={{
				position: 'relative',
				height: '100%',
				minHeight: '300px',
			}}
		>
			<Chart
				type="scatter"
				data={data}
				options={options}
				plugins={[
					CanvasPlugin,
					{
						id: 'eventPlugin',
						afterEvent(chart, { event }) {
							const { ctx } = chart;
							if (event.type === 'mouseout') {
								ctx.mouseCoordinates = null;
								ctx.mouseDown = false;
								ctx.mouseUp = true;
								chart.update();
							} else if (event.type === 'mousedown') {
								ctx.clickBlocked = false;
								ctx.mouseDown = true;
								ctx.mouseUp = false;
								ctx.mouseDownSlider = false;
								const mouseDownId = Math.random();
								ctx.mouseDownId = mouseDownId;
								setTimeout(() => {
									if (
										!ctx.mouseUp &&
										!ctx.mouseDown &&
										ctx.mouseDownId === mouseDownId
									)
										ctx.clickBlocked = true;
								}, 150);
							} else if (event.type === 'mouseup') {
								ctx.mouseDown = false;
								ctx.mouseUp = true;
								chart.update();
							} else if (event.type === 'mousemove') {
								const now = Date.now();
								// Update not more frequently than every 10ms.
								// That's frequent enough, so it doesn't look laggy but doesn't cause stack overflow error
								if (
									ctx.lastMouseOverUpdate &&
									now - ctx.lastMouseOverUpdate < 100
								) {
									return;
								}
								ctx.mouseCoordinates = { x: event.x, y: event.y };
								ctx.lastMouseOverUpdate = now;
								chart.update();
							}
						},
					},
				]}
			/>
		</Box>
	);
}

PrioritiesChart.propTypes = {
	products: PropTypes.arrayOf(ProductWithPrioritiesType),
	formatMerchantCurrency: PropTypes.func.isRequired,
	onInventoryKindChanged: PropTypes.func.isRequired,
	setAgeRate: PropTypes.func.isRequired,
	setValueRate: PropTypes.func.isRequired,
	minMaxValues: PropTypes.shape({
		medianValue: PropTypes.number,
		minValue: PropTypes.number,
		maxValue: PropTypes.number,
		minAge: PropTypes.number,
		maxAge: PropTypes.number,
	}).isRequired,
	inventoryKind: PropTypes.string.isRequired,
	ageRate: PropTypes.number,
	valueRate: PropTypes.number,
};

PrioritiesChart.defaultProps = {
	products: [],
	ageRate: 0.5,
	valueRate: 0.5,
};

export default PrioritiesChart;
