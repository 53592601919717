import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';

import { capitalizeFirstLetter } from '@app/helpers/stringHelpers';
import useAuth from '@app/hooks/useAuth';
import SubscriptionText from './SubscriptionText';

function SubscriptionNavItemContent({
	setPopoverDisplayed,
	isInPopover,
}) {
	const theme = useTheme();
	const { merchant } = useAuth();

	const { billing_tier: billingTier = {} } =
		merchant.active_subscription || {};

	const billingIntervalText = React.useMemo(() => {
		if (!billingTier.billing_interval) {
			return '';
		}

		if (billingTier.billing_interval === 'annually') {
			return ' (yearly)';
		}

		return ` (${billingTier.billing_interval})`;
	}, [billingTier.billing_interval]);

	const subscriptionTitle = React.useMemo(() => {
		if (!billingTier.title) {
			return '';
		}

		return `${capitalizeFirstLetter(
			billingTier.title.toLowerCase(),
		)}${billingIntervalText}`;
	}, [billingTier.title, billingIntervalText]);

	const content = (
		<Box>
			{!isInPopover && <Divider sx={{ mt: 1 }} />}
			<NavLink
				key={`/settings/subscriptions-${subscriptionTitle}`}
				to="/settings/subscriptions"
				style={{
					textDecoration: 'none',
					color: 'inherit',
				}}
				onMouseEnter={() => setPopoverDisplayed(true)}
				onMouseLeave={() => setPopoverDisplayed(false)}
				onClick={() => setPopoverDisplayed(false)}
			>
				{({ isActive }) => (
					<ListItemButton>
						<ListItemIcon>
							<SubscriptionsIcon
								color={
									isActive && !isInPopover ? 'primary' : 'default'
								}
								sx={{
									fontSize: isInPopover ? 48 : 24,
									m: isInPopover ? 1 : 0,
								}}
							/>
						</ListItemIcon>
						<ListItemText
							primary={
								<SubscriptionText
									isInPopover={isInPopover}
									subscriptionTitle={subscriptionTitle}
								/>
							}
							primaryTypographyProps={{
								style: {
									color:
										isActive && !isInPopover
											? theme.palette.primary.main
											: '',
								},
							}}
							sx={{
								m: isInPopover ? 1 : 0,
								ml: isInPopover ? 2 : 0,
								pt: 0.5,
							}}
						/>
					</ListItemButton>
				)}
			</NavLink>
		</Box>
	);

	if (!merchant.active_subscription) {
		return (
			<Tooltip
				title="Select Your Subscription - Access Premium Features"
				componentsProps={{
					tooltip: {
						sx: {
							marginBottom: '0 !important',
						},
					},
				}}
			>
				{content}
			</Tooltip>
		);
	}

	return content;
}

SubscriptionNavItemContent.propTypes = {
	setPopoverDisplayed: PropTypes.func,
	isInPopover: PropTypes.bool,
};

SubscriptionNavItemContent.defaultProps = {
	setPopoverDisplayed: () => null,
	isInPopover: false,
};

export default SubscriptionNavItemContent;
