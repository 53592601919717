import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import ModalScreen from '@app/components/shared/modal/ModalScreen';
import RadioButtonGroup from '@app/components/shared/inputs/RadioButtonGroup';
import constants from '@app/constants/index';

import {
	useBillingTiers,
	useCancelSubscription,
	useSelectBillingTier,
} from '@app/hooks/useMerchant';
import useAuth from '@app/hooks/useAuth';
import Button from '@app/components/shared/button/Button';

const CancellationReasons = {
	doesNotMeetExpectation: 'Does not meet expectations',
	tooExpensive: 'Too expensive',
	tooComplex: 'Too complex',
	foundAlternative: 'Found alternative',
	notUsed: 'Not used anymore',
	technicalIssuesPreventFromUsing: 'Technical hurdles',
	conflictsWithAnotherApp: 'Conflicts with another app',
	other: 'Other - please specify',
};

function CancelSubscription() {
	const { activeSubscription } = useAuth();

	const { data: tiers, isLoading } = useBillingTiers();
	const {
		mutate: changeToFreeMutate,
		isLoading: changeToFreeLoading,
	} = useSelectBillingTier();

	const {
		mutate: cancelSubscription,
		isLoading: cancellationLoading,
	} = useCancelSubscription();

	const [modalOpen, setModalOpen] = React.useState(false);

	const [cancellationReason, setCancellationReason] =
		React.useState('other');
	const [cancellationFeedback, setCancellationFeedback] =
		React.useState('');

	const freeTier = React.useMemo(() => {
		if (isLoading) {
			return null;
		}

		if (!tiers) {
			return null;
		}

		if (
			activeSubscription &&
			(!activeSubscription.billing_tier.billing_interval_amount ||
				parseFloat(
					activeSubscription.billing_tier.billing_interval_amount,
				) === 0)
		) {
			return null;
		}

		return tiers.find(
			(tier) =>
				!tier.billing_interval_amount ||
				parseFloat(tier.billing_interval_amount) === 0,
		);
	}, [isLoading, tiers, activeSubscription]);

	const switchToFreeTier = React.useCallback(() => {
		changeToFreeMutate(
			{
				tierId: freeTier.id,
				dealId: freeTier.deals[0]?.id,
				prevCancellationReason: cancellationReason,
				prevCancellationFeedback: cancellationFeedback,
			},
			{
				onSuccess: ({ confirmationUrl }) => {
					if (confirmationUrl) {
						window.open(confirmationUrl, '_blank');
					}
					setModalOpen(false);
				},
			},
		);
	}, [
		changeToFreeMutate,
		cancellationReason,
		cancellationFeedback,
		freeTier,
	]);

	const handleCancelSubscription = async () => {
		await cancelSubscription({
			cancellationReason,
			cancellationFeedback,
		});
		setModalOpen(false);
	};

	return (
		<>
			<ModalScreen open={modalOpen} handleModal={setModalOpen}>
				<Box sx={{ width: '40vw', minWidth: '680px' }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'stretch',
						}}
					>
						<RadioButtonGroup
							name="inventory"
							value={cancellationReason}
							handleChange={(_, value) =>
								setCancellationReason(value)
							}
							formLabelText="Reason"
							sx={{ width: 'fit-content', whiteSpace: 'nowrap' }}
							radios={[
								...Object.entries(CancellationReasons).map(
									([value, label]) => ({
										label,
										value,
									}),
								),
							]}
						/>
						<Box
							sx={{
								flexGrow: 1,
							}}
						>
							<FormLabel
								htmlFor="cancel-subscription-feedback"
								sx={{ color: constants.colors.textMain }}
							>
								<Typography>
									Please share some feedback{' '}
									<Typography component="span" color="red">
										*
									</Typography>
								</Typography>
							</FormLabel>
							<TextField
								id="cancel-subscription-feedback"
								rows={13}
								multiline
								value={cancellationFeedback}
								onChange={(event) =>
									setCancellationFeedback(event.target.value)
								}
								sx={{
									width: '100%',
								}}
							/>
						</Box>
					</Box>
					<Box sx={{ display: 'flex', gap: 2, float: 'right' }}>
						{freeTier ? (
							<Button
								onClick={switchToFreeTier}
								loading={changeToFreeLoading}
								disabled={!cancellationFeedback}
							>
								Change to Free Tier
							</Button>
						) : null}
						<Button
							variant="outlined"
							loading={cancellationLoading}
							disabled={!cancellationFeedback}
							onClick={handleCancelSubscription}
						>
							Cancel
						</Button>
					</Box>
				</Box>
			</ModalScreen>

			<Box sx={{ textAlign: 'center', mt: 2 }}>
				<Typography color="text.secondary">
					To cancel your subscription,{' '}
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<Typography
						component="span"
						style={{
							cursor: 'pointer',
							color: 'primary',
							textDecoration: 'underline',
						}}
						onClick={() => setModalOpen(true)}
						role="button"
					>
						click here
					</Typography>
				</Typography>
			</Box>
		</>
	);
}

export default CancelSubscription;
