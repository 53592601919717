import React from 'react';
import PropTypes from 'prop-types';

import { useEventBus } from './useEventBus';
import * as events from '@app/constants/eventConstants';

// Define the context
const SearchParamsContext = React.createContext();

const defaultState = {
	page: 1,
	filterLists: [],
	search: null,
	sort: ['start_date', 'DESC'],
	state: ['active'],
};
// Create a provider for this context
function SearchParamsProvider({ children }) {
	const { subscribe, unsubscribe } = useEventBus();

	const [searchParams, setSearchParams] =
		React.useState(defaultState);

	const setSearchParamsAutoSpread = React.useCallback((newParams) => {
		setSearchParams((prevState) => ({ ...prevState, ...newParams }));
	}, []);

	React.useEffect(() => {
		const clearSearchParams = () => {
			setSearchParams(defaultState);
		};

		// Clear state when changing shops or logging out
		subscribe(events.LOGOUT_EVENT, clearSearchParams);
		subscribe(events.ACTIVE_SHOP_CHANGED_EVENT, clearSearchParams);

		return () => {
			unsubscribe(events.LOGOUT_EVENT, clearSearchParams);
			unsubscribe(
				events.ACTIVE_SHOP_CHANGED_EVENT,
				clearSearchParams,
			);
		};
	}, [subscribe, unsubscribe]);

	const value = React.useMemo(
		() => ({
			searchParams,
			setSearchParams: setSearchParamsAutoSpread,
		}),
		[searchParams, setSearchParamsAutoSpread],
	);

	return (
		<SearchParamsContext.Provider value={value}>
			{children}
		</SearchParamsContext.Provider>
	);
}

SearchParamsProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]).isRequired,
};

export default SearchParamsProvider;

// A hook to use the context
export function useSearchParams() {
	const context = React.useContext(SearchParamsContext);
	if (!context) {
		throw new Error(
			'useSearchParams must be used within a SearchParamsProvider',
		);
	}
	return context;
}
