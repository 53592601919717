import React from 'react';
import InfoPopover from '@app/components/shared/ui/InfoPopoverIcon';

function WrongCounterValuePopover() {
	return (
		<InfoPopover
			type="warning"
			popoverVerticalPlacement="top"
			iconStyles={{
				fontSize: 20,
				mt: 0.3,
			}}
			textStyles={{
				fontSize: 14,
				width: '450px',
				maxWidth: '450px',
			}}
		>
			Your &apos;Name Your Price&apos; settings might result in prices
			exceeding the current retail price. Please ensure that you have
			the correct discount applied.
		</InfoPopover>
	);
}

export default WrongCounterValuePopover;
