import React from 'react';

import { updateMerchant } from '@app/api/merchant';
import useAuth from '@app/hooks/useAuth';
import { successHandler } from '@app/lib/SnackbarUtils';
import SettingCheckbox from '../components/settingCheckbox/SettingCheckbox';

function RecalculateTaxes() {
	const { merchant, setMerchant } = useAuth();
	const [error, setError] = React.useState('');

	const handleRecalculateTaxesChange = React.useCallback(
		async (e) => {
			try {
				const { checked: nyopRecalculateTaxes } = e.target;
				setMerchant((prevMerchant) => ({
					...prevMerchant,
					nyop_recalculate_taxes: nyopRecalculateTaxes,
				}));
				await updateMerchant({
					fields: {
						nyop_recalculate_taxes: nyopRecalculateTaxes,
					},
				});
				setError('');
				successHandler();
			} catch (err) {
				console.error(err);
				setError(err.message);
			}
		},
		[setMerchant],
	);

	return (
		<SettingCheckbox
			title="Tax-Inclusive Pricing"
			subtitle="Add tax to your counter offer amounts or leave them tax inclusive."
			checkboxText="Include taxes in pricing"
			checked={merchant?.nyop_recalculate_taxes}
			onChange={handleRecalculateTaxesChange}
			error={error}
		/>
	);
}

export default RecalculateTaxes;
