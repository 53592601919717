import PropTypes from 'prop-types';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import ProductTable from './productTable';
import NyopOffersInboxTable from '@app/pages/nyop/inbox/table';

function Table({ campaign }) {
	const [tableView, setTableView] = React.useState('products');

	return (
		<TabContext value={tableView}>
			<TabList
				onChange={(_, newValue) => setTableView(newValue)}
				sx={{ mb: 3 }}
			>
				<Tab value="products" label="Products" />
				<Tab value="inbox" label="Inbox" />
			</TabList>
			<TabPanel value="products">
				<ProductTable campaign={campaign} />
			</TabPanel>
			<TabPanel value="inbox">
				<NyopOffersInboxTable
					createdAfter={new Date('0001-01-01').toISOString()}
					campaignId={campaign.id}
				/>
			</TabPanel>
		</TabContext>
	);
}

Table.propTypes = {
	campaign: PropTypes.shape({
		id: PropTypes.string.isRequired,
		end_date: PropTypes.string.isRequired,
	}).isRequired,
};

export default Table;
