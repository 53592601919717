import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import * as sellingPlanApi from '../api/sellingPlanGroups';
import { getGqlId } from '../lib/shopify';
import constants from '../constants/index';

export const CACHE_KEY = 'sellingPlanGroups';

export function useSellingPlanGroups({
	active = false,
	type = null,
	configOpts = {},
} = {}) {
	return useQuery(
		[CACHE_KEY, type, 'list'],
		async () => sellingPlanApi.get({ active, type }),
		{ ...configOpts },
	);
}

export function useSellingPlanGroup({ id, type, configOpts = {} }) {
	const queryClient = useQueryClient();

	const shopifyId = getGqlId(
		id,
		constants.shopifyObjectPrefix.SELLING_PLAN_GROUP,
	);

	return useQuery(
		[CACHE_KEY, shopifyId],
		async () => sellingPlanApi.getById({ id: shopifyId, type }),
		{
			initialData: () => {
				const data = queryClient
					.getQueryData([CACHE_KEY, 'list'])
					?.data?.find((spg) => spg?.id === id);
				if (!data || data?.variants) return undefined;
				return { data };
			},
			...configOpts,
		},
	);
}

export function useSellingPlanGroupsSummary({
	configOpts = {},
} = {}) {
	return useQuery({
		queryKey: [CACHE_KEY],
		queryFn: () => sellingPlanApi.getSummary(),
		...configOpts,
	});
}

export function useSellingPlanGroupUpdate({ id, configOpts = {} }) {
	const queryClient = useQueryClient();

	return useMutation(sellingPlanApi.update, {
		onSettled: () => queryClient.invalidateQueries([CACHE_KEY, id]),
		...configOpts,
	});
}

export function useSellingPlanGroupCreate({ configOpts = {} } = {}) {
	const queryClient = useQueryClient();

	return useMutation(sellingPlanApi.create, {
		onSettled: () =>
			queryClient.invalidateQueries([CACHE_KEY, 'list']),
		...configOpts,
	});
}

/* eslint-disable no-unused-vars */
export function useSellingPlanGroupDelete({ id, configOpts = {} }) {
	const queryClient = useQueryClient();

	// onSettled used to invalidate just cache key for that ID but it
	// doesn't reload the list in table so instead invalidate everything
	return useMutation(sellingPlanApi.del, {
		onSettled: () =>
			queryClient.invalidateQueries([CACHE_KEY, 'list']),
		...configOpts,
	});
}
