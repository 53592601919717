import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import { useMarketplaceProductsContext } from '@app/pages/marketplace/supplier/context/useMarketProductsContext';

function VariantCheckboxInput({ productId, variantId, disabled }) {
	const { updateVariantEnabled, getVariantConfig } =
		useMarketplaceProductsContext();

	const config = getVariantConfig({ productId, variantId });

	return (
		<Checkbox
			checked={config.enabled || false}
			onClick={(event) => event.stopPropagation()}
			onChange={(event) => {
				updateVariantEnabled({
					variantId,
					productId,
					enabled: event.target.checked,
				});
			}}
			disabled={disabled}
		/>
	);
}

VariantCheckboxInput.propTypes = {
	productId: PropTypes.string.isRequired,
	variantId: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
};

VariantCheckboxInput.defaultProps = {
	disabled: false,
};

export default VariantCheckboxInput;
