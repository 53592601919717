import { useQuery } from '@tanstack/react-query';

import * as variantApi from '../api/variants';

export const CACHE_KEY = 'variants';

export function useVariants({
	cacheKey,
	queryVariables,
	configOpts = {},
}) {
	return useQuery({
		queryKey: [CACHE_KEY, cacheKey],
		queryFn: () => variantApi.getVariants(queryVariables),
		keepPreviousData: true,
		...configOpts,
	});
}
