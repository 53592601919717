import React from 'react';
import useAuth from '@app/hooks/useAuth';
import AuxiliaryWarningAlert from '../shared/alert/auxiliaryWarningAlert';
import ProductSyncInProgressAlert from '../shared/alert/productSyncInProgressMessage';
import SubscriptionsWarningAlert from '../shared/alert/subscriptionsWarningAlert';

const WARNING_PRIORITIES = {
	NO_WARNING: 0,
	SUBSCRIPTIONS: 1,
	AUXILIARY: 2,
	SYNC: 3,
};

function Warnings() {
	const { merchant } = useAuth();
	const [currentWarning, setCurrentWarning] = React.useState(
		WARNING_PRIORITIES.NO_WARNING,
	);

	const handleChangeCurrentWarning = React.useCallback(
		(newPriority) => (isOpen) => {
			setCurrentWarning((prevWarning) => {
				// if setOpen(false)
				if (!isOpen) {
					// if we want to close the current warning
					if (prevWarning === newPriority) {
						return WARNING_PRIORITIES.NO_WARNING;
					}

					return prevWarning;
				}

				// set new warning if newPriority is more important than the current one
				if (
					prevWarning === WARNING_PRIORITIES.NO_WARNING ||
					prevWarning > newPriority
				) {
					return newPriority;
				}

				return prevWarning;
			});
		},
		[],
	);

	if (!merchant) {
		return null;
	}

	return (
		<>
			<AuxiliaryWarningAlert
				setOpen={handleChangeCurrentWarning(
					WARNING_PRIORITIES.AUXILIARY,
				)}
				open={currentWarning === WARNING_PRIORITIES.AUXILIARY}
			/>
			<ProductSyncInProgressAlert
				setOpen={handleChangeCurrentWarning(WARNING_PRIORITIES.SYNC)}
				open={currentWarning === WARNING_PRIORITIES.SYNC}
			/>
			<SubscriptionsWarningAlert
				setOpen={handleChangeCurrentWarning(
					WARNING_PRIORITIES.SUBSCRIPTIONS,
				)}
				open={currentWarning === WARNING_PRIORITIES.SUBSCRIPTIONS}
			/>
		</>
	);
}

export default Warnings;
