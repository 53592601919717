import * as React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../../loaders/CircularLoader';

import {
	CACHE_KEY as PREORDER_CACHE_KEY,
	useSellingPlanGroupDelete,
} from '../../../../hooks/useSellingPlanGroups';
import { CACHE_KEY as VARIANTS_CACHE_KEY } from '../../../../hooks/useVariants';
import Modal from '../../modal/Modal';
import ConfirmationModal from '../../modal/ConfirmationModal';

import constants from '../../../../constants';
import {
	CACHE_KEY as NYOP_CACHE_KEY,
	useNyopCampaignDelete,
} from '../../../../hooks/useNyopCampaign';
import Button from '../../button/Button';

function DeleteButton({
	sellingPlanGroupId,
	disabled,
	type,
	buttonText,
	size,
	onSuccess,
}) {
	const [modalVisible, setModalVisible] = React.useState(false);

	const queryClient = useQueryClient();
	const {
		mutate: preorderMutate,
		isLoading: preorderLoading,
		isError: preorderError,
	} = useSellingPlanGroupDelete({
		id: sellingPlanGroupId,
	});
	const {
		mutate: nyopMutate,
		isLoading: nyopLoading,
		isError: nyopError,
	} = useNyopCampaignDelete({ id: sellingPlanGroupId });

	const getButtonColor = () => {
		if (
			(type === constants.campaignTypes.PREORDER && preorderError) ||
			(type === constants.campaignTypes.NYOP && nyopError)
		)
			return 'error';
		if (disabled) return 'text.disabled';
		return 'success';
	};

	const onDeleteConfirm = () => {
		if (type === constants.campaignTypes.PREORDER) {
			preorderMutate(
				{ id: sellingPlanGroupId },
				{
					onSuccess: () => {
						queryClient.invalidateQueries({
							predicate: (query) =>
								[VARIANTS_CACHE_KEY, PREORDER_CACHE_KEY].includes(
									query?.queryKey[0],
								),
						});
						setModalVisible(false);
					},
				},
			);
			return;
		}
		if (type === constants.campaignTypes.NYOP) {
			nyopMutate(
				{ id: sellingPlanGroupId },
				{
					onSuccess: () => {
						queryClient.invalidateQueries({
							predicate: (query) =>
								[VARIANTS_CACHE_KEY, NYOP_CACHE_KEY].includes(
									query?.queryKey[0],
								),
						});
						setModalVisible(false);
					},
				},
			);
		}
	};

	return (
		<>
			<Modal open={modalVisible} handleModal={setModalVisible}>
				<ConfirmationModal
					buttonText="Yes"
					cancelButtonText="No"
					handleClick={() => {
						if (onSuccess) {
							onSuccess();
						}
						onDeleteConfirm();
					}}
					handleCancelClick={() => setModalVisible(false)}
					prompt="Are you sure you want to cancel this campaign?”"
					subPrompt={
						type === constants.campaignTypes.NYOP
							? 'Confirming this will end the campaign and prevent shoppers from making new offers. Please note that existing orders will not be automatically cancelled and must be manually cancelled in the Shopify Admin if you choose do not wish to fulfill them.'
							: ''
					}
					isError={preorderError}
				/>
			</Modal>
			{type === 'preorder' && (
				<IconButton
					aria-label="End Campaign"
					onClick={() => setModalVisible(true)}
					disabled={disabled}
				>
					{preorderLoading ? (
						<Loader />
					) : (
						<CloseIcon color={getButtonColor()} />
					)}
				</IconButton>
			)}
			{type === 'nyop' && (
				<Button
					disabled={disabled}
					variant="outlined"
					color="error"
					size={size}
					loading={nyopLoading}
					onClick={() => setModalVisible(true)}
					sx={{ width: '100%' }}
				>
					{buttonText}
				</Button>
			)}
		</>
	);
}

DeleteButton.propTypes = {
	sellingPlanGroupId: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	type: PropTypes.string,
	buttonText: PropTypes.string,
	size: PropTypes.string,
	onSuccess: PropTypes.func,
};
DeleteButton.defaultProps = {
	disabled: false,
	type: constants.campaignTypes.PREORDER,
	buttonText: 'Cancel',
	size: 'medium',
	onSuccess: null,
};

export default DeleteButton;
