import * as React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@app/components/shared/button/Button';

// TODO: Copy pasted this duplicate from original customer portal refactor to remove redundancy
function CustomerUpdatePayment({ token }) {
	const { mutate, data, isLoading, isError } = useMutation({
		mutationFn: async () =>
			axios.get('/api/customers/update-payment-method', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}),
	});

	const handleClick = () => mutate();

	return (
		<Box>
			<Button
				loading={isLoading}
				color="secondary"
				onClick={handleClick}
			>
				Update Payment Method
			</Button>
			{isError && (
				<Typography color="error.main" mt={2}>
					An error occurred. Could not send update email.
				</Typography>
			)}
			{data?.data?.data?.success && (
				<Typography mt={2}>
					Email sent to {data?.data?.data?.email}. Check your inbox to
					update your payment information.
				</Typography>
			)}
		</Box>
	);
}

CustomerUpdatePayment.propTypes = {
	token: PropTypes.string,
};
CustomerUpdatePayment.defaultProps = {
	token: '',
};

export default CustomerUpdatePayment;
