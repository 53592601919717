import React from 'react';
import { useParams } from 'react-router-dom';
import CreateNyopCampaignForm from '@app/pages/createCampaign/Nyop';
import { useNyopCampaign } from '@app/hooks/useNyopCampaign';
import Loader from '@app/components/shared/loaders/CircularLoader';
import { useInventoryReviewContext } from '@app/hooks/useInventoryReviewContext';
import { mergeAutomationRulesWithVariants } from '../createCampaign/helpers';

function EditCampaign() {
	const [, setSelectedVariants] = useInventoryReviewContext();
	const { id: sellingPlanGroupId } = useParams();
	const { data: nyopCampaign, isLoading: nyopLoading } =
		useNyopCampaign({ id: sellingPlanGroupId });

	const defaultSelectedVariants = React.useMemo(() => {
		if (nyopLoading) {
			return [];
		}

		return mergeAutomationRulesWithVariants({
			variants: nyopCampaign?.data?.variants,
			variantAutomationRules:
				nyopCampaign?.data?.campaign?.automation_rules?.variants,
		});
	}, [
		nyopCampaign?.data?.variants,
		nyopCampaign?.data?.campaign?.automation_rules,
		nyopLoading,
	]);

	React.useEffect(() => {
		setSelectedVariants(defaultSelectedVariants);
	}, [setSelectedVariants, defaultSelectedVariants]);

	if (nyopLoading) {
		return <Loader fullPage />;
	}

	return (
		<CreateNyopCampaignForm
			defaultCampaign={nyopCampaign?.data}
			onCampaignTypeChange={() => null}
			onCompleteNavigate=".."
		/>
	);
}

export default EditCampaign;
