import * as React from 'react';
import PropTypes from 'prop-types';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import ConfirmationModal from '@app/components/shared/modal/ConfirmationModal';

// TODO: Remove duplicates from old customer portal component refactor DRY
function CancellationModal({ order, token, handleModal }) {
	const queryClient = useQueryClient();
	const { mutate, isLoading, isError, error } = useMutation({
		mutationFn: async () =>
			axios.put(
				`/api/customers/${order.id}/cancel`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			),
		onSuccess: (payload) => {
			// Want to optimistically set the order since webhooks may
			// not synchronize immediately.
			queryClient.setQueryData(['customer', token], (oldData) => {
				if (payload?.data?.order) {
					const newData = {
						...oldData,
						data: {
							...oldData.data,
							order: payload.data.order,
						},
					};
					return newData;
				}
				return oldData;
			});

			handleModal(false);
		},
	});

	const handleClick = () => {
		mutate();
	};

	return (
		<ConfirmationModal
			handleClick={handleClick}
			prompt="Are you sure you want to cancel this order?"
			subPrompt="This action cannot be undone."
			textBullets={[
				{
					text: 'Your order will be cancelled',
				},
				{
					text: 'No items in your order will be shipped',
				},
				{
					text: 'Any payments made will be refunded',
				},
				{
					text: 'Any remaining balance will not be charged',
				},
			]}
			buttonText="Cancel Order"
			isLoading={isLoading}
			isError={isError}
			errorMessage={error?.data?.message}
		/>
	);
}

CancellationModal.propTypes = {
	order: PropTypes.shape({
		// Getting from DB vs dynamic payload makes ID either or
		// TODO: Why this discrepancy?
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		admin_graphql_api_id: PropTypes.string,
	}),
	token: PropTypes.string,
	handleModal: PropTypes.func.isRequired,
};

CancellationModal.defaultProps = {
	order: {},
	token: '',
};

export default CancellationModal;
