import * as React from 'react';

import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MetricWidget from '@app/components/shared/ui/MetricWidget';
import ProgressBar from '@app/components/shared/loaders/ProgressBar';
import constants from '@app/constants/index';
import OffersChart from '@app/components/charts/OffersChart';
import { VariantType } from '@app/propTypes/types';
import useAuth from '@app/hooks/useAuth';

function NYOPVariantSummary({ variant, offers }) {
	const { formatMerchantCurrency } = useAuth();

	return (
		<Box sx={{ width: '100%' }}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={8}>
					<Box sx={{ height: '100%' }}>
						<Card sx={{ height: '100%', p: 1 }}>
							<OffersChart
								offers={offers}
								formatMerchantCurrency={formatMerchantCurrency}
							/>
						</Card>
					</Box>
				</Grid>

				<Grid item md={4} xs={12}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 1,
							height: '100%',
						}}
					>
						<MetricWidget
							name="MSRP"
							value={formatMerchantCurrency(
								variant.msrp || variant.price,
							)}
						/>
						<MetricWidget
							name="Current Price"
							value={formatMerchantCurrency(variant.price)}
						/>

						<MetricWidget
							name="Execution Progress"
							value={
								<Box sx={{ width: '100%' }}>
									<ProgressBar
										data={[
											{
												label: 'Accepted offers units',
												value: variant.summary.convertedUnits,
												color: constants.colors.primary,
											},
											{
												label: 'Countered offers units',
												value: variant.summary.counteredUnits,
												color: constants.colors.tertiary,
											},
											{
												label: 'Canceled offers units',
												value: variant.summary.lostUnits,
												color: constants.colors.failure,
											},
										]}
										total={variant.summary.totalUnits}
										showLegend
										legendInPercentage
									/>
								</Box>
							}
						/>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

NYOPVariantSummary.propTypes = {
	variant: VariantType.isRequired,
	offers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			offer_amount: PropTypes.string.isRequired,
			counter_offer_amount: PropTypes.string,
			accepted_at: PropTypes.string,
			counter_offer_created_at: PropTypes.string,
			counter_offer_accepted_at: PropTypes.string,
			canceled_at: PropTypes.string,
		}),
	).isRequired,
};

export default NYOPVariantSummary;
