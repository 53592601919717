import * as React from 'react';

import { Box } from '@mui/material';

import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import ErrorMessage from '../../components/shared/ui/ErrorMessage';
import useForm from '../../hooks/useForm';
import useAuth from '../../hooks/useAuth';
import PasswordInput from '../../components/shared/inputs/PasswordInput';
import SnackBarUtils from '../../lib/SnackbarUtils';
import { GA_LABEL } from '../../constants/googleAnalytics';
import Button from '@app/components/shared/button/Button';

const propTypes = {
	token: PropTypes.string,
};

const defaultProps = {
	token: null,
};

function ResetPasswordBeginForm({ token }) {
	const { resetPasswordComplete, loading, error } = useAuth();

	const navigate = useNavigate();

	const {
		handleSubmit,
		handleChange,
		data,
		errors: formErrors,
	} = useForm({
		initialValues: {
			password: '',
		},
		validations: {
			password: {
				required: {
					value: true,
					message: 'Please provide a new password.',
				},
				custom: {
					isValid: (value) => value.length >= 8 && value.length < 64,
					message:
						'Password must be between 8 and 64 characters long',
				},
			},
		},
		onSubmit: async () => {
			const isSuccessful = await resetPasswordComplete({
				password: data?.password,
				token,
			});
			if (isSuccessful) {
				SnackBarUtils.success('Password has been reset');
				navigate('/login');
			}
		},
	});

	return (
		<Box
			component="form"
			onSubmit={handleSubmit}
			noValidate
			sx={{ mt: 1 }}
		>
			<PasswordInput
				data-ga-label={GA_LABEL.RESET_PASSWORD_INPUT}
				id="password-reset-form"
				name="password"
				label="New Password"
				onChange={handleChange('password')}
				value={data.password}
				autoComplete="off"
				required
			/>
			{formErrors?.password && (
				<ErrorMessage message={formErrors?.password} />
			)}
			<Button
				fullWidth
				sx={{ mt: 3, mb: 2 }}
				disabled={!data.password}
				onClick={handleSubmit}
				loading={loading}
			>
				Complete
			</Button>
			{error && <Typography color="error">{error}</Typography>}
		</Box>
	);
}

ResetPasswordBeginForm.propTypes = propTypes;
ResetPasswordBeginForm.defaultProps = defaultProps;

export default ResetPasswordBeginForm;
