import axios from './index';
import * as urlHelpers from '../helpers/urlHelpers';

export const updateMerchant = async ({ fields }) => {
	const response = await axios.put(`/api/merchants`, {
		fields,
	});
	return response.data;
};

export const powerUpBilling = async () => {
	const response = await axios.post(
		`/api/merchants/billing/power-up`,
	);
	return response.data;
};

export const getBillingTransactions = async ({
	page,
	perPage,
	sortKey,
	getCount,
}) => {
	const queryString = urlHelpers.cleanAndBuildUrlQueryParams({
		page,
		perPage,
		sortKey,
		getCount,
	});

	const response = await axios.get(
		`/api/merchants/billing/transactions?${queryString}`,
	);
	return response.data;
};

export const getBillingBalance = async () => {
	const response = await axios.get(`/api/merchants/billing/balance`);
	return response.data;
};

export const getIntegrations = async () => {
	const response = await axios.get(`/api/merchants/integrations`);
	return response.data;
};

export const getIntegration = async (integration) => {
	const response = await axios.get(
		`/api/merchants/integrations/${integration}?full=true`,
	);
	return response.data;
};

export const createIntegration = async ({
	integration,
	settings,
}) => {
	const response = await axios.post(`/api/merchants/integrations`, {
		integration,
		settings,
	});
	return response.data;
};

export const updateIntegration = async ({
	integration,
	settings,
}) => {
	const response = await axios.put(`/api/merchants/integrations`, {
		integration,
		settings,
	});
	return response.data;
};

export const getProductSync = async () => {
	const response = await axios.get(`/api/merchants/product-sync`);
	return response.data;
};

export const startProductSync = async () => {
	const response = await axios.post(`/api/merchants/product-sync`);
	return response.data;
};

export const updateNyopCounterOfferItemSku = async ({ value }) => {
	const response = await axios.post(
		`/api/merchants/nyop/counter-offer-item-sku`,
		{ value },
	);
	return response.data;
};

export const getBillingTiers = async () => {
	const response = await axios.get(`/api/merchants/billing/tiers`);
	return response.data;
};

export const setBillingTierByChargeId = async ({ chargeId }) => {
	const response = await axios.put(
		`/api/merchants/billing/tiers/${chargeId}/set`,
	);
	return response.data;
};

export const selectBillingTier = async ({
	tierId,
	dealId,
	prevCancellationReason,
	prevCancellationFeedback,
	shouldCancelPreviousSubscription,
}) => {
	const body = {
		dealId,
		prevCancellationReason,
		prevCancellationFeedback,
		shouldCancelPreviousSubscription,
	};
	const response = await axios.post(
		`/api/merchants/billing/tiers/${tierId}/select`,
		body,
	);
	return response.data;
};

export const cancelSubscription = async ({
	cancellationReason,
	cancellationFeedback,
}) => {
	const body = {
		cancellationReason,
		cancellationFeedback,
	};
	const response = await axios.post(
		`/api/merchants/billing/subscriptions/cancel`,
		body,
	);
	return response.data;
};

export const getMaxOffersPerOrder = async () => {
	const response = await axios.get(
		`/api/merchants/max-offers-per-order`,
	);
	return response.data;
};

export const setMaxOffersPerOrder = async ({ maxOffersPerOrder }) => {
	const response = await axios.post(
		`/api/merchants/max-offers-per-order`,
		{ maxOffersPerOrder },
	);
	return response.data;
};
export const getCounterOfferExpiryDays = async () => {
	const response = await axios.get(
		`/api/merchants/nyop/counter-offer-expiry-days`,
	);
	return response.data;
};

export const setCounterOfferExpiryDays = async ({
	counterOfferExpiryDays,
}) => {
	const response = await axios.post(
		`/api/merchants/nyop/counter-offer-expiry-days`,
		{ value: counterOfferExpiryDays },
	);
	return response.data;
};

export default updateMerchant;
