import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import InfoPopover from '@app/components/shared/ui/InfoPopoverIcon';

function CustomColumnLabel({ title, infoContent }) {
	return (
		<TableCell
			sx={{
				display: 'table-cell',
				alignItems: 'center',
				position: 'relative',
				gap: 0.5,
				borderBottom: '1px solid rgba(224, 224, 224, 1)',
			}}
		>
			<Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
				<Typography
					sx={{
						fontSize: '0.875rem',
						fontWeight: 500,
						overFlow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					{title}
				</Typography>
				{infoContent ? (
					<InfoPopover
						popoverVerticalPlacement="bottom"
						popoverHorizontalPlacement="right"
						iconStyles={{ fontSize: 16 }}
						textStyles={{
							fontSize: 14,
							width: '450px',
							maxWidth: '450px',
						}}
					>
						{infoContent}
					</InfoPopover>
				) : null}
			</Box>
		</TableCell>
	);
}

CustomColumnLabel.propTypes = {
	title: PropTypes.string.isRequired,
	infoContent: PropTypes.node,
};

CustomColumnLabel.defaultProps = {
	infoContent: null,
};

export default CustomColumnLabel;
