import PropTypes from 'prop-types';

import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { useNyopCampaignEnd } from '@app/hooks/useNyopCampaign';
import Button from '@app/components/shared/button/Button';

function EndAction({ campaignId, onSuccess, searchParams }) {
	const {
		mutate,
		error,
		isSuccess,
		isLoading: loading,
	} = useNyopCampaignEnd({
		id: campaignId,
		searchParams,
	});

	React.useEffect(() => {
		if (isSuccess) {
			onSuccess();
		}
	}, [onSuccess, isSuccess]);

	const handleSubmit = () => mutate({ id: campaignId });

	return (
		<Box>
			<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
				Are you sure you want to end this campaign earlier?
			</Typography>
			<List>
				<ListItemText
					primary="Customers won't be able to buy any more products with this campaign."
					sx={{
						padding: 0,
						marginLeft: '20px',
						listStyleType: 'disc',
						display: 'list-item',
					}}
				/>
				<ListItemText
					primary="You will have three days from now to accept or counter outstanding offers."
					sx={{
						padding: 0,
						marginLeft: '20px',
						listStyleType: 'disc',
						display: 'list-item',
					}}
				/>
			</List>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
			>
				<Button
					type="submit"
					loading={loading}
					onClick={handleSubmit}
				>
					END EARLY
				</Button>

				{error && (
					<Typography color="error">{error.message}</Typography>
				)}
			</Box>
		</Box>
	);
}

EndAction.propTypes = {
	campaignId: PropTypes.string.isRequired,
	onSuccess: PropTypes.func.isRequired,
	searchParams: PropTypes.shape({
		page: PropTypes.number,
		search: PropTypes.string,
		sort: PropTypes.arrayOf(PropTypes.string),
		state: PropTypes.arrayOf(PropTypes.string),
	}),
};

EndAction.defaultProps = { searchParams: {} };

export default EndAction;
