import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ProductThumbnail from '@app/components/shared/ui/ProductThumbnail';

import { localizeCurrency } from '@app/helpers/localization.helpers';

import { OrderType, VariantType } from '@app/propTypes/types';

function ListItem({
	order,
	variant = {},
	offerPrice,
	originalPrice,
	quantity,
	rowHeight,
	secondaryContent,
	declined,
	acceptAllChecked,
	counterOfferTaxes,
	disabled,
	soldOut = false,
}) {
	const isTextDisabled = declined && !acceptAllChecked;
	let effectiveDiscount = null;

	if (offerPrice && originalPrice) {
		effectiveDiscount = Math.round(
			(1 - offerPrice / originalPrice) * 100,
		);
	}
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'stretch',
				position: 'relative',
				minHeight: rowHeight,
				backgroundColor: isTextDisabled
					? 'background.disabled'
					: 'background.default',
				color: isTextDisabled ? 'text.disabled' : 'text.primary',
				transition: 'color 0.3s ease, background-color 0.3s ease',
			}}
		>
			<Box
				marginRight={2}
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<ProductThumbnail
					variant={variant}
					size={rowHeight}
					sx={{ ...(disabled ? { filter: 'grayscale(0.8)' } : null) }}
					customerPortal
				/>
			</Box>

			<Box
				sx={{
					flexGrow: 1,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'baseline',
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					<Typography fontWeight="500" sx={{ pr: { xs: 0, sm: 5 } }}>
						{variant.full_title || variant.title}
					</Typography>
					<Box
						sx={{
							mt: { xs: 1, sm: 0 },
							mb: { xs: 1, sm: 0 },
							alignSelf: 'flex-start',
							textAlign: { xs: 'left', sm: 'right' },
						}}
					>
						<Typography
							variant="h6"
							fontWeight="bold"
							sx={{
								textDecoration: isTextDisabled
									? 'line-through'
									: 'none',
								transition: 'textDecoration 0.3s ease',
								fontSize: 'inherit', // Needed to keep inherited font size from parent
								display: 'inline-flex',
								alignItems: 'center',
								whiteSpace: 'nowrap',
							}}
						>
							{localizeCurrency({
								customerLocale: order?.customer_locale,
								currencyCode:
									order?.subtotal_price_set?.presentment_money
										?.currency_code,
								amount: soldOut
									? originalPrice
									: offerPrice || originalPrice,
							})}
							{quantity > 1 && (
								<Typography
									variant="string"
									color="text.secondary"
									fontStyle="italic"
									component="span"
									sx={{
										display: 'inline',
										transition: 'textDecoration 0.3s ease',
										ml: 0.25, // TODO: Strike through has break with margin but this collapses all whitespace - remove gap
									}}
								>
									x {quantity}
								</Typography>
							)}
						</Typography>
						{effectiveDiscount ? (
							<Typography
								color="text.disabled"
								fontStyle="italic"
								sx={{ whiteSpace: 'nowrap' }}
							>
								{soldOut ? null : `${effectiveDiscount}% off`}
							</Typography>
						) : null}
						{counterOfferTaxes && quantity ? (
							<Typography
								color="text.disabled"
								fontStyle="italic"
								sx={{ whiteSpace: 'nowrap' }}
							>
								Taxes:{' '}
								{localizeCurrency({
									customerLocale: order?.customer_locale,
									currencyCode:
										order?.subtotal_price_set?.presentment_money
											?.currency_code,
									amount: counterOfferTaxes / quantity,
								})}{' '}
								* {quantity}
							</Typography>
						) : null}
					</Box>
				</Box>
				<Box flexGrow={1} />
				{secondaryContent}
			</Box>
		</Box>
	);
}

ListItem.propTypes = {
	order: OrderType.isRequired,
	variant: VariantType.isRequired,
	offerPrice: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
	]),
	originalPrice: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
	]).isRequired,
	rowHeight: PropTypes.shape({
		height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}).isRequired,
	secondaryContent: PropTypes.node,
	declined: PropTypes.bool,
	quantity: PropTypes.number,
	acceptAllChecked: PropTypes.bool,
	counterOfferTaxes: PropTypes.string,
	disabled: PropTypes.bool,
	soldOut: PropTypes.bool,
};

ListItem.defaultProps = {
	offerPrice: null,
	declined: false,
	secondaryContent: <Box />, // Empty div preserves flexbox behavior
	quantity: 0,
	acceptAllChecked: false,
	counterOfferTaxes: null,
	disabled: null,
};

export default ListItem;
