import * as React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

function EllipsesTypography({ children, lines, sx, ...rest }) {
	const textRef = React.useRef();
	const [showTooltip, setShowTooltip] = React.useState(null);

	React.useEffect(() => {
		const textElement = textRef.current;
		const hasOverflowingChildren =
			textElement.offsetHeight < textElement.scrollHeight ||
			textElement.offsetWidth < textElement.scrollWidth;
		setShowTooltip(hasOverflowingChildren);
	}, [textRef]);

	return (
		<Tooltip title={showTooltip ? children : ''} arrow>
			<Typography
				sx={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					display: '-webkit-box',
					WebkitLineClamp: `${lines}`,
					WebkitBoxOrient: 'vertical',
					...sx,
				}}
				ref={textRef}
				{...rest}
			>
				{children}
			</Typography>
		</Tooltip>
	);
}

EllipsesTypography.propTypes = {
	children: PropTypes.string.isRequired,
	lines: PropTypes.number.isRequired,
	sx: PropTypes.shape({}),
};

EllipsesTypography.defaultProps = {
	sx: {},
};

export default EllipsesTypography;
