import * as React from 'react';
import PropTypes from 'prop-types';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import ReportIcon from '@mui/icons-material/Report';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import WarningIcon from '@mui/icons-material/Warning';
import constants from '@app/constants';

const getIcon = (type) => {
	if (type === 'error') {
		return {
			Icon: ReportIcon,
			color: 'error',
		};
	}
	if (type === 'warning') {
		return {
			Icon: WarningIcon,
			color: 'warning',
		};
	}
	if (type === 'deal') {
		return {
			Icon: ShoppingCartIcon,
			color: 'primary',
		};
	}
	if (type === 'deleted') {
		return {
			Icon: DeleteSweepIcon,
			color: 'primary',
		};
	}

	return {
		Icon: InfoIcon,
		color: 'primary',
	};
};

function MouseOverPopover({
	text,
	children,
	textStyles,
	iconStyles,
	popoverVerticalPlacement,
	popoverHorizontalPlacement,
	type,
}) {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const { Icon, color } = React.useMemo(() => getIcon(type), [type]);

	return (
		<>
			<Icon
				aria-owns={open ? 'info-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
				color={color}
				sx={{ fontSize: 24, ...iconStyles }}
			/>
			<Popover
				id="info-over-popover"
				sx={{
					pointerEvents: 'none',
					zIndex: constants.zIndex.popover,
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: popoverVerticalPlacement,
					horizontal: popoverHorizontalPlacement,
				}}
				transformOrigin={{
					vertical:
						popoverVerticalPlacement === 'bottom' ? 'top' : 'bottom',
					horizontal: popoverHorizontalPlacement,
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Box sx={{ p: 1, maxWidth: '200px', ...textStyles }}>
					<Typography>{text}</Typography>
					{children}
				</Box>
			</Popover>
		</>
	);
}
MouseOverPopover.propTypes = {
	text: PropTypes.string,
	children: PropTypes.node,
	textStyles: PropTypes.shape({}),
	iconStyles: PropTypes.shape({}),
	popoverVerticalPlacement: PropTypes.string,
	popoverHorizontalPlacement: PropTypes.string,
	type: PropTypes.string,
};

MouseOverPopover.defaultProps = {
	text: '',
	children: null,
	textStyles: {},
	iconStyles: {},
	popoverVerticalPlacement: 'bottom',
	popoverHorizontalPlacement: 'left',
	type: 'info',
};

export default MouseOverPopover;
