import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import LoginSignUpContainer from '../../components/shared/ui/LoginSignupContainer';
import ResetPasswordBeginForm from './ResetPasswordBeginForm';
import ResetPasswordCompleteForm from './ResetPasswordCompleteForm';

export default function ResetPassword() {
	const [searchParams] = useSearchParams();

	const token = searchParams.get('token');

	return (
		<LoginSignUpContainer headerText="Reset Password">
			{!token ? (
				<ResetPasswordBeginForm />
			) : (
				<ResetPasswordCompleteForm token={token} />
			)}
		</LoginSignUpContainer>
	);
}
