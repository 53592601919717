import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import AuxiliaryProductsTable from '@app/pages/onboarding/table/AuxiliaryProductsTable';
import { useMakeAuxiliary } from '@app/hooks/useProducts';
import { updateMerchant } from '@app/api/merchant';
import constants from '@app/constants';
import { useAuxiliaryCacheKey } from '@app/hooks/useAuxiliaryCacheKey';
import stickyContainerStyles from '@app/styles/stickyContainerStyles';
import Button from '@app/components/shared/button/Button';

function AddAuxiliaryProductsModal({
	displayProducts,
	setInitialProducts,
	onClose,
	auxiliaryCategory,
}) {
	const [
		additionalAuxiliaryProducts,
		setAdditionalAuxiliaryProducts,
	] = React.useState([]);
	const { mutate } = useMakeAuxiliary({
		cacheKey: useAuxiliaryCacheKey(auxiliaryCategory),
	});

	const handleAddProduct = React.useCallback((product) => {
		setAdditionalAuxiliaryProducts((prevProducts) => [
			...prevProducts,
			product,
		]);
	}, []);

	const handleSaveChanges = React.useCallback(async () => {
		if (!additionalAuxiliaryProducts.length) {
			onClose();
			return;
		}
		mutate({
			productIds: additionalAuxiliaryProducts.map(
				(product) => product.id,
			),
			auxiliaryCategory,
		});
		const auxiliaryField =
			constants.auxiliaryCategoriesFields[auxiliaryCategory];
		await updateMerchant({
			fields: {
				[auxiliaryField]: true,
			},
		});
		setInitialProducts((prevProducts) => [
			...prevProducts,
			...additionalAuxiliaryProducts,
		]);
		onClose();
	}, [
		mutate,
		additionalAuxiliaryProducts,
		onClose,
		setInitialProducts,
		auxiliaryCategory,
	]);

	const addProductColumn = React.useCallback(
		(product) => {
			const { id } = product.node;
			const formattedProduct = {
				...product.node,
				image_url: product.node.featuredImage?.url,
			};
			const isProductAuxiliary = [
				...displayProducts,
				...additionalAuxiliaryProducts,
			].some((productData) => productData.id === id);
			return (
				<IconButton
					sx={{
						display: 'flex',
						alignSelf: 'flex-end',
						ml: 'auto',
						mr: 0.75,
					}}
					onClick={() => handleAddProduct(formattedProduct)}
					disabled={isProductAuxiliary}
				>
					{isProductAuxiliary ? (
						<CheckIcon color="primary" />
					) : (
						<AddIcon />
					)}
				</IconButton>
			);
		},
		[displayProducts, additionalAuxiliaryProducts, handleAddProduct],
	);

	return (
		<>
			<AuxiliaryProductsTable
				selectedAuxiliaryIds={[]}
				setSelectedAuxiliaryIds={() => null}
				withBorder={false}
				showSelect={false}
				addProductColumn={addProductColumn}
				queryKeysArray={
					constants.auxiliaryCategoriesWords[auxiliaryCategory]
				}
			/>
			<Box sx={stickyContainerStyles}>
				<Button onClick={handleSaveChanges}>Save</Button>
			</Box>
		</>
	);
}

AddAuxiliaryProductsModal.propTypes = {
	displayProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	setInitialProducts: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	auxiliaryCategory: PropTypes.string,
};

AddAuxiliaryProductsModal.defaultProps = {
	auxiliaryCategory: null,
};

export default AddAuxiliaryProductsModal;
