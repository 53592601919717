import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import constants from '@app/constants';
import useAuth from '@app/hooks/useAuth';
import { updateMerchant } from '@app/api/merchant';

function AuxiliaryCheckBox({
	text,
	areAnyProductsSelected,
	auxiliaryCategory,
	checkBoxValue,
	setCheckBoxValue,
}) {
	const { merchant, setMerchant } = useAuth();
	const auxiliaryField = React.useMemo(
		() => constants.auxiliaryCategoriesFields[auxiliaryCategory],
		[auxiliaryCategory],
	);

	const isAuxiliaryStepSkipped = React.useMemo(
		() =>
			merchant?.active &&
			merchant?.onboarding_complete &&
			!merchant?.[auxiliaryField],
		[merchant, auxiliaryField],
	);

	React.useEffect(() => {
		setCheckBoxValue(
			!areAnyProductsSelected && !isAuxiliaryStepSkipped,
		);
	}, [
		areAnyProductsSelected,
		isAuxiliaryStepSkipped,
		setCheckBoxValue,
	]);

	const handleCheckBoxSelect = React.useCallback(
		async (e) => {
			setCheckBoxValue(e.target.checked);
			await updateMerchant({
				fields: {
					[auxiliaryField]: e.target.checked,
				},
			});
			setMerchant((prevMerchant) => ({
				...prevMerchant,
				[auxiliaryField]: e.target.checked,
			}));
		},
		[auxiliaryField, setMerchant, setCheckBoxValue],
	);

	return (
		<FormControlLabel
			label={
				<Typography
					sx={{
						fontSize: 14,
						color: areAnyProductsSelected
							? constants.colors.pending
							: constants.colors.textMain,
					}}
				>
					{text}
				</Typography>
			}
			control={
				<Checkbox
					checked={checkBoxValue}
					disabled={areAnyProductsSelected}
					onChange={handleCheckBoxSelect}
				/>
			}
		/>
	);
}

AuxiliaryCheckBox.propTypes = {
	text: PropTypes.string.isRequired,
	areAnyProductsSelected: PropTypes.bool.isRequired,
	auxiliaryCategory: PropTypes.string.isRequired,
	checkBoxValue: PropTypes.bool.isRequired,
	setCheckBoxValue: PropTypes.func.isRequired,
};

export default AuxiliaryCheckBox;
