import axios from './index';

import * as urlHelpers from '../helpers/urlHelpers';

const apiUrl = '/api/marketplace';

export async function getProducts({
	page,
	perPage,
	sortKey,
	collectionId,
	marketplaceEnabledFilter,
	searchTerm,
	selectedTags,
}) {
	const queryString = urlHelpers.cleanAndBuildUrlQueryParams({
		page,
		perPage,
		sortKey,
		collectionId,
		...(marketplaceEnabledFilter !== null
			? { marketplaceEnabled: marketplaceEnabledFilter }
			: null), // Don't filter by enabled if it's null
		searchTerm,
		selectedTags,
	});

	const response = await axios.get(
		`${apiUrl}/products?${queryString}`,
	);
	return response.data;
}

export async function updateVariants({ data }) {
	const response = await axios.post(`${apiUrl}/products`, data);
	return response.data;
}

export async function getEligibleShops() {
	const response = await axios.get(`${apiUrl}/eligible-shops`);
	return response.data;
}

export async function createMarketplaceCampaign(data) {
	const response = await axios.post(`${apiUrl}/campaigns`, data);
	return response.data;
}

export async function getMarketplaceCampaigns() {
	const response = await axios.get(`${apiUrl}/campaigns`);
	return response.data;
}

export async function getMarketplaceCampaign({ id }) {
	const response = await axios.get(`${apiUrl}/campaigns/${id}`);
	return response.data;
}

export async function getMarketplaceCampaignInbox({
	id,
	queryVariables: { page, perPage, getCount, sortKey },
}) {
	const queryString = urlHelpers.cleanAndBuildUrlQueryParams({
		page,
		perPage,
		getCount,
		sortKey,
	});
	const response = await axios.get(
		`${apiUrl}/campaigns/${id}/inbox?${queryString}`,
	);
	return response.data;
}

export async function completeMarketplaceCampaign({
	id,
	createdCampaignId,
}) {
	const response = await axios.post(`${apiUrl}/campaigns/complete`, {
		id,
		createdCampaignId,
	});
	return response.data;
}

export async function getMarketplaceProductsSyncs() {
	const response = await axios.get(`${apiUrl}/products-sync`);
	return response.data;
}

export async function syncMarketplaceProducts({ shop }) {
	const response = await axios.post(`${apiUrl}/products-sync`, {
		shop,
	});
	return response.data;
}

export async function syncMarketplaceCampaignProducts({ id }) {
	const response = await axios.post(
		`${apiUrl}/campaigns/products-sync`,
		{
			id,
		},
	);
	return response.data;
}
