/* eslint-disable react/prop-types */
import * as React from 'react';

import OffersProvider from '@app/pages/customerPortalNyop/components/OffersProvider';

import CustomerAuthContainer from '@app/components/customerPortal/CustomerAuthContainer';
import CustomerPortalNyopLayout from './CustomerPortalNyopLayout';

function CustomerPortalPreorder() {
	return (
		<OffersProvider>
			<CustomerAuthContainer>
				{({
					order,
					merchant,
					variants,
					preorders,
					offers,
					token,
				}) => (
					<CustomerPortalNyopLayout
						order={order}
						merchant={merchant}
						variants={variants}
						preorders={preorders}
						offers={offers}
						token={token}
					/>
				)}
			</CustomerAuthContainer>
		</OffersProvider>
	);
}

export default CustomerPortalPreorder;
