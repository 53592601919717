import React from 'react';
import PropTypes from 'prop-types';

import LoadingButton from '@mui/lab/LoadingButton';

function Button({ variant, loading, children, ...props }) {
	return (
		<LoadingButton variant={variant} loading={loading} {...props}>
			{children}
		</LoadingButton>
	);
}

Button.propTypes = {
	variant: PropTypes.string,
	loading: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
		PropTypes.string,
	]),
};

Button.defaultProps = {
	variant: 'contained',
	loading: false,
	children: null,
};

export default Button;
