import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function TableHeader({ leftText, rightText, sx }) {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				pt: 1,
				pb: 1,
				...sx,
			}}
		>
			<Typography variant="h6" color="text.secondary">
				{leftText}
			</Typography>
			<Typography variant="h6" color="text.secondary">
				{rightText}
			</Typography>
		</Box>
	);
}

TableHeader.propTypes = {
	leftText: PropTypes.string,
	rightText: PropTypes.string,
	sx: PropTypes.shape({}),
};

TableHeader.defaultProps = {
	leftText: '',
	rightText: '',
	sx: {},
};

export default TableHeader;
