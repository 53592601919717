export const getDiscountThreshold = ({ automationRules, id }) => {
	if (!automationRules || !id) {
		return null;
	}
	if (automationRules?.variants && automationRules.variants[id]) {
		return automationRules.variants[id].maxEffectiveDiscount;
	}
	return automationRules.thresholdDiscount;
};

export const getThresholdPrice = ({ automationRules, id, price }) => {
	if (!automationRules || !id || !price || Number.isNaN(price)) {
		return null;
	}

	const discountThreshold = getDiscountThreshold({
		automationRules,
		id,
	});

	return price * (1 - discountThreshold);
};
