import * as React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import OutlineBox from '@app/components/shared/ui/OutlineBox';
import { formatReadableDate } from '@app/helpers/dateHelpers';
import constants from '@app/constants/index';
import CustomerConsentToDelay from './CustomerConsentToDelay';
import CustomerUpdatePayment from './CustomerUpdatePayment';

const getPaymentMsg = (dueDate, orderType) => {
	const entity =
		orderType === constants.campaignTypes.PREORDER
			? 'pre-order'
			: 'offer';

	const paymentDueMessage = {
		title: `You have a payment due on ${formatReadableDate(
			dueDate,
		)}.`,
		description: `Your ${entity} should be fulfilled on or before that time.`,
	};
	return {
		pending: paymentDueMessage,
		authorized: {
			title: 'Your payment has been authorized.',
			description: `Your ${entity} should be fulfilled soon.`,
		},
		partially_paid: paymentDueMessage,
		paid: {
			title: `Your ${entity} has been paid in full.`,
			description: '',
		},
		partially_refunded: {
			title: `Your ${entity} has been partially refunded.`,
			description: '',
		},
		refunded: {
			title: 'Your payment has been refunded.',
			description: 'No further action needed',
		},
		voided: {
			title: 'Your payment has been voided.',
			description:
				'Please reach out to the merchant if you believe this is in error.',
		},
		// Need this for when a 0 down order is cancelled and order.financial_status
		// is left on "pending" despite order being cancelled
		cancelled: {
			title: 'Your order has been cancelled.',
			description: 'No further action is needed.',
		},
		default: {
			title: `Your item is on ${entity}.`,
			description: 'Check back later for more updates.',
		},
	};
};

function PaymentStatus({
	paymentStatus,
	dueDate,
	orderId,
	initialDueDate,
	consentedDueDate,
	cancelReason,
	cancelledAt,
	token,
	orderType,
}) {
	const requiresConsent =
		consentedDueDate !== dueDate &&
		Math.ceil(
			(Date.parse(dueDate) - Date.parse(initialDueDate)) /
				(1000 * 60 * 60 * 24),
		) > 30;

	const status = getPaymentMsg(dueDate, orderType);
	let message = status[paymentStatus]
		? status[paymentStatus]
		: status.default;

	// See above about what happened with 0 down order cancellation
	if (cancelReason || cancelledAt) {
		message = status.cancelled;
	}

	return (
		<OutlineBox sx={{ display: 'block' }}>
			<Box>
				{requiresConsent && (
					<CustomerConsentToDelay
						orderId={orderId}
						token={token}
						dueDate={dueDate}
					/>
				)}
				<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
					{message.title}
				</Typography>
				<Typography color="text.secondary">
					{message.description}
				</Typography>
				{paymentStatus === 'partially_paid' ||
				paymentStatus === 'pending' ||
				cancelReason ||
				cancelledAt ? (
					<Box mt={2}>
						<CustomerUpdatePayment token={token} />
					</Box>
				) : null}
			</Box>
		</OutlineBox>
	);
}

PaymentStatus.propTypes = {
	paymentStatus: PropTypes.string,
	dueDate: PropTypes.string,
	orderId: PropTypes.string,
	consentedDueDate: PropTypes.string,
	initialDueDate: PropTypes.string,
	cancelReason: PropTypes.string,
	cancelledAt: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	token: PropTypes.string,
	orderType: PropTypes.string.isRequired,
};
PaymentStatus.defaultProps = {
	paymentStatus: '',
	dueDate: '',
	orderId: '',
	consentedDueDate: '',
	initialDueDate: '',
	cancelReason: '',
	cancelledAt: '',
	token: '',
};

export default PaymentStatus;
