import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import Mailto from '../../components/shared/ui/MailtoLink';

import FullPageMessage from '../../components/shared/layout/FullPageMessage';

function NotWhitelisted() {
	const [searchParams] = useSearchParams();
	return (
		<FullPageMessage appBar={false}>
			<Typography variant="h4" paragraph sx={{ maxWidth: '500px' }}>
				Please reach out to us at{' '}
				<Mailto
					email="support@bazo.co"
					subject={`Access Code | [${searchParams.get('shop')}]`}
					color="secondary"
				>
					<Typography variant="h4" component="span">
						support@bazo.co
					</Typography>
				</Mailto>{' '}
				to get your access code.
			</Typography>
		</FullPageMessage>
	);
}

export default NotWhitelisted;
