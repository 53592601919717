import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import ReportIcon from '@mui/icons-material/Report';
import constants from '../../../constants/index';

const COLOR_MAP = {
	info: constants.colors.secondary,
	warning: constants.colors.tertiary,
	error: constants.colors.failure,
	success: constants.colors.primary,
};

const getIcon = (type) => {
	if (type === 'info') {
		return <InfoOutlinedIcon />;
	}
	if (type === 'success') {
		return <InfoOutlinedIcon />;
	}
	if (type === 'warning') {
		return <WarningOutlinedIcon />;
	}
	if (type === 'error') {
		return <ReportIcon />;
	}
	return <InfoOutlinedIcon />;
};

function InfoBox({ children, type = 'info', onClick, sx }) {
	const color = COLOR_MAP[type] || constants.colors.secondary;

	return (
		<Box
			p={1.5}
			sx={{
				display: 'flex',
				border: 1,
				alignItems: 'center',
				borderColor: color,
				color,
				borderRadius: 1,
				...sx,
			}}
			onClick={onClick}
		>
			<Box sx={{ mr: 1, display: 'flex' }}>{getIcon(type)}</Box>
			{children}
		</Box>
	);
}

InfoBox.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	type: PropTypes.string,
	onClick: PropTypes.func,
	sx: PropTypes.shape({}),
};

InfoBox.defaultProps = {
	type: 'info',
	onClick: () => {},
	sx: {},
};

export default InfoBox;
