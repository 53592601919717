import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function ThankYou({ order, merchant, customer, sx, children }) {
	return (
		<Box sx={{ ...sx }}>
			<Typography variant="h4" mb={2} sx={{ fontWeight: 'bold' }}>
				{merchant.name}
			</Typography>
			{children}
			<Box
				mb={3}
				mt={2}
				sx={{ display: 'flex', alignItems: 'center' }}
			>
				<CheckCircleOutlineIcon
					width="100px"
					color="secondary"
					sx={{ width: '50px', height: '50px' }}
				/>
				<Box ml={1}>
					<Typography color="text.secondary">
						Order {order?.name}
					</Typography>
					<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
						Thank you {customer?.first_name || ''}!
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}

ThankYou.propTypes = {
	merchant: PropTypes.shape({
		name: PropTypes.string,
	}),
	order: PropTypes.shape({
		name: PropTypes.string,
	}),
	customer: PropTypes.shape({
		first_name: PropTypes.string,
	}),
	sx: PropTypes.shape({}),
	children: PropTypes.node,
};
ThankYou.defaultProps = {
	merchant: {},
	order: {},
	customer: {},
	sx: {},
	children: null,
};

export default ThankYou;
