import constants from '@app/constants';
import { borderStyle } from './borderStyles';

export default {
	display: 'flex',
	justifyContent: 'flex-end',
	gap: 1,
	width: '100%',
	position: 'sticky',
	bottom: 0,
	background: 'white',
	paddingBottom: 1.2,
	paddingTop: 1.2,
	borderTop: borderStyle,
	zIndex: constants.zIndex.navStickyContainer,
};
