import * as React from 'react';
import PropTypes from 'prop-types';

import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import ExpiredTokenPage from './ExpiredTokenPage';
import FullPageMessage from '../shared/layout/FullPageMessage';

import Loader from '../shared/loaders/CircularLoader';
import Mailto from '../shared/ui/MailtoLink';

function CustomerAuthContainer({ children }) {
	const [searchParams] = useSearchParams();
	const initialToken = searchParams.get('token');
	const [token, setToken] = React.useState(initialToken);
	const [isExpired, setIsExpired] = React.useState(false);

	const { data, isLoading, error } = useQuery(['customer', token], {
		queryFn: async () =>
			axios.get('/api/customers/login', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}),
		onError: (err) => {
			if (err?.response?.data?.code === 400) {
				setIsExpired(true);
			}
		},
		enabled: !!token && !isExpired,
		retry: 0,
	});

	React.useEffect(() => {
		const authToken = searchParams.get('token');
		setToken(authToken);
	}, [token, searchParams]);

	// TODO: Flash of green loader from top level app
	// loading screen
	if (isLoading && token)
		return <Loader color="secondary" fullPage />;

	if (error && error?.response?.data?.code === 400) {
		return <ExpiredTokenPage token={token} />;
	}
	if (error || !token) {
		return (
			<Container maxWidth="md">
				<FullPageMessage appBar={false}>
					<Typography color="secondary.main" variant="h2">
						Oops! We were&apos;nt able to authenticate your request.
					</Typography>
					<Typography color="secondary.main" variant="h2">
						Please try the link again or reload the page.
					</Typography>
					<Typography variant="h6" sx={{ mt: 2 }}>
						If you are following up on your offers or your orders and
						believe this is in error, please reach out to{' '}
						<Mailto
							email="support@bazo.co"
							subject="Trouble logging into the Customer Portal"
							color="secondary"
						>
							<Typography component="span">
								support@bazo.co
							</Typography>
						</Mailto>
					</Typography>
				</FullPageMessage>
			</Container>
		);
	}

	// Get deeply nested values
	const {
		data: { order, variants, merchant, preorders, offers } = {
			order: {},
			merchant: {},
			preorders: [],
			offers: [],
			variants: [],
		},
	} = data;

	return children({
		order,
		merchant,
		variants,
		preorders,
		offers,
		token,
	});
}

CustomerAuthContainer.propTypes = {
	children: PropTypes.func.isRequired,
};

export default CustomerAuthContainer;
