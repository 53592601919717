import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import constants from '@app/constants/index';
import useAuth from '@app/hooks/useAuth';

const POTENTIAL_SELLTHROUGH_TOOLTIP =
	'Potential number of units that may be sold after all pending offers are processed';
const POSSIBLE_SELLTHROUGH_TOOLTIP =
	'Possible number of units that will be sold';
const FACTUAL_SELLTHROUGH_TOOLTIP = 'Actual number of units sold';

function UnitsRenderer({
	potentialOffersUnits,
	possibleOffersUnits,
	factualOffersUnits,
	isFinalized,
}) {
	const { formatMerchantNumber } = useAuth();
	return (
		<Box sx={{ textAlign: 'center', width: '80%' }}>
			<Tooltip
				title={POTENTIAL_SELLTHROUGH_TOOLTIP}
				arrow
				placement="left"
			>
				<Typography color={constants.colors.pending}>
					{formatMerchantNumber({ value: potentialOffersUnits })}
				</Typography>
			</Tooltip>
			<Divider />
			{isFinalized ? (
				<Tooltip
					title={FACTUAL_SELLTHROUGH_TOOLTIP}
					arrow
					placement="left"
				>
					<Typography color={constants.colors.primary}>
						{formatMerchantNumber({ value: factualOffersUnits })}
					</Typography>
				</Tooltip>
			) : (
				<Tooltip
					title={POSSIBLE_SELLTHROUGH_TOOLTIP}
					arrow
					placement="left"
				>
					<Typography color={constants.colors.primary}>
						{formatMerchantNumber({ value: possibleOffersUnits })}
					</Typography>
				</Tooltip>
			)}
		</Box>
	);
}

UnitsRenderer.propTypes = {
	potentialOffersUnits: PropTypes.number.isRequired,
	possibleOffersUnits: PropTypes.number.isRequired,
	factualOffersUnits: PropTypes.number.isRequired,
	isFinalized: PropTypes.bool.isRequired,
};

export default UnitsRenderer;
