import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export function getProductImage(variant) {
	const url = variant?.deleted_at ? null : variant?.image_url;

	return {
		src: url,
		alt: variant?.full_title,
	};
}

function ProductThumbnail({
	variant,
	variantImage,
	size = '40px',
	sx,
	deleted,
	customerPortal = false,
}) {
	const image = variantImage?.src
		? variantImage
		: getProductImage(variant);

	if (!customerPortal && (deleted || variant?.deleted_at)) {
		return (
			<DeleteOutlineIcon
				sx={{ height: size, width: size, minWidth: size }}
			/>
		);
	}

	if (!image.src) {
		return (
			<InsertPhotoIcon
				sx={{ height: size, width: size, minWidth: size }}
			/>
		);
	}

	return (
		<Box
			sx={{
				width: size,
				minWidth: size,
				height: size,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				border: 1,
				borderColor: 'divider',
				borderRadius: 1,
				overflow: 'hidden',
				...sx,
			}}
		>
			<img
				src={image.src}
				alt={image.alt}
				style={{ objectFit: 'cover', width: '100%', height: '100%' }}
			/>
		</Box>
	);
}

ProductThumbnail.propTypes = {
	variant: PropTypes.shape({
		full_title: PropTypes.string.isRequired,
		image_url: PropTypes.string.isRequired,
		deleted_at: PropTypes.string,
	}),
	variantImage: PropTypes.shape({
		altText: PropTypes.string,
		src: PropTypes.string,
	}),
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
	// eslint-disable-next-line react/forbid-prop-types
	sx: PropTypes.object,
	deleted: PropTypes.bool,
	customerPortal: PropTypes.bool,
};

export default ProductThumbnail;
