import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';

import IconButton from '@mui/material/IconButton';
import PreviewIcon from '@mui/icons-material/Preview';

import { Typography } from '@mui/material';
import useAuth from '../../hooks/useAuth';

import {
	formatDate,
	formatReadableDate,
} from '../../helpers/dateHelpers';
import { capitalizeFirstLetter } from '../../helpers/stringHelpers';
import { getOrderLink } from '../../lib/shopify';
import { GA_LABEL } from '../../constants/googleAnalytics';
import getNoTableMatch from '@app/components/shared/Table/components/NoTableMatch';

const columns = [
	{
		name: 'variant_id',
		label: 'Variant Id',
	},
	{
		name: 'item_price',
		label: 'Price',
	},
	{
		name: 'total_price',
		label: 'Order Total',
	},
	{
		name: 'quantity',
		label: 'Quantity',
	},
	{
		name: 'financial_status',
		label: 'Payment Status',
		options: {
			filterType: 'checkbox',
		},
	},
	{
		name: 'fulfillment_status',
		label: 'Fulfillment Status',
		options: {
			filterType: 'checkbox',
			customBodyRender: (value) => value || 'None',
		},
	},
	{
		name: 'createdAt',
		label: 'Order Date',
		options: {
			customBodyRender: (value) => formatReadableDate(value),
		},
	},
	{
		name: 'canceled',
		label: 'Cancel Reason',
		options: {
			filterType: 'checkbox',
			customBodyRender: (value) => {
				const isCancelled = !!value;
				return (
					<Typography color={isCancelled ? 'error.main' : ''}>
						{isCancelled ? capitalizeFirstLetter(value) : ''}
					</Typography>
				);
			},
			filterOptions: {
				renderValue: (value) => {
					if (!value) return 'None';
					return capitalizeFirstLetter(value);
				},
			},
		},
	},
	{
		name: 'payment_error',
		label: 'Payment Error',
		options: {
			filterType: 'checkbox',
			customBodyRender: (value) => {
				const isError = !!value;
				return (
					<Typography color={isError ? 'error.main' : ''}>
						{isError ? 'Error' : ''}
					</Typography>
				);
			},
			filterOptions: {
				renderValue: (value) => (!value ? 'None' : 'Error'),
			},
		},
	},
	{
		name: 'orderLink',
		label: 'Order',
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value) => (
				<IconButton
					href={value}
					title="View order in Shopify Admin"
					target="_blank"
					rel="noopener noreferrer"
				>
					<PreviewIcon />
				</IconButton>
			),
		},
	},
];

function PreorderTable({ preorders, isFetching }) {
	const { formatMerchantCurrency, merchant } = useAuth();

	const formattedData = preorders.map((preorder) => ({
		...preorder,
		item_price: formatMerchantCurrency(preorder.item_price),
		total_price: formatMerchantCurrency(preorder.total_price),
		orderLink: getOrderLink(
			merchant.myshopify_domain,
			preorder.order_id,
		),
	}));

	return (
		<MUIDataTable
			data-ga-label={GA_LABEL.PREORDERS_TABLE}
			title="Campaign Preorders"
			data={isFetching ? [] : formattedData}
			columns={columns}
			options={{
				filter: true,
				filterType: 'dropdown',
				responsive: 'vertical',
				enableNestedDataAccess: '.',
				storageKey: 'bazo-execute-preorder-settings',
				selectableRowsHideCheckboxes: true,
				viewColumns: true,
				downloadOptions: {
					filename: `preorders_${formatDate(new Date())}.csv`,
				},
				textLabels: {
					body: {
						noMatch: getNoTableMatch({
							isLoading: isFetching,
							text: 'Sorry, no Preorders found',
						}),
					},
				},
			}}
		/>
	);
}

PreorderTable.propTypes = {
	isFetching: PropTypes.bool,
	preorders: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			shop: PropTypes.string,
			line_item_id: PropTypes.string,
			currency: PropTypes.string,
			quantity: PropTypes.number,
			token: PropTypes.string,
			selling_plan_group_id: PropTypes.string,
			selling_plan_id: PropTypes.string,
			payment_mandate_id: PropTypes.string,
			payment_terms_id: PropTypes.string,
			payment_schedule_id: PropTypes.string,
			payment_due_at: PropTypes.string,
			order_id: PropTypes.string,
			product_id: PropTypes.string,
			variant_id: PropTypes.string,
			item_price: PropTypes.string,
			total_price: PropTypes.string,
			customer_email: PropTypes.string,
			multi_item: PropTypes.bool,
			financial_status: PropTypes.string,
			fulfillment_status: PropTypes.string,
			complete: PropTypes.bool,
			completed_at: PropTypes.oneOfType([
				PropTypes.instanceOf(Date),
				PropTypes.string,
			]),
			payment_error: PropTypes.bool,
			payment_error_at: PropTypes.oneOfType([
				PropTypes.instanceOf(Date),
				PropTypes.string,
			]),
			canceled: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.bool,
			]),
			canceled_at: PropTypes.oneOfType([
				PropTypes.instanceOf(Date),
				PropTypes.string,
			]),
			createdAt: PropTypes.oneOfType([
				PropTypes.instanceOf(Date),
				PropTypes.string,
			]),
			updatedAt: PropTypes.oneOfType([
				PropTypes.instanceOf(Date),
				PropTypes.string,
			]),
			sellingPlanGroupId: PropTypes.string,
		}),
	),
};

PreorderTable.defaultProps = {
	preorders: [],
	isFetching: false,
};

export default PreorderTable;
