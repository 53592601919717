const OfferStatus = {
	ACCEPTED: 'Accepted',
	AUTO_ACCEPTED: 'Auto Accepted',
	COUNTERED: 'Countered | Offer Out',
	COUNTERED_LOST: 'Countered | Lost',
	COUNTERED_CANCELLED: 'Countered | Cancelled',
	COUNTERED_CONVERTED: 'Countered | Converted',
	CANCELED: 'Canceled',
	PENDING: 'Pending Review',
};

const getOfferStatus = (offer) => {
	if (offer.accepted_at && offer.canceled_at) {
		return OfferStatus.CANCELED;
	}
	if (offer.accepted_at && offer.auto_accepted) {
		return OfferStatus.AUTO_ACCEPTED;
	}
	if (offer.accepted_at) {
		return OfferStatus.ACCEPTED;
	}
	if (
		offer.counter_offer_created_at &&
		offer.counter_offer_declined_at
	) {
		return OfferStatus.COUNTERED_LOST;
	}
	if (offer.counter_offer_created_at && offer.canceled_at) {
		return OfferStatus.COUNTERED_CANCELLED;
	}
	if (
		offer.counter_offer_created_at &&
		offer.counter_offer_accepted_at
	) {
		return OfferStatus.COUNTERED_CONVERTED;
	}
	if (offer.counter_offer_created_at) {
		return OfferStatus.COUNTERED;
	}
	if (offer.canceled_at) {
		return OfferStatus.CANCELED;
	}
	return OfferStatus.PENDING;
};

const isPending = (offer) =>
	getOfferStatus(offer) === OfferStatus.PENDING;

const isAccepted = (offer) =>
	[OfferStatus.ACCEPTED, OfferStatus.AUTO_ACCEPTED].includes(
		getOfferStatus(offer),
	);

export default {
	getOfferStatus,
	isPending,
	isAccepted,
};
