import PropTypes from 'prop-types';

export const OrderExtra = PropTypes.shape({
	paymentAttemptFailed: PropTypes.bool,
});

export const OrderType = PropTypes.shape({
	id: PropTypes.string,
	admin_graphql_api_id: PropTypes.string,
	customer_locale: PropTypes.string,
	currency: PropTypes.string,
	current_total_price_set: PropTypes.shape({
		presentment_money: PropTypes.shape({
			currency_code: PropTypes.string,
			amount: PropTypes.string,
		}),
	}),
	line_items: PropTypes.arrayOf(PropTypes.shape({})),
	payment_terms: PropTypes.shape({
		payment_schedules: PropTypes.arrayOf(
			PropTypes.shape({
				payment_due_at: PropTypes.string,
				currency: PropTypes.string,
				amount: PropTypes.string,
			}),
		),
	}),
	refunds: PropTypes.arrayOf(PropTypes.shape({})),
	subtotal_price_set: PropTypes.shape({
		presentment_money: PropTypes.shape({
			currency_code: PropTypes.string,
			amount: PropTypes.string,
		}),
	}),
	total_shipping_price_set: PropTypes.shape({
		presentment_money: PropTypes.shape({
			currency_code: PropTypes.string,
			amount: PropTypes.string,
		}),
	}),
	total_tax_set: PropTypes.shape({
		presentment_money: PropTypes.shape({
			currency_code: PropTypes.string,
			amount: PropTypes.string,
		}),
	}),
	extra: OrderExtra,
});

export const OfferType = PropTypes.shape({
	id: PropTypes.string.isRequired,
	variant_id: PropTypes.string.isRequired,
	original_price: PropTypes.string.isRequired,
	msrp: PropTypes.string.isRequired,
	offer_amount: PropTypes.string.isRequired,
});

export const PreorderType = PropTypes.shape({
	id: PropTypes.string.isRequired,
	shop: PropTypes.string.isRequired,
	payment_due_at: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
	]),
	initial_fulfillment_date: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
	]),
	consented_fulfillment_date: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
	]),
});

export const OffersSummaryType = PropTypes.shape({
	factualRevenue: PropTypes.string.isRequired,
	potentialRevenue: PropTypes.string.isRequired,
	possibleRevenue: PropTypes.string.isRequired,

	factualOffersUnits: PropTypes.number.isRequired,
	potentialOffersUnits: PropTypes.number.isRequired,
	possibleOffersUnits: PropTypes.number.isRequired,

	convertedUnits: PropTypes.number.isRequired,
	counteredUnits: PropTypes.number.isRequired,
	lostUnits: PropTypes.number.isRequired,
	pendingUnits: PropTypes.number.isRequired,
	totalUnits: PropTypes.number.isRequired,
});

export const VariantType = PropTypes.shape({
	id: PropTypes.string,
	full_title: PropTypes.string,
	price: PropTypes.string,
	msrp: PropTypes.string,
	product_id: PropTypes.string,
	summary: OffersSummaryType,
});

export const MerchantType = PropTypes.shape({
	domain: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
});

export const ProductType = PropTypes.shape({
	title: PropTypes.string,
	summary: OffersSummaryType,
	variants: PropTypes.arrayOf(VariantType),
});

export const ProductValuesType = PropTypes.shape({
	inventory_value: PropTypes.string.isRequired,
	inventory_quantity: PropTypes.string.isRequired,
	days_in_store: PropTypes.string.isRequired,
	age_rate: PropTypes.string.isRequired,
	value_rate: PropTypes.string.isRequired,
});

export const ProductWithPrioritiesType = PropTypes.shape({
	title: PropTypes.string,
	summary: OffersSummaryType,
	variants: PropTypes.arrayOf(VariantType),
	product_values: ProductValuesType,
});
