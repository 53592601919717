import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

// Use this in the parent layout so that the footer doesn't  block the main content
export function useStickyFooter() {
	const footerRef = React.useRef(null);
	const [footerHeight, setFooterHeight] = React.useState(0);

	React.useEffect(() => {
		if (footerRef.current) {
			setFooterHeight(footerRef.current.offsetHeight);
		}
	}, []);

	return { footerRef, footerHeight };
}

function StickyFooter({ children, footerRef, sx }) {
	return (
		<Box
			ref={footerRef}
			sx={{
				position: 'fixed',
				bottom: 0,
				left: 0,
				right: 0,
				backgroundColor: 'background.paper',
				boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
				zIndex: 1000,
				padding: 1,
				...sx,
			}}
		>
			{children}
		</Box>
	);
}

StickyFooter.propTypes = {
	children: PropTypes.node.isRequired,
	footerRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
	]),
	sx: PropTypes.shape({}),
};

StickyFooter.defaultProps = {
	footerRef: null,
	sx: {},
};

export default StickyFooter;
