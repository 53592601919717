import * as React from 'react';
import { useParams } from 'react-router-dom';

import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';

import PropTypes from 'prop-types';
import { getNumericId } from '../../lib/shopify';
import { useNyopVariants } from '../../hooks/useNyopCampaign';

function NYOPVariantSelector({ campaignId, onSelect }) {
	const { variantId = '' } = useParams();

	const [selectedId, setSelectedId] = React.useState('');

	const { data, isLoading } = useNyopVariants({ id: campaignId });

	// Must happen after data because select options are async
	// and needs to start as '' until they load or we get Mui warning
	React.useEffect(() => {
		if (data?.data?.variants) {
			setSelectedId(variantId);
		}
	}, [data, variantId]);

	const handleChange = (event) => {
		const { value } = event.target;
		setSelectedId(value);
		if (onSelect) {
			onSelect(event);
		}
	};

	return (
		<FormControl component={Paper} sx={{ width: '300px' }}>
			<InputLabel id="variant-select-label">
				Select Variant
			</InputLabel>
			<Select
				labelId="variant-select-label"
				id="variant-select"
				value={selectedId}
				onChange={handleChange}
				label="Select Variant"
				startAdornment={
					isLoading ? <CircularProgress size="1rem" /> : null
				}
			>
				<MenuItem key="variant-select-none" value="">
					<Typography>Product</Typography>
				</MenuItem>
				<Divider />
				{data?.data &&
					data.data.variants.map((variant) => {
						const { id, title } = variant;

						return (
							<MenuItem
								key={`variant-select-${id}`}
								value={getNumericId(id)}
							>
								{title}
							</MenuItem>
						);
					})}
			</Select>
		</FormControl>
	);
}

NYOPVariantSelector.propTypes = {
	campaignId: PropTypes.string.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default NYOPVariantSelector;
