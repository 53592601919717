import PropTypes from 'prop-types';
import React from 'react';

import Box from '@mui/material/Box';

import Button from '@app/components/shared/button/Button';
import stickyContainerStyles from '@app/styles/stickyContainerStyles';

function BackSaveButtonContainer({ onClose, handleSaveChanges }) {
	return (
		<Box sx={stickyContainerStyles}>
			<Button
				variant="text"
				sx={{ color: 'button.secondary.main' }}
				onClick={onClose}
			>
				Back
			</Button>

			<Button onClick={handleSaveChanges}>Save</Button>
		</Box>
	);
}

BackSaveButtonContainer.propTypes = {
	onClose: PropTypes.func.isRequired,
	handleSaveChanges: PropTypes.func.isRequired,
};

export default BackSaveButtonContainer;
