import * as React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Loader from '@app/components/shared/loaders/CircularLoader';
import { useMarketplaceCampaign } from '@app/hooks/useMarketplace';

import Table from './table/index';
import Summary from './Summary';
import NotFound from '@app/pages/notFound/index';

function MarketplaceCampaignSummary() {
	const { id } = useParams();
	const { data, isLoading } = useMarketplaceCampaign({
		id,
	});

	if (isLoading) return <Loader fullPage />;

	const { campaign, aggregations } = data.data;

	if (!campaign) {
		return <NotFound />;
	}

	return (
		<Box
			mb={2}
			sx={{
				display: 'flex',
				float: 'right',
			}}
		>
			<Grid container rowSpacing={2}>
				<Grid item md={12}>
					<Summary campaign={campaign} aggregations={aggregations} />
				</Grid>
				<Grid item md={12}>
					<Table campaign={campaign} marketplaceCampaignId={id} />
				</Grid>
			</Grid>
			<Outlet />
		</Box>
	);
}

export default MarketplaceCampaignSummary;
