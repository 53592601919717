import * as React from 'react';
import PropTypes from 'prop-types';
import { useUpdateVariants } from '@app/hooks/useMarketplace';

const MarketplaceProductsContext = React.createContext(undefined);

export function MarketplaceProductsProvider({ children }) {
	const [initialConfigurations, setInitialConfigurations] =
		React.useState(null);
	const [configurations, setConfiguration] = React.useState(null);

	const { mutate } = useUpdateVariants({
		configOpts: {
			onSuccess: () => setInitialConfigurations(configurations),
		},
	});

	const isDirty = React.useMemo(() => {
		if (configurations === initialConfigurations) {
			return false;
		}
		if (!configurations) {
			return false;
		}

		const changedConfigs = Object.entries(configurations).filter(
			([entityId, config]) =>
				!initialConfigurations[entityId] ||
				initialConfigurations[entityId].threshold !==
					config?.threshold ||
				initialConfigurations[entityId].enabled !== config?.enabled,
		);

		return changedConfigs.length !== 0;
	}, [initialConfigurations, configurations]);

	const applyChanges = React.useCallback(() => {
		const data = Object.entries(configurations).map(([, config]) => ({
			variant_id: config.variantId,
			product_id: config.productId,
			nyop_threshold: config.threshold || 0,
			is_enabled: config.enabled || false,
			type: config.type,
		}));

		return mutate({ data });
	}, [configurations, mutate]);

	const deriveFromMarketplaceProducts = React.useCallback(
		(marketplaceProducts) => {
			const newData = {};
			marketplaceProducts?.forEach((marketplaceProduct) => {
				newData[
					marketplaceProduct.variant_id ||
						marketplaceProduct.product_id
				] = {
					threshold: marketplaceProduct.nyop_threshold,
					enabled: marketplaceProduct.is_enabled,
					productId: marketplaceProduct.product_id,
					variantId: marketplaceProduct.variant_id,
				};
			});
			setInitialConfigurations((prev) => ({ ...prev, ...newData }));
			setConfiguration((prev) => ({ ...newData, ...prev }));
		},
		[],
	);

	const updateProductThreshold = React.useCallback(
		({ productId, threshold }) => {
			setConfiguration((prev) => ({
				...prev,
				[productId]: {
					...prev[productId],
					threshold,
					productId,
				},
			}));
		},
		[],
	);

	const updateVariantThreshold = React.useCallback(
		({ variantId, productId, threshold }) => {
			setConfiguration((prev) => ({
				...prev,
				[variantId]: {
					...(prev[variantId] || prev[productId]),
					productId,
					variantId,
					threshold,
				},
			}));
		},
		[],
	);

	const updateProductEnabled = React.useCallback(
		({ productId, variantsIds, enabled }) => {
			setConfiguration((prev) => ({
				...prev,
				[productId]: {
					...prev[productId],
					productId,
					enabled,
				},
				...variantsIds.reduce(
					(acc, variantId) =>
						prev[variantId]
							? {
									...acc,
									[variantId]: { ...prev[variantId], enabled },
							  }
							: acc,
					{},
				),
			}));
		},
		[],
	);

	const updateVariantEnabled = React.useCallback(
		({ variantId, productId, enabled }) => {
			const allVariantsDisabled =
				!enabled &&
				Object.values(configurations)
					.filter(
						(config) =>
							config.productId === productId && !!config.variantId,
					)
					.every((config) => !config.is_enabled);

			setConfiguration((prev) => ({
				...prev,
				[variantId]: {
					...(prev[variantId] || prev[productId]),
					variantId,
					productId,
					enabled,
				},
				...(allVariantsDisabled
					? {
							[productId]: {
								...prev[productId],
								productId,
								enabled: false,
							},
					  }
					: null),
			}));
		},
		[configurations],
	);

	const getVariantConfig = React.useCallback(
		({ variantId, productId }) =>
			configurations?.[variantId] ||
			configurations?.[productId] ||
			{},
		[configurations],
	);

	const getProductConfig = React.useCallback(
		({ productId }) => configurations?.[productId] || {},
		[configurations],
	);

	const value = React.useMemo(
		() => ({
			deriveFromMarketplaceProducts,
			updateProductThreshold,
			updateVariantThreshold,
			updateVariantEnabled,
			updateProductEnabled,
			getVariantConfig,
			getProductConfig,
			applyChanges,
			isDirty,
		}),
		[
			deriveFromMarketplaceProducts,
			updateProductThreshold,
			updateVariantThreshold,
			updateVariantEnabled,
			updateProductEnabled,
			getVariantConfig,
			getProductConfig,
			applyChanges,
			isDirty,
		],
	);

	return (
		<MarketplaceProductsContext.Provider value={value}>
			{children}
		</MarketplaceProductsContext.Provider>
	);
}

MarketplaceProductsProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export function useMarketplaceProductsContext() {
	const context = React.useContext(MarketplaceProductsContext);
	if (context === undefined) {
		throw new Error(
			'useMarketplaceProductsContext must be used within a provider',
		);
	}
	return context;
}
