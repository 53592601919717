import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';

import * as productApi from '../api/products';

export const CACHE_KEY = 'products';

export function useProducts({
	cacheKey,
	queryVariables,
	configOpts = {},
}) {
	return useQuery({
		queryKey: [CACHE_KEY, cacheKey],
		queryFn: () => productApi.getProducts(queryVariables),
		keepPreviousData: true,
		...configOpts,
	});
}

export function useProductsByIds({
	cacheKey,
	productIds,
	configOpts = {},
}) {
	return useQuery({
		queryKey: [CACHE_KEY, cacheKey],
		queryFn: () => productApi.getProductsByIds(productIds),
		keepPreviousData: true,
		...configOpts,
	});
}

export function useProductsForPriorityChart({
	cacheKey,
	queryVariables,
	configOpts = {},
}) {
	return useQuery({
		queryKey: [CACHE_KEY, 'priorities', cacheKey],
		queryFn: () =>
			productApi.getProductsForPriorityChart(queryVariables),
		keepPreviousData: true,
		...configOpts,
	});
}

export function useShopifyProducts({
	cacheKey,
	queryVariables,
	configOpts = {},
}) {
	return useQuery({
		queryKey: [CACHE_KEY, 'shopify', cacheKey],
		queryFn: () => productApi.getShopifyProducts(queryVariables),
		keepPreviousData: true,
		...configOpts,
	});
}

export function useAuxiliaryProducts({
	auxiliaryCategory,
	cacheKey,
	configOpts = {},
}) {
	return useQuery({
		queryKey: [CACHE_KEY, cacheKey],
		queryFn: () =>
			productApi.getAuxiliaryProducts({ auxiliaryCategory }),
		keepPreviousData: true,
		...configOpts,
	});
}

export function useMakeAuxiliary({ cacheKey, configOpts = {} } = {}) {
	const queryClient = useQueryClient();

	return useMutation(productApi.makeProductsAuxiliary, {
		...configOpts,
		onSuccess: (newData) => {
			queryClient.setQueryData([CACHE_KEY, cacheKey], () => newData);
		},
	});
}

export function useRemoveAuxiliary({
	cacheKey,
	configOpts = {},
} = {}) {
	const queryClient = useQueryClient();

	return useMutation(productApi.removeAuxiliary, {
		...configOpts,
		onSuccess: (newData) => {
			queryClient.setQueryData([CACHE_KEY, cacheKey], () => newData);
		},
	});
}

export function useProductsTags({ cacheKey, configOpts = {} }) {
	return useQuery({
		queryKey: [CACHE_KEY, cacheKey],
		queryFn: productApi.getProductsTags,
		keepPreviousData: true,
		...configOpts,
	});
}
