import * as React from 'react';
import PropTypes from 'prop-types';
import {
	useMarketplaceCampaigns,
	useMarketplaceProductsSyncs,
} from '@app/hooks/useMarketplace';

const MarketplaceCampaignContext = React.createContext(undefined);

export function MarketplaceCampaignProvider({ children }) {
	const [refetchInterval, setRefetchInterval] = React.useState(false);
	const { data: productSyncData } = useMarketplaceProductsSyncs({
		configOpts: {
			refetchInterval,
		},
	});

	const {
		data: campaignsData,
		isLoading,
		isFetching,
		refetch,
	} = useMarketplaceCampaigns({});

	React.useEffect(() => {
		if (productSyncData?.data.length !== 0) {
			setRefetchInterval(20000);
		} else {
			refetch();
			setRefetchInterval(false);
		}
	}, [refetch, productSyncData]);

	const value = React.useMemo(
		() => ({
			runningSyncs: productSyncData?.data || [],
			campaigns: campaignsData?.data || [],
			campaignsLoading: isLoading,
			campaignsRefetching: isFetching,
		}),
		[productSyncData, campaignsData, isLoading, isFetching],
	);

	return (
		<MarketplaceCampaignContext.Provider value={value}>
			{children}
		</MarketplaceCampaignContext.Provider>
	);
}

MarketplaceCampaignProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export function useMarketplaceCampaignContext() {
	const context = React.useContext(MarketplaceCampaignContext);
	if (context === undefined) {
		throw new Error(
			'useMarketplaceCampaignContext must be used within a provider',
		);
	}
	return context;
}
