import PropTypes from 'prop-types';

export const CustomerSegmentationType = PropTypes.shape({
	integration: PropTypes.string.isRequired,
	segments: PropTypes.arrayOf(PropTypes.string),
});

export const CampaignAdvancedSettingsType = PropTypes.shape({
	customerSegmentationVisibility: PropTypes.arrayOf(
		CustomerSegmentationType,
	),
});

export const CampaignType = PropTypes.shape({
	app_id: PropTypes.string.isRequired,
	automation_rules: PropTypes.shape({
		autoAcceptInterval: PropTypes.number,
		autoCounterInterval: PropTypes.number,
		bulkDiscounts: PropTypes.bool,
		counterOfferVariability: PropTypes.number,
		enabled: PropTypes.bool,
		fixedCostBuffer: PropTypes.number,
		processAtTheEndOfCampaign: PropTypes.bool,
		thresholdDiscount: PropTypes.number,
		variants: PropTypes.objectOf(
			PropTypes.shape({
				maxEffectiveDiscount: PropTypes.number,
			}),
		),
	}),
	campaign_due_reminder_sent_at: PropTypes.string,
	campaign_ending_reminder_sent_at: PropTypes.string,
	completed_at: PropTypes.string,
	createdAt: PropTypes.string,
	customer_segmentation_visibility: PropTypes.shape({}),
	daily_summary_email_sent_at: PropTypes.string,
	deleted: PropTypes.bool,
	deleted_at: PropTypes.number,
	deposit: PropTypes.number,
	description: PropTypes.string,
	end_date: PropTypes.string,
	fulfillment_trigger: PropTypes.string,
	id: PropTypes.string.isRequired,
	image_url: PropTypes.string,
	inventory_reservation: PropTypes.string,
	merchant_code: PropTypes.string,
	minimum_offer: PropTypes.number,
	name: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.string),
	original_fulfillment_date: PropTypes.string,
	position: PropTypes.number,
	preorders: PropTypes.arrayOf(PropTypes.shape({})),
	processing_error_message: PropTypes.string,
	processing_started_at: PropTypes.string,
	products: PropTypes.arrayOf(PropTypes.string),
	remaining_balance_charge_date: PropTypes.string,
	remaining_balance_charge_trigger: PropTypes.string,
	selling_plan_ids: PropTypes.arrayOf(PropTypes.string),
	selling_plans: PropTypes.arrayOf(PropTypes.shape({})),
	shop: PropTypes.string,
	start_date: PropTypes.string,
	starting_offer: PropTypes.number,
	summary: PropTypes.string,
	type: PropTypes.string,
	updatedAt: PropTypes.string,
	variant_display_name: PropTypes.string,
	variants: PropTypes.arrayOf(PropTypes.string),
});
