import * as React from 'react';

function useForm(options) {
	const [data, setData] = React.useState(
		options?.initialValues || {},
	);
	const [errors, setErrors] = React.useState({});

	const handleChange =
		(key, sanitizeFn) =>
		(event, additionalValues = {}) => {
			const value = sanitizeFn
				? sanitizeFn(event.target.value)
				: event.target.value;
			setData((prevData) => ({
				...prevData,
				[key]: value,
				...additionalValues,
			}));
		};

	const validateForm = () => {
		const validations = options?.validations;
		const newErrors = {};

		if (validations) {
			let valid = true;

			/* eslint-disable no-restricted-syntax */
			/* eslint-disable guard-for-in */
			for (const key in validations) {
				const value = data[key];
				const validation = validations[key];

				const required = validation?.required;
				if (required?.value && !value) {
					valid = false;
					newErrors[key] = required?.message;
				}

				const pattern = validation?.pattern;
				if (pattern?.value && !RegExp(pattern.value).test(value)) {
					valid = false;
					newErrors[key] = pattern.message;
				}

				const custom = validation?.custom;
				if (custom?.isValid && !custom.isValid(value)) {
					valid = false;
					newErrors[key] = custom.message;
				}

				const customMessage = validation?.customMessage;
				if (
					customMessage?.isValid &&
					typeof customMessage.isValid(value) === 'string'
				) {
					valid = false;
					newErrors[key] = customMessage.isValid(value);
				}

				if (!valid) {
					break;
				}
			}
			/* eslint-enable no-restricted-syntax */
			/* eslint-enable guard-for-in */
		}

		setErrors(newErrors); // Here we're setting the error state after the loop
		return newErrors; // Return the newErrors for immediate checking
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const currentErrors = validateForm();

		if (
			Object.keys(currentErrors).length === 0 &&
			options?.onSubmit
		) {
			options.onSubmit();
		}
	};

	return {
		data,
		handleChange,
		handleSubmit,
		errors,
		validateForm,
	};
}

export default useForm;
