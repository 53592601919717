import instance from './index';
import * as urlHelpers from '@app/helpers/urlHelpers';

const apiUrl = '/api/campaigns/nyop/offers';

export async function accept({ offersIds }) {
	const response = await instance.post(`${apiUrl}/accept`, {
		offersIds,
	});

	return response.data;
}

export async function counter({ offersIds, counterOfferAmount }) {
	const response = await instance.post(`${apiUrl}/counter`, {
		offersIds,
		counterOfferAmount,
	});

	return response.data;
}

export async function respond({ actions }) {
	const response = await instance.post(`${apiUrl}/respond`, {
		actions,
	});

	return response.data;
}

export async function getInboxCount() {
	const response = await instance.get(`${apiUrl}/inbox/count`);
	return response.data;
}

export async function getInboxOrders({
	campaignId,
	page,
	perPage,
	getCount,
	sortKey,
	createdAfter,
}) {
	const queryString = urlHelpers.cleanAndBuildUrlQueryParams({
		campaignId,
		page,
		perPage,
		getCount,
		sortKey,
		createdAfter,
	});

	const response = await instance.get(
		`${apiUrl}/inbox?${queryString}`,
	);
	return response.data;
}

export async function getInboxOrder({ orderId }) {
	const encodedUrlParam = encodeURIComponent(orderId);
	const response = await instance.get(
		`${apiUrl}/inbox/${encodedUrlParam}`,
	);
	return response.data;
}
