import * as React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { useCallback, useEffect } from 'react';

import TablePaginationActions from './components/Pagination';

// TODO: Add first and last handlers to finish the optional functionality

export default function CustomPaginationActionsTable({
	data,
	loading,
	columns,
	hasPrevious,
	hasNext,
	onPrevious,
	onNext,
	showFirstAndLast,
	setParentSelected,
	toolbar,
	showSelect,
	selected,
	setSelected,
	page,
	setPage,
	containerStyle,
	defaultRowsPerPage,
	staticHeight,
	withBorder,
}) {
	const [rowsPerPage, setRowsPerPage] = React.useState(
		defaultRowsPerPage,
	);
	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		staticHeight && page > 0
			? Math.max(0, rowsPerPage - data.length)
			: 0;
	// Use column length for dynamic sizing of empty rows and footer
	const columnLength = columns.length;

	/**
	 *
	 * This is all the checkbox stuff
	 */
	useEffect(() => {
		// TODO: Write better way to pass state
		if (showSelect && setParentSelected) {
			setParentSelected(selected);
		}
	}, [selected, setParentSelected, showSelect]);

	const isSelected = (id) => selected.indexOf(id) !== -1;

	const handleCheckboxClick = (event, id) => {
		event.stopPropagation();
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
		setSelected(newSelected);
	};

	/**
	 * End checkbox stuff
	 */

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		const numRows = data?.length || 20;
		setRowsPerPage(parseInt(event.target.value, numRows));
		setPage(0);
	};

	const memoizedActionsComponent = useCallback(
		(props) => (
			<TablePaginationActions
				hasNext={hasNext}
				hasPrevious={hasPrevious}
				onPrevious={onPrevious}
				onNext={onNext}
				showFirstAndLast={showFirstAndLast}
				loading={loading}
				{...props} // eslint-disable-line
			/>
		),
		[
			hasNext,
			hasPrevious,
			onPrevious,
			onNext,
			showFirstAndLast,
			loading,
		],
	);

	/**
	 * The following governs the check all and excludes ineligible variants
	 * and allows for search to quickly get different variants across your shop
	 */
	// Get all the eligible current page's IDs
	const eligibleCurrentPageIds = data
		.filter((row) => !row.checkbox())
		.map((row) => row.id);

	const eligibleSelectedIds = selected.filter((id) =>
		eligibleCurrentPageIds.includes(id),
	);
	const isAllEligibleSelected =
		eligibleCurrentPageIds.length === eligibleSelectedIds.length;

	const allIneligible = data.every((row) => row.checkbox());

	const handleSelectAllClick = () => {
		if (isAllEligibleSelected) {
			// If all eligible are selected, we remove the current page's eligible IDs from the selected array.
			setSelected(
				selected.filter((id) => !eligibleCurrentPageIds.includes(id)),
			);
		} else {
			// If not all eligible are selected, we add the current page's eligible IDs to the selected array.
			// But first, we filter out the IDs that are already in the 'selected' array.
			const newIds = eligibleCurrentPageIds.filter(
				(id) => !selected.includes(id),
			);
			setSelected([...selected, ...newIds]);
		}
	};

	return (
		<>
			{toolbar && toolbar}
			<TableContainer
				component={withBorder ? Paper : null}
				sx={containerStyle}
			>
				<Table
					sx={{ minWidth: 500 }}
					aria-label="Inventory Review Table"
				>
					<TableHead>
						<TableRow
							hover
							// onClick={() => {}}
							role={showSelect ? 'checkbox' : null}
							aria-checked={
								showSelect ? selected.length === rowsPerPage : null
							}
							tabIndex={showSelect ? -1 : null}
							selected={
								showSelect ? selected.length === rowsPerPage : null
							}
						>
							{showSelect && (
								<TableCell padding="checkbox">
									<Checkbox
										// indeterminate={numSelected > 0 && numSelected < rowCount}
										// checked if all products without campaign selected
										checked={isAllEligibleSelected && !allIneligible}
										onChange={handleSelectAllClick}
									/>
								</TableCell>
							)}
							{columns.map((column) => (
								<TableCell
									key={`table-head-cell-${column.id}`}
									align={column.align}
									style={{ minWidth: column.minWidth }}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((obj) => {
							const rowIsSelected = isSelected(obj.id);
							const keys = Object.keys(obj);

							return (
								<TableRow
									key={`table-row-${obj.id}`}
									hover
									// onClick={() => {}}
									role={showSelect ? 'checkbox' : null}
									aria-checked={showSelect ? rowIsSelected : null}
									selected={showSelect ? rowIsSelected : null}
									tabIndex={showSelect ? -1 : null}
								>
									{showSelect && (
										<TableCell padding="checkbox">
											<Checkbox
												onClick={(event) => {
													handleCheckboxClick(event, obj.id);
												}}
												checked={rowIsSelected}
												disabled={
													obj && obj.checkbox ? obj.checkbox() : false
												}
												// indeterminate={numSelected > 0 && numSelected < rowCount}
												// checked={numSelected === rowCount}
												// onChange={onSelectAllClick}
											/>
										</TableCell>
									)}
									{keys.map((key) => {
										// Skip needed id key
										if (key === 'id' || key === 'checkbox') {
											return null;
										}
										return (
											<TableCell
												key={`table-row-cell-${key}-${obj.id}`}
											>
												{typeof obj[key] === 'function'
													? obj[key]()
													: obj[key]}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}

						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell
									colSpan={
										showSelect ? columnLength + 1 : columnLength
									}
								/>
							</TableRow>
						)}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[]}
								colSpan={columnLength}
								count={-1}
								showFirstButton={false}
								showLastButton={false}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: {
										'aria-label': 'rows per page',
									},
									native: true,
								}}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={memoizedActionsComponent}
								labelDisplayedRows={({ from, to, count }) => {
									if (!hasNext) {
										return `${from}–${from + data.length - 1} of ${
											count !== -1
												? count
												: `${from + data.length - 1}`
										}`;
									}
									return `${from}–${to} of ${
										count !== -1 ? count : `more than ${to}`
									}`;
								}}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</>
	);
}

CustomPaginationActionsTable.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			// Other kays will be dynamically tunred into cells
		}),
	),
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
				.isRequired,
			label: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.shape({}),
			]),
			minWidth: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
			]),
			align: PropTypes.string,
		}),
	).isRequired,
	onPrevious: PropTypes.func.isRequired,
	onNext: PropTypes.func.isRequired,
	hasPrevious: PropTypes.bool,
	hasNext: PropTypes.bool,
	showFirstAndLast: PropTypes.bool,
	loading: PropTypes.bool,
	setParentSelected: PropTypes.func,
	toolbar: PropTypes.node,
	selected: PropTypes.arrayOf(PropTypes.string).isRequired,
	setSelected: PropTypes.func.isRequired,
	showSelect: PropTypes.bool,
	page: PropTypes.number.isRequired,
	setPage: PropTypes.func.isRequired,
	containerStyle: PropTypes.objectOf(Object),
	defaultRowsPerPage: PropTypes.number,
	staticHeight: PropTypes.bool,
	withBorder: PropTypes.bool,
};

CustomPaginationActionsTable.defaultProps = {
	data: [],
	hasPrevious: false,
	hasNext: false,
	showFirstAndLast: false,
	loading: false,
	setParentSelected: null,
	toolbar: null,
	showSelect: false,
	containerStyle: {},
	defaultRowsPerPage: 20,
	staticHeight: true,
	withBorder: true,
};
