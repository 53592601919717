import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { useQueryClient } from '@tanstack/react-query';
import { Box } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import addDays from 'date-fns/addDays';
import { useNavigate } from 'react-router-dom';
import constants from '@app/constants';
import { CACHE_KEY as VARIANTS_CACHE_KEY } from '@app/hooks/useVariants';
import {
	addDaysToDate,
	getNyopPresetDate,
} from '@app/helpers/dateHelpers';
import useForm from '@app/hooks/useForm';
import { useSellingPlanGroupCreate } from '@app/hooks/useSellingPlanGroups';
import DepositInput from '@app/pages/createCampaign/Preorder/depositInput';
import InventoryPolicyInput from '@app/pages/createCampaign/Preorder/inventoryPolicyInput';
import CampaignFulfillmentDatePicker from '@app/pages/createCampaign/Preorder/fulfillmentDatePicker';
import ErrorMessage from '@app/components/shared/ui/ErrorMessage';
import SelectCampaignType from '../SelectCampaignType';

import {
	formatDescription,
	formatValues,
	validationConfig,
} from '../../../components/shared/form/SellingPlanGroupForm/helpers';
import CampaignNameInput from '../campaignNameInput';
import { useInventoryReviewContext } from '@app/hooks/useInventoryReviewContext';
import Button from '@app/components/shared/button/Button';

const STEPS = [
	'Details',
	// 'Products',
	// 'Automation',
];

const defaultValues = {
	description: '',
	deposit: constants.deferredPurchaseOptions.depositValue.NoDeposit,
	remainingBalanceChargeDate: addDays(new Date(), 14),
	fulfillment:
		constants.deferredPurchaseOptions.sellingPlanFulfillmentTrigger
			.ExactTime,
	inventory:
		constants.deferredPurchaseOptions.sellingPlanReserve
			.OnFulfillment,
	variants: '',
	name: formatDescription({
		date: addDays(new Date(), 14),
		deposit: constants.deferredPurchaseOptions.depositValue.NoDeposit,
	}),
};

function CreatePreorderCampaignForm({ onCampaignTypeChange }) {
	const navigate = useNavigate();

	const queryClient = useQueryClient();

	const [activeStep] = React.useState(0);
	const activeStepName = STEPS[activeStep];

	const [defaultSelectedVariants] = useInventoryReviewContext();
	const defaultSelectedVariantsIds = defaultSelectedVariants.map(
		(variant) => variant.id,
	);
	const [selectedVariantsIds] = React.useState(
		defaultSelectedVariantsIds,
	);

	const { mutate } = useSellingPlanGroupCreate();

	const { handleSubmit, handleChange, data, errors } = useForm({
		initialValues: {
			...defaultValues,
			variants: selectedVariantsIds
				.map((id) => id.split('gid://shopify/ProductVariant/')[1])
				.join(','),
			startDate: getNyopPresetDate(),
			endDate: addDaysToDate(
				getNyopPresetDate(),
				constants.nyopTiming.defaultCampaignLength,
			),
			remainingBalanceChargeDate: addDaysToDate(
				getNyopPresetDate(),
				constants.nyopTiming.defaultCampaignLength +
					constants.nyopTiming.postEndedDuration,
			),
		},
		validations: validationConfig,
		onSubmit: () => {
			try {
				const {
					productVariantIds,
					sellingPlanGroupName,
					remainingBalanceChargeTrigger,
					remainingBalanceChargeTime,
					deposit: customerDepositPercentage,
					fulfillmentTrigger,
					inventoryReservation,
					sellingPlanDescription,
				} = formatValues(data);

				mutate(
					{
						productVariantIds,
						sellingPlanGroupName,
						remainingBalanceChargeTrigger,
						remainingBalanceChargeTime,
						deposit: customerDepositPercentage,
						fulfillmentTrigger,
						inventoryReservation,
						sellingPlanDescription,
					},
					{
						onSuccess: () => {
							queryClient.invalidateQueries({
								predicate: (query) =>
									query?.queryKey[0] === VARIANTS_CACHE_KEY,
							});
							navigate('/campaigns/preorders');
						},
					},
				);
			} catch (e) {
				console.log(e);
			}
		},
	});

	const STEPS_ACTIONS = {
		Details: {
			actionLabel: 'Create',
			action: (event) => handleSubmit(event),
		},
	};

	const handleNextStep = (event) => {
		const { action } = STEPS_ACTIONS[activeStepName];
		action(event);
	};

	return (
		<>
			<Stepper activeStep={activeStep}>
				{STEPS.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>

			{/* First screen with general campaign configurations */}
			<Box>
				{/* Common field for name of the campaign used for all types of campaigns */}
				<CampaignNameInput
					onChange={handleChange('description')}
					defaultName={data.description}
				/>
				<ErrorMessage message={errors?.description} />

				{/* Campaign selector. Rest of the intput will depend on the selected type */}
				{onCampaignTypeChange && (
					<SelectCampaignType
						onTypeSelect={onCampaignTypeChange}
						defaultValue={constants.campaignTypes.PREORDER}
					/>
				)}

				<DepositInput
					defaultValue={data.deposit}
					onChange={handleChange('deposit')}
				/>
				<CampaignFulfillmentDatePicker
					defaultDate={data.remainingBalanceChargeDate}
					onChange={(value) =>
						handleChange('remainingBalanceChargeDate')(
							{
								target: {
									value,
								},
							},
							{
								endDate: value, // end_date doesn't matter for preorder campaign, it's just updated for consistency
							},
						)
					}
				/>
				<InventoryPolicyInput
					defaultValue={data.inventory}
					onChange={handleChange('inventory')}
				/>

				{/* Common field with the shopper facing campaign name */}
				<Box>
					<TextField
						margin="normal"
						fullWidth
						id="pre-order-name"
						label="Shopper message"
						name="name"
						disabled
						multiline
						value={formatDescription({
							date: data.remainingBalanceChargeDate,
							deposit: data.deposit,
						})}
					/>
				</Box>

				{/* REMOVE THIS AFTER ADDING PRODUCTS PAGE */}
				<TextField
					margin="normal"
					fullWidth
					id="nyop-product"
					label="Variants IDs"
					name="variants"
					onChange={handleChange('variants')}
					value={data.variants}
					helperText="A comma separated list of variant ids from your Shopify admin"
				/>
				<ErrorMessage message={errors?.variants} />

				{/* Bottom block with action buttons */}
				<Box sx={{ display: 'flex', float: 'right', gap: 1 }}>
					{/* Next button that will either take to the next screen or submit */}
					<Button
						sx={{ color: 'button.primary.main' }}
						onClick={handleNextStep}
					>
						{STEPS_ACTIONS[activeStepName].actionLabel}
					</Button>
				</Box>
			</Box>
		</>
	);
}

CreatePreorderCampaignForm.propTypes = {
	onCampaignTypeChange: PropTypes.func.isRequired,
};

export default CreatePreorderCampaignForm;
