import * as React from 'react';
import * as _ from 'lodash-es';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LegendTooltip from '../ui/LegendTooltip';
import useAuth from '@app/hooks/useAuth';
import constants from '@app/constants';

const Record = PropTypes.shape({
	color: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	label: PropTypes.string,
});

export default function ProgressBar({
	data,
	total,
	sx,
	startAdornment = '',
	endAdornment = '',
	showLegend,
	legendInPercentage,
	showUnfilled,
	unfilledLabel,
}) {
	const { formatMerchantNumber } = useAuth();
	const sum = _.reduce(data, (r, i) => r + i.value, 0);

	const getWidthPercent = (value) => {
		if (total === 0) {
			return 0;
		}
		return Math.round((value / total) * 100);
	};

	return (
		<LegendTooltip
			showLegend={showLegend}
			legendInPercentage={legendInPercentage}
			total={total}
			data={data}
			showUnfilled={showUnfilled}
			unfilledLabel={unfilledLabel}
		>
			<Box sx={{ height: '100%', ...sx }}>
				<Box sx={{ display: 'flex' }}>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							borderRadius: '6px',
							overflow: 'hidden',
						}}
					>
						{data.map((r) => (
							<Box
								key={`${r.color}_${Math.random()}`}
								sx={{
									width: `${getWidthPercent(r.value)}%`,
									backgroundColor: r.color,
								}}
							/>
						))}
						{total === 0 && (
							<Box
								sx={{
									width: '100%',
									backgroundColor: constants.colors.darkGrey,
								}}
							/>
						)}
						{(sum === total || total < 0) && sum === 0 && (
							<Box
								sx={{
									width: '100%',
									backgroundColor: constants.colors.darkGrey,
								}}
							/>
						)}
						{sum < total && (
							<Box
								sx={{
									width: `${
										total > 0 ? getWidthPercent(total - sum) : 100
									}%`,
									backgroundColor: constants.colors.darkGrey,
								}}
							/>
						)}
					</Box>
					<Typography
						variant="caption"
						sx={{
							paddingLeft: '4px',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						{getWidthPercent(sum)}%
					</Typography>
				</Box>
				<Typography
					variant="caption"
					sx={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{startAdornment}
					{formatMerchantNumber({ value: sum })}
					{endAdornment}/{startAdornment}
					{formatMerchantNumber({ value: total })}
					{endAdornment}
				</Typography>
			</Box>
		</LegendTooltip>
	);
}

ProgressBar.propTypes = {
	data: PropTypes.arrayOf(Record).isRequired,
	total: PropTypes.number.isRequired,
	sx: PropTypes.instanceOf(Object),
	startAdornment: PropTypes.string,
	endAdornment: PropTypes.string,
	showLegend: PropTypes.bool,
	legendInPercentage: PropTypes.bool,
	showUnfilled: PropTypes.bool,
	unfilledLabel: PropTypes.string,
};

ProgressBar.defaultProps = {
	sx: {},
	startAdornment: '',
	endAdornment: '',
	showLegend: false,
	legendInPercentage: false,
	showUnfilled: true,
	unfilledLabel: 'Pending review',
};
