import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { parsePercents } from '@app/lib/parse';
import constants from '@app/constants/index';

const POTENTIAL_SELLTHROUGH_TOOLTIP =
	'Potential sell-though based on all not canceled offers';
const POSSIBLE_SELLTHROUGH_TOOLTIP =
	'Possible sell-though based on all accepted offers';
const FACTUAL_SELLTHROUGH_TOOLTIP =
	'Actual sell-through generated by the campaign';

function SellthroughRenderer({
	potentialOffersUnits,
	possibleOffersUnits,
	factualOffersUnits,
	availableUnits,
	isFinalized,
}) {
	if (availableUnits <= 0) {
		return <Box sx={{ textAlign: 'center', width: '70%' }}>-</Box>;
	}

	return (
		<Box sx={{ textAlign: 'center', width: '80%' }}>
			<Tooltip
				title={POTENTIAL_SELLTHROUGH_TOOLTIP}
				arrow
				placement="left"
			>
				<Typography color={constants.colors.pending}>
					{parsePercents(potentialOffersUnits / availableUnits)}%
				</Typography>
			</Tooltip>
			<Divider />
			{isFinalized ? (
				<Tooltip
					title={FACTUAL_SELLTHROUGH_TOOLTIP}
					arrow
					placement="left"
				>
					<Typography color={constants.colors.primary}>
						{parsePercents(
							Math.min(factualOffersUnits / availableUnits, 1),
						)}
						%
					</Typography>
				</Tooltip>
			) : (
				<Tooltip
					title={POSSIBLE_SELLTHROUGH_TOOLTIP}
					arrow
					placement="left"
				>
					<Typography color={constants.colors.primary}>
						{parsePercents(
							Math.min(possibleOffersUnits / availableUnits, 1),
						)}
						%
					</Typography>
				</Tooltip>
			)}
		</Box>
	);
}

SellthroughRenderer.propTypes = {
	potentialOffersUnits: PropTypes.number.isRequired,
	possibleOffersUnits: PropTypes.number.isRequired,
	factualOffersUnits: PropTypes.number.isRequired,
	availableUnits: PropTypes.number.isRequired,
	isFinalized: PropTypes.bool.isRequired,
};

export default SellthroughRenderer;
