export const handleCsvDownload = (
	buildHead,
	buildBody,
	columns,
	data,
) => {
	const alteredData = data?.map((row, index) => ({
		index,
		data: row?.data?.map((field, fieldIndex) => {
			const mapper = columns?.[fieldIndex]?.convertToCsv;
			return mapper ? mapper(field, row?.data) : field;
		}),
	}));

	return `${buildHead(columns)}${buildBody(alteredData)}`.trim();
};
