import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Snackbar from '../ui/SnackBar';
import { useProductSync } from '@app/hooks/useMerchant';

const BANNER_CLOSED_KEY = 'product_sync_banner_closed';

const getCookieId = (id) => {
	if (!id) return undefined;
	return `${BANNER_CLOSED_KEY}-${id}`;
};

function ProductSyncInProgressAlert({ open, setOpen }) {
	const [shouldRefetch, setShouldRefetch] = React.useState(true);

	const { data, isLoading } = useProductSync({
		configOpts: {
			enabled: shouldRefetch,
			refetchInterval: 5000,
		},
	});

	const sync = data?.data?.productSync;

	React.useEffect(() => {
		if (isLoading) {
			return;
		}

		if (!sync) {
			setOpen(false);
			setShouldRefetch(false);
			return;
		}

		const cookieId = getCookieId(sync.id);
		const isClosed = cookieId
			? localStorage.getItem(cookieId)
			: false;
		const isCompleted = !!sync.completed_at || !!sync.failed_at;

		const shouldClose = isClosed || isCompleted;
		setOpen(!shouldClose);
		setShouldRefetch(!shouldClose);
		// eslint-disable-next-line
	}, [sync, isLoading]);

	const handleClose = React.useCallback(() => {
		setOpen(false);
		setShouldRefetch(false);

		if (sync && sync.id) {
			localStorage.setItem(
				getCookieId(sync.id),
				Date.now().toString(),
			);
		}
	}, [sync, setOpen]);

	if (!open) {
		return null;
	}

	return (
		<Snackbar
			open={!!open}
			setOpen={handleClose}
			severity="info"
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
			autoHideDuration={null}
			message={
				<Typography sx={{ fontSize: 14 }}>
					Product sync is in progress, please stand by.
				</Typography>
			}
		/>
	);
}

ProductSyncInProgressAlert.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
};

export default ProductSyncInProgressAlert;
