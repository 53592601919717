import * as React from 'react';
import { Link } from '@mui/material';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '../../../components/shared/ui/Card';
import Table from './Table';
import useAuth from '../../../hooks/useAuth';
import Balance from './Balance';

function BillingPage() {
	const { merchant } = useAuth();
	return (
		<>
			<Box sx={{ mb: 1 }}>
				<Card>
					<Typography>
						All payments are facilitated through your stored credit
						card in Shopify. Please use this{' '}
						<Link
							href={`https://${merchant.myshopify_domain}/admin/settings/billing`}
							target="_blank"
							sx={{ textDecoration: 'none' }}
						>
							link
						</Link>{' '}
						to edit any payment information
					</Typography>
				</Card>
			</Box>
			<Balance />
			<Table />
		</>
	);
}

export default BillingPage;
