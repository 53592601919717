import React from 'react';
import MUIDataTable from 'mui-datatables';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {
	createTheme,
	ThemeProvider,
	useTheme,
} from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useMarketplaceProducts } from '@app/hooks/useMarketplace';
import TitleWithImage from '@app/components/muiTable/elements/TitleWithImage';
import useAuth from '@app/hooks/useAuth';
import InfoPopoverIcon from '@app/components/shared/ui/InfoPopoverIcon';
import {
	getProductLink,
	getProductVariantLink,
} from '@app/lib/shopify';
import getNoTableMatch from '@app/components/shared/Table/components/NoTableMatch';
import Button from '@app/components/shared/button/Button';
import ProductCheckboxInput from '@app/pages/marketplace/supplier/table/components/ProductCheckboxInput';
import ProductThresholdInput from '@app/pages/marketplace/supplier/table/components/ProductThresholdInput';
import { useMarketplaceProductsContext } from '@app/pages/marketplace/supplier/context/useMarketProductsContext';
import VariantCheckboxInput from '@app/pages/marketplace/supplier/table/components/VariantCheckboxInput';
import VariantThresholdInput from '@app/pages/marketplace/supplier/table/components/VariantThresholdInput';
import { useCollections } from '@app/hooks/useCollections';
import { capitalizeFirstLetter } from '@app/helpers/stringHelpers';
import { useProductsTags } from '@app/hooks/useProducts';

const DataColumnOptions = {
	viewColumns: false,
	filter: false,
	sort: false,
	download: false,
	display: 'excluded',
};

const DataIndex = {
	formatMerchantNumber: -8,
	inventory_tracked: -7,
	shop: -6,
	id: -5,
	variants: -4,
	formatMerchantCurrency: -3,
	image_url: -2,
	handle: -1,
};

function getDataColumns() {
	return Object.keys(DataIndex).map((name) => ({
		name,
		options: DataColumnOptions,
	}));
}

function getProductTitle(value, data) {
	const imageUrl = data.rowData.at(DataIndex.image_url);
	const handle = data.rowData.at(DataIndex.handle);
	const id = data.rowData.at(DataIndex.id);
	const shop = data.rowData.at(DataIndex.shop);
	return (
		<TitleWithImage
			title={value}
			imageUrl={imageUrl}
			subtitle={handle}
			url={getProductLink(shop, id)}
		/>
	);
}

function getInventoryValue(value, data) {
	const formatMerchantCurrency = data.rowData.at(
		DataIndex.formatMerchantCurrency,
	);

	const amount = formatMerchantCurrency(value);

	if (value < 0) {
		return (
			<Stack direction="row" spacing={3}>
				<Typography>{amount}</Typography>
				<InfoPopoverIcon text="A negative quantity means you likely have Bazo orders pending and a negative inventory quantity.  You should make up the negative balance in revenue when those orders are fulfilled." />
			</Stack>
		);
	}
	return amount;
}

function getInventoryQuantity(value, data) {
	const formatMerchantNumber = data.rowData.at(
		DataIndex.formatMerchantNumber,
	);

	const variants = data.rowData.at(DataIndex.variants);
	const notTracked = variants.every((v) => !v.inventory_tracked);
	const partiallyNotTracked =
		!notTracked && variants.some((v) => !v.inventory_tracked);

	return (
		<Stack direction="row" spacing={3}>
			<Typography>{formatMerchantNumber({ value })}</Typography>
			{notTracked ? (
				<InfoPopoverIcon text="Displayed quantity may not be accurate as all product variants are untracked." />
			) : null}
			{partiallyNotTracked ? (
				<InfoPopoverIcon text="Displayed quantity may not be accurate as some product variants are untracked." />
			) : null}
		</Stack>
	);
}

function getEnabledRenderer(value, data) {
	const variantsIds = data.rowData
		.at(DataIndex.variants)
		.map((v) => v.id);
	return (
		<ProductCheckboxInput
			productId={value}
			variantsIds={variantsIds}
		/>
	);
}

function getThresholdRenderer(value) {
	return <ProductThresholdInput productId={value} />;
}

function MarketplaceProductsTable({ style }) {
	const theme = useTheme();
	const {
		merchant,
		formatMerchantCurrency,
		formatMerchantNumber,
		getCurrencySymbol,
	} = useAuth();

	// filtering/pagination state values

	const [searchTerm, setSearchTerm] = React.useState('');
	const [page, setPage] = React.useState(0);
	const [sortKey, setSortKey] = React.useState(
		'inventory_value,desc',
	);
	const [collectionId, setCollectionId] = React.useState();
	const [marketplaceEnabledFilter, setMarketplaceEnabledFilter] =
		React.useState();
	const [selectedTags, setSelectedTags] = React.useState([]);

	const [perPage, setPerPage] = React.useState(20);

	const { data: collectionData } = useCollections({
		cacheKey: '',
		queryVariables: { query: '' },
	});
	const allCollections = React.useMemo(
		() =>
			(collectionData?.collections || []).map((collectionInfo) => ({
				...collectionInfo,
				title: capitalizeFirstLetter(collectionInfo.title),
			})),
		[collectionData],
	);

	// table state
	const [expandedProductsIds, setExpandedProductsIds] =
		React.useState([]);

	const { isDirty, deriveFromMarketplaceProducts, applyChanges } =
		useMarketplaceProductsContext();

	const { data, isLoading, isFetching, error } =
		useMarketplaceProducts({
			configOpts: {
				enabled: !!merchant,
			},
			cacheKey: `${merchant.id}|${collectionId}|${marketplaceEnabledFilter}|${searchTerm}|${page}|${perPage}|${sortKey}|${selectedTags}`,
			queryVariables: {
				searchTerm,
				page: page + 1,
				collectionId,
				marketplaceEnabledFilter,
				perPage,
				sortKey,
				selectedTags,
			},
		});
	const { data: productsTags } = useProductsTags({
		cacheKey: '',
	});

	React.useEffect(() => {
		if (!data) {
			return;
		}
		const marketplaceProducts = [];
		data.products?.forEach((product) => {
			if (product.marketplace_product) {
				marketplaceProducts.push(product.marketplace_product);
			}
			product.variants?.forEach((variant) => {
				if (variant.marketplace_product) {
					marketplaceProducts.push(variant.marketplace_product);
				}
			});
		});

		deriveFromMarketplaceProducts(marketplaceProducts);
	}, [data, deriveFromMarketplaceProducts]);

	const products = React.useMemo(() => {
		if (data && !isLoading && !isFetching) {
			return data?.products?.map((product) => ({
				...product,
				...product.product_values,
				formatMerchantCurrency,
				formatMerchantNumber,
			}));
		}
		return [];
	}, [
		data,
		formatMerchantCurrency,
		formatMerchantNumber,
		isFetching,
		isLoading,
	]);

	const columns = React.useMemo(
		() =>
			[
				{
					name: 'title',
					label: 'Product',
					options: {
						customBodyRender: getProductTitle,
						filter: false,
					},
				},
				{
					name: 'inventory_value',
					label: `Inventory Value (${getCurrencySymbol()})`,
					options: {
						customBodyRender: getInventoryValue,
						filter: false,
					},
				},
				{
					name: 'inventory_quantity',
					label: 'Inventory Units',
					options: {
						customBodyRender: getInventoryQuantity,
						filter: false,
					},
				},
				{
					name: 'days_in_store',
					label: 'Days In Store',
					options: {
						filter: false,
					},
				},
				{
					name: 'id',
					label: 'Enabled',
					options: {
						customBodyRender: getEnabledRenderer,
						filter: false,
						sort: false,
					},
				},
				{
					name: 'id',
					label: 'Threshold',
					options: {
						customBodyRender: getThresholdRenderer,
						filter: false,
						sort: false,
					},
				},
				{
					name: 'collections',
					label: 'Collections',
					options: {
						viewColumns: false,
						filter: true,
						sort: false,
						download: false,
						display: 'excluded',
						filterOptions: {
							names: allCollections.map(
								(collectionInfo) => collectionInfo.title,
							),
						},
					},
				},
				{
					name: 'enabled',
					label: 'Enabled For Marketplace',
					options: {
						viewColumns: false,
						filter: true,
						sort: false,
						download: false,
						display: 'excluded',
						filterOptions: {
							names: ['Only Enabled', 'Only Disabled'],
						},
					},
				},
				productsTags?.length
					? {
							name: 'tags',
							label: 'Tags',
							options: {
								viewColumns: false,
								filter: true,
								sort: false,
								download: false,
								display: 'excluded',
								filterType: 'multiselect',
								filterOptions: {
									names: productsTags,
								},
							},
					  }
					: null,
				// EXTRA DATA
				...getDataColumns(),
			].filter(Boolean),
		[allCollections, getCurrencySymbol, productsTags],
	);

	const rowsExpanded = React.useMemo(
		() =>
			products
				.map((product, index) => [product.id, index])
				.filter(([productId]) =>
					expandedProductsIds.includes(productId),
				)
				.map(([, index]) => index),
		[expandedProductsIds, products],
	);

	const options = React.useMemo(
		() => ({
			elevation: 0,
			serverSide: true,
			filter: true,
			filterType: 'dropdown',
			search: true,
			onSearchChange: (searchText) => setSearchTerm(searchText),
			searchPlaceholder: 'Search',
			download: false,
			print: false,
			viewColumns: false,
			responsive: 'standard',
			expandableRows: true,
			selectableRows: 'none',
			expandableRowsHeader: false,
			expandableRowsOnClick: true,
			textLabels: {
				body: {
					noMatch: getNoTableMatch({
						isLoading: isLoading || isFetching,
						text: error?.message || 'No products found',
					}),
				},
			},
			rowsExpanded,
			isRowExpandable: (dataIndex) => {
				const variants = products[dataIndex]?.variants;
				return variants && variants.length !== 0;
			},
			renderExpandableRow: (rowData) => {
				const id = rowData.at(DataIndex.id);
				const shop = rowData.at(DataIndex.shop);
				const variants = rowData.at(DataIndex.variants);
				const imageUrl = rowData.at(DataIndex.image_url);

				return variants
					.sort((v1, v2) => (v1.title > v2.title ? 1 : -1))
					.map((variant) => (
						<TableRow key={variant.id}>
							<TableCell
								sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}
							/>
							<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
								<TitleWithImage
									title={variant.title}
									imageUrl={variant.image_url || imageUrl}
									subtitle={variant.sku}
									url={getProductVariantLink(shop, id, variant.id)}
								/>
							</TableCell>
							<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
								{formatMerchantCurrency(
									variant.inventory_quantity *
										(variant.msrp || variant.price),
								)}
							</TableCell>
							<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
								<Stack direction="row" spacing={3}>
									<Typography>
										{formatMerchantNumber({
											value: variant.inventory_quantity,
										})}
									</Typography>
									{!variant.inventory_tracked ? (
										<InfoPopoverIcon
											iconStyles={{ width: '1.2rem' }}
											text="Inventory quantities displayed are not tracked in real-time and may be inaccurate"
										/>
									) : null}
								</Stack>
							</TableCell>
							<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
								-
							</TableCell>
							<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
								<VariantCheckboxInput
									productId={id}
									variantId={variant.id}
								/>
							</TableCell>{' '}
							<TableCell sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}>
								<VariantThresholdInput
									productId={id}
									variantId={variant.id}
								/>
							</TableCell>
						</TableRow>
					));
			},
			onRowExpansionChange: (
				currentRowsExpanded,
				allRowsExpanded,
			) => {
				let newExpandedProductsIds = expandedProductsIds;
				currentRowsExpanded.forEach((currentRow) => {
					const isExpanded = allRowsExpanded.find(
						(row) => row.dataIndex === currentRow.dataIndex,
					);

					const productId = products[currentRow.dataIndex].id;
					if (
						isExpanded &&
						!expandedProductsIds.includes(productId)
					) {
						newExpandedProductsIds.push(productId);
					} else {
						newExpandedProductsIds = newExpandedProductsIds.filter(
							(p) => p !== productId,
						);
					}
				});

				setExpandedProductsIds(newExpandedProductsIds);
			},
			// Sorting configuration
			onColumnSortChange: (column, direction) => {
				setSortKey(`${column},${direction}`);
			},
			// Pagination configurations
			count: data?.count ?? 200,
			page,
			rowsPerPage: perPage,
			rowsPerPageOptions: [10, 20, 50, 200],
			onChangeRowsPerPage: (value) => {
				setPerPage(value);
				setPage(0);
			},
			onChangePage: (newPage) => {
				setPage(newPage);
			},
			onFilterChange: (changedColumn, filterList) => {
				if (changedColumn === 'collections') {
					const collectionTitle = filterList[6]?.[0];
					const selectedCollection = allCollections.find(
						(collectionInfo) =>
							collectionInfo.title === collectionTitle,
					);
					setCollectionId(selectedCollection?.id);
				} else if (changedColumn === 'enabled') {
					const value = filterList[7]?.[0];
					if (value === 'Only Enabled') {
						setMarketplaceEnabledFilter(true);
					} else if (value === 'Only Disabled') {
						setMarketplaceEnabledFilter(false);
					} else {
						setMarketplaceEnabledFilter(null);
					}
				} else if (changedColumn === 'tags') {
					const filterTags = filterList[8];
					setSelectedTags(filterTags);
				} else if (!changedColumn) {
					setCollectionId(null);
					setMarketplaceEnabledFilter(null);
					setSelectedTags([]);
				}
			},
		}),
		[
			data,
			error,
			expandedProductsIds,
			formatMerchantCurrency,
			formatMerchantNumber,
			isFetching,
			isLoading,
			page,
			perPage,
			products,
			rowsExpanded,
			allCollections,
		],
	);

	const getMuiTheme = () => {
		const overrides = {
			components: {
				MUIDataTable: {
					styleOverrides: {
						root: {},
					},
				},
				MUIDataTableToolbar: {
					styleOverrides: {
						filterPaper: {
							minWidth: '500px',
						},
					},
				},
			},
		};
		if (style?.removeFrame) {
			overrides.components.MUIDataTable.styleOverrides.root.boxShadow =
				'none';
		}

		return createTheme(theme, overrides);
	};

	const table = React.useMemo(
		() => (
			<MUIDataTable
				title={style?.hideTitle ? '' : 'Marketplace Inventory'}
				data={products}
				columns={columns}
				options={options}
			/>
		),
		[style, products, columns, options],
	);

	return (
		<ThemeProvider theme={getMuiTheme()}>
			<Box
				sx={{
					position: 'sticky',
					width: { xs: '100%', md: '25%', lg: '20%' },
					top: '5rem',
					mb: 1,
					ml: 'auto',
					zIndex: 1000,
					textAlign: {
						xs: 'right',
						lg: 'right',
					},
				}}
			>
				<Button
					onClick={applyChanges}
					color="primary"
					variant="contained"
					disabled={!isDirty}
					sx={{ width: '100%' }}
				>
					Save
				</Button>
			</Box>
			{table}
		</ThemeProvider>
	);
}

MarketplaceProductsTable.propTypes = {
	style: PropTypes.shape({
		removeFrame: PropTypes.bool,
		hideTitle: PropTypes.bool,
	}),
};

export default MarketplaceProductsTable;
