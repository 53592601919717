import React from 'react';
import SupplierMarketplaceCampaignsTable from './table/index';
import { MarketplaceCampaignProvider } from '@app/hooks/useMarketplaceCampaignContext';

function SupplierMarketplaceCampaigns() {
	return (
		<MarketplaceCampaignProvider>
			<SupplierMarketplaceCampaignsTable />
		</MarketplaceCampaignProvider>
	);
}

export default SupplierMarketplaceCampaigns;
