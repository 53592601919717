import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { isNaN } from 'lodash-es';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

function AutomationBulkDiscountTier({
	disabled,
	onChange,
	onDelete,
	defaultTier,
	maxDiscount,
}) {
	const [quantity, setQuantity] = React.useState(
		defaultTier.quantity.toString(),
	);
	const [extraDiscount, setExtraDiscount] = React.useState(
		defaultTier.extraDiscount.toString(),
	);

	const handleChangeQuantity = (data) => {
		const value = Number.parseInt(data.target.value, 10);

		if (isNaN(value)) {
			setQuantity('');
		} else {
			setQuantity(value.toString());
		}

		onChange({
			...defaultTier,
			quantity: value,
			extraDiscount: Number.parseInt(extraDiscount, 10),
		});
	};

	const handleChangeDiscount = (data) => {
		let value = Number.parseInt(data.target.value, 10) || 0;

		if (isNaN(value) || value <= 0) {
			value = 0;
		} else if (value > maxDiscount) {
			value = maxDiscount;
		}
		setExtraDiscount(value.toString());
		onChange({
			...defaultTier,
			quantity: Number.parseInt(quantity, 10),
			extraDiscount: value,
		});
	};

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				gap: 2,
				mt: 3,
			}}
		>
			<Box sx={{ alignSelf: 'center' }}>
				{defaultTier.message ? (
					<Tooltip
						title={<Typography>{defaultTier.message}</Typography>}
					>
						<WarningOutlinedIcon />
					</Tooltip>
				) : null}
			</Box>

			<TextField
				name="quantity"
				label="Quantity"
				type="number"
				value={quantity}
				disabled={disabled}
				onChange={handleChangeQuantity}
				onWheel={(event) => {
					event.target.blur();
				}}
				sx={{ width: '100%' }}
			/>

			<TextField
				name="extraDiscount"
				label="Extra Discount"
				type="number"
				value={extraDiscount}
				disabled={disabled}
				onChange={handleChangeDiscount}
				sx={{ width: '100%' }}
				onWheel={(event) => {
					event.target.blur();
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">%</InputAdornment>
					),
				}}
			/>

			<IconButton onClick={onDelete} disabled={disabled}>
				<ClearIcon sx={{ width: 20, height: 20 }} />
			</IconButton>
		</Box>
	);
}

AutomationBulkDiscountTier.propTypes = {
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	maxDiscount: PropTypes.number.isRequired,
	defaultTier: PropTypes.shape({
		extraDiscount: PropTypes.number.isRequired,
		quantity: PropTypes.number.isRequired,
		message: PropTypes.string,
	}),
};

AutomationBulkDiscountTier.defaultProps = {
	disabled: false,
	defaultTier: {},
};

export default AutomationBulkDiscountTier;
