import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React from 'react';

import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '@app/hooks/useAuth';
import Snackbar from '../ui/SnackBar';

const SUBSCRIPTIONS_BANNER_CLOSED_COOKIE_KEY =
	'subscriptions_banner_closed_timestamp';
const ROUTES_BLACK_LIST = ['settings', 'onboarding'];

function SubscriptionsWarningAlert({ open, setOpen }) {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { merchant } = useAuth();

	const isRouteInBlackList = React.useMemo(
		() => ROUTES_BLACK_LIST.some((route) => pathname.includes(route)),
		[pathname],
	);

	React.useEffect(() => {
		const lastClosedTimestamp = Cookies.get(
			SUBSCRIPTIONS_BANNER_CLOSED_COOKIE_KEY,
		);

		const isOpen =
			!lastClosedTimestamp &&
			!isRouteInBlackList &&
			!merchant.active_subscription;
		setOpen(isOpen);
		// eslint-disable-next-line
	}, [isRouteInBlackList, merchant.active_subscription]);

	const handleNavigate = React.useCallback(() => {
		setOpen(false);
		navigate('/settings/subscriptions');
	}, [navigate, setOpen]);

	const handleClose = React.useCallback(
		(value) => {
			setOpen(value);
			Cookies.set(
				SUBSCRIPTIONS_BANNER_CLOSED_COOKIE_KEY,
				Date.now(),
				{
					expires: 1,
				},
			);
		},
		[setOpen],
	);

	return (
		<Snackbar
			open={!!open}
			setOpen={handleClose}
			severity="info"
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
			autoHideDuration={null}
			message={
				<>
					<Typography sx={{ fontSize: 13 }}>
						Please select a subscription to proceed.
					</Typography>
					<Typography sx={{ fontSize: 13 }}>
						You cannot create a Campaign until you&apos;ve subscribed.
						We have a free tier if you just want to explore.
					</Typography>
					<ButtonBase
						sx={{
							fontSize: 13,
							textDecoration: 'underline',
							fontWeight: 600,
						}}
						onClick={handleNavigate}
					>
						Click here to select a subscription
					</ButtonBase>
				</>
			}
		/>
	);
}

SubscriptionsWarningAlert.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
};

export default SubscriptionsWarningAlert;
