import React from 'react';
import PropTypes from 'prop-types';

const OffersContext = React.createContext();

function OffersProvider({ children }) {
	const counterOffersInitialState = [];
	const [offerStatus, setOfferStatus] = React.useState(
		counterOffersInitialState,
	);
	const memoedValues = React.useMemo(
		() => ({
			offerStatus,
			setOfferStatus,
		}),
		[offerStatus, setOfferStatus],
	);

	return (
		<OffersContext.Provider value={memoedValues}>
			{children}
		</OffersContext.Provider>
	);
}

OffersProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default OffersProvider;

export function useOffers() {
	return React.useContext(OffersContext);
}
