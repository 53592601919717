import * as React from 'react';
import PropTypes from 'prop-types';
import MuiDataTable from 'mui-datatables';

import ProductThumbnail from '../ui/ProductThumbnail';

import useAuth from '../../../hooks/useAuth';
import getNoTableMatch from '@app/components/shared/Table/components/NoTableMatch';

const columns = [
	{
		name: 'image',
		label: ' ',
		options: {
			sort: false,
			filter: false,
			customBodyRender: (imageObj) => (
				<ProductThumbnail variant={imageObj} />
			),
		},
	},
	{
		name: 'displayName',
		label: 'Product',
	},
	{
		name: 'title',
		label: 'Title',
		options: {
			display: false,
		},
	},
	{
		name: 'sku',
		label: 'SKU',
	},
	{
		name: 'price',
		label: 'Price',
	},
	{
		name: 'inventoryQuantity',
		label: 'Inventory Quantity',
		options: {
			filter: false,
		},
	},
	{
		name: 'product.productType',
		label: 'Type',
	},
	{
		name: 'id',
		label: 'ID',
		options: {
			display: false,
		},
	},
];

function VariantsTable({ variants, isFetching }) {
	const { formatMerchantCurrency } = useAuth();

	return (
		<MuiDataTable
			title="Campaign Variants"
			data={
				isFetching
					? []
					: variants.map((v) => ({
							...v,
							price: formatMerchantCurrency(v.price),
							image: {
								image: v.image,
								product: v.product,
								displayName: v.displayName,
							},
					  }))
			}
			columns={columns}
			options={{
				download: false,
				print: false,
				filter: true,
				filterType: 'dropdown',
				responsive: 'vertical',
				enableNestedDataAccess: '.',
				storageKey: 'bazo-campaign-management-variant-table',
				selectableRowsHideCheckboxes: true,
				viewColumns: true,
				textLabels: {
					body: {
						noMatch: getNoTableMatch({
							isLoading: isFetching,
							text: 'Sorry, no Variants found',
						}),
					},
				},
			}}
		/>
	);
}

VariantsTable.propTypes = {
	variants: PropTypes.arrayOf(PropTypes.shape({})),
	isFetching: PropTypes.bool,
};
VariantsTable.defaultProps = {
	variants: [],
	isFetching: false,
};

export default VariantsTable;
