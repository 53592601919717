import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';

import * as marketplaceApi from '../api/marketplace';

export const CACHE_KEY = 'marketplace';

export function useMarketplaceProducts({
	cacheKey,
	queryVariables,
	configOpts = {},
}) {
	return useQuery({
		queryKey: [CACHE_KEY, cacheKey],
		queryFn: () => marketplaceApi.getProducts(queryVariables),
		keepPreviousData: true,
		...configOpts,
	});
}

export function useUpdateVariants({ configOpts = {} } = {}) {
	const queryClient = useQueryClient();

	return useMutation(marketplaceApi.updateVariants, {
		...configOpts,
		onSettled: () => queryClient.invalidateQueries([CACHE_KEY]),
	});
}

export function useMarketplaceEligibleShops({ configOpts = {} }) {
	return useQuery({
		queryKey: [CACHE_KEY, 'eligibleShops'],
		queryFn: () => marketplaceApi.getEligibleShops(),
		keepPreviousData: true,
		...configOpts,
	});
}

export function useCreateMarketplaceCampaigns({ configOpts = {} }) {
	const queryClient = useQueryClient();

	return useMutation(marketplaceApi.createMarketplaceCampaign, {
		...configOpts,
		onSettled: () => queryClient.invalidateQueries([CACHE_KEY]),
	});
}

export function useMarketplaceCampaigns({ configOpts = {} }) {
	return useQuery({
		queryKey: [CACHE_KEY, 'campaigns'],
		queryFn: () => marketplaceApi.getMarketplaceCampaigns(),
		keepPreviousData: true,
		...configOpts,
	});
}

export function useMarketplaceCampaign({ id, configOpts = {} }) {
	return useQuery({
		queryKey: [CACHE_KEY, 'campaigns', id],
		queryFn: () => marketplaceApi.getMarketplaceCampaign({ id }),
		keepPreviousData: true,
		...configOpts,
	});
}

export function useMarketplaceCampaignInbox({
	id,
	queryKey,
	queryVariables,
	configOpts = {},
}) {
	return useQuery({
		queryKey: [CACHE_KEY, 'campaigns', id, 'inbox', queryKey],
		queryFn: () =>
			marketplaceApi.getMarketplaceCampaignInbox({
				id,
				queryVariables,
			}),
		keepPreviousData: true,
		...configOpts,
	});
}

export function useCompleteMarketplaceCampaign({ configOpts = {} }) {
	const queryClient = useQueryClient();

	return useMutation(marketplaceApi.completeMarketplaceCampaign, {
		...configOpts,
		onSettled: () => queryClient.invalidateQueries([CACHE_KEY]),
	});
}

export function useMarketplaceProductsSyncs({ configOpts = {} }) {
	return useQuery({
		queryKey: [CACHE_KEY, 'syncs'],
		queryFn: () => marketplaceApi.getMarketplaceProductsSyncs(),
		...configOpts,
	});
}

export function useSyncMarketplaceProducts({ configOpts = {} }) {
	const queryClient = useQueryClient();

	return useMutation(marketplaceApi.syncMarketplaceProducts, {
		...configOpts,
		onSettled: () =>
			queryClient.invalidateQueries([CACHE_KEY, 'syncs']),
	});
}

export function useSyncMarketplaceCampaignProducts({
	configOpts = {},
}) {
	const queryClient = useQueryClient();

	return useMutation(marketplaceApi.syncMarketplaceCampaignProducts, {
		...configOpts,
		onSettled: () =>
			queryClient.invalidateQueries([CACHE_KEY, 'syncs']),
	});
}
