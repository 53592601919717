import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import SelectInput from '../../../inputs/SelectInput';
import RadioButtonGroup from '../../../inputs/RadioButtonGroup';
import InfoPopover from '../../../ui/InfoPopoverIcon';
import ErrorMessage from '../../../ui/ErrorMessage';

import { arrayFromObjectValues } from '../../../../../helpers/objHelpers';
import { formatDescription } from '../helpers';
import constants from '../../../../../constants';
import { GA_LABEL } from '../../../../../constants/googleAnalytics';
import Button from '@app/components/shared/button/Button';

function CampaignForm({
	values,
	titleText,
	buttonText,
	handleSubmit,
	handleChange,
	loading,
	errors,
	fetchError,
}) {
	const [delayWarning, setDelayWarning] = React.useState(false);

	return (
		<Box
			sx={{
				marginTop: 2,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<Typography
				id="selling-plan-group-form-title"
				component="h1"
				variant="h5"
			>
				{titleText}
			</Typography>
			<Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
				<TextField
					data-ga-label={GA_LABEL}
					margin="normal"
					fullWidth
					id="name"
					label="description"
					name="description"
					autoFocus
					onChange={handleChange('description')}
					value={values.description}
				/>
				<ErrorMessage message={errors?.description} />
				<SelectInput
					name="deposit"
					value={values.deposit}
					label="Deposit"
					handleChange={handleChange('deposit')}
					inputLabelText="Deposit"
					fullWidth
					options={[
						{
							label: 'No deposit',
							value:
								constants.deferredPurchaseOptions.depositValue
									.NoDeposit,
						},
						{
							label: '25% deposit',
							value:
								constants.deferredPurchaseOptions.depositValue
									.Deposit25,
						},
						{
							label: '50% deposit',
							value:
								constants.deferredPurchaseOptions.depositValue
									.Deposit50,
						},
						{
							label: '75% deposit',
							value:
								constants.deferredPurchaseOptions.depositValue
									.Deposit75,
						},
						{
							label: 'Full deposit',
							value:
								constants.deferredPurchaseOptions.depositValue
									.Deposit100,
						},
					]}
				/>
				<ErrorMessage message={errors?.deposit} />
				<Box display="inline-flex" sx={{ alignItems: 'center' }}>
					<DatePicker
						required
						disablePast
						views={['day']}
						label="Estimated fulfillment date"
						name="remainingBalanceChargeDate"
						value={values.remainingBalanceChargeDate}
						onChange={(newValue) => {
							// Need to spoof event obj with Mui Date Picker to work with hook
							handleChange('remainingBalanceChargeDate')({
								target: {
									name: 'remainingBalanceChargeDate',
									value: newValue,
								},
							});
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								margin="normal"
								helperText={null}
								display="block"
							/>
						)}
						onAccept={() => {
							if (values?.id) {
								setDelayWarning(true);
							}
						}}
					/>
					<Box ml={1}>
						<InfoPopover
							text={`
								Estimated date for fulfillment.
								Any remaining customer balance will be charged then as well.
								You can change this at any time.`}
						/>
					</Box>
				</Box>
				<ErrorMessage message={errors?.remainingBalanceChargeDate} />
				{delayWarning && (
					<Typography color="error">
						Delaying fulfillment date will send update emails to
						customers with existing orders
					</Typography>
				)}
				<TextField
					margin="normal"
					fullWidth
					id="pre-order-name"
					label="Shopper message"
					name="name"
					disabled
					multiline
					// TODO: make this editable
					value={formatDescription({
						date: values.remainingBalanceChargeDate,
						deposit: values.deposit,
					})}
				/>
				<ErrorMessage message={errors?.name} />
				{/* <FormControl sx={{ m: 3 }} error={error} variant="standard"> */}
				<Grid container spacing={1}>
					{/* TODO: Clean this up and remove.
					Not needed with Exact delivery date */}

					{/* <Grid item md={6}>
						<RadioButtonGroup
							name="fulfillment"
							value={values.fulfillment}
							handleChange={handleChange}
							formLabelText="Fulfillment"
							radios={[
								{
									label: 'As Soon as possible',
									helperText:
										'The order is fulfilled when the order is created',
									value:
										constants.deferredPurchaseOptions
											.sellingPlanFulfillmentTrigger.Asap,
								},
								{
									label: 'Unknown',
									helperText: 'The fulfillment date is unknown',
									value:
										constants.deferredPurchaseOptions
											.sellingPlanFulfillmentTrigger.Unknown,
								},
							]}
						/>
					</Grid> */}
					<Grid item md={12}>
						<RadioButtonGroup
							name="inventory"
							value={values.inventory}
							handleChange={handleChange('inventory')}
							formLabelText="Inventory Policy"
							radios={[
								{
									label: 'On fulfillment',
									helperText:
										'Inventory is updated when the order is fulfilled',
									value:
										constants.deferredPurchaseOptions
											.sellingPlanReserve.OnFulfillment,
								},
								{
									label: 'On sale',
									helperText:
										'Inventory is updated when the order is created',
									value:
										constants.deferredPurchaseOptions
											.sellingPlanReserve.OnSale,
								},
							]}
						/>
					</Grid>
				</Grid>
				<ErrorMessage message={errors?.inventory} />
				<TextField
					margin="normal"
					fullWidth
					id="pre-order-product"
					label="variant id"
					name="variants"
					onChange={handleChange('variants')}
					value={values.variants}
					helperText="A comma separated list of variant ids from your Shopify admin"
				/>
				<ErrorMessage message={errors?.variants} />
				<Button
					type="submit"
					fullWidth
					sx={{ mt: 3, mb: 2 }}
					disabled={
						values.description === '' || values.variants === ''
					}
					loading={loading}
				>
					{buttonText}
				</Button>
				{fetchError && (
					<ErrorMessage
						message={
							fetchError.message
								? fetchError.message
								: 'There was a problem creating this Campaign. Please try again.'
						}
					/>
				)}
			</Box>
		</Box>
	);
}

CampaignForm.propTypes = {
	values: PropTypes.shape({
		id: PropTypes.string,
		description: PropTypes.string,
		name: PropTypes.string,
		deposit: PropTypes.oneOf(
			arrayFromObjectValues(
				constants.deferredPurchaseOptions.depositValue,
			),
		),
		remainingBalanceChargeDate: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.instanceOf(Date),
		]),
		fulfillment: PropTypes.oneOf(
			arrayFromObjectValues(
				constants.deferredPurchaseOptions
					.sellingPlanFulfillmentTrigger,
			),
		),
		inventory: PropTypes.oneOf(
			arrayFromObjectValues(
				constants.deferredPurchaseOptions.sellingPlanReserve,
			),
		),
		variants: PropTypes.string,
	}).isRequired,
	titleText: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	buttonText: PropTypes.string,
	loading: PropTypes.bool,
	errors: PropTypes.shape({
		description: PropTypes.string,
		deposit: PropTypes.string,
		remainingBalanceChargeDate: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.instanceOf(Date),
		]),
		fulfillment: PropTypes.string,
		inventory: PropTypes.string,
		variants: PropTypes.string,
		name: PropTypes.string,
	}),
	fetchError: PropTypes.oneOfType([
		PropTypes.instanceOf(Error),
		PropTypes.bool,
		PropTypes.shape({
			code: PropTypes.number,
			message: PropTypes.string,
			stack: PropTypes.string,
		}),
	]),
};

CampaignForm.defaultProps = {
	buttonText: 'Submit',
	loading: false,
	errors: {},
	fetchError: null,
};

export default CampaignForm;
