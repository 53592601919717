import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { useQueryClient } from '@tanstack/react-query';
import { Box } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import EditIcon from '@mui/icons-material/Edit';
import { isNil } from 'lodash-es';
import constants from '@app/constants';
import {
	useNyopCampaignCreate,
	useNyopCampaignUpdate,
} from '@app/hooks/useNyopCampaign';
import useAuth from '@app/hooks/useAuth';
import { CACHE_KEY as VARIANTS_CACHE_KEY } from '@app/hooks/useVariants';
import {
	addDaysToDate,
	getNyopPresetDate,
} from '@app/helpers/dateHelpers';
import useForm from '@app/hooks/useForm';
import { useAutomationRules } from '@app/hooks/useAutomationRules';
import AutomationBlock from './automationBlock';
import SelectCampaignType from '../SelectCampaignType';

import {
	formatNyopDescription,
	formatNyopValues,
} from '../../../components/shared/form/SellingPlanGroupForm/helpers';
import CampaignNameInput from '../campaignNameInput';
import StartEndDatesPicker from './startEndDatesPicker';
import ProductsStep from './productsStep';
import stickyContainerStyles from '@app/styles/stickyContainerStyles';
import { useInventoryReviewContext } from '@app/hooks/useInventoryReviewContext';
import { useCreateCampaignContext } from '@app/hooks/useCreateCampaignContext';
import ModalScreen from '@app/components/shared/modal/ModalScreen';
import SelectSubscriptionModal from './components/SelectSubscriptionModal';
import campaignModalStyles from '@app/styles/campaignModalStyles';
import Button from '@app/components/shared/button/Button';
import ConversationalInputWrapper from '@app/components/shared/inputs/ConversationalTextInput';
import AdvancedSettingsBlock from './components/advancedSettings';
import { FLAGS, useFlagEnabled } from '@app/hooks/useFlags';
import {
	useCompleteMarketplaceCampaign,
	useCreateMarketplaceCampaigns,
} from '@app/hooks/useMarketplace';

const DETAILS_STEP = 0;
const PRODUCTS_STEP = 1;
const AUTOMATION_STEP = 2;

const STEPS = ['Details', 'Products', 'Automation'];

const defaultValues = {
	name: '',
	extendChargeDateWithCounterDelay: true,
};

const validationConfig = {
	description: {
		required: {
			value: true,
			message:
				'You must provide a description for your Campaign. It will help you identify and manage this Campaign later.',
		},
	},
	variants: {
		custom: {
			isValid: (value) => {
				const arrFrom = value.split(',');
				const hasDuplicates =
					new Set(arrFrom).size !== arrFrom.length;

				return value.slice(-1) !== ',' && !hasDuplicates;
			},
			message: 'Please remove duplicates or trailing comma.',
		},
	},
};

function CreateNyopCampaignForm({
	onCampaignTypeChange,
	onCompleteNavigate,
	defaultCampaign,
}) {
	const navigate = useNavigate();
	const { getCurrencySymbol, merchant } = useAuth();
	const queryClient = useQueryClient();
	const klaviyoIntegrationFeatureEnabled = useFlagEnabled(
		FLAGS.KLAVIYO_INTEGRATION,
	);

	const [shopperMessageEditable, setShopperMessageEditable] =
		React.useState(false);
	const [shopperMessageCustomized, setShopperMessageCustomized] =
		React.useState(!!defaultCampaign?.campaign?.name);
	const [areCounterValuesValid, setAreCounterValuesValid] =
		React.useState(true);
	const [editByProductModal, setEditByProductModal] =
		React.useState(false);
	const [selectSubscriptionModal, setSelectSubscriptionModal] =
		React.useState(false);

	const {
		createCampaignInfo,
		setCreateCampaignInfo,
		resetCreateCampaignInfo,
	} = useCreateCampaignContext();
	const [selectedVariants, setSelectedVariants] =
		useInventoryReviewContext();
	const selectedVariantsIds = React.useMemo(
		() =>
			selectedVariants.map((variant) =>
				typeof variant === 'string' ? variant : variant.id,
			),
		[selectedVariants],
	);

	const [activeStep, setActiveStep] = React.useState(
		defaultCampaign ? DETAILS_STEP : createCampaignInfo.step,
	);

	const [automationBlockValid, setAutomationBlockValid] =
		React.useState(true);
	const { automationRules, setAutomationRules } = useAutomationRules({
		selectedVariants,
		defaultAutomationRules:
			defaultCampaign?.campaign?.automation_rules ||
			createCampaignInfo.automationRules,
	});

	const { mutate: completeMarketplaceCampaign } =
		useCompleteMarketplaceCampaign({});

	const {
		mutate: createCampaign,
		isLoading: isLoadingCreateCampaign,
	} = useNyopCampaignCreate({
		configOpts: {
			onSuccess: ({ data }) => {
				if (createCampaignInfo.marketplaceCampaignId) {
					return completeMarketplaceCampaign({
						id: createCampaignInfo.marketplaceCampaignId,
						createdCampaignId: data.id,
					});
				}
				return null;
			},
		},
	});

	const {
		mutate: createMarketplaceCampaign,
		isLoading: isLoadingCreateMarketplaceCampaign,
	} = useCreateMarketplaceCampaigns({});

	const {
		mutate: updateCampaign,
		isLoading: isLoadingUpdateCampaign,
	} = useNyopCampaignUpdate();

	let mutate = null;
	if (createCampaignInfo?.isMarketplace) {
		mutate = createMarketplaceCampaign;
	} else {
		mutate = defaultCampaign ? updateCampaign : createCampaign;
	}

	const { handleSubmit, handleChange, data, errors, validateForm } =
		useForm({
			initialValues: {
				...defaultValues,
				description: defaultCampaign?.campaign?.description || '',
				variants: selectedVariantsIds
					.map((id) => id.split('gid://shopify/ProductVariant/')[1])
					.join(','),
				startDate: defaultCampaign?.campaign?.start_date
					? new Date(defaultCampaign?.campaign?.start_date)
					: getNyopPresetDate(),
				// eslint-disable-next-line no-nested-ternary
				endDate: defaultCampaign?.campaign
					? defaultCampaign.campaign.end_date
						? new Date(defaultCampaign?.campaign?.end_date)
						: defaultCampaign?.campaign?.end_date
					: addDaysToDate(
							getNyopPresetDate(),
							constants.nyopTiming.defaultCampaignLength,
					  ),
				extendChargeDateWithCounterDelay: true,
				sellingPlanGroupName:
					defaultCampaign?.campaign?.name ||
					formatNyopDescription({
						chargeAfter: 2,
						currencySymbol: getCurrencySymbol(),
						automationGreatestInterval: Math.max(
							automationRules.autoAcceptInterval,
							automationRules.autoCounterInterval,
						),
					}),
				customerSegmentationVisibility:
					defaultCampaign?.campaign?.customer_segmentation_visibility,
				startingOfferInfo: {
					minimumOffer: isNil(
						defaultCampaign?.campaign?.minimum_offer,
					)
						? null
						: +defaultCampaign.campaign.minimum_offer * 100,
					startingOffer: isNil(
						defaultCampaign?.campaign?.starting_offer,
					)
						? null
						: +defaultCampaign.campaign.starting_offer * 100,
				},
				// Create campaign context is used here
				...(!defaultCampaign && createCampaignInfo.fieldsData),
			},
			validations: validationConfig,
			// UseForm will be skipped unless the last page. If we change that then
			// We will have to tell it which page to run on
			...(activeStep === STEPS.length - 1 && {
				onSubmit: () => {
					if (
						!merchant.active_subscription &&
						!createCampaignInfo.isMarketplace
					) {
						setSelectSubscriptionModal(true);
						return;
					}

					try {
						const {
							productVariantIds,
							sellingPlanGroupName,
							sellingPlanDescription,
							startDate,
							endDate,
							extendChargeDateWithCounterDelay,
							customerSegmentationVisibility,
							startingOfferInfo,
						} = formatNyopValues({
							...data,
							currencySymbol: getCurrencySymbol(),
						});
						mutate(
							{
								id: defaultCampaign?.campaign?.id,
								productVariantIds,
								sellingPlanGroupName,
								sellingPlanDescription,
								startDate,
								endDate,
								automationRules,
								extendChargeDateWithCounterDelay,
								customerSegmentationVisibility,
								startingOfferInfo,
							},
							{
								onSuccess: () => {
									queryClient.invalidateQueries({
										predicate: (query) =>
											query?.queryKey[0] === VARIANTS_CACHE_KEY,
									});
									if (!defaultCampaign) {
										resetCreateCampaignInfo();
										setSelectedVariants([]);
									}
									navigate(onCompleteNavigate || '..', {
										relative: 'path',
									});
								},
							},
						);
					} catch (e) {
						console.error(e);
					}
				},
			}),
		});

	React.useEffect(() => {
		if (!defaultCampaign) {
			setCreateCampaignInfo((prevInfo) => ({
				...prevInfo,
				fieldsData: data,
				automationRules,
				step: activeStep,
			}));
		}
	}, [
		data,
		automationRules,
		defaultCampaign,
		setCreateCampaignInfo,
		activeStep,
	]);

	React.useEffect(() => {
		handleChange('variants')({
			target: {
				value: selectedVariantsIds
					.map((id) => id.split('gid://shopify/ProductVariant/')[1])
					.join(','),
			},
		});
		// eslint-disable-next-line
	}, [selectedVariantsIds]);

	React.useEffect(() => {
		if (shopperMessageCustomized) {
			return;
		}

		const value = formatNyopDescription({
			chargeAfter: 2,
			currencySymbol: getCurrencySymbol(),
			automationGreatestInterval: Math.max(
				automationRules.autoAcceptInterval,
				automationRules.autoCounterInterval,
			),
		});

		handleChange('sellingPlanGroupName')({
			target: {
				value,
			},
		});
		// handleChange breaks useEffect if we put it in dependency array
		// eslint-disable-next-line
	}, [getCurrencySymbol, shopperMessageCustomized, automationRules]);

	const STEPS_ACTIONS = {
		[DETAILS_STEP]: {
			actionLabel: 'Next',
			action: (event) => handleSubmit(event),
			isDisabled: () => false,
		},
		[PRODUCTS_STEP]: {
			actionLabel: 'Next',
			action: () => {},
			isDisabled: () => !selectedVariantsIds.length,
		},
		[AUTOMATION_STEP]: {
			actionLabel: defaultCampaign ? 'Update' : 'Create',
			action: (event) => {
				if (
					!createCampaignInfo.isMarketplace && // Don't want to validate against MSRP when we change it on product import
					!areCounterValuesValid
				) {
					setEditByProductModal(true);
					return;
				}
				handleSubmit(event);
			},
			isDisabled: () => !automationBlockValid,
		},
	};

	const onAdvancedSettingsChange = (value) => {
		handleChange('customerSegmentationVisibility')({
			target: {
				value: value.customerSegmentationVisibility,
			},
		});
	};

	const onStartingOfferInfoChange = (value) => {
		handleChange('startingOfferInfo')({
			target: {
				value,
			},
		});
	};

	const onShopperMessageCustomized = (event) => {
		handleChange('sellingPlanGroupName')(event);
		setShopperMessageCustomized(true);
	};

	const onStartEndDateChange = (startDate, endDate) => {
		handleChange('remainingBalanceChargeDate')(
			{
				target: {
					value: addDaysToDate(
						typeof endDate !== 'string' ? endDate : parseISO(endDate),
						1,
					),
				},
			},
			{
				startDate,
				endDate,
			},
		);
	};

	const moveToNextStep = () => {
		if (STEPS.length > activeStep + 1) {
			setActiveStep(activeStep + 1);
		}
	};

	const moveToPreviousStep = () => {
		if (activeStep !== 0) {
			setActiveStep(activeStep - 1);
		}
	};

	const handleNextStep = async (event) => {
		const { action } = STEPS_ACTIONS[activeStep];
		const currentErrors = validateForm();
		const error = await action(event);
		if (Object.keys(currentErrors).length === 0 && !error) {
			moveToNextStep();
		}
	};

	const actionButtonLoading =
		isLoadingCreateCampaign ||
		isLoadingUpdateCampaign ||
		isLoadingCreateMarketplaceCampaign;

	return (
		<>
			<Stepper activeStep={activeStep} sx={{ marginBottom: 3 }}>
				{STEPS.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>

			{/* First screen with general campaign configurations */}
			<Box>
				{activeStep === DETAILS_STEP && (
					<>
						{/* Common field for name of the campaign used for all types of campaigns */}
						<CampaignNameInput
							onChange={handleChange('description')}
							defaultName={data.description}
							errorText={errors?.description}
						/>

						{/* Campaign selector. Rest of the input will depend on the selected type */}
						{!createCampaignInfo.isMarketplace &&
							onCampaignTypeChange && (
								<SelectCampaignType
									onTypeSelect={onCampaignTypeChange}
									defaultValue={constants.campaignTypes.NYOP}
									disabled={!!defaultCampaign}
								/>
							)}

						{!createCampaignInfo.isMarketplace && (
							<StartEndDatesPicker
								id={defaultCampaign?.campaign?.id || null}
								onChange={onStartEndDateChange}
								defaultStartDate={data.startDate}
								defaultEndDate={data.endDate}
							/>
						)}
						{/* Common field with the shopper facing campaign name */}
						{!createCampaignInfo.isMarketplace ? (
							<Box>
								<ConversationalInputWrapper prompt="Customize the shopper-facing message.">
									<TextField
										margin="normal"
										fullWidth
										id="nyop-name"
										label="Shopper message"
										helperText="This message appears to customers in the cart
									and checkout for 'Name Your Price' items. It's automatically
									formatted to indicate the timing of responses. Only edit for
									language adjustments or specific clarifications."
										name="name"
										disabled={!shopperMessageEditable}
										InputProps={{
											endAdornment: (
												<InputAdornment
													position="end"
													sx={{
														'&:hover': {
															cursor: 'pointer',
														},
													}}
													onClick={() =>
														setShopperMessageEditable(
															!shopperMessageEditable,
														)
													}
												>
													<EditIcon />
												</InputAdornment>
											),
										}}
										value={data.sellingPlanGroupName}
										onChange={onShopperMessageCustomized}
										sx={{
											maxWidth: '438.27px',
											width: '100%',
										}}
									/>
								</ConversationalInputWrapper>
							</Box>
						) : null}
						{klaviyoIntegrationFeatureEnabled && (
							<AdvancedSettingsBlock
								onChange={onAdvancedSettingsChange}
								defaultSettings={{
									customerSegmentationVisibility:
										defaultCampaign?.campaign
											?.customer_segmentation_visibility,
								}}
							/>
						)}
					</>
				)}
				{activeStep === PRODUCTS_STEP && <ProductsStep />}
				{activeStep === AUTOMATION_STEP && (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
						}}
					>
						<AutomationBlock
							onChange={setAutomationRules}
							onValidChange={setAutomationBlockValid}
							defaultAutomationRules={automationRules}
							areCounterValuesValid={areCounterValuesValid}
							setAreCounterValuesValid={setAreCounterValuesValid}
							editByProductModal={editByProductModal}
							setEditByProductModal={setEditByProductModal}
							endDate={data.endDate}
							startingOfferInfo={data.startingOfferInfo}
							setStartingOfferInfo={onStartingOfferInfoChange}
						/>
					</Box>
				)}

				{/* Bottom block with action buttons */}
				<Box
					sx={{
						...stickyContainerStyles,
						...(activeStep === AUTOMATION_STEP &&
							!merchant.active_subscription?.billing_tier
								?.nyop_automation && {
								ml: -3,
								width: 'calc(100% + 50px)',
								pr: 3,
							}),
					}}
				>
					{/* Back button that will take user to the previous screen if exists */}
					{activeStep !== 0 && (
						<Button
							variant="text"
							sx={{ color: 'button.secondary.main' }}
							onClick={moveToPreviousStep}
						>
							Back
						</Button>
					)}

					{/* Next button that will either take to the next screen or submit */}
					<Button
						onClick={handleNextStep}
						loading={actionButtonLoading}
						disabled={STEPS_ACTIONS[activeStep].isDisabled()}
					>
						{STEPS_ACTIONS[activeStep].actionLabel}
					</Button>
				</Box>
				<ModalScreen
					contentStyle={campaignModalStyles}
					aria={{
						labelledby: 'select-subscription-modal',
						describedby: 'select-subscription-modal',
					}}
					open={selectSubscriptionModal}
					closeIcon
					handleModal={setSelectSubscriptionModal}
				>
					<SelectSubscriptionModal />
				</ModalScreen>
			</Box>
		</>
	);
}

CreateNyopCampaignForm.propTypes = {
	onCampaignTypeChange: PropTypes.func.isRequired,
	onCompleteNavigate: PropTypes.string,
	defaultCampaign: PropTypes.shape({
		campaign: PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
			description: PropTypes.string,
			start_date: PropTypes.string,
			end_date: PropTypes.string,
			automation_rules: PropTypes.shape({}),
			customer_segmentation_visibility: PropTypes.shape({}),
			minimum_offer: PropTypes.string,
			starting_offer: PropTypes.string,
		}),
	}),
};

CreateNyopCampaignForm.defaultProps = {
	defaultCampaign: null,
	onCompleteNavigate: null,
};

export default CreateNyopCampaignForm;
