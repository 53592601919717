import React from 'react';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import {
	createTheme,
	ThemeProvider,
	useTheme,
} from '@mui/material/styles';
import { useMarketplaceEligibleShops } from '@app/hooks/useMarketplace';
import getNoTableMatch from '@app/components/shared/Table/components/NoTableMatch';
import CreateCampaignButton from '@app/pages/marketplace/retailer/table/CreateCampaignButton';
import SyncVariantsButton from '@app/pages/marketplace/retailer/table/SyncVariantsButton';

const DataColumnOptions = {
	viewColumns: false,
	filter: false,
	sort: false,
	download: false,
	display: 'excluded',
};

const DataIndex = {
	selectedVariants: -2,
	myshopify_domain: -1,
};

function getDataColumns() {
	return Object.keys(DataIndex).map((name) => ({
		name,
		options: DataColumnOptions,
	}));
}

function getActionRenderer(value, data) {
	const selectedVariants = data.rowData.at(
		DataIndex.selectedVariants,
	);
	return (
		<CreateCampaignButton
			selectedVariants={selectedVariants}
			shopName={value}
		/>
	);
}

function getVariantsCountRenderer(value, data) {
	return (
		<SyncVariantsButton
			variants={value}
			shop={data.rowData.at(DataIndex.myshopify_domain)}
		/>
	);
}

function MarketplaceCampaignsTable({ style }) {
	const theme = useTheme();

	const { data, isLoading, isFetching, error } =
		useMarketplaceEligibleShops({});

	const shops = React.useMemo(() => {
		if (data && !isLoading && !isFetching) {
			return data?.data?.map((shop) => {
				const selectedVariants = [];

				const variantsIds = new Set([]);

				shop.marketplace_products
					.sort((a, b) => {
						if (a.variant_id && !b.variant_id) {
							return -1;
						}
						if (!a.variant_id && b.variant_id) {
							return 1;
						}
						return 0;
					})
					.forEach((mp) => {
						if (mp.variant) {
							if (mp.enabled) {
								selectedVariants.push({
									...mp.variant,
									counterValue: mp.nyop_threshold,
								});
							}
							variantsIds.add(mp.variant_id);
						} else if (mp.product) {
							mp.product.variants.forEach((variant) => {
								if (!variantsIds.has(variant.id)) {
									selectedVariants.push({
										...variant,
										counterValue: mp.nyop_threshold,
									});
									variantsIds.add(variant.id);
								}
							});
						}
					});
				return {
					...shop,
					selectedVariants,
				};
			});
		}
		return [];
	}, [data, isFetching, isLoading]);

	const columns = React.useMemo(
		() => [
			{
				name: 'name',
				label: 'Shop',
				options: {
					filter: false,
				},
			},
			{
				name: 'selectedVariants',
				label: 'Variants Count',
				options: {
					customBodyRender: getVariantsCountRenderer,
					filter: false,
				},
			},
			{
				name: 'name',
				label: 'Action',
				options: {
					customBodyRender: getActionRenderer,
					filter: false,
					sort: false,
				},
			},
			// EXTRA DATA
			...getDataColumns(),
		],
		[],
	);

	const options = React.useMemo(
		() => ({
			elevation: 0,
			storageKey: 'marketplace_campaigns',
			serverSide: false,
			filter: true,
			filterType: 'dropdown',
			search: true,
			searchPlaceholder: 'Search',
			download: false,
			print: false,
			viewColumns: false,
			responsive: 'standard',
			expandableRows: false,
			selectableRows: 'none',
			textLabels: {
				body: {
					noMatch: getNoTableMatch({
						isLoading: isLoading || isFetching,
						text:
							error?.message || 'No shops eligible for marketplace',
					}),
				},
			},
			// Pagination configurations
			count: 10,
			rowsPerPageOptions: [10, 20, 50],
		}),
		[error, isFetching, isLoading],
	);

	const getMuiTheme = () => {
		const overrides = {
			components: {
				MUIDataTable: {
					styleOverrides: {
						root: {},
					},
				},
			},
		};
		if (style?.removeFrame) {
			overrides.components.MUIDataTable.styleOverrides.root.boxShadow =
				'none';
		}

		return createTheme(theme, overrides);
	};

	const table = React.useMemo(
		() => (
			<MUIDataTable
				title={style?.hideTitle ? '' : 'Marketplace Eligible Shops'}
				data={shops}
				columns={columns}
				options={options}
			/>
		),
		[style, shops, columns, options],
	);

	return <ThemeProvider theme={getMuiTheme()}>{table}</ThemeProvider>;
}

MarketplaceCampaignsTable.propTypes = {
	style: PropTypes.shape({
		removeFrame: PropTypes.bool,
		hideTitle: PropTypes.bool,
	}),
};

MarketplaceCampaignsTable.defaultProps = {
	style: null,
};

export default MarketplaceCampaignsTable;
