import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

function SubscriptionText({ isInPopover, subscriptionTitle }) {
	const fontSize = isInPopover ? '24px' : '1rem';

	if (!subscriptionTitle) {
		return (
			<>
				<Typography fontSize={fontSize}>
					Get Started with a
				</Typography>
				<Typography fontSize={fontSize}>Plan</Typography>
			</>
		);
	}

	return (
		<>
			<Typography fontSize={fontSize}>Current Plan:</Typography>
			<Typography fontSize={fontSize}>{subscriptionTitle}</Typography>
		</>
	);
}

SubscriptionText.propTypes = {
	isInPopover: PropTypes.bool.isRequired,
	subscriptionTitle: PropTypes.string.isRequired,
};

export default SubscriptionText;
