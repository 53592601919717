import constants from '../constants';

export const getGqlId = (
	id,
	prefix = constants.shopifyObjectPrefix.SELLING_PLAN_GROUP,
) => (id && id.includes(prefix) ? id : `${prefix}${id}`);

export const getNumericId = (id) => {
	if (!id || typeof id !== 'string') {
		return null;
	}
	return id?.split('/').pop();
};

export const getOrderLink = (shop, id) =>
	`https://${shop}/admin/orders/${getNumericId(id)}`;

export const getProductLink = (shop, id) =>
	`https://${shop}/admin/products/${getNumericId(id)}`;

export const getProductVariantLink = (shop, productId, id) =>
	`https://${shop}/admin/products/${getNumericId(
		productId,
	)}/variants/${getNumericId(id)}`;
