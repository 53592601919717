import React from 'react';
import RetailerMarketplaceCampaignsTable from '@app/pages/campaignManagement/marketplaceTable/table/index';
import { MarketplaceCampaignProvider } from '@app/hooks/useMarketplaceCampaignContext';

function RetailerMarketplaceCampaigns() {
	return (
		<MarketplaceCampaignProvider>
			<RetailerMarketplaceCampaignsTable />
		</MarketplaceCampaignProvider>
	);
}

export default RetailerMarketplaceCampaigns;
