import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import constants from '@app/constants';
import RadioButtonGroup from '@app/components/shared/inputs/RadioButtonGroup';

function SelectCampaignType({
	onTypeSelect,
	defaultValue,
	disabled,
}) {
	const [type, setType] = React.useState(
		defaultValue || constants.campaignTypes.NYOP,
	);

	const radios = React.useMemo(
		() => [
			{
				label: 'Name Your Own Price',
				value: constants.campaignTypes.NYOP,
				helperText: '',
				disabled,
			},
			{
				label: 'Preorder',
				value: constants.campaignTypes.PREORDER,
				helperText: '',
				disabled,
			},
		],
		[disabled],
	);

	React.useEffect(() => {
		onTypeSelect(type);
	}, [type, onTypeSelect]);
	const handleChange = ({ target }) => {
		setType(target.value);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',

				mb: 1,
			}}
		>
			<RadioButtonGroup
				handleChange={handleChange}
				radios={radios}
				name="selling-plan-type"
				formLabelText="What kind of Campaign would you like to create?"
				helperText="Choose 'Name Your Own Price' if you want to allow customers to make offers on products, or select 'Preorder' to enable customers to order products before they are released."
				value={type}
			/>
		</Box>
	);
}

SelectCampaignType.propTypes = {
	onTypeSelect: PropTypes.func.isRequired,
	defaultValue: PropTypes.string,
	disabled: PropTypes.bool,
};

SelectCampaignType.defaultProps = {
	defaultValue: constants.campaignTypes.NYOP,
	disabled: false,
};

export default React.memo(SelectCampaignType);
