import * as _ from 'lodash-es';

import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
	targetPriceFromThreshold,
	thresholdFromTargetPrice,
	validateCounterValueMsrp,
} from '../utils';
import constants from '@app/constants';
import ProductValueInput from './productValueInput';
import TitleWithImage from '@app/components/muiTable/elements/TitleWithImage';
import WrongCounterValuePopover from './wrongCounterValuePopover';
import useAuth from '@app/hooks/useAuth';
import { VariantType } from '@app/propTypes/types';
import { getProductLink } from '@app/lib/shopify';
import {
	THRESHOLD_MAX_VALUE,
	THRESHOLD_MIN_VALUE,
} from '../constants';
import VariantRow from './variantRow';

const getProductMSRPsAndPrices = (variants) => {
	let minPrice = null;
	let maxPrice = null;
	let minMSRP = null;
	let maxMSRP = null;

	const msrps = variants
		.map((variant) => variant.msrp)
		.filter((msrp) => !!msrp);

	const prices = variants
		.map((variant) => variant.price)
		.filter((price) => !!price);

	if (prices.length === 1) {
		[minPrice] = prices;
		[maxPrice] = prices;
	} else {
		minPrice = Math.min(...prices);
		maxPrice = Math.max(...prices);
	}

	if (msrps.length === 1) {
		[minMSRP] = msrps;
		[maxMSRP] = msrps;
	} else if (msrps.length > 1) {
		minMSRP = Math.min(...msrps);
		maxMSRP = Math.max(...msrps);
	}

	return [minMSRP, maxMSRP, minPrice, maxPrice];
};

function ProductRow({
	variants,
	thresholds,
	setThresholds,
	defaultThreshold,
	fixedCostBuffer,
}) {
	const { merchant, formatMerchantCurrency, getCurrencySymbol } =
		useAuth();

	const currencySymbol = getCurrencySymbol();

	const productId = variants[0].product_id;
	const variantsIds = variants.map((variant) => variant.id);

	const [productThresholds, setProductThresholds] = React.useState(
		_.pickBy(
			thresholds,
			(v, key) => key === productId || variantsIds.includes(key),
		),
	);

	React.useEffect(() => {
		setThresholds((prevThresholds) => ({
			...prevThresholds,
			...productThresholds,
		}));
	}, [setThresholds, productThresholds]);

	const productThreshold = thresholds[productId];

	const [minMSRP, maxMSRP, minPrice, maxPrice] =
		getProductMSRPsAndPrices(variants);

	const price =
		minMSRP !== null && minMSRP === maxMSRP ? minMSRP : maxPrice;

	const [threshold, setThreshold] = React.useState(
		productThreshold === undefined ? null : productThreshold,
	);
	const inputThreshold =
		threshold !== null ? threshold : defaultThreshold;

	const [targetPrice, setTargetPrice] = React.useState(null);
	const inputTargetPrice =
		targetPrice !== null
			? targetPrice
			: targetPriceFromThreshold({
					price,
					threshold: inputThreshold,
			  });

	const [changed, setChanged] = React.useState(!!productThreshold);

	const priceValid = variants.every((variant) =>
		validateCounterValueMsrp(
			productThresholds[variant.id] || inputThreshold,
			variant.msrp,
			variant.price,
		),
	);

	const [expanded, setExpanded] = React.useState(!priceValid);

	const handleClearThreshold = () => {
		setThreshold(null);
		setTargetPrice(null);
		setChanged(false);
		setProductThresholds((prevValues) => {
			const { [productId]: ignored, ...newValues } = {
				...prevValues,
			};
			return newValues;
		});
	};

	const handleChangeThreshold = (event) => {
		const { value } = event.target;
		let newThreshold = +value;
		if (+value <= THRESHOLD_MIN_VALUE) {
			newThreshold = THRESHOLD_MIN_VALUE;
		} else if (+value >= THRESHOLD_MAX_VALUE) {
			newThreshold = THRESHOLD_MAX_VALUE;
		}

		setProductThresholds((prevValues) => ({
			...prevValues,
			[productId]: newThreshold,
		}));

		if (!changed) {
			setChanged(true);
		}
		if (minMSRP === maxMSRP) {
			const interimTargetPrice =
				targetPriceFromThreshold({
					price,
					threshold: newThreshold,
				}) + fixedCostBuffer;

			const newTargetPrice = maxMSRP
				? Math.min(maxMSRP, interimTargetPrice)
				: interimTargetPrice;
			setTargetPrice(newTargetPrice);
		}
		setThreshold(newThreshold);
	};

	const handleChangeTargetPrice = (event) => {
		if (minMSRP !== maxMSRP) {
			console.warn(
				`Product ${productId} should not have editable target price column as variants prices vary`,
			);
			return;
		}

		const { value } = event.target;
		let correctedTargetPrice = +value;
		if (+value <= 0) {
			correctedTargetPrice = 0;
		} else if (+value >= maxPrice) {
			correctedTargetPrice = maxPrice;
		}

		const newThreshold = thresholdFromTargetPrice({
			price,
			targetPrice: correctedTargetPrice,
		});

		setProductThresholds((prevValues) => ({
			...prevValues,
			[productId]: newThreshold,
		}));
		setChanged(true);
		setThreshold(newThreshold);
		setTargetPrice(correctedTargetPrice);
	};

	let textColor = constants.colors.pending;
	if (changed) {
		textColor = constants.colors.textMain;
	}

	const renderMSRP = () => {
		if (minMSRP === null) {
			return <Typography sx={{ fontSize: '14px' }}>N/A</Typography>;
		}

		return (
			<Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
				{minMSRP === maxMSRP
					? formatMerchantCurrency(maxMSRP)
					: `${formatMerchantCurrency(
							minMSRP,
					  )}-${formatMerchantCurrency(maxMSRP)}`}
			</Typography>
		);
	};

	const renderPrice = () => (
		<Typography
			sx={{
				fontWeight: minMSRP !== null ? 'normal' : 'bold',
				fontSize: '14px',
			}}
		>
			{minPrice === maxPrice
				? formatMerchantCurrency(maxPrice)
				: `${formatMerchantCurrency(
						minPrice,
				  )}-${formatMerchantCurrency(maxPrice)}`}
		</Typography>
	);

	return (
		<>
			<TableRow
				onClick={() => setExpanded((prevState) => !prevState)}
				sx={{
					'&:hover': {
						backgroundColor: 'rgba(0, 0, 0, 0.04)',
						cursor: 'pointer',
					},
				}}
			>
				<TableCell sx={{ pl: 1, pr: 0 }}>
					<KeyboardArrowRightIcon
						sx={{
							borderRadius: 100,
							'&:hover': {
								backgroundColor: 'rgba(0, 0, 0, 0.04)',
								cursor: 'pointer',
							},
						}}
					/>
				</TableCell>
				<TableCell sx={{ pb: '4px', pt: '4px', pl: 1 }}>
					<TitleWithImage
						title={variants[0].full_title.slice(
							0,
							variants[0].full_title.length -
								variants[0].title.length -
								2,
						)}
						imageUrl={variants[0].image_url}
						url={getProductLink(merchant.myshopify_domain, productId)}
						titleIcon={
							!priceValid ? <WrongCounterValuePopover /> : null
						}
					/>
				</TableCell>
				<TableCell
					sx={{
						pb: '21px',
						pt: '21px',
					}}
				>
					{renderMSRP()}
				</TableCell>
				<TableCell
					sx={{
						pb: '21px',
						pt: '21px',
					}}
				>
					{renderPrice()}
				</TableCell>
				<TableCell
					sx={{
						mr: 3,
					}}
				>
					<ProductValueInput
						id={productId}
						textColor={textColor}
						value={inputThreshold}
						handleClearThreshold={handleClearThreshold}
						handleChange={handleChangeThreshold}
						isValueDifferent={changed}
						endAdornment="%"
					/>
				</TableCell>

				<TableCell
					sx={{
						mr: 3,
					}}
				>
					{minMSRP === maxMSRP ? (
						<ProductValueInput
							id={productId}
							textColor={textColor}
							value={inputTargetPrice}
							handleClearThreshold={handleClearThreshold}
							handleChange={handleChangeTargetPrice}
							isValueDifferent={changed}
							startAdornment={currencySymbol}
							maxValue={parseFloat(maxPrice)}
						/>
					) : (
						<Typography>See the variants</Typography>
					)}
				</TableCell>
				{fixedCostBuffer ? (
					<TableCell>
						<Typography>
							{formatMerchantCurrency(
								(targetPrice || inputTargetPrice) + fixedCostBuffer,
							)}
						</Typography>
					</TableCell>
				) : null}
			</TableRow>
			{expanded
				? variants.map((variant) => (
						<VariantRow
							variant={variant}
							defaultThreshold={productThresholds[variant.id]}
							parentThreshold={inputThreshold}
							setThresholds={setProductThresholds}
							fixedCostBuffer={fixedCostBuffer}
							key={variant.id}
						/>
				  ))
				: null}
		</>
	);
}

ProductRow.propTypes = {
	variants: PropTypes.arrayOf(VariantType).isRequired,
	thresholds: PropTypes.objectOf(PropTypes.string).isRequired,
	setThresholds: PropTypes.func.isRequired,
	defaultThreshold: PropTypes.number.isRequired,
	fixedCostBuffer: PropTypes.number,
};

ProductRow.defaultProps = {
	fixedCostBuffer: 0,
};

export default ProductRow;
