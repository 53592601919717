import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import FullPageMessage from '../../components/shared/layout/FullPageMessage';
import { GA_LABEL } from '../../constants/googleAnalytics';
import Button from '@app/components/shared/button/Button';

function NotFound() {
	const navigate = useNavigate();

	const handleClick = () => navigate(-1);

	return (
		<FullPageMessage appBar>
			<Typography
				data-ga-label={GA_LABEL.NOT_FOUND_CODE}
				variant="h1"
				sx={{ color: 'success.main' }}
			>
				404
			</Typography>
			<Typography variant="h6" paragraph>
				Page not found
			</Typography>
			{window.history.length !== 1 && (
				<Button size="large" onClick={handleClick}>
					Go back
				</Button>
			)}
		</FullPageMessage>
	);
}

export default NotFound;
