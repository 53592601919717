import * as React from 'react';
import PropTypes from 'prop-types';

const ModalContext = React.createContext(undefined);

export function ModalProvider({ children }) {
	const [open, setOpen] = React.useState(false);
	const handleModal = (bool) => setOpen(bool);

	const memoedValue = React.useMemo(
		() => ({
			open,
			handleModal,
		}),
		[open],
	);

	return (
		<ModalContext.Provider value={memoedValue}>
			{children}
		</ModalContext.Provider>
	);
}

ModalProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export function useModal() {
	return React.useContext(ModalContext);
}
