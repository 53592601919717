import React from 'react';
import { Box } from '@mui/material';
import NyopOffersInboxTable from './table';
import DateOffsetSelector from './DateOffsetSelector';

function NyopInbox() {
	const [createdAfter, setCreatedAfter] = React.useState('');

	return (
		<>
			<Box>
				<DateOffsetSelector
					name="Select Date"
					onChange={setCreatedAfter}
				/>
			</Box>
			<NyopOffersInboxTable createdAfter={createdAfter} />
		</>
	);
}

export default NyopInbox;
