import { parseISO } from 'date-fns';
import { getNyopDuration } from '../components/shared/form/SellingPlanGroupForm/helpers';
import constants from '../constants/index';

// Ending in: 3, 2, 1 days + XX hours = based on the End Date
//
// 		3 and 2 = BLUE
//
// 		1 and XX hours = RED
//
// Due in 3, 2, 1 days + XX hours = After campaign has ended, this will be based on the Due Date
//
// 		3 and 2 = ORANGE
//
// 		1 and XX hours = RED
//
// Cancelled = If the merchant cancelled the campaign
//
// 		GREY
//
// Executed = After the Merchant has clicked “Finalize & Execute Campaign”
//
// 		GREEN
//
// Overdue Cancelled = Merchant never clicked on “Finalize & Execute Campaign” AND we are beyond the Due Date
//
// 		RED

export const createBadge = ({
	startDate,
	endDate,
	deleted,
	completedAt,
}) => {
	const { primary, failure, warning, darkGrey } = constants.colors;

	const now = new Date();

	if (deleted && !completedAt) {
		return {
			message: 'Canceled',
			statusColor: failure,
		};
	}

	if (completedAt) {
		return {
			message: 'Executed',
			statusColor: primary,
		};
	}

	if (parseISO(startDate) > now) {
		const { duration } = getNyopDuration(now, startDate);

		return {
			message: `Starting in ${duration}`,
			statusColor: warning,
		};
	}

	const {
		days: endDays,
		hours: endHours,
		duration: endDuration,
		ended,
	} = getNyopDuration(now, endDate);

	if (!endDate || endDays > 3) {
		return {
			message: 'Active',
			statusColor: primary,
		};
	}

	if (endDays > 1 && endDays <= 3) {
		return {
			message: `Ending in ${endDuration}`,
			statusColor: warning,
		};
	}

	if (+endDays === 0 && endHours === 0) {
		if (ended) {
			return {
				message: `Ended just now`,
				statusColor: darkGrey,
			};
		}
		return {
			message: `Ending in less than an hour`,
			statusColor: darkGrey,
		};
	}

	if (endDays === 0 || endDays === 1) {
		if (endHours < 0) {
			return {
				message: `Ended ${endDuration} ago`,
				statusColor: darkGrey,
			};
		}
		return {
			message: `Ending in ${endDuration}`,
			statusColor: failure,
		};
	}

	if (endDays < 0) {
		return {
			message: `Ended ${endDuration} ago`,
			statusColor: darkGrey,
		};
	}

	return null;
};
