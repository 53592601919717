import * as React from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import useAppBarHeight from '../../../hooks/useAppBarHeight';
import getFullPageStyles from '../../../styles/getFullPageStyles';

function FullPageMessage({ children, appBar, sx }) {
	const appBarHeight = useAppBarHeight();
	const pageWithAppBarStyles = getFullPageStyles(appBarHeight);

	const pageStyles = !appBar
		? {
				display: 'flex',
				height: '80vh',
				width: '100%',
				justifyContent: 'center',
				flexDirection: 'column',
				alignItems: 'center',
				textAlign: 'center',
		  }
		: pageWithAppBarStyles;

	return (
		<Box
			sx={{
				...pageStyles,
				...sx,
			}}
		>
			<Paper sx={{ p: 3 }}>{children}</Paper>
		</Box>
	);
}

FullPageMessage.propTypes = {
	children: PropTypes.node.isRequired,
	appBar: PropTypes.bool,
	sx: PropTypes.shape({}),
};

FullPageMessage.defaultProps = {
	appBar: true,
	sx: {},
};

export default FullPageMessage;
