import PropTypes from 'prop-types';
import React from 'react';
import EllipsesTypography from '@app/components/shared/ui/EllipsesTypography';

function CollectionsRenderer({ collections }) {
	return (
		<EllipsesTypography
			lines={2}
			variant="subtitle2"
			sx={{ fontSize: '16px' }}
		>
			{collections?.join(', ')}
		</EllipsesTypography>
	);
}

CollectionsRenderer.propTypes = {
	collections: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CollectionsRenderer;
