import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { getNumericId } from '@app/lib/shopify';
import Button from '@app/components/shared/button/Button';

function ActionsRenderer({ productId, variantId, disabled }) {
	let url = `/nyop/products/${getNumericId(productId)}`;
	if (variantId) {
		url = `${url}/variants/${getNumericId(variantId)}`;
	}

	const navigate = useNavigate();

	return (
		<Button
			title="Analyze"
			onClick={() => navigate(url)}
			disabled={disabled}
			variant="text"
		>
			Analyze
		</Button>
	);
}

ActionsRenderer.propTypes = {
	productId: PropTypes.string.isRequired,
	variantId: PropTypes.string,
	disabled: PropTypes.bool,
};

ActionsRenderer.defaultProps = {
	disabled: false,
	variantId: null,
};

export default ActionsRenderer;
