import axios from './index';

export async function getCurrentUser() {
	const response = await axios.get('/api/user');
	return response.data;
}

export async function login({ username, password }) {
	const response = await axios.post('/api/auth/login', {
		username,
		password,
	});
	return response.data;
}

export async function register({ token, password }) {
	const response = await axios.post(
		'/api/auth/register',
		{
			password,
		},
		{
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return response.data;
}

export async function switchActiveMerchant(id) {
	const response = await axios.put(
		`/api/merchants/context/set-active`,
		{
			merchantId: id,
		},
	);
	return response.data;
}

export async function resetPasswordBegin({ email }) {
	const response = await axios.post(
		'/api/auth/reset-password-begin',
		{
			email,
		},
	);
	return response.data;
}

export async function resetPasswordComplete({ token, password }) {
	const response = await axios.post(
		'/api/auth/reset-password-complete',
		{
			token,
			password,
		},
	);
	return response.data;
}
