import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@app/components/shared/button/Button';

function CampaignNameRenderer({
	campaignId,
	description,
	disabled,
	sx,
}) {
	const navigate = useNavigate();
	const campaignUrl = `/marketplace/campaigns/${campaignId}`;

	return (
		<Button
			sx={{
				textAlign: 'left',
				...sx,
			}} // We are only using these in tables so left looks better on line break
			title={description}
			disabled={disabled}
			onClick={() => navigate(campaignUrl)}
			variant="text"
		>
			{description}
		</Button>
	);
}

CampaignNameRenderer.propTypes = {
	campaignId: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	sx: PropTypes.shape({}),
	disabled: PropTypes.bool,
};

export default CampaignNameRenderer;
