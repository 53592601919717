import React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import Button from '@app/components/shared/button/Button';

function SelectSubscriptionModal() {
	return (
		<Box
			textAlign="center"
			marginBottom={4}
			display="flex"
			flexDirection="column"
			gap={2}
			alignItems="center"
		>
			<Typography fontWeight="500" fontSize="20px">
				Let&apos;s Get Your Campaign Rolling!
			</Typography>
			<Typography marginTop={2} component="div">
				To launch a campaign, you need an active subscription with us.
				Don&apos;t worry, we&apos;ve got options for every stage of
				your journey – including a
				<Box fontWeight="fontWeightMedium" display="inline">
					{' '}
					free tier{' '}
				</Box>
				to get you started right away.
			</Typography>
			<Typography component="div">
				Please{' '}
				<Box fontWeight="fontWeightMedium" display="inline">
					choose a subscription{' '}
				</Box>
				that suits your needs to continue. We&apos;re excited to see
				what you&apos;ll create!
			</Typography>
			<Button
				component={Link}
				target="_blank"
				rel="noopener noreferrer"
				href="/settings/subscriptions"
				sx={{ alignSelf: 'flex-end', mt: 3 }}
			>
				Learn More
			</Button>
		</Box>
	);
}

export default SelectSubscriptionModal;
