import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { isNil } from 'lodash-es';
import constants from '@app/constants';
import useAuth from '@app/hooks/useAuth';

const subtitleStyles = {
	fontSize: 14,
	fontWeight: 500,
};

const cellStyles = {
	display: 'flex',
	flexGrow: 1,
	padding: 1,
};

const cellCenteredStyles = {
	...cellStyles,
	justifyContent: 'flex-start',
	paddingLeft: '7%',
	textAlign: 'left',
};

const cellRightAlignedStyles = {
	...cellStyles,
	justifyContent: 'flex-end',
	textAlign: 'right',
};

function ProductExampleContent({
	enabled,
	currentProduct,
	effectiveDiscount,
	counterOfferVariability,
}) {
	const { formatMerchantCurrency } = useAuth();

	// Formatting helpers
	const calculateDiscountedPrice = (counterValue, price) => {
		const counterValueDifference = 100 - counterValue;
		return Math.round((counterValueDifference / 100) * price);
	};

	const getFormattedPriceRange = (lowPrice, highPrice) =>
		`${formatMerchantCurrency(
			lowPrice,
			0,
		)} - ${formatMerchantCurrency(highPrice, 0)}`;

	// Automation values
	const counterValue = isNil(currentProduct.counterValue)
		? +effectiveDiscount
		: +currentProduct.counterValue;
	const discountedPrice = calculateDiscountedPrice(
		counterValue,
		+currentProduct.msrp,
	);
	const variabilityPrice = calculateDiscountedPrice(
		counterValue - counterOfferVariability,
		+currentProduct.msrp,
	);
	const lastCounteredPrice = discountedPrice - 1;
	const formattedCounterVariabilityPrice = Math.min(
		variabilityPrice,
		+currentProduct.msrp,
	);

	// Logic for final prices
	const acceptedFinalPrice =
		counterValue === 100
			? formatMerchantCurrency(discountedPrice, 0)
			: getFormattedPriceRange(discountedPrice, +currentProduct.msrp);
	const counteredOfferPrice =
		lastCounteredPrice <= 0
			? formatMerchantCurrency(1, 0)
			: getFormattedPriceRange(1, lastCounteredPrice);
	const counteredFinalPrice =
		counterOfferVariability && counterValue !== 100
			? getFormattedPriceRange(
					discountedPrice,
					formattedCounterVariabilityPrice,
			  )
			: formatMerchantCurrency(discountedPrice, 0);

	return (
		<Grid container>
			{/* Header */}
			<Grid item xs={4} sx={cellStyles}>
				<Typography sx={subtitleStyles}>Offer:</Typography>
			</Grid>
			<Grid item xs={4} sx={cellCenteredStyles}>
				<Typography sx={subtitleStyles}>Action:</Typography>
			</Grid>
			<Grid item xs={4} sx={cellRightAlignedStyles}>
				<Typography sx={subtitleStyles}>Final Price:</Typography>
			</Grid>

			{/* Divider */}
			<Grid item xs={12}>
				<Divider flexItem />
			</Grid>

			{/* Row 1 */}
			<Grid item xs={4} sx={cellStyles}>
				<Typography sx={subtitleStyles}>
					{acceptedFinalPrice}
				</Typography>
			</Grid>
			<Grid item xs={4} sx={cellCenteredStyles}>
				<Typography
					sx={{
						...subtitleStyles,
						color: enabled ? constants.colors.primary : '',
					}}
				>
					Accepted
				</Typography>
			</Grid>
			<Grid item xs={4} sx={cellRightAlignedStyles}>
				<Typography sx={subtitleStyles}>
					{acceptedFinalPrice}
				</Typography>
			</Grid>

			{/* Row 2 */}
			<Grid item xs={4} sx={cellStyles}>
				<Typography sx={subtitleStyles}>
					{counteredOfferPrice}
				</Typography>
			</Grid>
			<Grid item xs={4} sx={cellCenteredStyles}>
				<Typography
					sx={{
						...subtitleStyles,
						color: enabled ? constants.colors.tertiary : '',
					}}
				>
					Countered
				</Typography>
			</Grid>
			<Grid item xs={4} sx={cellRightAlignedStyles}>
				<Typography sx={subtitleStyles}>
					{counteredFinalPrice}
				</Typography>
			</Grid>
		</Grid>
	);
}

ProductExampleContent.propTypes = {
	enabled: PropTypes.bool.isRequired,
	currentProduct: PropTypes.shape({
		title: PropTypes.string,
		price: PropTypes.string,
		msrp: PropTypes.string,
		counterValue: PropTypes.number,
	}).isRequired,
	effectiveDiscount: PropTypes.string.isRequired,
	counterOfferVariability: PropTypes.string.isRequired,
};

export default ProductExampleContent;
