import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMarketplaceCampaignContext } from '@app/hooks/useMarketplaceCampaignContext';
import { useSyncMarketplaceProducts } from '@app/hooks/useMarketplace';

function SyncVariantsButton({ shop, variants }) {
	const { mutate } = useSyncMarketplaceProducts({});
	const { runningSyncs } = useMarketplaceCampaignContext();

	const onClick = () => {
		mutate({ shop });
	};

	const disabled = runningSyncs.length !== 0;

	const buttonTooltip = disabled
		? 'Marketplace products sync is in progress'
		: 'Click to make sure all variants are up-to-date in the marketplace with variants in the campaign';

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Typography>{variants.length}</Typography>
			<Tooltip title={buttonTooltip} arrow placement="top">
				<span>
					<IconButton onClick={onClick} disabled={disabled}>
						<RefreshIcon sx={{ width: 20, height: 20 }} />
					</IconButton>
				</span>
			</Tooltip>
		</Box>
	);
}

SyncVariantsButton.propTypes = {
	shop: PropTypes.string.isRequired,
	variants: PropTypes.arrayOf(
		PropTypes.shape({ id: PropTypes.string.isRequired }),
	).isRequired,
};

export default SyncVariantsButton;
