import { removeEmptyObjectKeys } from './objHelpers';

export function buildUrlQueryParams(obj) {
	const queryString = new URLSearchParams(obj).toString();
	return queryString;
}

export function cleanAndBuildUrlQueryParams(obj) {
	const filteredObj = removeEmptyObjectKeys(obj);
	const queryString = buildUrlQueryParams(filteredObj);
	return queryString;
}

export function isInternalUrl(url) {
	if (/^(http:|https:)/.test(url)) {
		const { hostname } = new URL(url);
		if (hostname === window.location.hostname) {
			return true;
		}
	}
	if (url.charAt(0) === '/') {
		// If it does, assume it's an internal URL
		return true;
	}
	// If none of the above conditions are met, assume it's an external URL
	return false;
}
