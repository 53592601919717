import * as React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Link } from '@mui/material';
// import Checkbox from '@mui/material/Checkbox';
// import FormControlLabel from '@mui/material/FormControlLabel';

import Typography from '@mui/material/Typography';

import EmailInput from '../../components/shared/inputs/EmailInput';
import PasswordInput from '../../components/shared/inputs/PasswordInput';
import ErrorMessage from '../../components/shared/ui/ErrorMessage';
import { GA_LABEL } from '../../constants/googleAnalytics';
import Button from '@app/components/shared/button/Button';

const propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	values: PropTypes.shape({
		email: PropTypes.string,
		password: PropTypes.string,
	}).isRequired,
	errors: PropTypes.shape({
		email: PropTypes.string,
		password: PropTypes.string,
	}),
	isLoading: PropTypes.bool,
	fetchError: PropTypes.oneOfType([
		PropTypes.instanceOf(Error),
		PropTypes.shape({}),
	]),
	disabled: PropTypes.bool,
};

const defaultProps = {
	errors: {},
	isLoading: false,
	fetchError: null,
	disabled: false,
};

function LoginForm({
	onSubmit,
	onChange,
	values,
	errors,
	isLoading,
	fetchError,
	disabled,
}) {
	return (
		<Box component="form" onSubmit={onSubmit} noValidate>
			<EmailInput
				data-ga-label={GA_LABEL.LOGIN_EMAIL_INPUT}
				id="email-input-login"
				name="email"
				label="Email"
				onChange={onChange('email')}
				value={values.email}
				autoComplete="username"
				autoFocus
				required
			/>
			{errors?.email && <ErrorMessage message={errors?.email} />}
			<PasswordInput
				id="password-login-form"
				name="password"
				onChange={onChange('password')}
				value={values.password}
				autoComplete="current-password"
				required
			/>
			{errors?.password && (
				<ErrorMessage message={errors?.password} />
			)}
			{/* TODO: implement remember me */}
			{/* <FormControlLabel
				control={<Checkbox value="remember" color="primary" />}
				label="Remember me"
			/> */}
			<Box display="flex" justifyContent="flex-end">
				<Link to="/reset-password" component={RouterLink}>
					Forgot password?
				</Link>
			</Box>
			<Button
				fullWidth
				sx={{ mt: 3, mb: 2 }}
				disabled={disabled || !values.email || !values.password}
				loading={isLoading}
				onClick={onSubmit}
			>
				Sign In
			</Button>
			{fetchError?.message && (
				<Typography color="error">{fetchError.message}</Typography>
			)}
		</Box>
	);
}

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default LoginForm;
