import * as React from 'react';

import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useIntegrations } from '@app/hooks/useMerchant';
import KlaviyoSettings from '@app/pages/settings/integrations/klaviyo';

const INTEGRATIONS = [
	{
		integration: 'klaviyo',
		title: 'Klaviyo',
		Component: KlaviyoSettings,
	},
];

function IntegrationsPage() {
	const [selectedIntegration, setSelectedIntegration] =
		React.useState('');

	const { data: merchantIntegrations, isLoading } = useIntegrations();

	const integrationObject = INTEGRATIONS.find(
		({ integration }) => integration === selectedIntegration,
	);

	const integrationSettings = merchantIntegrations?.data.find(
		({ integration }) => integration === selectedIntegration,
	);

	return (
		<Box sx={{ mb: 1, width: '100%' }}>
			<FormControl
				sx={{
					position: 'relative',
					m: 1,
					width: { xs: '100%', md: '50%', lg: '30%' },
				}}
			>
				<InputLabel id="select-integration-label">
					Integration
				</InputLabel>
				<Select
					labelId="select-integration-label"
					label="Select Integrations"
					placeholder="Select Integration"
					onChange={(event) =>
						setSelectedIntegration(event.target.value)
					}
					value={selectedIntegration}
					sx={{ width: '100%' }}
				>
					{isLoading && <MenuItem>Loading...</MenuItem>}
					{!isLoading &&
						INTEGRATIONS.map((integration) => (
							<MenuItem
								key={integration.integration}
								value={integration.integration}
							>
								{integration.title}
							</MenuItem>
						))}
				</Select>
			</FormControl>

			{integrationObject && (
				<Card>
					<Typography>{integrationObject.title}</Typography>
					<integrationObject.Component
						defaultSettings={integrationSettings?.settings}
					/>
				</Card>
			)}
		</Box>
	);
}

export default IntegrationsPage;
