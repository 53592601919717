import MUIDataTable from 'mui-datatables';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {
	formatDate,
	formatReadableDate,
} from '../../../helpers/dateHelpers';
import useAuth from '../../../hooks/useAuth';
import { getNumericId, getOrderLink } from '../../../lib/shopify';
import { useBillingTransactions } from '../../../hooks/useMerchant';
import getNoTableMatch from '@app/components/shared/Table/components/NoTableMatch';
import InfoPopover from '@app/components/shared/ui/InfoPopoverIcon';

const floatCompare = (order) => (v1, v2) => {
	const val1 = parseFloat(v1.data);
	const val2 = parseFloat(v2.data);
	return (val1 - val2) * (order === 'asc' ? 1 : -1);
};

const formatExtraIcons = (value, data) => {
	const writtenOffAt = data.rowData.at(-5);

	const icons = [];
	if (writtenOffAt) {
		icons.push(
			<InfoPopover
				type="deleted"
				text="This transaction is complimentary. No charges will be applied"
			/>,
		);
	}
	return <Box sx={{ textAlign: 'end', mr: 2 }}> {...icons} </Box>;
};

const columns = [
	{
		name: 'order.name',
		label: 'Order',
		options: {
			customBodyRender: (value, data) => {
				const entityType = data.rowData.at(-6);
				const entityId = data.rowData.at(-3);
				const merchant = data.rowData.at(-2);

				if (entityType === 'order') {
					return (
						<Link
							href={getOrderLink(merchant.myshopify_domain, entityId)}
							target="_blank"
							rel="noopener noreferrer"
							title="View order in Shopify Admin"
						>
							<Typography
								fontWeight="500"
								sx={{ width: 'fit-content' }}
							>
								{value || getNumericId(entityId)}
							</Typography>
						</Link>
					);
				}
				return entityId;
			},
		},
	},
	{
		name: 'amount',
		label: 'GMV',
		options: {
			customBodyRender: (value, data) => {
				const formatMerchantCurrency = data.rowData.at(-4);
				return formatMerchantCurrency(value);
			},
			sortCompare: floatCompare,
		},
	},
	{
		name: 'commission',
		label: 'Commission',
		options: {
			customBodyRender: (value, data) => {
				const formatMerchantCurrency = data.rowData.at(-4);
				return formatMerchantCurrency(value);
			},
			sortCompare: floatCompare,
		},
	},
	{
		name: 'charged_at',
		label: 'Invoice date',
		options: {
			customBodyRender: formatReadableDate,
		},
	},
	{
		name: 'order.name',
		label: ' ',
		options: {
			customBodyRender: formatExtraIcons,
			viewColumns: false,
			filter: false,
			download: false,
			sort: false,
		},
	},

	// Hidden technical columns
	{
		name: 'entity_type',
		options: {
			viewColumns: false,
			filter: false,
			display: 'excluded',
			download: false,
		},
	},
	{
		name: 'written_off_at',
		options: {
			viewColumns: false,
			filter: false,
			display: 'excluded',
			download: false,
		},
	},
	{
		name: 'formatMerchantCurrency',
		options: {
			viewColumns: false,
			filter: false,
			display: 'excluded',
			download: false,
		},
	},
	{
		name: 'entity_id',
		options: {
			viewColumns: false,
			filter: false,
			display: 'excluded',
			download: false,
		},
	},
	{
		name: 'merchant',
		options: {
			viewColumns: false,
			filter: false,
			display: 'excluded',
			download: false,
		},
	},
	{
		name: 'user',
		options: {
			viewColumns: false,
			filter: false,
			display: 'excluded',
			download: false,
		},
	},
];

function Table() {
	const { merchant, user, formatMerchantCurrency } = useAuth();

	const [count, setCount] = React.useState(null);
	const [page, setPage] = React.useState(0);
	const [perPage, setPerPage] = React.useState(20);
	const [sortKey, setSortKey] = React.useState('charged_at,DESC');

	const {
		data: transactionsData,
		isLoading: transactionsLoading,
		isFetching: transactionsFetching,
	} = useBillingTransactions({
		queryVariables: {
			page: page + 1,
			perPage,
			sortKey,
			getCount: count === null,
		},
	});

	React.useEffect(() => {
		if (transactionsData?.data && count === null) {
			setCount(transactionsData.data.count);
		}
	}, [transactionsData, count]);

	const transactions = React.useMemo(
		() =>
			transactionsData?.data?.transactions?.map((t) => ({
				...t,
				merchant,
				user,
				formatMerchantCurrency,
			})) || [],
		[
			transactionsData?.data?.transactions,
			formatMerchantCurrency,
			merchant,
			user,
		],
	);

	return (
		<Box>
			<MUIDataTable
				title="Billing Transactions"
				data={
					transactionsFetching || transactionsLoading
						? []
						: transactions
				}
				columns={columns}
				options={{
					search: false,
					filter: false,
					filterType: 'dropdown',
					responsive: 'vertical',
					enableNestedDataAccess: '.',
					storageKey: `${merchant?.myshopify_domain}-bazo-transactions`,
					selectableRowsHideCheckboxes: true,
					viewColumns: true,
					downloadOptions: {
						filename: `transactions_${formatDate(new Date())}.csv`,
					},
					textLabels: {
						body: {
							noMatch: getNoTableMatch({
								isLoading:
									transactionsFetching || transactionsLoading,
								text: 'Sorry, no Transactions found',
							}),
						},
					},
					serverSide: true,
					onColumnSortChange: (column, direction) => {
						if (column === 'order.name') {
							setSortKey(`order.number,${direction}`);
						} else {
							setSortKey(`${column},${direction}`);
						}
					},

					// Pagination configuration
					count: transactionsData?.data?.count ?? 20,
					page,
					rowsPerPage: perPage,
					rowsPerPageOptions: [10, 20, 50, 100],
					onChangeRowsPerPage: (value) => {
						setPerPage(value);
						setPage(0);
					},
					onChangePage: (newPage) => {
						setPage(newPage);
					},
				}}
			/>
		</Box>
	);
}

Table.propTypes = {};

export default Table;
