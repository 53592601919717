import * as React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { GA_LABEL } from '../../../constants/googleAnalytics';
import Button from '../button/Button';

function MuiCard({
	header,
	subheader,
	body,
	isActive,
	children,
	sx,
	learnMoreLink,
}) {
	return (
		<Card
			data-ga-label={GA_LABEL.SUBSCRIPTION_CARD}
			sx={{
				minWidth: 275,
				border: 'primary.main',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				...sx,
			}}
		>
			<CardContent>
				{header && (
					<Typography
						sx={{ fontSize: 26 }}
						color={isActive ? 'primary.main' : 'text.secondary'}
						gutterBottom
					>
						{header}
					</Typography>
				)}
				{subheader && (
					<Typography variant="subtitle1" component="div">
						{subheader}
					</Typography>
				)}
				{body && <Typography variant="body2">{body}</Typography>}
				{children}
			</CardContent>
			{learnMoreLink && (
				<CardActions disableSpacing sx={{ mt: 'auto' }}>
					<Button
						size="small"
						component={Link}
						href={learnMoreLink}
						target="_blank"
						rel="noopener noreferrer"
					>
						Learn More
					</Button>
				</CardActions>
			)}
		</Card>
	);
}

MuiCard.propTypes = {
	header: PropTypes.string,
	subheader: PropTypes.string,
	body: PropTypes.string,
	learnMoreLink: PropTypes.string,
	isActive: PropTypes.bool,
	children: PropTypes.node,
	sx: PropTypes.shape({}),
};

MuiCard.defaultProps = {
	header: '',
	subheader: '',
	body: '',
	learnMoreLink: '',
	isActive: false,
	children: null,
	sx: {},
};

export default MuiCard;
