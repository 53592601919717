import * as React from 'react';

import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import MetricWidget from '@app/components/shared/ui/MetricWidget';
import { OffersAggregations } from './types';
import { parsePercents } from '@app/lib/parse';
import ProgressBar from '@app/components/shared/loaders/ProgressBar';
import constants from '@app/constants/index';
import { campaignFinalized } from './utils';
import WaterfallChart from './WaterfallChart';
import useAuth from '@app/hooks/useAuth';

function Summary({ campaign, aggregations }) {
	const isFinalized = campaignFinalized(campaign);
	const { formatMerchantCurrency } = useAuth();

	const revenue = isFinalized
		? aggregations.factualRevenue
		: aggregations.possibleRevenue;

	const averageDiscount =
		aggregations.averageDiscount !== null &&
		aggregations.averageDiscount !== undefined
			? `${parsePercents(aggregations.averageDiscount)}%`
			: '-';

	const initialAverageDiscount =
		aggregations.initialAverageDiscount !== null &&
		aggregations.initialAverageDiscount !== undefined
			? `${parsePercents(aggregations.initialAverageDiscount)}%`
			: '-';

	return (
		<Box sx={{ width: '100%' }}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={8}>
					<Box sx={{ height: '100%' }}>
						<Card sx={{ height: '100%', p: 1 }}>
							<WaterfallChart
								campaignId={campaign.id}
								formatMerchantCurrency={formatMerchantCurrency}
								aggregations={aggregations}
								isFinalized={isFinalized}
							/>
						</Card>
					</Box>
				</Grid>

				<Grid item md={4} xs={12}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 1,
							height: '100%',
						}}
					>
						<Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
							<MetricWidget
								name="Revenue"
								height="100%"
								value={formatMerchantCurrency(revenue)}
								sx={{ width: '50%' }}
							/>
							<MetricWidget
								name="Units Sold"
								height="100%"
								value={aggregations.fulfilledOffersInventory}
								sx={{ width: '50%' }}
							/>
						</Box>
						<Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
							<MetricWidget
								name="Avg. Initial Discount"
								height="100%"
								value={initialAverageDiscount}
								sx={{ width: '50%' }}
							/>
							<MetricWidget
								name="Avg. Final Discount"
								height="100%"
								value={averageDiscount}
								sx={{ width: '50%' }}
							/>
						</Box>

						<MetricWidget
							name="Execution Progress"
							height="100%"
							value={
								<ProgressBar
									data={[
										{
											label: 'Accepted offers units',
											value:
												aggregations.acceptedOffersInventory +
												aggregations.counteredAndAcceptedOffersInventory,
											color: constants.colors.primary,
										},
										{
											label: 'Countered offers units',
											value: aggregations.counteredOffersInventory,
											color: constants.colors.tertiary,
										},
										{
											label: 'Canceled offers units',
											value: aggregations.canceledOffersInventory,
											color: constants.colors.failure,
										},
									]}
									total={aggregations.offersInventory}
									showLegend
									legendInPercentage
								/>
							}
						/>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

Summary.propTypes = {
	campaign: PropTypes.shape({
		id: PropTypes.string,
		end_date: PropTypes.string,
	}).isRequired,
	aggregations: OffersAggregations.isRequired,
};

Summary.defaultProps = {};

export default Summary;
