import { Popover } from '@mui/material';
import React from 'react';
import useAuth from '@app/hooks/useAuth';
import SubscriptionNavItemContent from './SubscriptionNavItemContent';

function SubscriptionNavItem() {
	const { merchant } = useAuth();
	const [popoverDisplayed, setPopoverDisplayed] =
		React.useState(false);

	return (
		<>
			<Popover
				id="subscription-info-popover"
				sx={{
					pointerEvents: 'none',
					top: '50%',
					transform: 'translate(0%, -50px)',
				}}
				open={popoverDisplayed && merchant?.active_subscription}
				anchorEl={document.body}
				anchorOrigin={{
					horizontal: 'center',
					vertical: 'top',
				}}
				transformOrigin={{
					horizontal: 'center',
					vertical: 'top',
				}}
				onClose={() => setPopoverDisplayed(false)}
				disableRestoreFocus
			>
				<SubscriptionNavItemContent isInPopover />
			</Popover>
			<SubscriptionNavItemContent
				setPopoverDisplayed={setPopoverDisplayed}
			/>
		</>
	);
}

export default SubscriptionNavItem;
