import * as React from 'react';

import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MetricWidget from '@app/components/shared/ui/MetricWidget';
import ProgressBar from '@app/components/shared/loaders/ProgressBar';
import constants from '@app/constants/index';
import OffersChart from '@app/components/charts/OffersChart';
import { ProductType } from '@app/propTypes/types';
import { CampaignType } from '@app/propTypes/campaign';
import useAuth from '@app/hooks/useAuth';
import { getThresholdPrice } from '@app/helpers/campaignHelpers';

function NYOPProductSummary({
	product,
	offers,
	campaign = { automation_rules: null },
}) {
	const { formatMerchantCurrency } = useAuth();
	const prices = product.variants.map((variant) => variant.price);
	const msrps = product.variants
		.filter((variant) => !!variant.msrp)
		.map((variant) => variant.msrp);

	const minPrice = Math.min(...prices);
	const maxPrice = Math.max(...prices);
	const minMSRP = msrps.length !== 0 ? Math.min(...msrps) : minPrice;
	const maxMSRP = msrps.length !== 0 ? Math.max(...msrps) : maxPrice;

	const thresholdPrice = getThresholdPrice({
		automationRules: campaign.automation_rules,
		id: product.id,
		price: maxMSRP || maxPrice,
	});

	const getProductMsrp = () => {
		if (!minMSRP && !maxMSRP) {
			return minPrice;
		}
		return minMSRP === maxMSRP
			? formatMerchantCurrency(minMSRP)
			: `${formatMerchantCurrency(minMSRP)}-${formatMerchantCurrency(
					maxMSRP,
			  )}`;
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={8}>
					<Box sx={{ height: '100%' }}>
						<Card
							sx={{
								height: '100%',
								p: 1,
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<OffersChart
								offers={offers}
								formatMerchantCurrency={formatMerchantCurrency}
								msrp={maxMSRP || maxPrice}
								thresholdPrice={thresholdPrice}
							/>
						</Card>
					</Box>
				</Grid>

				<Grid item md={4} xs={12}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 1,
							height: '100%',
						}}
					>
						<MetricWidget name="MSRP" value={getProductMsrp()} />
						<MetricWidget
							name="Threshold Price"
							value={formatMerchantCurrency(thresholdPrice)}
						/>
						<MetricWidget
							name="Execution Progress"
							value={
								<Box sx={{ width: '100%' }}>
									<ProgressBar
										data={[
											{
												label: 'Accepted offers units',
												value: product.summary.convertedUnits,
												color: constants.colors.primary,
											},
											{
												label: 'Countered offers units',
												value: product.summary.counteredUnits,
												color: constants.colors.tertiary,
											},
											{
												label: 'Canceled offers units',
												value: product.summary.lostUnits,
												color: constants.colors.failure,
											},
										]}
										total={product.summary.totalUnits}
										showLegend
										legendInPercentage
									/>
								</Box>
							}
						/>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

NYOPProductSummary.propTypes = {
	product: ProductType.isRequired,
	offers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			offer_amount: PropTypes.string.isRequired,
			counter_offer_amount: PropTypes.string,
			accepted_at: PropTypes.string,
			counter_offer_created_at: PropTypes.string,
			counter_offer_accepted_at: PropTypes.string,
			canceled_at: PropTypes.string,
		}),
	).isRequired,
	campaign: CampaignType,
};

export default NYOPProductSummary;
