import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AutomationBulkDiscountTier from '@app/pages/createCampaign/Nyop/components/AutomationBulkDiscount/Tier';
import { previewAppBlockDeepLink } from '@app/helpers/deepLink.helper';
import useAuth from '@app/hooks/useAuth';
import InfoPopover from '@app/components/shared/ui/InfoPopoverIcon';
import Button from '@app/components/shared/button/Button';
import ConversationalInputWrapper from '@app/components/shared/inputs/ConversationalTextInput';
import constants from '@app/constants';

const DEFAULT_INITIAL_BULK_DISCOUNT_TIER = {
	quantity: 3,
	extraDiscount: 5,
};

const generateTierId = () =>
	Math.round(Math.random() * 100000).toString();

const getDefaultTier = () => ({
	...DEFAULT_INITIAL_BULK_DISCOUNT_TIER,
	id: generateTierId(),
});

const DEFAULT_TIER_STEP_QUANTITY = 2;
const DEFAULT_TIER_STEP_DISCOUNT = 2;

const DUPLICATED_TIER_MESSAGE =
	'There is another tier with the same quantity, please adjust the quantity so every tier has unique valus';
const INCORRECT_QUANTITY_TIER_MESSAGE =
	'Quantity must be greater than or equal to 1';
const MISSING_QUANTITY_TIER_MESSAGE = 'You must provide a quantity';
const LESSER_EXTRA_DISCOUNT_TIER_MESSAGE =
	'Extra discount for this tier should be larger than for the previous one';

const tiersFromInternal = (tiers) => {
	if (!tiers || tiers.length === 0) {
		return [];
	}

	return tiers.map((tier) => ({
		id: generateTierId(),
		quantity: tier.quantity,
		extraDiscount: Math.round(tier.extraDiscount * 100),
	}));
};

const tiersToInternal = (tiers) => {
	if (!tiers || tiers.length === 0) {
		return [];
	}

	return tiers.map((tier) => ({
		quantity: tier.quantity,
		extraDiscount: tier.extraDiscount / 100,
	}));
};

const prepareTiers = (tiers) => {
	let maxDiscount = 0;
	return tiers.map((tier) => {
		let message = null;
		let invalid = false;

		const isDuplicated = tiers.find(
			(t) => t.id !== tier.id && t.quantity === tier.quantity,
		);
		if (isDuplicated) {
			message = DUPLICATED_TIER_MESSAGE;
			invalid = true;
		} else if (!tier.quantity && tier.quantity !== 0) {
			message = MISSING_QUANTITY_TIER_MESSAGE;
			invalid = true;
		} else if (tier.quantity < 1) {
			message = INCORRECT_QUANTITY_TIER_MESSAGE;
			invalid = true;
		} else if (maxDiscount >= tier.extraDiscount) {
			message = LESSER_EXTRA_DISCOUNT_TIER_MESSAGE;
			invalid = true;
		}

		maxDiscount = Math.max(maxDiscount, tier.extraDiscount);

		return { ...tier, message, invalid };
	});
};

function AutomationBulkDiscounts({
	disabled,
	onChange,
	onValidChange,
	maxDiscount,
	defaultBulkDiscounts,
}) {
	const { merchant } = useAuth();
	const defaultEnabled =
		defaultBulkDiscounts &&
		Object.keys(defaultBulkDiscounts).length !== 0;

	const [enabled, setEnabled] = React.useState(defaultEnabled);
	const [tiers, setTiers] = React.useState(
		prepareTiers(tiersFromInternal(defaultBulkDiscounts)),
	);
	const sortedTiers = tiers.sort((t1, t2) =>
		t1.quantity > t2.quantity ? 1 : -1,
	);

	const handleTiersUpdate = ({ newTiers, isEnabled }) => {
		const validatedTiers = prepareTiers(newTiers);

		setTiers(validatedTiers);

		if (isEnabled) {
			onChange(tiersToInternal(newTiers));
		} else {
			onChange([]);
		}
	};

	const addTier = () => {
		const lastTier = tiers.at(-1);
		if (!lastTier || !lastTier.quantity) {
			return;
		}

		const newTier = {
			quantity: lastTier.quantity + DEFAULT_TIER_STEP_QUANTITY,
			extraDiscount:
				lastTier.extraDiscount + DEFAULT_TIER_STEP_DISCOUNT,
			id: generateTierId(),
		};

		const newTiers = [...tiers, newTier];
		handleTiersUpdate({ newTiers, isEnabled: enabled });
	};

	const updateTierHandler = (tierIndex) => (updatedTier) => {
		const newTiers = [
			...tiers.slice(0, tierIndex),
			updatedTier,
			...tiers.slice(tierIndex + 1),
		];
		handleTiersUpdate({ newTiers, isEnabled: enabled });
	};

	const deleteTierHandler = (tierIndex) => () => {
		const newTiers = [
			...tiers.slice(0, tierIndex),
			...tiers.slice(tierIndex + 1),
		];
		if (newTiers.length === 0) {
			setEnabled(false);
		}
		handleTiersUpdate({ newTiers, isEnabled: enabled });
	};

	const onEnabledChange = (event) => {
		setEnabled(event.target.checked);
		if (tiers.length === 0) {
			const newTiers = [getDefaultTier()];
			handleTiersUpdate({
				newTiers,
				isEnabled: event.target.checked,
			});
		} else {
			handleTiersUpdate({
				newTiers: tiers,
				isEnabled: event.target.checked,
			});
		}
	};

	React.useEffect(() => {
		if (!enabled || sortedTiers.length === 0) {
			onValidChange(true);
			return;
		}

		onValidChange(sortedTiers.every((tier) => !tier.invalid));
	}, [enabled, onValidChange, sortedTiers]);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<ConversationalInputWrapper prompt="Would you like to offer Bulks Discounts?">
				<FormGroup sx={{ mb: -2, height: '100%' }}>
					<FormControlLabel
						sx={{
							width: 'fit-content',
						}}
						control={
							<Switch
								checked={enabled}
								disabled={disabled}
								onChange={onEnabledChange}
							/>
						}
						label="Bulk Discounts"
					/>
				</FormGroup>
			</ConversationalInputWrapper>

			<Box
				sx={{
					display: enabled ? 'flex' : 'none',
					flexDirection: 'column',
				}}
			>
				<Box>
					{sortedTiers.map((discountTier, tierIndex) => (
						<AutomationBulkDiscountTier
							disabled={disabled}
							onDelete={deleteTierHandler(tierIndex)}
							onChange={updateTierHandler(tierIndex)}
							defaultTier={discountTier}
							maxDiscount={maxDiscount}
							key={`bulk-discount-tier-${discountTier.id}`}
						/>
					))}
				</Box>

				{tiers.length < 5 ? (
					<Button
						onClick={addTier}
						sx={{ mt: 2 }}
						disabled={disabled}
					>
						Add tier
					</Button>
				) : null}
				<Typography
					fontWeight={400}
					fontSize="0.75rem"
					color={constants.colors.darkGrey}
					marginTop={0.5}
				>
					Create up to 5 discount tiers to offer shoppers increasing
					savings on larger orders.
				</Typography>

				{tiers.length !== 0 ? (
					<Box
						sx={{
							mt: 1,
							mb: 2,
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Link
							href={previewAppBlockDeepLink({
								uuid: import.meta.env.VITE_PREORDER_UUID,
								myshopifyDomain: merchant?.myshopify_domain,
								template: 'product',
								target: 'mainSection',
							})}
							rel="noopener noreferrer"
							target="_blank"
						>
							Configure how your shoppers see bulk discounts
						</Link>
						<InfoPopover
							text="Encourage higher order quantities by offering additional discounts. Set the number of items required and the extra discount percentage. This bulk discount will be messaged to shoppers, incentivizing them to increase their cart size for better deals."
							iconStyles={{ ml: 1 }}
						/>
					</Box>
				) : null}
			</Box>
		</Box>
	);
}

AutomationBulkDiscounts.propTypes = {
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	onValidChange: PropTypes.func.isRequired,
	maxDiscount: PropTypes.number.isRequired,
	defaultBulkDiscounts: PropTypes.arrayOf(
		PropTypes.shape({
			quantity: PropTypes.number.isRequired,
			extraDiscount: PropTypes.number.isRequired,
		}),
	),
};

AutomationBulkDiscounts.defaultProps = {
	defaultBulkDiscounts: null,
	disabled: false,
};
export default AutomationBulkDiscounts;
