import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from './useAuth';

function RequireAuth({ children }) {
	const { user, merchant } = useAuth();
	const location = useLocation();

	// You are not logged in, redirect to login
	if (!user) {
		return (
			<Navigate
				to={{
					...location,
					pathname: '/login',
					state: { from: location },
				}}
				replace
			/>
		);
	}

	// // If you are logged in without a selected merchant redirect here
	if (
		user &&
		!merchant &&
		location.pathname !== '/select-active-merchant' &&
		location.pathname !== '/onboarding'
	) {
		return (
			<Navigate
				to="/select-active-merchant"
				state={{ from: location }}
				replace
			/>
		);
	}

	// You have not finished onboarding
	if (
		user &&
		merchant &&
		(!merchant?.onboarding_complete || !merchant?.accept_terms) &&
		location.pathname !== '/onboarding' &&
		location.pathname !== '/select-active-merchant'
	) {
		return (
			<Navigate
				to={{
					...location,
					pathname: '/onboarding',
					state: { from: location },
				}}
				replace
			/>
		);
	}

	return children; // eslint-disable-line
}

RequireAuth.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]).isRequired,
};

export default RequireAuth;
