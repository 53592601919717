import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

function CustomerInformation({
	billingAddress,
	shippingAddress,
	customer,
}) {
	return (
		<Grid container>
			<Grid item xs={12} mb={1}>
				<Typography variant="subtitle1" fontWeight="bold">
					Customer Information
				</Typography>
			</Grid>

			<Grid item sm={6} xs={12}>
				<Box mb={1}>
					<Typography
						variant="subtitle1"
						fontSize={14}
						fontWeight="bold"
					>
						Contact Information
					</Typography>
					{customer?.email && (
						<Typography fontSize={14}>{customer.email}</Typography>
					)}
					{customer?.phone && (
						<Typography fontSize={14}>{customer.phone}</Typography>
					)}
				</Box>
				{/* Shipping address can be optional with non-deliverable goods */}
				{shippingAddress?.address1 && (
					<Box mb={1}>
						<Typography
							variant="subtitle1"
							fontSize={14}
							fontWeight="bold"
						>
							Shipping address
						</Typography>
						<Typography fontSize={14}>
							{shippingAddress.first_name} {shippingAddress.last_name}
						</Typography>
						<Typography fontSize={14}>
							{shippingAddress.address1}
						</Typography>
						{shippingAddress?.address2 && (
							<Typography fontSize={14}>
								{shippingAddress.address2}
							</Typography>
						)}
						<Typography fontSize={14}>
							{shippingAddress.city} {shippingAddress.province_code}{' '}
							{shippingAddress.zip}
						</Typography>
						<Typography fontSize={14}>
							{shippingAddress.country}
						</Typography>
					</Box>
				)}
			</Grid>
			{billingAddress?.address1 && (
				<Grid item sm={6} xs={12}>
					<Typography fontSize={14} fontWeight="bold">
						Billing address
					</Typography>
					<Typography fontSize={14}>
						{billingAddress.first_name} {billingAddress.last_name}
					</Typography>
					<Typography fontSize={14}>
						{billingAddress.address1}
					</Typography>
					{billingAddress?.address2 && (
						<Typography fontSize={14}>
							{billingAddress.address2}
						</Typography>
					)}
					<Typography fontSize={14}>
						{billingAddress.city} {billingAddress.province_code}{' '}
						{billingAddress.zip}
					</Typography>
					<Typography fontSize={14}>
						{billingAddress.country}
					</Typography>
				</Grid>
			)}
		</Grid>
	);
}

CustomerInformation.propTypes = {
	billingAddress: PropTypes.shape({
		first_name: PropTypes.string,
		address1: PropTypes.string,
		phone: PropTypes.string,
		city: PropTypes.string,
		zip: PropTypes.string,
		province: PropTypes.string,
		country: PropTypes.string,
		last_name: PropTypes.string,
		address2: PropTypes.string,
		company: PropTypes.string,
		latitude: PropTypes.number,
		longitude: PropTypes.number,
		name: PropTypes.string,
		country_code: PropTypes.string,
		province_code: PropTypes.string,
	}),
	shippingAddress: PropTypes.shape({
		first_name: PropTypes.string,
		address1: PropTypes.string,
		phone: PropTypes.string,
		city: PropTypes.string,
		zip: PropTypes.string,
		province: PropTypes.string,
		country: PropTypes.string,
		last_name: PropTypes.string,
		address2: PropTypes.string,
		company: PropTypes.string,
		latitude: PropTypes.number,
		longitude: PropTypes.number,
		name: PropTypes.string,
		country_code: PropTypes.string,
		province_code: PropTypes.string,
	}),
	customer: PropTypes.shape({
		email: PropTypes.string,
		phone: PropTypes.string,
	}),
};
CustomerInformation.defaultProps = {
	billingAddress: {},
	shippingAddress: {},
	customer: {
		email: '',
		phone: '',
	},
};

export default CustomerInformation;
