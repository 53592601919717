import PropTypes from 'prop-types';
import React from 'react';
import { Link } from '@mui/material';

import EllipsesTypography from '@app/components/shared/ui/EllipsesTypography';
import { getOrderLink } from '@app/lib/shopify';
import useAuth from '@app/hooks/useAuth';

function IdRenderer({ id, orderId }) {
	const { merchant } = useAuth();

	return (
		<Link
			href={getOrderLink(merchant.myshopify_domain, orderId)}
			target="_blank"
		>
			<EllipsesTypography lines={1}>{id}</EllipsesTypography>
		</Link>
	);
}

IdRenderer.propTypes = {
	id: PropTypes.string.isRequired,
	orderId: PropTypes.string.isRequired,
};

export default IdRenderer;
