import PropTypes from 'prop-types';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import NyopOffersInboxTable from './offersTable';

function Table({ marketplaceCampaignId }) {
	const [tableView, setTableView] = React.useState('inbox');

	return (
		<TabContext value={tableView}>
			<TabList
				onChange={(_, newValue) => setTableView(newValue)}
				sx={{ mb: 3 }}
			>
				<Tab value="inbox" label="Inbox" />
				{/* <Tab value="products" label="Products" /> */}
			</TabList>
			<TabPanel value="inbox">
				<NyopOffersInboxTable campaignId={marketplaceCampaignId} />
			</TabPanel>
			{/* <TabPanel value="products"> */}
			{/*	<ProductTable campaign={campaign} /> */}
			{/* </TabPanel> */}
		</TabContext>
	);
}

Table.propTypes = {
	marketplaceCampaignId: PropTypes.string.isRequired,
};

export default Table;
