import * as React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

function ErrorMessage({ message, sx }) {
	if (!message) return null;

	return (
		<Typography sx={sx} color="error">
			{message}
		</Typography>
	);
}

ErrorMessage.propTypes = {
	message: PropTypes.string,
	sx: PropTypes.objectOf(PropTypes.string),
};

ErrorMessage.defaultProps = {
	message: '',
	sx: {},
};

export default ErrorMessage;
