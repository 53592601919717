import * as React from 'react';
import PropTypes from 'prop-types';

import { getCookieConsentValue } from 'react-cookie-consent';

const CookieContext = React.createContext();

export function CookieConsentProvider({ children }) {
	const [cookieConsent, setCookieConsent] = React.useState(undefined);
	const [cookieConsentClicked, setCookieConsentClicked] =
		React.useState(false);

	React.useEffect(() => {
		const consent = getCookieConsentValue();
		setCookieConsent(consent === 'true');
	}, [cookieConsent]);

	const cookieMemo = React.useMemo(
		() => ({
			cookieConsent,
			setCookieConsent,
			cookieConsentClicked,
			setCookieConsentClicked,
		}),
		[
			cookieConsent,
			setCookieConsent,
			cookieConsentClicked,
			setCookieConsentClicked,
		],
	);

	return (
		<CookieContext.Provider value={cookieMemo}>
			{children}
		</CookieContext.Provider>
	);
}

CookieConsentProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export default function useCookieContext() {
	return React.useContext(CookieContext);
}
