import PropTypes from 'prop-types';

import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { useNyopCampaignDelete } from '@app/hooks/useNyopCampaign';
import Button from '@app/components/shared/button/Button';

function CancelAction({ campaignId, onSuccess }) {
	const {
		mutate,
		error,
		isLoading: loading,
	} = useNyopCampaignDelete({ id: campaignId });

	const handleSubmit = () =>
		mutate(
			{ id: campaignId },
			{
				onSuccess,
			},
		);

	return (
		<Box>
			<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
				Are you sure you want to cancel this campaign?
			</Typography>
			<List>
				<ListItemText
					primary="Customers won't be able to buy any more products with this campaign"
					sx={{
						padding: 0,
						marginLeft: '20px',
						listStyleType: 'disc',
						display: 'list-item',
					}}
				/>
				<ListItemText
					primary="All outstanding offers will be rejected and related orders will be canceled."
					sx={{
						padding: 0,
						marginLeft: '20px',
						listStyleType: 'disc',
						display: 'list-item',
					}}
				/>
			</List>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
			>
				<Button
					type="submit"
					color="error"
					loading={loading}
					onClick={handleSubmit}
				>
					CANCEL
				</Button>

				{error && (
					<Typography color="error">{error.message}</Typography>
				)}
			</Box>
		</Box>
	);
}

CancelAction.propTypes = {
	campaignId: PropTypes.string.isRequired,
	onSuccess: PropTypes.func.isRequired,
};

CancelAction.defaultProps = {};

export default CancelAction;
