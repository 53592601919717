import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import ProductThumbnail from '@app/components/shared/ui/ProductThumbnail';

function TitleWithImage({
	title,
	subtitle,
	url,
	imageUrl,
	titleIcon,
	deleted,
}) {
	return (
		<Box
			sx={{
				alignItems: 'center',
				flexDirection: 'row',
				display: 'flex',
			}}
		>
			<ProductThumbnail
				variantImage={{ src: imageUrl }}
				deleted={deleted}
			/>
			<Box sx={{ marginLeft: 2 }}>
				<Box sx={{ display: 'flex', gap: 1 }}>
					<Typography>
						{url ? (
							<Link
								href={url}
								target="_blank"
								rel="noopener noreferrer"
								underline="hover"
							>
								{title}
							</Link>
						) : (
							title
						)}
					</Typography>
					{titleIcon}
				</Box>
				{subtitle && (
					<Typography color="pending.main" variant="caption">
						{subtitle}
					</Typography>
				)}
			</Box>
		</Box>
	);
}

TitleWithImage.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	url: PropTypes.string,
	imageUrl: PropTypes.string,
	titleIcon: PropTypes.node,
	deleted: PropTypes.bool,
};

TitleWithImage.defaultProps = {
	subtitle: null,
	imageUrl: null,
	url: null,
	titleIcon: null,
	deleted: false,
};

export default TitleWithImage;
