import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as _ from 'lodash-es';

function LegendTooltip({
	showLegend,
	data,
	total,
	legendInPercentage,
	children,
	showUnfilled,
	unfilledLabel,
}) {
	const sum = _.reduce(data, (r, i) => r + i.value, 0);

	const getValueInFormat = React.useCallback(
		(value) => {
			if (legendInPercentage) {
				if (total === 0) {
					return `0%`;
				}
				return `${((value / total) * 100).toFixed(2)}%`;
			}

			return value;
		},
		[legendInPercentage, total],
	);

	return (
		<Tooltip
			componentsProps={{
				tooltip: {
					sx: {
						backgroundColor: 'transparent',
						color: 'black',
					},
				},
			}}
			placement="top-start"
			title={
				showLegend ? (
					<Box
						sx={{
							backgroundColor: '#fff',
							color: 'black',
							padding: 2,
							borderWidth: 1,
							borderColor: '#000',
							borderRadius: 2,
							boxShadow: 1,
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						{data.map((record, index) => (
							<Box
								key={`legend-tooltip-${record.label}-${index}`} // eslint-disable-line
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									marginTop: index > 0 ? 2 : 0,
								}}
							>
								<Box
									sx={{
										width: 10,
										height: 10,
										backgroundColor: record.color,
										borderRadius: 0.5,
										marginRight: 2,
									}}
								/>
								<Typography>
									{record.label} {getValueInFormat(record.value)}
								</Typography>
							</Box>
						))}
						{showUnfilled && (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									marginTop: 2,
								}}
							>
								<Box
									sx={{
										width: 10,
										height: 10,
										backgroundColor: '#CCCCCC',
										borderRadius: 0.5,
										marginRight: 2,
									}}
								/>
								<Typography>
									{unfilledLabel} {getValueInFormat(total - sum)}
								</Typography>
							</Box>
						)}
					</Box>
				) : (
					''
				)
			}
		>
			{children}
		</Tooltip>
	);
}

const Record = PropTypes.shape({
	color: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	label: PropTypes.string,
});

LegendTooltip.propTypes = {
	showLegend: PropTypes.bool,
	data: PropTypes.arrayOf(Record).isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	legendInPercentage: PropTypes.bool,
	total: PropTypes.number.isRequired,
	showUnfilled: PropTypes.bool,
	unfilledLabel: PropTypes.string,
};

LegendTooltip.defaultProps = {
	showLegend: false,
	legendInPercentage: false,
	showUnfilled: true,
	unfilledLabel: 'Pending review',
};

export default LegendTooltip;
