/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import PropTypes from 'prop-types';
import {
	useLocation,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';

import { useEventBus } from './useEventBus';
import * as events from '@app/constants/eventConstants';

import axios from '../api/index';
import * as sessionsApi from '../api/sessions';
import * as merchantApi from '../api/merchant';
import * as userApi from '../api/users';

import Loader from '../components/shared/loaders/CircularLoader';

import {
	localizeCurrency,
	localizeNumber,
} from '../helpers/localization.helpers';

const AuthContext = React.createContext({
	user: null,
	merchant: null,
	loading: true,
});

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({ children }) {
	// Event bus for clearing state on logout or shop switch
	const { broadcast } = useEventBus();

	// Auth state
	const [user, setUser] = React.useState(null);
	const [merchant, setMerchant] = React.useState(null);

	const [error, setError] = React.useState(null);

	const [loading, setLoading] = React.useState(false);
	const [loadingInitial, setLoadingInitial] = React.useState(true);
	const [interceptorsLoaded, setInterceptorsLoaded] =
		React.useState(false);

	// App react query state
	const queryClient = useQueryClient();

	// React router
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const chargeId = searchParams.get('charge_id');

	React.useEffect(() => {
		const getSubscription = async () => {
			const activeSubscription =
				await merchantApi.setBillingTierByChargeId({ chargeId });
			if (activeSubscription) {
				setMerchant((prevMerchant) => ({
					...prevMerchant,
					active_subscription: activeSubscription,
				}));
			}
		};

		if (chargeId) {
			getSubscription();
		}
	}, [chargeId]);

	const onActiveShopChange = async ({ merchantId }) => {
		const from = location.state?.from ?? '/';
		try {
			if (merchantId && user && user?.merchants) {
				const userRefreshData = await sessionsApi.getSession();
				if (!userRefreshData) {
					setUser(null);
					setMerchant(null);
					await sessionsApi.logout();
					broadcast(events.ACTIVE_SHOP_CHANGED_EVENT, {});
					navigate('/login');
					return;
				}
				const newActiveMerchant =
					userRefreshData?.user?.merchants.find(
						(m) => m.id === merchantId,
					);
				const updatedMerchant = await userApi.switchActiveMerchant(
					merchantId,
				);
				if (updatedMerchant.merchant === newActiveMerchant.id) {
					setMerchant(newActiveMerchant);
					await queryClient.clear();
					broadcast(events.ACTIVE_SHOP_CHANGED_EVENT, {
						merchant: newActiveMerchant,
					});
					navigate(from);
				}
			}
		} catch (err) {
			console.error(err);
			setError(new Error('Could not switch shop. Please try again'));
		} finally {
			setLoadingInitial(false);
		}
	};

	async function logout() {
		return sessionsApi
			.logout()
			.then(() => {
				setUser(null);
				setMerchant(null);
				queryClient.clear();
				broadcast(events.LOGOUT_EVENT, {});
				navigate('/login');
			})
			.catch((err) => console.error(err));
	}

	function setDefaultActiveShop(userObj) {
		if (!userObj) {
			// If no user we should not try to do any of this
			setLoadingInitial(false);
			return null;
		}

		const activeShopId = Cookies.get('active_shop');

		const activeMerchants = userObj?.merchants.filter(
			(m) => m.active,
		);

		if (activeMerchants && activeMerchants.length !== 0) {
			// If there's an active_shop cookie, set that as active shop
			if (activeShopId) {
				const activeShop = activeMerchants.find(
					(m) => m.id === activeShopId,
				);
				if (activeShop) {
					setMerchant(activeShop);
					setLoadingInitial(false);
					return null;
				}
			}
			// If there's only one merchant, log them into that shop
			if (activeMerchants.length === 1) {
				setLoadingInitial(false);
				setMerchant(activeMerchants[0]);
				return null;
			}
		}
		// There is no shop attached, you need to select one
		return navigate('/select-active-merchant');
	}

	async function register({ token, password }) {
		setLoading(true);
		try {
			const userData = await userApi.register({ token, password });
			setUser(userData.user);
			setMerchant(
				userData.user.merchants ? userData.user.merchants[0] : null,
			);
			navigate('/');
			return userData.user;
		} catch (err) {
			console.error('Problem registering user', err);
			setError(err);
		} finally {
			setLoading(false);
		}
		setUser(null);
		return null;
	}

	async function login({ username, password }) {
		setLoading(true);
		try {
			const userData = await userApi.login({ username, password });
			setUser(userData.user);
			setDefaultActiveShop(userData.user);
			navigate('/');
			return userData.user;
		} catch (err) {
			console.error('Problem logging in', err);
			setError(err);
		} finally {
			setLoading(false);
		}
		setUser(null);
		return null;
	}

	// If we change page, reset the error state.
	React.useEffect(() => {
		if (error) setError(null);
	}, [location.pathname]);

	const refreshUser = React.useCallback(async () => {
		try {
			const session = await sessionsApi.getSession();
			if (session?.user) {
				setUser(session.user);
				setDefaultActiveShop(session.user);
				return session.user;
			}
			setUser(null);
			return null;
		} catch (e) {
			if (e?.response?.status === 401) {
				setUser(null);
				return null;
			}
			return user;
		}
	}, []);
	// Check if there is a currently active session
	// when the provider is mounted for the first time.
	//
	// If there is an error, it means there is no session.
	//
	// Finally, just signal the component that the initial load
	// is over.
	React.useEffect(() => {
		setLoadingInitial(true);
		// Check for session if no user
		if (!user?.email) {
			refreshUser()
				.catch((err) => {
					// Only log unknown error codes from session check
					if (err?.response?.status !== 404) {
						Cookies.remove(sessionsApi.COOKIE);
						console.info('Unable to retrieve user session');
					}
				})
				.finally(() => setLoadingInitial(false));
		}
	}, []);

	React.useEffect(() => {
		if (!merchant) {
			setInterceptorsLoaded(true);
			return () => {};
		}

		const reqInterceptor = (request) => {
			if (request.url.includes('api')) {
				request.headers['x-merchant-id'] = merchant.id;
			}
			return request;
		};

		const interceptorReq =
			axios.interceptors.request.use(reqInterceptor);

		setInterceptorsLoaded(true);
		return () => {
			axios.interceptors.request.eject(interceptorReq);
		};
	}, [merchant]);

	// Make the provider update only when it should.
	// We only want to force re-renders if the user,
	// loading or error states change.

	// FIXME: Make this work for multiple merchants;
	const formatMerchantCurrency = (
		amount,
		maximumFractionDigits,
		currency,
	) => {
		const merchantLocale = merchant?.primary_locale || 'en';
		const merchantCurrencyCode =
			currency || merchant?.currency || 'USD';

		if (!amount && amount !== 0) {
			return '-';
		}

		if (!merchantLocale || !merchantCurrencyCode) {
			return amount;
		}

		try {
			const formattedValue = localizeCurrency({
				customerLocale: merchantLocale,
				currencyCode: merchantCurrencyCode,
				maximumFractionDigits,
				amount,
			});
			return formattedValue;
		} catch (err) {
			console.error(err);
			return amount;
		}
	};

	const formatMerchantNumber = ({ value, maximumFractionDigits }) => {
		const merchantLocale = merchant?.primary_locale || 'en';

		if (!value && value !== 0) {
			return '-';
		}

		return localizeNumber({
			locale: merchantLocale,
			maximumFractionDigits,
			value,
		});
	};

	const getCurrencySymbol = () => {
		const locale = merchant?.primary_locale || 'en';
		const currency = merchant?.currency || 'USD';
		return (0)
			.toLocaleString(locale, {
				style: 'currency',
				currency,
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			})
			.replace(/\d/g, '')
			.trim();
	};

	async function resetPasswordBegin({ email }) {
		setLoading(true);
		try {
			await userApi.resetPasswordBegin({ email });
			return true;
		} catch (err) {
			setError(err?.response.data.message);
		} finally {
			setLoading(false);
		}
		return false;
	}

	async function resetPasswordComplete({ token, password }) {
		setLoading(true);
		try {
			await userApi.resetPasswordComplete({ token, password });
			return true;
		} catch (err) {
			setError(err?.response.data.message);
		} finally {
			setLoading(false);
		}
		return false;
	}

	const activeSubscription = React.useMemo(
		() => merchant?.active_subscription,
		[merchant],
	);

	// Whenever the `value` passed into a provider changes,
	// the whole tree under the provider re-renders, and
	// that can be very costly! Even in this case, where
	// you only get re-renders when logging in and out
	// we want to keep things very performant.
	const memoedValue = React.useMemo(
		() => ({
			user,
			merchant,
			activeSubscription,
			loading,
			error,
			login,
			register,
			logout,
			formatMerchantCurrency,
			formatMerchantNumber,
			getCurrencySymbol,
			resetPasswordBegin,
			resetPasswordComplete,
			onActiveShopChange,
			refreshUser,
			setMerchant,
		}),
		[user, merchant, loading, error, loadingInitial],
	);

	// We only want to render the underlying app after we
	// assert for the presence of a current user.
	if (loadingInitial || !interceptorsLoaded) {
		return <Loader fullPage />;
	}

	return (
		<AuthContext.Provider
			value={memoedValue}
			key={merchant?.myshopify_domain || 'no-merchant'}
		>
			{children}
		</AuthContext.Provider>
	);
}

AuthProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
	return React.useContext(AuthContext);
}
