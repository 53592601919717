import * as React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { formatReadableDate } from '@app/helpers/dateHelpers';
import OutlineBox from '@app/components/shared/ui/OutlineBox';
import ErrorMessage from '@app/components/shared/ui/ErrorMessage';
import Button from '@app/components/shared/button/Button';

function CustomerConsentToDelay({ orderId, dueDate, token }) {
	const { mutate, data, isLoading, isError } = useMutation({
		mutationFn: async () =>
			axios.put(
				`/api/customers/${orderId}/consent-to-delay`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			),
	});

	const consented = data?.data?.success;

	return (
		<OutlineBox sx={{ backgroundColor: '#f5fad3', display: 'block' }}>
			{!consented && (
				<Box>
					<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
						Your order has been delayed until{' '}
						{formatReadableDate(dueDate)}.
					</Typography>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Box>
							<Typography
								variant="subtitle2"
								sx={{ width: '80%', mb: 1 }}
							>
								If you would like to proceed with your order, please
								consent by clicking Agree.
							</Typography>
							<Typography variant="subtitle2" sx={{ width: '80%' }}>
								If you do not consent to the new delivery date, you
								may cancel your order using the Cancel Order button
								below.
							</Typography>
						</Box>
						<Button
							loading={isLoading}
							color="secondary"
							onClick={mutate}
							sx={{ height: '2.5em', width: '20%' }}
						>
							Agree
						</Button>
					</Box>
				</Box>
			)}
			{consented && <Typography>Thanks for your patience</Typography>}
			<ErrorMessage
				message={
					isError &&
					'An error occurred. Could not consent to the order delay.'
				}
			/>
		</OutlineBox>
	);
}

CustomerConsentToDelay.propTypes = {
	token: PropTypes.string,
	orderId: PropTypes.string,
	dueDate: PropTypes.string,
};
CustomerConsentToDelay.defaultProps = {
	token: '',
	orderId: '',
	dueDate: '',
};

export default CustomerConsentToDelay;
