import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';

import VariantsSearchInput from '@app/components/shared/variantsSearch/VariantsSearchInput';
import Modal from '@app/components/shared/modal/Modal';
import campaignModalStyles from '@app/styles/campaignModalStyles';
import AddProductsModal from './addProductsModal';
import InfoBox from '@app/components/shared/ui/InfoBox';
import SelectedProductsTable, {
	dataIndex,
} from '@app/components/selectedProductsTable/SelectedProductsTable';
import XButton from '@app/components/selectedProductsTable/components/XButton';
import { useInventoryReviewContext } from '@app/hooks/useInventoryReviewContext';

function getProductDeleteButton(value, data, deleteProductCallback) {
	const id = data.rowData.at(dataIndex.id);
	return (
		<XButton deleteCallback={(e) => deleteProductCallback(e, id)} />
	);
}

function ProductsStep() {
	const [selectedVariants, setSelectedVariants] =
		useInventoryReviewContext();
	const [searchTermDisplay, setSearchTermDisplay] =
		React.useState('');
	const [addMoreProductsModal, setAddMoreProductsModal] =
		React.useState(false);
	const [displayVariants, setDisplayVariants] =
		React.useState(selectedVariants);

	const deleteProduct = React.useCallback(
		(e, id) => {
			e.stopPropagation();
			const variantsCallback = (prevVariants) =>
				prevVariants.filter((variant) => variant.product_id !== id);
			setSelectedVariants(variantsCallback);
			setDisplayVariants(variantsCallback);
		},
		[setSelectedVariants],
	);

	const deleteVariant = React.useCallback(
		(e, id) => {
			e.stopPropagation();
			const variantsCallback = (prevVariants) =>
				prevVariants.filter((variant) => variant.id !== id);
			setSelectedVariants(variantsCallback);
			setDisplayVariants(variantsCallback);
		},
		[setSelectedVariants],
	);

	const handleSearch = React.useCallback((event) => {
		const { value } = event.target;
		setSearchTermDisplay(value);
	}, []);

	const handleClear = React.useCallback(() => {
		setSearchTermDisplay('');
	}, []);

	React.useEffect(() => {
		if (searchTermDisplay.length === 0) {
			setDisplayVariants(selectedVariants);
			return;
		}

		setDisplayVariants((prevVariants) =>
			prevVariants.filter((variant) =>
				variant.full_title
					.toLocaleLowerCase()
					.includes(searchTermDisplay.toLocaleLowerCase()),
			),
		);
	}, [searchTermDisplay, selectedVariants]);

	const additionalProductColumns = React.useMemo(
		() => [
			{
				name: '',
				label: '',
				options: {
					customBodyRender: (value, data) =>
						getProductDeleteButton(value, data, deleteProduct),
				},
			},
		],
		[deleteProduct],
	);

	const additionalVariantColumns = React.useCallback(
		(id) => (
			<TableCell sx={{ pb: '4px', pt: '15px' }}>
				<XButton deleteCallback={(e) => deleteVariant(e, id)} />
			</TableCell>
		),
		[deleteVariant],
	);

	return (
		<SelectedProductsTable
			defaultSelectedVariants={displayVariants}
			style={{
				removeFrame: true,
				hideTitle: true,
			}}
			additionalProductColumns={additionalProductColumns}
			additionalVariantColumns={additionalVariantColumns}
			customToolbar={
				<Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginTop: 4,
							marginBottom: 2,
						}}
					>
						<VariantsSearchInput
							searchTermDisplay={searchTermDisplay}
							handleSearch={handleSearch}
							handleClear={handleClear}
							placeholder="Filter list"
						/>
						<Modal
							title="Add products"
							buttonText="Add products"
							aria={{
								describedby: 'add-products-modal-title',
								labelledby: 'add-products-modal-title',
							}}
							handleModal={setAddMoreProductsModal}
							open={addMoreProductsModal}
							contentStyle={campaignModalStyles}
						>
							<AddProductsModal
								onClose={() => setAddMoreProductsModal(false)}
							/>
						</Modal>
					</Box>
					<InfoBox
						type="warning"
						sx={{
							mt: 2,
							mb: 2,
						}}
					>
						<Typography sx={{ textAlign: 'left' }}>
							Attention: The &#39;Name Your Price&#39; feature may not
							work with bundled products or items that already have
							automatic discounts.
						</Typography>
					</InfoBox>
				</Box>
			}
		/>
	);
}

export default ProductsStep;
