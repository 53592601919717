import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InfoPopover from '@app/components/shared/ui/InfoPopoverIcon';

function CampaignFulfillmentDatePicker({ defaultDate, onChange }) {
	return (
		<Box display="inline-flex" sx={{ alignItems: 'center' }}>
			<DatePicker
				required
				disablePast
				views={['day']}
				label="Estimated fulfillment date"
				name="remainingBalanceChargeDate"
				value={defaultDate}
				onChange={onChange}
				renderInput={(params) => (
					<TextField
						{...params}
						margin="normal"
						helperText={null}
						display="block"
					/>
				)}
			/>
			<Box ml={1}>
				<InfoPopover
					text={`
						Estimated date for fulfillment.
						Any remaining customer balance will be charged then as well.
						You can change this at any time.`}
				/>
			</Box>
		</Box>
	);
}

CampaignFulfillmentDatePicker.propTypes = {
	defaultDate: PropTypes.instanceOf(Date).isRequired,
	onChange: PropTypes.func.isRequired,
};

export default CampaignFulfillmentDatePicker;
