import * as React from 'react';
import PropTypes from 'prop-types';

import Link from '@mui/material/Link';

function Mailto({ email, subject, body, children, color }) {
	let params = subject || body ? '?' : '';
	if (subject) {
		params += `subject=${encodeURIComponent(subject)}`;
	}
	if (body) {
		params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
	}

	return (
		<Link color={color} href={`mailto:${email}${params}`}>
			{children}
		</Link>
	);
}

Mailto.propTypes = {
	email: PropTypes.string.isRequired,
	subject: PropTypes.string,
	body: PropTypes.string,
	children: PropTypes.node.isRequired,
	color: PropTypes.string,
};

Mailto.defaultProps = {
	subject: '',
	body: '',
	color: 'primary',
};

export default Mailto;
