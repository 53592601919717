import React from 'react';
import * as _ from 'lodash-es';

import MUIDataTable from 'mui-datatables';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';

import ActionsRenderer from './ActionsRenderer';
import RevenueRenderer from '@app/components/muiTable/elements/RevenueRenderer';
import ExecutionProgress from '@app/components/muiTable/elements/ExecutionProgress';
import CollectionsRenderer from '@app/components/muiTable/elements/CollectionsRenderer';
import SellthroughRenderer from '@app/components/muiTable/elements/SellthroughRenderer';
import UnitsRenderer from '@app/components/muiTable/elements/UnitsRenderer';
import TitleWithImage from '@app/components/muiTable/elements/TitleWithImage';
import {
	getProductLink,
	getProductVariantLink,
} from '@app/lib/shopify';
import useAuth from '@app/hooks/useAuth';
import { getRangeOptions } from '@app/components/shared/Table/components/MuiDatatableRangeFilter';
import { useNYOPProducts } from '@app/hooks/useNyopCampaign';
import { useCollections } from '@app/hooks/useCollections';
import { capitalizeFirstLetter } from '@app/helpers/stringHelpers';
import { parsePercents } from '@app/lib/parse';
import { formatDate } from '@app/helpers/dateHelpers';
import { handleCsvDownload } from '@app/helpers/csvDownloadHelpers';
import TableCustomFilterDialogFooter from '@app/components/shared/Table/components/TableCustomFilterDialogFooter';

const DataColumnOptions = {
	viewColumns: false,
	filter: false,
	sort: false,
	download: false,
	display: 'excluded',
};

const DataIndex = {
	deleted_at: -8,
	summary: -7,
	isFinalized: -6,
	shop: -5,
	id: -4,
	variants: -3,
	image_url: -2,
	handle: -1,
};

function getDataColumns() {
	return Object.keys(DataIndex).map((name) => ({
		name,
		options: DataColumnOptions,
	}));
}

function getProductTitle(value, data) {
	const imageUrl = data.rowData.at(DataIndex.image_url);
	const id = data.rowData.at(DataIndex.id);
	const shop = data.rowData.at(DataIndex.shop);
	const handle = data.rowData.at(DataIndex.handle);
	const deletedAt = data.rowData.at(DataIndex.deleted_at);
	return (
		<TitleWithImage
			title={value}
			imageUrl={imageUrl}
			subtitle={handle}
			url={getProductLink(shop, id)}
			deleted={!!deletedAt}
		/>
	);
}

function getExecutionProgress(value, data) {
	const summary = data.rowData.at(DataIndex.summary);
	return (
		<ExecutionProgress
			total={value}
			convertedUnits={summary.convertedUnits}
			counteredUnits={summary.counteredUnits}
			lostUnits={summary.lostUnits}
		/>
	);
}

function getCollectionsRenderer(value) {
	const collections = value.map((node) => node.title);
	return <CollectionsRenderer collections={collections} />;
}

function getActionsRenderer(value) {
	return <ActionsRenderer productId={value} />;
}

function getRevenueRenderer(value, data) {
	const summary = data.rowData.at(DataIndex.summary);
	return (
		<RevenueRenderer
			factualRevenue={summary.factualRevenue}
			possibleRevenue={summary.possibleRevenue}
			potentialRevenue={summary.potentialRevenue}
			isFinalized
		/>
	);
}

function getSellthroughRenderer(value, data) {
	const summary = data.rowData.at(DataIndex.summary);
	const availableUnits = data.rowData
		.at(DataIndex.variants)
		.reduce((res, v) => res + v.inventory_quantity, 0);
	return (
		<SellthroughRenderer
			factualOffersUnits={summary.factualOffersUnits}
			possibleOffersUnits={summary.possibleOffersUnits}
			potentialOffersUnits={summary.potentialOffersUnits}
			isFinalized
			availableUnits={availableUnits}
		/>
	);
}

function getUnitsRenderer(value, data) {
	const summary = data.rowData.at(DataIndex.summary);
	return (
		<UnitsRenderer
			factualOffersUnits={summary.factualOffersUnits}
			possibleOffersUnits={summary.possibleOffersUnits}
			potentialOffersUnits={summary.potentialOffersUnits}
			isFinalized
		/>
	);
}

function getColumns({
	formatMerchantCurrency,
	formatMerchantNumber,
	products,
	allCollections,
}) {
	const revenueList = products.map((v) => v.revenue);
	const maxRevenue = Math.max(...revenueList);
	const minRevenue = Math.min(...revenueList);

	return [
		{
			name: 'title',
			label: 'Product',
			options: {
				filter: false,
				customBodyRender: getProductTitle,
			},
		},
		{
			name: 'collections',
			label: 'Collection',
			options: {
				customBodyRender: getCollectionsRenderer,
				sort: false,
				filterType: 'multiselect',
				filterList: [],
				filterOptions: {
					names: allCollections,
				},
				convertToCsv: (collectionData) =>
					collectionData
						.map((collection) => collection.title)
						.join(', '),
			},
		},
		{
			name: 'revenue',
			label: 'Revenue',
			options: {
				filterType: 'custom',
				filterOptions: getRangeOptions({
					format: formatMerchantCurrency,
					revert: (value) => Number(value.replace(/[^0-9.-]+/g, '')),
					maxValue: maxRevenue,
					minValue: minRevenue,
				}),
				customBodyRender: getRevenueRenderer,
				convertToCsv: (revenue, data) => {
					const summary = data.at(DataIndex.summary);
					return `${formatMerchantCurrency(
						summary.potentialRevenue,
					)} / ${formatMerchantCurrency(revenue)}`;
				},
			},
		},
		{
			name: 'sellThrough',
			label: 'Sell-through',
			options: {
				filter: false,
				customBodyRender: getSellthroughRenderer,
				convertToCsv: (sellThrough, data) => {
					const summary = data.at(DataIndex.summary);
					const availableUnits = data
						.at(DataIndex.variants)
						.reduce((res, v) => res + v.inventory_quantity, 0);

					const potentialSellThrough = parsePercents(
						Math.min(
							summary.potentialOffersUnits / availableUnits,
							1,
						),
					);
					const actualSellThrough = parsePercents(
						Math.min(summary.factualOffersUnits / availableUnits, 1),
					);

					return `${potentialSellThrough}% / ${actualSellThrough}%`;
				},
			},
		},
		{
			name: 'offersUnits',
			label: 'Units from offers',
			options: {
				filter: false,
				customBodyRender: getUnitsRenderer,
				convertToCsv: (offersUnits, data) => {
					const summary = data.at(DataIndex.summary);
					return `${offersUnits} / ${summary.factualOffersUnits}`;
				},
			},
		},
		{
			name: 'inStockUnits',
			label: 'Units available',
			options: {
				customBodyRender: (value) => (
					<Box sx={{ textAlign: 'center' }}>
						{formatMerchantNumber({ value })}
					</Box>
				),
				filter: false,
				sort: false,
			},
		},
		{
			name: 'summary.totalUnits',
			label: 'Execution Progress',
			options: {
				customBodyRender: getExecutionProgress,
				sort: false,
				filter: false,
				download: false,
			},
		},
		{
			name: 'id',
			label: 'Actions',
			options: {
				filter: false,
				sort: false,
				download: false,
				print: false,
				customBodyRender: getActionsRenderer,
			},
		},
		// // These columns are here just for reference, all new columns should be added on the top
		...getDataColumns(),
	];
}

function Table() {
	const { formatMerchantCurrency, formatMerchantNumber } = useAuth();

	// table state
	const [expandedProductsIds, setExpandedProductsIds] =
		React.useState([]);

	// filtering/pagination state values
	const [count, setCount] = React.useState(null);
	const [searchTerm, setSearchTerm] = React.useState('');
	const [page, setPage] = React.useState(0);
	const [sortKey, setSortKey] = React.useState(
		'summary.factualRevenue,desc',
	);
	const [perPage, setPerPage] = React.useState(20);
	const [collections, setCollections] = React.useState([]);

	const { data, isLoading, isFetching, error } = useNYOPProducts({
		queryVariables: {
			searchTerm,
			page: page + 1,
			perPage,
			sortKey,
			getCount: count === null,
			collections,
		},
	});

	const {
		data: collectionData,
		isLoading: isCollectionsDataLoading,
	} = useCollections({
		cacheKey: '',
		queryVariables: { query: '' },
	});
	const allCollections = React.useMemo(
		() =>
			(collectionData?.collections || []).map((collection) =>
				capitalizeFirstLetter(collection.title),
			),
		[collectionData],
	);

	React.useEffect(() => {
		if (data && count === null) {
			setCount(data.count);
		}
	}, [data, count]);

	const products = React.useMemo(
		() =>
			(data?.products || []).map((product) => ({
				...product,
				revenue: product.summary.factualRevenue,
				offersUnits: product.summary.potentialOffersUnits,
				inStockUnits: product.variants.reduce(
					(res, v) => res + v.inventory_quantity,
					0,
				),
			})),
		[data],
	);

	const columns = React.useMemo(
		() =>
			getColumns({
				formatMerchantCurrency,
				formatMerchantNumber,
				products,
				allCollections,
			}),
		[
			formatMerchantCurrency,
			formatMerchantNumber,
			products,
			allCollections,
		],
	);

	let message = 'Sorry, no Products found';
	if (error) {
		message =
			'Sorry, failed to load Products. Try to refresh the page';
	} else if (isLoading || isFetching || isCollectionsDataLoading) {
		message = 'Loading...';
	}

	const rowsExpanded = products
		.map((product, index) => [product.id, index])
		.filter(([productId]) => expandedProductsIds.includes(productId))
		.map(([, index]) => index);

	return (
		<MUIDataTable
			title="NYOP Products Summary"
			data={isLoading || isFetching ? [] : products}
			columns={columns}
			options={{
				elevation: 0,
				filter: true,
				search: true,
				print: true,
				download: true,
				downloadOptions: {
					filename: `bazo-analytics_${formatDate(
						new Date(),
						'yyyy-MM-dd',
					)}`,
				},
				onDownload: handleCsvDownload,
				serverSide: true,
				filterType: 'dropdown',
				enableNestedDataAccess: '.',
				storageKey: `nyop-products-summary.csv`,
				selectableRowsHideCheckboxes: true,
				viewColumns: false,
				textLabels: {
					body: {
						noMatch: message,
					},
				},
				responsive: 'simple',
				expandableRows: true,
				expandableRowsHeader: false,
				expandableRowsOnClick: true,
				rowsExpanded,
				isRowExpandable: (dataIndex) => {
					const variants = products[dataIndex]?.variants;
					return variants && variants.length !== 0;
				},
				renderExpandableRow: (rowData) => {
					const id = rowData.at(DataIndex.id);
					const shop = rowData.at(DataIndex.shop);
					const variants = rowData.at(DataIndex.variants);
					const imageUrl = rowData.at(DataIndex.image_url);

					return variants
						.sort((v1, v2) => (v1.title > v2.title ? 1 : -1))
						.map((variant) => (
							<TableRow key={variant.id}>
								<TableCell
									sx={{ pb: '4px', pt: '4px', pl: '2rem' }}
								/>
								<TableCell
									sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}
								>
									<TitleWithImage
										title={variant.title}
										imageUrl={variant.image_url || imageUrl}
										subtitle={variant.sku}
										deleted={!!variant.deleted_at}
										url={getProductVariantLink(shop, id, variant.id)}
									/>
								</TableCell>
								<TableCell
									sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}
								>
									-
								</TableCell>
								<TableCell
									sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}
								>
									<RevenueRenderer
										potentialRevenue={
											variant.summary.potentialRevenue
										}
										possibleRevenue={variant.summary.possibleRevenue}
										factualRevenue={variant.summary.factualRevenue}
										isFinalized
									/>
								</TableCell>
								<TableCell
									sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}
								>
									<SellthroughRenderer
										factualOffersUnits={
											variant.summary.factualOffersUnits
										}
										possibleOffersUnits={
											variant.summary.possibleOffersUnits
										}
										potentialOffersUnits={
											variant.summary.potentialOffersUnits
										}
										isFinalized
										availableUnits={variant.inventory_quantity}
									/>
								</TableCell>
								<TableCell
									sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}
								>
									<UnitsRenderer
										factualOffersUnits={
											variant.summary.factualOffersUnits
										}
										possibleOffersUnits={
											variant.summary.possibleOffersUnits
										}
										potentialOffersUnits={
											variant.summary.potentialOffersUnits
										}
										isFinalized
									/>
								</TableCell>
								<TableCell
									sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}
								>
									<Box sx={{ textAlign: 'center' }}>
										{formatMerchantNumber({
											value: variant.inventory_quantity,
										})}
									</Box>
								</TableCell>
								<TableCell
									sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}
								>
									<ExecutionProgress
										total={variant.summary.totalUnits}
										lostUnits={variant.summary.lostUnits}
										convertedUnits={variant.summary.convertedUnits}
										counteredUnits={variant.summary.counteredUnits}
									/>
								</TableCell>
								<TableCell
									sx={{ pb: '4px', pt: '4px', pl: '1.5rem' }}
								>
									<ActionsRenderer
										productId={id}
										variantId={variant.id}
									/>
								</TableCell>
							</TableRow>
						));
				},
				onRowExpansionChange: (
					currentRowsExpanded,
					allRowsExpanded,
				) => {
					let newExpandedProductsIds = expandedProductsIds;
					currentRowsExpanded.forEach((currentRow) => {
						const isExpanded = allRowsExpanded.find(
							(row) => row.dataIndex === currentRow.dataIndex,
						);

						const productId = products[currentRow.dataIndex].id;
						if (
							isExpanded &&
							!expandedProductsIds.includes(productId)
						) {
							newExpandedProductsIds.push(productId);
						} else {
							newExpandedProductsIds = newExpandedProductsIds.filter(
								(p) => p !== productId,
							);
						}
					});

					setExpandedProductsIds(newExpandedProductsIds);
				},

				onColumnSortChange: (column, direction) => {
					if (column === 'revenue') {
						setSortKey(`summary.factualRevenue,${direction}`);
					} else if (
						column === 'offersUnits' ||
						column === 'sellThrough'
					) {
						setSortKey(`summary.potentialOffersUnits,${direction}`);
					} else {
						setSortKey(`${column},${direction}`);
					}
				},
				onFilterChange: (changedColumn, filterList) => {
					if (filterList[1]) {
						setCollections(filterList[1]);
					}
				},
				// Pagination configurations
				count: data?.count ?? 20,
				page,
				rowsPerPage: perPage,
				rowsPerPageOptions: [10, 20, 50, 100],
				onChangeRowsPerPage: (value) => {
					setPerPage(value);
					setPage(0);
				},
				onChangePage: (newPage) => {
					setPage(newPage);
				},
				searchText: searchTerm,
				onSearchChange: _.debounce((newSearchTerm) => {
					setSearchTerm(newSearchTerm);
				}, 400),
				customFilterDialogFooter: TableCustomFilterDialogFooter,
			}}
		/>
	);
}

Table.propTypes = {};

export default Table;
