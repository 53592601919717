import React from 'react';
import MuiDataTable from 'mui-datatables';
import {
	formatDate,
	formatReadableDate,
} from '@app/helpers/dateHelpers';
import Loader from '@app/components/shared/loaders/CircularLoader';
import getNoTableMatch from '@app/components/shared/Table/components/NoTableMatch';
import CreateCampaignButton from './CreateCampaignButton';
import SyncVariantsButton from './SyncVariantsButton';
import { useMarketplaceCampaignContext } from '@app/hooks/useMarketplaceCampaignContext';

const DataColumnOptions = {
	viewColumns: false,
	filter: false,
	sort: false,
	download: false,
	display: 'excluded',
};

const DataIndex = {
	supplierVariants: -9,
	id: -8,
	minimum_offer: -7,
	starting_offer: -6,
	description: -5,
	automation_rules: -4,
	end_date: -3,
	start_date: -2,
	variants: -1,
};

function getDataColumns() {
	return Object.keys(DataIndex).map((name) => ({
		name,
		options: DataColumnOptions,
	}));
}

function getActionRenderer(value, data) {
	return (
		<CreateCampaignButton
			id={value}
			startingOfferInfo={{
				minimumOffer: data.rowData.at(DataIndex.minimum_offer),
				startingOffer: data.rowData.at(DataIndex.starting_offer),
			}}
			description={data.rowData.at(DataIndex.description)}
			automationRules={data.rowData.at(DataIndex.automation_rules)}
			startDate={data.rowData.at(DataIndex.start_date)}
			endDate={data.rowData.at(DataIndex.end_date)}
			variants={data.rowData.at(DataIndex.variants)}
		/>
	);
}

function getVariantsCountRenderer(value, data) {
	return (
		<SyncVariantsButton
			variants={value}
			supplierVariantsCount={
				data.rowData.at(DataIndex.supplierVariants).length
			}
			id={data.rowData.at(DataIndex.id)}
		/>
	);
}

const columns = [
	{
		name: 'description',
		label: 'Name',
		options: {
			filter: false,
		},
	},
	{
		name: 'shop',
		label: 'Shop',
		options: {
			filter: false,
		},
	},
	{
		name: 'variants',
		label: 'Variants Count',
		options: {
			customBodyRender: getVariantsCountRenderer,
			filter: false,
		},
	},
	{
		name: 'start_date',
		label: 'Start Date',
		options: {
			filter: false,
			customBodyRender: (value) => formatReadableDate(value),
		},
	},
	{
		name: 'end_date',
		label: 'End Date',
		options: {
			filter: false,
			customBodyRender: (value) => formatReadableDate(value),
		},
	},
	{
		name: 'id',
		label: 'Action',
		options: {
			customBodyRender: getActionRenderer,
			filter: false,
			sort: false,
		},
	},
	...getDataColumns(),
];

function RetailerMarketplaceCampaignsTable() {
	const { campaigns, campaignsLoading, campaignsRefetching } =
		useMarketplaceCampaignContext();

	if (campaignsLoading) {
		return <Loader fullPage />;
	}

	return (
		<MuiDataTable
			title="Marketplace Campaigns"
			data={campaigns}
			columns={columns}
			options={{
				elevation: 0,
				count: campaigns.length,
				rowsPerPage: 20,
				rowsPerPageOptions: [10, 20, 50],
				filter: true,
				filterType: 'dropdown',
				responsive: 'vertical',
				enableNestedDataAccess: '.',
				selectableRowsHideCheckboxes: true,
				viewColumns: true,
				textLabels: {
					body: {
						noMatch: getNoTableMatch({
							isLoading: campaignsRefetching,
							text: 'Sorry, no Marketplace Campaigns found',
						}),
					},
				},
				downloadOptions: {
					filename: `marketplace_campaigns_${formatDate(
						new Date(),
					)}.csv`,
				},
				tableId: 'marketplace-campaigns',
				storageKey: 'marketplace-campaigns',
				serverSide: false,
			}}
		/>
	);
}

export default RetailerMarketplaceCampaignsTable;
