import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import CustomColumnLabel from './components/customTableColumnLabel';
import CounterPopoverText from './components/counterPopoverText';

function TableHeader({ defaultThreshold, fixedCostBuffer }) {
	return (
		<TableHead>
			<TableRow>
				<TableCell />
				<CustomColumnLabel title="Title" />
				<CustomColumnLabel
					title="Compare-at Price"
					infoContent="AlgoSell prioritizes the 'Compare-at' price as
					the reference for discount thresholds to align with the
					MSRP, ensuring discounts are benchmarked against the
					manufacturer&lsquo;s suggested retail price whenever
					available"
				/>
				<CustomColumnLabel title="Price" />
				<CustomColumnLabel
					title="Discount Threshold"
					infoContent={
						<CounterPopoverText
							thresholdDiscount={defaultThreshold}
						/>
					}
				/>
				<CustomColumnLabel
					title="Target Price"
					infoContent="Set the 'Target Price' as the minimum
						acceptable offer for each product. Offers below this will
						trigger a counteroffer, while higher offers will be
						automatically accepted."
				/>
				{fixedCostBuffer ? (
					<CustomColumnLabel
						title="Final Price"
						infoContent="Final price adjusted after adding fixed cost buffer"
					/>
				) : null}
			</TableRow>
		</TableHead>
	);
}

TableHeader.propTypes = {
	defaultThreshold: PropTypes.number.isRequired,
	fixedCostBuffer: PropTypes.number,
};

TableHeader.defaultProps = {
	fixedCostBuffer: 0,
};

export default TableHeader;
