import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import Stepper from './Stepper';
import AcceptTerms from './steps/AcceptTerms';
import BazoFaq from './steps/BazoFaq';

import { updateMerchant } from '../../api/merchant';

import {
	addAdditionalScriptsDeepLink,
	createAppBlockDeepLink,
} from '../../helpers/deepLink.helper';
import constants from '../../constants';
import ProfilerProvider from '../../providers/ProfilerProvider';
import { useMakeAuxiliary } from '@app/hooks/useProducts';
import ShippingAndGiftsStep from './steps/ShippingAndGiftsStep';
import { useAuxiliaryCacheKey } from '@app/hooks/useAuxiliaryCacheKey';
import { useFetchOnboardingAuxiliaryProducts } from './hooks/useFetchOnboardingAuxiliaryProducts';
import Button from '@app/components/shared/button/Button';
import Code from '@app/components/shared/code/Code';
import { additionalBazoCode } from './constants/additionalBazoCode';

function SaasOnboarding({ merchant = {}, setMerchant }) {
	const [acceptTerms, setAcceptTerms] = React.useState(false);
	const [error, setError] = React.useState(false);
	const [protectionCheckBoxSelected, setProtectionCheckBoxSelected] =
		React.useState(false);
	const [giftCheckBoxSelected, setGiftCheckBoxSelected] =
		React.useState(false);

	const {
		selectedProtectionIds,
		setSelectedProtectionIds,
		selectedGiftsIds,
		setSelectedGiftsIds,
	} = useFetchOnboardingAuxiliaryProducts();

	const { mutate } = useMakeAuxiliary({
		cacheKey: useAuxiliaryCacheKey(),
		configOpts: {
			meta: {
				toastDisabled: true,
			},
		},
	});

	React.useEffect(() => {
		setProtectionCheckBoxSelected(
			merchant?.auxiliary_protection_step_complete,
		);
		setGiftCheckBoxSelected(
			merchant?.auxiliary_free_gift_step_complete,
		);
	}, [merchant]);

	const navigate = useNavigate();

	const completeOnboarding = React.useCallback(async () => {
		const protectionStepComplete =
			protectionCheckBoxSelected || !!selectedProtectionIds.length;
		const giftStepComplete =
			giftCheckBoxSelected || !!selectedGiftsIds.length;
		const updateFields = {
			onboarding_complete: true,
			accept_terms: true,
			auxiliary_protection_step_complete: protectionStepComplete,
			auxiliary_free_gift_step_complete: giftStepComplete,
		};
		try {
			mutate({
				productIds: selectedProtectionIds,
				auxiliaryCategory:
					constants.auxiliaryCategories.ORDER_PROTECTION,
				removeAuxiliaryFromOtherProducts: true,
			});
			mutate({
				productIds: selectedGiftsIds,
				auxiliaryCategory: constants.auxiliaryCategories.FREE_GIFT,
				removeAuxiliaryFromOtherProducts: true,
			});

			await updateMerchant({
				fields: updateFields,
			});

			// Update user merchant info, so we don't make another call to refresh all the data
			setMerchant({
				...merchant,
				...updateFields,
			});

			navigate('/onboarding/subscriptions');
		} catch (err) {
			console.error(err);
			setError(true);
		}
	}, [
		merchant,
		setMerchant,
		navigate,
		mutate,
		selectedProtectionIds,
		selectedGiftsIds,
		protectionCheckBoxSelected,
		giftCheckBoxSelected,
	]);

	const handleChange = (event) => {
		setAcceptTerms(event.target.checked);
	};

	return (
		<ProfilerProvider id="Onboarding">
			<Box>
				<Typography variant="h4" sx={{ mb: 1 }}>
					Set up for my store
				</Typography>
				<Stepper
					disabled={!acceptTerms}
					steps={[
						{
							labelText: 'Review Bazo FAQs',
							optional: false,
							description: <BazoFaq />,
						},
						{
							labelText: 'Enable Shopify Payments',
							optional: false,
							description: (
								<Box>
									<Typography>
										Ensure Shopify Payments is enabled in{' '}
										<Link
											target="_blank"
											rel="noopener noreferrer"
											href={`https://${merchant?.myshopify_domain}/admin/settings/payments`}
										>
											My Shopify Admin
										</Link>
									</Typography>
								</Box>
							),
						},
						{
							labelText: 'Enable Bazo App Block',
							optional: false,
							description: (
								<Box>
									<Typography>
										Bazo &quot;Name Your Price&quot; Button allows
										your customers to place offers on your store. It
										must be enabled for the app to function.
									</Typography>
									<ol>
										<li>
											<Typography>
												Click{' '}
												<Button
													rel="noopener noreferrer"
													href={createAppBlockDeepLink({
														uuid: import.meta.env.VITE_PREORDER_UUID,
														myshopifyDomain:
															merchant?.myshopify_domain,
														template: 'product',
														target: 'mainSection',
														handle: 'nyop-button',
													})}
													sx={{ ml: 1 }}
												>
													Add Now
												</Button>
											</Typography>
										</li>
										<li>
											<Typography>
												Configure your &quot;Name Your Price&quot;
												button to match your theme.
											</Typography>
										</li>
										<li>
											<Typography>
												Click &quot;Save&quot; in the upper right
												corner of the editor.
											</Typography>
										</li>
									</ol>
								</Box>
							),
						},
						{
							labelText: 'Add Bazo Additional Scripts',
							optional: false,
							description: (
								<Box>
									<Typography>
										Use this code to hide the payment button when
										it&apos;s not needed. This helps streamline the
										interface, reducing confusion for users who have
										already completed payment or in situations where
										payment isn&apos;t applicable.
									</Typography>
									<ol>
										<li>
											<Typography>
												Click{' '}
												<Button
													target="_blank"
													rel="noopener noreferrer"
													href={addAdditionalScriptsDeepLink({
														myshopifyDomain:
															merchant?.myshopify_domain,
													})}
													sx={{ ml: 1 }}
												>
													Add Now
												</Button>
											</Typography>
										</li>
										<li>
											<Typography>
												Paste this code to the &quot;Additional
												Scripts&quot; input
											</Typography>
											<Code>{additionalBazoCode}</Code>
										</li>
										<li>
											<Typography>
												Click &quot;Save&quot; in the upper right
												corner of the editor.
											</Typography>
										</li>
									</ol>
								</Box>
							),
						},
						// FIXME: mixedCartTemp uncomment me!
						// {
						// 	labelText: 'Enable Bazo App Embed',
						// 	optional: false,
						// 	description: (
						// 		<Box>
						// 			<Typography>
						// 				his extension is a requirement that allows you to
						// 				notify customers about mixed carts. You can
						// 				customize the alert in the theme editor.
						// 			</Typography>
						// 			<ol>
						// 				<li>
						// 					<Typography>
						// 						Click{' '}
						// 						<Button
						// 							component={Link}
						// 							variant="contained"
						// 							target="_blank"
						// 							rel="noopener noreferrer"
						// 							href={createAppEmbedDeepLink({
						// 								uuid: import.meta.env.VITE_PREORDER_UUID,
						// 								myshopifyDomain: merchant?.myshopify_domain,
						// 								template: 'cart',
						// 								handle: 'app-embed',
						// 							})}
						// 							sx={{ ml: 1 }}
						// 						>
						// 							Enable Now
						// 						</Button>
						// 					</Typography>
						// 				</li>
						// 				<li>
						// 					<Typography>
						// 						Configure the options for your Mixed Cart
						// 						notification.
						// 					</Typography>
						// 				</li>
						// 				<li>
						// 					<Typography>
						// 						Click &quot;Save&quot; in the upper right corner
						// 						of the editor to save your changes.
						// 					</Typography>
						// 				</li>
						// 			</ol>
						// 		</Box>
						// 	),
						// },
						{
							labelText: 'Identify Order And Shipping Protection',
							optional: false,
							description: (
								<ShippingAndGiftsStep
									selectedAuxiliaryIds={selectedProtectionIds}
									setSelectedAuxiliaryIds={setSelectedProtectionIds}
									checkBoxSelected={protectionCheckBoxSelected}
									setCheckBoxSelected={setProtectionCheckBoxSelected}
									title="Do you have order or shipping protection? If so, please select it below."
									subTitle="*For accurate offer handling, Bazo requires correct identification of order protection. Failure to do so may lead to offer discrepancies."
									checkBoxText="I do not have order or shipping protection in my store."
									queryKeys={
										constants.auxiliaryCategoriesWords
											.ORDER_PROTECTION
									}
								/>
							),
						},
						{
							labelText: 'Identify Free Gifts',
							optional: false,
							description: (
								<ShippingAndGiftsStep
									selectedAuxiliaryIds={selectedGiftsIds}
									setSelectedAuxiliaryIds={setSelectedGiftsIds}
									checkBoxSelected={giftCheckBoxSelected}
									setCheckBoxSelected={setGiftCheckBoxSelected}
									title="Do you have free gifts or any other products that get automatically attached to orders?  If so, please select them below."
									subTitle="*For accurate offer handling, Bazo requires correct identification of gifts and other products that are added automatically. Failure to do so may lead to offer discrepancies."
									checkBoxText="I do not have free gifts or other items that are automatically added to orders in my store."
									queryKeys={
										constants.auxiliaryCategoriesWords.FREE_GIFT
									}
								/>
							),
						},
						{
							labelText: "Agree to Bazo's policies",
							optional: false,
							description: (
								<AcceptTerms
									handleChange={handleChange}
									acceptTerms={acceptTerms}
								/>
							),
						},
					]}
					onComplete={completeOnboarding}
					reset={false}
				/>
				{error && (
					<Box sx={{ p: 1, mb: 2 }}>
						<Typography
							color="error"
							sx={{ mt: 2 }}
							textAlign="center"
						>
							Oops! There was a problem onboarding. Please refresh the
							page and try again.
						</Typography>
					</Box>
				)}
			</Box>
		</ProfilerProvider>
	);
}

SaasOnboarding.propTypes = {
	merchant: PropTypes.shape({
		onboarding_begin: PropTypes.bool,
		onboarding_complete: PropTypes.bool,
	}),
	setMerchant: PropTypes.func.isRequired,
};

export default SaasOnboarding;
