import { isNaN, isNil } from 'lodash-es';

import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import InfoBox from '@app/components/shared/ui/InfoBox';
import SelectInput from '@app/components/shared/inputs/SelectInput';
import EnableAlgoSellLabel from './components/enableAlgoSellLabel';
import constants from '@app/constants';
import ProductExample from './components/productExample';
import CampaignConfigsProductBarExample from '@app/components/campaigns/CampaignConfigsProductBarExample';
import Modal from '@app/components/shared/modal/Modal';
import campaignModalStyles from '@app/styles/campaignModalStyles';
import EditByProductModal from '@app/pages/createCampaign/Nyop/components/editByProduct';
import AutomationBulkDiscounts from '@app/pages/createCampaign/Nyop/components/AutomationBulkDiscount';
import { validateCounterValueMsrp } from '../helpers';
import StartingOfferAmount from './components/StartingOfferAmount';
import { useInventoryReviewContext } from '@app/hooks/useInventoryReviewContext';
import LockedComponent from '@app/components/shared/lockedComponent/LockedComponent';
import useAuth from '@app/hooks/useAuth';
import ConversationalInputWrapper from '@app/components/shared/inputs/ConversationalTextInput';
import FixedCostBuffer from '@app/pages/createCampaign/Nyop/components/FixedCostBuffer/index';
import { FLAGS, useFlagEnabled } from '@app/hooks/useFlags';
import { useCreateCampaignContext } from '@app/hooks/useCreateCampaignContext';

const INTERVAL_SELECTION = [
	{ label: '1', value: '1' },
	{ label: '2', value: '2' },
	{ label: '4', value: '4' },
	{ label: '6', value: '6' },
	{ label: '12', value: '12' },
	{ label: '24', value: '24' },
	{ label: '48', value: '48' },
];

const defaultMessage =
	'Set Discount Threshold and Bazo will accept all offers below it and counter offers above it automatically.';

const getInputDescriptions = ({
	acceptInterval,
	counterInterval,
} = {}) => ({
	discountThreshold:
		'Offers above the effective discount will be accepted. Offers below it will be counter offered.',
	autoAcceptInterval: `Bazo will check for offers below the Discount Threshold every ${acceptInterval} hours.`,
	autoCounterInterval: `Bazo will check for and send out counteroffers for all offers above the Discount Threshold every ${counterInterval} hours.`,
	counterOfferVariability: `When countering offer, Bazo will use this value to increase counter amount based on the original offer`,
	default: defaultMessage,
});

function AutomationBlock({
	onChange,
	onValidChange,
	defaultAutomationRules,
	areCounterValuesValid,
	setAreCounterValuesValid,
	editByProductModal,
	setEditByProductModal,
	endDate,
	startingOfferInfo,
	setStartingOfferInfo,
}) {
	const { merchant } = useAuth();
	// We should show this if it's explicitly toggled on or the marketplace_available
	// flag is toggled for our vendors so they can set this themselves
	const fixedCostBufferFeatureEnabled = useFlagEnabled(
		FLAGS.FIXED_COST_BUFFER,
	);
	const marketplaceAvailable = useFlagEnabled(
		FLAGS.MARKETPLACE_AVAILABLE,
	);
	const shouldShowFixedCostBuffer =
		fixedCostBufferFeatureEnabled || marketplaceAvailable;

	const automationAvailable =
		merchant?.active_subscription?.billing_tier?.nyop_automation;
	const [selectedVariants] = useInventoryReviewContext();

	const { createCampaignInfo } = useCreateCampaignContext();

	const [infoMessage, setInfoMessage] =
		React.useState(defaultMessage);
	const [exampleProduct, setExampleProduct] = React.useState({});

	// Will be true when creating a campaign anyway
	const defaultEnabled =
		defaultAutomationRules &&
		defaultAutomationRules?.enabled &&
		Object.keys(defaultAutomationRules).length !== 0 &&
		(automationAvailable || createCampaignInfo.isMarketplace);

	const defaultThresholdDiscount = !isNil(
		defaultAutomationRules?.thresholdDiscount,
	)
		? Math.round(+defaultAutomationRules.thresholdDiscount * 100)
		: null;

	const defaultCounterOfferVariability = !isNil(
		defaultAutomationRules?.counterOfferVariability,
	)
		? defaultAutomationRules.counterOfferVariability * 100
		: null;

	const [effectiveDiscount, setEffectiveDiscount] = React.useState(
		defaultThresholdDiscount?.toString() || '25',
	);

	const campaignDefaultCounterOfferVariability =
		createCampaignInfo.isMarketplace ? '0' : '10';
	const [counterOfferVariability, setCounterOfferVariability] =
		React.useState(
			defaultCounterOfferVariability?.toString() ||
				campaignDefaultCounterOfferVariability,
		);

	const [
		counterOfferVariabilityLimitToolTipShown,
		setCounterOfferVariabilityLimitToolTipShown,
	] = React.useState(false);

	const [processAtTheEndOfCampaign, setProcessAtTheEndOfCampaign] =
		React.useState(
			!!(
				defaultAutomationRules?.processAtTheEndOfCampaign && endDate
			),
		);

	const [autoAcceptInterval, setAutoAcceptInterval] = React.useState(
		defaultAutomationRules?.autoAcceptInterval || '1',
	);
	const [autoCounterInterval, setAutoCounterInterval] =
		React.useState(
			defaultAutomationRules?.autoCounterInterval || '1',
		);

	const [fixedCostBuffer, setFixedCostBuffer] = React.useState(
		defaultAutomationRules?.fixedCostBuffer || 0,
	);

	const [bulkDiscounts, setBulkDiscounts] = React.useState(
		defaultAutomationRules?.bulkDiscounts || null,
	);

	const [bulkDiscountsValid, setBulkDiscountsValid] =
		React.useState(true);

	const [enabled, setEnabled] = React.useState(defaultEnabled);

	const areDiscountAndVariabilityEqual =
		effectiveDiscount !== '0' &&
		counterOfferVariability === effectiveDiscount;

	React.useEffect(() => {
		onValidChange(bulkDiscountsValid);
	}, [onValidChange, bulkDiscountsValid]);

	React.useEffect(() => {
		if (!enabled) {
			setAreCounterValuesValid(true);
			return;
		}

		const isVariantsPriceValid = selectedVariants.every((variant) => {
			const variantCounterValue =
				variant.counterValue ?? variant.productCounterValue;
			const counterValue = variantCounterValue ?? effectiveDiscount;
			return validateCounterValueMsrp(
				+counterValue,
				variant.msrp,
				variant.price,
			);
		});

		setAreCounterValuesValid(isVariantsPriceValid);
	}, [
		setAreCounterValuesValid,
		effectiveDiscount,
		selectedVariants,
		enabled,
	]);

	const handleInfoChange = React.useCallback(
		(name, newValue) => {
			const updatedValues = {
				acceptInterval:
					name === 'autoAcceptInterval' && newValue
						? newValue
						: autoAcceptInterval,
				counterInterval:
					name === 'autoCounterInterval' && newValue
						? newValue
						: autoCounterInterval,
				counterOfferVariability:
					name === 'counterOfferVariability' && newValue
						? newValue
						: counterOfferVariability,
			};
			const newMessages = getInputDescriptions(updatedValues);
			setInfoMessage(newMessages[name]);
		},
		[
			counterOfferVariability,
			autoAcceptInterval,
			autoCounterInterval,
		],
	);

	React.useEffect(() => {
		const rule = { enabled };

		const globalValue = Number.parseInt(effectiveDiscount, 10);
		if (!isNaN(globalValue)) {
			rule.thresholdDiscount = globalValue / 100;
		}

		const counterOfferVariabilityNumber = Number.parseInt(
			counterOfferVariability,
			10,
		);
		if (!isNaN(counterOfferVariabilityNumber)) {
			rule.counterOfferVariability =
				counterOfferVariabilityNumber / 100;
		}

		const autoAcceptIntervalNumber = Number.parseInt(
			autoAcceptInterval,
			10,
		);

		if (
			!processAtTheEndOfCampaign &&
			!isNaN(autoAcceptIntervalNumber)
		) {
			rule.autoAcceptInterval = autoAcceptIntervalNumber;
		} else {
			rule.autoAcceptInterval = null;
		}

		const autoCounterIntervalNumber = Number.parseInt(
			autoCounterInterval,
			10,
		);

		if (
			!processAtTheEndOfCampaign &&
			!isNaN(autoCounterIntervalNumber)
		) {
			rule.autoCounterInterval = autoCounterIntervalNumber;
		} else {
			rule.autoCounterInterval = null;
		}

		rule.processAtTheEndOfCampaign = processAtTheEndOfCampaign;
		rule.bulkDiscounts = bulkDiscounts;

		if (fixedCostBuffer !== null) {
			rule.fixedCostBuffer = fixedCostBuffer;
		}

		onChange((prevRules) => ({ ...prevRules, ...rule }));
	}, [
		enabled,
		processAtTheEndOfCampaign,
		effectiveDiscount,
		counterOfferVariability,
		autoAcceptInterval,
		autoCounterInterval,
		bulkDiscounts,
		fixedCostBuffer,
		onChange,
	]);

	const handleChangeDiscountThreshold = (data) => {
		let value = Number.parseInt(data.target.value, 10);
		let counterOfferVariabilityToolTipVisibility = false;

		if (isNaN(value) || value <= 0) {
			value = 0;
		} else if (value > 100) {
			value = effectiveDiscount;
		} else if (value - counterOfferVariability < 0) {
			setCounterOfferVariability(value.toString());
			counterOfferVariabilityToolTipVisibility = true;
		}

		setCounterOfferVariabilityLimitToolTipShown(
			counterOfferVariabilityToolTipVisibility,
		);
		setEffectiveDiscount(value.toString());
	};

	const handleChangeCounterOfferVariability = (data) => {
		let value = Number.parseInt(data.target.value, 10);
		let counterOfferVariabilityToolTipVisibility = false;

		if (isNaN(value) || value <= 0) {
			value = 0;
		} else if (value > 100) {
			value = counterOfferVariability;
		} else if (effectiveDiscount - value < 0) {
			value = effectiveDiscount;
			counterOfferVariabilityToolTipVisibility = true;
		}

		setCounterOfferVariabilityLimitToolTipShown(
			counterOfferVariabilityToolTipVisibility,
		);
		setCounterOfferVariability(value.toString());
	};

	const handleChangeAutoAcceptInterval = (data) => {
		const newValue = data.target.value;
		setAutoAcceptInterval(newValue);
		handleInfoChange('autoAcceptInterval', newValue);
	};

	const handleChangeAutoCounterInterval = (data) => {
		const newValue = data.target.value;
		setAutoCounterInterval(newValue);
		handleInfoChange('autoCounterInterval', newValue);
	};

	const getSelectStyles = React.useCallback(
		(value) => ({
			width: '100%',
			// Need this to make the select component arrow behave exactly like the textfield arrows
			'& .MuiSelect-iconOutlined': {
				display: 'none',
			},
			...(enabled && {
				'&:hover, &:active, & :focus-within': {
					'.MuiSelect-iconOutlined': {
						display: 'block',
						marginRight: value === '1' ? 5 : 6,
					},
				},
			}),
		}),
		[enabled],
	);

	const effectiveDiscountHelperText = React.useMemo(() => {
		if (effectiveDiscount === '0') {
			return 'Discount Threshold cannot be 0';
		}

		// We don't want to flag anything here if it's a marketplace campaign and we are going to change the MSRP anyways
		if (!areCounterValuesValid && createCampaignInfo.isMarketplace) {
			return null;
		}
		if (!areCounterValuesValid) {
			return "Warning: Some discount thresholds are set too low, causing 'Name Your Price' offers to exceed regular prices. Please adjust the thresholds to avoid overcharging.";
		}

		return `Discounts of ${effectiveDiscount}% and below are accepted, while discounts of ${
			+effectiveDiscount + 1
		}% or more will be countered.`;
	}, [effectiveDiscount, areCounterValuesValid, createCampaignInfo]);

	const counterOfferVariabilityHelperText = React.useMemo(() => {
		if (counterOfferVariabilityLimitToolTipShown) {
			return 'Counter Offer Variability cannot be larger than Discount Threshold';
		}

		if (areDiscountAndVariabilityEqual) {
			return 'When Counter Offer Variability is equal to Discount Threshold, offers may be countered to the full price of the product';
		}

		return `When countering shoppers, Bazo’s AI will issue counter offers between ${
			parseFloat(effectiveDiscount) -
			parseFloat(counterOfferVariability)
		}% - ${effectiveDiscount}% discount.`;
	}, [
		effectiveDiscount,
		counterOfferVariability,
		counterOfferVariabilityLimitToolTipShown,
		areDiscountAndVariabilityEqual,
	]);

	return (
		<LockedComponent
			sx={{
				mt: 1.5,
				ml: -3,
				width: 'calc(100% + 50px)',
				height: 'calc(100% - 11.5px)',
			}}
			open={!automationAvailable && !createCampaignInfo.isMarketplace}
			description={
				<>
					<Typography fontWeight="500" fontSize="20px">
						To turn on AlgoSell you need to upgrade your subscription
					</Typography>
					<Typography fontWeight="500" fontSize="20px">
						Upgrade now to set it and forget it.
					</Typography>
				</>
			}
			buttonText="Upgrade Now"
			href="/settings/subscriptions"
			modalInfoSx={{ top: '35%' }}
		>
			<Box
				sx={{
					marginTop: 2,
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				{!createCampaignInfo.isMarketplace ? (
					<FormGroup sx={{ mb: 1, height: '100%' }}>
						<FormControlLabel
							control={
								<Switch
									checked={enabled}
									onChange={(event) =>
										setEnabled(event.target.checked)
									}
								/>
							}
							label={<EnableAlgoSellLabel />}
						/>
					</FormGroup>
				) : null}
				<InfoBox
					type={infoMessage === defaultMessage ? 'info' : 'success'}
					onClick={() => handleInfoChange('default')}
				>
					<Typography>{infoMessage}</Typography>
				</InfoBox>

				<CampaignConfigsProductBarExample
					productPrice={
						exampleProduct?.price
							? parseFloat(exampleProduct.price)
							: 100
					}
					productMSRP={
						// eslint-disable-next-line no-nested-ternary
						exampleProduct?.msrp
							? parseFloat(exampleProduct?.msrp)
							: exampleProduct?.price
							? parseFloat(exampleProduct.price)
							: 100
					}
					counterOfferThreshold={parseInt(
						exampleProduct.counterValue || effectiveDiscount,
						10,
					)}
					counterOfferVariability={parseInt(
						counterOfferVariability,
						10,
					)}
				/>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						gap: 2.7,
					}}
				>
					<Box
						sx={{
							width: '60%',
							display: 'flex',
							flexDirection: 'column',
							gap: 1,
						}}
					>
						<Grid>
							<ConversationalInputWrapper prompt="Accept all offers better than:">
								<TextField
									name="discountThreshold"
									type="number"
									disabled={!enabled}
									value={effectiveDiscount}
									onChange={handleChangeDiscountThreshold}
									onFocus={() =>
										handleInfoChange('discountThreshold')
									}
									sx={{ width: '100%' }}
									error={
										effectiveDiscount === '0' ||
										(!areCounterValuesValid &&
											!createCampaignInfo.isMarketplace)
									}
									helperText={effectiveDiscountHelperText}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												% discount
											</InputAdornment>
										),
									}}
								/>
							</ConversationalInputWrapper>
						</Grid>
						{!createCampaignInfo.isMarketplace && (
							<Grid>
								<ConversationalInputWrapper prompt="Define a range for Counteroffers.">
									<TextField
										name="counterOfferVariability"
										type="number"
										disabled={!enabled}
										value={counterOfferVariability}
										onChange={handleChangeCounterOfferVariability}
										onFocus={() =>
											handleInfoChange('counterOfferVariability')
										}
										error={
											counterOfferVariabilityLimitToolTipShown ||
											areDiscountAndVariabilityEqual
										}
										helperText={counterOfferVariabilityHelperText}
										sx={{ width: '100%' }}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<AddIcon />
												</InputAdornment>
											),
											endAdornment: (
												<InputAdornment position="end">
													%
												</InputAdornment>
											),
										}}
									/>
								</ConversationalInputWrapper>
							</Grid>
						)}
						{!createCampaignInfo.isMarketplace && (
							<Grid>
								<FormGroup sx={{ mb: 1, height: '100%' }}>
									<ConversationalInputWrapper
										prompt="Process all offers at the end of the campaign?"
										helperText="Wait until the end of you campaign to process any offers."
									>
										<FormControlLabel
											control={
												<Switch
													disabled={!endDate || !enabled}
													checked={processAtTheEndOfCampaign}
													onChange={(event) =>
														setProcessAtTheEndOfCampaign(
															event.target.checked,
														)
													}
												/>
											}
											label="Process offers at the end"
										/>
									</ConversationalInputWrapper>
								</FormGroup>
							</Grid>
						)}
						{!createCampaignInfo.isMarketplace && (
							<Grid>
								<ConversationalInputWrapper
									prompt="Inform shoppers of accepted offers in:"
									helperText={`Notify shoppers ${autoAcceptInterval} hour(s) after checkout that their offers have been accepted`}
								>
									<SelectInput
										name="autoAcceptInterval"
										value={autoAcceptInterval}
										disabled={!enabled || processAtTheEndOfCampaign}
										handleChange={handleChangeAutoAcceptInterval}
										onOpen={() =>
											handleInfoChange('autoAcceptInterval')
										}
										options={INTERVAL_SELECTION}
										sx={getSelectStyles(autoAcceptInterval)}
										endAdornment={
											<InputAdornment position="end">
												hour{autoAcceptInterval !== '1' && 's'}
											</InputAdornment>
										}
										size="large"
									/>
								</ConversationalInputWrapper>
							</Grid>
						)}
						{!createCampaignInfo.isMarketplace && (
							<Grid>
								<ConversationalInputWrapper
									prompt="Inform shoppers of countered offers in:"
									helperText={`Notify shoppers ${autoCounterInterval} hour(s) after checkout that their offers have been countered. Slightly delaying this notification can encourage shoppers to submit better initial offers.`}
								>
									<SelectInput
										name="autoCounterInterval"
										value={autoCounterInterval}
										disabled={!enabled || processAtTheEndOfCampaign}
										handleChange={handleChangeAutoCounterInterval}
										onOpen={() =>
											handleInfoChange('autoCounterInterval')
										}
										options={INTERVAL_SELECTION}
										sx={getSelectStyles(autoCounterInterval)}
										endAdornment={
											<InputAdornment position="end">
												hour{autoCounterInterval !== '1' && 's'}
											</InputAdornment>
										}
										size="large"
									/>
								</ConversationalInputWrapper>
							</Grid>
						)}
						{!createCampaignInfo.isMarketplace && (
							<StartingOfferAmount
								disabled={!enabled}
								startingOfferInfo={startingOfferInfo}
								onChange={setStartingOfferInfo}
							/>
						)}
						{shouldShowFixedCostBuffer ? (
							<FixedCostBuffer
								disabled={!enabled}
								defaultValue={fixedCostBuffer}
								onChange={setFixedCostBuffer}
								automationRules={defaultAutomationRules}
							/>
						) : null}

						{!createCampaignInfo.isMarketplace && (
							<AutomationBulkDiscounts
								disabled={!enabled}
								onChange={setBulkDiscounts}
								onValidChange={setBulkDiscountsValid}
								onBulkDiscountValidChange={setBulkDiscountsValid}
								maxDiscount={
									90 - Number.parseInt(effectiveDiscount, 10)
								} // max discount with bulk options will be 90%
								defaultBulkDiscounts={
									defaultAutomationRules?.bulkDiscounts
								}
							/>
						)}
					</Box>
					<Divider
						sx={{
							minHeight: '100%',
							borderLeft: `1px solid ${constants.colors.disabled}`,
							mt: 3,
						}}
					/>
					<Box
						sx={{
							width: '40%',
							minHeight: '100%',
							display: 'flex',
							flexDirection: 'column',
							gap: 1,
							mt: 3,
							color: !enabled ? constants.colors.disabled : '',
						}}
					>
						<ProductExample
							enabled={enabled}
							selectedVariants={selectedVariants}
							effectiveDiscount={effectiveDiscount}
							counterOfferVariability={counterOfferVariability}
							onExampleProductChanged={setExampleProduct}
						/>
						<Box sx={{ mt: 2 }}>
							<Modal
								title="Edit by product"
								buttonText="Edit by product"
								aria={{
									describedby: 'edit-by-variants-modal-title',
									labelledby: 'edit-by-variants-modal-title',
								}}
								buttonDisabled={!enabled}
								handleModal={setEditByProductModal}
								open={editByProductModal}
								buttonSx={{
									border: `1px solid ${
										enabled
											? constants.colors.primaryBorder
											: constants.colors.disabled
									}`,
								}}
								contentStyle={{
									...campaignModalStyles,
									height: '100%',
								}}
							>
								<EditByProductModal
									onClose={() => setEditByProductModal(false)}
									defaultThresholdDiscount={effectiveDiscount}
									areCounterValuesValid={areCounterValuesValid}
									fixedCostBuffer={fixedCostBuffer}
								/>
							</Modal>
						</Box>
					</Box>
				</Box>
			</Box>
		</LockedComponent>
	);
}

AutomationBlock.propTypes = {
	onChange: PropTypes.func.isRequired,
	onValidChange: PropTypes.func.isRequired,
	defaultAutomationRules: PropTypes.shape({
		thresholdDiscount: PropTypes.number,
		counterOfferVariability: PropTypes.number,
		autoAcceptInterval: PropTypes.number,
		autoCounterInterval: PropTypes.number,
		processAtTheEndOfCampaign: PropTypes.bool,
		fixedCostBuffer: PropTypes.number,
		bulkDiscounts: PropTypes.arrayOf(
			PropTypes.shape({
				quantity: PropTypes.number.isRequired,
				extraDiscount: PropTypes.number.isRequired,
			}),
		),
		enabled: PropTypes.bool,
	}),
	areCounterValuesValid: PropTypes.bool.isRequired,
	setAreCounterValuesValid: PropTypes.func.isRequired,
	editByProductModal: PropTypes.bool.isRequired,
	setEditByProductModal: PropTypes.func.isRequired,
	endDate: PropTypes.instanceOf(Date),
	startingOfferInfo: PropTypes.shape({
		minimumOffer: PropTypes.string,
		startingOffer: PropTypes.string,
	}).isRequired,
	setStartingOfferInfo: PropTypes.func.isRequired,
};

AutomationBlock.defaultProps = {
	defaultAutomationRules: null,
	endDate: null,
};

export default AutomationBlock;
