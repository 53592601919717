import React from 'react';

import Box from '@mui/material/Box';

import AuxiliaryProducts from '@app/pages/settings/appConfigs/auxiliaryProducts';
import ProductSyncSetting from '@app/pages/settings/appConfigs/ProductSync/index';

function AppConfigurations() {
	return (
		<Box
			sx={{
				pl: 2,
				pr: 2,
				display: 'flex',
				flexDirection: 'column',
				gap: 3,
			}}
		>
			<ProductSyncSetting />
			<AuxiliaryProducts />
		</Box>
	);
}

export default AppConfigurations;
