import PropTypes from 'prop-types';

import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { useNyopCampaignClose } from '@app/hooks/useNyopCampaign';
import Button from '@app/components/shared/button/Button';

function CloseAction({ campaignId, onSuccess, searchParams }) {
	const {
		mutate,
		error,
		isSuccess,
		isLoading: loading,
	} = useNyopCampaignClose({
		id: campaignId,
		searchParams,
	});

	React.useEffect(() => {
		if (isSuccess) {
			onSuccess();
		}
	}, [onSuccess, isSuccess]);

	const handleSubmit = () => mutate({ id: campaignId });

	return (
		<Box>
			<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
				Are you sure you want to close the campaign?
			</Typography>
			<List>
				<ListItemText
					primary="You won't be able to edit it."
					sx={{
						padding: 0,
						marginLeft: '20px',
						listStyleType: 'disc',
						display: 'list-item',
					}}
				/>
				<ListItemText
					primary="It won't be showing in the list of active campaigns."
					sx={{
						padding: 0,
						marginLeft: '20px',
						listStyleType: 'disc',
						display: 'list-item',
					}}
				/>
			</List>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
			>
				<Button
					type="submit"
					loading={loading}
					onClick={handleSubmit}
				>
					CLOSE
				</Button>

				{error && (
					<Typography color="error">{error.message}</Typography>
				)}
			</Box>
		</Box>
	);
}

CloseAction.propTypes = {
	campaignId: PropTypes.string.isRequired,
	onSuccess: PropTypes.func.isRequired,
	searchParams: PropTypes.shape({
		page: PropTypes.number,
		search: PropTypes.string,
		sort: PropTypes.arrayOf(PropTypes.string),
		state: PropTypes.arrayOf(PropTypes.string),
	}),
};

CloseAction.defaultProps = { searchParams: {} };

export default CloseAction;
