import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import constants from '@app/constants/index';
import useAuth from '@app/hooks/useAuth';

function MoneyRenderer({
	shopCurrencyAmount,
	customerCurrencyAmount,
	customerCurrency,
	shopCurrency,
}) {
	const { formatMerchantCurrency } = useAuth();

	if (customerCurrency === shopCurrency) {
		return (
			<Typography color={constants.colors.pending}>
				{formatMerchantCurrency(shopCurrencyAmount)}
			</Typography>
		);
	}
	return (
		<Typography color={constants.colors.pending}>
			{formatMerchantCurrency(shopCurrencyAmount, 1, shopCurrency)} (
			{formatMerchantCurrency(
				customerCurrencyAmount,
				1,
				customerCurrency,
			)}
			)
		</Typography>
	);
}

MoneyRenderer.propTypes = {
	shopCurrencyAmount: PropTypes.number.isRequired,
	customerCurrencyAmount: PropTypes.number.isRequired,
	customerCurrency: PropTypes.string.isRequired,
	shopCurrency: PropTypes.string.isRequired,
};

export default MoneyRenderer;
