import React from 'react';
import useAuth from '@app/hooks/useAuth';
import { useUpdateNyopCounterOfferItemSku } from '@app/hooks/useMerchant';
import SettingContainer from '@app/pages/settings/components/settingContainer/SettingContainer';

function CounterOfferItemSku() {
	const { merchant } = useAuth();

	const [error, setError] = React.useState(null);
	const [counterOfferItemSKU, setCounterOfferItemSku] =
		React.useState(merchant.nyop_counter_offer_sku);

	const { mutate, isLoading } = useUpdateNyopCounterOfferItemSku({});

	const onChange = (event) => {
		const { value } = event.target;

		setCounterOfferItemSku(value || null);
	};

	React.useEffect(() => {
		if (!/^[a-z0-9\-_]{1,120}$/i.test(counterOfferItemSKU)) {
			setError(
				'Invalid SKU format. Please use only alphanumeric characters, dashes (-), and underscores (_). Max length is 120 characters',
			);
		} else {
			setError(null);
		}
	}, [counterOfferItemSKU]);

	return (
		<SettingContainer
			title="SKU for Counter-offers"
			subtitle="Do you need to set a unique SKU for counter offer line item? If so, enter it below."
			helperText="Accepting a counter offer adds a custom line item to capture the adjusted order value. 
			Assign an optional SKU to this item for specific ERP system rules or exceptions. 
			If left blank, this line item will not have an attached SKU."
			value={counterOfferItemSKU || ''}
			onChangeValue={onChange}
			inputName="counterOfferSku"
			inputLabel="Counter Offer Item SKU"
			onClick={() => mutate({ value: counterOfferItemSKU })}
			isLoading={isLoading}
			errors={error ? [error] : []}
		/>
	);
}

export default CounterOfferItemSku;
