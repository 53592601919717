import { useLocation } from 'react-router-dom';

import * as googleAnalytics from '../lib/googleAnalytics';

function useTrackLoad() {
	const location = useLocation();

	const observer = new PerformanceObserver((list) => {
		list.getEntries().forEach((entry) => {
			googleAnalytics.GAtiming({
				categoryName: `Load performance for ${location.pathname}`,
				variableName: 'Server latency',
				valueNum: entry.responseStart - entry.requestStart,
			});
			googleAnalytics.GAtiming({
				categoryName: `Load performance for ${location.pathname}`,
				variableName: 'Download time',
				valueNum: entry.responseEnd - entry.responseStart,
			});
			googleAnalytics.GAtiming({
				categoryName: `Load performance for ${location.pathname}`,
				variableName: 'Total load time',
				valueNum: entry.responseEnd - entry.requestStart,
			});
		});
	});

	observer.observe({ type: 'navigation', buffered: true });
}

export default useTrackLoad;
