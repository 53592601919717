import * as React from 'react';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import {
	useCreateIntegration,
	useUpdateIntegration,
} from '@app/hooks/useMerchant';
import { getIntegration } from '@app/api/merchant';
import Button from '@app/components/shared/button/Button';

const INTEGRATION = 'klaviyo';
function KlaviyoSettings({ defaultSettings }) {
	const isUpdate = !!defaultSettings;
	const [defSettingsCopy, setDefSettingsCopy] =
		React.useState(defaultSettings);
	const [settings, setSettings] = React.useState({});
	const [apiKeyEditable, setApiKeyEditable] = React.useState(false);

	const { mutate: create } = useCreateIntegration();
	const { mutate: update } = useUpdateIntegration();

	const handleApiKeyChange = (event) => {
		setSettings({ ...settings, api_key: event.target.value });
	};

	const makeApiKeyEditable = async () => {
		const response = await getIntegration(INTEGRATION);
		const integration = response?.data;
		if (integration) {
			setDefSettingsCopy(integration.settings);
		}
		setApiKeyEditable(true);
	};

	const handleSubmit = async () => {
		if (isUpdate) {
			update({ integration: INTEGRATION, settings });
		} else {
			create({ integration: INTEGRATION, settings });
		}
	};

	return (
		<Box sx={{ mb: 1, width: { xs: '100%', md: '50%' } }}>
			<Box sx={{ display: 'flex' }}>
				{isUpdate && !apiKeyEditable && (
					<IconButton onClick={makeApiKeyEditable}>
						<EditIcon />
					</IconButton>
				)}

				<TextField
					fullWidth
					id="pre-order-name"
					label="API Key"
					name="api_key"
					value={settings.api_key || defSettingsCopy?.api_key}
					onChange={handleApiKeyChange}
					disabled={isUpdate && !apiKeyEditable}
				/>
			</Box>

			<Button
				onClick={handleSubmit}
				disabled={Object.keys(settings).length === 0}
			>
				Save
			</Button>
		</Box>
	);
}

KlaviyoSettings.propTypes = {
	defaultSettings: PropTypes.shape({ api_key: PropTypes.string }),
};

KlaviyoSettings.defaultProps = {
	defaultSettings: null,
};

export default KlaviyoSettings;
