import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import Box from '@mui/material/Box';
import OffersTable from '@app/components/muiTable/offers/index';
import Summary from './Summary';
import Loader from '@app/components/shared/loaders/CircularLoader';
import useAuth from '@app/hooks/useAuth';
import { useNyopCampaignVariant } from '@app/hooks/useNyopCampaign';
import NYOPVariantSelector from '@app/components/campaigns/NYOPVariantSelector';

function NYOPVariantDetails() {
	const { formatMerchantCurrency } = useAuth();
	const navigate = useNavigate();
	const { id, productId, variantId } = useParams();

	const { data, isLoading, isFetching } = useNyopCampaignVariant({
		id,
		variantId,
	});

	const handleVariantChange = (event) => {
		const { value } = event.target;

		const url = `/campaigns/nyop/${id}/products/${productId}`;
		if (value) {
			navigate(`${url}/variants/${encodeURIComponent(value)}`);
		} else {
			navigate(url);
		}
	};

	if (isLoading) return <Loader fullPage />;

	const { offers, variant, campaign } = data.data;

	return (
		<>
			<Box
				mb={2}
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<NYOPVariantSelector
					campaignId={id}
					onSelect={handleVariantChange}
				/>
			</Box>
			<Grid container rowSpacing={2}>
				<Grid item md={12}>
					<Summary
						variant={variant}
						offers={offers}
						formatMerchantCurrency={formatMerchantCurrency}
						campaign={campaign}
					/>
				</Grid>
				<Grid item md={12}>
					<OffersTable
						campaign={campaign}
						parentRef={variant.id}
						variant={variant}
						offers={offers}
						isFetching={isFetching}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default NYOPVariantDetails;
