import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import InfoBox from '@app/components/shared/ui/InfoBox';

function CounterValueWarning({ sx }) {
	return (
		<InfoBox type="warning" sx={sx}>
			<Typography>
				Warning: Some discount thresholds are set too low, causing
				&#39;Name Your Price&#39; offers to exceed regular prices.
				Please ensure that you have the correct discount applied.
			</Typography>
		</InfoBox>
	);
}

CounterValueWarning.propTypes = {
	sx: PropTypes.instanceOf(Object),
};

CounterValueWarning.defaultProps = {
	sx: {},
};

export default CounterValueWarning;
