import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import * as nyopApi from '../api/nyopCampaign';
import { getGqlId } from '../lib/shopify';
import constants from '../constants/index';
import useAuth from '@app/hooks/useAuth';

export const CACHE_KEY = 'nyopCampaigns';

// TODO: Need to pass in all search keys to optimize instead of this wildcard invalidation
// TODO: Create context with search params and pass into all methods that use [CACHE_KEY, 'list...
function invalidateListCache({ queryKey }) {
	const isListQuery =
		queryKey[0] === CACHE_KEY && queryKey[1] === 'list';
	return isListQuery;
}

function getListCacheKeyFromSearchParams({
	page,
	state,
	search,
	sort,
}) {
	return [CACHE_KEY, 'list', page, state, search, sort];
}

export function useNyopCampaigns({
	page,
	state,
	search,
	sort,
	configOpts = {},
}) {
	return useQuery(
		getListCacheKeyFromSearchParams({ page, state, search, sort }),
		async () => nyopApi.get({ state, page, search, sort }),
		{ ...configOpts },
	);
}

export function useNyopCampaign({ id, configOpts = {} }) {
	// const queryClient = useQueryClient();

	const shopifyId = getGqlId(
		id,
		constants.shopifyObjectPrefix.SELLING_PLAN_GROUP,
	);

	return useQuery(
		[CACHE_KEY, shopifyId],
		async () => nyopApi.getById({ id: shopifyId }),
		{
			/**
			 * TODO: Need to add search params bc this query will be undefined from what's active on the NYOP campaign table
			 * * Refactor to use search params from context and
			 * * Move search params context higher in the component tree to provide for edit pages
			 * but not really worth it to save a call to DB from cache
			 */
			// initialData: () => {
			// 	const data = queryClient
			// 		.getQueryData([CACHE_KEY, 'list'])
			// 		?.data?.find((spg) => spg?.id === id);
			// 	if (!data || data?.variants) return undefined;
			// 	return { data };
			// },
			...configOpts,
		},
	);
}

export function useNyopCampaignCreate({ configOpts = {} } = {}) {
	const queryClient = useQueryClient();

	return useMutation(nyopApi.create, {
		onSettled: () =>
			// Need to invalidate all so nothing is stale
			queryClient.invalidateQueries(invalidateListCache, {
				exact: false,
			}),
		...configOpts,
	});
}

export function useNyopCampaignUpdate({ configOpts = {} } = {}) {
	const queryClient = useQueryClient();
	return useMutation(nyopApi.update, {
		onSettled: () => queryClient.invalidateQueries([CACHE_KEY]),
		...configOpts,
	});
}

export function useNyopCampaignDelete({ id }) {
	const queryClient = useQueryClient();

	// onSettled used to invalidate just cache key for that ID, but it
	// doesn't reload the list in table so instead invalidate everything
	return useMutation(nyopApi.del, {
		// TODO: Pass in pagination search params to optimize invalidation
		onSuccess: () => {
			// queryClient.setQueryData([CACHE_KEY, 'list'], (data = {}) => ({
			// 	...data,
			// 	data: data.data.filter((r) => r.id !== id),
			// }));
			// Need to invalidate all so that paginated queries are not stale
			queryClient.invalidateQueries(invalidateListCache, {
				exact: false,
			});
			queryClient.invalidateQueries([CACHE_KEY, id]);
		},
	});
}

// Need pagination queries here bc we are lazy updating cache and need to change what is on the users screen right now
export function useNyopCampaignEnd({ id, searchParams = {} }) {
	const queryClient = useQueryClient();

	return useMutation(nyopApi.end, {
		onSuccess: (responseData) => {
			queryClient.setQueryData(
				getListCacheKeyFromSearchParams(searchParams),
				(data) => {
					if (!data) {
						return data;
					}
					const newData = data.data.map((campaign) => {
						if (campaign.id === id) {
							return {
								...campaign,
								end_date: responseData.data.end_date,
								remaining_balance_charge_date:
									responseData.data.remaining_balance_charge_date,
							};
						}
						return campaign;
					});

					return { ...data, data: newData };
				},
			);
			queryClient.invalidateQueries([CACHE_KEY, id]);
		},
	});
}

export function useNyopCampaignFinalize({ id, configOpts = {} }) {
	const queryClient = useQueryClient();

	return useMutation(nyopApi.finalize, {
		onSettled: () => {
			queryClient.invalidateQueries([CACHE_KEY, id]);
			queryClient.setQueryData([CACHE_KEY, id], (data) => ({
				...data,
				campaign: {
					...data?.campaign,
					processing_started_at: new Date(),
				},
			}));
		},
		...configOpts,
	});
}

export function useNyopCampaignClose({ id, searchParams = {} }) {
	const queryClient = useQueryClient();

	return useMutation(nyopApi.close, {
		onSuccess: () => {
			queryClient.setQueryData(
				getListCacheKeyFromSearchParams(searchParams),
				(data) => {
					if (!data) {
						return data;
					}
					return {
						...data,
						data: data.data.filter((campaign) => campaign.id !== id),
					};
				},
			);
			queryClient.invalidateQueries([CACHE_KEY, id]);
		},
	});
}

export function useNyopProduct({ productId, configOpts }) {
	const queryClient = useQueryClient();

	const shopifyProductId = getGqlId(
		productId,
		constants.shopifyObjectPrefix.PRODUCT,
	);

	return useQuery(
		[CACHE_KEY, 'products', productId],
		async () =>
			nyopApi.getProduct({
				productId: shopifyProductId,
			}),
		{
			initialData: () => {
				const data = queryClient
					.getQueryData([CACHE_KEY, 'products'])
					?.data?.products?.find(
						(product) => product?.id === productId,
					);
				if (!data || data?.products) return undefined;
				return { data };
			},
			...configOpts,
		},
	);
}

export function useNyopCampaignProduct({
	id,
	productId,
	configOpts,
}) {
	const queryClient = useQueryClient();

	const shopifyId = getGqlId(
		id,
		constants.shopifyObjectPrefix.SELLING_PLAN_GROUP,
	);

	const shopifyProductId = getGqlId(
		productId,
		constants.shopifyObjectPrefix.PRODUCT,
	);

	return useQuery(
		[CACHE_KEY, shopifyId, 'products', productId],
		async () =>
			nyopApi.getCampaignProductById({
				id: shopifyId,
				productId: shopifyProductId,
			}),
		{
			initialData: () => {
				const data = queryClient
					.getQueryData([CACHE_KEY, shopifyId, 'products'])
					?.data?.products?.find((product) => product?.id === id);
				if (!data || data?.products) return undefined;
				return { data };
			},
			...configOpts,
		},
	);
}

export function useNyopVariant({ variantId, configOpts }) {
	const queryClient = useQueryClient();

	const shopifyVariantId = getGqlId(
		variantId,
		constants.shopifyObjectPrefix.PRODUCT_VARIANT,
	);

	return useQuery(
		[CACHE_KEY, 'variants', variantId],
		async () =>
			nyopApi.getVariant({
				variantId: shopifyVariantId,
			}),
		{
			initialData: () => {
				const data = queryClient
					.getQueryData([CACHE_KEY, 'variants'])
					?.data?.variants?.find(
						(variant) => variant?.id === variantId,
					);
				if (!data || data?.variants) return undefined;
				return { data };
			},
			...configOpts,
		},
	);
}

export function useNyopCampaignVariant({
	id,
	variantId,
	configOpts,
}) {
	const queryClient = useQueryClient();

	const shopifyId = getGqlId(
		id,
		constants.shopifyObjectPrefix.SELLING_PLAN_GROUP,
	);

	const shopifyVariantId = getGqlId(
		variantId,
		constants.shopifyObjectPrefix.PRODUCT_VARIANT,
	);

	return useQuery(
		[CACHE_KEY, shopifyId, 'variants', variantId],
		async () =>
			nyopApi.getCampaignVariantById({
				id: shopifyId,
				variantId: shopifyVariantId,
			}),
		{
			initialData: () => {
				const data = queryClient
					.getQueryData([CACHE_KEY, shopifyId, 'variants'])
					?.data?.variants?.find((variant) => variant?.id === id);
				if (!data || data?.variants) return undefined;
				return { data };
			},
			...configOpts,
		},
	);
}

export function useNyopVariants({ id, configOpts }) {
	const queryClient = useQueryClient();

	const shopifyId = getGqlId(
		id,
		constants.shopifyObjectPrefix.SELLING_PLAN_GROUP,
	);

	return useQuery(
		[CACHE_KEY, shopifyId, 'variants'],
		async () =>
			nyopApi.getCampaignVariants({
				id: shopifyId,
			}),
		{
			initialData: () => {
				const data = queryClient.getQueryData([
					CACHE_KEY,
					shopifyId,
					'variants',
				]);
				if (!data || data?.variants) return undefined;
				return { data };
			},
			...configOpts,
		},
	);
}

export function useNYOPProducts({ queryVariables, configOpts = {} }) {
	const { merchant } = useAuth();

	const cacheKey = `${merchant.id}|${queryVariables.searchTerm}|${queryVariables.page}|${queryVariables.perPage}|${queryVariables.sortKey}|${queryVariables.collections}`;
	return useQuery({
		queryKey: [CACHE_KEY, 'products', cacheKey],
		queryFn: () => nyopApi.getNYOPProducts(queryVariables),
		keepPreviousData: true,
		enabled: !!merchant,
		...configOpts,
	});
}

export function useCampaignProducts({
	campaignId,
	queryVariables,
	configOpts = {},
}) {
	const { merchant } = useAuth();

	const cacheKey = `${merchant.id}|${queryVariables.searchTerm}|${queryVariables.page}|${queryVariables.perPage}|${queryVariables.sortKey}|${queryVariables.collections}`;
	return useQuery({
		queryKey: [CACHE_KEY, campaignId, 'products', cacheKey],
		queryFn: () =>
			nyopApi.getCampaignProducts({ campaignId, ...queryVariables }),
		keepPreviousData: true,
		enabled: !!merchant,
		...configOpts,
	});
}

export function useNyopProgress({ id, configOpts = {} }) {
	const shopifyId = getGqlId(
		id,
		constants.shopifyObjectPrefix.SELLING_PLAN_GROUP,
	);

	return useQuery(
		[CACHE_KEY, shopifyId, 'progress'],
		async () => nyopApi.getFinalizationProgress({ id: shopifyId }),
		{
			...configOpts,
		},
	);
}

export function useNyopCampaignRecreate({ configOpts = {} } = {}) {
	const queryClient = useQueryClient();

	return useMutation(nyopApi.recreate, {
		onSettled: () =>
			// Need to invalidate all so nothing is stale
			queryClient.invalidateQueries(invalidateListCache, {
				exact: false,
			}),
		...configOpts,
	});
}
