import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import PropTypes from 'prop-types';
import useAuth from '@app/hooks/useAuth';

function FlagsProvider({ children }) {
	const { merchant } = useAuth();
	return (
		<FlagsmithProvider
			flagsmith={flagsmith}
			options={{
				environmentID: import.meta.env.VITE_FLAGSMITH_CLIENT_KEY,
				api: import.meta.env.VITE_FLAGSMITH_HOST,
				identity:
					merchant?.myshopify_domain ||
					'unauthorized_merchant_dashboard_user',
			}}
		>
			{children}
		</FlagsmithProvider>
	);
}

FlagsProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]).isRequired,
};

export default FlagsProvider;
