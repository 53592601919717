import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useAuth from '@app/hooks/useAuth';
import ProductExampleContent from './productExampleContent';
import ProductSelector from './productSelector';
import ProductImage from './productImage';

const titleAndPriceStyles = {
	fontSize: 20,
	fontWeight: 500,
};

const exampleProduct = {
	full_title: 'Example T-Shirt',
	price: '100',
	msrp: '100',
	isDefault: true,
};

function ProductExample({
	enabled,
	selectedVariants,
	effectiveDiscount,
	counterOfferVariability,
	onExampleProductChanged,
}) {
	const { formatMerchantCurrency } = useAuth();

	const variantWithLargestEffectiveDiscount = selectedVariants.sort(
		(v1, v2) => (v1.price / v1.msrp > v2.price / v2.msrp ? 1 : -1),
	)[0];

	const selectorOptions = React.useMemo(() => {
		const options = selectedVariants.map(
			(variant) => variant.full_title,
		);
		const sortedOptions = options.sort((a, b) => a.localeCompare(b));
		return [exampleProduct.full_title, ...sortedOptions];
	}, [selectedVariants]);

	const defaultProduct = React.useMemo(
		() => selectedVariants[0] || exampleProduct,
		[selectedVariants],
	);

	const [currentProduct, setCurrentProduct] = React.useState(
		variantWithLargestEffectiveDiscount &&
			variantWithLargestEffectiveDiscount.price /
				variantWithLargestEffectiveDiscount.msrp <
				1
			? variantWithLargestEffectiveDiscount
			: defaultProduct,
	);

	React.useEffect(() => {
		onExampleProductChanged(currentProduct);
	}, [currentProduct, onExampleProductChanged]);

	const handleCurrentProductChange = React.useCallback(
		(title) => {
			const product = selectedVariants.find(
				(variant) => variant.full_title === title,
			);
			if (product) {
				if (product.msrp === null) {
					product.msrp = product.price;
				}
				setCurrentProduct(product);
				return;
			}
			setCurrentProduct(exampleProduct);
		},
		[selectedVariants],
	);

	React.useEffect(() => {
		setCurrentProduct((product) => {
			const newProduct = selectedVariants.find(
				(variant) => variant.id === product.id,
			);
			if (newProduct) {
				if (newProduct.msrp === null) {
					newProduct.msrp = newProduct.price;
				}
				return newProduct;
			}
			return exampleProduct;
		});
	}, [selectedVariants]);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Typography
				sx={{
					fontWeight: 700,
				}}
			>
				How it works
			</Typography>
			<ProductSelector
				options={selectorOptions}
				currentProduct={currentProduct}
				setCurrentProduct={handleCurrentProductChange}
				enabled={enabled}
			/>
			<ProductImage
				currentProduct={currentProduct}
				enabled={enabled}
			/>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					gap: 1,
				}}
			>
				<Typography
					sx={{
						...titleAndPriceStyles,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					}}
				>
					{currentProduct.full_title}
				</Typography>
				<Typography sx={titleAndPriceStyles}>
					{formatMerchantCurrency(Math.round(+currentProduct.msrp))}
				</Typography>
			</Box>
			<ProductExampleContent
				enabled={enabled}
				currentProduct={currentProduct}
				effectiveDiscount={effectiveDiscount}
				counterOfferVariability={counterOfferVariability}
			/>
		</Box>
	);
}

ProductExample.propTypes = {
	enabled: PropTypes.bool.isRequired,
	selectedVariants: PropTypes.arrayOf(PropTypes.object.isRequired)
		.isRequired,
	effectiveDiscount: PropTypes.string.isRequired,
	counterOfferVariability: PropTypes.string.isRequired,
	onExampleProductChanged: PropTypes.func.isRequired,
};

export default ProductExample;
