/* eslint-disable react/prop-types */
import * as React from 'react';

import CustomerAuthContainer from '@app/components/customerPortal/CustomerAuthContainer';
import CustomerPortalPreorderLayout from './CustomerPortalPreorderLayout';

function CustomerPortalPreorder() {
	return (
		<CustomerAuthContainer>
			{({ order, merchant, variants, preorders, offers, token }) => (
				<CustomerPortalPreorderLayout
					order={order}
					merchant={merchant}
					variants={variants}
					preorders={preorders}
					offers={offers}
					token={token}
				/>
			)}
		</CustomerAuthContainer>
	);
}

export default CustomerPortalPreorder;
