import React from 'react';
import MarketplaceCampaignsTable from '@app/pages/marketplace/retailer/table/index';
import { MarketplaceCampaignProvider } from '@app/hooks/useMarketplaceCampaignContext';

export default function MarketplaceRetailer() {
	return (
		<MarketplaceCampaignProvider>
			<MarketplaceCampaignsTable />
		</MarketplaceCampaignProvider>
	);
}
