import * as React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';

import FullPageMessage from '../shared/layout/FullPageMessage';

import checkEmailImage from '../../assets/images/check-email.svg';
import Button from '../shared/button/Button';

function ExpiredTokenError({ token }) {
	const [successMsg, setSuccessMsg] = React.useState(false);
	const { mutate, isLoading, isError, error } = useMutation({
		mutationFn: async () =>
			axios.get(`/api/customers/auth/request-link?token=${token}`),
		onSuccess: () => {
			setSuccessMsg(true);
		},
	});

	const handleClick = () => mutate();

	return (
		<FullPageMessage appBar={false}>
			<Box
				component="img"
				src={checkEmailImage}
				sx={{ maxWidth: '50%', mb: 4 }}
			/>

			<Typography color="secondary.main" variant="h2" sx={{ mb: 4 }}>
				Oops! Your link has expired.
			</Typography>
			<Button
				loading={isLoading}
				color="secondary"
				startIcon={<SendIcon />}
				size="large"
				onClick={handleClick}
				sx={{ mb: 4 }}
			>
				Request new link
			</Button>
			{successMsg && (
				<>
					<Typography variant="subtitle1">
						Check your email. We sent you a new link.
					</Typography>
					<Typography variant="subtitle1">
						It will expire in one hour.
					</Typography>
				</>
			)}
			{isError && (
				<Typography variant="h6" color="error">
					{error?.data?.message
						? error.data.message
						: 'An error ocurred.  Please try again'}
				</Typography>
			)}
		</FullPageMessage>
	);
}

ExpiredTokenError.propTypes = {
	token: PropTypes.string,
};

ExpiredTokenError.defaultProps = {
	token: '',
};

export default ExpiredTokenError;
