import * as React from 'react';
import PropTypes from 'prop-types';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import CancellationNotice from './components/CancellationNotice';
import CounterOffers from './components/CounterOffers';
import OrderDetails from './components/OrderDetails';
import OrderTotal from './components/OrderTotal';
import CustomerActions from './components/CustomerActions';
import StickyFooter, {
	useStickyFooter,
} from '@app/components/shared/layout/StickyFooter';

import {
	MerchantType,
	OfferType,
	OrderType,
	VariantType,
} from '../../propTypes/types';
import Loader from '@app/components/shared/loaders/CircularLoader';
import { orderCancellationInProgress } from '@app/pages/customerPortalNyop/helpers';

function CustomerPortalNyopLayout({
	order,
	merchant,
	variants,
	offers,
	token,
}) {
	const { footerRef, footerHeight } = useStickyFooter();

	const theme = useTheme();
	const isSmDown = useMediaQuery(theme.breakpoints.down('md'));

	const [acceptAllChecked, setAcceptAllChecked] =
		React.useState(false);

	const isCancelled = order.cancelled_at !== null;

	if (!order || offers.length === 0) {
		return <Loader />;
	}

	return (
		<Container
			maxWidth="xl"
			sx={{
				paddingBottom: isSmDown ? `${footerHeight}px` : 0,
			}}
		>
			<Grid container>
				<Grid item md={7} sm={12} p={3}>
					<Typography variant="h4" mb={1} sx={{ fontWeight: 'bold' }}>
						{merchant.name}
					</Typography>
					<Typography
						variant="body1"
						sx={{ color: 'text.disabled', marginBottom: 3 }}
					>
						Name Your Price
					</Typography>
					<CancellationNotice order={order} />
					<CounterOffers
						offers={offers}
						variants={variants}
						order={order}
						merchant={merchant}
						token={token}
						acceptAllChecked={acceptAllChecked}
						setAcceptAllChecked={setAcceptAllChecked}
						disabled={orderCancellationInProgress(order)}
					/>
				</Grid>
				<Grid item md={5} sm={12}>
					{isSmDown ? (
						<Box p={3}>
							<OrderDetails
								offers={offers}
								order={order}
								variants={variants}
								acceptAllChecked={acceptAllChecked}
							/>
						</Box>
					) : (
						<Paper
							elevation={2}
							sx={{
								display: 'flex', // Add this
								flexDirection: 'column', // Add this
								borderLeft: { md: '1px solid rgba(0, 0, 0, 0.12)' },
								borderRight: { md: '1px solid rgba(0, 0, 0, 0.12)' },
								borderTop: 'none',
								borderBottom: 'none',
								backgroundColor: 'transparent',
								minHeight: { md: '100vh' },
							}}
						>
							<Box p={5} sx={{ flex: 1 }}>
								<OrderDetails
									offers={offers}
									order={order}
									variants={variants}
									acceptAllChecked={acceptAllChecked}
								/>
							</Box>
							{!isSmDown && !isCancelled && (
								<CustomerActions
									token={token}
									order={order}
									offers={offers}
								/>
							)}
						</Paper>
					)}
					{isSmDown && !isCancelled && (
						<CustomerActions
							token={token}
							order={order}
							offers={offers}
						/>
					)}
				</Grid>
			</Grid>
			{/* Only shows up on mobile */}
			<StickyFooter
				footerRef={footerRef}
				sx={{ display: { sm: 'block', md: 'none' }, pl: 3, pr: 3 }}
			>
				<OrderTotal
					order={order}
					offers={offers}
					acceptAllChecked={acceptAllChecked}
				/>
			</StickyFooter>
		</Container>
	);
}

CustomerPortalNyopLayout.propTypes = {
	order: OrderType.isRequired,
	offers: PropTypes.arrayOf(OfferType).isRequired,
	variants: PropTypes.arrayOf(VariantType).isRequired,
	merchant: MerchantType.isRequired,
	token: PropTypes.string.isRequired,
};

export default CustomerPortalNyopLayout;
