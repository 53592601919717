import * as React from 'react';
import PropTypes from 'prop-types';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ProductThumbnail from '@app/components/shared/ui/ProductThumbnail';

function ProductDetails({ variant, lineItem }) {
	return (
		<Box sx={{ display: 'flex', mb: 2 }}>
			<Badge
				badgeContent={lineItem?.quantity || 1}
				sx={{
					'& .MuiBadge-badge': {
						color: 'primary.contrastText',
						backgroundColor: 'secondary.main',
						fontWeight: 'bold',
					},
				}}
			>
				<ProductThumbnail variant={variant} size="100px" />
			</Badge>
			<Box ml={4}>
				<Typography sx={{ fontWeight: 'bold' }}>
					{variant.displayName}
				</Typography>
				<Typography color="text.secondary">
					{variant.title}
				</Typography>
			</Box>
		</Box>
	);
}

ProductDetails.propTypes = {
	variant: PropTypes.shape({
		quantity: PropTypes.number,
		displayName: PropTypes.string,
		title: PropTypes.string,
	}),
	lineItem: PropTypes.shape({
		quantity: PropTypes.number,
	}),
};

ProductDetails.defaultProps = {
	variant: {},
	lineItem: {},
};

export default ProductDetails;
