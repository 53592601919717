import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import SaasOnboarding from './SaasOnboarding';
import MarketplaceOnboarding from './MarketplaceOnboarding';

import useAuth from '../../hooks/useAuth';

import { updateMerchant } from '../../api/merchant';

function Onboarding() {
	const [onboardingType, setOnboardingType] = useState(null);
	const [error, setError] = React.useState(false);
	const { merchant, setMerchant } = useAuth();

	const handleOnboardingType = (type) => {
		setOnboardingType(type);
	};

	React.useEffect(() => {
		if (!merchant.onboarding_begin) {
			const update = async () => {
				try {
					await updateMerchant({
						fields: {
							onboarding_begin: true,
						},
					});
				} catch (err) {
					console.error(err);
					setError(true);
				}
			};
			update();
		}
	}, [merchant]);

	if (!onboardingType) {
		return (
			<Container component="main" maxWidth="md">
				<Box sx={{ p: 1, mb: 2 }}>
					<Paper sx={{ p: 2, textAlign: 'center' }}>
						<Typography variant="h6" gutterBottom>
							Get Started with Bazo
						</Typography>
						<Typography variant="body1" gutterBottom>
							You can choose to set up the Name Your Price feature for
							your store or use it in the Bazo marketplace. You can
							complete one or both setups and revisit this onboarding
							process at any time from your account settings.
						</Typography>
						<Button
							variant="contained"
							color="primary"
							onClick={() => handleOnboardingType('saas')}
							sx={{ m: 1 }}
						>
							Set Up for My Store
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => handleOnboardingType('marketplace')}
							sx={{ m: 1 }}
						>
							Set Up for Marketplace
						</Button>
						{error && (
							<Typography color="error">
								An error occurred. Please refresh the page and try
								again
							</Typography>
						)}
					</Paper>
				</Box>
			</Container>
		);
	}

	return (
		<Container component="main" maxWidth="md">
			<Paper sx={{ p: 3 }}>
				<Box sx={{ position: 'relative', mb: 1 }}>
					<IconButton onClick={() => setOnboardingType(null)}>
						<ChevronLeftIcon />
					</IconButton>
				</Box>
				{error && (
					<Typography color="error">
						An error occurred. Please refresh the page and try again
					</Typography>
				)}
				{onboardingType === 'saas' ? (
					<SaasOnboarding
						merchant={merchant}
						setMerchant={setMerchant}
					/>
				) : (
					<MarketplaceOnboarding
						merchant={merchant}
						setMerchant={setMerchant}
					/>
				)}
			</Paper>
		</Container>
	);
}

export default Onboarding;
