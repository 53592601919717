import { FLAGS } from '@app/hooks/useFlags';

export const getSettingsMenuItems = (
	klaviyoIntegrationFeatureEnabled = true,
) => [
	{
		name: 'Inventory',
		link: '/settings/inventory',
	},
	{
		name: 'NYOP',
		link: '/settings/nyop',
	},
	{
		name: 'Email',
		link: '/settings/email',
	},
	...(klaviyoIntegrationFeatureEnabled
		? [
				{
					name: 'Integrations',
					link: '/settings/integrations',
				},
		  ]
		: []),
	{
		name: 'Subscriptions',
		link: '/settings/subscriptions',
	},
];

export const campaignMenuItems = [
	{
		name: 'Name Your Own Price',
		link: '/campaigns/nyop',
	},
	{
		name: 'Preorders',
		link: '/campaigns/preorders',
	},
	{
		name: 'Marketplace',
		link: '/marketplace/campaigns',
		condition: (merchant) => merchant?.is_marketplace,
	},
	{
		name: 'Marketplace',
		link: '/marketplace/campaigns',
		condition: (merchant) => !merchant?.is_marketplace,
		flag: FLAGS.MARKETPLACE_AVAILABLE,
	},
];
