import React from 'react';
import * as _ from 'lodash-es';
import MuiDataTable from 'mui-datatables';
import { isDate } from 'date-fns';

import constants from '../../../constants';
import { useNyopCampaigns } from '../../../hooks/useNyopCampaign';
import {
	dateIsBefore,
	formatDate,
	formatReadableDate,
} from '../../../helpers/dateHelpers';
import * as nyopHelpers from '../../../helpers/nyopHelpers';
import { useSearchParams } from '../../../hooks/useSearchParams';
import StatusBadge from '../../../components/shared/ui/StatusBadge';
import Loader from '../../../components/shared/loaders/CircularLoader';
import ProgressBar from '../../../components/shared/loaders/ProgressBar';
import ActionsRenderer from './ActionsRenderer';
import CampaignDescriptionRenderer from './CampaignDescriptionRenderer';
import WidgetsList from './widgetsList';
import { useSellingPlanGroupsSummary } from '@app/hooks/useSellingPlanGroups';
import useAuth from '@app/hooks/useAuth';
import getNoTableMatch from '@app/components/shared/Table/components/NoTableMatch';

function getActionsRenderer(value, tableMeta) {
	const now = new Date();

	const campaignState = {
		isStarted: dateIsBefore({
			start: tableMeta.rowData[1],
			end: now,
		}),
		isEnded: tableMeta.rowData[2]
			? dateIsBefore({
					start: tableMeta.rowData[2],
					end: now,
			  })
			: false,
		isFinalizing: !!tableMeta.rowData.at(-4),
		isFinalized: !!tableMeta.rowData.at(-3),
		isDeleted: !!tableMeta.rowData[8],
	};
	return (
		<ActionsRenderer
			campaignId={value}
			campaignState={campaignState}
		/>
	);
}

function getCampaignDescriptionRenderer(value, tableMeta) {
	return (
		<CampaignDescriptionRenderer
			campaignId={tableMeta.rowData.at(-7)}
			description={value}
		/>
	);
}

const columns = [
	{
		name: 'description',
		label: 'Campaign',
		options: {
			filter: false,
			customBodyRender: getCampaignDescriptionRenderer,
		},
	},
	{
		name: 'start_date',
		label: 'Start date',
		options: {
			filter: false,
			customBodyRender: (value) => formatReadableDate(value),
		},
	},
	{
		name: 'end_date',
		label: 'End date',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatReadableDate(value) : '-',
		},
	},
	{
		name: 'remaining_balance_charge_date',
		label: 'Due date',
		options: {
			filter: false,
			customBodyRender: (value) => {
				if (value === 'Fulfilled') {
					return value;
				}
				return isDate(value) ? formatReadableDate(value) : '-';
			},
		},
	},
	{
		name: 'statusBadge.message',
		label: 'Status',
		options: {
			sort: false,
			filter: false,
			customBodyRender: (value, tableMeta) => {
				const statusBadge = tableMeta.rowData.at(-2);
				return statusBadge ? (
					<StatusBadge
						sx={{
							textAlign: 'center',
						}}
						buttonText={statusBadge.message}
						color={statusBadge.statusColor}
						textColor={statusBadge?.textColor || null} // Only need text color for one case
					/>
				) : (
					''
				);
			},
		},
	},
	{
		name: 'variantsCount',
		label: 'Variants',
		options: {
			sort: false,
			filter: false,
			customBodyRender: (value, tableMeta) => {
				const formatMerchantNumber = tableMeta.rowData.at(-6);
				return formatMerchantNumber({ value });
			},
		},
	},
	{
		name: 'inventoryUnits',
		label: 'Units from offers / Units available',
		options: {
			sort: false,
			filter: false,
			customBodyRender: (value, tableMeta) => {
				const units = tableMeta.rowData.at(-5);
				const data = [
					{
						label: 'Units from offers',
						value: units.offersUnits,
						color: constants.colors.primary,
					},
				];
				return (
					<ProgressBar
						total={value}
						data={data}
						sx={{ maxWidth: '10rem' }}
						showLegend
						legendInPercentage
						unfilledLabel="Units left"
					/>
				);
			},
		},
	},
	{
		name: 'units.offersUnits',
		label: 'Execution progress',
		options: {
			sort: false,
			filter: false,
			customBodyRender: (value, tableMeta) => {
				const {
					acceptedOffersUnits,
					counteredOffersUnits,
					canceledOffersUnits,
				} = tableMeta.rowData.at(-5);

				const data = [
					{
						label: 'Accepted offers units',
						color: constants.colors.primary,
						value: acceptedOffersUnits,
					},
					{
						label: 'Countered offers units',
						color: constants.colors.tertiary,
						value: counteredOffersUnits,
					},
					{
						label: 'Canceled offers units',
						color: constants.colors.failure,
						value: canceledOffersUnits,
					},
				];

				return (
					<ProgressBar
						total={value}
						data={data}
						sx={{ maxWidth: '10rem' }}
						showLegend
						legendInPercentage
					/>
				);
			},
		},
	},
	{
		name: 'deleted',
		label: 'State',
		options: {
			sort: false,
			filter: true,
			filterType: 'checkbox',
			filterList: ['Active'],
			filterOptions: {
				names: ['Active', 'Inactive'],
			},
			display: 'excluded',
		},
	},
	{
		name: 'id',
		label: 'Actions',
		options: {
			sort: false,
			filter: false,
			download: false,
			setCellProps: () => ({
				style: {
					textAlign: 'center',
				},
			}),
			setCellHeaderProps: () => ({
				style: {
					textAlign: 'center',
				},
			}),
			customBodyRender: getActionsRenderer,
		},
	},
	// These columns are here just for reference, all new columns should be added on the top
	// tableMeta.rowData.at(-6)
	{
		name: 'formatMerchantNumber',
		options: {
			viewColumns: false,
			filter: false,
			display: 'excluded',
			download: false,
		},
	},
	// tableMeta.rowData.at(-5)
	{
		name: 'units',
		options: {
			viewColumns: false,
			filter: false,
			display: 'excluded',
			download: false,
		},
	},
	// tableMeta.rowData.at(-4)
	{
		name: 'processing_started_at',
		options: {
			viewColumns: false,
			filter: false,
			display: 'excluded',
			download: false,
		},
	},
	// tableMeta.rowData.at(-3)
	{
		name: 'completed_at',
		options: {
			viewColumns: false,
			filter: false,
			display: 'excluded',
			download: false,
		},
	},
	// tableMeta.rowData.at(-2)
	{
		name: 'statusBadge',
		options: {
			viewColumns: false,
			filter: false,
			display: 'excluded',
			download: false,
		},
	},
	// tableMeta.rowData.at(-1)
	{
		name: 'variants',
		options: {
			viewColumns: false,
			filter: false,
			display: 'excluded',
			download: false,
		},
	},
];

function NyopCampaignTable() {
	// Search params: { page, filterLists, state, search, sort }
	const { searchParams, setSearchParams } = useSearchParams();
	const { formatMerchantNumber } = useAuth();

	const { data, isLoading, isFetching } = useNyopCampaigns({
		page: searchParams?.page,
		search: searchParams?.search,
		sort: searchParams?.sort,
		state: searchParams?.state,
		configOpts: { keepPreviousData: true },
	});
	const {
		data: widgetsData,
		isLoading: isWidgetsDataLoading,
		isFetching: isWidgetsDataFetching,
	} = useSellingPlanGroupsSummary();

	const tableData = React.useMemo(
		() =>
			data?.data?.map((campaign) => {
				const inventoryUnits = campaign.variants.reduce(
					(acc, value) => acc + (value.inventory_quantity || 0),
					0,
				);

				return {
					...campaign,
					remaining_balance_charge_date: campaign?.deleted
						? 'Fulfilled'
						: formatReadableDate(
								campaign.remaining_balance_charge_date,
						  ),
					start_date: campaign.start_date,
					end_date: campaign.end_date,
					completedAt: campaign.completed_at,
					variantsCount: campaign.variants.length,
					statusBadge: nyopHelpers.createBadge({
						endDate: campaign.end_date,
						startDate: campaign.start_date,
						deleted: campaign.deleted,
						completedAt: campaign.completed_at,
					}),
					units: campaign.units,
					inventoryUnits,
					formatMerchantNumber,
				};
			}),
		[data, formatMerchantNumber],
	);

	const updateSearchValue = React.useMemo(
		() =>
			_.debounce((newSearchValue) => {
				// Set page value to 1 so text search does not try to look for page n
				setSearchParams({ search: newSearchValue, page: 1 });
			}, 600),
		[setSearchParams],
	);

	if (isLoading || isWidgetsDataLoading) {
		return <Loader fullPage />;
	}

	const columnsWithFilters = columns.map((column, index) => ({
		...column,
		options: {
			...column.options,
			filterList:
				searchParams.filterLists[index] || column.options?.filterList,
		},
	}));

	return (
		<>
			<WidgetsList widgetsData={widgetsData} />
			<MuiDataTable
				title="Campaign Management"
				data={
					isFetching || isWidgetsDataFetching || !tableData
						? []
						: tableData
				}
				columns={columnsWithFilters}
				options={{
					elevation: 0,
					count: data.count,
					rowsPerPage: 10,
					rowsPerPageOptions: [],
					filter: true,
					filterType: 'dropdown',
					responsive: 'vertical',
					enableNestedDataAccess: '.',
					selectableRowsHideCheckboxes: true,
					viewColumns: true,
					textLabels: {
						body: {
							noMatch: getNoTableMatch({
								isLoading: isFetching || isWidgetsDataFetching,
								text: 'Sorry, no Campaigns found',
							}),
						},
					},
					downloadOptions: {
						filename: `campaigns_${formatDate(new Date())}.csv`,
					},
					tableId: 'nyop',
					storageKey: 'nyop',
					serverSide: true,
					onFilterChange: (changedColumn, filterLists) => {
						if (changedColumn === 'deleted') {
							setSearchParams({
								filterLists,
								state: filterLists[8],
							});
						}
					},
					onChangePage: (newPage) => {
						setSearchParams({ page: newPage + 1 });
					},
					onSearchChange: (searchText) => {
						updateSearchValue(searchText);
					},
					onColumnSortChange: (column, direction) => {
						setSearchParams({ sort: [column, direction] });
					},
				}}
			/>
		</>
	);
}

export default NyopCampaignTable;
