import { isNil } from 'lodash-es';

// Used in Edit Campaign and Create Campaign with Marketplace Campaign
// to merge automation rules with selectedVariants
export function mergeAutomationRulesWithVariants({
	variants,
	variantAutomationRules,
}) {
	const perProductDiscounts = variantAutomationRules;

	return variants?.map((variant) => {
		let newVariant = variant;
		const variantCounterValue = perProductDiscounts?.[variant.id];
		const productCounterValue =
			perProductDiscounts?.[variant.product_id];
		if (variantCounterValue) {
			newVariant = {
				...newVariant,
				counterValue: variantCounterValue.maxEffectiveDiscount * 100,
			};
		}
		if (productCounterValue) {
			newVariant = {
				...newVariant,
				productCounterValue:
					productCounterValue.maxEffectiveDiscount * 100,
			};
		}
		return newVariant;
	});
}

export function getCounterValues(selectedVariants) {
	return selectedVariants.reduce(
		(
			object,
			{
				counterValue,
				productCounterValue,
				id,
				product_id: productId,
			},
		) => {
			if (counterValue !== undefined) {
				// eslint-disable-next-line no-param-reassign
				object[id] = Math.round(counterValue);
			}
			if (productCounterValue !== undefined) {
				// eslint-disable-next-line
				object[productId] = Math.round(productCounterValue);
			}
			return object;
		},
		{},
	);
}

export const validateCounterValueMsrp = (
	counterValue,
	msrp,
	price,
) => {
	if (isNil(msrp) || +msrp === +price) {
		return true;
	}

	const priceToMsrpRatio = +price / +msrp;
	const priceToMsrpCounterValue = 1 - priceToMsrpRatio;

	return priceToMsrpCounterValue < counterValue / 100;
};
