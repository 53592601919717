import * as React from 'react';
import { useLocation } from 'react-router-dom';

import * as googleAnalytics from '../lib/googleAnalytics';

function useGoogleAnalytics() {
	const location = useLocation();

	// Report page views in GA on location change
	React.useEffect(() => {
		if (import.meta.env.VITE_GA_TRACKING_ID) {
			const currentPath = location.pathname + location.search;
			googleAnalytics.sendPageview(currentPath);
		}
	}, [location]);
}

export default useGoogleAnalytics;
