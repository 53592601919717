import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../modal/Modal';
import CloseAction from '@app/pages/nyopCampaign/actions/CloseAction';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import Button from '../../button/Button';

function CloseNyopButton({
	campaignId,
	buttonText,
	size,
	disabled,
	onSuccess,
}) {
	const { searchParams } = useSearchParams();

	const [modalVisible, setModalVisible] = React.useState(false);

	return (
		<>
			<ConfirmationModal
				open={modalVisible}
				handleModal={setModalVisible}
			>
				<CloseAction
					campaignId={campaignId}
					onSuccess={() => {
						if (onSuccess) {
							onSuccess();
						}
						setModalVisible(false);
					}}
					searchParams={searchParams}
				/>
			</ConfirmationModal>
			<Button
				disabled={disabled}
				variant="outlined"
				color="primary"
				size={size}
				sx={{ width: '100%' }}
				onClick={() => setModalVisible(true)}
			>
				{buttonText}
			</Button>
		</>
	);
}

CloseNyopButton.propTypes = {
	campaignId: PropTypes.string.isRequired,
	buttonText: PropTypes.string,
	size: PropTypes.string,
	disabled: PropTypes.bool,
	onSuccess: PropTypes.func,
};

CloseNyopButton.defaultProps = {
	buttonText: 'Close',
	size: 'medium',
	disabled: false,
	onSuccess: null,
};

export default CloseNyopButton;
