import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ItemRowContainer from '@app/components/shared/layout/ItemRowContainer';
import TableHeader from '@app/pages/customerPortalNyop/components/TableHeader';
import ListItem from '@app/pages/customerPortalNyop/components/ListItem';
import VariantOptions from '@app/components/shared/ui/VariantOptions';
import OfferStatusBadge from './OfferStatusBadge';
import OrderTotal from '@app/pages/customerPortalNyop/components/OrderTotal';

import { useOffers } from './OffersProvider';

import {
	getBulkOffersSavedPrice,
	getFirstValidNumber,
	getVariantMap,
	mergeLineItems,
	sortOrderDetails,
} from '../helpers';

import {
	OfferType,
	OrderType,
	VariantType,
} from '@app/propTypes/types';
import SaveBulkPriceText from './SaveBulkPriceText';

// Problems with null variant id in order line item after deleted
// TODO: Make this just return the graphql id for simplicity
const getVariantIdFromLineItemOrOffer = (lineItem, offers) => {
	if (!lineItem || !Array.isArray(offers)) return null;

	if (lineItem.variant_id) {
		return lineItem.variant_id;
	}

	const offer = offers.find(
		(o) => o.line_item_id === lineItem.admin_graphql_api_id,
	);

	if (offer && offer.variant_id) {
		const parts = offer.variant_id.split('/');
		if (parts.length >= 5) {
			return parts[4];
		}
	}

	return null;
};

function OrderDetails({ offers, order, variants, acceptAllChecked }) {
	const { offerStatus } = useOffers();
	const sortedLineItems = React.useMemo(() => {
		const merged = mergeLineItems({
			order,
			offers,
		});
		return sortOrderDetails(merged);
	}, [offers, order]);

	const variantMap = getVariantMap(variants);
	const bulkOffersPriceDiscount = getBulkOffersSavedPrice(offers);

	const rowHeight = {
		xs: '50px',
		sm: '75px',
	};

	return (
		<Box>
			<Typography variant="h5" component="h2" fontWeight="bold">
				Your order:
			</Typography>
			<Box
				sx={{
					'& *': {
						fontSize: {
							sm: 'default',
							md: '12px',
						},
					},
				}}
			>
				<ItemRowContainer
					items={sortedLineItems}
					headerComponent={
						<TableHeader leftText="ITEM" rightText="PRICE" />
					}
					renderChild={(lineItem) => {
						let offerAmount = null;
						const matchedVariant =
							variantMap[
								`gid://shopify/ProductVariant/${getVariantIdFromLineItemOrOffer(
									lineItem,
									offers,
								)}`
							];
						if (!matchedVariant) {
							return null;
						}

						if (lineItem.isOffer) {
							if (
								acceptAllChecked &&
								lineItem.offer?.bulk_counter_offer_amount
							) {
								offerAmount =
									lineItem.offer.bulk_counter_offer_amount;
							} else if (lineItem.offer?.counter_offer_amount) {
								offerAmount = lineItem.offer?.counter_offer_amount;
							} else {
								offerAmount = lineItem.offer?.offer_amount;
							}
						} else {
							const totalDiscount =
								lineItem.discount_allocations.reduce(
									(result, discount) => result + +discount.amount,
									0,
								);

							if (totalDiscount) {
								offerAmount =
									+lineItem.price - totalDiscount / lineItem.quantity;
							}
						}

						const originalPrice = lineItem.isOffer
							? lineItem.offer.msrp
							: lineItem.price_set.presentment_money.amount;

						return (
							<ListItem
								key={`order-details-list-${lineItem.id}`}
								order={order}
								variant={matchedVariant}
								offerPrice={offerAmount}
								quantity={getFirstValidNumber(
									lineItem?.offer?.fulfilled_quantity,
									lineItem?.offer?.quantity,
									lineItem.quantity,
									1,
								)}
								originalPrice={originalPrice}
								rowHeight={rowHeight}
								soldOut={lineItem?.offer?.handle_out_of_stock}
								declined={
									(lineItem?.offer && // Is currently declined
										offerStatus[lineItem.offer.id] === false) ||
									(lineItem?.isOffer && // Has been previously declined or cancelled
										lineItem.offer?.canceled_at !== null) ||
									Boolean(
										lineItem.isOffer &&
											lineItem.offer?.counter_offer_declined_at !==
												null,
									)
								}
								secondaryContent={
									<Box
										sx={{
											display: 'flex',
											justifyContent: {
												xs: 'flex-start',
												sm: 'space-between',
											},
											alignItems: { xs: 'baseline', sm: 'flex-end' },
										}}
									>
										<VariantOptions
											variant={matchedVariant}
											sx={{ display: { xs: 'none', sm: 'flex' } }}
										/>
										{lineItem?.offer && (
											<OfferStatusBadge
												order={order}
												offer={lineItem.offer}
											/>
										)}
									</Box>
								}
								acceptAllChecked={acceptAllChecked}
							/>
						);
					}}
				/>
			</Box>
			<SaveBulkPriceText
				bulkOffersPriceDiscount={bulkOffersPriceDiscount}
				customerLocale={order?.customer_locale}
				currencyCode={
					order?.subtotal_price_set?.presentment_money?.currency_code
				}
				sx={{ mb: -2, mt: 2 }}
			/>
			<OrderTotal
				offers={offers}
				order={order}
				variants={variants}
				sx={{
					mt: 3,
					display: { xs: 'none', sm: 'none', md: 'flex' },
				}}
				acceptAllChecked={acceptAllChecked}
			/>
		</Box>
	);
}

OrderDetails.propTypes = {
	offers: PropTypes.arrayOf(OfferType).isRequired,
	order: OrderType.isRequired,
	variants: PropTypes.arrayOf(VariantType).isRequired,
	acceptAllChecked: PropTypes.bool,
};

OrderDetails.defaultProps = {
	acceptAllChecked: false,
};

export default OrderDetails;
