import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { getNumericId } from '@app/lib/shopify';
import Button from '../button/Button';

function ActionsRenderer({
	campaignId,
	productId,
	variantId,
	disabled,
	sx,
}) {
	let productLevel = 'product';
	let url = `/campaigns/nyop/${getNumericId(
		campaignId,
	)}/products/${getNumericId(productId)}`;
	if (variantId) {
		url = `${url}/variants/${getNumericId(variantId)}`;
		productLevel = 'variant';
	}

	const navigate = useNavigate();

	return (
		<Button
			title={`Analyze ${productLevel} performance`}
			onClick={() => navigate(url)}
			disabled={disabled}
			variant="text"
			sx={{ ...sx }}
		>
			Analyze
		</Button>
	);
}

ActionsRenderer.propTypes = {
	campaignId: PropTypes.string.isRequired,
	productId: PropTypes.string.isRequired,
	variantId: PropTypes.string,
	disabled: PropTypes.bool,
	sx: PropTypes.shape({}),
};

ActionsRenderer.defaultProps = {
	disabled: false,
	variantId: null,
	sx: {},
};

export default ActionsRenderer;
