import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from '@app/components/shared/inputs/SelectInput';
import constants from '@app/constants';

function DepositInput({ defaultValue, onChange }) {
	return (
		<SelectInput
			name="deposit"
			value={defaultValue}
			label="Deposit"
			handleChange={onChange}
			inputLabelText="Deposit"
			fullWidth
			options={[
				{
					label: 'No deposit',
					value:
						constants.deferredPurchaseOptions.depositValue.NoDeposit,
				},
				{
					label: '25% deposit',
					value:
						constants.deferredPurchaseOptions.depositValue.Deposit25,
				},
				{
					label: '50% deposit',
					value:
						constants.deferredPurchaseOptions.depositValue.Deposit50,
				},
				{
					label: '75% deposit',
					value:
						constants.deferredPurchaseOptions.depositValue.Deposit75,
				},
				{
					label: 'Full deposit',
					value:
						constants.deferredPurchaseOptions.depositValue.Deposit100,
				},
			]}
		/>
	);
}

DepositInput.propTypes = {
	defaultValue: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default DepositInput;
