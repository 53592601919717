import PropTypes from 'prop-types';

import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNyopCampaignRecreate } from '@app/hooks/useNyopCampaign';
import Button from '@app/components/shared/button/Button';

function RecreateAction({ campaignId, onSuccess }) {
	const {
		mutate,
		error,
		isSuccess,
		isLoading: loading,
	} = useNyopCampaignRecreate({
		id: campaignId,
	});

	React.useEffect(() => {
		if (isSuccess) {
			onSuccess();
		}
	}, [onSuccess, isSuccess]);

	const handleSubmit = () => mutate({ id: campaignId });

	return (
		<Box>
			<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
				Recreate campaign
			</Typography>
			<Typography sx={{ marginTop: 1, marginBottom: 2 }}>
				Do you want to restart campaign now with the same settings or
				you want to change settings?
			</Typography>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
			>
				<Button
					type="submit"
					loading={loading}
					onClick={handleSubmit}
				>
					RESTART NOW
				</Button>

				{error && (
					<Typography color="error">{error.message}</Typography>
				)}
			</Box>
		</Box>
	);
}

RecreateAction.propTypes = {
	campaignId: PropTypes.string.isRequired,
	onSuccess: PropTypes.func.isRequired,
	searchParams: PropTypes.shape({
		page: PropTypes.number,
		search: PropTypes.string,
		sort: PropTypes.arrayOf(PropTypes.string),
		state: PropTypes.arrayOf(PropTypes.string),
	}),
};

RecreateAction.defaultProps = { searchParams: {} };

export default RecreateAction;
