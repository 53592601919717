import PropTypes from 'prop-types';

export const OffersAggregations = PropTypes.shape({
	offersCount: PropTypes.number.isRequired,
	acceptedOffersCount: PropTypes.number.isRequired,
	counteredOffersCount: PropTypes.number.isRequired,
	counteredAndAcceptedOffersCount: PropTypes.number.isRequired,
	completedOffersCount: PropTypes.number.isRequired,
	canceledOffersCount: PropTypes.number.isRequired,
	potentialRevenue: PropTypes.number.isRequired,
	offersInventory: PropTypes.number.isRequired,
	acceptedOffersInventory: PropTypes.number.isRequired,
	acceptedOffersPotentialRevenue: PropTypes.number.isRequired,
	counteredOffersInventory: PropTypes.number.isRequired,
	counteredOffersPotentialRevenue: PropTypes.number.isRequired,
	counteredAndAcceptedOffersInventory: PropTypes.number.isRequired,
	counteredAndAcceptedOffersPotentialRevenue:
		PropTypes.number.isRequired,
	averageDiscount: PropTypes.number.isRequired,
	initialAverageDiscount: PropTypes.number.isRequired,
});
