import jwtDecodePackage from 'jwt-decode';

export function jwtDecode(token, opts = {}) {
	let decoded;

	try {
		decoded = jwtDecodePackage(token, opts);
	} catch (error) {
		console.error(error);
	}
	return decoded;
}
