import * as React from 'react';
import PropTypes from 'prop-types';

// import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import PlaceIcon from '@mui/icons-material/Place';

// import GoogleMapReact from 'google-map-react'; // yarn uninstalled

import OutlineBox from '@app/components/shared/ui/OutlineBox';

import { formatReadableDate } from '@app/helpers/dateHelpers';
import constants from '@app/constants';

// function Marker() {
// 	return <PlaceIcon fontSize="large" style={{ color: '#F4383D' }} />;
// }

function createShippingMessage({
	cancelledAt,
	fulfillmentStatus,
	dueDate,
	orderType,
}) {
	const entity =
		orderType === constants.campaignTypes.PREORDER
			? 'pre-order'
			: 'offer';
	if (cancelledAt) {
		return `Order cancelled on ${formatReadableDate(cancelledAt)}`;
	}
	if (!dueDate) {
		return `Your ${entity} will be shipped as soon as possible`;
	}
	if (fulfillmentStatus === 'fulfilled') {
		return `Your ${entity} has been fulfilled. ${formatReadableDate(
			dueDate,
		)}`;
	}
	if (fulfillmentStatus === 'partial') {
		return `Your ${entity} has been partially fulfilled. ${formatReadableDate(
			dueDate,
		)}`;
	}
	if (fulfillmentStatus === 'restocked') {
		return `Your ${entity} has been canceled and restocked. ${formatReadableDate(
			dueDate,
		)}`;
	}
	return `Your ${entity} should ship on or before ${formatReadableDate(
		dueDate,
	)}`;
}

function ShippingStatus({
	shippingAddress,
	dueDate,
	fulfillmentStatus,
	cancelledAt,
	orderType,
}) {
	// These values can sometimes be null
	if (!shippingAddress || !shippingAddress?.address1) {
		return null;
	}
	// const { latitude = '', longitude = '' } = shippingAddress;

	return (
		<OutlineBox p={0} sx={{ display: 'block' }}>
			{/* TODO: Maps package not compatible with react 18. Removing since this page is being deprecated.  See removed code below if needed before we delete this page */}
			<OutlineBox mb={0} sx={{ border: 0 }}>
				<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
					{createShippingMessage({
						cancelledAt,
						fulfillmentStatus,
						dueDate,
						orderType,
					})}
				</Typography>
			</OutlineBox>
		</OutlineBox>
	);
}

ShippingStatus.propTypes = {
	shippingAddress: PropTypes.shape({
		first_name: PropTypes.string,
		address1: PropTypes.string,
		phone: PropTypes.string,
		city: PropTypes.string,
		zip: PropTypes.string,
		province: PropTypes.string,
		country: PropTypes.string,
		last_name: PropTypes.string,
		address2: PropTypes.string,
		company: PropTypes.string,
		latitude: PropTypes.number,
		longitude: PropTypes.number,
		name: PropTypes.string,
		country_code: PropTypes.string,
		province_code: PropTypes.string,
	}),
	dueDate: PropTypes.string,
	fulfillmentStatus: PropTypes.string,
	cancelledAt: PropTypes.oneOfType([
		PropTypes.instanceOf(Date),
		PropTypes.string,
	]),
	orderType: PropTypes.string.isRequired,
};

ShippingStatus.defaultProps = {
	shippingAddress: {},
	dueDate: '',
	fulfillmentStatus: '',
	cancelledAt: '',
};

export default ShippingStatus;

// TODO: Remove this when possible
// {/* Sometimes shipping address is null */}
// {latitude && longitude && (
// 	<Box sx={{ height: '200px', width: '100%' }}>
// 		{/* TODO: Make a choice here */}
// 		{/* THis version is free but less customizable */}
// 		{/* {latitude && longitude && (
// 		<iframe
// 			width="100%"
// 			height="100%"
// 			title="Customer shipping address map"
// 			style={{ border: 0 }}
// 			loading="lazy"
// 			allowFullScreen={false}
// 			referrerPolicy="no-referrer-when-downgrade"
// 			src={`https://www.google.com/maps/embed/v1/place?key=${
// 				import.meta.env.VITE_GOOGLE_MAPS_API_KEY
// 			}&q=${latitude}+${longitude}`}
// 		/>
// 	)} */}
// 		{/* This version will cost after ~28,000 monthly views */}
// 		<GoogleMapReact
// 			bootstrapURLKeys={{
// 				key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
// 			}}
// 			yesIWantToUseGoogleMapApiInternals
// 			defaultCenter={{
// 				lat: latitude,
// 				lng: longitude,
// 			}}
// 			zoom={14}
// 			// TODO: Do we need this property? https://github.com/google-map-react/google-map-react#use-google-maps-api
// 			// yesIWantToUseGoogleMapApiInternals
// 			options={{
// 				fullscreenControl: false,
// 				gestureHandling: 'none',
// 			}}
// 		>
// 			<Marker lat={latitude} lng={longitude} />
// 		</GoogleMapReact>
// 	</Box>
// )}
