import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import useAuth from '@app/hooks/useAuth';
import constants from '@app/constants/index';

const POTENTIAL_REVENUE_TOOLTIP =
	'Potential revenue based on all not canceled offers';
const POSSIBLE_REVENUE_TOOLTIP =
	'Possible revenue based on all accepted offers';
const FACTUAL_REVENUE_TOOLTIP =
	'Actual revenue generated by the campaign';

function RevenueRenderer({
	potentialRevenue,
	possibleRevenue,
	factualRevenue,
	isFinalized,
}) {
	const { formatMerchantCurrency } = useAuth();

	return (
		<Box>
			<Tooltip
				title={POTENTIAL_REVENUE_TOOLTIP}
				arrow
				placement="left"
			>
				<Typography color={constants.colors.pending}>
					{formatMerchantCurrency(potentialRevenue)}
				</Typography>
			</Tooltip>
			<Divider />
			{isFinalized ? (
				<Tooltip
					title={FACTUAL_REVENUE_TOOLTIP}
					arrow
					placement="left"
				>
					<Typography color={constants.colors.primary}>
						{formatMerchantCurrency(factualRevenue)}
					</Typography>
				</Tooltip>
			) : (
				<Tooltip
					title={POSSIBLE_REVENUE_TOOLTIP}
					arrow
					placement="left"
				>
					<Typography color={constants.colors.primary}>
						{formatMerchantCurrency(possibleRevenue)}
					</Typography>
				</Tooltip>
			)}
		</Box>
	);
}

RevenueRenderer.propTypes = {
	factualRevenue: PropTypes.string.isRequired,
	possibleRevenue: PropTypes.string.isRequired,
	potentialRevenue: PropTypes.string.isRequired,
	isFinalized: PropTypes.bool.isRequired,
};

export default RevenueRenderer;
