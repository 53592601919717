import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

import ConversationalInputWrapper from '@app/components/shared/inputs/ConversationalTextInput';

function CampaignNameInput({ defaultName, onChange, errorText }) {
	return (
		<ConversationalInputWrapper prompt="What would you like to name this Campaign?">
			<TextField
				type="text"
				id="name"
				label="Campaign name"
				name="description"
				placeholder={`Summer Sale ${new Date().getFullYear()}`}
				onChange={onChange}
				value={defaultName}
				error={!!errorText}
				helperText={
					errorText ||
					'Enter a campaign name for internal use only. It will not be visible to shoppers.'
				}
				autoFocus
			/>
		</ConversationalInputWrapper>
	);
}

CampaignNameInput.propTypes = {
	defaultName: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	errorText: PropTypes.string,
};

CampaignNameInput.defaultProps = {
	errorText: '',
};

export default CampaignNameInput;
