import * as React from 'react';

import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';
import {
	createTheme,
	ThemeProvider,
	useTheme,
} from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import useAuth from '@app/hooks/useAuth';

function getAmount({ max, value }) {
	return Math.round(max - (value * max) / 100);
}

function InfoBox({ children, showText }) {
	const anchor = React.useRef();
	const theme = useTheme();

	const getMuiTheme = () => {
		const overrides = {
			components: {
				MuiPaper: {
					styleOverrides: {
						root: {
							borderRadius: 0,
							boxShadow: 'none',
							borderLeft: '1px solid #827F7F',
							borderBottom: '1px solid #827F7F',
							width: 'fit-content',
							height: 'fit-content',
							minHeight: '0.6rem',
							minWidth: '10rem',
							backgroundColor: '#F8F8F8E8',
							transform: 'translateY(1px) !important',
						},
					},
				},
			},
		};

		return createTheme(theme, overrides);
	};

	return (
		<ThemeProvider theme={getMuiTheme()}>
			<Box
				onMouseEnter={null}
				onMouseLeave={null}
				sx={{
					transform: 'translateY(-90%)',
					width: 'fit-content',
					height: 'fit-content',
					ml: '25%',
					display: 'flex',
					cursor: 'default',
				}}
			>
				<Box
					sx={{
						borderTop: '1px solid #827F7F',
						width: '0.32rem',
						height: '0.31rem',
						alignSelf: 'end',
						transformOrigin: 'top right',
						transform: 'rotate(-45deg)',
					}}
				/>
				<Box
					ref={anchor}
					sx={{
						transform: 'translateY(-0.3rem)',
						width: 'fit-content',
						height: 'fit-content',
						minWidth: '2rem',
						maxWidth: '2rem',
						minHeight: '0.6rem',

						...(!showText
							? {
									borderLeft: '1px solid #827F7F',
									borderBottom: '1px solid #827F7F',
							  }
							: {}),
						...(showText
							? {
									minWidth: '10rem',
									backgroundColor: '#F8F8F8E8',
									padding: '0.3rem',
									maxWidth: '10rem',
							  }
							: {}),
					}}
				>
					<Popover
						sx={{
							pointerEvents: 'none',
						}}
						open={showText}
						anchorEl={anchor.current}
						disableAutoFocus
						disableScrollLock
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						transformOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
					>
						<Box
							sx={{
								maxWidth: '10rem',
								padding: '0.3rem',
							}}
						>
							<Typography variant="body2">{children}</Typography>
						</Box>
					</Popover>
				</Box>
			</Box>
		</ThemeProvider>
	);
}

InfoBox.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	showText: PropTypes.bool.isRequired,
};

function Label({ children }) {
	return <Typography variant="caption">{children}</Typography>;
}

Label.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

function PercentLabels({ values }) {
	const adjustments = [];
	values.forEach(([value], index, arr) => {
		const nextValue = (arr[index + 1] || [])[0];
		if (nextValue === null || nextValue === undefined) {
			adjustments[index] = adjustments[index] || 0;
			return;
		}

		const distance = value - nextValue;

		if (distance < 4) {
			const padding = (4 - distance) / 2;
			adjustments[index] = -padding;
			adjustments[index + 1] = padding;
			return;
		}
		adjustments[index] = adjustments[index] || 0;
	});

	return (
		<Box
			sx={{
				display: 'flex',
				width: '100%',
				left: '-10px',
				position: 'relative',
				height: '1.1rem',
				lineHeight: '1.1rem',
			}}
		>
			{values?.map(([value], index) => (
				<Box
					sx={{
						left: `${100 - value + adjustments[index]}%`,
						position: 'absolute',
						textAlign: 'right',
					}}
					/* eslint-disable-next-line react/no-array-index-key */
					key={`percent-label-${index}`}
				>
					<Label>{Math.round(value)}%</Label>
				</Box>
			))}
		</Box>
	);
}

PercentLabels.propTypes = {
	values: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any))
		.isRequired,
};

function MoneyLabels({ values }) {
	const adjustments = [];
	values.forEach(([value], index, arr) => {
		const nextValue = (arr[index + 1] || [])[0];
		if (nextValue === null || nextValue === undefined) {
			adjustments[index] = adjustments[index] || 0;
			return;
		}

		const distance = value - nextValue;

		if (distance < 4) {
			const padding = (4 - distance) / 2;
			adjustments[index] = -padding;
			adjustments[index + 1] = padding;
			return;
		}
		adjustments[index] = adjustments[index] || 0;
	});

	return (
		<Box
			sx={{
				display: 'flex',
				width: '100%',
				left: '-10px',
				position: 'relative',
				height: '1rem',
				lineHeight: '1.1rem',
			}}
		>
			{values?.map(([value, amount], index) => (
				<Box
					sx={{
						left: `${100 - value + adjustments[index]}%`,
						position: 'absolute',
						textAlign: 'right',
					}}
					/* eslint-disable-next-line react/no-array-index-key */
					key={`money-label-${index}`}
				>
					<Label>{amount}</Label>
				</Box>
			))}
		</Box>
	);
}

MoneyLabels.propTypes = {
	values: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any))
		.isRequired,
};

function CampaignConfigsProductBarExample({
	productPrice,
	productMSRP,
	counterOfferThreshold,
	counterOfferVariability,
}) {
	const { formatMerchantCurrency } = useAuth();

	const [showCounterInfoTest, setShowCounterInfoText] =
		React.useState(false);
	const [
		showCounterVariabilityInfoTest,
		setShowCounterVariabilityInfoText,
	] = React.useState(false);
	const [showAcceptInfoTest, setShowAcceptInfoText] =
		React.useState(false);

	const [showMsrpInfoBox, setShowMsrpInfoBox] = React.useState(false);

	// This is the main value that decides the destiny of all others. If price/MSRP difference is huge
	// then it's possible that the length of counter bar will be affected
	const msrpRangeValue = (1 - productPrice / productMSRP) * 100;

	const counterRangeValue = Math.min(
		100 - counterOfferThreshold,
		100 - msrpRangeValue,
	);

	const acceptRangeValue = Math.max(
		0,
		100 -
			counterRangeValue -
			counterOfferVariability -
			msrpRangeValue,
	);

	const counterVariabilityThreshold = Math.max(
		msrpRangeValue,
		counterOfferThreshold - counterOfferVariability,
	);

	const counterOfferVariabilityValue = Math.min(
		counterOfferVariability,
		100 - msrpRangeValue - counterRangeValue,
	);

	const acceptThreshold =
		counterOfferThreshold -
		counterOfferVariability -
		acceptRangeValue;

	const values = [
		[
			100,
			formatMerchantCurrency(
				getAmount({
					max: productMSRP,
					value: 100,
				}),
				0,
			),
		],
		[
			100 - counterRangeValue,
			formatMerchantCurrency(
				getAmount({
					max: productMSRP,
					value: 100 - counterRangeValue,
				}),
				0,
			),
		],
	];

	if (counterOfferVariability !== 0) {
		values.push([
			counterVariabilityThreshold,
			formatMerchantCurrency(
				getAmount({
					max: productMSRP,
					value: counterVariabilityThreshold,
				}),
				0,
			),
		]);
	}

	if (acceptRangeValue) {
		values.push([
			acceptThreshold,
			formatMerchantCurrency(
				getAmount({
					max: productMSRP,
					value: acceptThreshold,
				}),
				0,
			),
		]);
	}

	if (productMSRP !== productPrice) {
		values.push([
			0,
			formatMerchantCurrency(
				getAmount({
					max: productMSRP,
					value: 0,
				}),
				0,
			),
		]);
	}

	return (
		<Box>
			<PercentLabels values={values} />
			<Box sx={{ height: '2.5rem' }}>
				<Box
					sx={{
						height: '2.5rem',
						width: '100%',
						display: 'flex',
					}}
				>
					<Box
						sx={{
							zIndex: 1000,
							width: `${counterRangeValue}%`,
							height: '100%',
							backgroundColor: 'counter.main',
							'&:hover': {
								cursor: 'pointer',
								backgroundColor: 'counter.accented',
							},
							display: 'flex',
						}}
						onMouseEnter={() => setShowCounterInfoText(true)}
						onMouseLeave={() => setShowCounterInfoText(false)}
					>
						<InfoBox showText={showCounterInfoTest}>
							All customer offers between{' '}
							{formatMerchantCurrency(1, 0)} and{' '}
							{formatMerchantCurrency(
								getAmount({
									max: productMSRP,
									value: counterOfferThreshold,
								}),
								0,
							)}{' '}
							will be countered
						</InfoBox>
					</Box>

					{counterOfferVariabilityValue ? (
						<Box
							sx={{
								zIndex: 1000,
								width: `${counterOfferVariabilityValue}%`,
								height: '100%',
								backgroundColor: 'variability.main',
								'&:hover': {
									cursor: 'pointer',
									backgroundColor: 'variability.accented',
								},
							}}
							onMouseEnter={() =>
								setShowCounterVariabilityInfoText(true)
							}
							onMouseLeave={() =>
								setShowCounterVariabilityInfoText(false)
							}
						>
							<InfoBox showText={showCounterVariabilityInfoTest}>
								Offers will be countered to the amount within range{' '}
								{formatMerchantCurrency(
									getAmount({
										max: productMSRP,
										value: counterOfferThreshold,
									}),
									0,
								)}
								-
								{formatMerchantCurrency(
									getAmount({
										max: productMSRP,
										value: counterVariabilityThreshold,
									}),
									0,
								)}
								. That amount is calculated based on the original
								offer amount
							</InfoBox>
						</Box>
					) : null}

					{acceptRangeValue ? (
						<Box
							sx={{
								zIndex: 1000,
								width: `${acceptRangeValue}%`,
								height: '100%',
								backgroundColor: 'accept.main',
								'&:hover': {
									cursor: 'pointer',
									backgroundColor: 'accept.accented',
								},
							}}
							onMouseEnter={() => setShowAcceptInfoText(true)}
							onMouseLeave={() => setShowAcceptInfoText(false)}
						>
							<InfoBox showText={showAcceptInfoTest}>
								All offers between{' '}
								{formatMerchantCurrency(
									getAmount({
										max: productMSRP,
										value: counterVariabilityThreshold,
									}),
									0,
								)}{' '}
								and{' '}
								{formatMerchantCurrency(
									getAmount({
										max: productMSRP,
										value: acceptThreshold,
									}),
									0,
								)}{' '}
								will be accepted
							</InfoBox>
						</Box>
					) : null}
					{msrpRangeValue ? (
						<Box
							sx={{
								width: `${msrpRangeValue}%`,
								height: '100%',
								backgroundColor: 'unused.main',
								'&:hover': {
									cursor: 'pointer',
									backgroundColor: 'unused.accented',
								},
							}}
							onMouseEnter={() => setShowMsrpInfoBox(true)}
							onMouseLeave={() => setShowMsrpInfoBox(false)}
						>
							<InfoBox showText={showMsrpInfoBox}>
								This is already applied discount to the product with
								“Compare-at-price” in Shopify. The larger this area
								less efficient campaign will be
							</InfoBox>
						</Box>
					) : null}
				</Box>
			</Box>
			<MoneyLabels max={productMSRP} values={values} />
		</Box>
	);
}

CampaignConfigsProductBarExample.propTypes = {
	productPrice: PropTypes.number.isRequired,
	productMSRP: PropTypes.number.isRequired,
	counterOfferThreshold: PropTypes.number.isRequired,
	counterOfferVariability: PropTypes.number.isRequired,
};

export default CampaignConfigsProductBarExample;
