import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import addDays from 'date-fns/addDays';
import Container from '@mui/material/Container';

import Form from './components/Form';

import { useSellingPlanGroupUpdate } from '../../../../hooks/useSellingPlanGroups';
import useForm from '../../../../hooks/useForm';
import { CACHE_KEY as VARIANTS_CACHE_KEY } from '../../../../hooks/useVariants';

import {
	formatValues,
	variantGqlToNumericIdString,
	formatDescription,
	validationConfig,
} from './helpers';

import constants from '../../../../constants';
import { GAevent } from '../../../../lib/googleAnalytics';

function UpdateSellingPlanGroup({
	sellingPlanGroup,
	sellingPlanId,
	description,
	deposit,
	remainingBalanceChargeDate,
	fulfillment,
	inventory,
	variants,
	name,
}) {
	const queryClient = useQueryClient();

	const {
		mutate,
		isLoading: loading,
		error,
	} = useSellingPlanGroupUpdate({ id: sellingPlanGroup.id });

	const {
		handleSubmit,
		handleChange,
		data: updateSellingPlanGroup,
		errors,
	} = useForm({
		initialValues: {
			...sellingPlanGroup,
			sellingPlanId,
			description,
			deposit,
			remainingBalanceChargeDate,
			fulfillment,
			inventory,
			variants: variantGqlToNumericIdString(variants),
			name,
		},
		validations: validationConfig,
		onSubmit: async () => {
			try {
				const {
					id,
					sellingPlanId: spId,
					productVariantIds,
					sellingPlanGroupName,
					remainingBalanceChargeTrigger,
					remainingBalanceChargeTime,
					deposit: customerDepositPercentage,
					fulfillmentTrigger,
					inventoryReservation,
					sellingPlanDescription,
				} = formatValues(updateSellingPlanGroup);

				GAevent({
					categoryName: 'Update Campaign Form',
					eventName: 'Submit Form',
					transport: 'beacon',
				});

				mutate(
					{
						id,
						sellingPlanId: spId,
						productVariantIds,
						sellingPlanGroupName,
						remainingBalanceChargeTrigger,
						remainingBalanceChargeTime,
						deposit: customerDepositPercentage,
						fulfillmentTrigger,
						inventoryReservation,
						sellingPlanDescription,
						name,
					},
					{
						onSuccess: () => {
							// Makes sure that all the variants in the inventory review
							// page are pulled again since this query has side effects in
							// The inventory review page
							queryClient.invalidateQueries({
								predicate: (query) =>
									query?.queryKey[0] === VARIANTS_CACHE_KEY,
							});
						},
					},
				);
			} catch (err) {
				console.error(err);
			}
		},
	});

	return (
		<Container component="main" maxWidth="md">
			<Form
				values={updateSellingPlanGroup}
				titleText="Update Campaign"
				buttonText="Update"
				handleSubmit={handleSubmit}
				handleChange={handleChange}
				loading={loading}
				errors={errors}
				fetchError={error?.data}
			/>
		</Container>
	);
}

UpdateSellingPlanGroup.propTypes = {
	sellingPlanGroup: PropTypes.shape({
		id: PropTypes.string,
	}),
	sellingPlanId: PropTypes.string,
	description: PropTypes.string,
	deposit: PropTypes.number,
	remainingBalanceChargeDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
	]),
	fulfillment: PropTypes.string,
	inventory: PropTypes.string,
	variants: PropTypes.arrayOf(PropTypes.string),
	name: PropTypes.string,
};

UpdateSellingPlanGroup.defaultProps = {
	sellingPlanGroup: {
		id: '',
		productVariantIds: [],
		sellingPlanGroupName: '',
		remainingBalanceChargeTrigger: '',
		remainingBalanceChargeTime: null,
		deposit: '',
		fulfillmentTrigger: '',
		inventoryReservation: '',
		sellingPlanDescription: '',
	},
	sellingPlanId: '',
	description: '',
	deposit: constants.deferredPurchaseOptions.depositValue.NoDeposit,
	remainingBalanceChargeDate: addDays(new Date(), 14),
	fulfillment:
		constants.deferredPurchaseOptions.sellingPlanFulfillmentTrigger
			.ExactTime,
	inventory:
		constants.deferredPurchaseOptions.sellingPlanReserve.OnSale,
	variants: '',
	name: formatDescription({
		date: addDays(new Date(), 14),
		deposit: constants.deferredPurchaseOptions.depositValue.NoDeposit,
	}),
};

export default UpdateSellingPlanGroup;
