import React from 'react';

import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';

import InfoPopoverIcon from '@app/components/shared/ui/InfoPopoverIcon';
import ErrorMessage from '@app/components/shared/ui/ErrorMessage';

import {
	useCollections,
	useSyncCollection,
} from '@app/hooks/useCollections';

function SyncCollection() {
	const [selectedCollection, setSelectedCollection] =
		React.useState('');

	const { data, isLoading, isError } = useCollections({
		cacheKey: '',
		queryVariables: { query: '' },
		configOpts: {
			staleTime: 0, // Fetch fresh data
			cacheTime: 1000 * 60,
		},
	});

	const {
		mutate: syncCollection,
		isLoading: isSyncing,
		error: syncError,
	} = useSyncCollection({
		onSuccess: () => {
			setSelectedCollection('');
		},
	});

	const handleSync = () => {
		if (selectedCollection) {
			syncCollection({ collectionId: selectedCollection });
		}
	};

	return (
		<Box>
			<Box>
				<Typography
					sx={{
						fontSize: '1.25rem',
						fontWeight: 500,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					Sync Your Product Collection
					<InfoPopoverIcon
						text="Products and Collections sync process might take about 10 minutes. During that time you are not recommended to create or edit campaigns"
						iconStyles={{ ml: 1 }}
					/>
				</Typography>
				<Typography
					sx={{
						mt: 1,
						mb: 2,
						color: 'pending.main',
						fontSize: 14,
					}}
				>
					Missing products in your collection? Quickly sync it here
					without having to resync your entire inventory.
				</Typography>
			</Box>
			<Grid
				container
				spacing={2}
				alignItems="center"
				sx={{ mb: 3, maxWidth: '500px' }}
			>
				<Grid item xs={12} sm={8}>
					<FormControl fullWidth size="medium">
						<InputLabel id="collection-select-label">
							Select Collection
						</InputLabel>
						<Select
							labelId="collection-select-label"
							id="collection-select"
							label="Select a Collection"
							variant="outlined"
							value={selectedCollection}
							onChange={(e) => setSelectedCollection(e.target.value)}
						>
							{isLoading && (
								<MenuItem disabled>
									<CircularProgress size={24} />
								</MenuItem>
							)}

							{isError && (
								<MenuItem disabled>
									<Typography color="error">
										Error loading collections
									</Typography>
								</MenuItem>
							)}

							{!isLoading &&
								!isError &&
								data?.collections?.map((collection) => (
									<MenuItem key={collection.id} value={collection.id}>
										{collection.title}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={4}>
					<LoadingButton
						fullWidth
						variant="contained"
						loading={isSyncing}
						disabled={!selectedCollection}
						onClick={handleSync}
					>
						Sync
					</LoadingButton>
				</Grid>
			</Grid>
			{syncError && (
				<ErrorMessage
					message="There was a problem syncing your Collection. Please try again"
					sx={{ mb: 3, mt: -2 }}
				/>
			)}
		</Box>
	);
}

export default SyncCollection;
