import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import * as merchantApi from '../api/merchant';
import * as segmentationApi from '../api/segmentation';
import useAuth from '@app/hooks/useAuth';

export const CACHE_KEY = 'merchants';

export function usePowerUpBilling({ configOpts = {} } = {}) {
	return useMutation(merchantApi.powerUpBilling, {
		...configOpts,
	});
}

export function useBillingTransactions({
	configOpts = {},
	queryVariables = {},
} = {}) {
	const { merchant } = useAuth();

	const cacheKey = `${merchant.id}|${queryVariables.page}|${queryVariables.perPage}|${queryVariables.sortKey}|${queryVariables.getCount}`;
	return useQuery({
		queryKey: [CACHE_KEY, 'billing', cacheKey],
		queryFn: () => merchantApi.getBillingTransactions(queryVariables),
		...configOpts,
	});
}

export function useBillingBalance({ configOpts = {} } = {}) {
	return useQuery({
		queryKey: [CACHE_KEY, 'billing', 'balance'],
		queryFn: () => merchantApi.getBillingBalance(),
		...configOpts,
	});
}

export function useIntegrations({ configOpts = {} } = {}) {
	return useQuery({
		queryKey: [CACHE_KEY, 'integrations'],
		queryFn: () => merchantApi.getIntegrations(),
		...configOpts,
	});
}

export function useIntegration({
	integration,
	configOpts = {},
} = {}) {
	return useQuery({
		queryKey: [CACHE_KEY, 'integrations', integration],
		queryFn: () => merchantApi.getIntegration(integration),
		...configOpts,
	});
}

export function useCreateIntegration({ configOpts = {} } = {}) {
	return useMutation(merchantApi.createIntegration, {
		...configOpts,
	});
}

export function useUpdateIntegration({ configOpts = {} } = {}) {
	return useMutation(merchantApi.updateIntegration, {
		...configOpts,
	});
}

export function useSegments({ integration, configOpts = {} } = {}) {
	return useQuery({
		queryKey: [CACHE_KEY, 'segments', integration],
		queryFn: () => segmentationApi.getSegments(integration),
		...configOpts,
	});
}

export function useProductSync({ configOpts = {} } = {}) {
	return useQuery({
		queryKey: [CACHE_KEY, 'product-sync'],
		queryFn: () => merchantApi.getProductSync(),
		...configOpts,
	});
}

export function useStartProductSync({ configOpts = {} } = {}) {
	return useMutation(merchantApi.startProductSync, {
		...configOpts,
	});
}

export function useUpdateNyopCounterOfferItemSku({
	configOpts = {},
} = {}) {
	return useMutation(merchantApi.updateNyopCounterOfferItemSku, {
		...configOpts,
	});
}

export function useBillingTiers({ configOpts = {} } = {}) {
	return useQuery({
		queryKey: [CACHE_KEY, 'tiers'],
		queryFn: () => merchantApi.getBillingTiers(),
		...configOpts,
	});
}

export function useSelectBillingTier({ configOpts = {} } = {}) {
	const { refreshUser, activeSubscription } = useAuth();

	const selectBillingTierMutation = useMutation(
		merchantApi.selectBillingTier,
		{
			...configOpts,
			onSuccess: async () => {
				const intervalId = setInterval(async () => {
					const newUser = await refreshUser();
					const newSubscriptionId =
						newUser?.merchants?.[0]?.active_subscription?.id;

					if (newSubscriptionId !== activeSubscription?.id) {
						clearInterval(intervalId);
					} // clear interval if new subscription is applied
				}, 5000); // 5 sec

				setTimeout(() => {
					clearInterval(intervalId);
				}, 300000); // 5 min

				selectBillingTierMutation.reset = () =>
					clearInterval(intervalId);
			},
		},
	);

	return selectBillingTierMutation;
}

export function useCancelSubscription({ configOpts = {} } = {}) {
	const { refreshUser } = useAuth();
	const queryClient = useQueryClient();

	return useMutation(merchantApi.cancelSubscription, {
		onSuccess: async () => {
			await queryClient.invalidateQueries([CACHE_KEY, 'tiers']);
			await refreshUser();
		},
		...configOpts,
	});
}

export function useGetMaxOffersPerOrder({ configOpts = {} } = {}) {
	return useQuery({
		queryKey: [CACHE_KEY, 'maxOffersPerOrder'],
		queryFn: () => merchantApi.getMaxOffersPerOrder(),
		...configOpts,
	});
}

export function useSetMaxOffersPerOrder({ configOpts = {} } = {}) {
	return useMutation(merchantApi.setMaxOffersPerOrder, {
		...configOpts,
	});
}

export function useCounterOfferExpiryDays({ configOpts = {} } = {}) {
	return useQuery({
		queryKey: [CACHE_KEY, 'counterOffersExpiryDays'],
		queryFn: () => merchantApi.getCounterOfferExpiryDays(),
		...configOpts,
	});
}

export function useSetCounterOfferExpiryDays({
	configOpts = {},
} = {}) {
	return useMutation(merchantApi.setCounterOfferExpiryDays, {
		...configOpts,
	});
}
