import axios from './index';
import * as urlHelpers from '../helpers/urlHelpers';
import { cleanAndBuildUrlQueryParams } from '../helpers/urlHelpers';

const apiUrl = '/api/campaigns/nyop';

export async function get({ state, page, search, sort }) {
	const queryString = cleanAndBuildUrlQueryParams({
		state,
		page,
		search,
		sort,
	});
	const response = await axios.get(`${apiUrl}?${queryString}`);
	return response.data;
}

export async function getById({ id }) {
	const encodedUrlParam = encodeURIComponent(id);
	const response = await axios.get(`${apiUrl}/${encodedUrlParam}`);
	return response.data;
}

export async function getFinalizationProgress({ id }) {
	const encodedUrlParam = encodeURIComponent(id);
	const response = await axios.get(
		`${apiUrl}/${encodedUrlParam}/progress`,
	);
	return response.data;
}

export async function create({
	productVariantIds,
	sellingPlanGroupName,
	sellingPlanDescription,
	startDate,
	endDate,
	automationRules,
	extendChargeDateWithCounterDelay,
	customerSegmentationVisibility,
	startingOfferInfo,
}) {
	const response = await axios.post(apiUrl, {
		productVariantIds,
		sellingPlanGroupName,
		sellingPlanDescription,
		extendChargeDateWithCounterDelay,
		startDate,
		endDate,
		automationRules,
		customerSegmentationVisibility,
		startingOfferInfo,
	});

	return response.data;
}

export async function update({
	id,
	productVariantIds,
	sellingPlanGroupName,
	sellingPlanDescription,
	extendChargeDateWithCounterDelay,
	customerSegmentationVisibility,
	automationRules,
	startDate,
	endDate,
	startingOfferInfo,
}) {
	const encodedUrlParam = encodeURIComponent(id);
	const response = await axios.patch(`${apiUrl}/${encodedUrlParam}`, {
		id,
		productVariantIds,
		sellingPlanGroupName,
		sellingPlanDescription,
		extendChargeDateWithCounterDelay,
		customerSegmentationVisibility,
		automationRules,
		startDate,
		endDate,
		startingOfferInfo,
	});

	return response.data;
}

export async function del({ id }) {
	const encodedUrlParam = encodeURIComponent(id);
	const response = await axios.delete(`${apiUrl}/${encodedUrlParam}`);

	return response.data;
}

export async function end({ id }) {
	const encodedUrlParam = encodeURIComponent(id);
	const response = await axios.post(
		`${apiUrl}/${encodedUrlParam}/end`,
	);

	return response.data;
}

export async function close({ id }) {
	const encodedUrlParam = encodeURIComponent(id);
	const response = await axios.post(
		`${apiUrl}/${encodedUrlParam}/close`,
	);

	return response.data;
}

export async function finalize({ id, shouldRecreate = false }) {
	const encodedUrlParam = encodeURIComponent(id);
	const response = await axios.post(
		`${apiUrl}/${encodedUrlParam}/finalize`,
		{ recreate: shouldRecreate },
	);

	return response.data;
}

export async function getProduct({ productId }) {
	const encodedProductId = encodeURIComponent(productId);
	const response = await axios.get(
		`${apiUrl}/products/${encodedProductId}`,
	);
	return response.data;
}

export async function getCampaignProductById({ id, productId }) {
	const encodedId = encodeURIComponent(id);
	const encodedProductId = encodeURIComponent(productId);
	const response = await axios.get(
		`${apiUrl}/${encodedId}/products/${encodedProductId}`,
	);
	return response.data;
}

export async function getVariant({ variantId }) {
	const encodedVariantId = encodeURIComponent(variantId);
	const response = await axios.get(
		`${apiUrl}/variants/${encodedVariantId}`,
	);
	return response.data;
}

export async function getCampaignVariantById({ id, variantId }) {
	const encodedId = encodeURIComponent(id);
	const encodedVariantId = encodeURIComponent(variantId);
	const response = await axios.get(
		`${apiUrl}/${encodedId}/variants/${encodedVariantId}`,
	);
	return response.data;
}

export async function getCampaignVariants({ id }) {
	const encodedId = encodeURIComponent(id);
	const response = await axios.get(`${apiUrl}/${encodedId}/variants`);
	return response.data;
}

export async function checkNyopDates({ id, startDate, endDate }) {
	const queryString = urlHelpers.cleanAndBuildUrlQueryParams({
		startDate,
		endDate,
		id,
	});
	const response = await axios.get(`${apiUrl}/check?${queryString}`);
	return response.data;
}

export async function getNYOPProductById({ productId }) {
	const encodedProductId = encodeURIComponent(productId);
	const response = await axios.get(
		`${apiUrl}/products/${encodedProductId}`,
	);
	return response.data;
}

export async function getNYOPProducts({
	page,
	perPage,
	sortKey,
	status,
	searchTerm,
	getCount,
	collections,
}) {
	const queryString = urlHelpers.cleanAndBuildUrlQueryParams({
		page,
		perPage,
		sortKey,
		status,
		searchTerm,
		collections,
		getCount,
	});

	const response = await axios.get(
		`${apiUrl}/products?${queryString}`,
	);
	return response.data;
}

export async function getCampaignProducts({
	campaignId,
	page,
	perPage,
	sortKey,
	status,
	searchTerm,
	getCount,
	collections,
}) {
	const encodedId = encodeURIComponent(campaignId);
	const queryString = urlHelpers.cleanAndBuildUrlQueryParams({
		page,
		perPage,
		sortKey,
		status,
		searchTerm,
		collections,
		getCount,
	});

	const response = await axios.get(
		`${apiUrl}/${encodedId}/products?${queryString}`,
	);
	return response.data;
}

export async function recreate({ id }) {
	const encodedUrlParam = encodeURIComponent(id);
	const response = await axios.post(
		`${apiUrl}/${encodedUrlParam}/recreate`,
	);

	return response.data;
}
