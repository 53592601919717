import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { SnackbarProvider } from 'notistack';
import QueryClientProvider from './providers/QueryClientProvider';
import LocalizationProvider from './providers/LocalizationProvider';
import HelmetProvider from './providers/HelmetProvider';
import ErrorBoundary from './providers/ErrorBoundary';

import {
	SnackbarUtilsConfigurator,
	SnackbarComponents,
} from './lib/SnackbarUtils';
import EventBusProvider from './hooks/useEventBus'; // Passes logout events to other context providers
import { CookieConsentProvider } from './hooks/useCookieConsent';
import { AuthProvider } from './hooks/useAuth';
import SearchParamsProvider from './hooks/useSearchParams'; // Need campaign search params across multiple pages
import { InventoryReviewProvider } from './hooks/useInventoryReviewContext';
import { CreateCampaignProvider } from './hooks/useCreateCampaignContext';
import App from './App';

import theme from './styles/theme';
import FlagsProvider from '@app/providers/FlagsProvider';

const root = createRoot(document.querySelector('#root'));

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<EventBusProvider>
				<ThemeProvider theme={theme}>
					{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
					<CssBaseline />
					<SnackbarProvider
						maxSnack={3}
						autoHideDuration={10000}
						preventDuplicate
						Components={SnackbarComponents}
					>
						<SnackbarUtilsConfigurator />
						<QueryClientProvider>
							<LocalizationProvider>
								<HelmetProvider>
									<CookieConsentProvider>
										<AuthProvider>
											<FlagsProvider>
												<ErrorBoundary>
													<InventoryReviewProvider>
														<CreateCampaignProvider>
															<SearchParamsProvider>
																<App />
															</SearchParamsProvider>
														</CreateCampaignProvider>
													</InventoryReviewProvider>
												</ErrorBoundary>
											</FlagsProvider>
										</AuthProvider>
									</CookieConsentProvider>
								</HelmetProvider>
							</LocalizationProvider>
						</QueryClientProvider>
					</SnackbarProvider>
				</ThemeProvider>
			</EventBusProvider>
		</BrowserRouter>
	</React.StrictMode>,
);
