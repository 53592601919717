import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function StatusBadge({ buttonText, color, sx, textColor }) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Typography
				variant="subtitle1"
				sx={{
					fontSize: '14px',
					bgcolor: color,
					borderRadius: '100px',
					padding: '3px 10px',
					...sx,
				}}
				color={textColor || '#fff'}
			>
				{buttonText}
			</Typography>
		</Box>
	);
}

StatusBadge.propTypes = {
	buttonText: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
	textColor: PropTypes.string,
	sx: PropTypes.shape({}),
};

StatusBadge.defaultProps = {
	textColor: null,
	sx: {},
};

export default StatusBadge;
