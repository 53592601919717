import * as React from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import Box from '@mui/material/Box';
import OffersTable from '@app/components/muiTable/offers';
import Loader from '@app/components/shared/loaders/CircularLoader';
// import NYOPVariantSelector from '@app/components/campaigns/NYOPVariantSelector';
import { useNyopCampaignProduct } from '@app/hooks/useNyopCampaign';
import NYOPProductSummary from '@app/components/nyop/ProductSummary';

function NYOPProductDetails() {
	// const navigate = useNavigate();
	const { id, productId } = useParams();

	const { data, isLoading, isFetching } = useNyopCampaignProduct({
		id,
		productId,
	});

	// const handleVariantChange = (event) => {
	// 	const { value } = event.target;
	//
	// 	const url = `/nyop/products/${encodeURIComponent(productId)}`;
	// 	if (value) {
	// 		navigate(`${url}/variants/${encodeURIComponent(value)}`);
	// 	} else {
	// 		navigate(url);
	// 	}
	// };

	if (isLoading) return <Loader fullPage />;

	const { offers, product, campaign } = data.data;

	return (
		<>
			<Box
				mb={2}
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				{/* <NYOPVariantSelector */}
				{/*	campaignId={id} */}
				{/*	onSelect={handleVariantChange} */}
				{/* /> */}
			</Box>
			<Grid container rowSpacing={2}>
				<Grid item md={12}>
					<NYOPProductSummary
						product={product}
						offers={offers}
						campaign={campaign}
					/>
				</Grid>
				<Grid item md={12}>
					<OffersTable
						parentRef={productId}
						offers={offers}
						isFetching={isFetching}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default NYOPProductDetails;
