import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import * as nyopApi from '../api/nyopOffers';
import { CACHE_KEY } from './useNyopCampaign';
import { getGqlId } from '../lib/shopify';
import constants from '../constants/index';
import useAuth from '@app/hooks/useAuth';

export function useNyopOffersAccept({
	campaignId,
	configOpts = {},
} = {}) {
	const queryClient = useQueryClient();
	const shopifyId = getGqlId(
		campaignId,
		constants.shopifyObjectPrefix.SELLING_PLAN_GROUP,
	);
	return useMutation(nyopApi.accept, {
		onSettled: () =>
			queryClient.invalidateQueries([CACHE_KEY, shopifyId]),
		...configOpts,
	});
}

export function useNyopOffersCounter({
	campaignId,
	configOpts = {},
} = {}) {
	const queryClient = useQueryClient();
	const shopifyId = getGqlId(
		campaignId,
		constants.shopifyObjectPrefix.SELLING_PLAN_GROUP,
	);

	return useMutation(nyopApi.counter, {
		onSettled: () =>
			queryClient.invalidateQueries([CACHE_KEY, shopifyId]),
		...configOpts,
	});
}

export function useNyopOffersRespond({
	queryKey,
	configOpts = {},
} = {}) {
	const queryClient = useQueryClient();

	return useMutation(nyopApi.respond, {
		onSettled: () => queryClient.invalidateQueries(queryKey),
		...configOpts,
	});
}

export function useNyopInboxCount({ configOpts = {} } = {}) {
	const { merchant } = useAuth();

	return useQuery(
		[CACHE_KEY, merchant.shop, 'count'],
		nyopApi.getInboxCount,
		configOpts,
	);
}

export function useNyopInboxOrders({
	configOpts = {},
	queryKey,
	queryVariables,
} = {}) {
	return useQuery({
		queryKey,
		queryFn: () => nyopApi.getInboxOrders(queryVariables),
		refetchInterval: 60000,
		...configOpts,
	});
}

export function useNyopInboxOrder({
	queryKey,
	configOpts = {},
	orderId,
} = {}) {
	const { merchant } = useAuth();

	return useQuery({
		queryKey: queryKey || [
			CACHE_KEY,
			merchant.shop,
			'inbox',
			orderId,
		],
		queryFn: () => nyopApi.getInboxOrder({ orderId }),
		refetchInterval: 60000,
		...configOpts,
	});
}
