import * as React from 'react';
import PropTypes from 'prop-types';
import { Scatter } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import constants from '@app/constants/index';

ChartJS.register(
	LinearScale,
	PointElement,
	LineElement,
	Tooltip,
	Legend,
	annotationPlugin,
);

const OFFER_STATUS_COLORS = {
	canceled: constants.colors.failure,
	accepted: constants.colors.primary,
	countered: constants.colors.tertiary,
	pending: constants.colors.disabled,
};

function OffersChart({
	offers,
	formatMerchantCurrency,
	msrp = null,
	thresholdPrice = null,
}) {
	const sortedOffers = offers.sort((a, b) => {
		const amountA = parseFloat(
			a.shop_currency_counter_offer_amount ||
				a.shop_currency_offer_amount,
		);
		const amountB = parseFloat(
			b.shop_currency_counter_offer_amount ||
				b.shop_currency_offer_amount,
		);
		return amountA >= amountB ? -1 : 1;
	});

	const dataAndColors = sortedOffers.map((offer, index) => {
		let status;

		if (offer.canceled_at) {
			status = 'canceled';
		} else if (offer.accepted_at || offer.counter_offer_accepted_at) {
			status = 'accepted';
		} else if (offer.counter_offer_created_at) {
			status = 'countered';
		} else {
			status = 'pending';
		}

		return {
			x: index + 1,
			y: parseFloat(
				offer.shop_currency_counter_offer_amount ||
					offer.shop_currency_offer_amount,
			),
			pointBackgroundColor: OFFER_STATUS_COLORS[status],
		};
	});

	const chartData = {
		labels: sortedOffers.map((offer) => offer.id),
		datasets: [
			{
				label: 'Accepted Offers',
				data: dataAndColors,
				showLine: true,
				pointRadius: 8,
				pointBorderColor: 'black',
				pointBorderWidth: 2,
				backgroundColor: OFFER_STATUS_COLORS.accepted,
				pointBackgroundColor: dataAndColors.map(
					(d) => d.pointBackgroundColor,
				),
			},
			{
				label: 'Countered Offers',
				backgroundColor: OFFER_STATUS_COLORS.countered,
			},
			{
				label: 'Pending Offers',
				backgroundColor: OFFER_STATUS_COLORS.pending,
			},
			{
				label: 'Lost Offers',
				backgroundColor: OFFER_STATUS_COLORS.canceled,
			},
		],
	};

	const options = {
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					callback: (v) => formatMerchantCurrency(v),
				},
			},
			x: {
				ticks: {
					callback: (v) => (Number.isInteger(v) ? v : null),
				},
			},
		},
		plugins: {
			legend: {
				onClick: null,
			},
			annotation: {
				annotations: {
					...(msrp && !Number.isNaN(msrp) && msrp > 0
						? {
								msrpLine: {
									type: 'line',
									mode: 'horizontal',
									yMin: msrp,
									yMax: msrp,
									borderColor: 'rgba(0,0,0,0.4)',
									borderWidth: 2,
									borderDash: [10, 5],
									label: {
										display: true,
										content: `MSRP: ${formatMerchantCurrency(msrp)}`,
										enabled: true,
										position: 'start',
									},
								},
						  }
						: {}),
					...(thresholdPrice
						? {
								thresholdLine: {
									type: 'line',
									mode: 'horizontal',
									yMin: thresholdPrice,
									yMax: thresholdPrice,
									borderColor: 'rgba(0,0,0,0.4)',
									borderWidth: 2,
									borderDash: [10, 5],
									label: {
										display: true,
										content: `Threshold: ${formatMerchantCurrency(
											thresholdPrice,
										)}`,
										enabled: true,
										position: 'end',
									},
								},
						  }
						: {}),
				},
			},
		},
	};

	return <Scatter data={chartData} options={options} />;
}

OffersChart.propTypes = {
	formatMerchantCurrency: PropTypes.func.isRequired,
	offers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			shop_currency_offer_amount: PropTypes.string.isRequired,
			shop_currency_counter_offer_amount: PropTypes.string,
			accepted_at: PropTypes.string,
			counter_offer_created_at: PropTypes.string,
			counter_offer_accepted_at: PropTypes.string,
			canceled_at: PropTypes.string,
		}),
	).isRequired,
	msrp: PropTypes.number,
	thresholdPrice: PropTypes.number,
};

export default OffersChart;
