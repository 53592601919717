import React from 'react';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

function XButton({ deleteCallback }) {
	return (
		<IconButton
			sx={{
				mr: -3,
			}}
			onClick={deleteCallback}
		>
			<ClearIcon />
		</IconButton>
	);
}

XButton.propTypes = {
	deleteCallback: PropTypes.func.isRequired,
};

export default XButton;
