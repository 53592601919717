import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import SyncAllProducts from './SyncAllProducts';
import SyncCollection from './SyncCollection';

function ProductSyncSetting() {
	return (
		<Box>
			<SyncAllProducts />
			<Divider
				fullWidth
				sx={{
					mt: 0.5,
					mb: 3,
				}}
			/>
			<SyncCollection />
			<Divider
				fullWidth
				sx={{
					mt: 0.5,
					mb: 3,
				}}
			/>
		</Box>
	);
}

export default ProductSyncSetting;
