import PropTypes from 'prop-types';
import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@app/components/shared/button/Button';

function SettingContainer({
	title,
	subtitle,
	helperText,
	value,
	onChangeValue,
	onBlurInput,
	inputType,
	inputName,
	inputLabel,
	placeholder,
	onClick,
	buttonText,
	isLoading,
	errors,
}) {
	const filteredErrors = errors.filter(Boolean);
	const errorText = filteredErrors[0];

	return (
		<>
			<Box>
				{title ? (
					<Typography
						sx={{
							fontSize: '1.25rem',
							fontWeight: 500,
							marginBottom: 2,
						}}
					>
						{title}
					</Typography>
				) : null}
				{subtitle ? <Typography>{subtitle}</Typography> : null}
				<Grid
					container
					columnSpacing={2}
					sx={{ alignItems: 'center', mt: 2 }}
				>
					<Grid item md={3}>
						<TextField
							name={inputName}
							label={inputLabel}
							value={value.toString()}
							type={inputType}
							onChange={onChangeValue}
							onBlur={onBlurInput}
							placeholder={placeholder}
							error={!!errorText}
							sx={{ width: '100%' }}
						/>
					</Grid>
					<Grid item>
						<Button onClick={onClick} loading={isLoading}>
							{buttonText}
						</Button>
					</Grid>
				</Grid>
			</Box>
			{(errorText || helperText) && (
				<FormHelperText
					sx={{
						color: errorText ? 'error.main' : 'text.secondary',
						marginTop: -2,
					}}
				>
					{errorText || helperText}
				</FormHelperText>
			)}
			<Divider
				fullWidth
				sx={{
					mt: helperText ? 0 : 3,
					mb: 3,
				}}
			/>
		</>
	);
}

SettingContainer.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	helperText: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChangeValue: PropTypes.func,
	onBlurInput: PropTypes.func,
	inputType: PropTypes.string,
	inputName: PropTypes.string,
	inputLabel: PropTypes.string,
	placeholder: PropTypes.string,
	onClick: PropTypes.func,
	buttonText: PropTypes.string,
	isLoading: PropTypes.bool,
	errors: PropTypes.arrayOf(PropTypes.string),
};

SettingContainer.defaultProps = {
	title: '',
	subtitle: '',
	helperText: '',
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChangeValue: () => null,
	onBlurInput: () => null,
	inputType: 'text',
	inputName: 'settingInput',
	inputLabel: '',
	placeholder: '',
	onClick: () => null,
	buttonText: 'Update',
	isLoading: false,
	errors: [],
};

export default SettingContainer;
