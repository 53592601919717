import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

import Button from '../button/Button';

function ConfirmationModal({
	handleClick,
	handleCancelClick,
	prompt,
	subPrompt,
	textBullets,
	buttonText,
	cancelButtonText,
	isLoading,
	isError,
	errorMessage,
}) {
	return (
		<Box id="confirmation-modal">
			<Typography
				variant="h6"
				sx={{ fontWeight: 'bold', textAlign: 'center' }}
			>
				{prompt}
			</Typography>
			{subPrompt && (
				<Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
					{subPrompt}
				</Typography>
			)}
			{textBullets && textBullets.length > 0 && (
				<List sx={{ mb: 2 }}>
					{textBullets.map((bullet) => (
						<ListItemText
							key={bullet.text}
							primary={bullet.text}
							sx={{
								padding: 0,
								marginLeft: '20px',
								listStyleType: 'disc',
								display: 'list-item',
							}}
						/>
					))}
				</List>
			)}
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-around"
				flexDirection="row"
				mt={3}
			>
				{cancelButtonText && (
					<Button color="secondary" onClick={handleCancelClick}>
						{cancelButtonText}
					</Button>
				)}
				<Button
					loading={isLoading}
					color="primary"
					onClick={handleClick}
				>
					{buttonText}
				</Button>
			</Box>
			{isError && (
				<Typography
					sx={{ textAlign: 'center', marginTop: 2 }}
					component="p"
					color="error"
				>
					{errorMessage || 'A problem occurred. Please try again.'}
				</Typography>
			)}
		</Box>
	);
}

ConfirmationModal.propTypes = {
	handleClick: PropTypes.func.isRequired,
	handleCancelClick: PropTypes.func,
	prompt: PropTypes.string.isRequired,
	subPrompt: PropTypes.string,
	textBullets: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
		}),
	),
	buttonText: PropTypes.string.isRequired,
	cancelButtonText: PropTypes.string,
	isLoading: PropTypes.bool,
	isError: PropTypes.bool,
	errorMessage: PropTypes.string,
};

ConfirmationModal.defaultProps = {
	subPrompt: '',
	textBullets: [],
	isLoading: false,
	isError: false,
	errorMessage: '',
	cancelButtonText: undefined,
	handleCancelClick: () => {},
};

export default ConfirmationModal;
