import PropTypes from 'prop-types';
import React from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';

import ErrorMessage from '@app/components/shared/ui/ErrorMessage';
import Loader from '@app/components/shared/loaders/CircularLoader';

function SettingCheckbox({
	title,
	subtitle,
	checkboxText,
	helperText,
	checked,
	onChange,
	error,
	isLoading,
}) {
	return (
		<>
			<Box>
				<Typography
					sx={{
						fontSize: '1.25rem',
						fontWeight: 500,
						marginBottom: 2,
					}}
				>
					{title}
				</Typography>
				<Typography>{subtitle}</Typography>
				<Grid
					container
					columnSpacing={2}
					sx={{ alignItems: 'center', mt: 2 }}
				>
					<Grid item>
						<FormControlLabel
							label={
								<Typography sx={{ fontSize: 14 }}>
									{checkboxText}
								</Typography>
							}
							control={
								isLoading ? (
									<Box
										marginRight={1.4}
										marginTop={1.25}
										marginLeft={1.35}
										marginBottom={0.7}
									>
										<Loader size={20} />
									</Box>
								) : (
									<Checkbox checked={checked} onChange={onChange} />
								)
							}
						/>
					</Grid>
				</Grid>
				{Boolean(helperText) && (
					<FormHelperText sx={{ mt: 1 }}>{helperText}</FormHelperText>
				)}
				{Boolean(error) && (
					<ErrorMessage key={error} message={error} sx={{ mt: 1 }} />
				)}
			</Box>
			<Divider
				fullWidth
				sx={{
					mt: 0.5,
					mb: 3,
				}}
			/>
		</>
	);
}

SettingCheckbox.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	checkboxText: PropTypes.string,
	helperText: PropTypes.string,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	error: PropTypes.string,
	isLoading: PropTypes.bool,
};

SettingCheckbox.defaultProps = {
	title: '',
	subtitle: '',
	checkboxText: '',
	helperText: '',
	checked: false,
	onChange: () => null,
	error: '',
	isLoading: false,
};

export default SettingCheckbox;
