import * as React from 'react';

import { Chart } from 'react-chartjs-2';

import PropTypes from 'prop-types';

import {
	BarController,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineController,
	LineElement,
	PointElement,
	Tooltip,
} from 'chart.js';
import Box from '@mui/material/Box';
import { OffersAggregations } from './types';
import constants from '@app/constants/index';
import { truncateChartBarText } from './utils';

ChartJS.register(
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
);

const POTENTIAL_BAR_COLOR = constants.colors.darkGrey;
const PENDING_BAR_COLOR = constants.colors.disabled;
const COUNTERED_BAR_COLOR = constants.colors.tertiary;
const CANCELED_BAR_COLOR = constants.colors.failure;
const REVENUE_BAR_COLOR = constants.colors.primary;

function WaterfallChart({ formatMerchantCurrency, aggregations }) {
	let fillCount = 1;
	const colors = [POTENTIAL_BAR_COLOR];
	const labels = ['Potential Revenue'];
	const waterfallMain = [[0, aggregations.potentialRevenue]];

	let checkpointAmount = aggregations.potentialRevenue;
	if (aggregations.pendingOffersPotentialRevenue) {
		colors.push(PENDING_BAR_COLOR);
		labels.push('Pending Revenue');
		fillCount += 1;
		waterfallMain.push([
			checkpointAmount - aggregations.pendingOffersPotentialRevenue,
			checkpointAmount,
		]);
	}

	checkpointAmount -= aggregations.pendingOffersPotentialRevenue;

	if (aggregations.counteredOffersPotentialRevenue) {
		colors.push(COUNTERED_BAR_COLOR);
		labels.push('Countered');
		fillCount += 1;
		waterfallMain.push([
			checkpointAmount - aggregations.counteredOffersPotentialRevenue,
			checkpointAmount,
		]);
	}
	checkpointAmount -= aggregations.counteredOffersPotentialRevenue;

	const canceledOnlyPotentialRevenue =
		aggregations.canceledOffersPotentialRevenue -
		aggregations.counteredAndDeclinedOffersPotentialRevenue;

	if (aggregations.counteredAndDeclinedOffersPotentialRevenue) {
		colors.push(CANCELED_BAR_COLOR);
		labels.push('Countered Declined');
		waterfallMain.push([
			checkpointAmount -
				aggregations.counteredAndDeclinedOffersPotentialRevenue,
			checkpointAmount,
		]);
		checkpointAmount -=
			aggregations.counteredAndDeclinedOffersPotentialRevenue;
	} else if (canceledOnlyPotentialRevenue) {
		colors.push(CANCELED_BAR_COLOR);
		labels.push('Canceled');
		waterfallMain.push([
			checkpointAmount - canceledOnlyPotentialRevenue,
			checkpointAmount,
		]);
	}

	checkpointAmount -= canceledOnlyPotentialRevenue;

	if (aggregations.counteredAndAcceptedOffersPotentialRevenue) {
		colors.push(REVENUE_BAR_COLOR);
		labels.push('Countered Converted');
		waterfallMain.push([
			checkpointAmount -
				aggregations.counteredAndAcceptedOffersPotentialRevenue,
			checkpointAmount,
		]);
	}

	const actualRevenue =
		aggregations.acceptedOffersPotentialRevenue +
		aggregations.counteredAndAcceptedOffersPotentialRevenue;

	const chartData = {
		labels,
		datasets: [
			{
				data: waterfallMain,
				type: 'bar',
				backgroundColor: colors,
				minBarLength: 1,
				yAxisID: 'y',
				order: 3,
				barPercentage: 1.0,
				categoryPercentage: 1.0,
			},
		],
	};

	const stackDataset = {
		data: [...new Array(fillCount).fill(null)],
		type: 'bar',
		backgroundColor: [...new Array(fillCount).fill(null)],
		minBarLength: 1,
		yAxisID: 'y',
		order: 1,
		barPercentage: 1.0,
		categoryPercentage: 1.0,
		borderWidth: {
			top: 1,
			right: 0,
			bottom: 0,
			left: 0,
		},
		borderColor: 'white',
	};

	if (
		canceledOnlyPotentialRevenue &&
		aggregations.counteredAndDeclinedOffersPotentialRevenue
	) {
		stackDataset.backgroundColor.push(CANCELED_BAR_COLOR);
		stackDataset.data.push([
			actualRevenue,
			actualRevenue + canceledOnlyPotentialRevenue,
		]);
	} else if (
		canceledOnlyPotentialRevenue ||
		aggregations.counteredAndDeclinedOffersPotentialRevenue
	) {
		stackDataset.data.push(null);
		stackDataset.backgroundColor.push(null);
	}

	if (aggregations.acceptedOffersPotentialRevenue) {
		stackDataset.data.push([
			0,
			aggregations.acceptedOffersPotentialRevenue,
		]);
		stackDataset.backgroundColor.push(REVENUE_BAR_COLOR);
		if (!aggregations.counteredAndAcceptedOffersPotentialRevenue) {
			chartData.datasets[0].data.push(null);
			chartData.datasets[0].backgroundColor.push(null);
			labels.push('Accepted');
		}
	}

	if (
		aggregations.acceptedOffersPotentialRevenue ||
		canceledOnlyPotentialRevenue
	) {
		chartData.datasets.push(stackDataset);
	}

	const ChartValuePlugin = {
		id: 'centerTextPlugin',
		afterDatasetsDraw: (chart) => {
			const { ctx } = chart;
			const { datasets } = chart.data;
			const sums = [];

			datasets.forEach((dataset) => {
				dataset.data.forEach((value, index) => {
					if (!value) {
						return;
					}

					if (!sums[index]) {
						sums[index] = 0;
					}
					sums[index] += value[1] - value[0];
				});
			});

			datasets.forEach((dataset, datasetIndex) => {
				const meta = chart.getDatasetMeta(datasetIndex);

				meta.data.forEach((bar, index) => {
					const previousDatasetBar =
						datasets[datasetIndex - 1]?.data?.[index];

					if (datasetIndex === 0 || previousDatasetBar === null) {
						const text = formatMerchantCurrency(sums[index]);
						const { x } = bar;
						const y = bar.y + bar.height / 2;
						const barWidth = bar.width;

						ctx.save();
						ctx.font = 'bold 14px Roboto';
						ctx.fillStyle = 'white';
						ctx.textBaseline = 'middle';
						ctx.textAlign = 'center';

						const truncatedText = truncateChartBarText(
							ctx,
							text,
							barWidth - 15,
						);
						ctx.fillText(truncatedText, x, y);
						ctx.restore();
					}
				});
			});
		},
	};

	const options = {
		animation: false,
		hover: {
			mode: null,
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false,
			},
			centerTextPlugin: ChartValuePlugin,
		},
		scales: {
			x: {
				stacked: true,
				ticks: {
					display: true,
					callback: (i) => {
						const label = labels[i];
						if (
							label === 'Countered Declined' &&
							canceledOnlyPotentialRevenue
						) {
							return 'Countered Declined / Canceled';
						}
						if (
							label === 'Countered Converted' &&
							aggregations.acceptedOffersPotentialRevenue
						) {
							return 'Countered Converted / Accepted';
						}

						return label;
					},
				},
				grid: {
					display: false,
				},
			},
			y: {
				grid: {
					display: false,
				},
				beginAtZero: true,
				ticks: {
					callback: (v) => formatMerchantCurrency(v),
				},
			},
		},
	};

	return (
		<Box sx={{ textAlign: 'center' }}>
			<Chart
				data={chartData}
				options={options}
				plugins={[ChartValuePlugin]}
			/>
		</Box>
	);
}

WaterfallChart.propTypes = {
	aggregations: OffersAggregations.isRequired,
	formatMerchantCurrency: PropTypes.func.isRequired,
};

export default WaterfallChart;
