import PropTypes from 'prop-types';
import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

function TabsNavigation({ items }) {
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const handleTabChange = React.useCallback(
		(event, newValue) => {
			navigate(newValue);
		},
		[navigate],
	);

	const tabItems = React.useMemo(
		() =>
			items.map((item) => (
				<Tab value={item.link} label={item.name} key={item.link} />
			)),
		[items],
	);

	return (
		<>
			<Tabs
				value={pathname}
				onChange={handleTabChange}
				sx={{ mb: 3 }}
			>
				{tabItems}
			</Tabs>
			<Outlet />
		</>
	);
}

TabsNavigation.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			link: PropTypes.string,
		}),
	),
};

TabsNavigation.defaultProps = {
	items: [],
};

export default TabsNavigation;
