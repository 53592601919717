import * as React from 'react';
import PropTypes from 'prop-types';

import { useQueryClient } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

import { useFulfillPreorders } from '../../hooks/usePreorders';
import { CACHE_KEY as SELLING_PLAN_GROUPS_CACHE_KEY } from '../../hooks/useSellingPlanGroups';
import { GAevent } from '../../lib/googleAnalytics';
import {
	eventCategories,
	events,
	transport,
} from '../../constants/googleAnalytics';
import Button from '@app/components/shared/button/Button';

function ConfirmFulfillment({
	sellingPlanGroupId,
	// count,
	handleModal,
	setShouldPoll,
}) {
	const { mutate, isLoading, error } = useFulfillPreorders({
		sellingPlanGroupId,
	});
	const queryClient = useQueryClient();

	const handleClick = () => {
		GAevent({
			categoryName: eventCategories.FULFILL_CAMPAIGN,
			eventName: events.SUBMIT,
			transport: transport.BEACON,
		});

		mutate(
			{ sellingPlanGroupId },
			{
				// TODO: Fix no-op React warning (Does not seem critical and warning removed in React 18)
				// https://tkdodo.eu/blog/mastering-mutations-in-react-query#some-callbacks-might-not-fire
				onSettled: (data, err) => {
					if (!err) {
						// Might not need this
						setTimeout(() => {
							queryClient.invalidateQueries([
								SELLING_PLAN_GROUPS_CACHE_KEY,
								'list',
							]);
							queryClient.invalidateQueries([
								SELLING_PLAN_GROUPS_CACHE_KEY,
								sellingPlanGroupId,
							]);
						}, 5000);
						setShouldPoll(true);
						handleModal(false);
					}
				},
			},
		);
	};

	return (
		<Box id="fulfillment-confirmation">
			{/* <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
				Are you sure you want to fulfill{' '}
				{`${count} order${count > 1 ? 's' : ''}`}?
			</Typography> */}
			<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
				Are you sure you want to fulfill this Campaign?
			</Typography>
			<List>
				<ListItemText
					primary="Customers are charged remaining balance"
					sx={{
						padding: 0,
						marginLeft: '20px',
						listStyleType: 'disc',
						display: 'list-item',
					}}
				/>
				<ListItemText
					primary='Any scheduled fulfillments are marked as "OPEN" (available for fulfillment)'
					sx={{
						padding: 0,
						marginLeft: '20px',
						listStyleType: 'disc',
						display: 'list-item',
					}}
				/>
			</List>
			<Box sx={{ textAlign: 'center', mb: 3 }}>
				<Typography
					color="text.secondary"
					sx={{ fontStyle: 'italic' }}
				>
					It may take a moment for all payments to complete.
				</Typography>
				<Typography
					color="text.secondary"
					sx={{ fontStyle: 'italic' }}
				>
					Your dashboard should update automatically or feel free to
					come back later.
				</Typography>
			</Box>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
			>
				<Button
					type="submit"
					disabled={isLoading}
					loading={isLoading}
					onClick={handleClick}
				>
					Fulfill
				</Button>
				{error && (
					<Typography component="p" color="error">
						{error?.data?.message ||
							'A problem occurred.  Please try again'}
					</Typography>
				)}
			</Box>
		</Box>
	);
}

ConfirmFulfillment.propTypes = {
	sellingPlanGroupId: PropTypes.string.isRequired,
	// count: PropTypes.number,
	handleModal: PropTypes.func.isRequired,
	setShouldPoll: PropTypes.func.isRequired,
};

ConfirmFulfillment.defaultProps = {
	// count: 0,
};

export default ConfirmFulfillment;
