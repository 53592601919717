import { useQuery, useMutation } from '@tanstack/react-query';

import * as collectionsApi from '../api/collections';

export const CACHE_KEY = 'collections';

export function useCollections({
	cacheKey,
	queryVariables,
	configOpts = {},
}) {
	return useQuery({
		queryKey: [CACHE_KEY, cacheKey],
		queryFn: () => collectionsApi.getCollections(queryVariables),
		keepPreviousData: true,
		// TODO: Setting this to basically never refresh.
		// Consider quicker refresh when we don't get variants from Shopify Graphql
		staleTime: 1000 * 60 * 5, // Data is considered fresh forever
		cacheTime: 1000 * 60 * 60 * 24, // 24 hours, adjust as needed
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		refetchInterval: false,
		...configOpts,
	});
}

export function useSyncCollection(configOpts = {}) {
	return useMutation({
		mutationKey: [CACHE_KEY, 'sync'],
		mutationFn: ({ collectionId }) =>
			collectionsApi.syncCollection({ collectionId }),
		...configOpts,
	});
}
