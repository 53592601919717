import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { isNaN } from 'lodash-es';
import ConversationalInputWrapper from '@app/components/shared/inputs/ConversationalTextInput';
import useAuth from '@app/hooks/useAuth';

function FixedCostBuffer({
	onChange,
	automationRules = {},
	defaultValue = null,
	disabled = false,
}) {
	const { getCurrencySymbol } = useAuth();
	const [fixedCostBuffer, setFixedCostBuffer] =
		React.useState(defaultValue);

	React.useEffect(() => {
		onChange(parseInt(fixedCostBuffer, 10));
	}, [onChange, fixedCostBuffer]);

	const handleChangeFixedCostBuffer = (data) => {
		let value = Number.parseInt(data.target.value, 10);

		if (isNaN(value) || value <= 0) {
			value = 0;
		}

		setFixedCostBuffer(value.toString());
	};

	// Show if auto incremented for marketplace campaign
	const secondaryPrompt =
		automationRules?.autoIncrementedFixedCostBuffer
			? `Incremented from original $${automationRules.originalFixedCostBuffer} for Marketplace.`
			: '';

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				mt: 2,
			}}
		>
			<ConversationalInputWrapper
				prompt="Would you like to add an additional shipping cost?"
				secondaryPrompt={secondaryPrompt}
				helperText={`
					If you are selling on the Bazo Marketplace,
					 Bazo will add this amount in addition to the discount
					  threshold to cover additional costs like shipping.
						If you are using Bazo on your own store, this is not
						needed and your normal shipping rates will apply.
				`}
			>
				<TextField
					name="fixedCostBuffer"
					type="number"
					disabled={disabled}
					value={fixedCostBuffer}
					onChange={handleChangeFixedCostBuffer}
					sx={{ width: '100%' }}
					InputProps={{
						startAdornment: (
							<InputAdornment position="end">
								<Typography sx={{ pr: 1 }}>
									{getCurrencySymbol()}
								</Typography>
							</InputAdornment>
						),
					}}
				/>
			</ConversationalInputWrapper>
		</Box>
	);
}

FixedCostBuffer.propTypes = {
	disabled: PropTypes.bool,
	defaultValue: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	automationRules: PropTypes.shape({
		autoIncrementedFixedCostBuffer: PropTypes.bool,
		fixedCostBuffer: PropTypes.number,
		originalFixedCostBuffer: PropTypes.number,
	}),
};

export default FixedCostBuffer;
