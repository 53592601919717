import { useFlags } from 'flagsmith/react';

const FLAGS = {
	FIXED_COST_BUFFER: 'fixed_cost_buffer',
	CUSTOM_COUNTER_OFFER_EXPIRY: 'custom_counter_offer_expiry',
	KLAVIYO_INTEGRATION: 'klaviyo_integration',
	MARKETPLACE_AVAILABLE: 'marketplace_available',
};

function useFlagEnabled(flag) {
	const flagsList = flag ? [flag] : [];
	const flags = useFlags(flagsList);

	if (!flag) {
		return null;
	}

	return flags[flag]?.enabled ?? false;
}

export { FLAGS, useFlagEnabled };
