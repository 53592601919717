import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useInventoryReviewContext } from '@app/hooks/useInventoryReviewContext';
import { useCreateCampaignContext } from '@app/hooks/useCreateCampaignContext';

function CreateCampaignButton({ shopName, selectedVariants }) {
	const navigate = useNavigate();
	const [, setSelectedVariants] = useInventoryReviewContext();

	const { setCreateCampaignInfo } = useCreateCampaignContext();

	const handleCreateCampaignNavigate = React.useCallback(() => {
		setSelectedVariants(selectedVariants);
		setCreateCampaignInfo({
			fieldsData: {
				description: `${shopName} [Marketplace]`,
			},
			automationRules: {
				enabled: true,
			},
			step: 0,
		});
		navigate('/campaigns/create');
	}, [
		setSelectedVariants,
		setCreateCampaignInfo,
		selectedVariants,
		navigate,
		shopName,
	]);

	return (
		<Button onClick={handleCreateCampaignNavigate}>
			Create Campaign
		</Button>
	);
}

CreateCampaignButton.propTypes = {
	shopName: PropTypes.string.isRequired,
	selectedVariants: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
		}),
	).isRequired,
};

export default CreateCampaignButton;
