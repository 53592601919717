import { MaterialDesignContent, useSnackbar } from 'notistack';
import { styled, useTheme } from '@mui/material/styles';

// TODO: get this more closley integrated to work natively with hooks
// Having a hard time getting theme colors to work with this package
// as well as use this functionality outside of hooks for http global error handlers
// https://stackoverflow.com/questions/66051870/notistack-enqueuesnackbar-usage-outside-component
// https://smartdevpreneur.com/customizing-notistack-with-material-ui-snackbar/

let useSnackbarRef;
let theme;

export function SnackbarUtilsConfigurator() {
	useSnackbarRef = useSnackbar();
	theme = useTheme();
	return null;
}

const SnackBarUtils = {
	success(msg) {
		this.toast(msg, 'success');
	},
	warning(msg) {
		this.toast(msg, 'warning');
	},
	info(msg) {
		this.toast(msg, 'info');
	},
	error(msg) {
		this.toast(msg, 'error');
	},
	toast(msg, variant = 'default') {
		useSnackbarRef.enqueueSnackbar(msg, {
			variant,
		});
	},
};

export default SnackBarUtils;

export function errorHandler(error) {
	let errorMsg = 'Oops! An error has occurred. Please try again.';

	if (error?.response?.data?.message) {
		errorMsg = error.response.data.message;
	} else if (error?.data?.message) {
		errorMsg = error.data.message;
	}

	return SnackBarUtils.error(errorMsg);
}

export function successHandler(msg) {
	return SnackBarUtils.success(msg || 'Success!');
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
	() => ({
		'&.notistack-MuiContent-success': {
			backgroundColor: theme.palette.success.main,
		},
		'&.notistack-MuiContent-error': {
			backgroundColor: theme.palette.error.main,
		},
		'&.notistack-MuiContent-warning': {
			backgroundColor: theme.palette.warning.main,
		},
		'&.notistack-MuiContent-info': {
			backgroundColor: theme.palette.info.main,
		},
	}),
);

export const SnackbarComponents = {
	success: StyledMaterialDesignContent,
	error: StyledMaterialDesignContent,
	info: StyledMaterialDesignContent,
	warning: StyledMaterialDesignContent,
};
