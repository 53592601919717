import axios from './index';

const apiUrl = '/api/checkout-validation';

export async function getCheckoutValidation() {
	const response = await axios.get(apiUrl);
	return response.data;
}

export const updateCheckoutValidation = async ({ enabled }) => {
	const response = await axios.patch(apiUrl, { enabled });
	return response.data;
};
