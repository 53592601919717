import { useLocation } from 'react-router-dom';
import * as googleAnalytics from '../lib/googleAnalytics';

function usePaintPerformance() {
	const location = useLocation();

	const paintObserver = new PerformanceObserver((list) => {
		list.getEntries().forEach((entry) => {
			googleAnalytics.GAtiming({
				categoryName: `Paint ${location.pathname}`,
				variableName: entry.name,
				valueNum: entry.startTime,
			});
		});
	});

	const meaningfulPaintObserver = new PerformanceObserver((list) => {
		const entries = list.getEntries();
		const meaningfulEntry =
			entries.find(
				(entry) =>
					entry.element &&
					entry.element.getAttribute('data-ga-label'),
			) || entries[entries.length - 1];
		if (meaningfulEntry) {
			googleAnalytics.GAtiming({
				categoryName: `Meaningful Paint ${location.pathname}`,
				variableName: meaningfulEntry.name,
				valueNum: meaningfulEntry.startTime,
			});
		}
	});

	paintObserver.observe({ type: 'paint', buffered: true });
	meaningfulPaintObserver.observe({
		type: 'largest-contentful-paint',
		buffered: true,
	});
}

export default usePaintPerformance;
