import React from 'react';
import PropTypes from 'prop-types';
import BackSaveButtonContainer from './components/backSaveButtonContainer';
import ProductsTable from '@app/components/muiTable/ProductsTable';
import { useInventoryReviewContext } from '@app/hooks/useInventoryReviewContext';

function AddProductsModal({ onClose }) {
	const [defaultSelectedVariants, onSelectedVariantsChanged] =
		useInventoryReviewContext();
	const [displaySelectedVariants, setDisplaySelectedVariants] =
		React.useState(defaultSelectedVariants);

	const handleSaveChanges = React.useCallback(() => {
		onSelectedVariantsChanged(displaySelectedVariants);
		onClose();
	}, [displaySelectedVariants, onClose, onSelectedVariantsChanged]);

	return (
		<>
			<ProductsTable
				defaultSelectedVariants={defaultSelectedVariants}
				onSelectedVariantsChanged={setDisplaySelectedVariants}
				style={{
					removeFrame: true,
					hideTitle: true,
				}}
			/>
			<BackSaveButtonContainer
				onClose={onClose}
				handleSaveChanges={handleSaveChanges}
			/>
		</>
	);
}

AddProductsModal.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default AddProductsModal;
