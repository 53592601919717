import React from 'react';
import PropTypes from 'prop-types';

// See implementation  notes below for use in components
// Must do so correctly to prevent memory leaks

const EventBusContext = React.createContext();

function EventBusProvider({ children }) {
	const [events, setEvents] = React.useState({});

	const subscribe = React.useCallback((eventName, callback) => {
		setEvents((prevEvents) => ({
			...prevEvents,
			[eventName]: [...(prevEvents[eventName] || []), callback],
		}));
	}, []);

	const unsubscribe = React.useCallback((eventName, callback) => {
		setEvents((prevEvents) => ({
			...prevEvents,
			[eventName]: (prevEvents[eventName] || []).filter(
				(cb) => cb !== callback,
			),
		}));
	}, []);

	const broadcast = React.useCallback(
		(eventName, data) => {
			(events[eventName] || []).forEach((callback) => callback(data));
		},
		[events],
	);

	const contextMemo = React.useMemo(
		() => ({
			subscribe,
			unsubscribe,
			broadcast,
		}),
		[subscribe, unsubscribe, broadcast],
	);

	return (
		<EventBusContext.Provider value={contextMemo}>
			{children}
		</EventBusContext.Provider>
	);
}

EventBusProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export default EventBusProvider;

export const useEventBus = () => React.useContext(EventBusContext);

/* Implementation details for using in components
 * Make sure to mount and unmount to prevent memory leaks
 * and use as follows below

import React, { useEffect } from 'react';
import { useEventBus } from './EventBus';

const ExampleComponent = () => {
  const { subscribe, broadcast } = useEventBus();

  useEffect(() => {
    const handleEvent = (data) => {
      console.log('Event received', data);
    };

    subscribe('myEvent', handleEvent);

    // Cleanup on unmount
    return () => unsubscribe('myEvent', handleEvent);
  }, [subscribe, unsubscribe]);

  const emitEvent = () => {
    broadcast('myEvent', { message: 'Hello from ExampleComponent!' });
  };

  return (
    <div>
      <button onClick={emitEvent}>Emit Event</button>
    </div>
  );
};

export default ExampleComponent;

*/
