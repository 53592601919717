import React from 'react';
import PropTypes from 'prop-types';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@app/components/shared/button/Button';

const INPUT_MIN_VALUE = 0;
const INPUT_MAX_VALUE = 100;

function ProductValueInput({
	id,
	textColor,
	value,
	handleClearThreshold,
	handleChange,
	isValueDifferent,
	startAdornment,
	endAdornment,
	minValue = INPUT_MIN_VALUE,
	maxValue = INPUT_MAX_VALUE,
}) {
	const onChange = (event) => {
		if (parseFloat(event.target.value) === value) {
			return;
		}

		handleChange(event, id);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<OutlinedInput
				type="number"
				color="primary"
				inputProps={{
					min: minValue,
					max: maxValue,
					style: {
						height: 40,
						width: 75,
						padding: 5,
						marginLeft: 8,
					},
				}}
				sx={{
					color: textColor,
				}}
				value={value.toString()}
				onChange={onChange}
				onClick={(event) => {
					event.stopPropagation();
				}}
				startAdornment={
					<InputAdornment
						position="end"
						sx={{
							marginLeft: 0.4,
						}}
					>
						<Typography
							sx={{
								color: textColor,
							}}
						>
							{startAdornment}
						</Typography>
					</InputAdornment>
				}
				endAdornment={
					<InputAdornment
						position="end"
						sx={{
							marginLeft: 0.4,
						}}
					>
						<Typography
							sx={{
								color: textColor,
							}}
						>
							{endAdornment}
						</Typography>
					</InputAdornment>
				}
			/>
			<Button
				name="clear-search"
				variant="text"
				sx={{
					marginLeft: 1,
					marginRight: -5,
					display: isValueDifferent ? 'block' : 'none',
				}}
				onClick={(event) => {
					event.stopPropagation();
					handleClearThreshold(id);
				}}
			>
				Clear
			</Button>
		</Box>
	);
}

ProductValueInput.propTypes = {
	id: PropTypes.string.isRequired,
	textColor: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleClearThreshold: PropTypes.func.isRequired,
	isValueDifferent: PropTypes.bool.isRequired,
	startAdornment: PropTypes.string,
	endAdornment: PropTypes.string,
	minValue: PropTypes.number,
	maxValue: PropTypes.number,
};

ProductValueInput.defaultProps = {
	startAdornment: '',
	endAdornment: '',
	minValue: INPUT_MIN_VALUE,
	maxValue: INPUT_MAX_VALUE,
};

export default ProductValueInput;
