import * as React from 'react';
import PropTypes from 'prop-types';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import FullPageMessage from '../components/shared/layout/FullPageMessage';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		console.error(error);
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.error(error, errorInfo);
	}

	render() {
		const { hasError } = this.state;
		const { children } = this.props;

		if (hasError) {
			// You can render any custom fallback UI
			return (
				<Container>
					<FullPageMessage appBar>
						<Typography
							variant="h1"
							sx={{ color: 'error.main', mb: 4 }}
						>
							Oops! Something went wrong.
						</Typography>
						<Typography variant="h6" paragraph>
							Please reload the page and try again.
						</Typography>
					</FullPageMessage>
				</Container>
			);
		}

		return children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]).isRequired,
};

export default ErrorBoundary;
