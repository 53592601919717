import PropTypes from 'prop-types';
import * as React from 'react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function DateOffsetSelector({ onChange }) {
	const [open, setOpen] = React.useState(false);
	const [value, setValue] = React.useState('');

	React.useEffect(() => {
		onChange(value);
	}, [onChange, value]);

	const options = React.useMemo(() => {
		const hours24 = new Date();
		hours24.setHours(hours24.getHours() - 24);

		const hours36 = new Date();
		hours36.setHours(hours36.getHours() - 36);

		const hours48 = new Date();
		hours48.setHours(hours48.getHours() - 48);

		const hours72 = new Date();
		hours72.setHours(hours72.getHours() - 72);

		const weeks1 = new Date();
		weeks1.setHours(weeks1.getHours() - 24 * 7);

		const weeks2 = new Date();
		weeks2.setHours(weeks2.getHours() - 24 * 14);

		const all = new Date('0001-01-01');

		return [
			{
				value: '',
				label: 'New',
			},
			{
				value: hours24.toISOString(),
				label: '24 Hours',
			},
			{
				value: hours36.toISOString(),
				label: '36 Hours',
			},
			{
				value: hours48.toISOString(),
				label: '48 Hours',
			},
			{
				value: hours72.toISOString(),
				label: '72 Hours',
			},
			{
				value: weeks1.toISOString(),
				label: 'One Week',
			},
			{
				value: weeks2.toISOString(),
				label: 'Two Weeks',
			},
			{
				value: all.toISOString(),
				label: 'All',
			},
		];
	}, []);

	return (
		<FormControl
			variant="standard"
			sx={{ minWidth: '240px', width: 'fit-content' }}
		>
			<InputLabel shrink id="select-date-offset-label">
				Select orders for the last:
			</InputLabel>
			<Select
				id="select-inbox-date-offset"
				labelId="select-date-offset-label"
				defaultValue=""
				value={value}
				label="Select orders for the last:"
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				onClick={() => setOpen(!open)}
				sx={{ ':hover': { cursor: 'pointer' } }}
				onChange={(event) => setValue(event.target.value)}
				displayEmpty
			>
				{options.map((option) => (
					<MenuItem
						key={`select-date-offset-option-${option.label}`}
						value={option.value}
					>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

DateOffsetSelector.propTypes = {
	onChange: PropTypes.func.isRequired,
};

DateOffsetSelector.defaultProps = {};

export default DateOffsetSelector;
