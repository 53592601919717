export function localizeCurrency({
	customerLocale = 'en-US',
	currencyCode = 'USD',
	amount = 0,
	maximumFractionDigits,
}) {
	return new Intl.NumberFormat(customerLocale, {
		style: 'currency',
		currency: currencyCode,
		maximumFractionDigits,
	}).format(amount);
}

export function getCurrencySymbol({
	locale = 'en',
	currency = 'USD',
}) {
	return (0)
		.toLocaleString(locale, {
			style: 'currency',
			currency,
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		})
		.replace(/\d/g, '')
		.trim();
}

export function parseCurrencyValue(value) {
	if (!value || Number.isNaN(value)) {
		return '-';
	}
	return parseFloat(value.replace(/[^0-9.-]+/g, ''));
}

export function localizeNumber({
	locale = 'en-US',
	maximumFractionDigits,
	value,
}) {
	if (
		value === null ||
		value === undefined ||
		Number.isNaN(parseInt(value, 10))
	) {
		return null;
	}
	return new Intl.NumberFormat(locale, {
		maximumFractionDigits,
	}).format(value);
}
