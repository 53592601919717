import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Snackbar from '../ui/SnackBar';
import useAuth from '@app/hooks/useAuth';

const BANNER_CLOSED_COOKIE_KEY = 'banner_closed_timestamp';
const ROUTES_BLACK_LIST = ['settings', 'onboarding'];

function AuxiliaryWarningAlert({ open, setOpen }) {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { merchant } = useAuth();

	const isRouteInBlackList = React.useMemo(
		() => ROUTES_BLACK_LIST.some((route) => pathname.includes(route)),
		[pathname],
	);
	const isAuxiliaryStepSkipped = React.useMemo(
		() =>
			merchant?.active &&
			merchant?.onboarding_complete &&
			(!merchant?.auxiliary_protection_step_complete ||
				!merchant?.auxiliary_free_gift_step_complete),
		[merchant],
	);

	React.useEffect(() => {
		const lastClosedTimestamp = Cookies.get(BANNER_CLOSED_COOKIE_KEY);

		const isOpen =
			isAuxiliaryStepSkipped &&
			!lastClosedTimestamp &&
			!isRouteInBlackList;
		setOpen(isOpen);
		// eslint-disable-next-line
	}, [isAuxiliaryStepSkipped, isRouteInBlackList]);

	const handleNavigate = React.useCallback(() => {
		setOpen(false);
		navigate('/settings/inventory');
	}, [navigate, setOpen]);

	const handleClose = React.useCallback(
		(value) => {
			setOpen(value);
			Cookies.set(BANNER_CLOSED_COOKIE_KEY, Date.now(), {
				expires: 1,
			});
		},
		[setOpen],
	);

	return (
		<Snackbar
			open={!!open}
			setOpen={handleClose}
			severity="info"
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
			autoHideDuration={null}
			message={
				<>
					<Typography sx={{ fontSize: 13 }}>
						Please let us know if you have Order Protection or
						products that are automatically added to the cart.
					</Typography>
					<Typography sx={{ fontSize: 13 }}>
						Failure to do so may result in complications completing
						offers.
					</Typography>
					<ButtonBase
						sx={{
							fontSize: 13,
							textDecoration: 'underline',
							fontWeight: 600,
						}}
						onClick={handleNavigate}
					>
						Click here to open settings
					</ButtonBase>
				</>
			}
		/>
	);
}

AuxiliaryWarningAlert.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
};

export default AuxiliaryWarningAlert;
