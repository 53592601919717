import React from 'react';
import PropTypes from 'prop-types';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

const INPUT_MIN_VALUE = 0;
const INPUT_MAX_VALUE = 99;

const VALID_CHARACTERS = new Set([
	'0',
	'1',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
]);

function InputField({
	handleChange,
	value = null,
	startAdornment = '',
	endAdornment = '',
	minValue = INPUT_MIN_VALUE,
	maxValue = INPUT_MAX_VALUE,
	inherited = false,
}) {
	const onValueChanged = (newValue) => {
		handleChange(newValue);
	};

	const onChange = (event) => {
		const newValue = event.target.value;

		if (!newValue) {
			onValueChanged(0);
			return;
		}

		const newValueSymbols = new Set(newValue);
		if (newValueSymbols.difference(VALID_CHARACTERS).size !== 0) {
			return;
		}

		const newValueNum = parseInt(newValue, 10);
		if (newValueNum === parseInt(value, 10)) {
			return;
		}

		if (
			newValueNum === null ||
			newValueNum === undefined ||
			Number.isNaN(newValueNum)
		) {
			return;
		}

		if (newValueNum > maxValue) {
			onValueChanged(maxValue);
			return;
		}

		if (newValueNum < minValue) {
			onValueChanged(minValue);
			return;
		}

		onValueChanged(newValueNum);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<OutlinedInput
				type="text"
				color="primary"
				sx={{
					color: inherited ? 'pending.main' : 'black',
				}}
				inputProps={{
					min: minValue,
					max: maxValue,
					style: {
						height: 40,
						width: 75,
						padding: 5,
						marginLeft: 8,
					},
				}}
				value={value?.toString() || '0'}
				onChange={onChange}
				onClick={(event) => {
					event.stopPropagation();
				}}
				startAdornment={
					<InputAdornment
						position="end"
						sx={{
							marginLeft: 0.4,
						}}
					>
						<Typography>{startAdornment}</Typography>
					</InputAdornment>
				}
				endAdornment={
					<InputAdornment
						position="end"
						sx={{
							marginLeft: 0.4,
						}}
					>
						<Typography>{endAdornment}</Typography>
					</InputAdornment>
				}
			/>
		</Box>
	);
}

InputField.propTypes = {
	handleChange: PropTypes.func.isRequired,
	value: PropTypes.number,
	startAdornment: PropTypes.string,
	endAdornment: PropTypes.string,
	minValue: PropTypes.number,
	maxValue: PropTypes.number,
	inherited: PropTypes.bool,
};

export default InputField;
