import PropTypes from 'prop-types';
import { useFlagEnabled } from '@app/hooks/useFlags';

export default function RequiresFlag({ flag, children }) {
	const enabled = useFlagEnabled(flag);

	if (!flag) {
		return children;
	}

	if (!enabled) {
		return null;
	}

	return children;
}

RequiresFlag.propTypes = {
	flag: PropTypes.string,
	children: PropTypes.node.isRequired,
};

RequiresFlag.defaultProps = {
	flag: null,
};
