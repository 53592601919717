import * as React from 'react';
import Grid from '@mui/material/Grid';

import Table from './table';

function NYOPProductsSummary() {
	return (
		<Grid container rowSpacing={2}>
			<Grid item md={12}>
				<Table />
			</Grid>
		</Grid>
	);
}

export default NYOPProductsSummary;
