import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import { toTitleCase } from '../../helpers/stringHelpers';
import { getSettingsMenuItems } from '@app/constants/navigationItems';

// Breadcrumbs should not show up on these pages
const routeBlacklist = [
	...getSettingsMenuItems().map((item) => item.link),
	'/login',
	'/onboarding',
	'/onboarding/subscriptions',
	'/register',
	'/auth/get-access-code',
];

function BreadcrumbNav() {
	const location = useLocation();
	const { search = '' } = location;

	if (routeBlacklist.indexOf(location.pathname) > -1) {
		return null;
	}

	const pathnames = location.pathname.split('/').filter((x) => x);

	return (
		<Breadcrumbs aria-label="Breadcrumb">
			<Link color="inherit" component={RouterLink} to={`/${search}`}>
				Home
			</Link>
			{pathnames.map((pathname, index) => {
				const last = index === pathnames.length - 1;
				const to = `/${pathnames
					.slice(0, index + 1)
					.join('/')}${search}`;

				let displayPathname;

				if (pathname.startsWith('gid')) {
					displayPathname = decodeURIComponent(pathname);
				} else if (pathname === 'nyop') {
					displayPathname = 'NYOP';
				} else {
					displayPathname = toTitleCase(pathname);
				}

				return last ? (
					<Typography color="primary" key={to}>
						{displayPathname}
					</Typography>
				) : (
					<Link
						color="inherit"
						component={RouterLink}
						to={to}
						key={to}
					>
						{displayPathname}
					</Link>
				);
			})}
		</Breadcrumbs>
	);
}

export default BreadcrumbNav;
