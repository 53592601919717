import React from 'react';
import useForm from '@app/hooks/useForm';
import {
	useCounterOfferExpiryDays,
	useSetCounterOfferExpiryDays,
} from '@app/hooks/useMerchant';
import SettingContainer from '../components/settingContainer/SettingContainer';

function CounterOfferExpiry() {
	const [error, setError] = React.useState('');

	const { data: counterOfferExpiryDays, isLoading } =
		useCounterOfferExpiryDays();

	const currentCounterOfferExpiryDays =
		counterOfferExpiryDays?.counterOfferExpiryDays;

	const { mutate } = useSetCounterOfferExpiryDays();

	const { handleSubmit, handleChange, data, errors } = useForm({
		initialValues: {
			counterOfferExpiryDays: currentCounterOfferExpiryDays,
		},
		validations: {
			counterOfferExpiryDays: {
				pattern: {
					value: /^(10|[0-9])$/,
					message:
						'The number of days before counter offer expiration must be a valid number between 1 and 3 (inclusive).',
				},
				custom: {
					isValid: (value) => value > 0 && value < 4,
					message:
						'Please ensure that the value is the correct number of days between 1 and 3.',
				},
			},
		},
		onSubmit: async () => {
			try {
				mutate({
					counterOfferExpiryDays: data.counterOfferExpiryDays,
				});
			} catch (err) {
				console.error(err);
				setError(err.message);
			}
		},
	});

	React.useEffect(() => {
		handleChange('counterOfferExpiryDays')({
			target: {
				value: currentCounterOfferExpiryDays || 3,
			},
		});
		// eslint-disable-next-line
	}, [currentCounterOfferExpiryDays]);

	const handleCounterOfferExpiryDaysChange = (e) => {
		let value = Number.parseInt(e.target.value, 10);
		if (Number.isNaN(value) || value <= 0) {
			value = 0;
		}

		if (value > 3) {
			value = 3;
		}

		handleChange('counterOfferExpiryDays')({
			target: {
				value,
			},
		});
	};

	return (
		<SettingContainer
			title="Counter Offer Expiry Days"
			subtitle="How long should we wait before cancelling unacknowledged counter offers?"
			helperText={`After your customers receive a counter offer, they will have ${data.counterOfferExpiryDays} day(s) to respond before Bazo automatically declines it.`}
			value={data.counterOfferExpiryDays}
			inputType="number"
			onChangeValue={handleCounterOfferExpiryDaysChange}
			inputName="counterOfferExpiryDays"
			onClick={handleSubmit}
			errors={[...Object.values(errors ?? {}), error]}
			isLoading={isLoading}
			placeholder="Loading..."
		/>
	);
}

export default CounterOfferExpiry;
