import PropTypes from 'prop-types';
import React from 'react';
import MUIDataTable from 'mui-datatables';
import {
	createTheme,
	ThemeProvider,
	useTheme,
} from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useAuth from '@app/hooks/useAuth';
import { formatReadableDateTime } from '@app/helpers/dateHelpers';
import { parsePercents } from '@app/lib/parse';
import TitleWithImage from '@app/components/muiTable/elements/TitleWithImage';
import {
	getOrderLink,
	getProductVariantLink,
} from '@app/lib/shopify';
import offerService from '@app/services/offer.service';
import OffersExecutionProgress from '@app/components/nyop/offers/OffersExecutionProgress';
import StatusRenderer from '@app/components/muiTable/offers/StatusRenderer';
import MoneyRenderer from '@app/components/muiTable/offers/MoneyRenderer';
import getNoTableMatch from '@app/components/shared/Table/components/NoTableMatch';
import { useMarketplaceCampaignInbox } from '@app/hooks/useMarketplace';

const OrderStatus = {
	NEW: 'New',
	CANCELED: 'Canceled',
	PARTIALLY_PROCESSED: 'Partially Processed',
	PROCESSED: 'Processed',
};

const DataColumnOptions = {
	viewColumns: false,
	filter: false,
	sort: false,
	download: false,
	display: 'excluded',
};

const DataIndex = {
	status: -9,
	setOrderToReviewId: -8,
	totalCurrentCustomerCurrencyOffersAmount: -7,
	totalInitialCustomerCurrencyOffersAmount: -6,
	shop: -5,
	offers: -4,
	formatMerchantNumber: -3,
	formatMerchantCurrency: -2,
	id: -1,
};

function getDataColumns() {
	return Object.keys(DataIndex).map((name) => ({
		name,
		options: DataColumnOptions,
	}));
}

function getOrderNumberRenderer(value, data) {
	const orderId = data.rowData.at(DataIndex.id);
	const shop = data.rowData.at(DataIndex.shop);
	return (
		<Link
			href={getOrderLink(shop, orderId)}
			target="_blank"
			rel="noopener noreferrer"
			underline="hover"
			sx={{ width: 'min-content', display: 'block' }}
			onClick={(event) => event.stopPropagation()}
		>
			<Typography fontWeight="500" sx={{ width: 'fit-content' }}>
				{value}
			</Typography>
		</Link>
	);
}

function getDateRenderer(value) {
	return formatReadableDateTime(value);
}

function getInitialAmountRenderer(value, data) {
	const offers = data.rowData.at(DataIndex.offers);
	if (offers.length === 0) {
		return '-';
	}

	const customerCurrencyAmount = data.rowData.at(
		DataIndex.totalInitialCustomerCurrencyOffersAmount,
	);
	return (
		<MoneyRenderer
			shopCurrencyAmount={value}
			shopCurrency={offers[0].shop_currency}
			customerCurrencyAmount={customerCurrencyAmount}
			customerCurrency={offers[0].currency}
		/>
	);
}

function getCurrentAmountRenderer(value, data) {
	const offers = data.rowData.at(DataIndex.offers);
	if (offers.length === 0) {
		return '-';
	}

	const customerCurrencyAmount = data.rowData.at(
		DataIndex.totalCurrentCustomerCurrencyOffersAmount,
	);
	return (
		<MoneyRenderer
			shopCurrencyAmount={value}
			shopCurrency={offers[0].shop_currency}
			customerCurrencyAmount={customerCurrencyAmount}
			customerCurrency={offers[0].currency}
		/>
	);
}

function getUnitsRenderer(value, data) {
	const formatMerchantNumber = data.rowData.at(
		DataIndex.formatMerchantNumber,
	);
	return (
		<Typography color="pending.main">
			{formatMerchantNumber({ value })}
		</Typography>
	);
}

function getPercentRenderer(value) {
	return (
		<Typography color="pending.main">
			{parsePercents(value)}%
		</Typography>
	);
}

function getStatusRenderer(value, data) {
	const offers = data.rowData.at(DataIndex.offers);
	return <OffersExecutionProgress offers={offers} />;
}

const columns = [
	{
		name: 'name',
		label: 'Order & Product',
		options: {
			customBodyRender: getOrderNumberRenderer,
			sort: false,
		},
	},
	{
		name: 'created_at',
		label: 'Date',
		options: {
			customBodyRender: getDateRenderer,
		},
	},
	{
		name: 'totalInitialAmount',
		label: 'Original Offer',
		options: {
			customBodyRender: getInitialAmountRenderer,
		},
	},
	{
		name: 'totalCurrentAmount',
		label: 'Current Offer',
		options: {
			customBodyRender: getCurrentAmountRenderer,
		},
	},
	{
		name: 'totalUnits',
		label: 'Units',
		options: {
			customBodyRender: getUnitsRenderer,
		},
	},
	{
		name: 'totalDiscount',
		label: 'Effective Discount',
		options: {
			customBodyRender: getPercentRenderer,
		},
	},
	{
		name: 'status',
		label: 'Status',
		options: {
			customBodyRender: getStatusRenderer,
		},
	},
	// EXTRA DATA
	...getDataColumns(),
];

function NyopOffersInboxTable({ campaignId }) {
	const theme = useTheme();
	const { merchant, formatMerchantCurrency, formatMerchantNumber } =
		useAuth();

	const [page, setPage] = React.useState(0);
	const [sortKey, setSortKey] = React.useState('status,ASC');
	const [perPage, setPerPage] = React.useState(10);
	const [count, setCount] = React.useState(null);
	const [expandedOrdersIds, setExpandedOrdersIds] = React.useState(
		[],
	);

	const [firstLoad, setFirstLoad] = React.useState(true);

	const queryKey = [
		`inbox|${
			merchant?.shop
		}|${campaignId}|${page}|${perPage}|${sortKey}|${count === null}`,
	];

	const { data, isLoading, isFetching } = useMarketplaceCampaignInbox(
		{
			configOpts: {
				enabled: !!merchant,
				refetchInterval: 60000,
			},
			queryKey,
			id: campaignId,
			queryVariables: {
				campaignId,
				page: page + 1,
				perPage,
				sortKey,
				...(count === null ? { getCount: true } : null),
			},
		},
	);

	React.useEffect(() => {
		if (data?.count || data?.count === 0) {
			setCount(data.count);
			setFirstLoad(false);
		}
	}, [data]);

	const orders = React.useMemo(
		() =>
			data?.orders?.map((order) => {
				const processedOffersCount = order.offers.reduce(
					(res, offer) =>
						res +
						(!offer.canceled_at &&
						(offer.accepted_at || offer.counter_offer_amount)
							? 1
							: 0),
					0,
				);

				const canceledOffersCount = order.offers.reduce(
					(res, offer) => res + (offer.canceled_at ? 1 : 0),
					0,
				);

				let status;
				if (canceledOffersCount === order.offers.length) {
					status = OrderStatus.CANCELED;
				} else if (processedOffersCount === 0) {
					status = OrderStatus.NEW;
				} else if (
					processedOffersCount + canceledOffersCount ===
					order.offers.length
				) {
					status = OrderStatus.PROCESSED;
				} else {
					status = OrderStatus.PARTIALLY_PROCESSED;
				}

				const totalInitialAmount = order.offers.reduce(
					(res, offer) =>
						res +
						parseFloat(offer.shop_currency_offer_amount) *
							offer.quantity,
					0,
				);

				const totalCurrentAmount = order.offers.reduce(
					(res, offer) =>
						res +
						(parseFloat(offer.shop_currency_counter_offer_amount) ||
							parseFloat(offer.shop_currency_offer_amount)) *
							(offer.fulfilled_quantity || offer.quantity),
					0,
				);

				const totalInitialCustomerCurrencyOffersAmount =
					order.offers.reduce(
						(res, offer) =>
							res + parseFloat(offer.offer_amount) * offer.quantity,
						0,
					);

				const totalCurrentCustomerCurrencyOffersAmount =
					order.offers.reduce(
						(res, offer) =>
							res +
							(parseFloat(offer.counter_offer_amount) ||
								parseFloat(offer.offer_amount)) *
								(offer.fulfilled_quantity || offer.quantity),
						0,
					);

				const totalOriginalAmount = order.offers.reduce(
					(res, offer) =>
						res +
						((parseFloat(offer.shop_currency_counter_offer_amount) ||
							parseFloat(offer.shop_currency_offer_amount)) /
							(offer.counter_offer_discount ||
								offer.offer_discount)) *
							(offer.fulfilled_quantity || offer.quantity),
					0,
				);

				return {
					...order,
					totalInitialCustomerCurrencyOffersAmount,
					totalCurrentCustomerCurrencyOffersAmount,
					totalInitialAmount,
					totalCurrentAmount,
					totalUnits: order.offers.reduce(
						(res, offer) =>
							res + (offer.fulfilled_quantity || offer.quantity),
						0,
					),
					totalDiscount: totalCurrentAmount / totalOriginalAmount,
					status,
					formatMerchantCurrency,
					formatMerchantNumber,
				};
			}) || [],
		[data, formatMerchantCurrency, formatMerchantNumber],
	);

	const rowsExpanded =
		orders
			?.map((order, index) => [order.id, index])
			.filter(([orderId]) => expandedOrdersIds.includes(orderId))
			.map(([, index]) => index) || [];

	const dataExists =
		!(isLoading || isFetching) &&
		data &&
		data.length !== 0 &&
		!firstLoad;

	const options = {
		elevation: 0,
		storageKey: 'inbox',
		serverSide: true,
		filter: false,
		search: false,
		download: false,
		print: false,
		viewColumns: false,
		selectToolbarPlacement: 'none',
		responsive: 'standard',
		expandableRows: dataExists,
		expandableRowsHeader: false,
		expandableRowsOnClick: dataExists,
		rowsExpanded,
		selectableRows: 'none',
		rowHover: dataExists,
		renderExpandableRow: (rowData) => {
			const offers = rowData.at(DataIndex.offers);

			return offers?.map((offer) => (
				<TableRow key={offer.id}>
					<TableCell />
					<TableCell sx={{ pb: '4px', pt: '4px', pl: '24px' }}>
						<TitleWithImage
							title={offer.variant.title}
							imageUrl={offer.variant.image_url}
							subtitle={offer.variant.sku}
							url={getProductVariantLink(
								offer.shop,
								offer.variant.product_id,
								offer.variant.id,
							)}
						/>
					</TableCell>
					<TableCell sx={{ pb: '4px', pt: '4px' }}>
						{formatReadableDateTime(offer.createdAt)}
					</TableCell>
					<TableCell sx={{ pb: '4px', pt: '4px' }}>
						<MoneyRenderer
							shopCurrencyAmount={+offer.shop_currency_offer_amount}
							customerCurrencyAmount={+offer.offer_amount}
							customerCurrency={offer.currency}
							shopCurrency={offer.shop_currency}
						/>
					</TableCell>
					<TableCell sx={{ pb: '4px', pt: '4px' }}>
						<MoneyRenderer
							shopCurrencyAmount={
								+(
									offer.shop_currency_counter_offer_amount ||
									offer.shop_currency_offer_amount
								)
							}
							customerCurrencyAmount={
								+(offer.counter_offer_amount || offer.offer_amount)
							}
							customerCurrency={offer.currency}
							shopCurrency={offer.shop_currency}
						/>
					</TableCell>
					<TableCell sx={{ pb: '4px', pt: '4px' }}>
						<Typography color="pending.main">
							{formatMerchantNumber({
								value: offer.fulfilled_quantity || offer.quantity,
							})}
						</Typography>
					</TableCell>
					<TableCell sx={{ pb: '4px', pt: '4px' }}>
						<Typography color="pending.main">
							{parsePercents(
								offer.counter_offer_discount || offer.offer_discount,
							)}
							%
						</Typography>
					</TableCell>
					<TableCell>
						<StatusRenderer
							quantity={offer.quantity}
							fulfilledQuantity={offer.fulfilled_quantity}
							status={offerService.getOfferStatus(offer)}
							cancellationReason={offer.cancellation_reason}
						/>
					</TableCell>
				</TableRow>
			));
		},
		// Sorting configuration
		onColumnSortChange: (column, direction) => {
			setSortKey(`${column},${direction}`);
		},
		onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) => {
			let newExpandedRowsIds = expandedOrdersIds;
			currentRowsExpanded.forEach((currentRow) => {
				const isExpanded = allRowsExpanded.find(
					(row) => row.dataIndex === currentRow.dataIndex,
				);

				const orderId = orders[currentRow.dataIndex].id;
				if (isExpanded && !expandedOrdersIds.includes(orderId)) {
					newExpandedRowsIds.push(orderId);
				} else {
					newExpandedRowsIds = newExpandedRowsIds.filter(
						(p) => p !== orderId,
					);
				}
			});

			setExpandedOrdersIds(newExpandedRowsIds);
		},
		// Pagination configurations
		count: count ?? 10,
		page,
		rowsPerPage: perPage,
		rowsPerPageOptions: [10, 20, 50],
		onChangeRowsPerPage: (value) => {
			setPerPage(value);
			setPage(0);
		},
		onChangePage: (newPage) => {
			setPage(newPage);
		},
		textLabels: {
			body: {
				noMatch: getNoTableMatch({
					isLoading: isLoading || isFetching || firstLoad,
					text: 'No offers found',
				}),
			},
		},
	};

	const getMuiTheme = () => {
		const overrides = {
			components: {
				MUIDataTable: {
					styleOverrides: {
						root: {},
					},
				},
			},
		};

		return createTheme(theme, overrides);
	};
	return (
		<ThemeProvider theme={getMuiTheme()}>
			<MUIDataTable
				title="Offers Inbox"
				data={dataExists ? orders : []}
				columns={columns}
				options={options}
			/>
		</ThemeProvider>
	);
}

NyopOffersInboxTable.propTypes = {
	campaignId: PropTypes.string,
};

export default NyopOffersInboxTable;
