import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const STEPS = [
	'Manage Campaign',
	'Review Campaign',
	'Analyze Offers',
];

function CampaignWorkflowStepper({ activeStep }) {
	return (
		<>
			<Typography
				sx={{
					fontSize: 12,
					color: 'rgba(0, 0, 0, 0.6)',
				}}
			>
				Workflow
			</Typography>
			<Stepper
				activeStep={activeStep}
				sx={{
					mb: 3,
					mt: 1,
				}}
			>
				{STEPS.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
			<Outlet />
		</>
	);
}

CampaignWorkflowStepper.propTypes = {
	activeStep: PropTypes.number.isRequired,
};

export default CampaignWorkflowStepper;
