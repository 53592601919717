import Cookies from 'js-cookie';
import axios from './index';
import { COOKIE } from '@app/api/constants';

const apiUrl = '/api/sessions';

export const clearSession = () => {
	Cookies.remove(COOKIE);
};

export async function getSession() {
	const cookie = Cookies.get(COOKIE);
	if (!cookie) {
		return null;
	}

	try {
		const response = await axios.get(apiUrl);
		return response.data;
	} catch (e) {
		if (e?.response?.status === 401) {
			// Clear session so next time this function is called it returns early without API calls
			clearSession();
			return null;
		}
		throw e;
	}
}

export async function logout() {
	const response = await axios.delete(apiUrl);
	clearSession();
	return response.data;
}
