import Analytics from '@mui/icons-material/Analytics';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import StoreIcon from '@mui/icons-material/Store';

import NyopOffersInboxIcon from '@app/components/DashboardFrame/icons/NyopOffersInboxIcon';
import { FLAGS } from '@app/hooks/useFlags';

export const mainRoutes = [
	{
		to: '/',
		text: 'Inventory Review',
		Icon: DashboardIcon,
	},
];

export const marketplaceRoutes = [
	{
		to: '/marketplace/supplier',
		text: 'Products',
		Icon: StorefrontIcon,
		flag: FLAGS.MARKETPLACE_AVAILABLE,
		condition: (merchant) => !merchant?.is_marketplace,
	},
	{
		to: '/marketplace/retailer',
		text: 'Shops',
		Icon: StoreIcon,
		condition: (merchant) => merchant?.is_marketplace,
	},
	{
		to: '/marketplace/campaigns',
		text: 'Campaigns',
		Icon: ShoppingBagIcon,
		flag: FLAGS.MARKETPLACE_AVAILABLE,
	},
];

export const campaignRoutes = [
	{
		to: '/campaigns',
		text: 'Manage Campaigns',
		Icon: LocalOfferIcon,
	},
	{
		to: '/nyop/inbox',
		text: 'Offers Inbox',
		Icon: NyopOffersInboxIcon,
	},
	{
		to: '/nyop/products',
		text: 'Analytics',
		Icon: Analytics,
	},
];

export const settingsRoutes = [
	{
		to: '/billing',
		text: 'Billing',
		Icon: ReceiptIcon,
	},
	{
		to: '/settings',
		text: 'Settings',
		Icon: SettingsIcon,
	},
];
