import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Stepper from './Stepper';
import AcceptTerms from './steps/AcceptTerms';
import BazoFaq from './steps/BazoFaq';

import { updateMerchant } from '../../api/merchant';

function MarketplaceOnboarding({ merchant, setMerchant }) {
	const navigate = useNavigate();
	const [acceptTerms, setAcceptTerms] = React.useState(false);
	const [error, setError] = React.useState(false);

	const handleChange = (event) => {
		setAcceptTerms(event.target.checked);
	};

	const completeOnboarding = React.useCallback(async () => {
		const updateFields = {
			onboarding_complete: true,
			accept_terms: true,
			// Set the following to true so we don't send irritating alerts
			// TODO: Differentiate onboarding types and suppress warnings based on onboarding type
			auxiliary_protection_step_complete: true,
			auxiliary_free_gift_step_complete: true,
		};

		try {
			await updateMerchant({
				fields: updateFields,
			});
			setMerchant({
				...merchant,
				...updateFields,
			});

			navigate('/'); // Skip subscription screen and go strait to home
		} catch (err) {
			console.error(err);
			setError(true);
		}
	}, [merchant, setMerchant, navigate]);

	return (
		<Box>
			<Typography variant="h4" sx={{ mb: 1 }}>
				Set up for the Bazo Marketplace
			</Typography>
			<Stepper
				disabled={!acceptTerms}
				steps={[
					{
						labelText: 'Review Bazo FAQs',
						optional: false,
						description: <BazoFaq />,
					},
					{
						labelText: "Agree to Bazo's policies",
						optional: false,
						description: (
							<AcceptTerms
								handleChange={handleChange}
								acceptTerms={acceptTerms}
							/>
						),
					},
				]}
				onComplete={completeOnboarding}
				reset={false}
			/>
			{error && (
				<Box sx={{ p: 1, mb: 2 }}>
					<Typography color="error" sx={{ mt: 2 }} textAlign="center">
						Oops! There was a problem onboarding. Please refresh the
						page and try again.
					</Typography>
				</Box>
			)}
		</Box>
	);
}

MarketplaceOnboarding.propTypes = {
	merchant: PropTypes.shape({
		onboarding_begin: PropTypes.bool,
		onboarding_complete: PropTypes.bool,
	}),
	setMerchant: PropTypes.func.isRequired,
};

export default MarketplaceOnboarding;
