export const campaignEnded = (campaign) =>
	campaign.end_date && new Date(campaign.end_date) < new Date();

export const campaignStarted = (campaign) =>
	campaign.start_date && new Date() >= new Date(campaign.start_date);

export const campaignBeingFinalized = (campaign) =>
	campaignEnded(campaign) &&
	campaign.processing_started_at &&
	!campaign.processing_error_message &&
	!campaign.completed_at;

export const campaignFinalized = (campaign) =>
	campaignEnded(campaign) && campaign.completed_at !== null;

export const campaignReadyForProcessing = (campaign) =>
	!campaignFinalized(campaign) && !campaignBeingFinalized(campaign);

export const truncateChartBarText = (ctx, text, maxWidth) => {
	let { width } = ctx.measureText(text);
	const ellipsis = '...';
	const ellipsisWidth = ctx.measureText(ellipsis).width;

	if (width <= maxWidth) {
		return text;
	}

	let truncatedText = text;
	while (
		width + ellipsisWidth > maxWidth &&
		truncatedText.length > 0
	) {
		truncatedText = truncatedText.slice(0, -1);
		width = ctx.measureText(truncatedText).width;
	}

	return truncatedText + ellipsis;
};
