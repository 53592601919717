import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import * as checkoutValidationApi from '../api/checkoutValidation';

export const CACHE_KEY = 'checkout-validation';

export function useGetCheckoutValidation({ configOpts = {} } = {}) {
	return useQuery(
		[CACHE_KEY],
		async () => checkoutValidationApi.getCheckoutValidation(),
		configOpts,
	);
}

export function useUpdateCheckoutValidation({
	configOpts = {},
} = {}) {
	const queryClient = useQueryClient();

	return useMutation(checkoutValidationApi.updateCheckoutValidation, {
		...configOpts,
		onSuccess: (newData) => {
			queryClient.setQueryData([CACHE_KEY], () => ({
				node: newData?.validation,
			}));
		},
	});
}
