import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import constants from '@app/constants';
import RadioButtonGroup from '@app/components/shared/inputs/RadioButtonGroup';

function InventoryPolicyInput({ defaultValue, onChange }) {
	return (
		<Grid container spacing={1}>
			{/* TODO: Clean this up and remove.
					Not needed with Exact delivery date */}

			{/* <Grid item md={6}>
						<RadioButtonGroup
							name="fulfillment"
							value={values.fulfillment}
							handleChange={handleChange}
							formLabelText="Fulfillment"
							radios={[
								{
									label: 'As Soon as possible',
									helperText:
										'The order is fulfilled when the order is created',
									value:
										constants.deferredPurchaseOptions
											.sellingPlanFulfillmentTrigger.Asap,
								},
								{
									label: 'Unknown',
									helperText: 'The fulfillment date is unknown',
									value:
										constants.deferredPurchaseOptions
											.sellingPlanFulfillmentTrigger.Unknown,
								},
							]}
						/>
					</Grid> */}
			<Grid item md={12}>
				<RadioButtonGroup
					name="inventory"
					value={defaultValue}
					handleChange={onChange}
					formLabelText="Inventory Policy"
					radios={[
						{
							label: 'On fulfillment',
							helperText:
								'Inventory is updated when the order is fulfilled',
							value:
								constants.deferredPurchaseOptions.sellingPlanReserve
									.OnFulfillment,
						},
						{
							label: 'On sale',
							helperText:
								'Inventory is updated when the order is created',
							value:
								constants.deferredPurchaseOptions.sellingPlanReserve
									.OnSale,
						},
					]}
				/>
			</Grid>
		</Grid>
	);
}

InventoryPolicyInput.propTypes = {
	defaultValue: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default InventoryPolicyInput;
