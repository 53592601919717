import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InfoBox from '@app/components/shared/ui/InfoBox';
import Card from '../../../components/shared/ui/Card';
import { useBillingBalance } from '../../../hooks/useMerchant';
import Loader from '../../../components/shared/loaders/CircularLoader';
import ProgressBar from '../../../components/shared/loaders/ProgressBar';
import constants from '../../../constants/index';
import useAuth from '@app/hooks/useAuth';

function Balance() {
	const { merchant } = useAuth();
	const { data: balanceData, isLoading: balanceLoading } =
		useBillingBalance();

	if (!merchant || balanceLoading) {
		return <Loader fullPage />;
	}

	// We may have a custom app or a lapsed or non-completed subscription status that was breaking this
	if (!balanceData?.data?.balance) {
		return (
			<Box sx={{ mb: 1 }}>
				<Card>
					<InfoBox type="error">
						<Typography>
							Your app subscription has expired or is unavailable.
							Some features may be limited or unavailable until you
							renew.
						</Typography>
					</InfoBox>
				</Card>
			</Box>
		);
	}

	const usageFeeCharged =
		merchant.active_subscription?.billing_tier?.percent_fee;

	return (
		<Box sx={{ mb: 1 }}>
			<Card>
				{usageFeeCharged ? (
					<>
						<Typography>
							You will be charged next billing cycle
						</Typography>
						<Box sx={{ display: 'flex', width: '100%' }}>
							<ProgressBar
								sx={{ width: '100%' }}
								startAdornment="$"
								data={[
									{
										color: constants.colors.primary,
										value: parseFloat(
											balanceData.data.balance.balanceUsed,
										),
									},
								]}
								total={parseFloat(
									balanceData.data.balance.cappedAmount,
								)}
							/>
						</Box>
					</>
				) : (
					<Typography>
						No usage fees will be charged under your current billing
						subscription
					</Typography>
				)}
			</Card>
		</Box>
	);
}

export default Balance;
