import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import ProgressBar from '@app/components/shared/loaders/ProgressBar';
import constants from '@app/constants/index';

function ExecutionProgress({
	total,
	convertedUnits,
	counteredUnits,
	lostUnits,
}) {
	return (
		<Box sx={{ width: '160px' }}>
			<ProgressBar
				sx={{ maxWidth: '130px' }}
				data={[
					{
						label: 'Converted offers units',
						value: convertedUnits,
						color: constants.colors.primary,
					},
					{
						label: 'Countered offers units',
						value: counteredUnits,
						color: constants.colors.tertiary,
					},
					{
						label: 'Canceled offers units',
						value: lostUnits,
						color: constants.colors.failure,
					},
				]}
				total={total}
				showLegend
				legendInPercentage
			/>
		</Box>
	);
}

ExecutionProgress.propTypes = {
	total: PropTypes.number.isRequired,
	convertedUnits: PropTypes.number.isRequired,
	counteredUnits: PropTypes.number.isRequired,
	lostUnits: PropTypes.number.isRequired,
};

export default ExecutionProgress;
