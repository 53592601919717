import React from 'react';

import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import { useBillingTiers } from '@app/hooks/useMerchant';
import Loader from '../shared/loaders/CircularLoader';
import SubscriptionTile from './components/SubscriptionTile';

function SubscriptionsTilesContainer() {
	const { data, isLoading } = useBillingTiers();

	if (isLoading) {
		return <Loader fullPage />;
	}

	if (data.length === 0) {
		return (
			<Typography
				variant="h5"
				color="text.secondary"
				textAlign="center"
			>
				You don&apos;t have any eligible subscription tiers
			</Typography>
		);
	}

	return (
		<Grid
			container
			spacing={2}
			columnSpacing={{ xs: 1, sm: 2, md: 3 }}
		>
			{data.map((subscription) => (
				<SubscriptionTile
					subscription={subscription}
					key={`subscription-${subscription.id}`}
				/>
			))}
		</Grid>
	);
}

export default SubscriptionsTilesContainer;
