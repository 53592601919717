import { isNil } from 'lodash-es';

export const roundValue = (value) => {
	if (value === Math.round(value)) {
		return Math.round(value);
	}

	return parseFloat(value.toFixed(2));
};

export const targetPriceFromThreshold = ({ price, threshold }) =>
	roundValue((price * (100 - threshold)) / 100);

export const thresholdFromTargetPrice = ({ price, targetPrice }) =>
	roundValue(100 - (targetPrice / price) * 100);

export const validateCounterValueMsrp = (
	counterValue,
	msrp,
	price,
) => {
	if (isNil(msrp) || +msrp === +price) {
		return true;
	}

	const priceToMsrpRatio = +price / +msrp;
	const priceToMsrpCounterValue = 1 - priceToMsrpRatio;

	return priceToMsrpCounterValue < counterValue / 100;
};

export function sortVariantsByTitle(variants) {
	return variants.toSorted((a, b) =>
		a.full_title.localeCompare(b.full_title),
	);
}

export const splitVariantsByCounterValue = (
	selectedVariants,
	counterValues,
	defaultThresholdDiscount,
) => {
	const variantsWithValidCounterValues = [];
	const variantsWithInvalidCounterValues = [];

	selectedVariants.forEach((currentVariant) => {
		const productCounterValue =
			counterValues[currentVariant.product_id]?.toString();

		const variantDefaultCounterValue =
			productCounterValue ?? defaultThresholdDiscount;

		const variantCounterValue =
			counterValues[currentVariant.id]?.toString();

		const counterValue =
			variantCounterValue ?? variantDefaultCounterValue;

		if (
			validateCounterValueMsrp(
				counterValue,
				currentVariant.msrp,
				currentVariant.price,
			)
		) {
			variantsWithValidCounterValues.push(currentVariant);
		} else {
			variantsWithInvalidCounterValues.push(currentVariant);
		}
	});

	return {
		variantsWithValidCounterValues,
		variantsWithInvalidCounterValues,
	};
};
