import * as React from 'react';
import PropTypes from 'prop-types';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import { useTheme } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import RequiresFlag from '@app/components/shared/flags/RequiresFlag';
import useAuth from '@app/hooks/useAuth';

function NavListItems({ routes, subheader }) {
	const theme = useTheme();
	const { merchant } = useAuth();

	return (
		<>
			{subheader && (
				<ListSubheader component="div" inset>
					{subheader}
				</ListSubheader>
			)}
			{routes
				.filter(
					(route) => !route.condition || route.condition(merchant),
				)
				.map((route) => (
					<RequiresFlag flag={route.flag}>
						<NavLink
							key={`${route.to}-${route.text}`}
							to={route.to}
							style={{ textDecoration: 'none', color: 'inherit' }}
						>
							{({ isActive }) => (
								<ListItemButton>
									<ListItemIcon>
										{React.createElement(route.Icon, {
											color: isActive ? 'primary' : 'default',
										})}
									</ListItemIcon>
									<ListItemText
										primary={route.text}
										primaryTypographyProps={
											isActive
												? {
														style: {
															color: theme.palette.primary.main,
														},
												  }
												: null
										}
									/>
								</ListItemButton>
							)}
						</NavLink>
					</RequiresFlag>
				))}
		</>
	);
}

NavListItems.propTypes = {
	routes: PropTypes.arrayOf(
		PropTypes.shape({
			to: PropTypes.string,
			text: PropTypes.string,
			Icon: PropTypes.elementType,
		}),
	).isRequired,
	subheader: PropTypes.string,
};

NavListItems.defaultProps = {
	subheader: '',
};

export default NavListItems;
